import React from "react";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Grid } from "@material-ui/core";
import {
  enableOrDisableRobot,
  fetchRobotList,
  editStatusRobot
} from "../../../redux/actions/services";
import { FETCH_ROBOTS } from "../../../redux/constants";
import AdminTable from "../AdminTable";
import useStyles from "../FleetManagement/style";
import StatusLegend from "components/StatusLegend";
import PageHeader from "../../../components/PageHeader";

function RobotManagement() {
  const entityBaseUrl = "/robotAdministration/robots";
  const enableDisableEntity = enableOrDisableRobot;
  const fetchEntities = fetchRobotList;
  const entityQuery = FETCH_ROBOTS;
  const searchField = "searchCriteria";
  const classes = useStyles();

  const headerCells = {
    fields: [
      {
        id: "logo",
        gridSize: 1,
        align: "center",
        label: "",
      },
      {
        id: "robotDisplayName",
        gridSize: 3,
        align: "center",
        label: "robot.management.robotName",
      },
      {
        id: "processesRunning",
        gridSize: 2,
        align: "center",
        label: "robot.management.processesRunning",
      },
      {
        id: "displayStatus",
        gridSize: 2,
        align: "center",
        label: "robot.management.displayStatus",
      },
      {
        id: "orchestratorName",
        gridSize: 2,
        align: "center",
        label: "robot.management.orchestratorName",
      },
      {
        id: "attributeName",
        gridSize: 2,
        align: "center",
        label: "robot.management.attributeName",
      },
    ],
  };

  const displayFields = [
    {
      id: "displayStatus",
      gridSize: 1,
      align: "center",
      valuePath: "displayStatus",
      isStatus: true,
      translate: false,
    },
    {
      id: "robotDisplayName",
      gridSize: 3,
      align: "center",
      valuePath: "robotDisplayName",
      translate: false,
    },
    {
      id: "processesRunning",
      gridSize: 2,
      align: "center",
      valuePath: "processesRunning",
    },
    {
      id: "displayStatus",
      gridSize: 2,
      align: "center",
      valuePath: "displayStatus",
      defaultValue: "Offline",
    },
    {
      id: "orchestratorName",
      gridSize: 2,
      align: "center",
      valuePath: "orchestrator.name",
    },
    {
      id: "attributeName",
      gridSize: 2,
      align: "center",
      valuePath: "attributeName",
    },
  ];

  const statusList = [
    { code: "IDLE", label: "IDLE" },
    { code: "WORKING", label: "WORKING" },
    { code: "OFFLINE", label: "OFFLINE" },
  ];

  const labels = {
    title: "robot.management.title",
    addButton: "robot.management.add.button",
    deleteEntity: "robot.management.hide.confirmMsg",
    restoreEntity: "robot.management.show.confirmMsg",
    deleteEntityTooltip: "tooltip.action.hide",
    deleteEntityConfirmButton: "tooltip.action.hide",
    restoreEntityConfirmButton: "tooltip.action.show",
    restoreEntityTooltip: "tooltip.action.show",
    deleteEntities: "robot.management.deleteRobotActionMessage",
    enableEntity: "robot.management.enable.confirmMsg",
    enableConfirm: "robot.management.enable.button.confirm",
    disableEntity: "robot.management.disable.confirmMsg",
    disableConfirm: "robot.management.disable.button.confirm",
    searchPlaceHolder: "Search",
    entityDeleted: "robot.hidden.success",
    confirmAction: "Accept",
  };
  const statues = ["WORKING", "IDLE", "OFFLINE"]
  return (
    <div className={classes.root}>
      <div className={classes.paper}>
        <PageHeader title={labels.title} />
        <Grid container>
          <Grid container item xs={10}>
            <StatusLegend statues={statues} />
          </Grid>
        </Grid>
        <AdminTable
          deleteIcon={VisibilityIcon}
          restoreIcon={VisibilityOffIcon}
          deleteEntities={editStatusRobot}
          deleteEntity={editStatusRobot}
          statusList={statusList}
          displayFields={displayFields}
          headerCells={headerCells}
          entityBaseUrl={entityBaseUrl}
          enableDisableEntity={enableDisableEntity}
          fetchEntities={fetchEntities}
          entityQuery={entityQuery}
          labels={labels}
          searchField={searchField}
          isRobotAdministrationPage
          statusField="isActive"
          showStatusSwitch={false}
          enableDeleteMultiple
        />
      </div>
    </div>
  );
}

export default RobotManagement;
