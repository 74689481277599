import {
    FETCH_EXECUTIONS_ROBOTS,
    FETCH_PROCESS_ASSIGNED_ROBOTS,
    FETCH_QUEUE_ROBOTS,
    FETCH_ROBOT,
    FETCH_ROBOTS,
    FETCH_ROBOTS_BY_ORCHESTRATOR,
    FETCH_ROBOTS_BY_PROCESSES,
    UPDATE_ROBOT,
    UPDATE_ROBOT_STATUS
} from "../../constants";
import {
    URL_PROCESSES, URL_PROCESS_EXECUTION, URL_ROBOT, URL_SR_QUEUES_MNGT
} from "../../constants/endpoints";

export const fetchRobotList = (filter = {}) => {
    const {
        searchCriteria,
        orchestrator,
        pageNo,
        pageSize,
        status,
        sortBy,
        showDeleted,
        fleets
    } = filter;
    return {
        type: FETCH_ROBOTS,
        request: {
            url: encodeURI(`${URL_ROBOT}${searchCriteria ? `?searchCriteria=${searchCriteria}` : ""}`),
            method: "GET",
            params: {
                pageNo,
                pageSize,
                sortBy,
                status: status ? status.join() : null,
                hidden: showDeleted,
                orchestrators: Array.isArray(orchestrator)
                    ? orchestrator.map((orch) => orch.id).join()
                    : [],
                fleetsIds: fleets?.map(({ id }) => id)?.join()
            },
        },
    };
};
export const enableOrDisableRobot = (id, robotStatus, onSuccess, onError) => ({
    type: UPDATE_ROBOT_STATUS,
    request: {
        url: `${URL_ROBOT}${id}/${robotStatus === "ACTIVE" ? "enable" : "disable"}`,
        method: "put",
        headers: { "Content-Type": "application/json" },
    },
    meta: {
        onSuccess: (response) => {
            if (onSuccess) onSuccess(response);
            return response;
        },
        onError: (error) => {
            if (onError) onError(error);
            return error;
        },
    },
});
export const updateRobot = (robotId, processesId, robotName) => {
    const params = { processesId, robotName };
    return {
        type: UPDATE_ROBOT,
        request: {
            url: `${URL_ROBOT}${robotId}`,
            method: "PUT",
            params,
        },
    };
};
export const fetchRobotsByOrchestrator = (idOrchestrator) => ({
    type: FETCH_ROBOTS_BY_ORCHESTRATOR,
    request: {
        url: `${URL_ROBOT}orchestrator/${idOrchestrator}`,
        method: "GET",
    },
});

export const fetchRobot = (id) => ({
    type: FETCH_ROBOT,
    request: {
        url: `${URL_ROBOT}${id}`,
        method: "GET",
    },
});
export const editStatusRobot = (robotsIds, onSuccess, onError) => {
    if (typeof robotsIds === "number") {
        robotsIds = [robotsIds]
    }
    return {
        type: "EDIT_ROBOTS_BY_ID",
        request: {
            url: `${URL_ROBOT}editStatus`,
            method: "put",
            params: {
                robotsIds: robotsIds.join(",")
            }
        },
        meta: {
            onSuccess: (response) => {
                if (onSuccess) onSuccess(response);
                return response;
            },
            onError: (error) => {
                if (onError) onError(error);
                throw error;
            },
        },
    };
};
export const getQueuRobots = (queueId) => ({
    type: FETCH_QUEUE_ROBOTS,
    request: {
        url: `${URL_SR_QUEUES_MNGT}/${queueId}/robots`,
        method: "get",
    },
});
export const fetchAssignedRobotsByProcess = (processId) => ({
    type: FETCH_PROCESS_ASSIGNED_ROBOTS,
    request: {
        url: `${URL_PROCESSES}${processId}/robots/assigned`,
        method: "get",
    },
});
export const fetchRobotsByProcesses = (processesIds) => ({
    type: FETCH_ROBOTS_BY_PROCESSES,
    request: {
        url: `${URL_ROBOT}processes`,
        method: "get",
        params: {
            processesIds: processesIds.join(","),
        },
    },
});
export const fetchExecutionsRobots = (processIds) => ({
    type: FETCH_EXECUTIONS_ROBOTS,
    request: {
        url: `${URL_PROCESS_EXECUTION}robots`,
        method: "get",
        params: {
            processIds: processIds.join(","),
        },
    },
});
