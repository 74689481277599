import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector, connect } from "react-redux";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import { format } from "date-fns-tz";
import DataNotFound from "components/DataNotFound";
import { useHistory, useLocation } from "react-router-dom";
import {
  fetchExceptions,
  fetchProcessesNameException,
  fetchExceptionsReason,
  fetchExceptionsType,
  deleteException,
  deleteExceptions,
  showExceptions,
  exportExceptionXLSX,
  fetchExceptionByAssigner,
  fetchExportedFiles,
  fetchExceptionsCount,
  fetchItemExceptionsAnalyticsKpiByProcess,
  fetchItemExceptionsAnalyticsKpiByReason,
  fetchItemExceptionsAnalyticsKpiByType,
  fetchItemExceptionsAnalyticsKpiByTrend,
} from "../../../redux/actions/services";
import useStyles from "../style";

import ConfirmMessage from "../../../components/ConfirmMessage";
import Filter from "../Filter";
import CircularLoader from "../../../components/Loaders/CircularLoader";
import PageHeader from "../../../components/PageHeader";
import Details from "../../../components/DataDetail";
import ExceptionsContent from "../ExceptionsContent";
import Analytics from "../Analytics";
import { useQuery } from "../../../components/Filter";
import RetryDialog from "./RetryDialog";
import { updateFilter } from "../../../redux/slices/exceptionFilter";
import WorkflowDrawer from "./Workflow/WorkflowDrawer";
import WorkflowBulkDrawer from "./Workflow/WorkflowBulkDrawer";
import { exceptionCurrentModule } from "../../../util";
import { MAX_GRAPH_PROCESSES } from "util/constants";
import ExportConfirmation from "components/Filter/ExportConfirmation/ExportConfirmation";
import { getQuery } from "@redux-requests/core";
import { toast } from "react-toastify";
import isEmpty from "lodash/isEmpty";
import CustomPagination from "pages/Services/components/CustomPagination";

const sortColumns = [
  {
    id: "executionStartTime",
    label: "Creation date (Descending)",
    order: "desc",
  },
  {
    id: "executionStartTime",
    label: "Creation date (Ascending)",
    order: "asc",
  },
  {
    id: "exceptionTime",
    label: "Exception Time (Descending)",
    order: "desc",
  },
  {
    id: "exceptionTime",
    label: "Exception Time (Ascending)",
    order: "asc",
  },
  {
    id: "executionDuration",
    label: "Execution Time (Descending)",
    order: "desc",
  },
  {
    id: "executionDuration",
    label: "Execution Time (Ascending)",
    order: "asc",
  },
];

const dateFormat = "yyyy/MM/dd HH:mm";
function ItemExceptions(props) {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const query = useQuery();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const processExecIdsParam = Number(
    history?.location?.state?.processExecutionId
      || query.get("processExecution"),
  );
  const processExecIds = processExecIdsParam ? [processExecIdsParam] : [];
  const [currentUserIsSet, setCurrentUserIsSet] = React.useState(props.currentUserSet);
  const [data, setData] = React.useState(null);
  const [count, setCount] = React.useState(0);
  const [processName, setProcessName] = React.useState([]);
  const [processes, setProcesses] = React.useState([]);
  const [exceptions, setExceptions] = React.useState([]);
  const [openMsgDetail, setOpenMsgDetail] = React.useState(false);
  const [selectedException, setSelectedException] = React.useState(null);
  const [showHidden, setShowHidden] = React.useState(
    Boolean(processExecIds.length),
  );
  const [process, setProcess] = React.useState([]);
  const [deleteButtonContent, setDeleteButtonContent] = React.useState("");
  const [processExecutionIds, setProcessExecutionIds] = React.useState([]);
  const [retryDialogOpen, setRetryDialogOpen] = React.useState(false);
  const [retryDialogItems, setRetryDialogItems] = React.useState([]);
  const [workflowDrawerOpen, setWorkflowDrawerOpen] = React.useState(false);
  const [workflowBulkDrawerOpen, setWorkflowBulkDrawerOpen] = React.useState(false);
  const [workflowDrawerItem, setWorkflowDrawerItem] = React.useState(null);
  const exceptionWorkFlowStatus = ["READY", "TAKEN", "DONE"]
  const [assigners, setAssigners] = React.useState([]);
  const [exceptionsType, setExceptionsType] = React.useState([]);
  const [selectedFleetIds, setSelectedFleetIds] = React.useState([]);
  const [selectedProcessIds, setSelectedProcessIds] = React.useState([]);
  const [loadedDatetime, setLoadedDatetime] = React.useState(null);
  const [completedDatetime, setCompletedDatetime] = React.useState(null);
  const [selectedExceptionsType, setSelectedExceptionsType] = React.useState(
    [],
  );
  const [kpiDataValues, setKpiDataValues] = useState(false);
  const [checkedItems, setCheckedItems] = React.useState([]);
  const [originalItemId, setOriginalItemId] = React.useState("");
  const [itemsAreRetryable, setItemsAreRetryable] = React.useState(false);
  const [exportConfirmationOpen, setExportConfirmationOpen] = React.useState(false);
  const [separateDetails, setSeparateDetails] = React.useState(false);
  const containerRef = React.useRef();
  const [
    selectedExceptionWorkFlowStatusFilter,
    setSelectedExceptionWorkFlowStatusFilter,
  ] = React.useState([]);

  const exceptionFilters = useSelector(
    ({ filterListException }) => filterListException[exceptionCurrentModule.ITEMS],
  );

  const exceptionProcessesAnalytics = useSelector(
      ({ requests }) => requests.queries.FETCH_ITEM_EXCEPTION_ANALYTICS_KPI_BY_PROCESS?.data,
  );
  const exceptionReasonsAnalytics = useSelector(
      ({ requests }) => requests.queries.FETCH_ITEM_EXCEPTION_ANALYTICS_KPI_BY_REASON?.data,
  );
  const exceptionTypeAnalytics = useSelector(
      ({ requests }) => requests.queries.FETCH_ITEM_EXCEPTION_ANALYTICS_KPI_BY_TYPE?.data,
  );
  const exceptionTrendsAnalytics = useSelector(
      ({ requests }) => requests.queries.FETCH_ITEM_EXCEPTION_ANALYTICS_KPI_BY_TREND?.data,
  );
  const kpiData = useMemo(() => ({
    exceptionProcessesAnalytics,
    exceptionReasonsAnalytics,
    exceptionTypeAnalytics,
    exceptionTrendsAnalytics
  }), [exceptionProcessesAnalytics, exceptionReasonsAnalytics, exceptionTypeAnalytics, exceptionTrendsAnalytics])

  useEffect(() => {
    setKpiDataValues(exceptionProcessesAnalytics?.values?.length
        || exceptionReasonsAnalytics?.values?.length
        || exceptionTypeAnalytics?.values?.some((elm) => elm)
        || exceptionTrendsAnalytics?.values?.some((obj) => Object.keys(obj).length));
  }, [kpiData]);

  const filterExceptionList = useSelector(
    ({ filterListException }) => filterListException,
  );
  const processFilter = useSelector((state) => getQuery(state, { type: "FETCH_WORK_QUEUE_ITEM_PROCESS_ITEM_EXCEPTION" }).data);
  const fetchExceptionsType = () => {
    props
      .fetchExceptionsType(
        selectedProcessIds,
        loadedDatetime,
        completedDatetime,
        selectedFleetIds,
        selectedExceptionWorkFlowStatusFilter
      )
      .then((res) => {
        setExceptionsType(res.data);
      });
  };
  const fetchProcessesNameException = () => {
    props.fetchProcessesNameException(selectedFleetIds).then((res) => {
      setProcesses(res.data);
    });
  };
  const fetchExceptionsReason = () => {
    props
      .fetchExceptionsReason(
        selectedExceptionsType,
        selectedProcessIds,
        loadedDatetime,
        completedDatetime,
        selectedFleetIds,
      )
      .then((res) => {
        setExceptions(res.data);
      });
  };

  React.useEffect(() => {
    handleChangeProcess([]);
    handleChangeExceptionType([]);
    handleChangeException([]);
    handleChangeDates(null, null, "ALL_TIME");
    fetchProcessesNameException();
    fetchExceptionsType();
    fetchExceptionsReason();
  }, [selectedFleetIds.join()]);

  React.useEffect(() => {
    handleChangeExceptionType([]);
    handleChangeException([]);
    fetchExceptionsType();
    fetchExceptionsReason();
  }, [selectedProcessIds.join()]);

  React.useEffect(() => {
    handleChangeException([]);
    fetchExceptionsReason();
  }, [selectedExceptionsType.join()]);

  React.useEffect(() => {
    fetchExceptionsType();
  }, [selectedExceptionWorkFlowStatusFilter.join()])

  React.useEffect(() => {
    let itemsId = data?.filter((i) => props.selected.includes(i.id) && !checkedItems.includes(i)) ?? [];
    itemsId = [...itemsId, ...checkedItems].filter((i) => props.selected.includes(i.id))
      .filter((itm, index, self) => index === self.findIndex((t) => t.id === itm.id));
    setCheckedItems(itemsId);
  }, [props.selected.join()]);

  const fetchData = () => {
    const filterExceptionTypes = exceptionFilters.exceptionType?.map(
      (e) => e.desc,
    );
    const peId = processExecIds || exceptionFilters.processExecutions;
    const filterFleetIds = exceptionFilters.fleet.map((c) => c.id);
    const assignerIds = !currentUserIsSet ? exceptionFilters.usersInCharge.map((a) => a.userId) : new Array(props.currentUser.id);
    getExceptions(
      exceptionFilters.pageNo,
      exceptionFilters.pageSize,
      exceptionFilters.order.id,
      exceptionFilters.order.order,
      exceptionFilters.process,
      exceptionFilters.divisions,
      exceptionFilters.exception,
      filterExceptionTypes,
      filterFleetIds,
      exceptionFilters.exceptionsFromDate,
      exceptionFilters.exceptionsToDate,
      showHidden,
      exceptionFilters.searchText,
      peId,
      exceptionFilters.workflowStatus,
      assignerIds,
      originalItemId,
      exceptionFilters.tags
    );
  };

  useEffect(() => {
    fetchData()
  }, [JSON.stringify(filterExceptionList[exceptionCurrentModule.ITEMS]), originalItemId]);

  const handleChangeShowHidden = (event) => {
    setShowHidden(event.target.checked);
    dispatch(
      updateFilter({
        ...filterExceptionList,
        [exceptionCurrentModule.ITEMS]: {
          pageNo: 0,
          showHidden: event.target.checked,
        },
      }),
    );
  };
  useEffect(() => {
    props.setSelected([]);
  }, [filterExceptionList])

  const handleChangeException = (values) => {
    if (values) {
      dispatch(
        updateFilter({
          ...filterExceptionList,
          [exceptionCurrentModule.ITEMS]: {
            pageNo: 0,
            exception: values
          },
        }),
      );
    }
  };

  const handleChangeExceptionWorkflowStatus = (values) => {
    if (values) {
      dispatch(
        updateFilter({
          ...filterExceptionList,
          [exceptionCurrentModule.ITEMS]: {
            pageNo: 0,
            workflowStatus: values,
          },
        }),
      );
    }
  };
  const handleChangeExceptionAssigners = (values) => {
    if (values) {
      dispatch(
        updateFilter({
          ...filterExceptionList,
          [exceptionCurrentModule.ITEMS]: {
            pageNo: 0,
            usersInCharge: values,
          },
        }),
      );
    }
  };

  const handleChangeExceptionType = (values) => {
    const exceptionTypesDesc = values?.map((e) => e.desc);
    if (values) {
      setSelectedExceptionsType(exceptionTypesDesc);
      dispatch(
        updateFilter({
          ...filterExceptionList,
          [exceptionCurrentModule.ITEMS]: {
            exceptionType: values,
          },
        }),
      );
    }
  };

  const handleChangeProcess = (values) => {
    if (values) {
      const processIds = values.map((p) => p.id);
      setProcessName(values);
      setSelectedProcessIds(processIds);
      dispatch(
        updateFilter({
          ...filterExceptionList,
          [exceptionCurrentModule.ITEMS]: {
            process: values,
          },
        }),
      );
    }
    if (processExecIds?.length && processExecIds.length !== 0) {
      history.replace({
        pathname: location.pathname,
      });
    }
  };
  const handleChangeDivision = (values) => {
    if (values) {
      dispatch(
        updateFilter({
          ...filterExceptionList,
          [exceptionCurrentModule.ITEMS]: {
            divisions: values,
          },
        }),
      );
    }
    if (processExecIds?.length && processExecIds.length !== 0) {
      history.replace({
        pathname: location.pathname,
      });
    }
  };

  const handleChangeFleet = (values) => {
    if (values) {
      dispatch(
          updateFilter({
            ...filterExceptionList,
            [exceptionCurrentModule.ITEMS]: {
              fleet: values,
            },
          }),
      );
    }
    if (processExecIds?.length && processExecIds.length !== 0) {
      history.replace({
        pathname: location.pathname,
      });
    }
  };

  const handleChangeDates = (from, to, value) => {
    if (from && to) {
      const fromString = `${from}`;
      const toString = `${to}`;
      from = fromString.includes("/") ? from : format(from, dateFormat);
      to = toString.includes("/") ? to : format(to, dateFormat);
    }
    setLoadedDatetime(from);
    setCompletedDatetime(to);
    dispatch(
      updateFilter({
        ...filterExceptionList,
        [exceptionCurrentModule.ITEMS]: {
          exceptionsSelectedDurationValue: value,
          exceptionsFromDate: from,
          exceptionsToDate: to,
        },
      }),
    );
  };

  const handleRequestSort = (property) => {
    dispatch(
      updateFilter({
        ...filterExceptionList,
        [exceptionCurrentModule.ITEMS]: {
          pageNo: 0,
          order: property,
        },
      }),
    );
  };

  const handleSelectAllClick = (event) => {
    const newSelecteds = data.map((n) => n.id);
    if (event.target.checked) {
      const selectedToAdd = newSelecteds.filter((s) => !props.selected.includes(s));
      props.setSelected((prvSelected) => [...prvSelected, ...selectedToAdd]);
      const exceptionsToShow = data
        .filter((item) => item.isDeleted)
        .map((n) => n.id);
      props.setExceptionsToShow((prvExceptionsToShow) => Array.from(new Set([...prvExceptionsToShow, ...exceptionsToShow])));
      const exceptionsToHide = data
        .filter((item) => !item.isDeleted)
        .map((n) => n.id);
      props.setExceptionsToHide((prvExceptionsToHide) => Array.from(new Set([...prvExceptionsToHide, ...exceptionsToHide])));
      return;
    }
    props.deleteDuplicationSelection(newSelecteds);
  };

  const handleChangePage = (event, newPage) => {
    dispatch(
      updateFilter({
        ...filterExceptionList,
        [exceptionCurrentModule.ITEMS]: {
          pageNo: newPage,
        },
      }),
    );
  };

  const handleNext = (page) => {
    dispatch(
      updateFilter({
        ...filterExceptionList,
        [exceptionCurrentModule.ITEMS]: {
          pageNo: page + 1,
        },
      }),
    );
    }
    const handlePrevious = (page) => {
      dispatch(
        updateFilter({
          ...filterExceptionList,
          [exceptionCurrentModule.ITEMS]: {
            pageNo: page - 1,
          },
        }),
      );
    }

  const handleChangeRowsPerPage = (event) => {
    dispatch(
      updateFilter({
        ...filterExceptionList,
        [exceptionCurrentModule.ITEMS]: {
          pageNo: 0,
          pageSize: event.target.value,
        },
      }),
    );
  };
  const getExceptions = (
    page,
    size,
    sortField,
    sortOrder,
    processes,
    divisions,
    exceptions,
    exceptionsType,
    fleetIds,
    loadedDatetime,
    completedDatetime,
    hidden,
    searchContent,
    processExecutionIds,
    workFlowStatus,
    assignersIds,
    originalItemId,
    tags = []
  ) => {
    if (!isEmpty(exceptionWorkFlowStatus)) {
      props.setIsLoading(false);
      const processID = processes ? processes.map((e) => e.id) : [];
      props.setIsLoading(true);
      props
          .fetchExceptions(
              page,
              size,
              sortField,
              sortOrder,
              processID,
              divisions,
              exceptions,
              exceptionsType,
              fleetIds,
              loadedDatetime,
              completedDatetime,
              hidden,
              searchContent,
              processExecutionIds,
              workFlowStatus,
              assignersIds,
              originalItemId,
              tags
          )
          .then((result) => {
            setData(result?.data?.content);
            props.setIsLoading(false);
          });
      props
          .fetchExceptionsCount(
              processID,
              divisions,
              exceptions,
              exceptionsType,
              fleetIds,
              loadedDatetime,
              completedDatetime,
              hidden,
              searchContent,
              processExecutionIds,
              workFlowStatus,
              assignersIds,
              originalItemId,
              tags,
          ).then((result) => setCount(result?.data));
    }
  };

  const onExportConfirm = () => {
    const ids = filterExceptionList[exceptionCurrentModule.ITEMS].process.map((p) => p?.id);
    exportItemExceptionXLSX(
      ids,
      filterExceptionList[exceptionCurrentModule.ITEMS]?.exceptionType,
      filterExceptionList[exceptionCurrentModule.ITEMS]?.exception,
      separateDetails
)
  }

  useEffect(() => {
    const fleetIds = exceptionFilters.fleet.map((c) => c.id);
    const typesIds = exceptionFilters.exceptionType.map((t) => t.desc);
    const processIds = exceptionFilters.process.map((p) => p.id);
    const from = exceptionFilters.exceptionsFromDate;
    const to = exceptionFilters.exceptionsToDate;
    setSelectedFleetIds(fleetIds);
    props.fetchProcessesNameException(fleetIds).then((result) => {
      setProcesses(result.data);
    });
    props
      .fetchExceptionsType(processIds, from, to, fleetIds, selectedExceptionWorkFlowStatusFilter)
      .then((resultT) => {
        setExceptionsType(resultT.data);
      });
    props
      .fetchExceptionsReason(typesIds, processIds, from, to, fleetIds)
      .then((resultE) => {
        setExceptions(resultE.data);
      });
    props.fetchExceptionByAssigner().then((res) => {
      if (res.data) {
        setAssigners(res.data);
      }
    });
    if (
      !processExecutionIds.length
      && processExecIds
      && processExecIds.length !== 0
    ) {
      setProcessExecutionIds(processExecIds);
      history.replace({
        pathname: location.pathname,
        search: `?processExecution=${processExecIds}`,
      });
    }
  }, []);
  const updateAssigners = () => {
    props.fetchExceptionByAssigner().then((res) => {
      if (res.data) {
        setAssigners(res.data);
      }
    });
  };
  useEffect(() => {
    dispatch(
      updateFilter({
        ...filterExceptionList,
        [exceptionCurrentModule.ITEMS]: {
          ...filterExceptionList[exceptionCurrentModule.ITEMS],
          order: exceptionFilters.order,
        },
      }),
    );
  }, [exceptionFilters[exceptionCurrentModule.ITEMS]?.order]);

  const deleteException = (e, row) => {
    setSelectedException(row);
    props.setOpenMsgConfirm(true);
    props.setMessageConfirm(
      row?.isDeleted
        ? t("Are you sure you want to show this exception ?")
        : t("Are you sure you want to delete this exception ?"),
    );
    setDeleteButtonContent(
      row?.isDeleted ? t("tooltip.action.show") : t("tooltip.action.hide"),
    );
  };
  const deleteExceptions = () => {
    props.setOpenMsgConfirm(true);
    props.setMessageConfirm(
      `${t("Are you sure you want to delete")
      } ${
        props.exceptionsToHide.length
      } ${
        t("exceptions")
      } ?`,
    );
    setDeleteButtonContent(t("tooltip.action.hide"));
  };

  const showExceptions = () => {
    props.setOpenMsgConfirm(true);
    props.setMessageConfirm(
      t("Are you sure you want to show X exceptions?", {
        count: props.exceptionsToShow.length,
      }),
    );

    setDeleteButtonContent(t("tooltip.action.show"));
  };

  const cancelConfirm = () => {
    props.setOpenMsgConfirm(false);
    setSelectedException(null);
  };
  const confirmDelete = () => {
    props.setIsLoadingConfirm(true);
    if (selectedException?.id) {
      props.deleteException(selectedException.id).then(() => {
        toast.success(selectedException?.isDeleted ? t("Exception Restored Successfully") : t("Exception Deleted Successfully"))
        props.setOpenMsgConfirm(false);
        props.setIsLoadingConfirm(false);
        props.setSelected([]);
        props.setExceptionsToShow([]);
        props.setExceptionsToHide([]);
        setSelectedException(null);
        props.fetchProcessesNameException().then((result) => {
          setProcesses(result.data);
          props
            .fetchExceptionsReason([])
            .then((resultE) => {
              setExceptions(resultE.data);
              fetchData();
            });
        });
      });
    } else if (deleteButtonContent === t("tooltip.action.hide")) {
      props.deleteExceptions(props.selected).then(() => {
        toast.success(`${props.exceptionsToHide.length} ${t("exceptions successfully deleted")}`)
        props.setOpenMsgConfirm(false);
        props.setIsLoadingConfirm(false);
        props.setSelected([]);
        props.setExceptionsToShow([]);
        props.setExceptionsToHide([]);
        setSelectedException(null);
        fetchData();
      });
    } else if (deleteButtonContent === t("tooltip.action.show")) {
      props.showExceptions(props.selected).then(() => {
        toast.success(t("X exceptions successfully restored", { count: props.exceptionsToShow.length, }))
        props.setOpenMsgConfirm(false);
        props.setIsLoadingConfirm(false);
        props.setSelected([]);
        props.setExceptionsToShow([]);
        props.setExceptionsToHide([]);
        setSelectedException(null);
        fetchData();
      });
    }
  };

  const handleShowExceptionDetails = (row) => {
    setOpenMsgDetail(true);
    props.setDetail(row?.parsedData);
    props.setLogDetail(row?.logDetail);
    props.setValidate(row?.logDetail && row?.logSummary);
  };

  const onClickRow = (row) => {
    handleShowExceptionDetails(row);
  };
  const closeDialog = () => {
    setOpenMsgDetail(false);
  };

  const exportItemExceptionXLSX = (ids, exceptionTypeName, exceptionName, separateDetails = false) => {
    const exceptionNames = exceptionName.map((e) => e.desc);
    const exceptionTypes = exceptionTypeName.map((e) => e.desc);
    const peId = processExecIds || exceptionFilters.processExecutions;
    const handlersIds = exceptionFilters?.usersInCharge.map((u) => u.userId);
    const divisionsIds = exceptionFilters.divisions.map((division) => division.id).join(", ");
    const tagsID = exceptionFilters.tags?.map((e) => e?.id ?? e);
    dispatch(
      exportExceptionXLSX(
        ids,
        exceptionTypes,
        exceptionNames,
        exceptionFilters.fleet,
        exceptionFilters.exceptionsFromDate,
        exceptionFilters.exceptionsToDate,
        props.selected,
        showHidden,
        exceptionFilters.searchText,
        peId,
        exceptionFilters.workflowStatus,
        handlersIds,
        exceptionFilters.order.id,
        exceptionFilters.order.order,
        separateDetails,
        divisionsIds,
        tagsID
      ),
    ).then(() => {
      props.setExportLoading(false);
      dispatch(fetchExportedFiles());
      toast.success(t("export.successful.notification"));
    });
  };

  const deletePermission = "Delete item exceptions";

  const [analyticsLoading, setAnalyticsLoading] = React.useState({
    exceptionProcessesAnalyticsLoading: false,
    exceptionReasonsAnalyticsLoading: false,
    exceptionTypeAnalyticsLoading: false,
    exceptionTrendsAnalyticsLoading: false,
  });

  useEffect(() => {
    if (props.showAnalyticsContent) {
      setAnalyticsLoading({
        exceptionProcessesAnalyticsLoading: true,
        exceptionReasonsAnalyticsLoading: true,
        exceptionTypeAnalyticsLoading: true,
        exceptionTrendsAnalyticsLoading: true,
      });

      const requestData = {
        processes: filterExceptionList[exceptionCurrentModule.ITEMS].process?.map((e) => e.id),
        exceptions: exceptionFilters.exception.map(({ exceptionReason }) => exceptionReason).join(),
        exceptionsType: exceptionFilters.exceptionType.map((t) => t.desc).join(),
        fleetIds: exceptionFilters.fleet.map((c) => c.id),
        tagsIds: exceptionFilters.tags.join(),
        loadedDatetime,
        completedDatetime,
        processExecutionIds,
        showHidden,
      }
      dispatch(
          fetchItemExceptionsAnalyticsKpiByProcess(
              requestData.processes,
              requestData.exceptions,
              requestData.exceptionsType,
              requestData.fleetIds,
              requestData.loadedDatetime,
              requestData.completedDatetime,
              requestData.processExecutionIds,
              requestData.showHidden,
              MAX_GRAPH_PROCESSES,
              requestData.tagsIds,
              () => setAnalyticsLoading((prevState) => ({ ...prevState, exceptionProcessesAnalyticsLoading: false })),
              () => setAnalyticsLoading((prevState) => ({ ...prevState, exceptionProcessesAnalyticsLoading: false })),
          ),
      );
      dispatch(
          fetchItemExceptionsAnalyticsKpiByReason(
              requestData.processes,
              requestData.exceptions,
              requestData.exceptionsType,
              requestData.fleetIds,
              requestData.loadedDatetime,
              requestData.completedDatetime,
              requestData.processExecutionIds,
              requestData.showHidden,
              requestData.tagsIds,
              () => setAnalyticsLoading((prevState) => ({ ...prevState, exceptionReasonsAnalyticsLoading: false })),
              () => setAnalyticsLoading((prevState) => ({ ...prevState, exceptionReasonsAnalyticsLoading: false })),
          ),
      );
      dispatch(
          fetchItemExceptionsAnalyticsKpiByType(
              requestData.processes,
              requestData.exceptions,
              requestData.exceptionsType,
              requestData.fleetIds,
              requestData.loadedDatetime,
              requestData.completedDatetime,
              requestData.processExecutionIds,
              requestData.showHidden,
              requestData.tagsIds,
              () => setAnalyticsLoading((prevState) => ({ ...prevState, exceptionTypeAnalyticsLoading: false })),
              () => setAnalyticsLoading((prevState) => ({ ...prevState, exceptionTypeAnalyticsLoading: false })),
          ),
      );
      dispatch(
          fetchItemExceptionsAnalyticsKpiByTrend(
              requestData.processes,
              requestData.exceptions,
              requestData.exceptionsType,
              requestData.fleetIds,
              requestData.loadedDatetime,
              requestData.completedDatetime,
              requestData.processExecutionIds,
              requestData.showHidden,
              requestData.tagsIds,
              () => setAnalyticsLoading((prevState) => ({ ...prevState, exceptionTrendsAnalyticsLoading: false })),
              () => setAnalyticsLoading((prevState) => ({ ...prevState, exceptionTrendsAnalyticsLoading: false })),
          ),
      );
    }
  }, [props.showAnalyticsContent, filterExceptionList]);

  const handleChangeSearchText = (value) => {
    dispatch(
      updateFilter({
        ...filterExceptionList,
        [exceptionCurrentModule.ITEMS]: {
          pageNo: 0,
          searchText: value,
        },
      }),
    );
  };

  const handleSetRetryItems = (items) => {
    setRetryDialogItems(items);
    setRetryDialogOpen(true);
    setItemsAreRetryable(!(items.some((element) => !(element.processDto.isRetryable))));
  };

  const handleSetWorkflowItem = (item) => {
    setWorkflowDrawerItem(item);
    setWorkflowDrawerOpen(true);
  };

  const resetCurrentUserFilter = () => {
    setCurrentUserIsSet(false);
    window.history.pushState("", "", "/items/itemsExceptions");
  };
  const handleChangeTag = (value) => {
    dispatch(
        updateFilter({
          ...filterExceptionList,
          [exceptionCurrentModule.ITEMS]: {
            pageNo: 0,
            tags: value
          },
        }),
    );
  }

  const renderContent = () => {
    if (props.isLoading || !data) {
      return <CircularLoader />;
    }
    if (data && data.length && !props.isLoading) {
      return (
        <ExceptionsContent
          data={data}
          getDate={props.getDate}
          getDateDifference={props.getDateDifference}
          handleShowExceptionDetails={handleShowExceptionDetails}
          deleteException={deleteException}
          isSelected={props.isSelected}
          selected={props.selected}
          setSelected={props.setSelected}
          handleClick={props.handleClick}
          onClickRow={onClickRow}
          deletePermission={deletePermission}
          isItemList
          endDateLabel={t("Exception date")}
          setRetryItems={handleSetRetryItems}
          setWorkflowItem={handleSetWorkflowItem}
          setOriginalItemId={setOriginalItemId}
        />
      );
    }
    return (
      <DataNotFound
        message={t("no.items.exception.message")}
      />
    );
  };

  const resetSelected = () => {
    props.setSelected([]);
  };

  return (
    <Grid
      container
      spacing={4}
      alignItems="stretch"
      className={classes.rootGlobal}
      ref={containerRef}
    >
      <PageHeader title="exceptions.item.tab.title" />
      <Grid container xs={12} direction="row" justify="space-between">
        <Grid container xs={12}>
          <Filter
            indeterminateCheck={
              data?.length > 0
              && data.some((d) => props.selected.includes(d.id))
            }
            checkedAll={
              data?.length > 0
              && data.every((d) => props.selected.includes(d.id))
            }
            setOriginalItemId={setOriginalItemId}
            selected={props.selected}
            classes={classes}
            processes={processes}
            exceptionsType={exceptionsType}
            exceptions={exceptions}
            handleChangeException={handleChangeException}
            handleChangeTag={handleChangeTag}
            handleChangeExceptionType={handleChangeExceptionType}
            handleChangeProcess={handleChangeProcess}
            handleChangeDivision={handleChangeDivision}
            handleRequestSort={handleRequestSort}
            handleChangeFleet={handleChangeFleet}
            handleChangeDates={handleChangeDates}
            onDelete={deleteExceptions}
            onShow={showExceptions}
            numSelected={props.selected.length}
            onSelectAllClick={handleSelectAllClick}
            rowCount={data?.length}
            exceptionTypeName={[]}
            exceptionName={[]}
            processName={processName}
            exportExceptionXLSX={exportItemExceptionXLSX}
            deletePermission={deletePermission}
            exportLoading={props.exportLoading}
            setExportLoading={props.setExportLoading}
            showAnalyticsContent={props.showAnalyticsContent}
            setShowAnalyticsContent={props.setShowAnalyticsContent}
            executionStartTime={loadedDatetime}
            executionEndTime={completedDatetime}
            exceptionWorkFlowStatus={exceptionWorkFlowStatus}
            handleChangeExceptionWorkflowStatus={
              handleChangeExceptionWorkflowStatus
            }
            sortColumns={sortColumns}
            handleChangeShowHidden={handleChangeShowHidden}
            showHidden={showHidden}
            handleChangeSearchText={handleChangeSearchText}
            setProcess={setProcess}
            process={process}
            exceptionsToShow={props.exceptionsToShow}
            exceptionsToHide={props.exceptionsToHide}
            durations={props.durations}
            setRetryItems={() => handleSetRetryItems(
              data?.filter((i) => props.selected.includes(i.id)),
            )}
            isItemList
            openWorkflowBulkDrawer={() => setWorkflowBulkDrawerOpen(true)}
            currModule={exceptionCurrentModule.ITEMS}
            assigners={assigners}
            handleChangeExceptionAssigners={handleChangeExceptionAssigners}
            currentUserSet={currentUserIsSet}
            resetCurrentUserFilter={resetCurrentUserFilter}
            setExportConfirmationOpen={setExportConfirmationOpen}
            selectedExceptionWorkFlowStatusFilter={selectedExceptionWorkFlowStatusFilter}
            setSelectedExceptionWorkFlowStatusFilter={setSelectedExceptionWorkFlowStatusFilter}
          />
        </Grid>
      </Grid>
      {props.showAnalyticsContent && kpiDataValues && (
      <Grid item container xs={12}>
        <Analytics
              kpiData={kpiData}
              containerRef={containerRef}
              analyticsLoading={analyticsLoading}
            />
      </Grid>
        )}
      { renderContent() }
      <Grid container xs={12} direction="row" justify="flex-end">
        { data?.length > 0 && <CustomPagination
          rowsPerPageOptions={[5, 10, 25]}
          count={count ?? 0}
          rowsPerPage={exceptionFilters.pageSize}
          page={exceptionFilters.pageNo}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          onNext={handleNext}
          onPrevious={handlePrevious}
        />}
      </Grid>
      {openMsgDetail && (
        <Details
          handleClose={closeDialog}
          logDetail={props.logDetail}
          data={props.detail}
          validate={props.validate}
          openStart={openMsgDetail}
          onCancel={cancelConfirm}
          onConfirm={confirmDelete}
        />
      )}
      {props.openMsgConfirm && (
        <ConfirmMessage
          message={props.messageConfirm}
          openStart={props.openMsgConfirm}
          onCancel={cancelConfirm}
          onConfirm={confirmDelete}
          buttonConfirm={deleteButtonContent}
          buttonCancel={t("Cancel")}
          isDelete="true"
          isLoading={props.isLoadingConfirm}
        />
      )}
      <RetryDialog
        open={retryDialogOpen}
        onClose={() => setRetryDialogOpen(false)}
        items={retryDialogItems}
        processIsRetryable={itemsAreRetryable}
        // The key ensures dialog state is cleared
        key={`retry-items-${retryDialogItems.reduce((k, i) => k + i.id, 0)}`}
        resetSelected={resetSelected}
        fetchData={fetchData}
      />
      <WorkflowDrawer
        open={workflowDrawerOpen}
        onClose={() => setWorkflowDrawerOpen(false)}
        item={workflowDrawerItem}
        setItem={setWorkflowDrawerItem}
        data={data}
        fetchData={fetchData}
        handleShowExceptionDetails={handleShowExceptionDetails}
        updateAssigners={updateAssigners}
        key={`workflow-drawer-${workflowDrawerItem?.id}`}
        setSelected={props.setSelected}
      />
      <WorkflowBulkDrawer
        open={workflowBulkDrawerOpen}
        onClose={() => setWorkflowBulkDrawerOpen(false)}
        items={checkedItems}
        fetchData={fetchData}
        updateAssigners={updateAssigners}
        // Multiplication by 2 prevents having the same key for RetryDialog, WorkflowDrawer and WorkflowBulkDrawer
        // when only one item is selected
        key={props.selected.reduce((k, i) => 2 * (k + i), 0)}
        resetSelected={resetSelected}
      />
      <ExportConfirmation
        setExportLoading={props.setExportLoading}
        exportConfirmationOpen={exportConfirmationOpen}
        setExportConfirmationOpen={setExportConfirmationOpen}
        setSeparateDetails={setSeparateDetails}
        onExportConfirm={onExportConfirm}
        selectedProcess={exceptionFilters.process.length}
        fetchedProcess={processFilter?.length} />
    </Grid>
  );
}

const mapDispatchToProps = {
  fetchExceptions,
  fetchProcessesNameException,
  fetchExceptionsReason,
  fetchExceptionsType,
  deleteException,
  deleteExceptions,
  showExceptions,
  fetchExceptionByAssigner,
  fetchExceptionsCount
};
export default connect(null, mapDispatchToProps)(ItemExceptions);
