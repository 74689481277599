import React, { forwardRef } from "react";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles((theme) => ({
  switchBase: {
    color: theme.palette.background.default,
    "&$checked": {
      color: theme.palette.background.default,
    },
    "&$checked + $track": {
      backgroundColor: theme.custom.color.chartColor2,
      opacity: 1
    },
    "&$disabled": {
      color: theme.palette.background.default,
    },
    "&$disabled + $track": {
      backgroundColor: theme.custom.color.chartColor4,
      opacity: 1
    }
  },
  checked: {},
  track: {
    backgroundColor: theme.custom.color.color7,
  },
  root: {
    width: "72px",
    height: "40px",
  },
  rootSmall: {
  },
  thumb: {
    width: "22px",
    height: "22px",
  },
  thumbSmall: {

  },
  thumbChecked: {
    marginLeft: "12px",
  },
  disabled: {},
  containerSmall: {
    width: "45px"
  },
}));

const StyledSwitch = forwardRef(({ checked, ...props }, ref) => {
  const classes = useStyles();
  return (
    <Box textAlign="flex-end" className={props.small ? classes.containerSmall : ""}>
      <Switch
          ref={ref}
          classes={{
          root: props.small ? "" : classes.root,
          switchBase: classes.switchBase,
          checked: classes.checked,
          /* eslint-disable-next-line no-nested-ternary */
          thumb: checked ? props.small ? "" : classes.thumbChecked : props.small ? "" : classes.thumb,
          track: classes.track,
          disabled: classes.disabled,
        }}
          checked={checked}
          {...props}
      />
    </Box>
  );
});

const CustomSwitch = forwardRef(({
 checked, handleChange, name, label, labelPlacement, ...rest
}, ref) => (
  <FormControlLabel
    label={label}
    labelPlacement={labelPlacement}
    control={<StyledSwitch ref={ref} {...rest} checked={checked} onChange={handleChange} name={name} />}
  />
));

export default CustomSwitch;
