import React, { useEffect, useState } from "react";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";
import Timer from "assets/NewDashboardIcons/timer.svg";
import useStyles from "../../style";
import { useDispatch } from "react-redux";
import renderValue, { formatAverageHandlingTime, renderContent } from "../../dashboardUtils";
import { fetchReducedFleet } from "redux/actions/services";
import { useQuery } from "@redux-requests/react";
import { FETCH_CURRENT_USER } from "redux/constants";

const AvgHandlingTime = ({ avgHandlingTime, isLoading, fillContainer }) => {
  const classes = useStyles()();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [userFleet, setUserFleet] = useState();
  const currentUser = useQuery({ type: FETCH_CURRENT_USER })?.data;

  useEffect(() => {
    dispatch(fetchReducedFleet(currentUser?.fleet?.id)).then((res) => {
       setUserFleet(res.data);
    })
  }, [dispatch]);

    const Component = () => (
      <Grid container direction={fillContainer ? "column" : "row"} item justify="space-around" alignItems="center">
        <Grid item xs={fillContainer ? 12 : 3}>
          <img src={Timer} alt="Timer" className={fillContainer ? classes.timerImageFillContainer : classes.timerImage} />
        </Grid>
        <Grid item xs={fillContainer ? 12 : 8}>
          <Typography className={classes.textBold}>
            {renderValue({
              value: avgHandlingTime,
              formatFct: formatAverageHandlingTime,
              md: userFleet?.hoursPerManDay,
              ftp: userFleet?.daysPerYear,
              t,
            })}
          </Typography>
        </Grid>
      </Grid>
    );

  return (
    <Box className={classes.cardContainer}>
      <CardHeader
        title={t("Average Handling Time")}
        classes={{
          title: classes.cardHeaderTitle,
        }}
      />
      <CardContent>
        { renderContent(isLoading, [avgHandlingTime], <Component />) }
      </CardContent>
    </Box>
  );
};

export default AvgHandlingTime;
