import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useFieldArray, useForm } from "react-hook-form";

import get from "lodash/get";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import ConfirmMessage from "../../../../components/ConfirmMessage";
// eslint-disable-next-line import/no-cycle
import CustomAlertInfo from "./CustomAlertInfo";
import {
  createCustomAlert,
  fetchCustomAlert,
  updateCustomAlert,
  resetCustomAlertScheduler,
  fetchUserProcesses,
  fetchFleetUsers,
} from "../../../../redux/actions/services";
import AlertsPageHeader from "../../AlertsPageHeader";
import useStyles from "./style";
import { toast } from "react-toastify";
import isEmpty from "lodash/isEmpty";

const menuItems = {
  whenMenuItem: [
    "Items exception rate",
    "Items success rate",
    "Number of completed items",
    "Number of items completed with exceptions",
    "Number of items with Business Exceptions",
    "Number of items with System/Internal Exception",
    "Number of pending items",
    "Number of process executions completed successfully",
    "Number of process executions with Business Exceptions",
    "Number of process executions with exceptions",
    "Number of process executions with System/Internal Exception",
    "Number of process executions",
    "Process execution duration",
    "Return on Investment",
    "Total time saved",
  ],
  operatorMenuItem: [
    "GREATER THAN",
    "LESS THAN",
    "GREATER OR EQUAL TO",
    "LESS OR EQUAL TO",
  ],
};

export const USE_CUMULATIVE = "USE_CUMULATIVE";

export const USE_INTERVALS = "USE_INTERVALS";

export default function CustomAlertForm() {
  const entityBaseUrl = "/alertsManagement/customAlerts";

  const classes = useStyles();

  const { t } = useTranslation();

  const history = useHistory();

  const dispatch = useDispatch();

  const { idAlert, mode = "add" } = useParams();

  const [customAlert, setCustomAlert] = React.useState({});

  const [openMsgConfirm, setOpenMsgConfirm] = React.useState(false);

  const [openMsgCancel, setOpenMsgCancel] = React.useState(false);

  const [isLoading, setIsLoading] = React.useState(false);

  const [endDate, setEndDate] = React.useState(null);

  const [startDate, setStartDate] = React.useState(null);

  const [frequency, setFrequency] = useState();

  const [unitType, setUnitType] = useState(null);

  const [useInterval, setUseInterval] = useState(USE_INTERVALS);

  const [submittedScheduler, setSubmittedScheduler] = useState({});

  const [processMenuItem, setProcessMenuItem] = useState(null);

  const [tabValue, setTabValue] = React.useState(0);

  const [open, setOpen] = React.useState(false);

  const [disableSchedulerInfo, setDisableSchedulerInfo] = React.useState(false);

  const [sameFleetUsers, setSameFleetUsers] = useState();

  const [subscribersIds, SetSubscribersIds] = useState();

  const [emailStatus, setEmailStatus] = useState(false);
  const [smsStatus, setSmsStatus] = useState(false);

  const [
    selectedSubscribers,
    setSelectedSubscribers,
  ] = React.useState([]);

  const handleClickOpen = (data) => {
    if (data === true || data === false) setDisableSchedulerInfo(data);
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    control,
    unregister,
    watch,
    trigger,
    getValues,
  } = useForm({
    defaultValues: {
      customRules: [{ when: "", operator: "", condition: "" }],
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "customRules",
  });

  const disableFields = !(mode === "add" || mode === "edit");

  const showDefaultData = mode !== "add";

  // eslint-disable-next-line no-nested-ternary
  const headerTitle = mode === "add"
    ? "alerts.addHeader"
    : mode === "edit"
      ? "alerts.editHeader"
      : "alerts.displayHeader";

  function setDateToTheStartOfDay(date) {
    const dt = new Date(date);
    dt.setHours(0);
    dt.setMinutes(0);
    dt.setSeconds(0);
    return dt;
  }

  const onSubmit = (data) => {
    if (!isEmpty(submittedScheduler))
    {
      setOpenMsgConfirm(true);
      let dataToSend = data;
      let alertSubscribersIds = selectedSubscribers.map((e) => e.id);
      // eslint-disable-next-line no-nested-ternary
      alertSubscribersIds = alertSubscribersIds?.length > 1
          ? alertSubscribersIds?.join(",")
          : alertSubscribersIds?.length === 1
              ? alertSubscribersIds?.join("")
              : "";
      dataToSend = {
        ...data,
        alertSubscribersIds,
        processId: data.process,
        emailStatus,
        smsStatus,
      };
      dataToSend = {
        ...dataToSend,
        schedulerUnitType: submittedScheduler.unitType,
        schedulerPeriod: submittedScheduler.frequency,
        schedulerEndDate:
            submittedScheduler.endDate
            && moment(setDateToTheStartOfDay(submittedScheduler.endDate)).format(
                "yyyy-MM-DDTHH:mm:ss",
            ),
        schedulerStartDate:
            submittedScheduler.startDate
            && moment(setDateToTheStartOfDay(submittedScheduler.startDate)).format(
                "yyyy-MM-DDTHH:mm:ss",
            ),
        schedulerUseInterval: submittedScheduler.useInterval,
      };

      dataToSend = {
        ...dataToSend,
        fleetId: currentUser?.fleet?.id,
        type: "CUSTOM"
      }
      setCustomAlert(dataToSend);
    }
  };

  const confirmSave = () => {
    setIsLoading(true);
    if (mode === "add") {
      dispatch(
        createCustomAlert(customAlert, (res) => {
          if (res?.status === 200) {
            history.push({
              pathname: entityBaseUrl,
            });
            toast.success(t("alert.management.formControl.saveSuccess"))
          }
        }),
      )
        .then((res) => {
          if (res.response.status !== "OK") {
            setOpenMsgConfirm(false);
            setIsLoading(false);
            toast.error(t(res.response?.data?.message))
          }
        })
        .catch(() => {});
    } else {
      dispatch(
        updateCustomAlert(idAlert, customAlert, (res) => {
          if (res?.status === 200) {
            history.push({
              pathname: entityBaseUrl,
            });
            toast.success(t("alert.management.formControl.updateSuccess"))
          }
        }),
      )
        .then((res) => {
          if (res.response.status !== "OK") {
            setOpenMsgConfirm(false);
            setIsLoading(false);
            toast.error(t(res.response?.data?.message))
          }
        })
        .catch(() => {});
    }
  };

  const resetScheduler = () => {
    setFrequency(0.5);
    setEndDate(null);
    setStartDate(null);
    setUnitType(1);
    setTabValue(0);
    setUseInterval(USE_INTERVALS);

    unregister("alertScheduler.period");
    unregister("alertScheduler.endDate");
    unregister("alertScheduler.startDate");
    unregister("alertScheduler.useInterval");

    setSubmittedScheduler({});
  };

  const currentUser = useSelector(({ requests }) => get(requests, "queries.FETCH_CURRENT_USER.data"));

  const handleCancel = () => setOpenMsgCancel(true);

  const cancelConfirm = () => setOpenMsgConfirm(false);

  const handleRejectCancelForm = () => setOpenMsgCancel(false);

  const handleAcceptCancelForm = () => {
    setOpenMsgCancel(false);
    history.push(entityBaseUrl);
  };

  const handleChangeEndDate = (value) => {
    setEndDate(value);
  };

  const handleChangeStartDate = (value) => {
    setStartDate(value);
  };

  const handleChangeFrequency = (event) => {
    setFrequency(event.target.value);
  };

  const handleSubscribersChange = (event) => {
    const value = event?.target?.value || event;
    setSelectedSubscribers(value);
    setValue("sub", value);
  }

  const handleChangeUseInterval = (event) => {
    setUseInterval(event.target.value);
  };

  const handleDeleteScheduler = () => {
    setIsLoading(true);
    resetScheduler();
  };

  const confirmSaveScheduler = () => {
    setValue("alertScheduler.period", frequency || 0.5);
    setValue("alertScheduler.endDate", endDate);
    setValue("alertScheduler.startDate", startDate);
    setValue("alertScheduler.useInterval", useInterval);

    setSubmittedScheduler({
      frequency: frequency || 0.5,
      endDate,
      startDate,
      useInterval,
      unitType: unitType || 1,
    });

    toast.success(showDefaultData ? t("Schedule modified successfully") : t("Schedule saved successfully"))
  };

  const discardSchedulerChanges = () => {
    setFrequency(
      submittedScheduler.frequency ? submittedScheduler.frequency : 0.5,
    );
    setEndDate(submittedScheduler.endDate);
    setStartDate(submittedScheduler.startDate);
    setUseInterval(
      submittedScheduler.useInterval
        ? submittedScheduler.useInterval
        : USE_INTERVALS,
    );
    setUnitType(submittedScheduler.unitType ? submittedScheduler.unitType : 1);
    setTabValue(
      submittedScheduler.unitType - 1 ? submittedScheduler.unitType - 1 : 0,
    );
    toast.success(t("Changes disregarded"))
  };

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
    setUnitType(newValue + 1);
    if (newValue === 0) {
      setFrequency(0.5);
      setValue("alertScheduler.period", 0.5);
    } else {
      setFrequency(1);
      setValue("alertScheduler.period", 1);
    }
  };

  useEffect(() => {
    currentUser?.fleet?.id
      && dispatch(fetchUserProcesses(currentUser?.id)).then((res) => setProcessMenuItem(res.data?.list?.filter((item) => !item.isDisabled)));
  }, [currentUser]);

  useEffect(() => {
    dispatch(fetchFleetUsers(currentUser?.fleet?.id)).then((result) => {
      setSameFleetUsers(result.data);
      const selectedUsers = result?.data?.filter((e) => subscribersIds?.includes(e?.id.toString()))
      setSelectedSubscribers(selectedUsers);
    });
  }, [subscribersIds]);

  useEffect(() => {
    if (mode !== "add") {
      dispatch(fetchCustomAlert(idAlert)).then((res) => {
        const userIds = !(res?.data?.alertSubscribersIds?.split(",")) ? [] : (res?.data?.alertSubscribersIds?.split(","));
        SetSubscribersIds(userIds);
        setValue("alertName", res?.data?.alertName);
        setValue("alertDescription", res?.data?.alertDescription);
        setValue("flag", res?.data?.flag);
        setValue("status", res?.data?.status);
        setValue("sub", userIds);
        setSmsStatus(res?.data?.smsStatus)
        setEmailStatus(res?.data?.emailStatus)
        const customRules = res?.data?.customRules;
        for (let i = 0; i < customRules.length - 1; i += 1) {
          append({
            when: "",
            operator: "",
            condition: "",
          });
        }
        for (let i = 0; i < customRules.length; i += 1) {
          setValue(`customRules[${i}].when`, customRules[i]?.when);
          setValue(`customRules[${i}].operator`, customRules[i]?.operator);
          setValue(`customRules[${i}].condition`, customRules[i]?.condition);
        }
        setValue("process", customRules.length > 0 ? res?.data?.process : "");
        setUnitType(
          res?.data?.schedulerUnitType == null
            ? 1
            : res?.data?.schedulerUnitType,
        );
        setTabValue(
          res?.data?.schedulerUnitType == null
            ? 0
            // eslint-disable-next-line no-unsafe-optional-chaining
            : res?.data?.schedulerUnitType - 1,
        );
        res?.data?.schedulerPeriod
          && setValue("alertScheduler.period", res?.data?.schedulerPeriod);
        setFrequency(res?.data?.schedulerPeriod);
        res?.data?.schedulerEndDate
          && setValue("alertScheduler.endDate", res?.data?.schedulerEndDate);
        setEndDate(res?.data?.schedulerEndDate);
        res?.data?.schedulerStartDate
          && setValue(
            "alertScheduler.startDate",
            setDateToTheStartOfDay(res?.data?.schedulerStartDate),
          );
        setStartDate(setDateToTheStartOfDay(res?.data?.schedulerStartDate));
        res?.data?.schedulerUseInterval
          && setValue(
            "alertScheduler.useInterval",
            res?.data?.schedulerUseInterval,
          );
        setUseInterval(
          res?.data?.schedulerUseInterval
            ? res?.data?.schedulerUseInterval
            : USE_INTERVALS,
        );
        setSubmittedScheduler({
          unitType: res?.data?.schedulerUnitType,
          frequency: res?.data?.schedulerPeriod,
          endDate: res?.data?.schedulerEndDate,
          startDate: res?.data?.schedulerStartDate,
          useInterval: res?.data?.schedulerUseInterval,
        });
      });
    }
  }, []);

  return (
    <div className={classes.root}>
      <AlertsPageHeader title={t(headerTitle)} />

      <form className={classes.fleetForm} onSubmit={handleSubmit(onSubmit)}>
        <CustomAlertInfo
          trigger={trigger}
          classes={classes}
          register={register}
          unregister={unregister}
          control={control}
          disableFields={disableFields}
          errors={errors}
          setValue={setValue}
          clearErrors={clearErrors}
          showDefaultData={showDefaultData}
          fields={fields}
          append={append}
          remove={remove}
          menuItems={menuItems}
          idAlert={idAlert}
          handleCancel={handleCancel}
          handleChangeEndDate={handleChangeEndDate}
          handleChangeStartDate={handleChangeStartDate}
          endDate={endDate}
          startDate={startDate}
          useInterval={useInterval}
          watch={watch}
          open={open}
          handleClickOpen={handleClickOpen}
          handleCloseDialog={handleCloseDialog}
          handleChangeFrequency={handleChangeFrequency}
          handleChangeUseInterval={handleChangeUseInterval}
          discardSchedulerChanges={discardSchedulerChanges}
          frequency={frequency}
          mode={mode}
          handleChangeTab={handleChangeTab}
          value={tabValue}
          confirmSaveScheduler={confirmSaveScheduler}
          processMenuItem={processMenuItem}
          submittedScheduler={submittedScheduler}
          handleDeleteScheduler={handleDeleteScheduler}
          resetCustomAlertScheduler={resetCustomAlertScheduler}
          isLoading={isLoading}
          disableSchedulerInfo={disableSchedulerInfo}
          entityBaseUrl={entityBaseUrl}
          sameFleetUsers={sameFleetUsers}
          selectedSubscribers={selectedSubscribers}
          setSameFleetUsers={setSameFleetUsers}
          handleSubscribersChange={handleSubscribersChange}
          getValues={getValues}
          smsStatus={smsStatus}
          emailStatus={emailStatus}
          setSmsStatus={setSmsStatus}
          setEmailStatus={setEmailStatus}
        />
      </form>
      {openMsgConfirm && (
        <ConfirmMessage
          message={
            idAlert
              ? t("alerts.management.update.confirmMsg")
              : t("alerts.management.save.confirmMsg")
          }
          openStart={openMsgConfirm}
          onCancel={cancelConfirm}
          onConfirm={confirmSave}
          buttonConfirm={
            idAlert
              ? t("alerts.management.button.update")
              : t("alerts.management.button.save")
          }
          buttonCancel={t("alerts.management.button.cancel")}
          isLoading={isLoading}
        />
      )}
      {openMsgCancel && (
        <ConfirmMessage
          message={t("alerts.management.delete.discard")}
          openStart={openMsgCancel}
          onCancel={handleRejectCancelForm}
          onConfirm={handleAcceptCancelForm}
          buttonConfirm={t("alerts.management.button.discard")}
          buttonCancel={t("alerts.management.button.cancel")}
        />
      )}
    </div>
  );
}
