import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import get from "lodash/get";
import {
  createUserPermissionsTemplate,
  fetchUserPermissionsTemplates,
} from "../../../../redux/actions/services";
import { toast } from "react-toastify";
import CustomDialog from "pages/Services/components/CustomDialog";
import CustomButton from "../../../../components/CustomButton";

const CreateTemplateForm = ({
  dispatch,
  row,
  permissions,
  t,
  setOpenSavePermissionAsTemplate,
  openSavePermissionAsTemplate,
}) => {
  const [templateError, setTemplateError] = React.useState(false);
  const [templateName, setTemplateName] = React.useState("");
  const [isLoading, setIsLoading] = React.useState("");

  const handleCloseCreateTemplateForm = () => {
    setOpenSavePermissionAsTemplate(!openSavePermissionAsTemplate);
    setTemplateError(false);
    setTemplateName("");
  };

  const handleTemplateNameChange = (e) => {
    setTemplateName(e.target.value);
  };

  const handleCreateUserPermissionTemplate = () => {
    if (!templateName || !templateName.trim()) {
      setTemplateError(true);
      return;
    }
    setIsLoading(true);
    dispatch(
      createUserPermissionsTemplate(
        get(row, "id"),
        templateName,
        JSON.stringify(permissions),
      ),
    ).then((res) => {
      setIsLoading(false);
      if (res.status === 200) {
        toast.success(t("user.permission.templateCreate.success"))
        setOpenSavePermissionAsTemplate(!openSavePermissionAsTemplate);
        setTemplateError(false);
        setTemplateName("");
        dispatch(fetchUserPermissionsTemplates());
      } else {
        toast.success(t("user.permission.templateCreate.error"))
      }
    });
  };

  return (
    <CustomDialog
      fullWidth
      maxWidth="xs"
      open={openSavePermissionAsTemplate}
      onClose={handleCloseCreateTemplateForm}
    >
      <DialogTitle>{t("user.permission.saveAsTemplaceTitle")}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label={`${t("user.permissions.template.name")}*`}
          type="email"
          fullWidth
          variant="standard"
          onChange={handleTemplateNameChange}
          value={templateName}
        />
        {templateError && <p>{t("Required")}</p>}
      </DialogContent>
      <DialogActions>
        <CustomButton onClick={handleCloseCreateTemplateForm} variant="outlined">
          {t("Cancel")}
        </CustomButton>
        <CustomButton
          view="primary"
          onClick={handleCreateUserPermissionTemplate}
          disabled={isLoading}
        >
          {isLoading && <CircularProgress size={14} />}
&nbsp;
          {t("user.permissions.template.createBtn")}
        </CustomButton>
      </DialogActions>
    </CustomDialog>
  );
};

export default CreateTemplateForm;
