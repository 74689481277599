import React from "react";
import { useSelector } from "react-redux";
import { get } from "lodash";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";
import { FETCH_ORCHESTRATORS } from "../../../redux/constants/index";
import {
  fetchOrchestrators,
  deleteOrchestrator,
  enableOrDisableOchestrator,
  deleteOrchestrators,
} from "../../../redux/actions/services/index";
import AdminTable from "../AdminTable";
import StatusLegend from "components/StatusLegend";
import { isFleetAdministrator } from "../../../util";
import PageHeader from "../../../components/PageHeader";
import useStyles from "./style.js";

const headerCells = {
  fields: [
    {
      id: "status",
      gridSize: 1,
      align: "center",
      label: "orchestrator.management.status",
    },
    {
      id: "logo",
      gridSize: 1,
      align: "center",
      label: "",
    },
    {
      id: "name",
      gridSize: 4,
      align: "left",
      label: "orchestrator.management.name",
    },
    {
      id: "databaseServer",
      gridSize: 3,
      align: "left",
      label: "orchestrator.management.databaseServer",
    },
    {
      id: "sessionNumber",
      gridSize: 3,
      align: "center",
      label: "orchestrator.management.formControl.sessionNumber",
    },
  ],
};

const displayFields = [
  {
    id: "status",
    gridSize: 1,
    align: "center",
    valuePath: "status",
    isStatus: true,
    translate: false,
  },
  {
    id: "logo",
    gridSize: 1,
    align: "center",
    valuePath: "name",
    isAvatar: true,
  },
  {
    id: "name",
    gridSize: 4,
    align: "left",
    valuePath: "name",
  },
  {
    id: "databaseServer",
    gridSize: 3,
    align: "left",
    valuePath: "databaseServer",
  },
  {
    id: "sessionNumber",
    gridSize: 3,
    align: "center",
    valuePath: "sessionNumber",
    customRender: (row) => {
      const activeLicenses = row?.licenses?.filter((license) => license?.status === "ACTIVE");
      return activeLicenses?.reduce((total, license) => total + Number(license?.maxConcurrentSessions), 0);
    },
  },
];

const rowCustomActions = null;

const statusList = [
  { code: "ACTIVE", label: "ACTIVE" },
  { code: "DISABLE", label: "DISABLE" },
];

const sortByList = [
  { code: "name", label: "orchestrator.management.name" },
  {
    code: "sessionNumber",
    label: "orchestrator.management.formControl.sessionNumber",
  },
];

const labels = {
  title: "orchestrator.management.title",
  addButton: "orchestrator.management.add.button",
  deleteEntity: "orchestrator.management.delete.confirmMsg",
  deleteEntities: "orchestrator.management.deleteOrchestratorsActionMessage",
  enableEntity: "orchestrator.management.enaDes.confirmMsg",
  enableConfirm: "orchestrator.management.enaDes.button.confirm",
  disableEntity: "orchestrator.management.desaDes.confirmMsg",
  disableConfirm: "orchestrator.management.desaDes.button.confirm",
  searchPlaceHolder: "Search",
  confirmAction: "Delete",
  deleteAll: "tooltip.action.delete",
  entityDeleted: "orchestrator deleted successfully"
};
export default function OrchestratorManagement() {
  const entityBaseUrl = "/robotAdministration/orchestrators";
  const enableDisableEntity = enableOrDisableOchestrator;
  const fetchEntities = fetchOrchestrators;
  const deleteEntity = deleteOrchestrator;
  const deleteEntities = deleteOrchestrators;
  const entityQuery = FETCH_ORCHESTRATORS;
  const searchField = "searchCriteria";
  const classes = useStyles();
  const { t } = useTranslation();
  const statues = ["ACTIVE", "DISABLE"]
  const currentUser = useSelector(({ requests }) => get(requests, "queries.FETCH_CURRENT_USER.data"));

  return (
    <div className={classes.root}>
      <div className={classes.paper}>
        <PageHeader title={labels.title} />
        <Grid container>
          <Grid container item xs justify="center">
            <StatusLegend statues={statues} />
          </Grid>
        </Grid>
        <AdminTable
          statusList={statusList}
          sortByList={sortByList}
          displayFields={displayFields}
          headerCells={headerCells}
          entityBaseUrl={entityBaseUrl}
          enableDisableEntity={enableDisableEntity}
          fetchEntities={fetchEntities}
          deleteEntity={deleteEntity}
          deleteEntities={deleteEntities}
          entityQuery={entityQuery}
          labels={labels}
          searchField={searchField}
          rowCustomActions={rowCustomActions}
          isOrchestratorAdministrationPage
          showAddIcon={isFleetAdministrator(currentUser)}
          addButtonText={t(labels.addButton)}
        />
      </div>
    </div>
  );
}
