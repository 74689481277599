/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from "react";
import Tooltip from "@material-ui/core/Tooltip";
import CardActions from "@material-ui/core/CardActions";
import CustomSwitch from "../../../../Services/components/CustomSwitch";
import IconButton from "@material-ui/core/IconButton";
import Checkbox from "@material-ui/core/Checkbox";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import ButtonBase from "@material-ui/core/ButtonBase";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import EditIcon from "@material-ui/icons/Edit";
import TrashIcon from "@material-ui/icons/Delete";
import LaunchIcon from "@material-ui/icons/Launch";
import Replay from "@material-ui/icons/Replay";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import get from "lodash/get";
import { toast } from "react-toastify";
import { isFleetAdministrator } from "util";
import {
  ASK_DELETE_QUEUE,
  CONFIRM_DELETE_QUEUE,
  ASK_ENABLE_QUEUE,
  ASK_DISABLE_QUEUE,
  ASK_DELETE_ONE_QUEUE,
  ASK_DELETE_QUEUE_FROM_ONE,
  QUEUE_DELETED,
} from "util/constants";
import {
  openQueue,
  updateQueueAction,
  triggerQueueAction,
} from "redux/slices/executionManagerSlice.js";
import ConfirmMessage from "components/ConfirmMessage";
import QueueTableCell from "../QueueTableCell/index.js";
import { isActionPermitted } from "components/HasPermission/index.js";
import useStyles from "./style.js";

export default function QueueTableRow({
  row,
  displayFields,
  headerCells,
  index,
  selected,
  setSelected,
  entityBaseUrl,
  enableDisableEntity,
  resetEntity,
  fetchEntities,
  deleteEntity,
  enableLabel,
  disableLabel,
  enableConfirm,
  disableConfirm,
  deleteEntityLabel,
  deleteEntityTooltip,
  restoreEntityTooltip,
  fetchEntitiesReset,
  showStatusSwitch,
  enableDeleteMultiple,
  entityDeleted,
  enableEdit,
  enableDelete,
  preDeleteEntity,
  statusField = "status",
}) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const active = get(row, statusField) === "ACTIVE";
  const [openConfirmationPopup, setopenConfirmationPopup] = useState(false);
  const [openMsgConfirm, setOpenMsgConfirm] = useState(false);
  const [toDeleteId, setToDeleteId] = useState(null);
  const [isLoading, setIsloading] = useState({
    switch: false,
    deleteAction: false,
    archive: false,
  });
  const { queueAction, processesAction, entityId } = useSelector(
    ({ executionManager }) => executionManager
  );
  useEffect(() => {
    if (!processesAction && queueAction === CONFIRM_DELETE_QUEUE) {
      dispatch(deleteEntity(toDeleteId, processesAction, onSuccessDeleteQueue));
    } else {
      handleQueueActions();
    }
  }, [queueAction]);
  const handleQueueActions = () => {
    if (queueAction && entityId === get(row, "id")) {
      const actions = {
        CONFIRM_DELETE_QUEUE: {
          action: onDeleteQueueEntity,
        },
        ASK_DELETE_ONE_QUEUE: {
          action: confirmDelete,
        },
        CONFIRM_DISABLE_QUEUE: {
          action: onUpdateQueueStatus,
        },
        CONFIRM_ENABLE_QUEUE: {
          action: onUpdateQueueStatus,
        },
        CANCEL_QUEUE_ACTIONS: {
          action: onCancelStatus,
        },
      };
      actions[queueAction]?.action();
    }
  };
  const onCancelStatus = () => {
    onCancel();
    cancelConfirm();
    dispatch(
      triggerQueueAction({
        queueAction: null,
        processesAction: [],
        id: null,
      })
    );
  };

  const resetLoaders = () => setIsloading({ switch: false, deleteAction: false, archive: false });

  const handleChangeStatus = () => {
    const id = get(row, "id");
    const orchId = row?.orchestrator?.id;

    dispatch(
      triggerQueueAction({
        queueAction: active ? ASK_DISABLE_QUEUE : ASK_ENABLE_QUEUE,
        id,
        orchId,
      })
    );
  };

  const cancelConfirm = () => {
    resetLoaders();
    setOpenMsgConfirm(false);
  };

  const confirmDelete = () => {
    if (queueAction === ASK_DELETE_ONE_QUEUE) {
      dispatch(updateQueueAction({ queueAction: ASK_DELETE_QUEUE_FROM_ONE }));
    } else {
      // Déclencher l'action au parent (QueueManagement)
      const orchId = row?.orchestrator?.id;
      dispatch(
        triggerQueueAction({
          queueAction: ASK_DELETE_QUEUE,
          id: toDeleteId,
          orchId,
        })
      );
    }
  };

  const onDeleteQueueEntity = () => {
    setIsloading({ ...isLoading, deleteAction: true });
    dispatch(
      preDeleteEntity(entityId, processesAction, onSuccessPreDeleteQueue)
    );
  };

  const onSuccessPreDeleteQueue = (res) => {
    if (res?.data === true) {
      dispatch(deleteEntity(entityId, processesAction, onSuccessDeleteQueue));
    }
  };

  const onSuccessDeleteQueue = (res) => {
    if (res?.status === 200) {
      toast.success(t(entityDeleted));
      terminateQueueDeleteAction();
      fetchEntitiesReset();
    } else {
      toast.error(t(res?.response?.data?.detail || "Unexpected Error"));
      terminateQueueDeleteAction();
    }
    resetLoaders();
    setOpenMsgConfirm(false);
  };
  const terminateQueueDeleteAction = () => {
    dispatch(
        triggerQueueAction({
          queueAction: QUEUE_DELETED,
          processesAction: [],
          id: null,
        })
    );
  }

  const onUpdateQueueStatus = () => {
    const id = get(row, "id");
    setIsloading({ ...isLoading, switch: true });
    dispatch(preDeleteEntity(id, processesAction)).then((res) => {
      if (res?.status === 200 && res?.data === true) {
        dispatch(
          enableDisableEntity(
            id,
            active ? "DISABLE" : "ACTIVE",
            processesAction
          )
        ).then((err) => {
          resetLoaders();
          if (err?.response?.data?.detail) {
            toast.error(t(err?.response?.data?.detail));
          } else {
            fetchEntities();
            dispatch(
              triggerQueueAction({
                queueAction: null,
                processesAction: [],
                id: null,
              })
            );
          }
          setopenConfirmationPopup(false);
        });
      }
    });
  };

  const onCancel = () => {
    resetLoaders();
    setopenConfirmationPopup(false);
  };

  const currentUser = useSelector(({ requests }) => get(requests, "queries.FETCH_CURRENT_USER.data"));
  const isCurrentUser = Number(currentUser?.id) === row?.id;

  const isRobotActive = row?.isActive === "ACTIVE";
  const deleteMsg = typeof deleteEntityLabel === "string"
      ? t(deleteEntityLabel)
      : deleteEntityLabel;
  const IconPlaceHolder = () => (
    <Grid item className={classes.rowActionButtons}>
      &nbsp;
    </Grid>
  );

  return (
    <>
      <Card key={index} className={classes.root}>
        <ButtonBase
          focusRipple
          onClick={() => history.push(`${entityBaseUrl}/view/${get(row, "id")}`)}
          className={classes.menuItemDivider}
        >
          <CardContent className={classes.content}>
            <Grid container alignItems="center" item xs={12}>
              <Grid container alignItems="center" item xs={12}>
                <div className={classes.viewClickContainer}>
                  <Grid container>
                    <Grid container item justify="flex-start">
                      {headerCells.fields.map((cell, index) => (
                        <Grid
                          key={index}
                          item
                          xs={cell.gridSize}
                          className={classes.ColumnName}>
                          <Typography
                            component="div"
                            variant="subtitle2"
                            align="left"
                            color="secondary"
                          >
                            <Box marginLeft="0" textAlign="center">
                              {t(cell.label)}
                            </Box>
                          </Typography>
                        </Grid>
                      ))}
                    </Grid>
                    <Grid
                      container
                      item
                      justify="flex-start"
                      alignItems="center"
                    >
                      {displayFields.map((field, index1) => (
                        <QueueTableCell key={index1} field={field} row={row} />
                      ))}
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </CardContent>

          <CardActions className={classes.rowActions}>
            <Grid container xs={12} justify="flex-end" alignItems="center">
              {isFleetAdministrator(currentUser) && (
                <Grid item>
                  <Tooltip
                    placement="top"
                    title={t("queue.management.open-queue")}
                  >
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        dispatch(openQueue(row.id));
                      }}
                    >
                      <LaunchIcon className={classes.defaultIconColor} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    placement="top"
                    title={t("queue.management.reset-queue")}
                  >
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        resetEntity(row.id);
                      }}
                    >
                      <Replay className={classes.defaultIconColor} />
                    </IconButton>
                  </Tooltip>
                </Grid>
              )}
              {(isActionPermitted(currentUser, false, "Edit user")
                || isActionPermitted(currentUser, false, "Edit division")
                || isFleetAdministrator(currentUser))
              && showStatusSwitch ? (
                <Grid item>
                  <Tooltip
                    placement="top"
                    title={t(`tooltip.action.${active ? "disable" : "enable"}`)}
                  >
                    <CustomSwitch
                      checked={active}
                      onMouseDown={(e) => e.stopPropagation()}
                      onClick={(e) => e.stopPropagation()}
                      handleChange={() => {
                        setopenConfirmationPopup(true);
                      }}
                      disabled={isCurrentUser}
                      value={active}
                      name="checkedB"
                      inputProps={{ "aria-label": "primary checkbox" }}
                      small
                    />
                  </Tooltip>
                </Grid>
              ) : (
                <IconPlaceHolder />
              )}

              {enableEdit
              && (isFleetAdministrator(currentUser)
                || isActionPermitted(currentUser, true, "Edit SR Queue")) ? (
                  <Grid item>
                    <Tooltip placement="top" title={t("tooltip.action.edit")}>
                      <IconButton
                      className={classes.rowActionButtons}
                      aria-label="edit"
                      onMouseDown={(e) => e.stopPropagation()}
                      onClick={(e) => {
                        e.stopPropagation();
                        history.push(`${entityBaseUrl}/edit/${get(row, "id")}`);
                      }}
                    >
                        <EditIcon className={classes.defaultIconColor} />
                      </IconButton>
                    </Tooltip>
                  </Grid>
              ) : (
                <IconPlaceHolder />
              )}
              {}

              {enableDelete
              && (isFleetAdministrator(currentUser)
                || isActionPermitted(currentUser, true, "Delete SR Queue")) ? (
                  <Grid item>
                    <Tooltip
                    placement="top"
                    title={
                      isRobotActive
                        ? t(deleteEntityTooltip)
                        : t(restoreEntityTooltip)
                    }
                  >
                      <IconButton
                      className={classes.rowActionButtons}
                      disabled={isCurrentUser}
                      aria-label="delete"
                      onMouseDown={(e) => e.stopPropagation()}
                      onClick={(e) => {
                        e.stopPropagation();
                        setToDeleteId(get(row, "id"));
                        setOpenMsgConfirm(true);
                      }}
                    >
                        <TrashIcon
                        className={
                          currentUser?.id === row?.id ? "" : classes.deleteColor
                        }
                      />
                      </IconButton>
                    </Tooltip>
                  </Grid>
              ) : (
                <IconPlaceHolder />
              )}
              {enableDeleteMultiple ? (
                isFleetAdministrator(currentUser) ? (
                  <Grid item>
                    <Checkbox
                      className={[
                        classes.rowActionButtons,
                        classes.defaultIconColor,
                      ]}
                      checked={
                        currentUser?.id !== row?.id
                        && selected.includes(parseInt(row.id, 10))
                      }
                      disabled={isCurrentUser}
                      onMouseDown={(e) => e.stopPropagation()}
                      onClick={(e) => e.stopPropagation()}
                      onChange={(e) => {
                        const list = [...selected];
                        // eslint-disable-next-line
                        e.target.checked
                          ? list.push(parseInt(get(row, "id"), 10))
                          : list.splice(
                              list.indexOf(parseInt(get(row, "id"), 10)),
                              1
                            );
                        setSelected(list);
                      }}
                    />
                  </Grid>
                ) : null
              ) : null}
            </Grid>
          </CardActions>
        </ButtonBase>
      </Card>
      <ConfirmMessage
        message={active ? t(disableLabel) : t(enableLabel)}
        openStart={openConfirmationPopup}
        onCancel={onCancel}
        onConfirm={handleChangeStatus}
        buttonConfirm={active ? t(disableConfirm) : t(enableConfirm)}
        buttonCancel={t("Cancel")}
        isLoading={isLoading.switch}
      />

      <ConfirmMessage
        message={deleteMsg}
        openStart={openMsgConfirm}
        onCancel={cancelConfirm}
        onConfirm={confirmDelete}
        buttonConfirm={t("Delete")}
        buttonCancel={t("Cancel")}
        isDelete="true"
        isLoading={isLoading.deleteAction}
      />
    </>
  );
}
