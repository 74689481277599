import {
  ADD_DIVISION,
  DELETE_DIVISION_BY_ID,
  DELETE_LIST_DIVISIONS,
  FETCH_DIVISION,
  FETCH_DIVISION_BY_FLEET_ID,
  FETCH_DIVISION_BY_ID, FETCH_DIVISION_FOR_FILTERS, FETCH_DIVISIONS_BY_USER,
  FETCH_PROCESSES_LIST_BY_DIVISION_AND_USER,
  FETCH_PROCESSES_LIST_BY_DIVISIONS,
  FETCH_PROCESSES_LIST_BY_FLEET,
  UPDATE_DIVISION,
  UPDATE_DIVISION_STATUS,
} from "../../constants";
import { URL_DIVISION, URL_DIVISION_ADMIN, URL_PROCESSES } from "../../constants/endpoints";
import { isPartitioningDisabled } from "../../../util/constants/PermitedFeature";

export const fetchDivisions = (filter = {}) => {
  const {
 searchCriteria, pageNo, pageSize, status, sortBy, fleets
} = filter;
  return {
    type: FETCH_DIVISION,
    request: {
      url: encodeURI(`${URL_DIVISION}${searchCriteria ? `?searchCriteria=${searchCriteria}` : ""}`),
      params: {
        page: pageNo,
        size: pageSize,
        sort: sortBy,
        status: status?.join(","),
        fleetIds: fleets?.map(({ id }) => id)?.join()
      },
    },
  };
};

export const fetchDivisionsForFilters = () => ({
    type: FETCH_DIVISION_FOR_FILTERS,
    request: {
      url: `${URL_DIVISION}/filters`,
    }
  })
export const enableOrDisableDivision = (
  id,
  divisionStatus,
  onSuccess,
  onError
) => ({
  type: UPDATE_DIVISION_STATUS,
  request: {
    url: `${URL_DIVISION}/${id}`,
    method: "patch",
    headers: { "Content-Type": "application/json" },
  },
  meta: {
    onSuccess: (response) => {
      if (onSuccess) onSuccess();
      return response;
    },
    onError: (error) => {
      if (onError) onError();
      throw error;
    },
  },
});

export const deleteDivision = (id, onSuccess, onError) => ({
  type: DELETE_DIVISION_BY_ID,
  request: {
    url: `${URL_DIVISION_ADMIN}/${id}`,
    method: "delete",
  },
  meta: {
    onSuccess: (response) => {
      if (onSuccess) onSuccess();
      return response;
    },
    onError: (error) => {
      if (onError) onError();
      throw error;
    },
  },
});

export const deleteDivisions = (ids, onSuccess, onError) => ({
  type: DELETE_LIST_DIVISIONS,
  request: {
    url: `${URL_DIVISION_ADMIN}`,
    method: "delete",
    params: { ids: ids.join(",") },
  },
  meta: {
    onSuccess: (response) => {
      if (onSuccess) onSuccess();
      return response;
    },
    onError: (error) => {
      if (onError) onError();
      throw error;
    },
  },
});

export const fetchDivisionById = (id) => ({
  type: `${FETCH_DIVISION_BY_ID}`,
  request: {
    url: `${URL_DIVISION}/${id}`,
  },
});

export const fetchProcessesListByFleet = (id, fetchAll = false) => ({
  type: FETCH_PROCESSES_LIST_BY_FLEET,
  request: {
    url: `${URL_PROCESSES}list/fleet/${id}`,
    method: "get",
    params: {
      fetchAll,
    },
  },
});

export const addDivision = (division, onSuccess, onError) => ({
  type: ADD_DIVISION,
  request: {
    url: `${URL_DIVISION}`,
    method: "post",
    data: division,
    headers: { "Content-Type": "application/json" },
  },
  meta: {
    onSuccess: (response) => {
      if (onSuccess) onSuccess();
      return response;
    },
    onError: (error) => {
      if (onError) onError();
      throw error;
    },
  },
});

export const updateDivision = (id, division, onSuccess, onError) => ({
  type: UPDATE_DIVISION,
  request: {
    url: `${URL_DIVISION}/${id}`,
    method: "put",
    data: division,
  },
  meta: {
    onSuccess: (response) => {
      if (onSuccess) onSuccess();
      return response;
    },
    onError: (error) => {
      if (onError) onError();
      throw error;
    },
  },
});

export const fetchDivisionByFleetId = (id) => ({
  type: `${FETCH_DIVISION_BY_FLEET_ID}`,
  request: {
    url: `${URL_DIVISION}/fleet/${id}`,
  },
});

export const fetchProcessesByDivisionIds = (ids) => ({
  type: FETCH_PROCESSES_LIST_BY_DIVISIONS,
  request: {
    url: `${URL_PROCESSES}list/assigned-to-divisions`,
    method: "get",
    params: {
      divisionsIds: isPartitioningDisabled ? [] : ids
    }
  },
});

export const fetchDivisionProcessesByUserId = (id, divisionsIds) => ({
  type: FETCH_PROCESSES_LIST_BY_DIVISION_AND_USER,
  request: {
    url: `${URL_PROCESSES}list/assignedToDivision/available/${id}`,
    method: "get",
    params: {
      divisionsIds: divisionsIds.join()
    }
  },
});

export const fetchDivisionsByUserId = (id) => ({
  type: FETCH_DIVISIONS_BY_USER,
  request: {
    url: `${URL_DIVISION}/user/${id}`,
    method: "get",
  },
});
