import React, { useState } from "react";
import { useDispatch } from "react-redux";
import moment from "moment/moment";
import CustomDialog from "pages/Services/components/CustomDialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Typography from "@material-ui/core/Typography";
import CustomCloseButton from "pages/Services/components/CustomCloseButton";
import { fetchExecutionTimeEstimation } from "../../../../../../redux/actions/services";
import { useTranslation } from "react-i18next";
import CustomButton from "../../../../../../components/CustomButton";
import useStyles from "./style";

const periodOptions = [
  { value: 7, label: "Last week" },
  { value: 30, label: "fleet.management.be.period.1" },
  { value: 90, label: "fleet.management.be.period.2" },
  { value: 180, label: "fleet.management.be.period.3" },
]

function EstimationComputing({
  setValues,
  processId,
  openEstimatedTimeComputingPopUp,
  setOpenEstimatedTimeComputingPopUp,
}) {
  const [selectedPeriod, setSelectedPeriod] = useState();
  const [estimation, setEstimation] = useState({});
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation()

  const setFormValues = () => {
    // eslint-disable-next-line guard-for-in, no-restricted-syntax
    for (const key in estimation) {
      setValues(`estimationExecution.${key}`, estimation[key]);
      setOpenEstimatedTimeComputingPopUp(false);
    }
  };
  const getEstimation = (period) => {
    setLoading(true);
    dispatch(fetchExecutionTimeEstimation(processId, period)).then(
      (res) => {
        if (res.status === 200) {
          const duration = moment.duration(res.data);
          setLoading(false);
          setEstimation(duration._data);
        }
      },
    );
  };
  const reject = () => {
    setSelectedPeriod(null);
    setEstimation({});
    setOpenEstimatedTimeComputingPopUp(false);
  };

  const handlePeriodChange = (e) => {
    setSelectedPeriod(e.target.value)
    setEstimation({});
    getEstimation(e.target.value)
  }

  const hideEstimation = estimation.days === 0
      && estimation.hours === 0
      && estimation.minutes === 0
      && estimation.seconds === 0

  const isEstimationValid = Object.keys(estimation).length !== 0

  return (
    <CustomDialog
      open={openEstimatedTimeComputingPopUp}
      onClose={() => setOpenEstimatedTimeComputingPopUp(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <Grid container justify="space-between">
          <Grid item xs={11}>{t("Use Historical data")}</Grid>
          <Grid item xs={1}>
            <CustomCloseButton
            aria-label="close"
            onClick={reject}
            className={classes.closeButton}
            />
          </Grid>
        </Grid>

      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {t("Choose the period")}
        </DialogContentText>
        <Grid
          container
          spacing={2}
        >
          <Grid item xs={5}>
            <FormControl
                variant="standard"
                fullWidth
            >
              {!selectedPeriod && <InputLabel id="choose-period-label">{t("Choose period")}</InputLabel>}
              <Select
                  labelId="choose-period-label"
                  id="demo-simple-select-standard"
                  value={selectedPeriod}
                  onChange={(e) => handlePeriodChange(e)}
              >
                {periodOptions.map(({ value, label }) => (
                  <MenuItem value={value}>
                    {t(label)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {loading && <Grid alignItems="center"><CircularProgress color="white" size={20} /></Grid>}
          <Grid
              item
              xs={12}
          >
            {/* eslint-disable-next-line no-nested-ternary */}
            {isEstimationValid ? (
              hideEstimation ? (
                <Typography component="h6">
                  {t("no execution in this period")}
                </Typography>
              ) : (
                <Typography component="h6">
                  {t("Estimated Execution")}
                  {estimation.days !== 0 && (
                  <b>
                    {`${estimation.days.toString()} ${t("Days")} `}
                  </b>
                      )}
                  {estimation.hours !== 0 && (
                  <b>
                    {`${estimation.hours.toString()} ${t("Hours")} `}
                  </b>
                      )}
                  {estimation.minutes !== 0 && (
                  <b>
                    {`${estimation.minutes.toString()
                        } ${
                          t("Minutes")
                        } `}
                  </b>
                      )}
                  {estimation.seconds !== 0 && (
                  <b>
                    {`${estimation.seconds.toString()
                        } ${
                          t("Seconds")
                        } `}
                  </b>
                      )}
                </Typography>
              )
          ) : (
            null
          )}
          </Grid>
          <Grid
              item
              xs={12}
              className={classes.buttonsContainer}
          >
            <CustomButton
                onClick={() => reject()}
                color="primary"
                size="small"
                variant="outlined"
            >
              {t("user.button.cancel")}
            </CustomButton>
            <CustomButton
                view="primary"
                size="small"
                autoFocus
                onClick={() => setFormValues()}
                disabled={!selectedPeriod || hideEstimation || loading}
            >
              {t("Accept")}
            </CustomButton>
          </Grid>
        </Grid>
      </DialogContent>
    </CustomDialog>
  );
}

export default EstimationComputing;
