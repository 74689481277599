 const supportedLanguages = [
    {
        flag: "us",
        key: "en",
        text: "English",
    },
    {
        flag: "fr",
        key: "fr",
        text: "Français",
    },
    {
        flag: "es",
        key: "es",
        text: "Español",
    },
    {
        flag: "pl",
        key: "pl",
        text: "Polski",
    },
    {
        flag: "de",
        key: "de",
        text: "Deutsch",
    },
];
export default supportedLanguages;
