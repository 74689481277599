import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Tooltip,
  CardActions,
  IconButton,
  Card,
  CardContent,
  Grid,
} from "@material-ui/core";
import get from "lodash/get";
import VisibilityIcon from "@material-ui/icons/Visibility";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { isPermitted } from "../../../../components/HasPermission/index.js";
import AlertsTableCell from "../AlertsTableCell";
import useStyles from "./style.js";
import ConfirmMessage from "../../../../components/ConfirmMessage/index.js";
import { toast } from "react-toastify";

export default function AlertsTableRow({
  row,
  displayFields,
  index,
  entityBaseUrl,
  deleteEntity,
  deleteEntityLabel,
  isDefaultAlertsPage = false,
  isCustomAlertsPage = false,
  fetchEntitiesReset,
}) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [openMsgConfirm, setOpenMsgConfirm] = useState(false);
  const [toDeleteId, setToDeleteId] = useState(null);
  const [isLoading, setIsloading] = useState({
    switch: false,
    deleteAction: false,
  });

  const moduleName = isDefaultAlertsPage ? "default" : "custom";

  const resetLoaders = () => {
    setIsloading({ switch: false, deleteAction: false });
  };

  const cancelConfirm = () => {
    resetLoaders();
    setOpenMsgConfirm(false);
  };

  const confirmDelete = () => {
    setIsloading({ ...isLoading, deleteAction: true });
    dispatch(deleteEntity(toDeleteId)).then(() => {
      fetchEntitiesReset();
      resetLoaders();
      setOpenMsgConfirm(false);
      toast.success(t("alert.management.formControl.delete"))
    });
  };

  const currentUser = useSelector(({ requests }) => get(requests, "queries.FETCH_CURRENT_USER.data"));

  const IconPlaceHolder = () => (
    <Grid item className={classes.rowActionButtons} style={{ width: 40 }}>
      &nbsp;
    </Grid>
  );

  return (
    <>
      <Card key={index} className={classes.root} elevation={1}>
        <CardContent className={classes.content}>
          <Grid container item xs={12}>
            <Grid container justify="flex-start" alignItems="center">
              {displayFields.map((field, index1) => (
                !field.disableSmsFunctionality
                  && <AlertsTableCell key={index1} field={field} row={row} />
              ))}
            </Grid>
          </Grid>
        </CardContent>
        <CardActions className={classes.rowActions}>
          <Grid container xs={12} justify="flex-end" alignItems="center">
            <Grid item>
              <Tooltip placement="top" title={t("tooltip.action.view")}>
                <IconButton
                  id={`${moduleName}-alert-view-btn`}
                  className={classes.rowActionButtons}
                  aria-label="view"
                  onClick={() => history.push(`${entityBaseUrl}/view/${get(row, "id")}`)}
                >
                  <VisibilityIcon />
                </IconButton>
              </Tooltip>
            </Grid>

            {(isDefaultAlertsPage
              && isPermitted(currentUser, "Edit default alerts"))
            || (isCustomAlertsPage
              && isPermitted(currentUser, "Edit custom alerts")) ? (
                <Grid item>
                  <Tooltip placement="top" title={t("tooltip.action.edit")}>
                    <IconButton
                      id={`${moduleName}-alert-edit-btn`}
                      className={classes.rowActionButtons}
                      aria-label="edit"
                      onClick={() => history.push(`${entityBaseUrl}/edit/${get(row, "id")}`)}
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              ) : (
                <IconPlaceHolder />
              )}
            {(isDefaultAlertsPage
              && isPermitted(currentUser, "Delete default alert"))
            || (isCustomAlertsPage
              && isPermitted(currentUser, "Delete custom alert")) ? (
                <Grid item>
                  <Tooltip placement="top" title={t("tooltip.action.delete")}>
                    <IconButton
                      id={`${moduleName}-alert-delete-btn`}
                      className={(classes.rowActionButtons, classes.deleteIcon)}
                      aria-label="delete"
                      onClick={() => {
                        setToDeleteId(get(row, "id"));
                        setOpenMsgConfirm(true);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              ) : (
                <IconPlaceHolder />
              )}
          </Grid>
        </CardActions>
      </Card>
      <ConfirmMessage
        message={
          typeof deleteEntityLabel === "string"
            ? t(deleteEntityLabel)
            : deleteEntityLabel
        }
        openStart={openMsgConfirm}
        onCancel={cancelConfirm}
        onConfirm={confirmDelete}
        buttonConfirm={t("Delete")}
        buttonCancel={t("Cancel")}
        isDelete="true"
        isLoading={isLoading.deleteAction}
      />
    </>
  );
}
