import React, { useEffect, useRef, useState } from "react";
import Typography from "@material-ui/core/Typography";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";
import { Tooltip } from "@material-ui/core";
import { Doughnut } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { getQuery } from "@redux-requests/core";
import { fetchDashboardProcessesStatus } from "redux/actions/services/dashboard";
import renderValue, {
    renderContent,
    DOUGHNUT_CHART_DATASET_OPTION,
    DOUGHNUT_CHART_DATASET_DATA, translationX, translationY, MONITORING_TIME
} from "pages/newDashboard/dashboardUtils";
import { isEnvParamEnabled } from "util/index";
import { handleRequestMonitoringMode } from "pages/Dashboard/dashboardUtils";
import LightCircle from "assets/NewDashboardIcons/lightCircle.svg";
import useStyles from "../../style";

const Processes = () => {
  const { t } = useTranslation();
  const [percentage, setPercentage] = useState(0);
  const classes = useStyles({ translationX: translationX(percentage), translationY: translationY(percentage) })();
  const [processesWorking, setProcessesWorking] = useState();
  const [processesNotWorking, setProcessesNotWorking] = useState();
  const [processesTotal, setProcessesTotal] = useState();
  const isLoading = useSelector((state) => getQuery(state, { type: "FETCH_DASHBOARD_PROCESSES_STATUS_COUNT" }).loading);
  const dispatch = useDispatch();
  const filterDashboard = useSelector(
    ({ dashboardFilters }) => dashboardFilters,
  );
  const isRiyadBankEnvEnabled = isEnvParamEnabled("REACT_APP_DISPLAY_RIYAD_BANK_LOGO");
  const fetchProcesses = () => {
    dispatch(
      fetchDashboardProcessesStatus(filterDashboard, isRiyadBankEnvEnabled, (res) => {
        setProcessesWorking(res?.data?.countWorking);
        setProcessesNotWorking(res?.data?.countIdle);
        setProcessesTotal(res?.data?.countTotal);
      }),
    );
  };

  const fetchIntervalRef = useRef(null);
  useEffect(() => {
    handleRequestMonitoringMode(MONITORING_TIME, fetchIntervalRef, fetchProcesses)
    return () => {
        clearInterval(fetchIntervalRef.current)
    }
  }, [filterDashboard])

  useEffect(() => {
    if (filterDashboard?.selectedDurationValue === "DAY") {
      fetchProcesses();
    }
  }, [filterDashboard]);

  useEffect(() => {
    if (processesNotWorking + processesWorking) {
      setPercentage((processesWorking / (processesWorking + processesNotWorking)) * 100)
    }
  }, [processesWorking, processesNotWorking]);

  const ProcessesChart = () => (
    <Grid container item alignItems="center" direction="column" spacing={2}>
      <Grid item className={classes.chartContainer}>
        <Doughnut data={DOUGHNUT_CHART_DATASET_DATA(percentage, "light")} options={DOUGHNUT_CHART_DATASET_OPTION} />
        <img src={LightCircle} alt="circle" className={classes.circleImage} />
      </Grid>
      <Grid
        container
        item
        direction="column"
        alignItems="center"
        className={classes.centeredContent}
        xs={2}
      >
        <Typography className={classes.textBold}>
          {renderValue({
            value: processesTotal,
          })}
        </Typography>
        <Typography className={classes.textLight}>
          {isRiyadBankEnvEnabled ? t("all.processes") : t("processes")}
        </Typography>
      </Grid>
      <Grid container item justify="space-between" alignItems="center">
        <Grid item>
          <Typography className={classes.textRegular}>
            {t("notWorking.processes.status")}
          </Typography>
        </Grid>
        <Grid item>
          <Chip label={renderValue({ value: processesNotWorking })} className={clsx(classes.chip, classes.defaultChip)} />
        </Grid>
      </Grid>
      <Grid container item justify="space-between" alignItems="center">
        <Grid item>
          <Typography className={classes.textRegular}>
            {t("working.processes.status")}
          </Typography>
        </Grid>
        <Grid item>
          <Chip label={renderValue({ value: processesWorking })} className={clsx(classes.chip, classes.lightChip)} />
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <Box className={classes.cardContainer}>
      <CardHeader
        title={t("processes")}
        classes={{
          title: classes.cardHeaderTitle,
        }}
      />
      <Tooltip title={t("Processes.activity.tooltip")}>
        <CardContent>
          { renderContent(isLoading, [processesWorking, processesNotWorking], <ProcessesChart />) }
        </CardContent>
      </Tooltip>
    </Box>
  );
};

export default Processes;
