import React from "react";
import { Chip } from "@material-ui/core";

const PriorityChip = ({
  priority, onClick, style, hidden,
}) => (
  <Chip
    label={priority}
    className={style}
    variant="outlined"
    onClick={onClick}
    disabled={hidden}
  />
);

export default PriorityChip;
