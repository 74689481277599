import React, { useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";
import ReplayRoundedIcon from "@material-ui/icons/ReplayRounded";
import MuiDialogActions from "@material-ui/core/DialogActions";
import { toast } from "react-toastify";
import { useFieldArray, useForm } from "react-hook-form";
import { connect } from "react-redux";
import {
  fetchProcessDataMapping,
  updateProcessDataMapping,
} from "../../../../redux/actions/services";
import CircularLoader from "../../../../components/Loaders/CircularLoader";
import DataNotFound from "../../../../components/DataNotFound";
import EnhancedTableHead from "../../../../components/DataTable/EnhancedTableHead";
import ConfirmMessage from "../../../../components/ConfirmMessage";
import CustomSwitch from "../CustomSwitch";
import { CustomTextField } from "../../../../components/FormFields/CustomAutoComplete";
import processExecutionNotFoundIcon from "../../../../assets/Process_Overview.svg";
import CustomButton from "../../../../components/CustomButton";
import useStyles from "../ModalService/style";

function DataMapping(props) {
  const { processId } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [openMsgConfirm, setOpenMsgConfirm] = React.useState(false);
  const {
    register, control, watch, handleSubmit, reset,
  } = useForm({
    defaultValues: { dataMappings: data },
  });

  const { fields } = useFieldArray({
    control,
    name: "dataMappings",
  });

  const headCells = [
    {
      label: t("Data"),
    },
    {
      label: t("New name"),
    },
    {
      label: t("Display"),
    },
    {
      label: t("can.be.edited.for.retry"),
    },
    {
      label: t("display.in.items.list"),
    },
  ];

  useEffect(() => {
    if (!isLoaded) {
      props.fetchProcessDataMapping(processId).then((result) => {
        setData(result?.data);
        reset({ dataMappings: result?.data });
        setIsLoaded(true);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [processId]);

  const handleChangeSwitch = (e, obj, index, fieldName) => {
    const { checked } = event.target;
    const newData = data.map((d) => {
      if (d.key === obj.key) {
        fields[index][fieldName] = checked;
        return {
          ...d,
          [fieldName]: checked,
        };
      }
      return d;
    });
    setData(newData);
  };

  const onSubmit = () => {
    setOpenMsgConfirm(true);
  };

  const confirmSave = () => {
    setIsLoaded(false);
    const toSubmit = [...data.map((item, index) => {
      item.label = watch("dataMappings")[index].label;
      return item;
    })]
    props.updateProcessDataMapping(processId, toSubmit, onSuccess, onError);
  };

  const cancelConfirm = () => {
    setOpenMsgConfirm(false);
  };

  const onSuccess = (res) => {
    if (res.status === 200) {
      setOpenMsgConfirm(false);
      toast.success(t("Data saved with success"))
      props.fetchProcessDataMapping(processId).then((result) => {
        setData(result?.data);
        reset({ dataMappings: result?.data });
        setIsLoaded(true);
      });
    }
  };

  const onError = () => {
    setIsLoaded(false);
    setOpenMsgConfirm(false);
    toast.error(t("Data not saved"))
  };

  const onReset = () => {
    const defaultData = data.map((d) => ({
      ...d,
      label: d.key,
      isDisplayed: true,
      editForRetry: true,
      displayInItemsList: true,
    }));
    reset({ dataMappings: defaultData });
    setData(defaultData)
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={classes.root}
      autoComplete="off"
      style={{ height: "100%" }}
    >
      <Paper elevation="0" className={classes.paper}>
        <TableContainer className={classes.dataMappingTableContainer}>
          {isLoaded ? (
            <Table
              stickyHeader
              className={classes.table}
              aria-labelledby="tableTitle"
              size="medium"
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                classes={classes}
                headCells={headCells}
                align="left"
                transparentHeader
              />
              <TableBody className={classes.dataMappingTableBody}>
                {!data || data.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={6}>
                      <DataNotFound
                        padding={50}
                        message={t("no.data-mapping.message")}
                        icon={processExecutionNotFoundIcon}
                      />
                    </TableCell>
                  </TableRow>
                ) : (
                  fields?.map((row, index) => (
                    <TableRow tabIndex={-1} key={index.toString()}>
                      <TableCell
                        align="left"
                        classes={{
                        root: classes.transparentTableCell
                      }}>
                        <Typography className={classes.transparentTableCell}>
                          {row?.key}
                        </Typography>
                      </TableCell>
                      <TableCell align="left" className={classes.transparentTableCell}>
                        <CustomTextField
                            {...register(`dataMappings.${index}.label`)}
                            className={classes.tableCellInput}
                            value={watch(`dataMappings.${index}.label`)}
                        />
                      </TableCell>

                      <TableCell align="left" className={classes.transparentTableCell}>
                        <CustomSwitch
                            {...register(`dataMappings.${index}.isDisplayed`)}
                            checked={row?.isDisplayed}
                            defaultChecked={row?.isDisplayed}
                            handleChange={(e) => handleChangeSwitch(e, row, index, "isDisplayed")}
                        />
                      </TableCell>
                      <TableCell align="left" className={classes.transparentTableCell}>
                        <CustomSwitch
                            {...register(`dataMappings.${index}.editForRetry`)}
                            checked={row?.editForRetry}
                            defaultChecked={row?.editForRetry}
                            handleChange={(e) => handleChangeSwitch(e, row, index, "editForRetry")}
                        />
                      </TableCell>
                      <TableCell align="left" className={classes.transparentTableCell}>
                        <CustomSwitch
                            {...register(`dataMappings.${index}.displayInItemsList`)}
                            checked={row?.displayInItemsList}
                            defaultChecked={row?.displayInItemsList}
                            handleChange={(e) => handleChangeSwitch(e, row, index, "displayInItemsList")}
                        />
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          ) : (
            <CircularLoader />
          )}
        </TableContainer>
        {openMsgConfirm && (
          <ConfirmMessage
            message={t("Are you sure you want to update the mapping ?")}
            openStart={openMsgConfirm}
            onCancel={cancelConfirm}
            onConfirm={confirmSave}
            buttonConfirm={t("Update")}
            buttonCancel={t("Cancel")}
            isLoading={!isLoaded}
          />
        )}
        <MuiDialogActions className={classes.cardActions}>
          <CustomButton
            onClick={onReset}
            view="cancel"
            startIcon={<ReplayRoundedIcon />}
            disabled={data?.length === 0}
          >
            {t("Reset")}
          </CustomButton>
          <CustomButton
            view="primary"
            size="medium"
            type="submit"
            disabled={data?.length === 0}
          >
            {t("apply")}
          </CustomButton>
        </MuiDialogActions>
      </Paper>
    </form>
  );
}

const mapDispatchToProps = {
  fetchProcessDataMapping,
  updateProcessDataMapping,
};
export default connect(null, mapDispatchToProps)(DataMapping);
