import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  root: {
    display: "flex",
    marginBottom: "1%",
    boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)"
  },
  content: {
    display: "flex",
    flex: "0 0 78%",
    flexDirection: "column",
    paddingTop: 0,
    paddingBottom: 0
  },
  viewClickContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  userAvatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  rowActions: {
    flex: "0 0 22%",
    justifyContent: "flex-start",
    padding: theme.spacing(2, 1),
  },
  rowActionButtons: {
    padding: theme.spacing(1, 1),
    width: 40
  },
  menuItemContainer: {
    padding: theme.spacing(1),
  },
  menuItemDivider: {
    width: "100%",
  },
  permissionContainer: {
    padding: theme.spacing(0, 1),
  },
  templateLink: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  showIcon: {
    color: theme.custom.color.color1,
  },
  instanceOwnerTag: {
    color: theme.custom.color.alertDefaultIconColor,
  },
  deleteColor: {
    color: theme.custom.color.color6,
  },
  defaultIconColor: {
    color: theme.custom.color.color10,
  },
  archiveColor: {
    color: "blue",
  },
  permissionsContainer: {
    maxHeight: 500,
  },
  ColumnName: {
    fontWeight: "bold",
    marginBottom: "6px"
  },
  permissionsForm: {
    padding: "10px 0 0 10px",
  },
}));
