import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Tabs from "@material-ui/core/Tabs";
import Paper from "@material-ui/core/Paper";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import CustomCloseButton from "pages/Services/components/CustomCloseButton";
import { useDispatch, useSelector } from "react-redux";
import { resetCustomHandleClose, storeCustomHandleClose } from "redux/slices/stepperSlice";
import {
  deleteSchedule,
  fetchSchedule,
} from "../../../../redux/actions/services";
import CustomTab, { useIndicatorStyle } from "pages/Services/components/CustomTab";
import ConfirmMessage from "../../../../components/ConfirmMessage";
import CustomDialog from "pages/Services/components/CustomDialog";
import CustomButton from "../../../../components/CustomButton";
import ScheduleForm from "../../../Services/components/ScheduleForm";
import CircularLoader from "../../../../components/Loaders/CircularLoader";
import useStyles from "pages/Calendar/style";

function CalendarScheduleForm({
  openStart,
  handleClose,
  scheduleId,
  reloadPlannerData,
  editMode = false,
}) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = React.useState(false);
  const [isScheduleLoading, setScheduleIsLoading] = React.useState(false);
  const [selectedSchedule, setSelectedSchedule] = React.useState(null);
  const [openDeleteMsgConfirm, setOpenDeleteMsgConfirm] = React.useState(false);
  const preventHandleClose = useSelector(({ stepper }) => stepper?.preventHandleClose);

  const cancelConfirm = () => {
    setOpenDeleteMsgConfirm(false);
  };

  const handleDeleteSchedule = () => {
    setOpenDeleteMsgConfirm(true);
  };

  const confirmDelete = () => {
    if (scheduleId) {
      setIsLoading(true);
      dispatch(deleteSchedule(scheduleId, onScheduleDeleteSuccess, onScheduleDeleteError))
    }
  };

  const onScheduleDeleteSuccess = () => {
    toast.success(t("Schedule Deleted Successfully"));
    reloadPlannerData();
    setIsLoading(false);
    setOpenDeleteMsgConfirm(false);
    handleClose();
  }

  const onScheduleDeleteError = () => {
    toast.error(t("Failed to delete schedule"));
    setIsLoading(false);
    setOpenDeleteMsgConfirm(false);
  }

  useEffect(() => {
    if (scheduleId) {
      setScheduleIsLoading(true);
      dispatch(
        fetchSchedule(
          scheduleId,
          (res) => {
            setSelectedSchedule(res?.data);
            setScheduleIsLoading(false);
          },
          (res) => {
            toast.error(
              t(res.response?.data?.message ?? t("Unexpected Error"))
            );
            handleClose();
          }
        )
      );
    }
  }, []);

  const indicatorStyle = useIndicatorStyle();

    const handleDialogClose = () => {
      if (preventHandleClose) {
        dispatch(storeCustomHandleClose(() => {
          handleClose()
          dispatch(resetCustomHandleClose())
        }));
        preventHandleClose()
      }
      else {
        handleClose();
      }
    }

  return (
    <CustomDialog
      open={openStart}
      aria-labelledby="customized-dialog-title"
      maxWidth="lg"
      disableBackdropClick
      onClose={handleClose}
    >
      <Paper square>
        <Grid container direction="row" xs={12}>
          <Grid container direction="row" xs={11}>
            <Tabs
              id="process-tabs-page"
              indicatorColor="primary"
              textColor="primary"
              aria-label="switch tabs"
              TabIndicatorProps={{
                style: indicatorStyle,
              }}
            >
              <CustomTab
                id="process-overview-tab"
                label={editMode ? t("Edit Scheduler") : t("create.scheduler")}
              />
            </Tabs>
          </Grid>
          <Grid container direction="row" xs={1} justify="flex-end">
            <CustomCloseButton
              aria-label="close"
              onClick={handleDialogClose}
            />
          </Grid>
        </Grid>
      </Paper>
      <Box component={Grid} container padding="10px 35px 35px 35px !important" className={classes.scheduleContainer}>
        {isScheduleLoading ? (
          <CircularLoader />
        ) : (
          <ScheduleForm
            row={selectedSchedule}
            onSave={() => {
              handleClose();
              reloadPlannerData();
            }}
            isFormOnly
            customCancel={
              scheduleId && (
              <CustomButton
                        view="cancel"
                        variant="contained"
                        size="medium"
                        onClick={handleDeleteSchedule}
                    >
                {t("Delete")}
              </CustomButton>
                )
            }
          />
        )}
      </Box>
      {openDeleteMsgConfirm && (
        <ConfirmMessage
          message={t("Are you sure you want to delete this schedule ?")}
          openStart={openDeleteMsgConfirm}
          onCancel={cancelConfirm}
          onConfirm={confirmDelete}
          isDelete="true"
          buttonConfirm={t("Delete")}
          buttonCancel="Cancel"
          isLoading={isLoading}
        />
      )}
    </CustomDialog>
  );
}

export default CalendarScheduleForm;
