import { React } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import TextFieldForm from "../../../components/ReactHookField/TextFieldForm";
import {
    formatInputOnBlur,
    formatInputOnFocus, getCurrencySymbole,
    handleInputChanges
} from "util";
import CustomAutoComplete, { CustomTextField } from "../../../components/FormFields/CustomAutoComplete";
import { FETCH_CURRENT_USER } from "../../../redux/constants";
import { useQuery } from "@redux-requests/react";
import useStyles from "../style";
import InputAdornment from "@material-ui/core/InputAdornment";

export default function TimeSaved({
 processesList, selectedProcess, handleSelectProcess, isSaveLoading
}) {
    const { t } = useTranslation();
    const classes = useStyles();
    const form = useFormContext()
    const currentUser = useQuery({ type: FETCH_CURRENT_USER })?.data;
    const onSelectProcess = (newVal) => {
        handleSelectProcess(processesList?.find(({ id }) => id === newVal.id))
    }

    const disableFields = isSaveLoading

    return (
      <Grid item xs={7} container alignItems="center">
        <Grid item xs={5}>
          <Typography variant="h5" className={classes.timeLabel}>
            {t("select.process")}
          </Typography>
        </Grid>
        <Box component={Grid} item xs={6} pb={4}>
          <CustomAutoComplete
              className={classes.autoComplete}
              multiple={false}
              options={processesList}
              getOptionLabel={(option) => option?.processDisplayName}
              value={processesList?.find(({ id }) => id === selectedProcess.id)}
              onChange={onSelectProcess}
              disableClearable
              disableCloseOnSelect={false}
              renderInput={(params) => (
                <CustomTextField
                  {...params}
                  value={selectedProcess}
                  InputProps={{
                    ...params.InputProps,
                    className: classes.customAutoCompleteInput,
                    classes: { input: classes.customAutoCompleteInput },
                  }}
                />
              )}
              renderOption={((option) => (
                <Typography component="h3">
                  {(option?.processDisplayName)}
                </Typography>
              ))}
          />
        </Box>
        <Box component={Grid} item xs={5} mt={2}>
          <Typography variant="h5" className={classes.timeItem}>
            {t("Time Saved per Item")}
          </Typography>
        </Box>
        <Grid item xs={6}>
          <TextFieldForm
                  fieldName="fteItemTime"
                  helperText={t("Time saved for each completed item of this process (in minutes)")}
                  fullWidth
                  min={0}
                  onBlur={(field) => formatInputOnBlur(field, form)}
                  onFocus={(field) => formatInputOnFocus(field, form)}
                  onInput={(e) => handleInputChanges(e, 9)}
                  readOnly={disableFields}
                  classes={classes.timeInput}
                  isCustom
              />
        </Grid>
        <Grid item xs={5}>
          <Typography variant="h5" className={classes.timeExecution}>
            {t("Time Saved per Execution")}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <TextFieldForm
                    fieldName="fteExecutionTime"
                    helperText={t(" Time saved for each execution of this process excluding the time saved from every item (in minutes)")}
                    fullWidth
                    min={0}
                    onBlur={(field) => formatInputOnBlur(field, form)}
                    onFocus={(field) => formatInputOnFocus(field, form)}
                    onInput={(e) => handleInputChanges(e, 9)}
                    readOnly={disableFields}
                    isCustom

                />
        </Grid>
        <Grid item xs={5}>
          <Typography variant="h5" className={classes.fte}>
            {t("FTE Cost")}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <TextFieldForm
                    fieldName="fteCost"
                    helperText={t("FTE hourly cost")}
                    fullWidth
                    min={0}
                    InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">{getCurrencySymbole(currentUser?.fleet)}</InputAdornment>
                        ),
                    }}
                    onBlur={(field) => formatInputOnBlur(field, form)}
                    onFocus={(field) => formatInputOnFocus(field, form)}
                    onInput={(e) => handleInputChanges(e, 9)}
                    readOnly={disableFields}
                    isCustom

                />
        </Grid>
      </Grid>
    );
}
