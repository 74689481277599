import { makeStyles, fade } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  root: {
    position: "relative",
    display: "flex",
    flex: "0 0 100%",
    marginBottom: "1%",
    background: "white !important",
    transition: theme.transitions.create("transform", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.short,
    }),
    "&:hover": {
      background: "white !important",
      boxShadow: theme.shadows[25],
      transform: "scale(1.01)",
    },
  },
  rootGlobal: {
    width: "100%",
    paddingLeft: theme.spacing(1),
    marginTop: "-15px",
  },
  content: {
    fontWeight: "600",
    textAlign: "center",
    width: "100%"
  },
  contentFilter: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
  },
  actionHead: {
    justifyContent: "right",
    paddingLeft: theme.spacing(4.5),
  },
  select: {
    padding: "12px",
  },

  downloadIcon: {
    color: theme.palette.success.main,
  },

  tableRow: {
    background: theme.palette.common.white,
    cursor: "pointer",
    padding: "20px 0px",
  },
  tableCell: {
    fontWeight: "400",
    paddingLeft: theme.spacing(3),
  },
  tableHeadLabel: {
    fontWeight: "500",
    fontSize: theme.typography.pxToRem(16),
    padding: "10px 0",
    color: "gray",
  },

  tableCellHead: {
    borderBottom: "none",
    paddingTop: 0,
    paddingBottom: 0,
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(12),
    color: fade(theme.palette.primary.main, 0.5),
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  card: { background: "transparent", width: "100%" },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },

  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  center: {
    textAlign: "center",
    alignItems: "center",
  },
  right: {
    textAlign: "right",
    alignItems: "right",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 400,
    maxWidth: 700,
  },
  deleteIcon: {
    color: theme.custom.color.color4,
  },
  autoComplete: {
    width: "95%",
    marginBottom: 10,
  },
  autoCompleteRight: {
    width: "85%",
    marginLeft: "52%",
  },
  tableCellAction: {
    fontWeight: "400",
  },
  filter: {
    paddingTop: theme.spacing(1.5),
    marginBottom: 30
  },
  filterRoot: {
    paddingBottom: theme.spacing(1),
  },
  image: {
    width: 40,
    height: 40,
  },
  actions: {
    marginRight: theme.spacing(2),
  },
  processCard: {
    paddingLeft: theme.spacing(3),
  },
  // exportButton: {
  //   height: 40,
  //   width: 150,
  // },
  field: {
    fontWeight: 700,
    wordWrap: "break-word",
  },
  value: {
    wordWrap: "break-word",
  },
  dialogContent: {
    marginTop: theme.spacing(3),
  },
  gridPadding: {
    padding: theme.spacing(2, 0),
  },
  headerSpacing: {
    padding: "16px"
  },
  actionButtons: {
  marginLeft: "auto",
  marginTop: "auto",
  marginBottom: 7,
  marginRight: 12
}
}));
