import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  root: {
    width: "100%",
    background: "transparent",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
    padding: theme.spacing(0, 4, 2, 4),
  },
  warningColor: {
    color: theme.custom.color.color3,
  },
  errorColor: {
    color: theme.custom.color.color4,
  },
  infoColor: {
    color: theme.palette.secondary.main,
  },
  iconStyle: {
    verticalAlign: "middle",
    marginRight: "8px",
  },
  active: { color: theme.palette.secondary.main },
  inactive: { color: theme.custom.color.alertDefaultIconColor },
}));
