import React, { useState } from "react";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Checkbox from "@material-ui/core/Checkbox";
import TrashIcon from "@material-ui/icons/Delete";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "@redux-requests/react";
import {
  countQueuesSchedules,
  deleteQueuesSchedules,
  migrateQueuesSchedules,
} from "redux/actions/services";
import {
  triggerQueueAction,
  updateQueueAction,
} from "redux/slices/executionManagerSlice"; import { isFleetAdministrator } from "util";
import { ASK_DELETE_ONE_QUEUE, QUEUE_DELETED } from "util/constants";
import ConfirmMessage from "components/ConfirmMessage/index.js";
import useStyles from "./style.js";

export default function QueueTableHeader({
  selected,
  setSelected,
  handleSelectedAll,
  checkedAll,
  checkedindeterminate,
  fetchEntities,
  deleteEntities,
  deleteEntitiesLabel,
  page,
  rowsPerPage,
  enableDeleteMultiple,
  confirmActionLabel,
  entityDeleted,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [openDeletePopup, setopenDeletePopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [isPopUpActionLoading, setIsPopUpActionLoading] = useState(false);
  const [popUpMessage, setPopUpMessage] = useState("");
  const [action, setAction] = useState("");
  const [confirmBtnText, setConfirmBtnText] = useState("");

  const handleCloseConfirmationPopup = () => {
    setIsLoading(false);
    setopenDeletePopup(false);
    setOpenPopup(false);
  };

  const currentUser = useSelector(({ requests }) => get(requests, "queries.FETCH_CURRENT_USER.data"));
  const { data } = useQuery({ type: fetchEntities().type });
  const { queueAction } = useSelector(
    ({ executionManager }) => executionManager
  );

  const deleteAll = (newSelected = selected) => {
    dispatch(
      deleteEntities(
        newSelected,
        () => {
          setIsLoading(false);
          dispatch(fetchEntities({ pageNo: page, pageSize: rowsPerPage }));
          setSelected([]);
          handleCloseConfirmationPopup();
          toast.success(t(entityDeleted));
        },
        () => {
          handleCloseConfirmationPopup();
          toast.error(t("something went wrong"));
        }
      )
    );
  };
  React.useEffect(() => {
    if (queueAction === QUEUE_DELETED) {
      handleCloseConfirmationPopup();
      setSelected([]);
      dispatch(updateQueueAction({ queueAction: null }));
      dispatch(fetchEntities({ pageNo: page, pageSize: rowsPerPage }));
    }
  }, [queueAction]);

  const onClick = () => {
    setIsLoading(true);
    const newSelected = selected;
    // if only one queue is selected , trigger action to AdminTableRow to reuse delete one queue logic
    if (newSelected.length === 1) {
      dispatch(
        triggerQueueAction({
          queueAction: ASK_DELETE_ONE_QUEUE,
          id: newSelected[0],
        })
      );
      return;
    }
    // else check if queues have schedules with robot and without
    dispatch(countQueuesSchedules(selected, handleQueuesCountSchedules));
  };
  const handleQueuesCountSchedules = (response) => {
    const countSchedules = response.data;
    if (countSchedules === 0) {
      // delete all
      // deleteAll();
      return;
    }

    // migrate to bp
    setAction("MIGRATE");
    setConfirmBtnText(t("confirm"));
    setOpenPopup(true);
    setPopUpMessage(
        t("queue.management.unassign.process.part2_plural", {
          nbSchedules: t("some"),
        })
    );
  }
  const closePopUp = () => {
    setIsPopUpActionLoading(false);
    setOpenPopup(false);
  };
  const confirm = () => {
    if (action === "MIGRATE") migrateSchedules();
    else {
      deleteAllSchedules();
    }
  };
  const migrateSchedules = () => {
    setIsPopUpActionLoading(true);
    dispatch(
      migrateQueuesSchedules(selected, (res) => {
        // proceed to delete all
        if (res.data) deleteAll();
        else {
          handleCloseConfirmationPopup();
          toast.error(t("something went wrong"));
        }
        closePopUp();
      }),
      () => {
        handleCloseConfirmationPopup();
        closePopUp();
      }
    );
  };

  const deleteAllSchedules = () => {
    setIsPopUpActionLoading(true);
    dispatch(
      deleteQueuesSchedules(selected, () => {
        // proceed to delete all
        deleteAll();
        closePopUp();
      }),
      () => {
        handleCloseConfirmationPopup();
        toast.error(t("something went wrong"));
        closePopUp();
      }
    );
  };

  return (
    <Card className={classes.header}>
      <Grid
        container
        xs={12}
        justify="flex-end"
        direction="row"
        alignItems="flex-end"
      >
        {/* eslint-disable-next-line no-nested-ternary */}
        {enableDeleteMultiple ? (
          isFleetAdministrator(currentUser) && data?.totalElements > 0 ? (
            <>
              <Grid item>
                <Tooltip placement="top" title={t(confirmActionLabel)}>
                  <IconButton
                    className={classes.headerActionButtons}
                    aria-label="delete"
                    disabled={isEmpty(selected)}
                    onClick={() => {
                      setopenDeletePopup(true);
                    }}
                  >
                    <TrashIcon className={isEmpty(selected) ? classes.defaultIconsColor : classes.deleteColor} />
                  </IconButton>
                </Tooltip>
                <ConfirmMessage
                  message={t(deleteEntitiesLabel)}
                  openStart={openDeletePopup}
                  onCancel={handleCloseConfirmationPopup}
                  onConfirm={onClick}
                  buttonConfirm={t(confirmActionLabel)}
                  buttonCancel={t("Cancel")}
                  isLoading={isLoading}
                />
              </Grid>
              <Grid item>
                <Checkbox
                  checked={checkedAll}
                  className={classes.headerActionButtons}
                  indeterminate={checkedindeterminate}
                  onChange={(e) => {
                    handleSelectedAll(e.target.checked);
                  }}
                />
              </Grid>
            </>
          ) : null
        ) : null}
      </Grid>

      <ConfirmMessage
        message={popUpMessage}
        openStart={openPopup}
        onCancel={() => {
          closePopUp();
          handleCloseConfirmationPopup();
        }}
        onConfirm={confirm}
        buttonConfirm={confirmBtnText}
        buttonCancel={t("Cancel")}
        isLoading={isPopUpActionLoading}
      />
    </Card>
  );
}
