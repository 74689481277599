import {
    DELETE_ALL_EXPORTED_FILES,
    DELETE_EXPORTED_FILE,
    EXPORT_ALL_FILES, EXPORT_ANALYSIS_TABLE_DATA, EXPORT_EXCEPTIONS, EXPORT_EXECUTIONS,
    EXPORT_FILE_BY_NAME,
    EXPORT_FILTERED_EXECUTIONS, EXPORT_OCCURENCES, EXPORT_PROCESS_EXECUTION_EXCEPTIONS,
    FETCH_EXPORTED_FILES
} from "../../constants";
import { URL_BASE_EXPORT } from "../../constants/endpoints";
import { onExportSuccess, formatDateTimeIsoParam } from "../../../util";

export const exportAllFiles = () => ({
    type: EXPORT_ALL_FILES,
    request: {
        url: `${URL_BASE_EXPORT}exportedzip`,
        method: "get",
        responseType: "blob",
    },
    meta: {
        onSuccess: (response) => onExportSuccess(response, "unamed_export.zip")
    },
});

export const exportFile = (filename) => ({
    type: EXPORT_FILE_BY_NAME,
    request: {
        url: `${URL_BASE_EXPORT}exported/${filename}`,
        method: "get",
        responseType: "blob",
    },
    meta: {
        onSuccess: (response) => onExportSuccess(response, "unamed_export.xlsx")
    },
});
export const deleteExportedFileByName = (filename, onSuccess, onError) => ({
    type: DELETE_EXPORTED_FILE,
    request: {
        url: `${URL_BASE_EXPORT}deleteexported/${filename}`,
        method: "put",
    },
    meta: {
        onSuccess: () => {
            if (onSuccess) {
                onSuccess();
            }
        },
        onError: () => {
            if (onError) onError();
        },
    },
});
export const deleteAllExportedFiles = (onSuccess, onError) => ({
    type: DELETE_ALL_EXPORTED_FILES,
    request: {
        url: `${URL_BASE_EXPORT}deleteexported/`,
        method: "put",
    },
    meta: {
        onSuccess: () => {
            if (onSuccess) {
                onSuccess();
            }
        },
        onError: () => {
            if (onError) onError();
        },
    },
});
export const fetchExportedFiles = (onSuccess, onError) => ({
    type: FETCH_EXPORTED_FILES,
    request: {
        url: `${URL_BASE_EXPORT}exportedfiles`,
        method: "get",
    },
    meta: {
        onSuccess: (response) => {
            if (onSuccess) onSuccess(response);
            return response;
        },
        onError: (error) => {
            if (onError) onError(error);
            throw error;
        },
    },
});
export const exportFilteredExecutionsXLSX = (
    sortField,
    sortOrder,
    processes,
    status,
    launchingDatetime,
    endDatetime,
    searchText,
    robots,
    triggers,
    tags,
    onSuccess,
    onError
) => ({
    type: EXPORT_FILTERED_EXECUTIONS,
    request: {
        url: encodeURI(`${URL_BASE_EXPORT}executions${searchText ? `?searchText=${searchText}` : ""}`),
        params: {
            sortField,
            sortOrder,
            processes: processes.join(","),
            status: status.join(","),
            triggers: triggers.join(","),
            robots: robots.join(","),
            tagsIds: tags.join(),
            launchingDatetime,
            endDatetime,
        },
    },
    meta: {
        onSuccess: (response) => {
            if (onSuccess) onSuccess(response);
            return response;
        },
        onError: (error) => {
            if (onError) onError(error);
            return error;
        }
    }
});
export const exportExecutionsXLSX = (processId, statusIds) => ({
    type: EXPORT_EXECUTIONS,
    request: {
        url: `${URL_BASE_EXPORT}process/executions`,
        params: {
            processId,
            status: statusIds?.join(","),
        },
    },
});
export const exportOccurrencesXLSX = (
    processes,
    fleetIds,
    loadedDatetime,
    completedDatetime,
    searchText,
    isPending = false,
    processExecutionIds = [],
    separateDetails,
    sortOrder,
    sortField,
    tagsIds
) => {
    const exportUrl = isPending
        ? encodeURI(`${URL_BASE_EXPORT}occurrences/pending${searchText ? `?searchText=${searchText}` : ""}`)
        : encodeURI(`${URL_BASE_EXPORT}occurrences${searchText ? `?searchText=${searchText}` : ""}`);

    return {
        type: EXPORT_OCCURENCES,
        request: {
            url: exportUrl,
            params: {
                fleetIds: fleetIds?.join(","),
                processes: processes?.join(","),
                loadedDatetime: formatDateTimeIsoParam(loadedDatetime),
                completedDatetime: formatDateTimeIsoParam(completedDatetime),
                processExecutionIds: processExecutionIds.join(","),
                separateDetails,
                sortField,
                sortOrder,
                tagsIds
            },
        },
    };
};
export const exportProcessExecutionExceptionXLSX = (
    processes,
    exceptionsType,
    exceptions,
    fleets,
    executionStartTime,
    executionEndTime,
    selected,
    hidden,
    searchText,
    sortOrder,
    sortField,
    tags
) => ({
    type: EXPORT_PROCESS_EXECUTION_EXCEPTIONS,
    request: {
        url: encodeURI(
            `${URL_BASE_EXPORT}process-execution/exceptions?processes=${processes?.join()}&exceptionsType=${exceptionsType?.join()}&exceptions=${exceptions?.join()}&fleetIds=${fleets
                .map((e) => e.id)
                .join()}${searchText ? `&searchText=${searchText}` : ""}`,
        ),
        responseType: "blob",
        params: {
            executionStartTime: formatDateTimeIsoParam(executionStartTime),
            executionEndTime: formatDateTimeIsoParam(executionEndTime),
            selectedExceptions: selected?.join(","),
            tagsIds: tags.join(),
            hidden,
            sortField,
            sortOrder
        },
    },
});
export const exportExceptionXLSX = (
    processes,
    exceptionsType,
    exceptions,
    fleets,
    executionStartTime,
    executionEndTime,
    selected = [],
    hidden,
    searchText,
    processExecutionIds = [],
    workFlowStatus = [],
    handlersIds = [],
    sortField,
    sortOrder,
    separateDetails = false,
    divisionsIds,
    tags
) => ({
    type: EXPORT_EXCEPTIONS,
    request: {
        url: encodeURI(`${URL_BASE_EXPORT}exceptions?processes=${processes?.join()}&exceptionsType=${exceptionsType?.join()}&exceptions=${exceptions?.join()}&fleetIds=${fleets
            .map((e) => e.id)
            .join()}${searchText ? `&searchText=${searchText}` : ""}`),
        params: {
            executionStartTime: formatDateTimeIsoParam(executionStartTime),
            executionEndTime: formatDateTimeIsoParam(executionEndTime),
            selectedExceptions: selected?.join(","),
            hidden,
            processExecutionIds: processExecutionIds.join(),
            workFlowStatus: workFlowStatus.join(),
            assignersIds: handlersIds.join(),
            tagsIds: tags?.join(),
            sortField,
            sortOrder,
            separateDetails,
            divisionsIds
        },
    },
});
export const exportAnalysisTableData = (selectedProcesses, from, to, kpi, customKpisIds, tags, page, size, exportAll = false) => {
  const processIds = selectedProcesses.map((e) => e.id);
  const tagsIds = tags?.map((e) => e.id)?.join() ?? "";
  return {
      type: EXPORT_ANALYSIS_TABLE_DATA,
      request: {
          url: `${URL_BASE_EXPORT}analysis/table`,
          params: {
              processIds: `${processIds?.join()}`,
              kpi: `${kpi.join()}`,
              from: formatDateTimeIsoParam(from),
              to: formatDateTimeIsoParam(to),
              customKpisIds: `${customKpisIds.join()}`,
              tagsIds,
              page,
              size,
              exportAll
          }
      },
  };
};
