import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  backButton: {
    marginRight: theme.spacing(1),
  },
  fleetForm: {
    width: "100%",
    padding: "10px",
  },
  contactInfo: {
    borderRight: "2px solid #cccccc",
  },
  validation_error: {
    color: "red",
  },
  processes: {
    marginTop: "10px",
    marginLeft: "10px",
  },
  headerData: {
    padding: "10px",
  },
  userAvatar: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  headerPath: {
    marginRight: "5px",
  },
  searchIcon: {
    padding: "0",
  },
  filter__sort: {
    "& .MuiListItemText-root": {
      marginTop: "0px",
      marginBottom: " 0px",
    },
    "& .MuiTypography-root": {
      lineHeight: 1,
    },
  },
  legend: {
    marginBottom: "15px",
    padding: "8px"
  },
}));
