import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@redux-requests/react";
import { connect, useDispatch, useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import Box from "@material-ui/core/Box";
import debounce from "lodash/debounce";
import {
  fetchAllFleets,
  fetchDivisionsForFilters,
  fetchProcesses, fetchTagsForFilters,
} from "../../../../redux/actions/services";
import {
  updateFilter,
  initFilter,
} from "../../../../redux/slices/processSlice";
import { FETCH_CURRENT_USER, FETCH_TAG_FOR_FILTERS } from "../../../../redux/constants";
import { isFleetAdministrator } from "../../../../util";
import CustomAutoComplete from "../../../../components/FormFields/CustomAutoComplete";
import { HasPermission } from "../../../../components/HasPermission";
import ClearFilter from "../../../../components/ClearFilter";
import { SearchFilter } from "../../../../components/Filter";
import NoDataMenu from "../../../../components/NoData/NoDataMenu";
import CustomSwitch from "../CustomSwitch";
import useStyles from "./style";

const sortFields = [
  { id: "latestStatus", label: "Status" },
  { id: "processKpi.exceptionCount", label: "exceptionCount" },
  { id: "processKpi.exceptionCount,DESC", label: "exceptionCount.descending" },
  { id: "processKpi.completedCount", label: "completedCount" },
  { id: "processKpi.completedCount,DESC", label: "completedCount.descending" },
  { id: "processKpi.pendingCount", label: "pendingCount" },
  { id: "processKpi.pendingCount,DESC", label: "pendingCount.descending" },
  { id: "processRestriction.priority", label: "processRestriction.priority" },
  { id: "processRestriction.priority,DESC", label: "processRestriction.priority.descending" }
]
function Filter() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const currentUser = useQuery({ type: FETCH_CURRENT_USER })?.data;
  const [searchText, setsearchText] = React.useState("")
  const [userDivision, setUserDivision] = useState();
  const [userFleets, setUserFleets] = useState();

  const filterProcess = useSelector(({ filterProcess: i }) => i);
  const tagsFilter = useQuery({ type: FETCH_TAG_FOR_FILTERS })?.data;

  const searchProcesses = (keyWord) => {
    dispatch(
      updateFilter({
        ...filterProcess,
        pageNo: 0,
        search: keyWord,
      }),
    );
  };

  const handleClear = () => {
    dispatch(initFilter())
    setsearchText("")
  }

  const debouncer = useCallback(
    debounce((nextValue) => searchProcesses(nextValue), 500),
    [filterProcess.fleet, filterProcess.showAll],
  );

  const handleChangeSearchText = (event) => {
    setsearchText(event.target.value);
    debouncer(event.target.value);
  };

  useEffect(() => {
    dispatch(
      fetchProcesses(
        filterProcess.search,
        filterProcess.fleet,
        filterProcess.division,
        filterProcess.showAll,
        filterProcess.tags,
        filterProcess.pageNo,
        filterProcess.pageSize,
        filterProcess.sortBy
      ),
    );
  }, [dispatch, filterProcess]);

  useEffect(() => {
    dispatch(fetchTagsForFilters());
    setsearchText(filterProcess.search);
  }, []);

  const handleShowAllProcesses = (event) => {
    dispatch(
      updateFilter({
        ...filterProcess,
        pageNo: 0,
        showAll: event.target.checked,
      }),
    );
  };

  const handleChangeFilter = (event, key) => {
    dispatch(
        updateFilter({
          ...filterProcess,
          [key]: event?.map(({ id }) => id)
        }),
    );
  };
  const handleSort = (value) => {
    dispatch(
        updateFilter({
          ...filterProcess,
          sortBy: value
        }),
    );
  };

  useEffect(() => {
    if (currentUser) { dispatch(fetchDivisionsForFilters()).then((res) => {
      setUserDivision(res?.data) }) }
  }, [currentUser])

  useEffect(() => {
    dispatch(fetchAllFleets()).then((res) => {
      setUserFleets(res.data);
    });
  }, [])

  return (
    <Grid container className={classes.filterRoot} justify="space-between">
      <Grid
        container
        item
        xs={11}
        className={classes.filter}
        justify="flex-start"
      >
        <Box
          component={Grid}
          item
          container
          xs={2}
          alignItems="center"
          pt="3px"
        >
          <FormControl fullWidth>
            <SearchFilter
              callback={handleChangeSearchText}
              fullWidth
              placeHolder={t("Search")}
              value={searchText}
              queryParams={filterProcess.search}
            />
          </FormControl>
        </Box>
        <Grid item xs={2}>
          <CustomAutoComplete
            multiple
            options={tagsFilter || []}
            optionLabel="name"
            value={tagsFilter?.filter(({ id }) => filterProcess.tags?.includes(id))}
            noOptionsNode={<NoDataMenu message={t("no.tags.message")} />}
            onChange={(e) => handleChangeFilter(e, "tags")}
            label="Tags"
          />
        </Grid>
        {!process.env.REACT_APP_DISABLE_PARTITIONING && (
          <Grid item xs={2}>
            <CustomAutoComplete
              multiple
              options={userDivision || []}
              optionLabel="divisionName"
              value={userDivision?.filter(({ id }) => filterProcess.division?.includes(id))}
              noOptionsNode={<NoDataMenu message={t("no.division.message")} />}
              onChange={(e) => handleChangeFilter(e, "division")}
              label={t("divisions.division")}
            />
          </Grid>
        )}
        {currentUser?.fleet?.instanceOwner
          && isFleetAdministrator(currentUser) && (
            <Grid
              container
              justify="flex-end"
              className={classes.center}
              item
              xs={2}
            >
              <CustomAutoComplete
                multiple
                options={userFleets || []}
                optionLabel="companyName"
                value={userFleets?.filter(({ id }) => filterProcess.fleet?.includes(id))}
                noOptionsNode={<NoDataMenu message={t("no.fleet.message")} />}
                onChange={(e) => handleChangeFilter(e, "fleet")}
                label={t("fleets.fleet")}
              />
            </Grid>
          )}
        <Grid item xs={2}>
          <CustomAutoComplete
              defaultValue={{ id: "processRestriction.priority", label: "processRestriction.priority" }}
              options={sortFields}
              optionLabel="label"
              value={sortFields?.filter(({ id }) => filterProcess.sortBy === id)}
              onChange={(e) => handleSort(e.id)}
              label={t("Sort By")}
          />
        </Grid>
        <HasPermission name="Enable/Disable process">
          <Grid
            direction="row"
            justify="flex-end"
            alignItems="center"
            className={classes.toggleMenu}
          >
            <CustomSwitch
              label={t("process.filter.showAll")}
              checked={filterProcess.showAll}
              labelPlacement="start"
              handleChange={handleShowAllProcesses}
            />
          </Grid>
        </HasPermission>
        <Grid className={classes.toggleMenu} direction="row">
          <ClearFilter clearFilter={handleClear} label={t("reporting.clear")} />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default connect(null, { fetchProcesses })(Filter);
