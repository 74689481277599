export const URL_PROCESSES = "/api/orchestrator/process/";
export const URL_BASE_EXPORT = "/api/export/";
export const URL_PROCESSES_KPI = "/api/orchestrator/process/kpi";
export const URL_RPAQUAL_PROCESSES = "/api/rpaqual/processes";
export const URL_SCHEDULE = "/api/orchestrator/schedule/";
export const URL_PROCESS_EXECUTION = "/api/orchestrator/process-execution/";
export const URL_WORK_QUEUE_ITEM = "/api/orchestrator/work_queue_item/";
export const URL_DOCUMENT = "/api/orchestrator/document/";
export const URL_DOCUMENT_FILE = "/api/orchestrator/document/load/";
export const URL_CURRENT_USER = "/current-user";
export const URL_UPDATE_PASSWORD = "/api/admin/user/userPassword";
export const URL_UPDATE_DEV_COST = `${URL_RPAQUAL_PROCESSES}/devCost`;
export const URL_ORCHESTRATOR = "/api/orchestrator/management";
export const URL_USER = "/api/admin/user";
export const URL_USER_PERMISSIONS = "/api/admin/user/permission";
export const URL_FLEET = "/api/admin/fleet";
export const URL_FLEET_v2 = "/api/orchestrator/fleets";
export const URL_DIVISION = "/api/orchestrator/divisions";
export const URL_DIVISION_ADMIN = "/api/admin/divisions";
export const URL_RPA_QUAL = "/api/rpaqual";
export const URL_RPA_QUAL_PROCESSES = `${URL_RPA_QUAL}/processes`;
export const URL_RPA_QUAL_PROCESSES_ARCHIVE = `${URL_RPA_QUAL_PROCESSES}/archive`;
export const URL_RPA_QUAL_EXPORT = `${URL_RPA_QUAL}/export`;
export const URL_PROCESSES_ANALYSIS = "/api/orchestrator/process/analysis";
export const URL_DEFAULT_ALERT = "/api/alert/default-alert/";
export const URL_CUSTOM_ALERT = "/api/alert/custom-alert/";
export const URL_ROBOT = "/api/orchestrator/robot/";
export const URL_BP_CONNECTOR = "/api/bpconnector/";
export const URL_UIPATH_CONNECTOR = "/api/uipathconnector/";
export const URL_SYNCHRO = "/api/synchro/";
export const URL_COST_ALLOCATIONS = "/api/orchestrator/financial/";
export const URL_COST_SHARED = "/api/orchestrator/shared/";
export const URL_COST_SHARED_LIST = "/api/orchestrator/shared/";
export const URL_ANALYTICS = "/api/orchestrator/analytics/";
export const URL_TAG = "/api/orchestrator/tags"
export const URL_TAG_ADMIN = "api/orchestrator/tags"

export const URL_COST_SHARED_ALL = "/api/orchestrator/shared/all/";
export const URL_PROCESSES_DATA_MAPPING = `${URL_PROCESSES}data-mappings`;
export const URL_EXCEPTION_WORKFLOW = "/api/orchestrator/exceptionWorkflow";
export const URL_LICENSES = "/api/admin/licenses"
export const URL_SR_QUEUES = "/api/srqueues";
export const URL_SR_QUEUES_MNGT = "/api/srqueues/queue";
export const URL_TAGS = "/api/orchestrator/tags"
export const URL_STEPPER_LOAD_STEPS = `${URL_RPA_QUAL}/params/steps`;
export const URL_PROCESS_UPDATED = `${URL_RPA_QUAL_PROCESSES}/update`;
export const URL_PROCESS_CALCULATE_FACTOR = `${URL_RPA_QUAL}/calculate`;
export const URL_DASHBOARD = "/api/orchestrator/dashboard/";
