import React, { useEffect, } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { useTranslation } from "react-i18next";
import { useQuery } from "@redux-requests/react";
import { useDispatch, useSelector } from "react-redux";
import get from "lodash/get";
import { toggleView } from "../../redux/slices/settingsSlice";
import { fetchProcesses } from "../../redux/actions/services/index";
import { updateFilter } from "../../redux/slices/processSlice";
import { FETCH_PROCESSES } from "redux/constants";
import TableCard from "./components/Process/TableCard";
import Filter from "./components/Filter";
import PageHeader from "../../components/PageHeader";
import ProcessNotFound from "../../assets/Process_Overview.svg";
import DataNotFound from "../../components/DataNotFound";
import CircularLoader from "../../components/Loaders/CircularLoader";
import CustomPagination from "./components/CustomPagination";
import useStyles from "./style";

function Service() {
  const dispatch = useDispatch();
  const classes = useStyles();

  const { data } = useQuery({ type: FETCH_PROCESSES });
  const { list: processesData } = data || {};
  const { resultsCount } = data || 0;
  // This use state is used to make sure you cannot edit the priority of 2 processes at the same time
  // it stores the id of the currently edited process, or 0 if no process is edited
  const [editId, setEditId] = React.useState(0);
  const [loading, setLoading] = React.useState(false);

  const { view } = useSelector((state) => state.settings);
  const handleToggleView = () => {
    dispatch(toggleView());
  };

  const filterProcess = useSelector(({ filterProcess: i }) => i);

  const dataNotFound = () => (
    <DataNotFound message={t("no.process.message")} icon={ProcessNotFound} />
  );

  useEffect(() => {
    setLoading(true);
    dispatch(
      fetchProcesses(
        filterProcess.search,
        filterProcess.fleet,
        filterProcess.division,
        filterProcess.showAll,
        filterProcess.tags,
        filterProcess.pageNo,
        filterProcess.pageSize,
        filterProcess.sortBy
      ),
    ).then(() => {
      setLoading(false);
    });
  }, [dispatch, filterProcess]);

  const { t } = useTranslation();

  const handlePageChange = (e, pageNo) => {
    dispatch(
        updateFilter({
          ...filterProcess,
          pageNo
        }),
    );
  };

  const handleRowPerPageChange = (e) => {
    dispatch(
        updateFilter({
          ...filterProcess,
          pageNo: 0,
          pageSize: e.target.value,
        }),
    );
  };

  const handleNextPerPage = (pageNo) => {
    dispatch(
        updateFilter({
          ...filterProcess,
          pageNo: pageNo + 1
        }),
    );
  };

  const handlePreviousPage = (pageNo) => {
    dispatch(
        updateFilter({
          ...filterProcess,
          pageNo: pageNo - 1
        }),
    );
  };

  const getItemKeyLabel = (filterIdLabel) => {
    switch (filterIdLabel) {
      case "latestStatus":
        return "processStatus";
      case "processKpi.exceptionCount":
      case "processKpi.exceptionCount,DESC":
        return "processDetail.exceptionCount";
      case "processKpi.completedCount":
      case "processKpi.completedCount,DESC":
        return "processDetail.completedCount";
      case "processKpi.pendingCount":
      case "processKpi.pendingCount,DESC":
        return "processDetail.pendingCount";
      default:
        return "";
  }
  }
    const sortBySelectedFilterThenPriority = (a, b) => {
      if (
        (get(a, getItemKeyLabel(filterProcess?.sortBy))
          === get(b, getItemKeyLabel(filterProcess?.sortBy))
          && ![
            "processRestriction.priority,DESC",
            "processRestriction.priority",
            "latestStatus",
          ].includes(filterProcess?.sortBy))
        || (filterProcess?.sortBy === "latestStatus"
          && statusData[a]?.label === statusData[b]?.label)
      ) {
        return a.priority - b.priority;
      }
      return null;
    };
  return (
    <Grid
      container
      spacing={4}
      className={classes.rootGlobal}
    >
      <PageHeader title="My Processes" />
      <Grid container>
        <Filter view={view} handleToggleView={handleToggleView} showListButton={processesData && processesData.length > 0} />
      </Grid>
      {!loading ? (
        <Grid container xs={12}>
          <Grid className={classes.processContainer} container spacing={2} item xs={12}>
            {processesData && processesData.length > 0 && (
            <Box
              component={Grid}
              marginTop="15px"
              padding="16px"
              container
              xs={12}
              // style={{ marginTop: "25px", padding: "16px" }}
            >
              <Grid container item xs={3}>
                <Grid container item xs={2} />
                <Grid
                  container
                  item
                  xs={8}
                  spacing={0}
                  direction="column"
                  justify="center"
                  className={classes.tableLabel}
                >
                  <Typography
                    variant="body2"
                    align="center"
                    className={classes.content}
                  >
                    {t("Process")}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container justify="space-around" xs={9} spacing={1}>
                <Grid
                  container
                  item
                  xs={1}
                  spacing={0}
                  direction="column"
                  justify="center"
                  className={classes.executionsTableLabel}
                >
                  <Typography
                    variant="body2"
                    align="center"
                    className={classes.content}
                  >
                    {t("Execution Count")}
                  </Typography>
                </Grid>

                <Grid
                  container
                  item
                  xs={1}
                  spacing={0}
                  direction="column"
                  justify="center"
                  className={classes.tableLabel}
                >
                  <Typography
                    variant="body2"
                    align="center"
                    className={classes.content}
                  >
                    {t("Items Count")}
                  </Typography>
                </Grid>

                <Grid
                  container
                  item
                  xs={1}
                  spacing={0}
                  direction="column"
                  justify="center"
                  className={classes.tableLabel}
                >
                  <Typography
                    variant="body2"
                    align="center"
                    className={classes.content}
                  >
                    {t("Process CompletedItems")}
                  </Typography>
                </Grid>

                <Grid
                  container
                  item
                  xs={1}
                  spacing={0}
                  direction="column"
                  justify="center"
                  className={classes.tableLabel}
                >
                  <Typography
                    variant="body2"
                    align="center"
                    className={classes.content}
                  >
                    {t("process.history.exceptionItems")}
                  </Typography>
                </Grid>

                <Grid
                  container
                  item
                  xs={1}
                  spacing={0}
                  direction="column"
                  justify="center"
                  className={classes.tableLabel}
                >
                  <Typography
                    variant="body2"
                    align="center"
                    className={classes.content}
                  >
                    {t("dashboard.graph.pendingItems")}
                  </Typography>
                </Grid>

                <Grid
                  container
                  item
                  xs={1}
                  spacing={0}
                  direction="column"
                  justify="center"
                  className={classes.tableLabel}
                >
                  <Typography
                    variant="body2"
                    align="center"
                    className={classes.content}
                  >
                    {t("Roi")}
                  </Typography>
                </Grid>

                <Grid
                  container
                  item
                  xs={1}
                  spacing={0}
                  direction="column"
                  justify="center"
                  className={classes.tableLabel}
                >
                  <Typography
                    variant="body2"
                    align="center"
                    className={classes.content}
                  >
                    {t("Break-even")}
                  </Typography>
                </Grid>
                <Grid
                  container
                  item
                  xs={1}
                  spacing={0}
                  direction="column"
                  justify="center"
                  className={classes.tableLabel}
                >
                  <Typography
                    variant="body2"
                    align="center"
                    className={classes.content}
                  >
                    {t("Status")}
                  </Typography>
                </Grid>
                <Grid
                  container
                  item
                  xs={1}
                  spacing={0}
                  direction="column"
                  justify="center"
                  className={classes.priorityTableLabel}
                >
                  <Typography
                    variant="body2"
                    align="center"
                    className={classes.content}
                  >
                    {t("Priority")}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            )}
            {processesData && processesData.length > 0
              ? processesData?.sort(sortBySelectedFilterThenPriority)?.map((process, index) => (
                <Grid
                  item
                  xs={12}
                  key={`process-list-table-card-${index}`}
                >
                  <TableCard process={process} index={index} statusData={statusData} editId={editId} setEditId={setEditId} />
                </Grid>
              ))
              : dataNotFound()}
          </Grid>
        </Grid>
      ) : (
        <CircularLoader />
      )}
      <Grid container xs={12} direction="row" justify="flex-end">
        {!loading && processesData && processesData.length > 0 && (
        <CustomPagination
                count={resultsCount}
                rowsPerPage={filterProcess.pageSize}
                page={filterProcess.pageNo}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handleRowPerPageChange}
                onNext={handleNextPerPage}
                onPrevious={handlePreviousPage}
            />
        )}
      </Grid>
    </Grid>
  );
}

const statusData = {
  PENDING: { label: "Pending" },
  RUNNING: { label: "Executing" },
  PENDING_SR: { label: "PENDING_SR" },
  DONE: { label: "Ready" },
  UNKNOWN: { label: "Unknown" },
  EXCEPTION: { label: "Ready" },
  STOPPED: { label: "Ready" },
  COMPLETED: { label: "Ready" },
  DEBUGGING: { label: "Ready" },
  ARCHIVED: { label: "Ready" },
  WARNING: { label: "Ready" },
  STOPPING: { label: "Executing" },
};

export default Service;
