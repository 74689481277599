import React, { useEffect, useMemo } from "react";
import { Query } from "@redux-requests/react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import get from "lodash/get";
import useStyles from "./style.js";
import CircularLoader from "../../../components/Loaders/CircularLoader";
import AdminTableHeader from "./AdminTableHeader";
import AdminTableRow from "./AdminTableRow";
import AdminTableFilter from "./AdminTableFilter";
import DataNotFound from "../../../components/DataNotFound";
import OrchestratorNotFound from "../../../assets/No_Orchestrator.svg";
import UserNotFound from "../../../assets/No_Users.svg";
import ClientNotFound from "../../../assets/No_Client.svg";
import { updateFilter } from "../../../redux/slices/adminFilterSlice.js";
import CustomPagination from "pages/Services/components/CustomPagination/index.js";

export default function AdminTable({
  displayFields,
  headerCells,
  entityBaseUrl,
  enableDisableEntity,
  resetEntity,
  fetchEntities,
  deleteEntity,
  preDeleteEntity,
  deleteIcon,
  restoreIcon,
  deleteEntities,
  entityQuery,
  statusList,
  sortByList,
  labels,
  searchField,
  rowCustomActions,
  isUserAdministrationPage,
  isFleetAdministrationPage,
  isOrchestratorAdministrationPage,
  isRobotAdministrationPage,
  isDivisionAdministrationPage,
  isTagAdministrationPage,
  isQueueAdministrationPage,
  statusField,
  showStatusSwitch = true,
  enableDeleteMultiple = true,
  enableEdit = true,
  enableDelete = true,
  openTagForm,
  archiveEntity,
  archiveEntities,
  addButtonText,
  showAddIcon
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const [showTableHeader, setShowTableHeader] = React.useState(true)

  const [countUsers, setCountUsers] = React.useState(0);
  const [selected, setSelected] = React.useState([]);
  const { t } = useTranslation();
  const [fetchedData, setFetchedData] = React.useState([]);
  const filter = useSelector(({ adminFilter }) => adminFilter);
  const handleChangeStatus = (event) => {
    const statuses = event?.map(({ code }) => code);
    if (isRobotAdministrationPage && statuses.includes("OFFLINE")) statuses.push("DISCONNECTED", "MISSING", "UNKNOWN", "LOGGED OUT", "PRIVATE");
    if (isRobotAdministrationPage && statuses.includes("WORKING")) statuses.push("WARNING", "ACTIVE");
    dispatch(
      updateFilter({
        status: statuses,
        pageNo: 0,
      }),
    );
  };

  const handleChangeOrchestrator = (value) => {
    dispatch(
      updateFilter({
        orchestrator: value,
        pageNo: 0,
      }),
    );
  };

  const handleChangeFleet = (values) => {
    dispatch(
      updateFilter({
        fleets: values?.map(({ id }) => id),
        pageNo: 0,
      }),
    );
  };

  const handleChangeDivisions = (values) => {
    dispatch(
      updateFilter({
        divisions: values?.map(({ id }) => id),
        pageNo: 0,
      }),
    );
  };

  const handleChangeSortBy = (value) => {
    dispatch(
      updateFilter({
        sortBy: value?.code,
        pageNo: 0,
      }),
    );
  };
  const handleFilterValueChange = (value) => {
    dispatch(
      updateFilter({
        pageNo: 0,
        searchCriteria: value,
      }),
    );
  };

  const handleSelectedAll = (checked) => {
    const data = fetchedData?.map((d) => d.id);
    if (!checked) {
      const filteredData = selected.filter((fd) => !data.includes(fd));
      setSelected(filteredData);
    } else {
      setSelected(data);
    }
  };

  const handleChangePage = (event, newPage) => {
    dispatch(
      updateFilter({
        pageNo: newPage,
      }),
    );
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(
      updateFilter({
        pageNo: 0,
        pageSize: parseInt(event.target.value, 10),
      }),
    );
  };

  const handleChangeShowDeleted = (event) => {
    dispatch(
      updateFilter({
        showDeleted: event.target.checked,
      }),
    );
  };

  const fetchEntitiesReset = () => {
    const pageNo = (countUsers - 1) % filter[filter.currModule].pageSize === 0
      ? filter[filter.currModule].pageNo - 1
      : filter[filter.currModule].pageNo;
    dispatch(
      updateFilter({
        pageNo: pageNo === -1 ? 0 : pageNo,
      }),
    );
    setSelected([]);
  };
  const isAllSelected = useMemo(
    () => fetchedData?.every((d) => selected?.includes(d.id)),
    [fetchedData, selected],
  );

  useEffect(() => {
    dispatch(fetchEntities({ ...filter[filter.currModule] }));
  }, []);

  useEffect(() => {
    dispatch(fetchEntities({ ...filter[filter.currModule] }));
  }, [dispatch, fetchEntities, filter, history.location.pathname]);

  const fetchEntitiesWithFilter = () => dispatch(fetchEntities({ ...filter[filter.currModule] }));

  const handleNextPage = (page) => {
  dispatch(
    updateFilter({
      pageNo: page + 1,
    }),
  );
  }
  const handlePrevious = (page) => {
    dispatch(
      updateFilter({
        pageNo: page - 1,
      }),
    );
  }

  return (
    <div>
      <AdminTableFilter
        statusList={statusList}
        sortByList={sortByList}
        pageSize={filter.pageSize}
        fetchEntities={fetchEntities}
        className={classes.userFilter}
        searchLabel={labels.searchPlaceHolder}
        searchField={searchField}
        handleChangeStatus={handleChangeStatus}
        handleChangeOrchestrator={handleChangeOrchestrator}
        handleFilterValueChange={handleFilterValueChange}
        handleChangeSortBy={handleChangeSortBy}
        isUserAdministrationPage={isUserAdministrationPage}
        isRobotAdministrationPage={isRobotAdministrationPage}
        handleChangeFleets={handleChangeFleet}
        handleChangeDivisions={handleChangeDivisions}
        isDivisionAdministrationPage={isDivisionAdministrationPage}
        isOrchestratorAdministrationPage={isOrchestratorAdministrationPage}
        isTagAdministrationPage={isTagAdministrationPage}
        entityBaseUrl={entityBaseUrl}
        showAddIcon={showAddIcon}
        addButtonText={addButtonText}
        handleChangeShowDeleted={handleChangeShowDeleted}
      />
      { showTableHeader && <AdminTableHeader
        headerCells={headerCells}
        selected={selected}
        setSelected={setSelected}
        handleSelectedAll={handleSelectedAll}
        checkedAll={
          fetchedData?.length > 0
          && fetchedData.every((d) => selected.includes(d.id))
        }
        checkedindeterminate={
          fetchedData?.length > 0 && !isAllSelected
          && fetchedData.some((d) => selected.includes(d.id))
        }
        fetchedData={fetchedData}
        fetchEntities={fetchEntities}
        deleteEntities={deleteEntities}
        deleteEntitiesLabel={labels.deleteEntities}
        deleteAllLabel={labels?.deleteAll}
        page={filter[filter.currModule].pageNo}
        rowsPerPage={filter[filter.currModule].pageSize}
        showDeleted={filter[filter.currModule].showDeleted}
        isUserAdministrationPage={isUserAdministrationPage}
        isFleetAdministrationPage={isFleetAdministrationPage}
        isOrchestratorAdministrationPage={isOrchestratorAdministrationPage}
        isRobotAdministrationPage={isRobotAdministrationPage}
        isQueueAdministrationPage={isQueueAdministrationPage}
        deleteIcon={deleteIcon}
        enableDeleteMultiple={enableDeleteMultiple}
        confirmActionLabel={labels.confirmAction}
        isDivisionAdministrationPage={isDivisionAdministrationPage}
        isTagAdministrationPage={isTagAdministrationPage}
        archiveEntities={archiveEntities}
        archiveConfirmationMsg={labels.archiveConfirmationMsg}
        archiveTooltip={labels.archiveTooltip}
        entityDeleted={labels.entityDeleted}
        confirmActionArchive={labels.confirmActionArchive}
        tagDeletedSuccess={labels.tagDeletedSuccess}
        tagArchivedSuccess={labels.tagArchivedSuccess}
      />}
      <Query type={entityQuery} loadingComponent={CircularLoader}>
        {({ data }) => {
          if (!data?.content?.length) setCountUsers(0);
          setFetchedData(data?.content);
          const length = data.totalElements;
          setCountUsers(length);
          (data?.content?.length > 0) ? setShowTableHeader(true) : setShowTableHeader(false)
          return data?.content?.length > 0
            ? data?.content?.map((row, index) => (
              <AdminTableRow
                  key={`admin-table-${index}-${Math.random()}`}
                  row={row}
                  index={index}
                  handleClick={() => {
                    history.push(`${entityBaseUrl}/${get(row, "id")}`);
                  }}
                  selected={selected}
                  setSelected={setSelected}
                  displayFields={displayFields}
                  entityBaseUrl={entityBaseUrl}
                  enableDisableEntity={enableDisableEntity}
                  resetEntity={resetEntity}
                  deleteEntity={deleteEntity}
                  archiveEntity={archiveEntity}
                  preDeleteEntity={preDeleteEntity}
                  deleteIcon={deleteIcon}
                  restoreIcon={restoreIcon}
                  enableLabel={labels.enableEntity}
                  enableConfirm={labels.enableConfirm}
                  disableLabel={labels.disableEntity}
                  disableConfirm={labels.disableConfirm}
                  deleteEntityLabel={labels.deleteEntity}
                  restoreEntityLabel={labels.restoreEntity}
                  deleteEntityTooltip={labels.deleteEntityTooltip}
                  deleteEntityConfirmationButton={
                    labels.deleteEntityConfirmButton
                  }
                restoreEntityConfirmationButton={
                    labels.restoreEntityConfirmButton
                  }
                rowCustomActions={rowCustomActions}
                isUserAdministrationPage={isUserAdministrationPage}
                isRobotAdministrationPage={isRobotAdministrationPage}
                isFleetAdministrationPage={isFleetAdministrationPage}
                isOrchestratorAdministrationPage={
                    isOrchestratorAdministrationPage
                  }
                  isQueueAdministrationPage={isQueueAdministrationPage}
                  statusField={statusField}
                  fetchEntitiesReset={fetchEntitiesReset}
                  showStatusSwitch={showStatusSwitch}
                  restoreEntityTooltip={labels.restoreEntityTooltip}
                  enableDeleteMultiple={enableDeleteMultiple}
                  fetchEntities={fetchEntitiesWithFilter}
                  entityDeleted={labels.entityDeleted}
                  enableEdit={enableEdit}
                  enableDelete={enableDelete}
                  isDivisionAdministrationPage={isDivisionAdministrationPage}
                  isTagAdministrationPage={isTagAdministrationPage}
                  open={open}
                  openTagForm={openTagForm}
                  archiveConfirmationMsg={labels.archiveConfirmationMsg}
                  archivedChip={labels.archivedChip}
                  archiveTooltip={labels.archiveTooltip}
                  confirmActionArchive={labels.confirmActionArchive}
                  tagDeletedSuccess={labels.tagDeletedSuccess}
                  tagArchivedSuccess={labels.tagArchivedSuccess}
                />
              ))
            : (isOrchestratorAdministrationPage && (
            <DataNotFound
              message={t("no.orchestrator.message")}
              icon={OrchestratorNotFound}
            />
            ))
                || (isUserAdministrationPage && (
                  <DataNotFound
                    message={t("no.users.message")}
                    icon={UserNotFound}
                  />
                ))
                || (isFleetAdministrationPage && (
                  <DataNotFound
                    message={t("no.fleets.message")}
                    icon={ClientNotFound}
                  />
                ))
                || (isRobotAdministrationPage && (
                  <DataNotFound
                    message={t("no.robots.message")}
                    icon={OrchestratorNotFound}
                  />
                ))
                || (isQueueAdministrationPage && (
                  <DataNotFound
                    message={t("queue.management.no-data")}
                    icon={OrchestratorNotFound}
                  />
                ))
              || (isDivisionAdministrationPage && (
              <DataNotFound
                      message={t("no.division.message")}
                      icon={OrchestratorNotFound}
                  />
              ))
              || (isTagAdministrationPage && (
                <DataNotFound
                        message={t("no.tags.message")}
                        icon={OrchestratorNotFound}
                    />
                ));
}}
      </Query>

      {showTableHeader && <CustomPagination
        rowsPerPageOptions={[5, 10, 25]}
        count={countUsers ?? 0}
        page={filter[filter.currModule].pageNo}
        rowsPerPage={filter[filter.currModule].pageSize}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        onChangePage={handleChangePage}
        onNext={handleNextPage}
        onPrevious={handlePrevious}
      />}
    </div>
  );
}
