import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  title: {
    marginLeft: 110,
  },
  image: {
    maxWidth: 50,
  },
  disabled: {
    opacity: 0.5,
  },
  btn: {
    textTransform: "none",
  },

  card: {
    width: "100%",
    fheight: "100%",
    padding: theme.spacing(2, 2),
  },
  cardHeader: {
    marginTop: theme.spacing(1),
  },
  gridContent: {
    padding: theme.spacing(2, 2),
  },
  lineContent: {
    padding: theme.spacing(2, 0),
  },
  contentHeader: {
    padding: theme.spacing(2, 1),
    height: "50%",
    marginBottom: theme.spacing(2),
  },
  contentHeaderTitle: {
    color: theme.palette.secondary.main,
    paddingLeft: theme.spacing(2),
    fontWeight: 600,
  },
  lineContentTitle: {
    fontWeight: "800",
  },
  content: {
    fontWeight: "600",
  },
  cardRoot: {
    radius: "15px",
    borderLeft: "10px solid",
    borderColor: theme.palette.secondary.main,
  },
  cardRootSR: {
    borderColor: theme.custom.color.color6,
  },
  robotAvatar: {
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
  clearPriority: {
    color: "red",
  },
  processCard: {
    minHeight: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  priorityLabel: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  okBtn: {
    color: "green"
  }
}));
