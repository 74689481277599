import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  userAvatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  avatarColor: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
  },
}));
