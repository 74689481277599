export const SUBMITTED = "SUBMITTED";
export const EXPORT_STATUS = {
    COLLECTING: "COLLECTING",
    EXPORTING: "EXPORTING",
    FINISHED: "FINISHED",
}
export const EXECUTION_STATUS = {
    PENDING_SR: "PENDING_SR",
    PENDING: "PENDING"
  }
export const ADMIN_MODULE = {
    ROBOTS: "robots"
}
