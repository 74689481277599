import React, { useEffect, useState } from "react";
import ButtonBase from "@material-ui/core/ButtonBase";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import {
  FormControl, IconButton, MenuItem, Select, Tooltip,
} from "@material-ui/core";
import { Clear, Done } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "@redux-requests/react";

import {
  saveProcessRestriction,
  checkIfPrioriryAlreadyExists,
  fetchProcesses,
  fetchProcessesDetails,
  fetchUnavailablePriorities
} from "../../../../../redux/actions/services";
import {
  formatImagePath,
  formtNumberByLanguage,
  numberRoiFloatFormat,
  rateFloatFormat,
} from "../../../../../util";
import ConfirmMessage from "../../../../../components/ConfirmMessage";
import useStyles from "./style.js";
import CustomizedDialogs from "../../ModalService";
import { roiInSufficientData } from "../../../../../WrapperContainer/components/AppBar/components/SRCards";
import { isPermitted } from "../../../../../components/HasPermission";
import PriorityChip from "../components/PriorityChip";
import QuestionMarkIcon from "../../../../../components/QuestionMarkIcon";
import StatusButton from "../../../../../components/StatusButton";
import { ReactComponent as ProcessDefaultIcon } from "assets/ProcessDefaultIcon.svg"
import { FETCH_CURRENT_USER } from "../../../../../redux/constants/index"
import { isSRQueuesDisabled } from "../../../../../util/constants/PermitedFeature";

function TableCard(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { process: _process, index, statusData } = props;
  const { editId, setEditId } = props;
  const currentUser = useQuery({ type: FETCH_CURRENT_USER }).data;
  const [processState, setProcessState] = useState(null);
  const breakEvenContent = ({ breakEvenUnit, breakEvenValue }) => {
    if (breakEvenUnit === undefined || breakEvenUnit === null) {
      return "--";
    }
    return breakEvenValue > 0
      ? `${t("ago", {
        value: numberRoiFloatFormat(breakEvenValue),
        unit: `${t(breakEvenUnit)}`,
      })}`
      : `${t("point.amortissage.negative", {
        value: numberRoiFloatFormat(breakEvenValue),
        unit: `${t(breakEvenUnit)}`,
      })}`;
  };

  const title = _process?.processDisplayName;
  const content = _process?.processDescription;
  const disabled = _process?.disabled;
  const hiddenProcess = _process.isDisabled;
  const priority = (_process?.priority === 0) ? "--" : _process?.priority;
  const insuffisantData = roiInSufficientData(_process?.processDetail?.roi);
  const roiValue = rateFloatFormat(_process?.processDetail?.roi);
  const roi = roiValue && roiValue !== "--" && !insuffisantData && !hiddenProcess ? (
    <>
      {roiValue}
      %
    </>
  ) : (
    <>
      --
      <QuestionMarkIcon titleContent={t("kpi.header.roi.tooltip.nodata")} />
    </>
  );

  const breakEvenValue = breakEvenContent(
    _process?.processDetail?.processBreakEven || {},
  );
  const breakEven = breakEvenValue
    && breakEvenValue !== "--"
    && !insuffisantData
    && !hiddenProcess ? (
      breakEvenValue
    ) : (
      <>
        --
        <QuestionMarkIcon
          titleContent={t("kpi.header.breakEven.tooltip.nodata")}
        />
      </>
    );

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const [openSlide, setOpenSlide] = React.useState(false);
  const [newPriority, setNewPriority] = React.useState(priority);
  const filterProcess = useSelector(({ filterProcess: i }) => i);

  const priorityArray = Array.from({ length: 100 }, (v, k) => k + 1).map(
    String,
  );
  const [availablePriorities, setAvailablePriorities] = React.useState(priorityArray);

  const handleChange = (event) => {
    setNewPriority(event.target.value);
  };

  const handleCloseSlide = () => {
    setOpenSlide(false);
  };

  const closeDialog = () => {
    setOpen(false);
    setEditId(0);
    setProcessState(null)
    dispatch(
      fetchProcesses(
        filterProcess.search,
        filterProcess.fleet,
        filterProcess.division,
        filterProcess.showAll,
        filterProcess.tags,
        filterProcess.pageNo,
        filterProcess.pageSize,
        filterProcess.sortBy
      ),
    );
  };
  const openAndResetValue = () => {
    setIsLoading(true)
    dispatch(fetchProcessesDetails(_process?.id)).then((res) => {
      setValue(0);
      setIsLoading(false)
      setProcessState(res.data);
      setOpen(true);
    });
  };
  const changePriority = (reset = true) => {
    const dataToSend = { priority: null };
    if (_process?.priority) dataToSend.priority = parseInt(_process?.priority);
    dataToSend.priority = reset ? null : parseInt(newPriority);
    dispatch(saveProcessRestriction(_process?.id, dataToSend)).then(() => {
      setEditId(0);
      dispatch(
        fetchProcesses(
          filterProcess.search,
          filterProcess.fleet,
          filterProcess.division,
          filterProcess.showAll,
          filterProcess.tags,
          filterProcess.pageNo,
          filterProcess.pageSize,
          filterProcess.sortBy
        ),
      );
    });
    if (reset) setNewPriority(priority);
  };

  const updatePriority = () => {
    dispatch(
      checkIfPrioriryAlreadyExists(_process.id, parseInt(newPriority)),
    ).then((result) => {
      if (result?.data === false) {
        changePriority(false);
      } else {
        setOpenSlide(true);
        setNewPriority(priority);
      }
    });
  };
  const bgColor = _process.isDisabled ? "#c8dae8" : "#fff";

  const onPriorityClick = () => {
    if (isPermitted(currentUser, "SLA")) {
      setEditId(_process.id);
      dispatch(fetchUnavailablePriorities(_process.id)).then((result) => {
        setAvailablePriorities(priorityArray.filter((p) => !result?.data.includes(Number(p))));
      })
    }
  };

  useEffect(() => {
    if (editId === _process.id) {
      setOpen(false);
      setNewPriority(priority);
    }
  }, [editId]);

  useEffect(() => {
    setEditId(0);
  }, [filterProcess.showAll]);

  return (
    <Grid item xs={12} key={index}>
      <Card
        key={index}
        elevation={1}
        className={clsx(classes.cardRoot, { [classes.cardRootSR]: !isSRQueuesDisabled && _process.queueId })}
        style={{ backgroundColor: bgColor }}
      >
        <ButtonBase
          disableRipple={disabled}
          className={classes.card}
          onClick={() => editId !== _process.id && openAndResetValue()}
        >
          <Grid container>
            <Grid container item xs={3}>
              <Grid
                item
                xs={2}
              >
                {_process.icon ? (
                  <img
                    src={formatImagePath(_process.icon)}
                    align="left"
                    alt="Service"
                    className={classes.image}
                  />
                ) : (
                  <ProcessDefaultIcon className={classes.image} />
                )}
              </Grid>
              <Grid
                container
                item
                direction="column"
                xs={8}
                spacing={0}
                justify="center"
              >
                <Grid item>
                  <Tooltip title={_process.queueId && !isSRQueuesDisabled ? t("process.assigned.srqueue.tooltip") : ""}>
                    <Typography
                      align="left"
                      className={classes.contentHeaderTitle}
                      variant="body2"
                    >
                      {title}
                    </Typography>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
            <Grid container item justify="space-around" xs={9}>
              <Grid
                item
                xs={1}
                spacing={0}
                className={classes.processCard}
              >
                <Typography variant="body2" align="center">
                  {formtNumberByLanguage(
                    _process?.processDetail?.executionCount,
                  )}
                </Typography>
              </Grid>
              <Grid
                item
                xs={1}
                spacing={0}
                className={classes.processCard}
              >
                <Typography variant="body2" align="center">
                  {formtNumberByLanguage(_process?.processDetail?.itemsCount)}
                </Typography>
              </Grid>

              <Grid
                item
                xs={1}
                spacing={0}
                className={classes.processCard}
              >
                <Typography variant="body2" align="center">
                  {formtNumberByLanguage(
                    _process?.processDetail?.completedCount,
                  )}
                </Typography>
              </Grid>

              <Grid
                item
                xs={1}
                spacing={0}
                className={classes.processCard}
              >
                <Typography variant="body2" align="center">
                  {formtNumberByLanguage(
                    _process?.processDetail?.exceptionCount,
                  )}
                </Typography>
              </Grid>

              <Grid
                item
                xs={1}
                spacing={0}
                className={classes.processCard}
              >
                <Typography variant="body2" align="center">
                  {formtNumberByLanguage(
                    _process?.processDetail?.pendingCount,
                  )}
                </Typography>
              </Grid>

              <Grid
                item
                xs={1}
                spacing={0}
                className={classes.processCard}
              >
                <Typography variant="body2" align="center">
                  {roi}
                </Typography>
              </Grid>

              <Grid
                item
                xs={1}
                spacing={0}
                className={classes.processCard}
              >
                <Typography variant="body2" align="center">
                  {breakEven}
                </Typography>
              </Grid>
              <Grid
                item
                direction="column"
                xs={1}
                spacing={1}
                className={classes.processCard}
              >
                <StatusButton
                    isProcess
                    status={_process.processStatus}
                    label={statusData[_process.processStatus]?.label || "Ready"}
                    t={t}
                />
              </Grid>
              <Grid
                item
                xs={1}
                spacing={1}
                className={classes.processCard}
              >
                <Typography variant="body2" align="center">
                  <span className={classes.lineContentValue}>
                    {editId !== _process.id && (
                      <PriorityChip
                        hidden={hiddenProcess}
                        priority={priority}
                        style={{ minWidth: "3.125rem", marginLeft: "2rem" }}
                        onClick={(event) => {
                          event.stopPropagation();
                          onPriorityClick();
                        }}
                      />
                    )}
                  </span>
                </Typography>
                <Typography variant="body2" align="center">
                  <span className={classes.lineContentValue}>
                    {editId === _process.id && (
                      <label className={classes.priorityLabel}>
                        <FormControl
                          variant="standard"
                        >
                          <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            value={newPriority}
                            onChange={(e) => handleChange(e)}
                          >
                            {availablePriorities.map((priority) => (
                              <MenuItem value={priority}>
                                <em>{priority}</em>
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <IconButton
                          component="span"
                          onClick={() => updatePriority()}
                          className={classes.okBtn}
                          size="small"
                        >
                          <Done />
                        </IconButton>
                        <IconButton
                          color="danger"
                          component="span"
                          size="small"
                          onClick={() => {
                            setEditId(0);
                          }}
                          className={classes.clearPriority}
                        >
                          <Clear />
                        </IconButton>
                      </label>
                    )}
                  </span>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </ButtonBase>
        {!disabled && open && processState?.id && (
          <CustomizedDialogs
            open={open}
            handleClose={closeDialog}
            handleClickOpen={() => openAndResetValue()}
            content={content}
            title={title}
            _process={processState}
            setProcess={setProcessState}
            value={value}
            setValue={setValue}
            callAfterClose={setEditId}
            changePriority={changePriority}
            statusData={statusData}
            isLoading={isLoading}
          />
        )}
        {openSlide && (
          <ConfirmMessage
            message={t("priority.error")}
            openStart={openSlide}
            onCancel={handleCloseSlide}
            onConfirm={handleCloseSlide}
            buttonConfirm={t("Accept")}
            buttonCancel={t("Cancel")}
          />
        )}
      </Card>
    </Grid>
  );
}

export default TableCard;
