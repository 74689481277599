import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  header: {
    width: "100%",
    display: "flex",
    background: "transparent",
    boxShadow: "none",
    paddingBottom: "0px",
  },
  content: {
    flex: "0 0 78%",
    width: "80%",
    flexDirection: "column",
  },
  headerActions: {
    flex: "0 0 22%",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    padding: theme.spacing(2, 3),
  },
  headerActionButtons: {
    padding: theme.spacing(0, 1),
    color: theme.custom.color.color10,
  },
  defaultIconsColor: {
    color: theme.custom.color.color10,
  },
  deleteColor: {
    color: theme.custom.color.color6,
  },
}));
