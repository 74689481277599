import React, { useEffect, useRef, useState } from "react";
import Box from "@material-ui/core/Box";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchDashboardWeeklyFTESaved,
} from "redux/actions/services/dashboard";
import { secondsToFTPTime } from "util";
import {
  chartPeriodLabels,
  LINE_CHART_DATA_LABELS,
  LINE_CHART_TOOLTIP_OPTIONS,
  LINE_CHART_X_AXES, MONITORING_TIME, MONITORING_TIME_Params,
} from "pages/newDashboard/dashboardUtils";
import { handleRequestMonitoringMode } from "pages/Dashboard/dashboardUtils";
import MixedChart from "pages/newDashboard/MixedChart";
import ChartSectionHeader from "pages/newDashboard/WeeklyView/ChartSectionHeader";
import CircularLoader from "components/Loaders/CircularLoader";
import DataNotFound from "components/DataNotFound";
import theme from "../../../../style/orchestratorTheme";
import useStyles from "pages/newDashboard/style";

const formattedData = (data) => ({
  labels: data?.chartPeriodLabels,
  datasets: [
    {
      yAxisID: "fte",
      data: data?.hoursSaved,
      fill: true,
      type: "bar",
      backgroundColor: theme.custom.color.chartColor1,
      barThickness: 12,
      borderRadius: 10,
      borderColor: theme.custom.color.chartColor1,
      borderWidth: 0,
    },
  ],
});

const options = (t, dataFormatter) => ({
  elements: {
    line: {
      tension: 0,
    },
  },
  legend: {
    display: false,
  },
  scales: {
    xAxes: LINE_CHART_X_AXES({ offset: true }),
    yAxes: [
      {
        id: "fte",
        display: false,
        position: "right",
        gridLines: {
          drawOnChartArea: false,
        },
        ticks: {
          beginAtZero: true,
          display: false,
        },
        offset: true,
      },
    ],
  },
  plugins: {
    datalabels: LINE_CHART_DATA_LABELS({ formatter: dataFormatter }, false),
  },
  layout: {
    padding: {
      top: 30,
    },
  },
  tooltips: {
    ...LINE_CHART_TOOLTIP_OPTIONS,
  },
});

export default function WeeklyFTESaved({ userFleet }) {
  const classes = useStyles()();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [containerHeight, setContainerHeight] = useState(null);
  const containerRef = useRef();
    const dispatch = useDispatch();
    const filterDashboard = useSelector(({ dashboardFilters }) => dashboardFilters);

    const [FTEsSavedData, setFTEsSavedData] = useState();
    const fetchWeeklyFTESaved = (withLoading = true) => {
        setIsLoading(withLoading);
        dispatch(
            fetchDashboardWeeklyFTESaved({
                ...filterDashboard
            })
        ).then((res) => {
            setFTEsSavedData(res.data);
            setIsLoading(false);
        });
    }

  const fetchIntervalRef = useRef(null);
  useEffect(() => {
    handleRequestMonitoringMode(MONITORING_TIME, fetchIntervalRef, fetchWeeklyFTESaved, MONITORING_TIME_Params)
    return () => {
      clearInterval(fetchIntervalRef.current)
    }
  }, [filterDashboard])

    useEffect(() => {
        fetchWeeklyFTESaved()
    }, [filterDashboard]);

  const dataFormatter = (value) => secondsToFTPTime(value ?? 0, userFleet?.hoursPerManDay, userFleet?.daysPerYear, t)

  const chartData = formattedData({
    ...FTEsSavedData,
    chartPeriodLabels: chartPeriodLabels(t, filterDashboard.selectedDurationValue),
  });
  const chartOption = options(t, dataFormatter);
  const headerDataList = [
    {
      label: t("Total time saved"),
      value: dataFormatter(FTEsSavedData?.totalHoursSaved),
    },
    {
      label: t("average.FTEs.saved"),
      value: dataFormatter(FTEsSavedData?.averageHoursSaved),
    },
  ];
  useEffect(() => {
    if (containerRef?.current) setContainerHeight(containerRef.current.clientHeight - 85);
  }, [containerRef]);
  return (
    <Box className={classes.sectionContainer} ref={containerRef}>
      <ChartSectionHeader
        title={t("Time Saved")}
        dataList={headerDataList}
        isLoading={isLoading}
      />
      {isLoading ? (
        <CircularLoader height={300} size={23} />
      ) : (
        FTEsSavedData?.hoursSaved?.length > 0 && (
          <MixedChart
            key={`SuccessRateChart-${containerHeight}`}
            data={chartData}
            options={chartOption}
            width={null}
            height={containerHeight}
          />
        )
      )}
      {!isLoading
        && (!FTEsSavedData?.hoursSaved
          || FTEsSavedData?.hoursSaved?.length === 0) && (
          <DataNotFound
            backgroundColor="transparent"
            boxShadow="none"
            iconHeight={100}
            iconWidth={100}
            height={300}
          />
        )}
    </Box>
  );
}
