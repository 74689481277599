import React from "react";
import get from "lodash/get";
import { useTranslation } from "react-i18next";
import { Typography, Grid } from "@material-ui/core";

export default function AlertsTableCell({ row, field }) {
  const { t } = useTranslation();

  const getFieldData = () => get(row, field.valuePath);

  return (
    <Grid item xs={field.gridSize} align={field.align}>
      {field?.customContent ? (
        field.customContent({
          field: field.valuePath,
          value: getFieldData(),
          index: get(row, "id"),
        })
      ) : (
        <Typography color="primary" variant="body2">
          {t(getFieldData())}
        </Typography>
      )}
    </Grid>
  );
}
