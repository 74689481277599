import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  CardActions,
  Tooltip,
  IconButton,
  Checkbox,
} from "@material-ui/core";
import TrashIcon from "@material-ui/icons/Delete";
import FolderIcon from "@material-ui/icons/Folder";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import { useQuery } from "@redux-requests/react";
import RobotStatusAction from "pages/Administration/RobotManagement/RobotStatusAction";
import { isActionPermitted } from "../../../../components/HasPermission/index.js";
import { isFleetAdministrator } from "../../../../util/index.js";
import ConfirmMessage from "../../../../components/ConfirmMessage";
import useStyles from "./style.js";
import { toast } from "react-toastify";
import {
    countQueuesSchedules,
    deleteQueuesSchedules,
    migrateQueuesSchedules
} from "../../../../redux/actions/services";
import { triggerQueueAction, updateQueueAction } from "../../../../redux/slices/executionManagerSlice";
import { ASK_DELETE_ONE_QUEUE, QUEUE_DELETED } from "util/constants";
import { disabledFunctionality } from "../../../../util/constants/PermitedFeature";

export default function AdminTableHeader({
  headerCells,
  selected,
  setSelected,
  handleSelectedAll,
  checkedAll,
  checkedindeterminate,
  fetchEntities,
  deleteEntities,
  deleteEntitiesLabel,
  page,
  rowsPerPage,
  isUserAdministrationPage = false,
  isRobotAdministrationPage = false,
  isFleetAdministrationPage = false,
  isOrchestratorAdministrationPage = false,
  isQueueAdministrationPage,
  enableDeleteMultiple,
  deleteAllLabel,
  confirmActionLabel,
  fetchedData,
  isDivisionAdministrationPage,
  isTagAdministrationPage,
  archiveEntities,
  archiveConfirmationMsg,
  archiveTooltip,
  entityDeleted,
  confirmActionArchive,
  tagArchivedSuccess
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [openDeletePopup, setopenDeletePopup] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [openPopup, setOpenPopup] = React.useState(false);
  const [isPopUpActionLoading, setIsPopUpActionLoading] = React.useState(false);
  const [popUpMessage, setPopUpMessage] = React.useState("");
  const [action, setAction] = React.useState("");
  const [confirmBtnText, setConfirmBtnText] = React.useState("");
  const [openMsgConfirmArchive, setOpenMsgConfirmArchive] = React.useState(false);
  const handleCloseConfirmationPopup = () => {
    setIsLoading(false);
    setopenDeletePopup(false);
    setOpenPopup(false);
  };

  const currentUser = useSelector(({ requests }) => get(requests, "queries.FETCH_CURRENT_USER.data"));
  const { data } = useQuery({ type: fetchEntities().type });
  const { queueAction } = useSelector(({ executionManager }) => executionManager);
  const onlyCurrentUserSelected = selected.length === 1 && selected[0] === Number(currentUser.id);
  const onlyCurrentUserInList = fetchedData.length === 1 && fetchedData[0].id === Number(currentUser.id);

  const deleteAll = (newSelected = selected) => {
      dispatch(
          deleteEntities(newSelected, onDeleteSuccess, () => {
              handleCloseConfirmationPopup();
              toast.error(t("something went wrong"))
          })
      );
  }
  const onDeleteSuccess = (response) => {
    setIsLoading(false);
    dispatch(fetchEntities({ pageNo: page, pageSize: rowsPerPage }));
    handleCloseConfirmationPopup();
    if (isUserAdministrationPage) {
      if (response.data > 0) {
        toast.error(t("users not deleted", { usersNumber: response.data }));
      }
      if (selected.length > response.data) {
        toast.success(t(entityDeleted));
      }
    }
    else toast.success(t(entityDeleted))
    setSelected([]);
  }
  React.useEffect(() => {
    if (queueAction === QUEUE_DELETED) {
      handleCloseConfirmationPopup();
      setSelected([]);
      dispatch(updateQueueAction({ queueAction: null }));
      dispatch(fetchEntities({ pageNo: page, pageSize: rowsPerPage }));
    }
  }, [queueAction]);

  const onClick = () => {
      setIsLoading(true);
      let newSelected = selected;
      if (isUserAdministrationPage)
      {
          newSelected = selected.filter((e) => e !== Number(currentUser.id));
      }
      if (isQueueAdministrationPage)
      {
          // if only one queue is selected , trigger action to AdminTableRow to reuse delete one queue logic
          if (newSelected.length === 1)
          {
              dispatch(
                  triggerQueueAction({
                      queueAction: ASK_DELETE_ONE_QUEUE,
                      id: newSelected[0]
                  })
                );
              return;
          }
          // else check if queues have schedules with robot and without
          dispatch(countQueuesSchedules(selected, handleQueuesCountSchedules))
      }
      else
      {
          deleteAll(newSelected)
      }
  }
  const handleQueuesCountSchedules = (response) => {
      const { haveSchedulesWithoutRobots, haveSchedulesWithRobots } = response.data;
        /*
      queues got schedules with robots          queues got  schedules without robots
            false                                          false                  =>  good to go
            true                                           false                  => we got robots we can migrate to bp , choose ?

            false                                          true
          {                                                     }            => schedules without robots we can't migrate ,
            true                                           true                  if proceed all shcedules and their executions will be deleted
         */

      if (!haveSchedulesWithRobots && !haveSchedulesWithoutRobots)
        {
            // delete all
            deleteAll()
            return;
        }
      if (haveSchedulesWithRobots && !haveSchedulesWithoutRobots)
        {
            // migrate to bp
            setAction("MIGRATE");
            setConfirmBtnText(t("confirm"));
            setOpenPopup(true);
            setPopUpMessage(t("queue.management.unassign.process.part2_plural", { nbSchedules: t("some") }));
            return;
        }
      if (haveSchedulesWithoutRobots)
        {
            // multiple schedules found for multiple queues , cant do multi migration , you might want to go one by one
            setAction("CANT_MIGRATE");
            setConfirmBtnText(t("Continue"));
            setOpenPopup(true);
            setPopUpMessage(t("cant perform multi queue migration"));
        }
  }
  const closePopUp = () => {
      setIsPopUpActionLoading(false)
      setOpenPopup(false)
      setOpenMsgConfirmArchive(false)
  }
  const confirm = () => {
      if (action === "MIGRATE") migrateSchedules();
      else
      {
        deleteAllSchedules();
      }
  }
  const migrateSchedules = () => {
      setIsPopUpActionLoading(true);
      dispatch(migrateQueuesSchedules(selected, (res) => {
          // proceed to delete all
          if (res.data) deleteAll();
          else
          {
              handleCloseConfirmationPopup();
              toast.error(t("something went wrong"))
          }
          closePopUp();
      }), () => {
          handleCloseConfirmationPopup();
          closePopUp()
      })
  }

  const deleteAllSchedules = () => {
      setIsPopUpActionLoading(true);
      dispatch(deleteQueuesSchedules(selected, () => {
          // proceed to delete all
          deleteAll();
          closePopUp();
      }), () => {
          handleCloseConfirmationPopup();
          toast.error(t("something went wrong"));
          closePopUp();
      })
  }
    const onConfirmeArchiveSuccess = () => {
        setOpenMsgConfirmArchive(false)
        setIsLoading(false)
        setSelected([])
        dispatch(fetchEntities())
        toast.success(t(tagArchivedSuccess))
    }
    const onConfirmeArchiveError = () => {
        setSelected([])
        setOpenMsgConfirmArchive(false)
        setIsLoading(false);
        toast.error("Unexpected Error")
    }
    const confirmerchive = () => {
        if (isTagAdministrationPage) {
            setIsLoading(true)
            setOpenMsgConfirmArchive(true)
            dispatch(archiveEntities(selected, onConfirmeArchiveSuccess, onConfirmeArchiveError))
        }
    };

  const fieldsHeaderWidth = headerCells.customActions ? 9 : 12;
  return (
    <Card className={classes.header}>
      <CardContent className={classes.content}>
        <Grid container>
          <Grid
            container
            style={{ height: "50px" }}
            item
            xs={fieldsHeaderWidth}
            justify="flex-start"
            alignItems="flex-end"
          >
            {headerCells?.fields?.map((cell, index) => (
              <Grid key={index} item xs={cell.gridSize}>
                <Typography
                  variant="body2"
                  align={cell.align}
                  color="textSecondary"
                >
                  {t(cell.label)}
                </Typography>
              </Grid>
            ))}
          </Grid>
          {headerCells.customActions ? (
            <Grid
              container
              style={{ height: "50px" }}
              item
              xs={3}
              justify="flex-start"
              alignItems="flex-end"
            >
              {headerCells?.customActions?.map((cell, index) => (
                <Grid key={index} item xs={cell.gridSize} align="center">
                  <Typography
                    variant="body2"
                    align={cell.align}
                    color="textSecondary"
                  >
                    {t(cell.label)}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          ) : null}
        </Grid>
      </CardContent>

      <CardActions className={classes.headerActions}>
        <Grid
          container
          xs={12}
          justify="flex-end"
          direction="row"
          alignItems="flex-end"
        >
          {isTagAdministrationPage && !disabledFunctionality && (isActionPermitted(currentUser, isTagAdministrationPage, "Delete tag") || isFleetAdministrator(currentUser)) && (
            <Grid item>
              <Tooltip placement="top" title={t(archiveTooltip)}>
                <IconButton
                  className={classes.headerActionButtons}
                  aria-label="archive"
                  disabled={isEmpty(selected)}
                  onClick={(e) => {
                      e.stopPropagation();
                      setPopUpMessage(t(archiveConfirmationMsg))
                      setOpenMsgConfirmArchive(true);
                  }}
                >
                  <FolderIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          )}
          {/* eslint-disable-next-line no-nested-ternary */}
          {enableDeleteMultiple ? (
            ((
              isUserAdministrationPage ? isFleetAdministrator(currentUser) : isActionPermitted(currentUser, isUserAdministrationPage, "Delete user")
              || ((isFleetAdministrationPage || isOrchestratorAdministrationPage || isRobotAdministrationPage || isTagAdministrationPage) && isFleetAdministrator(currentUser))
              || isActionPermitted(currentUser, isTagAdministrationPage, "Delete tag")
              || isActionPermitted(currentUser, isDivisionAdministrationPage, "Delete division")
            ))
              && data?.totalElements > 0 ? (
                <>
                  <Grid item>
                    {isRobotAdministrationPage
                    ? <RobotStatusAction selected={selected} setSelected={setSelected} classes={classes} items={fetchedData} />
                      : (
                        <Tooltip placement="top" title={t(deleteAllLabel)}>
                          <IconButton
                            className={classes.headerActionButtons}
                            aria-label="delete"
                            disabled={isEmpty(selected) || onlyCurrentUserSelected || onlyCurrentUserInList}
                            onClick={() => {
                              setopenDeletePopup(true);
                            }}
                          >
                            <TrashIcon />
                          </IconButton>
                        </Tooltip>
                    )}
                    <ConfirmMessage
                      message={t(deleteEntitiesLabel)}
                      openStart={openDeletePopup}
                      onCancel={handleCloseConfirmationPopup}
                      onConfirm={onClick}
                      buttonConfirm={t(confirmActionLabel)}
                      buttonCancel={t("Cancel")}
                      isLoading={isLoading}
                    />
                  </Grid>
                  <Grid item>
                    <Checkbox
                      checked={checkedAll && !onlyCurrentUserInList}
                      className={classes.headerActionButtons}
                      indeterminate={checkedindeterminate && !onlyCurrentUserSelected}
                      onChange={(e) => {
                        handleSelectedAll(e.target.checked);
                      }}
                      disabled={onlyCurrentUserInList}
                    />
                  </Grid>
                </>
            ) : (
              null
            )
          ) : (
            null
          )}
        </Grid>
      </CardActions>
      <ConfirmMessage
            message={popUpMessage}
            openStart={openPopup}
            onCancel={() => {
                closePopUp();
                handleCloseConfirmationPopup();
             }}
            onConfirm={confirm}
            buttonConfirm={confirmBtnText}
            buttonCancel={t("Cancel")}
            isLoading={isPopUpActionLoading}

        />
      <ConfirmMessage
            message={popUpMessage}
            openStart={openMsgConfirmArchive}
            onCancel={() => {
                closePopUp();
                handleCloseConfirmationPopup();
            }}
            onConfirm={confirmerchive}
            buttonConfirm={t(confirmActionArchive)}
            buttonCancel={t("Cancel")}
            isLoading={isLoading}
        />
    </Card>
  );
}
