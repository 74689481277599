import React from "react";
import { Tooltip } from "@material-ui/core";
import InfoOutlined from "@material-ui/icons/InfoOutlined";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  infoIcon: {
    fontSize: 13,
    marginLeft: theme.spacing(0.2),
    color: theme.custom.color.chartColor3
  },
}));

const QuestionMarkIcon = ({ titleContent }) => {
  const classes = useStyles();
  return (
    <Tooltip placement="top" title={titleContent}>
      <InfoOutlined className={classes.infoIcon} />
    </Tooltip>
  );
};

export default QuestionMarkIcon;
