import { React, useState, useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  Card, Grid, Paper, Typography, makeStyles,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import isEmpty from "lodash/isEmpty";
import EmptyDropSection from "../../../components/EmptyDropSection";
import SearchField from "../../../components/EmptyDropSection/SearchField";
import DataNotFound from "../../../components/DataNotFound";
import clsx from "clsx";
import useStyles from "./style.js";

// A little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

/**
 * Returns true if the item is a tag, false if not
 */
const isTag = (item) => Object.keys(item).includes("name");

const grid = 8;

const itemStyle = makeStyles((theme) => ({
  // some basic styles to make the items look a bit nicer
  item: (props) => ({
    userSelect: "none",
    padding: `${grid}px ${grid * 2}px`,
    margin: `0 0 ${grid}px 0`,
    boxShadow:
        "0px 1px 2px 0px rgb(172,186,255)",
    fontWeight: 400,
    fontSize: 14,
    borderRadius: "2px",
    background: props.isSelected ? theme.custom.color.containerBackground : theme.palette.background.default,
    borderLeft: "10px solid",
    borderColor: props.itemIsTag ? theme.custom.color.color6 : theme.custom.color.color2,
    // styles we need to apply on draggables
    ...props.draggableStyle,
  })
}));

const getItemStyle = (isDragging, draggableStyle, itemIsTag, isSelected = false) => itemStyle({
 isDragging, draggableStyle, itemIsTag, isSelected
}).item;

const ProcessAndTagSelector = ({
  availableProcesses,
  selectedProcesses,
  isDisabled,
  setAvailableProcesses,
  setSelectedProcesses,
  availableTags,
  selectedTags,
  setAvailableTags,
  setSelectedTags,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [availableProcessSearchTerm, setAvailableProcessSearchTerm] = useState("");
  const [availableTagSearchTerm, setAvailableTagSearchTerm] = useState("");
  const [assignedSearchTerm, setAssignedSearchTerm] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);

  useEffect(() => {
      let newItems = [];

      selectedTags && (newItems = newItems.concat(selectedTags));
      selectedProcesses && (newItems = newItems.concat(selectedProcesses));
      setSelectedItems(newItems);
  }, [selectedTags, selectedProcesses, availableTags, availableProcesses]);

  /**
 * Moves an item from one list to another list.
 */
  const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    // You can't move a tag in processes and vice versa
    if ((droppableSource.droppableId === "availableProcessDroppable" || droppableSource.droppableId === "availableTagDroppable")
    && (droppableDestination.droppableId === "availableProcessDroppable" || droppableDestination.droppableId === "availableTagDroppable")) {
      return;
    }

    // If we need to remove from selected, do it in the good list
    if (droppableSource.droppableId === "selectedDroppable") {
      if (isTag(removed) && droppableDestination.droppableId === "availableTagDroppable") {
         setSelectedTags(selectedTags.filter((element) => element.id !== removed.id));
      }
      else if (!isTag(removed) && droppableDestination.droppableId === "availableProcessDroppable") {
         setSelectedProcesses(selectedProcesses.filter((element) => element.id !== removed.id));
      }
    }

    // If we need to add in selected, do it in the good list
    if (droppableDestination.droppableId === "selectedDroppable") {
      if (isTag(removed)) selectedTags.splice(droppableDestination.index, 0, removed);
      else if (!isTag(removed)) selectedProcesses.splice(droppableDestination.index, 0, removed);
    } else {
      destClone.splice(droppableDestination.index, 0, removed);
    }

    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    result.availableProcessDroppable && !isTag(removed) && setAvailableProcesses(result.availableProcessDroppable);
    result.availableTagDroppable && isTag(removed) && setAvailableTags(result.availableTagDroppable);
  };

  const getList = (id) => {
    switch (id) {
      case "availableProcessDroppable":
        return availableProcesses;
      case "selectedDroppable":
        return selectedItems;
      case "availableTagDroppable":
        return availableTags;
      default:
        return undefined;
    }
  }

  const onDragEnd = async (result) => {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      const items = reorder(
        getList(source.droppableId),
        source.index,
        destination.index,
      );

      // let state = { items };
      if (source.droppableId === "selectedDroppable") {
        const tagsItems = Array.from(selectedTags);
        const processItems = Array.from(selectedProcesses);
        setSelectedTags(tagsItems);
        setSelectedProcesses(processItems);
      } else if (source.droppableId === "availableProcessDroppable") {
        setAvailableProcesses(items);
      } else {
        setAvailableTags(items);
      }
    } else {
      move(
        getList(source.droppableId),
        getList(destination.droppableId),
        source,
        destination,
      );
    }
  };
  const isBothProcessAreasEmpty = isEmpty(selectedProcesses) && isEmpty(availableProcesses);
  const isBothTagAreasEmpty = isEmpty(selectedTags) && isEmpty(availableTags);
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Grid item container xs={12}>
        <Grid
          item
          container
          className={clsx(classes.dropabbleTitle, classes.processContainer)}
        >
          <Typography className={classes.droppableTitle}>{t("processesSelector.availableProcess")}</Typography>
        </Grid>
        <Grid item className={classes.processSeparator} />
        <Grid
          item
          container
            className={clsx(classes.dropabbleTitle, classes.processContainer)}
        >
          <Typography className={classes.droppableTitle}>{t("processTagSelector.assignedProcessTags")}</Typography>
        </Grid>
        <Grid item className={classes.processSeparator} />
        <Grid
          item
          container
            className={clsx(classes.dropabbleTitle, classes.processContainer)}
        >
          <Typography className={classes.droppableTitle}>{t("tagSelector.availableTags")}</Typography>
        </Grid>
        <Grid item container xs={12}>
          <SearchField
              searchTerm={availableProcessSearchTerm}
              setSearchTerm={setAvailableProcessSearchTerm}
              placeholder={t("search.available.processes")}
              disabled={isEmpty(availableProcesses)}
              justify="flex-start"
              xsSize={4}
          />
          <SearchField
              searchTerm={assignedSearchTerm}
              setSearchTerm={setAssignedSearchTerm}
              placeholder={t("search.assigned.processes")}
              disabled={isEmpty(selectedItems)}
              justify="flex-start"
              xsSize={4}
          />
          <SearchField
              searchTerm={availableTagSearchTerm}
              setSearchTerm={setAvailableTagSearchTerm}
              placeholder={t("search.available.tags")}
              disabled={isEmpty(availableTags)}
              justify="flex-start"
              xsSize={4}
          />
        </Grid>
      </Grid>
      <Grid item container xs={12}>
        <Grid item container direction="column" className={classes.processContainer}>
          <Card className={classes.card}>
            <Droppable droppableId="availableProcessDroppable">
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  className={classes.droppableContainer}
                >
                  {/* eslint-disable-next-line no-nested-ternary */}
                  {isBothProcessAreasEmpty ? <DataNotFound iconHeight={40} />
                      : isEmpty(availableProcesses)
                          ? <EmptyDropSection />
                  : availableProcesses?.map((item, i) => ({ ...item, i })).filter((item) => (availableProcessSearchTerm.trim() !== "" ? (item?.processName?.toLowerCase()?.includes(availableProcessSearchTerm.trim()) || item?.processDescription?.processDisplayName?.toLowerCase()?.includes(availableProcessSearchTerm.trim())) : true))?.map((item) => (
                    <Draggable
                      key={`available-process-draggable-${item.id}`}
                      draggableId={`available-process-draggable-${item.id}`}
                      index={item.i}
                      isDragDisabled={isDisabled}
                    >
                      {(provided, snapshot) => (
                        <Paper
                          elevation={0}
                        >
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style,
                              isTag(item)
                          )}
                          >
                            {item?.processName || item?.processDescription?.processDisplayName}
                          </div>
                        </Paper>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </Card>
        </Grid>
        <Grid item className={classes.processSeparator} />
        <Grid item container justify="flex-end" className={classes.processContainer}>
          <Card className={classes.card}>
            <Droppable droppableId="selectedDroppable">
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  className={clsx(classes.droppableList, classes.droppableContainer)}
                >
                  {isEmpty(selectedItems) ? (
                    <EmptyDropSection />
                  )
                      : (
                      selectedItems?.map((item, i) => ({ ...item, i })).filter((item) => (assignedSearchTerm.trim() !== "" ? item?.processName?.toLowerCase()?.includes(assignedSearchTerm.trim()) || item?.processDescription?.processDisplayName?.toLowerCase()?.includes(assignedSearchTerm.trim()) || item?.name?.toLowerCase()?.includes(assignedSearchTerm.trim()) : true))?.map((item) => (
                        <Draggable
                              key={`selected-process-draggable-${item.id}`}
                              draggableId={`selected-process-draggable-${item.id}`}
                              index={item.i}
                              isDragDisabled={isDisabled}
                          >
                          {(provided, snapshot) => (
                            <Paper elevation={0}>
                              <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      className={getItemStyle(
                                          snapshot.isDragging,
                                          provided.draggableProps.style,
                                          isTag(item),
                                          true
                                      )}
                                  >
                                {isTag(item) ? item.name : (item?.processName || item?.processDescription?.processDisplayName)}
                              </div>
                            </Paper>
                            )}
                        </Draggable>
                      ))
                  )}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </Card>
        </Grid>
        <Grid item className={classes.processSeparator} />
        <Grid item className={classes.processContainer}>
          <Card className={classes.card}>
            <Droppable droppableId="availableTagDroppable">
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  className={classes.droppableContainer}
                >
                  {/* eslint-disable-next-line no-nested-ternary */}
                  {isBothTagAreasEmpty ? <DataNotFound iconHeight={40} />
                      : isEmpty(availableTags)
                          ? <EmptyDropSection />
                  : availableTags?.map((item, i) => ({ ...item, i })).filter((item) => (availableTagSearchTerm.trim() !== "" ? item?.name?.toLowerCase()?.includes(availableTagSearchTerm.trim()) : true))?.map((item) => (
                    <Draggable
                      key={`available-process-draggable-${item.id}`}
                      draggableId={`available-process-draggable-${item.id}`}
                      index={item.i}
                      isDragDisabled={isDisabled}
                    >
                      {(provided, snapshot) => (
                        <Paper
                          elevation={0}
                        >
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style,
                              true
                            )}
                          >
                            {item.name}
                          </div>
                        </Paper>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </Card>
        </Grid>
      </Grid>
    </DragDropContext>
  );
};

export default ProcessAndTagSelector;
