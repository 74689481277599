import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    backgroundColor: theme.custom.color.containerBackground,
  },
  content: {
    flexGrow: 1,
    marginLeft: theme.spacing(8),
    position: "relative",
    transition: theme.transitions.create("padding", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    height: "calc(100vh - 55px)",
    overflowY: "auto",
    backgroundColor: theme.custom.color.containerBackground,
    maxWidth: "100vw",
  },
  smallContent: {
    marginLeft: theme.custom.variables.menuLeftWidth,
  },
  container: {
    backgroundColor: theme.custom.color.containerBackground,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  },
  isFullScreen: {
    marginLeft: 0,
  },
  copyright: { marginTop: "5px" },
  typography: {
    paddingTop: "30px",
    backgroundColor: theme.custom.color.containerBackground,
  },
  exportContainer: {
    display: "flex",
    justifyContent: "flex-end",
    position: "sticky",
    bottom: "20px",
    marginRight: "8px"
  },
  speedDialWrapper: {
    position: "fixed",
    bottom: "16px",
    right: "0px",
  },
  revertMargin: {
    marginLeft: "revert"
  },
  noMargin: {
    marginLeft: ""
  },
  logo: {
    width: 80,
    marginRight: 5,
  },
}));
