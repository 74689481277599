// eslint-disable-next-line import/no-anonymous-default-export
export default {
  "dashboar.refresh": "Refresh",
  "dashboard.refresh.desc": "Every {{time}} {{timeUnit}}",
  "All rights reserved": "All rights reserved.",
  Dashboard: "Performance dashboard",
  Planner: "Resource planner",
  "Resource Planner": "Resource planner",
  "Business management": "Business management",
  Orders: "Orders",
  Invoices: "Invoices",
  Regulations: "Payments",
  Suppliers: "Suppliers",
  Rapports: "Reporting",
  Search: "Search",
  "Add your Invoice": "Add your Invoice or ",
  browse: "browse",
  "Show all": "Show all",
  Supplier: "Supplier",
  "Invoice No.": "Invoice No.",
  "restriction.form.estimationExecutaion.required.error":
      "Please enter an execution time",
  "restriction.form.allowedSession": "Number of allowed parallel executions",
  "restriction.form.to.error": "Date must be greater than ",
  "restriction.form.allDays": "All days",
  "restriction.form.MinimumExecutionFrequency": "Minimum execution frequency",
  "user has exception item": "This user account has exceptions attached to it.",
  "Unexpected Error": "Unexpected Error.",
  "robot.hidden.success": "Robot {{status}} successfully",
  hidden: "hidden",
  unhidden: "unhidden",
  "user deleted successfully": "User has been deleted successfully.",
  "users not deleted": "{{usersNumber}} user(s) could not be deleted due to having exceptions assigned to them.",
  "fleet deleted successfully": "Group has been deleted successfully.",
  "orchestrator deleted successfully": "Orchestrator has been deleted successfully.",
  "queue deleted successfully": "SR Queue has been deleted successfully.",
  Mon: "Mon",
  Tue: "Tue",
  Wed: "Wed",
  Thu: "Thu",
  Fri: "Fri",
  Sat: "Sat",
  Sun: "Sun",
  "restriction.form.numberInteger.error": "Please enter a valid integer number",
  "restriction.form.minValue.error":
      "Value must be greater than or equal to 0.",
  "restriction.form.maxValue.error":
      "Number of digits must be less than or equal to 9.",

  "restriction.form.frequency": "Frequency",
  "restriction.form.occurence": "Occurrence",
  "restriction.form.priority.required.error": "Please enter a number",
  "restriction.form.priority.minValue.error":
      "Please enter a value greater than or equal to 1",
  "restriction.form.priority.maxValue.error":
      "Please enter a value less than or equal to 100",
  "Release date": "Issue date",
  "Due date": "Due date",
  "Price WT": "Pre-Tax amount",
  "Taxe Rate": "VAT rate",
  Price: "Amount Incl tax",
  "Last actions": "Last actions",
  Today: "Today",
  Yesterday: "Yesterday",
  "Last week": "Last week",
  "Estimated Execution":
      "Based on the selection criteria, the Estimated Execution Time for this process is ",
  "Use Historical data": "Use Historical data?",
  "Update using historical data": "Update using historical data",
  "Choose the period":
      "Choose the period to take into consideration when calculating the Estimated Execution Time for this process :",
  Status: "Status",
  "Execution.time": "Execution time",
  "Default Alerts": "Default Alerts",
  "Choose frequency": "Choose frequency",
  "View default alerts": "View default alerts",
  "Edit default alerts": "Edit default alerts",
  "Delete default alert": "Delete default alert",
  "Add default alert": "Add default alert",
  "Robot Administration": "Robot administration",
  "Manage robot": "Manage robot",
  "Custom Alerts": "Custom Alerts",
  "View custom alerts": "View custom alerts",
  "Edit custom alerts": "Edit custom alerts",
  "Delete custom alert": "Delete custom alert",
  "Add custom alert": "Add custom alert",
  Date: "Date",
  "My tasks": "My invoices",
  "Invoices to verify": "To check",
  "Invoices to be checked": "To validate",
  "Invoices to Payers": "Ready for payment",
  "Paid invoices": "Paid",
  "Unpaid invoices": "Unpaid",
  "Total amount of invoices": "Total invoices",
  "Total VAT amount": "Total VAT",
  "Statistical overview of purchases": "Invoicing history",
  "Statistical overview of product sales":
      "Statistical overview of product sales",
  "Total Purchases": "Total purchase orders",
  "Total of expenses": "Total invoices",
  Late: "Overdue",
  "restriction.form.resources": "Robots",
  "restriction.form.priority": "Priority ",
  Day: "Day",
  Week: "Week",
  "3 DAYS": "3 days",
  "restriction.form.priority.label": "Priority of your process",
  "restriction.form.executionTimeLine": "Allowed execution time",
  "restriction.form.executionDay": "Day of execution",
  "restriction.form.executionTime": "Execution time",
  "restriction.form.from": "From",
  "restriction.form.to": "To",
  "restriction.form.estimatedExecutionDuration": "Estimated execution duration",
  "restriction.form.estimatedTime": "Estimated time",

  "restriction.userGuide.resources":
      "Indicates on which machines (Robots) this process can be run. To add eligible Robots, please contact your RPA team",
  "restriction.userGuide.priority":
      "When all SLAs are the same, the process with the highest priority (the lowest number) will be executed first.",
  "sla.update.success": "SLAs updated successfully",
  "restriction.userGuide.executionTimeLine":
      "Choose the days and times when this process is allowed to run",
  "restriction.userGuide.estimatedExecutionTime":
      "The average time it takes to complete this process.",
  "restriction.userGuide.allowedSession":
      "The number of parallel runs allowed for this process, if enough licenses and machines are available to run them.",
  "restriction.userguide.frequencyOccurence":
      "The minimum execution frequency that the process is required to run.",
   "sla.update.error": "Failed to update the SLAs.",
  Administrator: "Administrator",
  "administration.status": "Status",
  "administration.company": "Company",
  "administration.userId": "User ID",
  "administration.name": "User name",
  "administration.creationDate": "Creation date",
  "administration.role": "Role",
  "administration.email": "Email",
  "administration.phone": "Phone",
  "administration.departement": "Departement",
  "administration.subscription": "Subscription",
  "administration.activate.deactivate.user.message":
      "Are you sure you want to activate/deactivate this user?",
  "administration.orchestrator.robot.list.not.available":
      "There is no available robots",
  "administration.orchestrator.process.list.not.available":
      "There are no processes available",
  "user.add.civility": "Civility",
  "user.add.lastName": "Last name",
  "user.add.firstName": "First name",
  "user.add.creationDate": "Creation date",
  "user.add.entreprise": "Company",
  "user.add.subscription": "Subscription",
  "user.add.email": "Email",
  "user.add.pass1": "Password",
  "user.add.pass2": "Repeart password",
  "user.add.comm.lang": "Communications language",
  "user.add.comm.lang.helperText": "All communications and emails sent from SmartRoby will be in the selected language",
  "New suppliers": "New suppliers",
  "user.add.process": "Processes",
  "user.add.availableProcess": "Processes available",
  "user.add.affectedProcess": "Affected processes",
  "user.add.button": "ADD USER",
  "user.add.mfa": "OTP required",
  "user.add.division.admin": "Division admin",
  "user.add.fleet.admin": "Group admin",
  "user.add.division": "Division",
  "user.status.active": "Enabled",
  "user.modify": "Edit",
  "process.modify": "Edit",
  "process.hidden": "Hidden",
  "user.add": "Add",
  "user.button.save": "Add user",
  "user.button.update": "Update",
  "user.button.cancel": "Cancel",
  "user.add.processus": "Processus",
  "user.status.disable": "Inactive",
  "user.title.add": "Add user",
  "user.title.details": "User details",
  "user.save.confirmMsg": "Are you sure you want to add this user?",
  "user.update.confirmMsg":
      "Are you sure you want to update this user’s details? ",
  "user.delete.confirmMsg": "Are you sure you want to delete this user?",
  "user.delete.discard": "Are you sure you want to discard these changes?",
  "orchestrator.button.save": "Add orchestrator",
  orchestrator: "Orchestrator",
  "orchestrator.save.confirmMsg":
      "Are you sure you want to add this orchestrator?",
  "orchestrator.update.confirmMsg":
      "Are you sure you want to update this orchestrator’s details ?",

  "exception.type": "Exception type",
  "exception.time": "Exception time",
  Mr: "Mr",
  Mrs: "Mrs",
  Ms: "Ms",
  "tooltip.action.delete": "Delete",
  "tooltip.action.edit": "Edit",
  "tooltip.action.view": "View",
  "tooltip.action.add": "Add",
  "Visualise Scheduler": "Visualise Schedule ",
  firstName: "First Name",
  lastName: "Last Name",
  companyName: "Company",
  "720 hours/year": "720 hour/year",
  "1800 hours/year": "1800 hour/year",
  "60 days/year": "60 day/year",
  "120 days/year": "120 day/year",
  "3 months/year": "3 month/year",
  "5 months/year": "5 month/year",
  "user.roles.admin": "Administrator",
  "user.roles.user": "User",
  "sorted descending": "sorted descending",
  "sorted ascending": "sorted ascending",
  Invoicing: "Invoicing",
  Alerts: "Alerts",
  "User Management": "User management",
  "Audit Logs": "Audit logs",
  Documentation: "Documentation",
  Services: "Services",
  "Carte d'identité nationale": "National ID card",
  "Driving license": "Driving license",
  Passport: "Passport",
  "Green card": "Green card",
  "Car registration papers": "Car registration papers",
  Facture: "Invoice",
  Invoice: "Invoice",
  RIB: "Bank account statement",
  "Purchase order": "Purchase order",
  "Payroll statemenet": "Payroll statemenet",
  "Avis d'imposition": "Tax notice",
  "Trigger Details": "Trigger details",
  "Start Process": "Start Process",
  "Stop Process": "Stop",
  Schedule: "Schedule",
  Prioritize: "Prioritize",
  Sheet: "Sheet",
  Parameters: "Parameters",
  "Processes launcher": "Processes launcher",
  "Drop process": "Drop here to start process",
  "Time saved": "Time saved",
  "Total time saved": "Total time saved",
  "Process overview": "Execution time by process",
  "Robot overview": "Execution time by robot",
  "Number of executions": "Number of executions",
  "Latest Transcriptions": "Latest transcriptions",
  "Break-even": "Break-even",
  "Return on Investment": "Profitability Ratio",

  // smart ocr services
  "Analyze a new file": "Analyze a new file",
  Receipt: "Receipt",
  "EDF proof": "EDF proof",
  "Click here or drop a file to download":
      "Click here or drop a file to download",
  "Loaded File": "Loaded file",
  "File preview": "File preview",
  Informations: "Informations",
  "Document name": "Document name",
  "File content type": "File content type",
  userId: "userId",
  "First name": "First name",
  "Last name": "Last name",
  CIN: "CIN",
  "Date of Birth": "Date of birth",
  "Place of birth": "Place of birth",
  "Expiration date": "Expiration date",
  Address: "Address",
  Gender: "Gender",
  CivilStatusNo: "civilStatusNo",
  "Use of APIs": "Services consumption",
  "under construction":
      "This section is under construction, our team is working on it. Stay tuned..",
  Overview: "Overview",
  Historical: "Historical",
  "General Information": "General information",
  "Schedule Information": "Schedule information",
  Description: "Description",
  processOriginalName: "Original process name",
  Priority: "Priority",
  "Start date": "Start date",
  "End date": "End date",
  Cron: "Cron",
  "FTE Time to Process": "FTE Time to process",
  "FTE Cost": "FTE cost",
  "Process cost": "Process cost",
  "add.schedule": "Create schedule",
  "add.process": "Add process",
  Reset: "Reset",
  Update: "Update",
  Save: "Save",
  Cancel: "Cancel",
  "Are you sure delete this row ?": "Are you sure delete this row ?",
  "Field must not be empty": "Field must not be empty",
  "This process was started successfully":
      "This process was started successfully",
  "The process has been added to the SR Queues.": "The requested execution has been added to the SR Queue.",
  "An execution trigerred manually on the same robot has already been requested for this process": "An execution trigerred manually on the same robot has already been requested for this process",
  "The process start failed! Please try again later":
      "The process start failed! Please try again later",
  ERRORS_EMPTY_FIELD: "Field must not be empty.",
  ERRORS_INVALID_DATA: "Please enter valid data.",
  ERRORS_NO_DATA: "No data available!",
  WEIGHTS_SUM_MUST_BE_100: "Sum of weights must be 100%",
  "Start date must be less than the end date":
      "Start date must be less than the end date",
  "End date must be greater than the start date":
      "End date must be greater than the start date",
  "Start time": "Start time",
  "End time": "End time",
  "How much time does it take an employee to execute this process?":
      "How much time does it take an employee to execute this process?",
  "How much does the hour cost for an employee executing this task?":
      "How much does the hour cost for an employee executing this task?",
  "How much does this process cost per month ?":
      "How much does this process cost per month ?",
  Recurrence: "Recurrence",
  Previous: "Previous",
  // authentication page
  "Enter your mail": "Enter your mail",
  "Confirm your mail": "Confirm your mail",
  "Enter your password": "Enter your password",
  Login: "Login",
  "Sign up to SmartRoby": "Sign up to SmartRoby",
  "Go to SmartRoby": "Go to Smartroby",
  "Registration was successfull, you will be redirected":
      "Registration was successfull, you will be redirected",
  "Registration failed": "Registration failed",
  Close: "Close",
  "Aws registration token missing": "Aws registration token missing",
  "Email not matching": "Email not matching",
  Register: "Sign up",
  "You are already signed up": "You are already signed up",
  "Forgot your password ?": "Forgot your password ?",
  "Login or password incorrect !": "Login or password incorrect !",
  "Copyright © Smart-Roby  All rights reserved.":
      "Copyright © Smart-Roby  All rights reserved.",
  English: "English",
  French: "French",
  Spanish: "Spanish",
  Polish: "Polish",
  Germany: "Germany",
  "Process executions": "Process executions",
  Ready: "Ready",
  "Executed by": "Executed by",
  "you want to modify this data ?":
      "Are you sure you want to modify this data?",
  Yes: "Yes",
  No: "No",
  "Data saved with success": "Data saved with success",
  "Data not saved": "Data not saved ",
  "Hour(s)": "Hour(s)",
  "Day(s)": "Day(s)",
  "Week(s)": "Week(s)",
  "Month(s)": "Month(s)",
  "Year(s)": "Year(s)",
  Every: "Every",
  "Choose period": "Choose period",
  "Your input is required": "Your input is required",
  "The process": "The process",
  "is running, try again when it's completed.":
      "is running, try again when it's completed.",
  "Are you sure you want to execute this process ?":
      "Are you sure you want to execute this process ?",
  Domain: "Domain",
  "Process name": "Process name",
  "Process reference": "Process reference",
  "Number used of systems and applications":
      "Number used of systems and applications",
  "SAP environments (if applicable)": "SAP environments (if applicable)",
  Frequency: "Frequency",
  Volume: "Volume",
  "Execution per": "Execution per",
  "Rule-based": "Rule-based",
  Standardization: "Standardization",
  "Structured data": "Structured data",
  "IT environment": "IT environment",
  "Test enabled": "Test enabled",
  "Documentation Readiness": "Documentation readiness",
  "Authentication mode": "Authentication mode",
  "Time spent for one execution": "Time spent for one execution",
  "Process UoM": "Process unit of measure",
  "Frequency of occurence": "Frequency of occurence",
  "Time spent": "Time spent",
  "Time granularity": "Execution time granularity",
  "Occurence time granularity": "Occurence time granularity",
  Occurrence: "Occurrence",
  "Process Exceptions": "Process Exceptions",
  "Item Exceptions": "Item exceptions",
  "Occurrence driver": "Occurrence driver",
  "Total time spent in hours per year": "Time (in hours) given back to the business per year",
  "Re-usability": "Re-usability",
  "Expected qualitative benefits": "Expected qualitative benefits",
  "Process Stability": "Process stability",
  "Number of steps": "Number of steps",
  "Number of windows": "Number of windows",
  "Business Rules": "Business rules",
  "Business Applications": "Business applications",
  "Complex functions - Unstructured data / OCR":
      "Complex functions - Unstructured data / OCR",
  "Complex functions - DataBase": "Complex functions - DataBase",
  "Complex functions - Powershell": "Complex functions - Powershell",
  "Complex functions - Active Directory":
      "Complex functions - Active Directory",
  "Complex functions - VB Script": "Complex functions - VB Script",
  "Complex functions - Web Services": "Complex functions - Web Services",
  "Type of applications - Web application":
      "Type of applications - Web application",
  "Type of applications - Windows application":
      "Type of applications - Windows application",
  "Type of applications - Mainframe application":
      "Type of applications - Mainframe application",
  "Type of applications - Citrix application":
      "Type of applications - Citrix application",
  "Automation without RPA": "Automation without RPA",
  "assessment.Basic documentation is available":
      "Basic documentation is available",
  "assessment.Few simple applications with a unique ERP instance custom application":
      "Few simple applications with a personalized application of instance of unique ERP",
  "assessment.No - Judgment based": "No - Judgment based",
  "assessment.Yes - Based on objective criteria":
      "Yes - Based on objective criteria",
  Accounting: "Accounting",
  Controlling: "Controlling",
  Other: "Other",
  Others: "Others",

  "assessment.Yes - Completely based on objective criteria":
      "Yes - Completely based on objective criteria",
  "assessment.No - Completely judgment-based": "No - Completely judgment-based",
  "assessment.Completely optimized process, managed at Group level":
      "Completely optimized process, managed at Group level",
  "assessment.Largely standardized process, mostly managed at group level":
      "Largely standardized process, mostly managed at group level",
  "assessment.Good level of standardisation, managed regionaly":
      "Good level of standardisation, managed regionaly",
  "assessment.Low degree of standardization and split responsibilities":
      "Low degree of standardization and split responsibilities",
  "assessment.Disaggregated process and split responsibilities":
      "Disaggregated process and split responsibilities",
  "assessment.Yes - Completely structured (e.g. in a form/table/spreadsheet)":
      "Yes - Completely structured (e.g. in a form/table/spreadsheet)",
  "assessment.No - Completely un-structured e.g. non standardized email":
      "No - Completely un-structured e.g. non standardized email",
  "assessment.Few simple applications (Excel, Outlook, …)":
      "Few simple applications (Excel, Outlook, …)",
  "assessment.Few simple applications with a unique ERP instance or another / custom application":
      "Few simple applications with a unique SAP instance or another / custom application",
  "assessment.Few complex / custom applications or ERP instances":
      "Few complex / custom applications or SAP instances",
  "assessment.Several complex / custom applications, and a cloud application":
      "Several complex / custom applications, and a cloud application",
  "assessment.Large number of applications, (i.e. several ERP instances)":
      "Large number of applications, (i.e. several ERP instances)",
  "assessment.Yes - Data-sets fully available to conduct multiple tests":
      "Yes - Data-sets fully available to conduct multiple tests",
  "assessment.No - No Data-sets available to conduct multiple tests":
      "No - No Data-sets available to conduct multiple tests",
  "assessment.A detailed documentation is available":
      "A detailed documentation is available",
  "assessment.A high level documentation is available":
      "A high level documentation is available",
  "assessment.No documentation": "No documentation",
  "assessment.Standard authentication mode (ID and password)":
      "Standard authentication mode (ID and password)",
  "assessment.SSO only authentication mode": "SSO only authentication mode",
  "assessment.Additional authentication required (e.g. USB key)":
      "Additional authentication required (e.g. USB key)",
  Seconds: "Seconds",
  Minutes: "Minutes",
  Minute: "Minute",
  Hours: "Hours",
  Days: "Days",
  "no execution in this period": "No process execution found.",
  "unit.manday": "m/d",
  "assessment.Fully Core process compliant": "Fully Core process compliant",
  "assessment.Partially Core process compliant":
      "Partially Core process compliant",
  "assessment.Local (zone or country specific)":
      "Local (zone or country specific)",
  "assessment.Major": "Major",
  "assessment.High": "High",
  "assessment.Medium": "Medium",
  "assessment.Low": "Low",
  "assessment.Minor": "Minor",
  "assessment.No change is planned": "No change is planned",
  "assessment.No change is planned in the next 2 years":
      "No change is planned in the next 2 years",
  "assessment.Process/system may change in next 2 years":
      "Process/system may change in next 2 years",
  "assessment.Process/system update planned in next 2 years":
      "Process/system update planned in next 2 years",
  "assessment.Frequent changes occur in the process":
      "Frequent changes occur in the process",
  "assessment.1-16": "1-16",
  "assessment.17-45": "17-45",
  "assessment.46-94": "46-94",
  "assessment.More than 94": "More than 94",
  "assessment.1-4": "1-4",
  "assessment.5-10": "5-10",
  "assessment.11-20": "11-20",
  "assessment.More than 20": "More than 20",
  "assessment.Between 2 and 3": "Between 2 and 3",
  "assessment.Between 4 and 10": "Between 4 and 10",
  "assessment.More than 10": "More than 10",
  "assessment.Between 4 and 5": "Between 4 and 5",
  "assessment.More than 5": "More than 5",
  "assessment.1": "1",
  true: "true",
  false: "false",
  "planned.executions": "Planned executions",
  robot: "Robot",
  "planner.fullScreenExit": "Exit in full screen mode",
  "planner.fullScreen": "Open planner in full screen",
  "Risk management": "Risk Margin",
  "planner.risktime": "Risk time",
  "Latest Exceptions": "Latest exceptions",
  Process: "Process",
  Executing: "Executing",
  Planned: "Planned",
  Paused: "Paused",
  "Rows Per page": "Rows per page",
  "pagination.of": "of",
  Completed: "Completed",
  Running: "Running",
  RUNNING: "Running",
  "How long will it take an employee to complete this process?":
      "How long will it take an employee to complete this process?",
  "How much does it cost per hour for an employee to perform this task?":
      "How much does it cost per hour for an employee to perform this task?",

  unknown: "unknown",
  Unknown: "Unknown",
  UNKNOWN: "Unknown",
  "This week": "This week",
  "This Month": "This month",
  "This Year": "This year",
  "All time": "All time",
  Discard: "Discard",
  "Exception Reason": "Exception reason",
  "Exception Workflow Status": "Status",
  "Exception Assignee": "Assignee",
  "Show more filters": "Show more filters",
  "Hide filters": "Hide filters",
  "This Week": "This week",
  READY: "Ready",
  TAKEN: "Assigned",
  Name: "Name",
  createdBy: "Created by",
  updatedBy: "Last modified by",
  "Last Update ": "Last updated",
  "Last update": "Last modified date",
  Feasibility: "Feasibility",
  Impact: "Impact",
  Roi: "Profitability ratio",
  "RPA Opportunities": "RPA opportunities",
  Accepted: "Accepted",
  Rejected: "Rejected",
  Pending: "Pending",
  PENDING: "Pending",
  DELETED: "Deleted",
  MISSED: "Missed",
  MISSED_P: "Pending (Missed)",
  PENDING_SR: "Pending SR",
  DONE: "Done",
  EXCEPTION: "Exception",
  "Exception Type": "Exception type",
  "My cases": "My cases",
  "Schedule Name": "Schedule name",
  Period: "Period",
  Exceptions: "Exceptions",
  "header.kpi.exceptions": "Unhandled exceptions",
  Actions: "Actions",
  "Loaded Time": "Loaded time",
  Reason: "Reason",
  "Exception Time": "Exception Time",
  "How much time does it take an employee to execute an Item in this process? (in minutes)":
      "How much time does it take an employee to execute an Item in this process? (in minutes)",
  "How much does this Process cost to maintain per Year?":
      "How much does this process cost to maintain per year?",
  "Are you sure you want to update the parameters ?":
      "Are you sure you want to update the parameters ?",
  "Are you sure you want to create this schedule ?":
      "Are you sure you want to create this schedule ?",
  "Are you sure you want to update this schedule ?":
      "Are you sure you want to update this schedule ?",
  "The schedule was created successfully":
      "Your request was send to BP and schedule will be created soon",
  "Failed to create the schedule! Please try again later":
      "Failed to create the schedule! Please try again later",
  "Failed to update parameters": "Failed to update parameters",
  "Are you sure you want to disable this schedule ?":
       "Are you sure you want to disable this schedule ?",
   "Are you sure you want to enable this schedule ?":
       "Are you sure you want to enable this schedule ?",
    "button.deactivate.confirm":
        "Deactivate",
   "button.activate.confirm":
        "Activate",
  hours: "hours",
  Once: "Once",
  Hourly: "Hourly",
  Daily: "Daily",
  Weekly: "Weekly",
  Monthly: "Monthly",
  Yearly: "Yearly",
  Trimestrial: "Trimestrial",
  Quarterly: "Quarterly",
  "Start Date": "Start date",
  "Are you sure you want to save the changes ?":
      "Are you sure you want to save the changes ?",
  "Failed to modify schedule": "Failed to modify schedule",
  "Are you sure you want to disregard the changes ?":
      "Are you sure you want to disregard the changes ?",
  "Changes disregarded": "Changes disregarded",
  "Are you sure you want to delete this exception ?":
      "Are you sure you want to hide this exception ?",
  "Are you sure you want to show this exception ?":
      "Are you sure you want to show this exception ?",
  "Are you sure you want to delete these exception ?":
      "Are you sure you want to delete these exception ?",
  "Exception Deleted Successfully": "Exception hidden successfully!",
  "Exception Restored Successfully": "Exception restored successfully!",
  "Exceptions Deleted Successfully": "Exceptions hidden successfully",
  "Are you sure you want to delete": "Are you sure you want to hide",
  "Are you sure you want to show X exceptions?":
      "Are you sure you want to show {{count}} exception(s) ?",
  exceptions: "exception(s)",
  "exceptions successfully deleted": "exceptions successfully hidden",
  "X exceptions successfully restored":
      "{{count}} exception(s) successfully restored",

  // rpa qual

  Submitted: "Submitted",
  Draft: "Draft",
  draft: "DRAFT",
  "Not submitted": "Not submitted",
  "Are you sure?": "Are you sure?",
  "You won't be able to revert this!": "You won't be able to revert this!",
  warning: "Warning",
  "Yes, submit it!": "Yes, submit it!",
  "Yes, save it!": "Yes, save it!",
  "Submit later": "Validate later",
  Back: "Back",
  Result: "Result",
  "Impact/Feasibility Matrix": "Impact/Feasibility Matrix",
  next: "Next",
  "Red zone": "Red zone",
  "Impact and feasibility are limited": "Impact and feasibility are limited",
  "The process is likely to be reprioritized":
      "The process is likely to be reprioritized",
  "Yellow zone": "Yellow zone",
  "Here either the impact or the feasibility is relevant":
      "Here either the impact or the feasibility is relevant",
  "Depending on the case and arbitration, the process could be deprioritized":
      "Depending on the case and arbitration, the process could be deprioritized",
  "Green zone": "Green zone",
  "Both impact and feasibility show some great signs":
      "Both impact and feasibility show some great signs",
  "The process is likely to be prioritized":
      "The process is likely to be prioritized",
  "Saving (per year)": "Estimation of gains (per year)",
  "FTE Gains": "FTE gains",
  "FTE Costs": "FTE costs",
  "Total in $": "Total in $",
  "Developement costs": "Estimation of development costs (in m/d)",
  Developer: "Developer",
  "Business analyst": "Business analyst",
  "Running costs (per year)": "Running costs (per year)",
  "Running costs in $": "Running costs in $",
  "Payback period (year)": "Payback period (year)",
  "Yes, accept it": "Yes, accept it",
  "Yes, reject it": "Yes, reject it",
  "Yes, delete it": "Yes, delete it",
  NAME: "NAME",
  "CREATED BY": "CREATED BY",
  "Date created": "Created date",
  "LAST UPDATE": "Last Updated",
  Edit: "Edit",
  Exit: "Exit",
  "Home - RPA Qualification Tool By Novelis innovation":
      "Home - RPA Qualification Tool By Novelis innovation",
  "No opportunities found !": "No opportunities found !",
  "create.opportunity": "Create opportunity",
  "No cases found !": "No cases found !",
  "Tool for discovering, evaluating and classifying business processes":
      "Tool for discovering, evaluating and classifying business processes",
  "Sign in": "Sign in",
  "Invalid email address": "Invalid email address",
  "Email Address": "Email Address",
  "Password is required": "Password is required",
  "Password must contains at least 8 characters":
      "Password must contains at least 8 characters",
  Password: "Password",
  "No network": "No network",
  "Please verify your internet connection and try again !":
      "Please verify your internet connection and try again !",
  OK: "OK",
  "New process": "New process",
  "Page not found": "Page not found",
  "Sorry, the page you are looking for can't be found !":
      "Sorry, the page you are looking for can't be found !",
  Reject: "Reject",
  Submit: "Submit",
  "rpaQual.action.submit": "Validate",
  "assessment.submit": "Submit",
  Accept: "Accept",
  Delete: "Delete",
  "priority.error": "The value already exists, please choose another value.",
  "priority.error.unhide":
      "The priority value of this process already exists. Your process will be unhidden without any priority set.",
  "rpaqual.submit": "Validate",
  "Time period": "Time period",
  "Select a Department": "Select a Department",
  "name your process": "name your process",
  "Select a robot": "Select a robot",
  "describe briefly your process": "describe briefly your process",
  "What is the value that would drive a round of process executions":
      "What is the value that would drive a round of process executions",

  "Select if process is rule-based": "Select if process is rule-based ",

  "Select if process is standardized": "Select if process is standardized",

  "Select if data used in the process is structured ":
      "Select if data used in the process is structured",

  "Select the sentence that fits the most with the context of your process":
      "Select the sentence that fits the most with the context of your process",

  "Select if testing will be possible": "Select if testing will be possible ",

  "the documentation level that fits the most with the context of your process":
      "the documentation level that fits the most with the context of your process",

  "what authentication mode is applied for the systems in scope":
      "what authentication mode is applied for the systems in scope",

  "Time required for a single but complete process execution":
      "Time required for a single but complete process execution",

  "Time granularity of the above mentionned time":
      "Time granularity of the above mentionned time",

  "Weekly=52, Monthly=12, Daily=220.Details of your calculations in the comment section":
      "eg: Weekly=52, Monthly=12, Daily=220",

  "This field is populated automatically from the details time spent below":
      "This field is populated automatically from the details time spent below",

  "Assess re-usability to the best of your knowledge":
      "Assess re-usability to the best of your knowledge",

  "Explain what are the benefits expected, e.g. better quality":
      "Explain what are the benefits expected, e.g. better quality",

  "Select the number of steps": "Select the number of steps",

  "number of screens that change during the execution ":
      " number of screens that change during the execution",

  "number of  the business rules governing the process":
      "Select the number of  the business rules governing the process",

  "number of business applications involved in the process":
      "number of business applications involved in the process",

  "whether unstructured data or any OCR processing will be processed":
      "whether unstructured data or any OCR processing will be processed",

  "communication with a database to establish or any sql commands will be executed":
      "communication with a database to establish or any sql commands will be executed",

  "if any Powershell script to install or any powershell code to execute in the command line":
      "if any Powershell script to install or any powershell code to execute in the command line",

  "if the robots will have a Windows account and communicate by email or Teams":
      "if the robots will have a Windows account and communicate by email or Teams",

  "if there is a need to create VB scripts or run macros directly on blue prism ":
      "if there is a need to create VB scripts or run macros directly on BluePrism ",

  "if there is a possibility to use web services or to expose them for other processes":
      "if there is a possibility to use web services or to expose them for other processes",

  "if the process interacts with web applications via browsers":
      "if the process interacts with web applications via browsers",

  "if the process interacts with Windows applications installed on the computer":
      "if the process interacts with Windows applications installed on the computer",

  "if  there is any application that we access via an emulator ":
      "if  there is any application that we access via an emulator ",

  "if  there is any application that we access via the cloud":
      "if  there is any application that we access via the cloud",

  "the number of steps": "the number of steps ",

  // scheduler
  "Are you sure you want to disregard this schedule ?":
      "Are you sure you want to disregard this schedule ?",
  "Schedule disregarded!": "Schedule disregarded!",
  "Are you sure you want to execute this process?":
      "Are you sure you want to execute this process?",

  // kpi
  January: "January",
  March: "March",
  April: "April",
  May: "May",
  June: "June",
  July: "July",
  August: "August",
  September: "September",
  October: "October",
  November: "November",
  December: "December",
  "Weeks ago": "Weeks ago",

  "7 Weeks ago": "7 weeks ago",
  "6 Weeks ago": "6 weeks ago",
  "5 Weeks ago": "5 weeks ago",
  "4 Weeks ago": "4 weeks ago",
  "3 Weeks ago": "3 weeks ago",
  "2 Weeks ago": "2 weeks ago",
  "Last Week": "Last Week",

  "hours ago": "#NB# hours ago",
  "1 hour ago": "1 hour ago",

  Monday: "Monday",
  Tuesday: "Tuesday",
  Wednesday: "Wednesday",
  Thursday: "Thursday",
  Friday: "Friday",
  Saturday: "Saturday",
  Sunday: "Sunday",

  "Total executions": "Total executions",
  "Number of process executions": "Number of process executions",
  "Latest activities": "Latest activities",

  "Creation date (Descending)": "Creation date (descending)",
  "Creation date (Ascending)": "Creation date (ascending)",
  "Exception Time (Descending)": "Exception date (descending)",
  "Exception Time (Ascending)": "Exception date (ascending)",
  "End date (Descending)": "End date (descending)",
  "End date (Ascending)": "End date (ascending)",
  "Creation date": "Creation date",
  "Exception date": "Exception date",
  "Sort By": "Sort by",
  "Document Name": "Document Name",
  "Document Status": "Document Status",
  Preview: "Preview",
  "The document was sent to the orchestrator":
      "The document was sent to the orchestrator",
  "The process has started successfully":
      "The process has started successfully",
  "Are you sure you want to delete this document ?":
      "Are you sure you want to delete this document ?",
  "Document Deleted Successfully": "Document Deleted Successfully",
  "Show empty fields": "Show empty fields",
  "Are you sure you want to delete this Opportunity ?":
      "Are you sure you want to delete this Opportunity ?",
  "Opportunity deleted successfully": "Opportunity deleted successfully",
  // added by zineb

  "Are you sure you want to start this process ?":
      "Are you sure you want to start this process ?",
  START: "START",
  Of: "Of",
  FOREVER: "FOREVER",
  "Are you sure you want to delete this schedule ?":
      "Are you sure you want to delete this schedule ? ",
   "Are you sure you want to delete this frequency ?": "Are you sure you want to delete this frequency ?",
  "Schedule Deleted Successfully": "Schedule Deleted Successfully",
  "Failed to delete schedule": "Failed to delete schedule",

  "No options": "No options",
  Clear: "Clear",
  "save as draft": "save as draft",
  "Save as draft": "Save as draft",
  "Save draf": "Save draf",
  calculate: "calculate",
  Calculate: "Calculate",

  // Process Assessment help Added By Zineb

  "Can a defined rule be applied to each step of this process?":
      "Can a defined rule be applied to each step of this process?",
  "To what extent is the process standard across locations?":
      "To what extent is the process standard across locations?",
  "The more optimized & centrally managed the process is, the easier the automation tests & implementation will be.":
      "The more optimized & centrally managed the process is, the easier the automation tests & implementation will be.",
  "Are the process inputs provided in a structured manner?":
      "Are the process inputs provided in a structured manner?",
  "Is a full data set availbale to test this process ?":
      "Is a full data set availbale to test this process ?",
  "What is the level of IT environment complexity?":
      "What is the level of IT environment complexity?",
  "What level of documentation exists to support this process?":
      "What level of documentation exists to support this process?",
  "What is scale of the security challenge for authentication?":
      "What is scale of the security challenge for authentication?",

  "What is the Time required for a single but complete process execution?":
      "What is the Time required for a single but complete process execution?",
   "What does an execution represent?":
        "What does each execution represent?",
  "What is the frequency which this process occures?":
      "What is the frequency which this process occures?",
  "What is the value that would drive a round of process executions?":
      "What is the value that would drive a round of process executions?",
  "Please indicate the nature of the driver for the occurrence of the process (number of company codes, accounts, postings, etc.)":
      "Please indicate the nature of the driver for the occurrence of the process (number of company codes, accounts, postings, etc.)",
  "Weekly=52, Monthly=12, Daily=220, etc. For specific frequencies, please add the details of your calculations in the comment section":
      "Weekly=52, Monthly=12, Daily=220, etc. For specific frequencies, please add the details of your calculations in the comment section",

  "This field is populated automatically from the details from the Time Spent Section":
      "This field is populated automatically from the details from the Time Spent Section",
  "Select gravity of benefits and mention in the comment area what are the benefits expected, e.g. better quality, productivity gains, reduced lead time etc.":
      "Select gravity of benefits and mention in the comment area what are the benefits expected, e.g. better quality, productivity gains, reduced lead time etc.",
  "To what extent is this same process performed worldwide?":
      "To what extent is this same process performed worldwide?",
  "Are systems/tool changes planned that would impact this process? (i.e. new report development)":
      "Are systems/tool changes planned that would impact this process? (i.e. new report development)",

  "Select the number of steps (Clicks, navigation, reading and writing in each application window), if necessary precise in comments":
      "Select the number of steps (Clicks, navigation, reading and writing in each application window), if necessary precise in comments",
  "Select the number of windows (screens) that change during the execution of the process, if necessary precise in comments":
      "Select the number of windows (screens) that change during the execution of the process, if necessary precise in comments",
  "Select the number of  the business rules governing the process, if necessary precise in comments":
      "Select the number of  the business rules governing the process, if necessary precise in comments",
  "Select the number of business applications involved in the process (Number of objects to be created), if necessary precise in comments":
      "Select the number of business applications involved in the process (Number of objects to be created), if necessary precise in comments",
  "Select whether unstructured data (PDF, Excel, e-mail, Txt file) or any OCR processing will be processed, if necessary precise in comments":
      "Select whether unstructured data (PDF, Excel, e-mail, Txt file) or any OCR processing will be processed, if necessary precise in comments",
  "Select whether a communication with a database to establish or any sql commands will be executed if necessary precise in comments":
      "Select whether a communication with a database to establish or any sql commands will be executed if necessary precise in comments",
  "Select if there is any Powershell script to install or any powershell code to execute in the command line, if necessary precise in comments":
      "Select if there is any Powershell script to install or any powershell code to execute in the command line, if necessary precise in comments",
  "Select if the robots will have a Windows account and communicate by email or Teams, if necessary precise in comments":
      "Select if the robots will have a Windows account and communicate by email or Teams, if necessary precise in comments",
  "Select if there is a need to create VB scripts or run macros directly on blue prism if necessary precise in comments":
      "Select if there is a need to create VB scripts or run macros directly on BluePrism if necessary precise in comments",
  "Select if there is a possibility to use web services or to expose them for other processes, if necessary precise in comments":
      "Select if there is a possibility to use web services or to expose them for other processes, if necessary precise in comments",
  "Select if the process interacts with web applications via browsers (IE, Chrome, Firefox), if necessary precise in comments":
      "Select if the process interacts with web applications via browsers (IE, Chrome, Firefox), if necessary precise in comments",
  "Select if the process interacts with Windows applications installed on the computer, if necessary precise in comments":
      "Select if the process interacts with Windows applications installed on the computer, if necessary precise in comments",
  "Select if  there is any application that we access via an emulator (IBM, AS400, HUB 5250), if necessary precise in comments":
      "Select if  there is any application that we access via an emulator (IBM, AS400, HUB 5250), if necessary precise in comments",
  "Select if  there is any application that we access via the cloud (image), if necessary precise in comments":
      "Select if  there is any application that we access via the cloud (image), if necessary precise in comments",
  "Select if this process can be automated without the use of RPA technologies":
      "Select if this process can be automated without the use of RPA technologies",

  "Are you sure you want to save this case as a draft?":
      "Are you sure you want to save this case as a draft?",
  "Case saved as a draft": "Case saved as a draft",
  "Are you sure you want to save and submit this case later?":
      "Are you sure you want to save and submit this case later?",
  "Case saved but not submitted": "Case saved but not submitted",
  "Are you sure you want to save and submit this case?":
      "Are you sure you want to save and submit this case?",
  "Case saved and submitted for approval":
      "Case saved and submitted for approval",
  "rpaQual.transition.accepted":
      "Are you sure you want to accept this case as an opportunity for automation?",
  "Case accepted as an opportunity": "Case accepted as an opportunity",
  "Are you sure you want to reject this case?":
      "Are you sure you want to reject this case?",
  "Case rejected": "Case rejected ",
  "This case is not yet submitted. Are you sure you want to delete it?":
      "This case is not yet submitted. Are you sure you want to delete it?",
  "Case deleted successfully": "Case deleted successfully",
  "Case archived successfully": "Case archived successfully",
  "This case is submitted and awaiting approval. Are you sure you want to delete it?":
      "This case is submitted and awaiting approval. Are you sure you want to delete it?",
  "This case is an accepted opportunity for automation. Are you sure you want to delete it?":
      "This case is an accepted opportunity for automation. Are you sure you want to delete it?",
  "Are you sure you want to delete this case?":
      "Are you sure you want to delete this case?",
  "Your progress will be lost, are you sure you want to exit?":
      "Your progress will be lost, are you sure you want to exit?",
  "Total in man-days": "Total in man-days",
  "Running costs in man-days": "Running costs in man-days",
  months: "months",
  Months: "Months",
  Weeks: "Weeks",
  Years: "Years",
  days: "days",
  weeks: "weeks",
  years: "years",
  left: "left",
  ago: "{{value}} {{unit}} ago",
  Logout: "Logout",
  Profile: "Profile",
  "Choose your timezone": "Choose your timezone",
  "Execution Count": "Executions",
  "Exception.count": "Exceptions",
  process: "Process",
  "point.amortissage.negative": "{{value}} {{unit}} left",
  "of the total": "of the total",
  SearchProcess: "Search for process",
  searchUser: "Search User",
  status: "Status",
  sortBy: "Sort by",
  "Failed To create User": "Failed To create User",
  "Failed To create Scheduler": "Failed to create schedule",
  "user.keycloak.exists": "User already exist in keycloak",
  "user.email.exists": "User already exist",
  "User with same email already exists": "User with same email already exists",
  "user.update.fail": "User update failed",
  "user.update.success": "User updated successfully !",
  "invalid.value": "Invalid value",
  Required: "Required",
  deleteUsersActionMessage: "Are you sure you want to delete these users?",
  "profile.update-password.current-password-required":
      "Current password is required",
  "profile.update-password.new-password-required": "New password is required",
  "profile.update-password.password-required": "Password is required",
  "profile.update-password.password-not-matched": "Passwords do not match",
  "profile.update-password.current-password": "Current password",
  "profile.update-password.new-password": "New password",
  "profile.update-password.confirm-password": "Confirm password",
  "profile.update-password.reset-password": "Reset password",
  "profile.update-password.password-reset": "Password reset",
  "profile.update-password.password-reset-success":
      "Your password was changed successfully",
  "profile.update-password.password-email-send":
      "An email has been sent. Please check your mail.",
  "profile.update-password.password-reset-done": "Done",
  "profile.update-password.update-password": "Change password",
  "profile.update-password.email": "Your current email",
  "profile.update-password.tokenError":
      "Invalid Token, Password is already updated!",
  "token.validation.noteExist": "Change password request already sent",
  "Process CompletedItems": "Completed items",
  "Completed time": "Completed time",
  "traited item": "items processed",
  "Items Count": "Processed items",
  "process.devCost.edit.cancel":
      "Your changes will be lost, are you sure you want to cancel?",
  "process.devCost.edit.confirm": "Are you sure you want to save your changes?",
  "process.status.pending": "PENDING",
  "not submitted": "NOT SUBMITTED",
  "Process Assessment": "Process assessment",
  "title.processassessment": "PROCESS ASSESSMENT",
  "no.exception.message": "No exceptions detected",
  "show.all.exception.button": "show all",
  manageUsers: "Manage users",
  "assessment.Accounting & Finance": "Accounting & finance",
  "assessment.Compliance": "Compliance",
  "assessment.Customer Service": "Customer service",
  "assessment.Purchasing": "Purchasing",
  "assessment.IT Operations": "IT operations",
  "assessment.Maintenance": "Maintenance",
  "assessment.Sales": "Sales",
  "assessment.Marketing": "Marketing",
  "assessment.Research and Development": "Research and development",
  "assessment.Production": "Production",
  "assessment.Human Resources Management ": "Human resources management ",
  "assessment.Software Development": "Software development",
  "assessment.Other": "Other",
  "assessment.Seconds": "Seconds",
  "assessment.Minutes": "Minutes",
  "assessment.Hours": "Hours",
  "assessment.Days": "Days",
  "assessment.Months": "Months",
  "assessment.Years": "Years",
  "assessment.Weeks": "Weeks",
  "orchestrator.management.title": "Orchestrator administration",
  "orchestrator.management.menu.title": "Orchestrators",
  "robot.management.title": "Robot administration",
  "robot.management.menu.title": "Robots",
  "Process Occurence": "Completed items",
  "Process Item": "Process items",
  "orchestrator.management.add.button": "Connect orchestrator",
  "orchestrator.management.delete.confirmMsg":
      "Are you sure you want to delete the orchestrator ? All data will be lost permanently.",
  "orchestrator.management.deleteOrchestratorsActionMessage":
      "Are you sure you want to delete selected orchestrators?",
  "orchestrator.management.enaDes.confirmMsg":
      "Are you sure you want to enable the orchestrator ? The connection to your RPA environment will be re-initiated",
  "orchestrator.management.enaDes.button.confirm": "Activate",
  "orchestrator.management.desaDes.confirmMsg":
      "Are you sure you want to disable the orchestrator ? The connection to your RPA environment will be lost.",
  "orchestrator.management.desaDes.button.confirm": "Deactivate",
  "orchestrator.management.searchOrchestrator": "Search orchestrators",
  "orchestrator.management.status": "Status",
  "orchestrator.management.name": "Name",
  "orchestrator.management.solutionProvider": "Solution provider",
  "orchestrator.management.isActionable": "Is actionable",
  "orchestrator.management.orchestratorIp": "Public IP",
  "orchestrator.management.isAcionableToggle":
      "Are you sure this orchestrator is not actionable ?",
  "orchestrator.management.isAcionableToggle.confirm": "Yes, I’m sure",
  "orchestrator.management.isNotAcionableToggle":
      "Are you sure you want to make this orchestrator actionable ?",
  "orchestrator.management.isNotAcionableToggle.confirm": "Make actionable",
  "orchestrator.management.editHeader": "Edit Orchestrator",
  "orchestrator.management.addHeader": "Add Orchestrator",
  "orchestrator.management.displayHeader": "Orchestrator Details",
  "orchestrator.management.formControl.required": "Required Field",
  "orchestrator.management.formControl.orchestratorId": "Orchestrator ID",
  "orchestrator.management.formControl.orchestratorName": "Orchestrator name",
  "orchestrator.management.formControl.orchestratorName.information":
      "The name of the Orchestrator",
  "orchestrator.management.formControl.description.information":
      "Any information you need to add here",
  "orchestrator.management.formControl.orchestratorIp.information":
      "Public or Private IP of the Orchestrator Machine",
  "orchestrator.management.formControl.sessionNumber.information":
      "The number of sessions allowed in the current active BP Licence",
  "orchestrator.management.formControl.databaseServer.information":
      "The hostname of the database server",
  "orchestrator.management.formControl.serverPort.information":
      "This must match the listening port configured on the SQL Server",
  "orchestrator.management.formControl.databaseName.information":
      "The name of the database used by BluePrism",
  "orchestrator.management.formControl.dbUsername.information":
      "	The database user name to use when connecting. The user must have db_datareader & db_datawriter permissions on the BluePrism Database",
  "orchestrator.management.formControl.dbPassword.information":
      "	The password of the user name used when connecting",
  "orchestrator.management.formControl.solutionProvider": "Solution provider",
  "orchestrator.management.formControl.orchestratorIp": "Orchestrator IP",
  "orchestrator.management.formControl.apiToken": "API token",
  "orchestrator.management.formControl.apiUrl": "API URL",
  "orchestrator.management.formControl.yes": "Yes",
  "orchestrator.management.formControl.no": "No",
  "orchestrator.management.formControl.orchestratorId.actionnable":
      "Actionable",
  "orchestrator.management.formControl.apiPath": "API path",
  "orchestrator.button.update": "Update",
  "orchestrator.delete.discard":
      "Are you sure you want to disregard the changes?",
  "orchestrator.management.save.confirmMsg":
      "Are you sure you want to save this orchestrator?",
  "user.enaDes.confirmMsg": "Are you sure you want to delete User?",
  "orchestrator.management.formControl.description": "Description",
  "orchestrator.management.formControl.databaseServer": "Database server",
  "orchestrator.management.databaseServer": "Database server",
  "orchestrator.management.formControl.serverPort": "Port",
  "orchestrator.management.serverPort": "Port",
  "orchestrator.management.formControl.databaseName": "Database name",
  "orchestrator.management.formControl.dbUsername": "Username",
  "orchestrator.management.formControl.dbPassword": "Password",
  "orchestrator.management.formControl.invalidPort":
      "Port number must be between 1 and 65,535",
    "orchestrator.management.formControl.apiClientId": "API Client ID",
    "orchestrator.management.formControl.apiClientSecret": "API Client Secret",
    "orchestrator.management.formControl.organizationName": "Uipath Organization Name",
    "orchestrator.management.formControl.tenantName": "Uipath Tenant Name",
    "orchestrator.management.formControl.orchestratorApiName": "Uipath Orchestrator Name",
  "orchestrator.management.testConnection": "Test connection",
  "orchestrator.management.testConnection.successMessage":
      "Connection successful",
  "orchestrator.management.testConnection.failureMessage": "Connection failed",
  "Are you sure you want to accept this case as an opportunity for automation ?":
      "Are you sure you want to accept this case as an opportunity for automation ?",
  "Are you sure you want to save the changes?":
      "Are you sure you want to save the changes?",
  "Schedule modified successfully": "Your request has been sent to BP and the schedule will be updated soon",
  "Schedule saved successfully": "Frequency saved successfully",
  "Frequency Deleted Successfully": "Frequency Deleted Successfully",
  "Failed to update the schedule! Please try again later":
      "Failed to update the schedule! Please try again later",

  "Create schedule": "Create schedule",
  "Edit schedule": "Update schedule",
  "Choose Process": "Choose process",
  "Choose Robot": "Choose robot",
  "Disregard changes": "Discard",
  "Save changes": "Save changes",
  apply: "APPLY",
  reset: "RESET",
  processes: "Processes",
  ACTIVE: "Active",
  IDLE: "Idle",
  WORKING: "Working",
  OFFLINE: "Disconnected",
  Idle: "Idle",
  Working: "Working",
  Offline: "Disconnected",
  DISABLED: "Inactive",
  DISABLE: "Inactive",
  Disabled: "Disabled",
  Missing: "Missing",
  "Process status": "Process status",
  "timeUnite.millis": " ms",
  "timeUnite.sec": " sec(s)",
  "timeUnite.min": " min(s)",
  "timeUnite.hrs": " HR(s)",
  "timeUnite.days": " day(s)",
  "timeUnite.manDay": "MD(s)",
  "timeUnite.months": " month(s)",
  "timeUnite.fte": "FTE",
  "Choose icon": "Choose icon",
  "Edit Icon": "Edit Icon",
  export: "export",
  "user.management.title": "User administration",
  "user.management.menu.title": "Users",
  "User Administration": "User administration",
  Administration: "Administration",
  "user.management.add.button": "Create user",
  "user.management.delete.confirmMsg":
      "Are you sure you want to delete this user?",
  "user.management.deleteUsersActionMessage":
      "Are you sure you want to delete selected users?",
  "user.management.enaDes.confirmMsg":
      "Are you sure you want to activate this user?",
  "user.management.enaDes.button.confirm": "Activate",
  "user.management.desaDes.confirmMsg":
      "Are you sure you want to deactivate this user ?",
  "user.management.desaDes.button.confirm": "Deactivate",
  "user.management.searchUser": "Search users",
  depuis: "Since",
  "depuis la premiere execution": "the first execution found",
  "kpi.parameter.ConsiderBusinessExceptionsAsCompletedItems": "Consider business exceptions as completed items in the monitoring KPIs",
  "kpi.parameter.ConsiderBusinessExceptionsAsCompletedItems.tooltip": "If activated, all item exceptions with the type Business Exception in the monitoring KPI's will be considered as completed items.",
  "kpi.parameter.be": "Include data since",
  "kpi.parameter.includeException":
      "Include item business exceptions in the financial KPIs",
  "kpi.parameter.executionsIncludeException":
      "Include executions exceptions in the financial KPIs",
  "kpi.parameter.invalidRate": "Invalid Rate",
  "kpiParam.update.success": "Settings updated successfully",
  "kpiParam.update.error": "Failed to update KPI parameters",
  "kpiParam.reset.success": "Settings reseted successfully",
  "kpiParam.reset.error": "Failed to reset KPI parameters",
  "kpi.parameter.includeSince.tooltip":
      "Choose when SmartRoby will start using and displaying process data in modules and key performance indicators throughout the portal.",
  "kpi.parameter.includeExceptFinancial.tooltip":
      "Choose the percentage of time saved per item to include for each business exception.",
  "kpi.parameter.includeExceptFinancialKPI.tooltip":
      "Choose the percentage of time saved per execution to include for each process exception.",
  "kpi.parameter.breakEvenCalculationPeriod.tooltip":
      "Choose the historical runtime to consider when estimating the break-even point for this process.",

  // ROUTES
  "routes.home": "Home",
  "routes.orchestrators": "orchestrators",
  "routes.orchestrators.processAssessment": "process Assessment",
  "routes.orchestrators.processAssessment.rpa": "rpa",
  "routes.orchestrators.processAssessment.rpa.view": "view",
  "routes.orchestrators.processAssessment.rpa.create": "create",
  "routes.orchestrators.processAssessment.rpa.edit": "edit",
  "routes.orchestrators.smartRobots": "Smart Robots",
  "routes.orchestrators.myProcesses": "My processes",
  "My Processes": "My processes",
  "routes.orchestrators.smartRobots.documents": "documents",
  "routes.orchestrators.smartRobots.documents.details": "details",
  "routes.orchestrators.administration": "administration",
  "routes.orchestrators.administration.users": "users",
  "routes.orchestrators.administration.users.add": "add",
  "routes.orchestrators.administration.users.edit": "edit",
  "routes.orchestrators.administration.users.view": "view",
  "routes.orchestrators.protected": "protected",
  "routes.orchestrators.protected.user": "user",
  "routes.orchestrators.protected.user.profile": "profile",
  "routes.orchestrators.administration.orchestrators": "orchestrators",
  "routes.orchestrators.administration.orchestrators.add": "add",
  "routes.orchestrators.administration.orchestrators.edit": "edit",
  "routes.orchestrators.administration.orchestrators.view": "view",
  "routes.orchestrators.smartocr": "smart ocr",
  "routes.orchestrators.smartocr.identity_card": "identity_card",
  "routes.orchestrators.smartocr.passport": "passport",
  "routes.orchestrators.smartocr.driver_licence": "driver_licence",
  "routes.orchestrators.smartocr.car_registration": "car_registration",
  "routes.orchestrators.smartocr.green_card": "green_card",
  "routes.orchestrators.smartocr.rib": "rib",
  "routes.orchestrators.smartocr.invoice": "invoice",
  "routes.orchestrators.smartocr.purchase_order": "purchase_order",
  "routes.orchestrators.smartocr.tax_notice": "tax_notice",
  "routes.orchestrators.smartocr.receipt": "receipt",
  "routes.orchestrators.smartocr.edf": "edf",
  "routes.orchestrators.smartocr.payroll_statemenet": "payroll_statemenet",
  "routes.orchestrators.exceptions": "Exceptions",
  "routes.orchestrators.exceptions.processes": "Process exceptions",
  "routes.orchestrators.exceptions.items": "Item exceptions",
  "routes.orchestrators.process-occurrence": "Process occurrences",
  // ALERT MANAGEMENT ROUTES
  "routes.orchestrators.alertsManagement": "Alerts management",
  "routes.orchestrators.alertsManagement.defaultAlerts": "Default alerts",
  "routes.orchestrators.alertsManagement.customAlerts": "Custom alerts",
  "routes.orchestrators.alertsManagement.defaultAlerts.add": "Add",
  "routes.orchestrators.alertsManagement.defaultAlerts.edit": "Edit",
  "routes.orchestrators.alertsManagement.defaultAlerts.view": "View",
  "routes.orchestrators.alertsManagement.customAlerts.add": "Add",
  "routes.orchestrators.alertsManagement.customAlerts.edit": "Edit",
  "routes.orchestrators.alertsManagement.customAlerts.view": "View",
  //= =======================================================================
  "exceptions.item.tab.title": "Item exceptions",
  "exceptions.processes.tab.title": "Process exceptions",
  "routes.orchestrators.process-occurence": "process-occurence",
  "routes.orchestrators.profile": "profile",
  "routes.orchestrators.administration.fleets": "groups",
  "routes.orchestrators.administration.fleets.add": "add",
  "routes.orchestrators.administration.fleets.edit": "edit",
  "routes.orchestrators.administration.fleets.view": "view",
  "routes.orchestrators.analytics": "Analytics",
  Analytics: "Analytics",
  "Alerts Management": "Alerts management",
  "save draft": "SAVE DRAFT",
  "user.title.editHeader": "Edit User",
  "fleet.management.status": "Status",
  "fleet.management.companyName": "Group name",
  "fleet.management.email": "Email",
  "fleet.management.clientSince": "Group created since",
  "fleet.management.title": "Group administration",
  "fleet.management.menu.title": "groups",
  "fleet.management.add.button": "Create group",
  "fleet.management.delete.confirmMsg":
      "Are you sure you want to delete this group?",
  "fleet.management.deleteFleetsActionMessage":
      "Are you sure you want to delete selected groups",
  "fleet.management.enaDes.confirmMsg":
      "Are you sure you want to activate this group?",
  "fleet.management.enaDes.button.confirm": "activate",
  "fleet.management.desaDes.confirmMsg":
      "Are you sure you want to deactivate this group?",
  "fleet.management.desaDes.button.confirm": "deactivate",
    "fleet.management.searchFleet": "Search fleets",
  "fleet.management.addHeader": "Add group",
  "fleet.management.editHeader": "Edit group",
  "fleet.management.displayHeader": "Group details",
  "fleet.management.formControl.required": "Required field",
  "fleet.management.formLabel.companyName": "Group name",
  "fleet.management.formControl.email": "Wrong email format",
  "fleet.management.formControl.siret.notValid": "SIRET not valid",
  "fleet.management.formControl.siren.notValid": "SIREN not valid",
  "fleet.management.formLabel.email": "Email",
  "fleet.management.formLabel.phoneNumber": "Phone number",
  "fleet.management.formLabel.vatNumber": "VAT number",
  "fleet.management.formControl.vatNumber.notValid": "Value must be a number",
  "fleet.management.formLabel.address": "Full address",
  "fleet.management.formLabel.subscription": "Subscription",
  "fleet.management.formLabel.clientSince": "Client since",
  "fleet.button.update": "Update",
  "fleet.button.save": "Add",
  "fleet.button.cancel": "Cancel",
  "fleett.update.confirmMsg":
      "Are you sure you want to update group information?",
  "fleet.save.confirmMsg": "Are you sure you want to add group?",
  "fleet.delete.discard": "Are you sure you want to discard your changes?",
  "fleet.button.discard": "Discard",
  "fleet.formControl.updateSuccess": "Group information updated successfully",
  "fleet.formControl.updateError": "Error while updating group information",
  "fleet.formControl.saveError": "Error while adding new group",
  "fleet.management.formControl.invalidDate": "Invalid date format",
  "orchestrator.formControl.updateSuccess":
      "Orchestrator information updated successfully",
  "user.management.formLabel.fleet": "Group",
  "processesSelector.availableProcess": "Available processes",
  "processesSelector.assignedProcess": "Assigned processes",
  "processTagSelector.assignedProcessTags": "Assigned processes & tags",
  "tagSelector.availableTags": "Availables tags",
  "user.formControl.saveError": "Error while adding new user",
  "user.formControl.updateError": "Error while updating group information",
  "user.formControl.updateSuccess": "User information updated successfully",
  "user.formControl.saveSuccess": "New user added successfully",
  "user.permissions.title": "User permissions",
  "user.permission.save": "Save",
  "user.permissions.template.createBtn": "Create template",
  "user.add.template": "Template",
  "user.permission.confirmSave":
      "Are you sure you want to update the users permissions?",
  "user.permission.confirmDelete":
      "Are you sure you want to delete this template?",
  "user.permission.saveAsTemplace": "Save & Create template",
  "user.permission.saveAsTemplaceTitle": "New template",
  "user.permission.templateCreate.success": "Template created successfully",
  "user.permission.templateDelete.success": "Template deleted successfully",
  "user.permission.templateCreate.error": "template name already exists",
  "permission.action.applyTemplate": "Apply template",
  "user.permissions.template.name": "Template name",
  "user.permissions.default": "Default",
  "user.add.permissions": "Permissions",
  "fleet.formControl.saveSuccess": "New group added successfully",
  "orchestrator.formControl.saveSuccess": "New orchestrator added successfully",
  "fleet.formControl.unassignProcessError":
      "Process is already assigned to {{users}} users",
  "fleet.management.delete.warning":
      "Deleting a group will automatically delete all its users and unassign its processes!",
  "user.administration.group": "Group",
  "user.administration.lastLogin": "Last login",
  "tooltip.action.enable": "Enable",
  "tooltip.action.disable": "Disable",
  "tooltip.action.permissions": "Permissions",
  "user.management.formControl.required": "Required field",
  "user.management.formControl.email": "Wrong email format",
  "fleet.management.formControl.phone": "Phone number format is not accepted",
  "user.management.formControl.phone": "Wrong phone format",
  "fleet.formSection.fleetInfo": "General info",
  "fleet.formSection.clientParams": "Client parameters",
  "fleet.formSection.subscriptionInfo": "Subscription info",
  "fleet.formSection.clientParameters": "Group settings",
  "fleet.management.formLabel.rate": "Rate",
  "fleet.management.formLabel.overConsumptionRate": "Overconsumption rate",
  "fleet.management.formLabel.subscriptionPeriod": "Subscription period",
  "fleet.management.formLabel.paymentTerm": "Payment term",
  "fleet.formSection.processes": "Processes",
  "fleet.formSection.processesTags": "Processes & tags",
  "fleet.formSection.summary": "Active Subscription summary",
  "user.administration.fleet": "Group",
  "fleet.management.formLabel.rateUnit": "€/hour",
  "fleet.management.formLabel.subscriptionPeriodUnit": "months",
  "fleet.management.formLabel.siret": "Siret",
  "fleet.management.formLabel.siren": "Siren",
  "fleet.management.formLabel.manDayValue": "1 Man-day (md)",
  "fleet.management.formLabel.fullTime": "1 Full-time employee (FTE)",
  "fleet.management.formLabel.bePeriod": "Break-even calculation period",
  "fleet.management.formLabel.recommendedOccupancy": "Recommended occupancy",
  "fleet.management.be.period.1": "Last month",
  "fleet.management.be.period.2": "Last 3 months",
  "fleet.management.be.period.3": "Last 6 months",
  "fleet.management.be.period.4": "Last year",
  "fleet.add.previous": "Previous",
  fleet: "Group",
  "process.filter.fleet": "Group",
  "process.enabled": "Showed",
  "process.filter.showAll": "Show hidden processes",
  Unremarkable: "< 5 Years",
  "fleet.management.formControl.number": "VAT number format is not accepted",
  "fleet.management.formControl.occupancyNumber": "Recommanded occupancy has to be a number",
  "fleet.management.formControl.occupancyHigherThan": "Recommanded occupancy cannot be higher than 24",
  "fleet.management.formControl.occupancyLowerThan": "Recommanded occupancy cannot be lower than 0",
  "fleet.management.hoursPerDay": "hours per day",
  "reporting.copyToClipboard": "Copy to clipboard",
  "reporting.process": "Process",
  "reporting.kpi": "KPI",
  "reporting.reportType": "Report type",
  "reporting.date": "Date",
  "reporting.table": "Table",
  "reporting.lineChart": "Line chart",
  "reporting.pieChart": "Pie chart",
  "reporting.barChar": "Bar chart",
  "reporting.columnChart": "Column chart",

  "reporting.processExecutions": "Process executions",
  "reporting.itemsProcessed": "Items processed",
  "reporting.itemsCompleted": "Items completed",
  "reporting.successRate": "Items success rate",
  "reporting.exceptionRate": "Items exception rate",
  "reporting.itemsBusinessExceptions": "Items business exceptions",
  "reporting.itemsSystemExceptions": "Items system exceptions",

  "reporting.totalException": "Total exceptions",
  "Exception rate": "Exception rate",
  "reporting.timeSaved": "Time saved",
  "reporting.executionTime": "Process execution time",
  "reporting.itemExecutionTime": "Item execution time",
  "reporting.breakEven": "Break-even",
  "reporting.roi": "Profitability ratio",
  "reporting.roiShort": "Profitability ratio",
  "reporting.occupancyRate": "Occupancy Rate",
  "reporting.roiPercentage": "Profitability ratio",
  "reporting.executedProcesses": "Executed processes",
  "reporting.save": "Export as PNG",
  "reporting.copyClipboard": "Copy to clipboard",
  "reporting.exportPDF": "Export as PDF",
  "reporting.clear": "Clear",
  "reporting.from": "From",
  "reporting.to": "To",
  "reporting.exceptionCount": "Exception count",
  "reporting.reportTitle": "between {{from}} and {{to}}",
  "reporting.and": "and",
  for: "for",
  "reporting.cumul": "Cumulative result",
  "reporting.date.errorFuture": "Value should be greater than {{dateValue}}",
  "reporting.date.errorPast": "Value should be less than {{dateValue}}",
  "Process launcher": "Process launcher",
  "Execution detail": "Execution details",
  "Business Exception": "Business exception",
  "System Exception": "System exception",
  "Process.list.names.by.orchestrator": "Processes",
  "Robot.list.names.by.orchestrator": "Robots",
  "fleet.management.formLabel.effectiveStartDate": "Effective start date",
  "rpaQual.transition.delete": "Delete",
  "rpaQual.transition.deleteMsg":
      "Are you sure you want to delete this opportunity?",
  "rpaQual.button.delete":
      "Are you sure you want to delete this qualification?",
  "rpaQual.transition.reconsider": "Reconsider",
  "rpaQual.transition.reconsiderMsg":
      "Are you sure you want to reconsider this case?",
  "rpaQual.transition.archive": "Archive",
  "rpaQual.transition.archiveMsg":
      "Are you sure you want to archive this case?",
  "rpaQual.process.archived.success": "Case archived",
  "rpaQual.process.roadMap.success": "Added to roadmap",
  "rpaQual.process.reconsider.success": "Opportunity reconsidered",
  "rpaQual.transition.addToRoadMap": "Add to roadmap",
  "rpaQual.transition.addToRoadMapMsg":
      "Are you sure you want to add this case to your roadmap?",
  "rpaQual.filter.show": "Include archived",
  "rpaQual.display.nonArchived": "Not archived",
  "rpaQual.display.archived": "Archived",
  "rpaQual.display.all": "All",
  "breadcrumbs.label.details": "Details",
  "rpaQual.opportunity.list.empty": "No opportunity found !",
  "rpaQual.case.list.empty": "No case found !",
  "rpaQual.status.submitted": "Pending",
  "rpaQual.status.reconsider": "Pending",
  "rpaQual.status.accepted": "Accepted",
  "rpaQual.status.draft": "Draft",
  "rpaQual.status.not submitted": "Not validated",
  "rpaQual.status.rejected": "Rejected",
  "rpaQual.status.roadMap": "RoadMap",

  "filter.graph": "Graph",
  "filter.export": "Export",

  // Analytics - help
  "help.process": "Please choose the process(es) concerned by the analytics",
  "help.tags": "Please choose the tag(s) concerned by the analytics",
  "help.kpi": "Please choose the KPI(s)",
  "help.reportType":
      "The displayed list of Report type is based on the chosen KPI(s)",

  "Select All": "Select all",
  // permissions

  "Start process": "Start process",
  "Schedule process": "Schedule process",
  "KPI parameters": "Settings",
  "Financial Parameters": "Financial parameters",
  "Edit process details": "Edit process details",
  "Enable/Disable process": "Show/Hide processes",
  "Item exceptions": "Item exceptions",
  "Delete item exceptions": "Hide item exceptions",
  "Retry item exceptions with changed data":
      "Retry item exceptions with changed data",
  "Retry item exceptions": "Retry item exceptions",
  Workflow: "Workflow",
  "Process execution exceptions": "Process execution exceptions",
  "Delete process execution exceptions": "Delete process execution exceptions",
  "View process exceptions": "View process exceptions",
  "Delete process exceptions": "Hide process exceptions",
  "View item exceptions": "View item exceptions",
  "Add opportunity": "Add opportunity",
  "Accept / Reject / Reconsider": "Accept / Reject / Reconsider",
  "Archive / Backlog": "Archive / Backlog",
  "Delete opportunity": "Delete opportunity",
  "Add fleet": "Add group",
  "Edit fleet": "Edit group",
  "Delete fleet": "Delete group",
  "Add user": "Add user",
  "Edit user": "Edit users",
  "Delete user": "Delete user",
  "User Permissions": "User permissions",
  "Add Orchestrators": "Add orchestrator",
  "Edit Orchestrators": "Edit orchestrators",
  "Delete Orchestrators": "Delete orchestrator",
  "process-occurence.cutsomdate": "Custom date",
  "exception.analytics.by.process": "Exceptions by process",
  "exception.analytics.by.type": "Exception type summary",
  "exception.analytics.by.reason": "Top exception reasons",
  "exception.analytics.by.trend": "Exception trend",

  "Execution Time (Descending)": "Execution duration (descending)",
  "Execution Time (Ascending)": "Execution duration (ascending)",

  "no.process.message": "No processes detected",

  "no.completedItems.message": "No completed items detected",
  "no.pendingItems.message": "No pending items detected",
  "no.orchestrator.message": "No orchestrators yet",
  "no.robot.message": "No robots yet",
  "no.fleets.message": "No groups yet",
  "no.users.message": "No  users yet",
  "no.robots.message": "No robots yet",
  "no.items.exception.message": "No item exceptions found",
  "no.process.exception.message": "No process exceptions found",
  "no.analytics.message": "No process detected",
  "no.planned.execution.message": "No Planned executions found",
  "no.activities.message": "No activities found",
  "no.execution.message": "No process execution found",
  "no.scheduler.message": "No schedule found",

  // ALERT MANAGEMENT
  "alerts.default.tab.title": "Default alerts",
  "alerts.custom.tab.title": "Custom alerts",

  // DEFAULT ALERTS TABLE HAEDER CELLS
  "alert.status": "Status",
  "alert.alertName": "Name",
  "default.alert.event": "Event",
  "alert.operator": "Operator",
  "alert.condition": "Condition",
  "alert.type": "Alert type",
  "report.type": "Report type",
  "alert.searchAlerts": "Search Alerts",

  "no.alerts.message": "No alerts found !",
  "alerts.default.addButton": "Create alert",

  // CUSTOM ALERTS TABLE HAEDER CELLS
  "custom.alert.KPI": "KPI",
  "custom.alert.process": "Process",
  "custom.alert.searchCustomAlerts": "Search Alerts",
  "alerts.custom.addButton": "Add Alert",
  "alert.management.delete.confirmMsg":
      "Are you sure you want to delete this alert?",

  // Add & Edit headers alert
  "alerts.addHeader": "Add alert",
  "alerts.editHeader": "Edit alert",
  "alerts.displayHeader": "Alert details",

  // error messages
  "alerts.management.required.fields": "Required Field",

  // confirmation buttons
  "alerts.management.button.update": "update",
  "alerts.management.button.add": "add",
  "alerts.management.button.cancel": "cancel",
  "alerts.management.button.save": "save",
  "alerts.management.button.exit": "exit",
  "alerts.management.button.discard": "discard",

  "alerts.management.trigger": "Trigger",
  "alerts.management.triggers": "Triggers",
  "alerts.management.notifications": "Notifications",
  "alerts.management.subscribers": "Subscribers",
  "alerts.management.name.placeholder": "Enter alert name",
  "alerts.management.name.label": "Alert Name *",
  "alerts.management.condition.label": "Value *",
  "alerts.management.alertDescription.placeholder":
      "Enter the description of the alert",
  "alerts.management.alertDescription.label": "Description",
  "alerts.management.when.placeholder": "When *",
  "alerts.management.operator.placeholder": "Is *",
  "alerts.management.process.placeholder": "Of *",
  "alerts.management.of.placeholder": "Of *",

  "alerts.management.email.invalidEmail": "Invalid email address format",
  "alerts.management.email.invalidEmails": "Invalid email addresses format",
  "alerts.management.email.invalidPhoneNumber": "Invalid phone number format",
  "alerts.management.email.invalidPhoneNumbers": "Invalid phone numbers format",
  "alerts.management.severity": "Alert type *",
  "alerts.management.save.confirmMsg":
      "Are you sure you want to save this alert?",
  "alerts.management.update.confirmMsg":
      "Are you sure you want to update alert information?",
  "alerts.management.delete.discard":
      "Are you sure you want to discard your changes?",
  "Alert Frequency": "Frequency",
  "alert.management.formControl.saveSuccess": "New alert added successfully",
  "alert.management.formControl.updateSuccess":
      "Alert information updated successfully",
  "alert.management.formControl.delete":
    "Notification has been deleted",
  "alert.management.enable.email.notification":
      "Are you sure you want to enable notifications by email for this alert ?",
  "alert.management.disable.email.notification":
      "Are you sure you want to disable notifications by email for this alert ?",
  "alert.management.enable.sms.notification":
      "Are you sure you want to enable notifications by sms for this alert ?",
  "Success Rate": "Success rate",
  "alert.management.disable.sms.notification":
      "Are you sure you want to disable notifications by sms for this alert ?",
  "Execution Time": "Execution duration",
  "Process execution duration": "Process execution duration",
  "Total Executions": "Total executions",
  "Items success rate": "Items success rate",
  "Exceptions Rate": "Exceptions rate",
  "Items exception rate": "Items exception rate",
  "Time Saved": "Time saved",
  "Break-Even": "Break-even",
  "Number of completed items": "Number of completed items",
  "create.scheduler": "Create schedule",
  "Edit Scheduler": "Edit Schedule",
  "Confirm frequency": "Confirm frequency",
  "Update frequency": "Update frequency",
  "First day of month": "First day of month",
  "Last day of month": "Last day of month",
  "Day number": "Day number",
  "alert.management.email.helper":
      "You can validate the addition of your email by either clicking Enter or Space.",
  "alert.management.sms.helper":
      "You can validate the addition of your phone number by either clicking Enter or Space.",
  "alert.management.trigger.helper":
      "In case of multiple triggers, all triggers must be true for the notification to be sent.",
  Active: "Active",
  info: "Information",
  error: "Error",
  EQUAL: "EQUAL",
  TRUE: "TRUE",
  FALSE: "FALSE",
  "GREATER THAN": "Greater than",
  "LESS THAN": "Less than",
  "GREATER OR EQUAL TO": "Greater or equal to",
  "LESS OR EQUAL TO": "Less or equal to",
  "alerts.management.alertName.maxLength.error":
      "This field must not exceed 55 characters!",
  "alerts.management.alertDescription.maxLength.error":
      "This field must not exceed 65535 characters!",
  "select.all": "Select all",

  "add.fleet.form.vat.number.email.company.name.already.exist":
      "A group with the same email address, VAT number and company name already exists",
  "add.fleet.form.vat.number.email.already.exist":
      "A group with the same email address and the same VAT number already exists",
  "add.fleet.form.vat.number.company.name.already.exist":
      "A group with the same VAT number and company name already exists",
  "add.fleet.form.email.company.name.already.exist":
      "A group with the same email address and company name already exists",
  "add.fleet.form.email.already.exist":
      "A group with the same email address already exists",
  "add.fleet.form.vat.number.already.exist":
      "A group with the same VAT number already exists",
  "add.fleet.form.company.name.already.exist":
      "A group with the same company name already exists",

  "add.fleet.form.vat.number.email.company.name.siren.already.exist":
      "A group with the same email address, VAT number, company name and SIREN already exists",
  "add.fleet.form.vat.number.email.siren.already.exist":
      "A group with the same email address, VAT number and SIREN already exists",
  "add.fleet.form.vat.number.company.name.siren.already.exist":
      "A group with the same VAT number, company name and SIREN already exists",
  "add.fleet.form.vat.number.siren.already.exist":
      "A group with the same VAT number and the same SIREN already exists",
  "add.fleet.form.email.company.name.siren.already.exist":
      "A group with the same email address, company name and SIREN already exists",
  "add.fleet.form.email.siren.already.exist":
      "A group with the same email address and SIREN already exists",
  "add.fleet.form.company.name.siren.already.exist":
      "A group with the same company name and the same SIREN already exists",
  "add.fleet.form.siren.already.exist":
      "A group with the same SIREN already exists",

  // Robot Management
  "robot.management.name": "Name",
  "robot.management.formControl.actionsRunning": "Actions running",
  "robot.management.formControl.processesRunning": "Processes running",
  "robot.management.formControl.robotName": "Robot name",
  "robot.management.formControl.robotOriginalName": "Original resource name",
  "robot.management.formControl.statusDescription": "Status description",
  "robot.management.formControl.robotId": "Robot id",
  "robot.management.formControl.unitsAllocated": "Units allocated",
  "robot.management.solutionProvider": "Solution provider",
  "robot.management.displayStatus": "Display status",
  "robot.management.orchestratorName": "Orchestrator",
  "robot.management.attributeName": "Robot type",
  "robot.management.processesRunning": "Processes running",
  "robot.management.robotId": "Robot id",
  "robot.management.robotName": "Robot name",
  "robot.management.searchRobot": "Search robot",
  "Routes.orchestrators.administration.robots": "Robots",
  "robot.management.displayHeader": "Robot Details",
  "robot.management.enable.confirmMsg":
      "Are you sure you want to activate this robot ?",
  "robot.management.disable.confirmMsg":
      "Are you sure you want to deactivate this robot ?",
  "robot.management.disable.button.confirm": "Deactivate",
  "robot.management.enable.button.confirm": "Activate",
  "robot.button.update": "Update",
  "robot.button.cancel": "Cancel",
  "robot.button.discard": "Discard",
  "robot.update.confirmMsg":
      "Are you sure you want to update robot information?",
  "robot.update.discard": "Are you sure you want to discard your changes?",
  "robot.modify": "Edit",
  "routes.orchestrators.administration.robots.view": "view",
  "routes.orchestrators.administration.robots.edit": "edit",
  "routes.orchestrators.administration.robots": "Robots",
  "administration.detail.entity.processes.title": "Processes",
  "start.process.no.robot.assigned": "No robots assigned",
  "no.process.planned": "No planned processes yet",
  Fleet: "Group",
  "Orchestrator Administration": "Orchestrator administration",
  "New opportunity created": "New opportunity created",
  "Opportunity accepted": "Opportunity accepted",
  "Opportunity rejected": "Opportunity rejected",
  "Opportunity added to the road map": "Opportunity added to the road map",
  "Opportunity archived": "Opportunity archived",
  "Process activated": "Process activated",
  "Process disabled": "Process disabled",
  "Orchestrator enabled": "Orchestrator enabled",
  "Orchestrator disabled": "Orchestrator disabled",
  "Orchestrator actionable": "Orchestrator actionable",
  "Orchestrator not actionable": "Orchestrator not actionable",
  "Schedule added": "Schedule added",
  "Time Spent": "Time spent",
  "IT Review": "Complexity",
  Comment: "Comment",
  Schedules: "Schedules",
  "Delete Schedule": "Delete schedule",
  "Filter List": "Filter list",
  Validate: "Validate",
  "kpi.header.breakEven.tooltip":
      "The break-even estimated based on historical data. It anwsers to when is the investment in the processes gained in full ?  Results are based on the finciancial parameters configured for each process and the time filter of the dashboard. ",
  "kpi.header.roi.tooltip":
      "Answers to the question of how much of the initial investment has already been earned? Results based on the financial parameters configured for each process and the time filter of the dashboard.",
  "kpi.header.breakEven.tooltip.nodata":
      "Waiting for more executions to estimate the break even point.",
  "kpi.header.roi.tooltip.nodata":
      "Waiting for more executions to calculate the profitability ratio.",
  "kpi.header.success.rate.tooltip":
      "The success rate is calculated for process items. Results are based on the settings configured for each process and the time filter of the dashboard. ",
  "kpi.header.exception.rate.tooltip":
      "The exception rate is calculated for process items. Results are based on the settings configured for each process and the time filter of the dashboard. ",
  "dashboard.graph.treatedItems": "Completed items",
  "dashboard.graph.pendingItems": "Pending items",
  "dashboard.graph.exceptionItems": "Exception items",
  "dashboard.graph.totalItems": "Total items",
  "Submitting...": "Submitting...",
  "NOT VALIDATED": "NOT VALIDATED",
  "tooltip.action.hide": "Hide",
  "tooltip.action.show": "Show",
  "robot.management.deleteRobotActionMessage": "Elements selected will have their visibility changed to either hidden or visible.",
  "tooltip.action.showHiddenItems": "Show hidden",
  "exception.analytics.typeNotFound": "Exception type not specified",
  "orchestrator.management.formControl.sessionNumber":
      "Number of allowed sessions",
  "profile.user.general.title": "General information",
  "profile.user.general.security": "Security",
  "rpa.export.rpa": "RPA",
  "rpa.export.qualification": "QUALIFICATION",
  "rpa.create.businessApplication": "List applications here",
  "user.add.admin": "Admin user",
  "Items status by process": "Items status by process",
  "Average Handling Time": "Average handling time",
  "Current Period": "Current Period",
  "Last Period": "Last Period",
  "use intervals": "Use intervals",
  "use cumulative": "Use cumulative",
  "please enter at least one email to enable email notification":
      "Please enter at least one email to enable email notification.",
  "please enter at least one phone number to activate the sms notification":
      "Please enter at least one phone number to activate the sms notification.",
  Exception: "Exception",
  Success: "Success",
  "The calculation of the KPIs will be based on an interval":
      "The calculation of the KPIs will be based on an interval.",
  "Calculation of the KPIs for the whole time":
      "Calculation of the KPIs for the whole time.",
  "Receive alerts from the following date":
      "Receive alerts from the following date.",
  "Stop receiving alerts from the following date":
      "stop receiving alerts from the following date.",
  "dashboard.tooltip.timesaved":
      "This graph shows the distribution of time saved (in hours) over the period selected in the dashboard time filter. The results are calculated based on the financial parameters configured for each process and the process filter of the dashboard.",
  "dashboard.tooltip.processOverview":
      "This graph shows the distribution of the execution time of each process compared to all the processes. Results are based on the time filter of the dashboard.",
  "dashboard.tooltip.itemsProcess":
      "This graph shows the breakdown of status of items for the top processes. Results are based on the time filter of the dashboard.",
  "dashboard.tooltip.executionsProcess":
      "This graph shows the breakdown of process executions over the period selected in the dashboard time filter.",
  "dashboard.tooltip.averageTime":
      "The average processing time (AHT) is the average time it took to execute an item in this process during the period selected in the time filter.",
  STOPPED: "Stopped",
  COMPLETED: "Completed",
  DEBUGGING: "Debugging",
  ARCHIVED: "Archived",
  STOPPING: "Stopping",
  WARNING: "Warning",
  "status.running.with.warning": "Running with a warning",
  "process.history.duration": "Execution duration",
  "process.history.completedItems": "Completed items",
  "process.history.exceptionItems": "Exception items",
  "process.history.robot": "Robot",
  AUTOMATIC: "Automatic",
  MANUAL: "Manual",

  "Process data mapping": "Data mapping",
  "Are you sure you want to update the mapping ?":
      "Are you sure you want to update the mapping ?",
  "no.data-mapping.message":
      "Mapping can only be done after the processing of the first process item.",
  Data: "Data",
  "New name": "New name",
  Display: "Display",
  "display.in.items.list": "Show in items list",
  "Orchestrator configuration": "Orchestrator configuration",
  "SmartRoby will add columns and create views and triggers on your orchestrator database":
      "SmartRoby will add columns and create views and triggers on your orchestrator database.",
  "Initialize connectors to synchronize data between your orchestrator and SmartRoby":
      "Initialize connectors to synchronize data between your orchestrator and SmartRoby.",
  "sync.Execute": "Execute",
  "sync.Initialize": "Initialize",
  "Database configuration": "Database configuration",
  "Orchestrator/SmartRoby synchronization":
      "Orchestrator/SmartRoby synchronization",
  "Orchestrator status": "Orchestrator status",
  "sync.Refresh": "Refresh",
  "sync.General": "General",
  "Database connection": "Database connection",
  "Synchronization engine": "Synchronization engine",
  "sync.Synchronization": "Synchronization",
  "sync.process": "Processes",
  "sync.execution": "Executions",
  "sync.resource": "Resources",
  "sync.schedule": "Schedules",
  "sync.work_queue_item": "Items",
  "Number of items with Business Exceptions":
      "Number of items with Business Exceptions",
  "Number of items with System/Internal Exception":
      "Number of items with System/Internal Exception",
  "Number of process executions with Business Exceptions":
      "Number of process executions with Business Exceptions",
  "Number of process executions with System/Internal Exception":
      "Number of process executions with System/Internal Exception",
  "sync.deleted_record": "Records deletion",
  "sync.license": "Licenses",
  "robot.management.hide.confirmMsg":
      "Are you sure you want to hide this robot?",
  "robot.management.show.confirmMsg":
      "Are you sure you want to show this robot?",
  "filter.clear": "Clear filters",
  Reasons: "Reasons",
  "List of conflicts for": "List of conflicts for ",
  allowedSessionsExceededForAnOrchestrator:
      "{{parallelExecutions}} parallel executions were found whereas the orchestrator : '{{OrhcestratorName}}' has only {{allowedSessions}} session(s) allowed.",
  concurrentExecutionInTheSameRobotMessage:
      "More than one execution was found in robot : '{{robotName}}' at {{executionDate}} between {{plannerStart}} and {{plannerEnd}}. The other planned executions will begin after the first one has been completed according to this order : ",
  "Number of pending items": "Number of pending items",
  "kpi.parameter.allowScheduling": "Can be scheduled",
  "kpi.parameter.allowManual": "Can be started manually",
  "kpi.parameter.retryable": "Exception items can be retried",
  "kpi.parameter.retryableWithData": "Exception items can be retried with different data",
  "kpiParam.schedule.warning":
      "This process already has schedules. If you proceed, they will all be deleted.",
  "Warnings Management": "Warnings Management",
  "processStore.year": "Year",
  "processStore.years": "Years",
  "can.be.edited.for.retry": "Can be edited for a retry",
  retry: "Retry",
  "retry-dialog.title.one-item": "Retry item exception",
  "retry-dialog.title.multiple-items": "Retry {{count}} item exceptions",
  "retry-dialog.text.one-item":
      "Are you sure you want to retry this exception? The item will be retried the next time the process is executed.",
  "retry-dialog.text.multiple-items":
      "Are you sure you want to retry {{count}} exceptions? The items will be retried the next time their processes are executed.",
  "retry-dialog.pending-alert": "A pending retry for this item already exists",
  "retry-dialog.pending-alert-multiple": "Pending retries for these items already exist",
  "retry-dialog.message.warning.pending": " (Items that already have a pending retry will not undergo another retry)",
  "retry-dialog.not-retryable": "Selected item(s) belong to a process that can't be retried",
  "retry-dialog.radio.same-data": "Retry with the same data",
  "retry-dialog.radio.changed-data": "Retry with changed data",
  "retry-dialog.table.name": "Name",
  "retry-dialog.table.old-value": "Old value",
  "retry-dialog.table.new-value": "New value",
  "retry-dialog.message.success.one-item":
      "The item's retry is created successfully",
  "retry-dialog.message.success.multiple-items":
      "{{count}} items retries are created successfully",
  "retry-dialog.message.error.one-item": "Failed to create the item's retry",
  "retry-dialog.message.error.multiple-items":
      "Failed to create the items retries",
  "restriction.form.hour.maxValue.error":
      "The value must be less than or equal to 23.",
  "restriction.form.min.sec.maxValue.error":
      "The value must be less than or equal to 59.",
  attempt: "Attempt",
  "attempt.chip.tooltip": "This is this item's {{attempt}} attempt",
  "exception-workflow.status.ready": "Ready",
  "exception-workflow.status.taken": "Assigned to {{firstname}} {{lastname}}",
  "exception-workflow.status.done": "Done",
  "exception-workflow.action.take": "Take",
  "exception-workflow.action.assign": "Assign",
  "exception-workflow.action.release": "Release",
  "exception-workflow.action.reopen": "Reopen",
  "exception-workflow.action.markAsDone": "Mark as Done",
  "exception-workflow.log.take":
      "Taken by <b>{{user.firstname}} {{user.lastname}}</b>",
  "exception-workflow.log.assign":
      "Assigned to <b>{{targetUser.firstname}} {{targetUser.lastname}}</b> by <b>{{user.firstname}} {{user.lastname}}</b>",
  "exception-workflow.log.markAsDone":
      "Marked as done by <b>{{user.firstname}} {{user.lastname}}</b>",
  "exception-workflow.log.release":
      "Released by <b>{{user.firstname}} {{user.lastname}}</b>",
  "exception-workflow.log.reopen":
      "Reopened by <b>{{user.firstname}} {{user.lastname}}</b>",
  "exception-workflow.snackbar.success":
      "The action was performed successfully",
  "exception-workflow.snackbar.error": "Failed to perform the action",
  "exception-workflow.confirm.take":
      "Are you sure you want to take this exception?",
  "exception-workflow.confirm.assign":
      "Are you sure you want to assign this exception to {{selectedUser.firstName}} {{selectedUser.lastName}}?",
  "exception-workflow.confirm.markAsDone":
      "Are you sure you want to mark this exception as done?",
  "exception-workflow.confirm.release":
      "Are you sure you want to release this exception?",
  "exception-workflow.confirm.reopen":
      "Are you sure you want to reopen this exception?",
  "exception-workflow.open": "Workflow",
  "exception-workflow.open-bulk": "Workflow",
  "exception-workflow.no-bulk-action-possible":
      "No action is possible for the selected exception combination",
  "exception-workflow.selected-message": "{{count}} exceptions are selected",
  "exception-workflow.confirm.bulk-take":
      "Are you sure you want to take {{count}} exceptions?",
  "exception-workflow.confirm.bulk-assign":
      "Are you sure you want to assign {{count}} exceptions to {{selectedUser.firstName}} {{selectedUser.lastName}}?",
  "exception-workflow.confirm.bulk-markAsDone":
      "Are you sure you want to mark {{count}} exceptions as done?",
  "exception-workflow.confirm.bulk-release":
      "Are you sure you want to release {{count}} exceptions?",
  "exception-workflow.confirm.bulk-reopen":
      "Are you sure you want to reopen {{count}} exceptions?",
  "exception-workflow.choose-user": "Choose a user",
  "exception-workflow.snackbar.success.take":
      "The exception was taken successfully",
  "exception-workflow.snackbar.success.assign":
      "The exception was assigned successfully",
  "exception-workflow.snackbar.success.assign.notify":
      "The assignee has been notified",
  "exception-workflow.snackbar.success.release":
      "The exception was released successfully",
  "exception-workflow.snackbar.success.markAsDone":
      "The exception was marked as done successfully",
  "exception-workflow.snackbar.success.reopen":
      "The exception was reopened successfully",
  "exception-workflow.snackbar.success.bulkTake":
      "The exceptions were taken successfully",
  "exception-workflow.snackbar.success.bulkAssign":
      "The exceptions were assigned successfully",
  "exception-workflow.snackbar.success.bulkRelease":
      "The exceptions were released successfully",
  "exception-workflow.snackbar.success.bulkMarkAsDone":
      "The exceptions were marked as done successfully",
  "exception-workflow.snackbar.success.bulkReopen":
      "The exceptions were reopened successfully",
  "processe.schedules.onDay.first": " - On the {{startDay}}st",
  "processe.schedules.onDay.second": " - On the {{startDay}}nd",
  "processe.schedules.onDay.third": " - On the {{startDay}}rd",
  "processe.schedules.onDay": " - On the {{startDay}}th",
    "orchestrator.license.status": "Status",
    "orchestrator.license.status.ACTIVE": "Active",
    "orchestrator.license.status.EXPIRED": "Expired",
    "orchestrator.license.status.NOT_YET_ACTIVE": "Not yet active",
    "process.laststage": "LAST STAGE: {{lastStage}}",
    "analytics.preset.button.label": "Presets",
    "analytics.preset.load": "Load existing preset",
    "analytics.preset.create": "Create new preset",
    "analytics.preset.noPresets": "No saved presets",
    "analytics.preset.dialog.label": "New preset",
    "analytics.preset.dialog.field.label": "Preset name",
    "analytics.preset.dialog.field.submit": "Create preset",
    "analytics.preset.dialog.delete.label": "Delete preset",
    "analytics.preset.dialog.delete.text": "Are you sure you want to delete this preset ?",
    "analytics.preset.dialog.delete.submit": "Delete preset",
    "robot.tooltip.hide": "Are you sure you want to hide {{count}} robot(s)",
    "robot.tooltip.unhide": "Are you sure you want to unhide {{count}} robot(s)",
    "export.confirmation.radio.grouped": "Export with item details grouped.",
    "export.confirmation.radio.columns": "Export with item details in columns.",
    "export.confiramtion.warnning": "To enable this option you have to select one process.",
    FINISHED: "FINISHED",
    EXPORTING: "EXPORTING",
    COLLECTING: "COLLECTING",
    "exporting.title": "Exporting files",
    "exporting.no.data": "No files to download",
    "exporting.btn.download.all": "Download all",
    "exporting.btn.delete.all": "Delete all",
  Executions: "Process executions",
  "Executions history": "Executions history",
  "routes.orchestrators.executions": "Process Executions",
  "no.executions.message": "No executions found",
  Robot: "Robot",
  Trigger: "Trigger",
  MANUALLY: "Manually",
  SCHEDULED: "Scheduled",
  KEEP: "Keep in queue",
  DELETE: "Delete from queue",
  Dates: "Dates",
  "Launching date": "Launching date",
  "Launching date (Descending)": "Launching date (descending)",
  "Launching date (Ascending)": "Launching date (ascending)",
  "queue.management.title": "SR Queue management",
  "SR Queues": "SR Queues",
  "queue.management.status": "Status",
  "queue.management.name": "Name",
  "queue.management.creation-date": "Creation date",
  "queue.management.active-since": "Active since",
  "queue.management.nb-licences": "Number of licences",
  "queue.management.orchestrator": "Orchestrator",
  "queue.management.search": "Search",
  "queue.management.new": "Create SR Queue",
  "queue.management.add": "Add queue",
  "queue.management.delete.confirm-msg": "Are you sure you want to delete selected queue?",
  "queue.management.bulk-delete.confirm-msg": "Are you sure you want to delete selected queues?",
  "queue.management.enable.confirm-msg": "Are you sure you want to activate this queue?",
  "queue.management.enable.confirm": "Activate queue",
  "queue.management.disable.confirm-msg": "Are you sure you want to disable this queue?",
  "queue.management.disable.confirm": "Disable queue",
  "queue.management.section.info": "General info",
  "queue.management.section.processes": "Processes",
  "queue.management.section.robots": "Robots",
  "queue.management.form.save.success": "The SR queue was created successfully",
  "queue.management.form.update.success": "The SR queue was updated successfully",
  "queue.management.form.save.error": "Failed to create the SR queue",
  "queue.management.form.update.error": "Failed to update the SR queue",
  "queue.management.form.validation.required": "This field is required",
  "queue.management.form.validation.negative-nb-licences": "Number of licences cannot be negative",
  "queue.management.update": "Update queue",
  "queue.management.save.confirm-msg": "Are you sure you want to create this SR queue?",
  "queue.management.update.confirm-msg": "Are you sure you want to update this SR queue?",
  "queue.management.reset.confirm-msg": "Are you sure you want to delete all missed executions of this SR queue?",
  "restriction.form.missedExecutionsPolicy": "Missed executions policy",
  "restriction.userGuide.missedExecutionsPolicy": "Specifies whether a missed execution should be kept or removed from the SR queue.",
  "queue.management.choose.robot": "Some schedules are not assigned to any robot, when migrating them to the original orchestrator assign them to :",
  "queue.management.assign.process.part1":
    "You are about to delegate the execution of {{this_process}} to the SR Queues. To guarantee expected performance, please refrain from creating schedules and launching executions outside of SmartRoby.",
  "queue.management.assign.process.part2":
    "A schedule has been found for {{this_process}} at its original orchestrator. Would you like to migrate it to use with the SR queues?",
  "queue.management.assign.process.part2_plural":
    "{{nbSchedules}} schedules have been found for {{this_process}} at its original orchestrator. Would you like to migrate them to use with the SR queues?",
  "queue.management.unassign.process.part1":
    "You are about to cancel the delegation of {{this_process}}'s executions from the SR Queues. Moving forward, all executions and schedules can be either from SmartRoby or from your original orchestrator.",
  "queue.management.unassign.process.part2": "An SR Queues schedule has been found, would you like to migrate it to the original orchestrator?",
  "queue.management.unassign.process.part2_plural": "{{nbSchedules}} SR Queues schedules have been found, would you like to migrate them to the original orchestrator?",
  thisProcess: "this process",
  thisProcess_plural: "the queue processes",
  atIts: "at its",
  atIts_plural: "at their",
  some: "Some",

  "add.schedule.same.date.constraint":
      "A schedule with the same start date exists already",

  "robot-selector.available-robots": "Available robots",
  "robot-selector.assigned-robots": "Assigned robots",
  "queue.management.form.validation.nb-licences-bigger-than-remaining": "There are {{count}} remaining licences in the selected orchestrator",
  "queue.management.no-data": "No queues yet",
  "queue.management.open-queue": "Open in SR Queue",
  "queue.management.reset-queue": "Reset SR Queue",
  "execution.manager.title": "SR Queue",
  "queue reseted successfully": "SR Queue has been reseted successfully.",
  "error while reseting queue": "Failed to reset the SR Queue.",
  "Add SR Queue": "Add SR Queue",
  "Edit SR Queue": "Edit SR Queue",
  "Delete SR Queue": "Delete SR Queue",
  "process.assigned.srqueue.tooltip": "This process is assigned to an SR Queue.",
  "number of runing executions singular": "An execution is already in progress",
  "number of runing executions plural": "Some executions are already in progress",
  "not enough licenses":
    ", you do not have any available licences. Please contact your RPA administrator.",
  "check.connectivity":
    "Please check the connectivity to the App Server {{serverName}}, it seems that the connection has been lost.",
  "deleted by sr policy": "A schedule is deleted by the SR Queues policy",
  "execution is deleted": "An SR Queue execution deleted manually",
  "execution is missed": "An SR Queue execution is missed",
  "missed raison": "Missed because of",
  UNAVAILABLE_ROBOTS: "Unavailable Robots",
  NOT_ENOUGH_LICENCES: "Not enough Licences",
  BP_UNREACHABLE: "BP unreachable",
  BP_UNRESPONSIVE: "BP unresponsive",
  "robot.formControl.succesUpdate": "Robot information updated successfully",
  "cant perform multi queue migration": "Some SR Queues schedules have been found for the selected queues. They cannot be migrated to the original orchestrator.",
  "something went wrong": "Something went wrong while processing your query , please retry.",
  Continue: "Continue",
  "Something.Wrong": "Something Wrong",
  "export.successful.notification": "Your file has been added to the export files section and is now ready for you to access and download. The addition was successful.",
  revoke: "revoke",
  "division.formSection.divisionInfo": "Division Info",
  "division.formSection.divisionProcess": "Division Processes",
  "division.formSection.divisionUser": "Division Users",
  "division.formSection.divisionAdmin": "Division Admins",
  "division.name": "Division name",
  "division.description": "Description",
  "division.fleet": "Group name",
  "division.cancel": "Cancel",
  "division.previous": "Previous",
  "division.save": "Save",
  "division.next": "Next",
  "divisions.module.add.button": "New division",
  "usersSelector.availableUsers": "Available users",
  "usersSelector.assignedUsers": "Assigned users",
  "usersSelector.availableAdmins": "Available admins",
  "usersSelector.assignedAdmins": "Assigned admins",
  "divisions.module.title": "Divisions administration",
  "divisions.status": "Status",
  "divisions.division": "Division",
  "divisions.fleet": "Group",
  "divisions.creation.date": "Creation Date",
  "division.form.updateSuccess": "Division updated successfully",
  "division.form.addSuccess": "Division added successfully",
  "divisions.module.delete.confirmMsg": "Are you sure you want to delete this division?",
  "divisions.module.deleteClientsActionMessage": "Are you sure you want to delete all those divisions?",
  "divisions.module.enaDes.confirmMsg": "Are you sure you want to activate this division?",
  "divisions.module.enaDes.button.confirm": "Activate",
  "divisions.module.desaDes.button.confirm": "Deactivate",
  "divisions.module.desaDes.confirmMsg": "Are you sure you want to activate this division?",
  "divisions.module.searchClient": "Search division",
  "division deleted successfully": "division deleted successfully",
  "no.division.message": "no division found",
  "fleet.formSection.divisions": "Divisions",
  "divisionsSelector.availableDivision": "Available divisions",
  "divisionsSelector.assignedDivision": "Assigned divisions",
  "custom.kpi.item": "Items",
  "custom.kpi.process": "Process",
  "custom.kpi.processExecution": "Process Executions",
  "custom.kpi.pending": "Pending",
  "custom.kpi.completed": "Completed successfully",
  "custom.kpi.completedWithException": "Completed with exception",
  "custom.kpi.completedWithBusinessException": "Completed with business exception",
  "custom.kpi.completedWithIntSysException": "Completed with system/internal exception",
  "custom.kpi.executionTimeHours": "Hour of execution time",
  "custom.kpi.timeSavedHours": "Hour of time saved",
  "reporting.createKpi": "Create new KPI",
  "custom.kpi.dialog.create.label": "New KPI",
  "custom.kpi.dialog.field.name": "KPI name",
  "custom.kpi.dialog.field.element": "For each",
  "custom.kpi.dialog.field.detail": "Detail",
  "custom.kpi.dialog.field.allocation": "Allocate",
  "custom.kpi.dialog.field.unitofmesure": "Unit of measure",
  "custom.kpi.formControl.required": "Required Field",
  "custom.kpi.formControl.pattern": "Please enter a number",
  "custom.kpi.dialog.create.field.submit": "CREATE KPI",
  "custom.kpi.dialog.create.label.edit": "Update custom KPI",
  "custom.kpi.dialog.create.field.edit": "UPDATE KPI",
  "custom.kpi.dialog.delete.label": "Delete KPI",
  "custom.kpi.dialog.delete.text": "Are you sure you want to delete this KPI ?",
  "custom.kpi.dialog.delete.submit": "Delete KPI",
  "fleets.fleet": "Group",
  "no.fleet.message": "No groups yet",
  "instance.owner": "Instance Owner",
  "instance.owner.confirmMsg": "Are you sure you want to proceed with assigning a new instance owner?/nThis action will cause your group to lose its instance owner status.",
  "instance.owner.can.not.be.disabled": "The instance owner can not be disabled",
  "orchestrator.management.formControl.isPrivate": "Private Orchestrator",
  private: "Private",
  "fleet.with.no.users": "This group does not have any users, can't make it instance owner",
  "fleet.with.no.admins": "This group does not have any admins, can't make it instance owner",
  "user.fleet.disabled.message": "The group of this user is disabled, can't enable this user.",
  "instance.owner.only.active.admin.can.not.be.disabled": "The only active administrator of the instance owner cannot be disabled",
  "search.available.processes": "search available processes",
  "search.assigned.processes": "search assigned processes",
  "search.available.tags": "search available tags",
  "search.available.users": "search available users",
  "search.assigned.users": "search assigned users",
  "search.available.divisions": "search available divisions",
  "orchestrator.license.originalId": "License original ID",
  "orchestrator.license.starts": "Starts",
  "orchestrator.license.expires": "Expires",
  "orchestrator.license.installedOn": "Installed",
  "orchestrator.license.maxConcurrentSessions": "Number of sessions",
  "search.assigned.divisions": "search assigned divisions",
  "search.available.robots": "search available robots",
  "search.assigned.robots": "search assigned robots",
  "drag.and.drop.here": "Drag and drop here",
  "tooltip.action.license": "Licenses",
  "A license is one month away from expiry": "A license is one month away from expiry",
  "A license is two months away from expiry": "A license is two months away from expiry",
  "Orchestrator licenses": "Orchestrator licenses",
  "tag.management.title": "Tag administration",
  "tag.management.add.button": "Create tag",
  "tag.management.searchTag": "Search tags",
  "no.tags.message": "No tags yet",
  "tag.management.add": "add",
  "tag.management.update": "update",
  "tag.add.previous": "previous",
  "tag.management.save.confirm-msg": "Do you really want to create this tag ?",
  "tag.management.update.confirm-msg": "Do you really want to update this tag ?",
  "tag.form.addSuccess": "tag added successfully",
  "tag.deleted.success": "Tag(s) deleted successfully",
  "tag.form.next": "next",
  "tag.form.name": "Tag name",
  "tag.form.description": "Tag description",
  "tooltip.action.archive": "Archive",
  "archive.confirm.msg": "Tag(s) selected will be archived",
  "tag.management.btn.confirm": "Archive",
  "tag.archived.success": "Tag(s) archived with success",
  "tag.management.deleteTagActionMessage": "Delete tag(s) selected",
  "tag.management.archived": "Archived",
  "tag.status": "Status",
  "tag.icon": "Icon",
  "tag.name": "Name",
  "tag.description": "Description",
  "tag.management.name": "name",
  "tag.management.formControl.desc": "description",
  "wrong.file.type": "Invalid file type",
  "invalid license file": "Invalid license file",
  "invalid signature": "Invalid signature",
  "empty file": "Empty file",
  "wrong file extension": "Wrong file extension",
  "missing file": "Missing file",
  "the submitted license file is invalid": "The submitted license file is invalid",
  "the signature of the license is invalid": "The signature of the license is invalid",
  "the submitted file is empty": "The submitted file is empty",
  "we only accept .lic files": "We only accept .lic files",
  "please upload a license file": "Please upload a license file",
  activation: "Activation",
  license: "License",
  "import your license here": "Import your license here...",
  activate: "Activate",
  "licence.id": "License ID",
  "licence.licensee": "Licensee",
  "licence.start.at": "Starts at",
  "licence.end.at": "Ends at",
  "licence.validation.valid": "You have a valid license.",
  "licence.validation.expire": "Your license has expired. Please renew it.",
  "licence.goback.btn": "Go to the home page",
  "tag.management.hide.confirmMsg": "Are you sure you want to delete the tag? All data will be permanently lost.",
  "productivity.hrs": "Productivity",
  Occupancy: "Occupancy (%)",
  "robots.occupancy": "Occupancy",
  "dashboard.monitoring.mode": "Monitoring mode",
  "HRS Saved": "HRS Saved",
  "Robot Productivity": "Robot productivity",
  "Process Activity": "Process activity",
  "Working Time (HRS)": "Working time (HRS)",
  "Processed Items": "Processed items",
  "HRS Runtime": "HRS Runtime",
  mins: "mins",
  "recently.used.processes": "Most used processes",
  "process.total.cost": "Process total cost",
  "tag.management.form.save.success": "Tag saved successfully",
  "tag.management.form.update.success": "Tag updated successfully",
  "tag.management.form.save.error": "Failed to create the tag",
  "tag.management.form.update.error": "Failed to update the tag",
  "scheduler.is.required": "Schedule is required",
  "try.active.alert": "The process {{processName}} is hidden, to activate this alert please unhide it.",
  "tooltip.action.close": "Close",
  "Time Saved per Execution": "Time saved per execution",
  "Time Saved per Item": "Time saved per item",
  "Cost Allocation": "Cost allocation",
  "Allocation Amount": "Allocation amount",
  "filter by": "Filter by",
  showing: "Showing",
  from: "From",
  to: "To",
  entries: "entries",
  "Data mapping": "Data mapping",
  Temps: "Temps",
  "process.start.restriction": "The process you are launching is not supposed to run today based on its SLAs, are you sure you want to continue ?",
  "server Type": "Server type",
  "Automation Impact": "Automation impact",
  ERRORS_NO_DATA_AVAILABLE: "No data available",
  "queue.management.robot.has.pendingExecutions": "You have {{count}} pending executions using this robot, please delete them if you want to proceed with this operation.",
  "queue.management.robot.has.scheduled.pendingExecutions": "{{count}} of executions are scheduled, please review the schedules assigned to this robot.",
  "occupancy.waiting.more": "Waiting for more executions to calculate robot occupancy",
  "select.process": "Select a process",
  "changes.saved.successfully": "Changes saved successfully",
  "changes.saving.failed": "Failed to save the changes",
  "process.owner": "Process owner",
  "end date cannot be earlier than the start date": "End date cannot be earlier than the start date",
  "end date cannot be in the future": "End date cannot be in the future",
  "no.period.message": "No period found",
  "no.status.message": "No status found",
  "pending.executions.warning": "Warning: This process has pending executions. Removing it now will clear them all.",
  "calendar.formControl.saveRiskSuccess": "Risk margin added successfully",
  "export.confirm.msg.delete.all": "Are you sure you want to delete all files ?",
  "export.confirm.msg.delete.one": "Are you sure you want to delete this file ?",
  on: "On",
  language: "Language",
  "Choose.the.checking.frequency": "Choose the checking frequency",
  "robots.show.hidden": "Show hidden robots",
  "user.logo.allowed.extensions": "We only allow jpg,jpeg,png and bmp extensions",
  "allowed.extensions": "We only allow {{extensions}} extensions",
  "max.size.allowed": "The maximum file size allowed is {{size}} MB",
  "items.pending.locked": "This item is currently being worked",
  "retry.mark.done": "Retry and mark as done",
  "task.initial.task": "Initial task",
  "task.post.completion.delay": "Post completion delay",
  "task.remove": "Remove task",
  "task.name": "Task name",
  "task.description": "Task description",
  "task.on.complete": "On complete",
  "task.on.exception": "On exception",
  "task.add.session": "Add session",
  "task.delete.session": "Delete session",
  "task.name.already.exist": "Task name already exist",
  "task(s)": "Task(s)",
  "add.task": "Add task",
  task: "Task",
  "no.tasks.yet": "No task yet",
  "no.task.message": "No task found",
  "task.stop": "Stop",
  "task.abort": "Abort",
  "task.required.process": "Please choose a process",
  "task.required.first.session.process": "Please choose first session process",
  "process.migration.not.possible": "This process can't be migrated as it contains multitask schedules",
  "see.all": "See all",
  "sync.task_session": "Task sessions",
  "sync.sch_task": "Tasks",
  "unique.schedule.name.exception": "Schedule name already exists",
  "awaiting.synchro": "waiting for synchronization",
  "pendingCount.descending": "Pending items (descending)",
  pendingCount: "Pending items",
  "completedCount.descending": "Completed items (descending)",
  completedCount: "Completed items",
  "exceptionCount.descending": "Exception items (descending)",
  exceptionCount: "Exception items",
  "productivity.descending": "Productivity (descending)",
  "occupancy.ascending": "Occupancy ",
  "occupancy.descending": "Occupancy (descending)",
  "processRestriction.priority": "Priority",
  "processRestriction.priority.descending": "Priority (descending)",
  "average.FTEs.saved": "Average time saved",
  "success.rate": "Success Rate",
  "average.success.rate": "Average Success Rate",
  transactions: "Transactions",
  "total.transactions": "Total Transactions",
  "average.transactions": "Average Transactions",
  min: "min",
  week: "week",
  "newDashboard.AllStats.title": "All Time Stats",
  "newDashboard.CurrentStats.title": "Current Stats ",
  "transactions.header.tooltip": "Total number of transactions",
  "successRate.header.tooltip": "All time percentage of successful transactions",
  "bots.header.tooltip": "Total working time of bots",
  "FTESaved.header.tooltip": "Total FTEs saved",
  "transactions.header.label": "All Transactions",
  "items.header.label": "Items",
  "successRate.header.label": "Success Rate",
  "transactions.cardHeader": "Transactions",
  "AvgHandlingTime.cardHeader": "Average Handling Time",
  "inProgress.transactions.status": "In progress",
  "completed.transactions.status": "Completed",
  "all.processes": "Master Processes",
  "working.processes.status": "Running as per schedules",
  "notWorking.processes.status": "Schedules with missing executions",
  "Processes.activity.tooltip": "This KPI tracks the number of processes that are on schedule and being executed as planned versus the number of processes that are scheduled but not proceeding according to the planned timeline.",
  "til.now": "Until Now",
  "Monitoring Dashboard": "Monitoring dashboard",
  "System exceptions": "System exceptions",
  "Business exceptions": "Business exceptions",
  "reporting.exportCSV": "Export as CSV",
  "reporting.exportCSV.all": "Export all pages as CSV",
  "alerts.management.smsStatus.label": "SMS",
  "alerts.management.emailStatus.label": "Email",
};
