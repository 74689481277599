import React from "react";
import { useFormContext } from "react-hook-form";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import CustomSwitch from "../../../../Services/components/CustomSwitch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { CalendarToday } from "@material-ui/icons";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { useTranslation } from "react-i18next";
import DateFnsUtils from "@date-io/date-fns";
import useStyles from "../style.js";
import {
  REGEX,
  getLocale,
  isValidSiren,
  isValidSiret,
  formatDatePickerByLanguage,
} from "../../../../../util";
import ConfirmMessage from "../../../../../components/ConfirmMessage";
import { useQuery } from "@redux-requests/react";
import { FETCH_CURRENT_USER } from "../../../../../redux/constants";
import { useParams } from "react-router-dom";

const FleetInfo = ({ disableFields, today }) => {
  const currentUser = useQuery({ type: FETCH_CURRENT_USER }).data;
  const {
    watch,
    formState: { errors },
    register,
    setValue,
    getValues,
  } = useFormContext();
  const classes = useStyles();
  const { t } = useTranslation();
  const dateFns = new DateFnsUtils();
  const [openInstanceOwnerConfirm, setOpenInstanceOwnerConfirm] = React.useState(false);
  const { mode = "add" } = useParams();

  const handleInstanceOwnerChange = () => (watch("instanceOwner") ? setValue("instanceOwner", false) : setOpenInstanceOwnerConfirm(true));

  const switchFields = [
    {
      label: "instance.owner",
      fieldName: "instanceOwner",
      disabled: disableFields,
      isAuthorized: mode === "edit" && currentUser?.fleet?.instanceOwner && currentUser?.fleet?.id !== watch("fleetId"),
      onChange: handleInstanceOwnerChange
    },
  ]

  const confirmInstanceOwnerConfirm = () => {
    setValue("instanceOwner", true)
    setOpenInstanceOwnerConfirm(false)
  };

  const cancelInstanceOwnerConfirm = () => {
    setValue("instanceOwner", false)
    setOpenInstanceOwnerConfirm(false)
  };

  return (
    <>
      <Grid item xs={12} container className={classes.formRow}>
        <Grid item xs={6} className={classes.inputsSection}>
          <TextField
            {...register("companyName", {
              required: {
                value: true,
                message: t("fleet.management.formControl.required"),
              },
            })}
            id="companyName"
            label={`${t("fleet.management.formLabel.companyName")} *`}
            disabled={disableFields}
            fullWidth
            InputLabelProps={{
              shrink: !!watch("companyName"),
            }}
            error={!!errors.companyName?.message}
          />
          <p className={classes.validation_error}>
            {errors.companyName?.message}
          </p>
        </Grid>
        {
          process.env.REACT_APP_HIDE_FIELDS_FROM_FLEET === "false"
            && (
            <Grid item xs={6} className={classes.inputsSection}>
              <TextField
                {...register("siret", { validate: (value) => isValidSiret(value) })}
                id="siret"
                label={t("fleet.management.formLabel.siret")}
                disabled={disableFields}
                InputLabelProps={{
                  shrink: !!watch("siret"),
                }}
                fullWidth
                error={!!errors.siret?.message}
              />
              <p className={classes.validation_error}>
                {errors.siret
                  ? t("fleet.management.formControl.siret.notValid")
                  : null}
              </p>
            </Grid>
            )
          }
      </Grid>
      {
        process.env.REACT_APP_HIDE_FIELDS_FROM_FLEET === "false"
        && (
        <Grid item xs={12} container className={classes.formRow}>
          <Grid item xs={6} className={classes.inputsSection}>
            <TextField
              {...register("siren", { validate: (value) => isValidSiren(value) })}
              id="siren"
              label={t("fleet.management.formLabel.siren")}
              disabled={disableFields}
              fullWidth
              InputLabelProps={{
                shrink: !!watch("siren"),
              }}
              error={!!errors.siren?.message}
            />
            <p className={classes.validation_error}>
              {errors.siren
                ? t("fleet.management.formControl.siren.notValid")
                : null}
            </p>
          </Grid>
          <Grid item xs={6} className={classes.inputsSection}>
            <TextField
              {...register("vatNumber", {
                required: {
                  value: true,
                  message: t("fleet.management.formControl.required"),
                },
                pattern: {
                  value: REGEX.ALPHANUM,
                  message: t("fleet.management.formControl.number"),
                },
              })}
              id="vatNumber"
              label={`${t("fleet.management.formLabel.vatNumber")} *`}
              disabled={disableFields}
              fullWidth
              InputLabelProps={{
                shrink: !!watch("vatNumber"),
              }}
              error={!!errors.vatNumber?.message}
            />

            <p className={classes.validation_error}>
              {errors.vatNumber?.message}
            </p>
          </Grid>
        </Grid>
        )
      }
      <Grid item xs={12} container className={classes.formRow}>
        <Grid item xs={6} className={classes.inputsSection}>
          <TextField
            {...register("phoneNumber", {
              pattern: {
                value: REGEX.TEL,
                message: t("fleet.management.formControl.phone"),
              },
            })}
            id="phoneNumber"
            label={t("fleet.management.formLabel.phoneNumber")}
            disabled={disableFields}
            fullWidth
            InputLabelProps={{
              shrink: !!watch("phoneNumber"),
            }}
            error={!!errors.phoneNumber?.message}
          />

          <p className={classes.validation_error}>
            {errors.phoneNumber?.message}
          </p>
        </Grid>
        <Grid item xs={6} className={classes.inputsSection}>
          <TextField
            {...register("email", {
              required: {
                value: true,
                message: t("fleet.management.formControl.required"),
              },
              pattern: {
                value: REGEX.EMAIL,
                message: t("fleet.management.formControl.email"),
              },
            })}
            id="email"
            label={`${t("fleet.management.formLabel.email")} *`}
            disabled={disableFields}
            fullWidth
            InputLabelProps={{
              shrink: !!watch("email"),
            }}
            error={!!errors.email?.message}
          />

          <p className={classes.validation_error}>{errors.email?.message}</p>
        </Grid>
      </Grid>
      <Grid item xs={12} container className={classes.formRow}>
        {
            process.env.REACT_APP_HIDE_FIELDS_FROM_FLEET === "false"
            && (
            <>
              <Grid item xs={6} className={classes.inputsSection}>
                <TextField
                  {...register("address", {
                    required: {
                      value: true,
                      message: t("fleet.management.formControl.required"),
                    },
                  })}
                  id="address"
                  label={`${t("fleet.management.formLabel.address")} *`}
                  disabled={disableFields}
                  fullWidth
                  InputLabelProps={{
                    shrink: !!watch("address"),
                  }}
                  error={!!errors.address?.message}
                />

                <p className={classes.validation_error}>{errors.address?.message}</p>
              </Grid>
              <Grid item xs={6} className={classes.inputsSection}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={getLocale()}>
                  <DatePicker
                    InputProps={{
                      endAdornment: <CalendarToday />,
                    }}
                    {...register("clientSince", {
                      validate: (value) => dateFns.isValid(value),
                    })}
                    onChange={(date) => {
                      setValue("clientSince", date, {
                        shouldValidate: true,
                        shouldDirty: true,
                      });
                    }}
                    defaultValue={today}
                    value={getValues("clientSince")}
                    label={`${t("fleet.management.formLabel.clientSince")} *`}
                    autoOk
                    showTodayButton
                    format={formatDatePickerByLanguage(false)}
                    disabled={disableFields}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    fullWidth
                    invalidDateMessage=""
                    invalidLabel=""
                    error={!!errors.clientSince?.message}
                    cancelLabel={t("user.button.cancel")}
                    todayLabel={t("Today")}
                  />
                </MuiPickersUtilsProvider>
                <p className={classes.validation_error}>
                  {errors.clientSince
                    ? t("fleet.management.formControl.invalidDate")
                    : null}
                </p>
              </Grid>
            </>
          )
        }
        {!process.env.REACT_APP_DISABLE_PARTITIONING && (
        <Grid item xs={6} className={classes.inputsSection}>
          {switchFields.map((field, i) => (field.isAuthorized ? (
            <Grid alignItems="center" key={`${field.fieldName}-${i}`}>
              <Typography variant="h3">
                <FormControlLabel
                      control={(
                        <CustomSwitch
                              key={watch(field.fieldName)}
                              {...register(field.fieldName)}
                              checked={watch(field.fieldName)}
                              disabled={field.disabled}
                              handleChange={field.onChange}
                          />
                      )}
                      label={t(field.label)}
                  />
              </Typography>
            </Grid>
          ) : null))}
        </Grid>
        )}
      </Grid>
      {openInstanceOwnerConfirm && (
      <ConfirmMessage
          message={t("instance.owner.confirmMsg").split("/n").map((line) => <Typography component="h6">{line}</Typography>)}
              openStart={openInstanceOwnerConfirm}
              onCancel={cancelInstanceOwnerConfirm}
              onConfirm={confirmInstanceOwnerConfirm}
              buttonConfirm={t("confirm")}
              buttonCancel={t("Cancel")}
          />
      )}
    </>
  );
};

export default FleetInfo;
