import appSlice from "../WrapperContainer/appSlice";
import filterListAlert from "./slices/alertFilterSlice";
import filterListProcess from "./slices/processSlice";
import filterListException from "./slices/exceptionFilter";
import filterDashboard from "./slices/dashboardFilterSlice";
import dashboardFilters from "./slices/dashboardSlice";
import pendingItemsFilter from "./slices/pendingItemsFilter";
import executionManagerSlice from "./slices/executionManagerSlice";
import executionsSlice from "./slices/executionsSlice"

const rootReducer = {
  appState: appSlice,
  filterProcess: filterListProcess,
  filterListAlert,
  filterListException,
  filterDashboard,
  dashboardFilters,
  pendingItemsFilter,
  executionManager: executionManagerSlice,
  executionsFilter: executionsSlice
};

export default rootReducer;
