import React, { useEffect, useState } from "react";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Grid,
  Typography,
  FormGroup,
} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { isEmpty } from "lodash";
import clsx from "clsx";
import PageHeader from "../../components/PageHeader/index.js";
import Filter from "./components/Filter";
import AnalyticsNotFound from "../../assets/Analytics.svg";
import {
  fetchAnalysisData,
  fetchAnalysisTableData,
  fetchAnalyticsPresets,
  deleteAnalyticsPreset,
  createAnalyticsPreset,
  fetchCustomKpis,
  createOrUpdateCustomKpi,
  deleteCustomKpi,
  fetchReducedFleet,
  fetchFilterProcesses,
  exportAnalysisTableData,
} from "../../redux/actions/services/index.js";
import BarChart from "./components/Chart/BarChart";
import PieChart from "./components/Chart/PieChart";
import LineChart from "./components/Chart/LineChart";
// eslint-disable-next-line import/no-cycle
import TableChart from "./components/Chart/TableChart/index.js";
import {
  accumulate,
  formatDatePickerByLanguage,
  formtNumberByLanguage,
} from "util";
import moment from "moment";
import DataNotFound from "../../components/DataNotFound";
import CircularLoader from "../../components/Loaders/CircularLoader";
import { useQuery } from "@redux-requests/react";
import { FETCH_CURRENT_USER } from "redux/constants/index.js";
import CustomSwitch from "../Services/components/CustomSwitch";
import { ANALYTICS_TABLE_ITEMS_GAP } from "./components/Chart/TableChart/style";
import CustomPagination from "pages/Services/components/CustomPagination";
import useStyles from "./style.js";
import "./style.css";

const { format } = require("date-fns-tz");

const Reporting = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const width = "500px";
  const height = "400px";

  const [processes, setProcesses] = useState([]);
  const [isloading, setIsloading] = useState(false);
  const [data, setData] = useState([]);
  const [selectedReportType, setSelectedReportType] = useState(null);
  const [selectedProcess, setSelectedProcess] = React.useState([]);
  const [selectedKpi, setSelectedKpi] = React.useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isCumulativeResult, setIsCumulativeResult] = React.useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const [fromSelectedDate, setFromDate] = React.useState(new Date());
  const [toSelectedDate, setToDate] = React.useState(new Date());
  const [filterPresets, setFilterPresets] = React.useState([]);
  const [customKpis, setCustomKpis] = React.useState([]);
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: "selection",
    },
  ]);
  const [validReportTypes, setValidReportTypes] = React.useState([]);
  const [selecteCustomKpi, setSelecteCustomKpi] = React.useState([]);
  const [selectedTags, setSelectedTags] = React.useState([]);
  const [userFleet, setUserFleet] = React.useState();
  const currentUser = useQuery({ type: FETCH_CURRENT_USER })?.data;
  const [dateContainerWidth, setDateContainerWidth] = useState(260)
  const [count, setCount] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(8);
  const [page, setPage] = React.useState(0);

  const handleExportLoading = () => {
    setTimeout(() => {
      // add loader animation
      setExportLoading(false);
    }, 400);
  };

  useEffect(() => {
    dispatch(fetchFilterProcesses()).then((res) => {
      if (res?.status === 200) setProcesses(res.data?.list);
    });
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchAnalyticsPresets((res) => setFilterPresets(res.data?.list)));
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchReducedFleet(currentUser?.fleet?.id)).then((res) => {
      setUserFleet(res.data);
    })
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchCustomKpis((res) => {
      setCustomKpis(res.data.map((kpi) => ({ ...kpi, type: "count" })));
    }));
  }, [dispatch]);

  const download = (canvas, filename) => {
    const a = document.createElement("a");
    a.download = filename;
    a.href = canvas.toDataURL("image/png;base64");
    a.style.display = "none";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const getCanvasElement = () => document.getElementById("chart_canvas");

  const SaveCanvas = () => {
    setExportLoading(true);
    const element = getCanvasElement();
    html2canvas(element, { scrollY: -window.scrollY, scale: 2 }).then(
      (canvas) => {
        download(canvas, `SR_Analytics_${new Date().getTime()}`);
        handleExportLoading();
      },
    );
    setAnchorEl(null);
  };

  const copyCanvasToClipBoard = () => {
    const element = getCanvasElement();
    try {
      html2canvas(element, { scrollY: -window.scrollY, scale: 2 }).then(
        (canvas) => {
          canvas.toBlob((blob) => {
            navigator.clipboard.write([
              // eslint-disable-next-line no-undef
              new ClipboardItem(
                Object.defineProperty({}, blob.type, {
                  value: blob,
                  enumerable: true,
                }),
              ),
            ]);
          });
        },
      );
    } catch (e) { /* empty */ }
    setAnchorEl(null);
  };

  const pixelsToMM = (element) => {
    const width = parseInt(window.getComputedStyle(element, null).width);
    const height = parseInt(window.getComputedStyle(element, null).height);
    const converted = {};
    converted.width = Math.floor(width * 0.264583);
    converted.height = Math.floor(height * 0.264583);

    return converted;
  };

  const handleExportPDF = () => {
    setExportLoading(true);
    const element = getCanvasElement();
    // eslint-disable-next-line no-undef
    html2canvas(element, { scrollY: -window.scrollY, scale: 2 }).then(
      (canvas) => {
        const dim = pixelsToMM(element);
        let { width } = dim;
        let { height } = dim;
        const ratio = height / width;
        const max = { height: 210, width: 300 };

        const dataURL = canvas.toDataURL();
        const pdf = new jsPDF({
          orientation: "landscape",
          format: "a4",
          unit: "mm",
        });
        if (height > max.height || width > max.width) {
          if (height > width) {
            height = max.height;
            width = height * (1 / ratio);
            // Making reciprocal of ratio because ration of height as width is no valid here needs width as height
          } else if (width > height) {
            width = max.width;
            height = width * ratio;
            // Ratio is valid here
          }
        }
        pdf.addImage(dataURL, "JPEG", 10, 10, width - 20, height); // -20 for the margin left and right
        pdf.save(`SR_Analytics_${new Date().getTime()}.pdf`);
        handleExportLoading();
      },
    );
    setAnchorEl(null);
  };

  const handleExportCsv = (exportAll = false) => {
    setExportLoading(true);
    setAnchorEl(null);
    const customKpiIds = selectedKpi.filter((kpi) => kpi?.customKpiDetails).map((kpi) => kpi.id);
    const selectedKpiCodes = selectedKpi.filter((kpi) => !kpi?.customKpiDetails).map((kpi) => kpi.code);
    dispatch(
      exportAnalysisTableData(
        selectedProcess,
        fromSelectedDate,
        toSelectedDate,
        selectedKpiCodes,
        customKpiIds,
        selectedTags,
        page,
        rowsPerPage,
        exportAll
      ),
    ).then(() => {
      handleExportLoading();
    });
  }

  const handleClearCanvas = () => {
    setData([]);
    setSelectedKpi([]);
    setSelectedProcess([]);
    setSelectedReportType(null);
    setSelectedTags([]);
    setAnchorEl(null);

    const today = new Date();
    setToDate(today);
    setFromDate(today);
  };

  const optionsChart = {
    responsive: true,
    maintainAspectRatio: false,
    aspectRatio: 1,
    layout: {
      padding: {
        right: 30,
        left: 30,
      },
    },
    legend: { display: true },
    elements: {
      line: {
        fill: false,
      },
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            drawOnChartArea: false,
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            drawOnChartArea: false,
          },
        },
      ],
    },
    tooltips: {
      callbacks: {
        label(tooltipItem, data) {
          return formatAnalyticsGraphNumbers(tooltipItem, data);
        },
      },
    },
  };
  const formatAnalyticsGraphNumbers = (tooltipItem, data) => `${data.labels[tooltipItem.index]}: ${formtNumberByLanguage(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index])}`;
  const handleGenerateReport = () => {
    setIsloading(true);
    const customKpiIds = selectedKpi.filter((kpi) => kpi?.customKpiDetails).map((kpi) => kpi.id);
    const selectedKpiCodes = selectedKpi.filter((kpi) => !kpi?.customKpiDetails).map((kpi) => kpi.code);
    dispatch(
      fetchAnalysisData(
        selectedProcess,
        fromSelectedDate,
        toSelectedDate,
        selectedKpi && selectedKpiCodes,
        customKpiIds,
        selectedTags
      ),
    ).then((res) => {
      if (res.status === 200) {
        setData(res.data);
        setIsloading(false);
      }
    });
    const id = "reporting-container";
    const yOffset = -50;
    const element = document.getElementById(id);
    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

    window.scrollTo({ top: y, behavior: "smooth" });
  };

  const handleGenerateTable = () => {
    setIsloading(true);
    const customKpiIds = selectedKpi.filter((kpi) => kpi?.customKpiDetails).map((kpi) => kpi.id);
    const selectedKpiCodes = selectedKpi.filter((kpi) => !kpi?.customKpiDetails).map((kpi) => kpi.code);
    dispatch(
      fetchAnalysisTableData(
        selectedProcess,
        fromSelectedDate,
        toSelectedDate,
        selectedKpiCodes,
        customKpiIds,
        selectedTags,
        page,
        rowsPerPage,
      ),
    ).then((res) => {
      if (res.status === 200) {
        setData(res.data);
        setIsloading(false);
      }
    });
  };

  const setElementTitle = ((element) => {
    if (element?.title?.includes(" : ")) {
      const title = element.title.split(" : ");
      title[2] ? element.title = `${title[0]} - ${t(`custom.kpi.${title[1]}`)} ${t(`custom.kpi.${title[2]}`)}`
      : element.title = `${title[0]} - ${t(`custom.kpi.${title[1]}`)}`;
    }
  });

  const generateBarChartData = (input) => {
    if (isEmpty(input)) {
      return {};
    }

    // init variables
    let labels = null;
    let datasets = [];

    input.forEach((element) => {
      if (element) {
        setElementTitle(element);
        // get graph labels
        if (!labels) {
          labels = element.labels;
        }
        // get graph data
        const datasetElement = {
          label: t(element.title),
          title: element.title,
          data: element.values,
        };
        datasets.push(datasetElement);
      }
    });

    datasets.sort((a, b) => a.title?.localeCompare(b.title));

    datasets = datasets.map((e, index) => ({
      ...e,
      backgroundColor: colorsList[index],
      borderColor: colorsList[index],
    }));

    let res = [...datasets];
    if (isCumulativeResult) {
      res = datasets.map((el) => ({ ...el, data: accumulate(el.data) }));
    }
    return { labels, datasets: res };
  };

  const generatePieChartData = (input) => {
    if (isEmpty(input)) {
      return {};
    }
    const labels = [];
    const data = [];
    const tempArr = [];
    input.forEach((element) => {
      if (element) {
        setElementTitle(element);
        tempArr.push({
          label: t(element.title),
          title: element.title,
          data: element.countWQ,
        });
      }
    });

    tempArr.sort((a, b) => a.title?.localeCompare(b.title));

    tempArr.forEach((el) => {
      labels.push(el.label);
      data.push(el.data);
    });

    const datasets = [{ data, backgroundColor: colorsList }];
    return { labels, datasets };
  };

  const generateTableData = (input) => {
    const res = [];
    if (!isEmpty(input)) {
      input.forEach((element) => {
        if (element) {
          res.push(element);
        }
      });
    }
    return res;
  };

  const getReport = () => {
    if (selectedReportType) {
      const tempOptionsChart = { ...optionsChart }
      if (selectedKpi.some((kpi) => kpi.type === "percentage")) {
        tempOptionsChart.tooltips = {
          callbacks: {
            label(tooltipItem, data) {
              return `${formatAnalyticsGraphNumbers(tooltipItem, data)} %`;
            },
          },
        };
      }

      if (selectedReportType.code === "lineChart") {
        const graphData = generateBarChartData(data);
        return (
          <LineChart
            data={graphData}
            optionsChart={tempOptionsChart}
            width={width}
            height={height}
          />
        );
      }
      if (selectedReportType.code === "barChart") {
        const graphData = generateBarChartData(data);
        return (
          <BarChart
            data={graphData}
            optionsChart={tempOptionsChart}
            width={width}
            height={height}
          />
        );
      }
      if (selectedReportType.code === "pieChart") {
        const graphData = generatePieChartData(data);
        if (!graphData.labels?.length) return null;
        return (
          <PieChart
            data={graphData}
            optionsChart={tempOptionsChart}
            width={width}
            height={height}
          />
        );
      }
      if (selectedReportType.code === "table") {
        const graphData = generateTableData(data);
        if (!graphData || graphData.length === 0) return null;
        return (
          <>
            <Box display="grid" gridTemplateColumns={`repeat(2, ${dateContainerWidth}px)`} justifyContent="center" mt="40px" mb="25px" gridColumnGap={`${ANALYTICS_TABLE_ITEMS_GAP}px`}>
              <Box className={classes.dateContainer}>
                <Typography component="span" className={classes.dateLabel}>
                  {t("from")}
                </Typography>
                <Typography component="span" className={classes.dateValue}>
                  {moment(fromSelectedDate).format("MM/DD/YYYY HH:mm:ss")}
                </Typography>
              </Box>
              <Box className={classes.dateContainer}>
                <Typography component="span" className={classes.dateLabel}>
                  {t("to")}
                </Typography>
                <Typography component="span" className={classes.dateValue}>
                  {moment(toSelectedDate).format("MM/DD/YYYY HH:mm:ss")}
                </Typography>
              </Box>
            </Box>
            <TableChart
                  selectedKpi={selectedKpi}
                  data={graphData}
                  classes={classes}
                  t={t}
                  customKpis={customKpis}
                  userFleet={userFleet}
                  setDateContainerWidth={setDateContainerWidth}
              />
            <CustomPagination
                rowsPerPageOptions={[8]}
                count={count ?? 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                onNext={handleNext}
                onPrevious={handlePrevious}
            />
          </>
        );
      }
    }
    return null;
  };

  const getTitle = () => {
    const kpiLabels = [];
    const title = [];
    const tempSelectedKpi = selectedKpi;

    tempSelectedKpi.sort((a, b) => (a.label > b.label ? 1 : -1));
    const tempCustomKpiDetails = tempSelectedKpi.map((kpi) => {
      const customKpiDetails = kpi?.customKpiDetails || []; // Ensure customKpiDetails is an array
      return customKpiDetails.map((detail) => ({
          ...detail,
          originalKpiName: kpi?.name
        }));
    }).flat();
    let colorIndex = 0;

    if (data && Object.keys(data)?.length > 0) {
      tempSelectedKpi.forEach((kpiEl, i) => {
        if (kpiEl) {
          kpiLabels[i] = t(kpiEl.label) || kpiEl?.name;
        }
      });
      kpiLabels.sort((a, b) => a.localeCompare(b));
      kpiLabels.forEach((kpiEl, i) => {
        title[i] = (
          <>
            <Typography
              component="span"
              style={{ color: colorsList[i + colorIndex], fontWeight: 800 }}
            >
              {kpiEl}
            </Typography>
            {tempCustomKpiDetails.filter((detail) => detail.originalKpiName === kpiEl)
            .sort((a, b) => (`${a.element}${a.detail}`).localeCompare(`${b.element}${b.detail}`))
            .map((detail, detailIndex) => {
              const filteredDetails = tempCustomKpiDetails.filter((detail) => detail.originalKpiName === kpiEl);
              const prefix = detailIndex === 0 ? " ( " : "";
              const suffix = detailIndex === filteredDetails.length - 1 ? " ) " : " & ";
              const customDetail = (
                <Typography
                component="span"
                style={{ color: colorsList[i + colorIndex], fontWeight: 800 }}
                >
                  {`${prefix}${t(`custom.kpi.${detail.element}`)} ${t(`custom.kpi.${detail.detail}`)}${suffix}`}
                </Typography>
              )
              if (detailIndex !== filteredDetails.length - 1)colorIndex += 1;
              return customDetail;
            })}
            {i !== tempSelectedKpi.length - 1 && (
              <Typography component="span">
                {" "}
                {t("reporting.and")}
                {" "}
              </Typography>
            )}
          </>
        );
      });
      title.push(
        <Typography component="span">
          {" "}
          {t("for")}
        </Typography>
      );
      selectedProcess.forEach((e, i) => {
        title.push(
          <>
            <Typography component="span" style={{ fontWeight: 800 }}>
              &nbsp;
              {e.processName}
            </Typography>
            {i !== selectedProcess.length - 1 && (
              <Typography component="span">
                {" "}
                {t("reporting.and")}
                {" "}
              </Typography>
            )}
          </>,
        );
      });
      const dates = (
        <Typography component="span">
          &nbsp;
          {t("reporting.reportTitle", {
            from: format(fromSelectedDate, formatDatePickerByLanguage(false)),
            to: format(toSelectedDate, formatDatePickerByLanguage(false)),
          })}
        </Typography>
      );
      title.push(dates);
    }

    return title;
  };

  useEffect(() => {
    const types = [...selectedKpi]?.map((e) => e.type);
    const set = new Set(types);
    const validReports = set.size === 0 || (set.size === 1 && !set.has("date"))
      ? reportTypes
      : reportTypes.filter((e) => e.code === "table");
    setValidReportTypes(validReports);
    let localSelectedReportType = selectedReportType;
    if (
      validReports.length > 0
      && selectedReportType
      && validReports.filter((report) => report.code === selectedReportType.code)
        .length === 0
    ) {
      // eslint-disable-next-line prefer-destructuring
      localSelectedReportType = validReports[0];
      setSelectedReportType(validReports[0]);
    }
    if (!isEmpty(localSelectedReportType)) {
      if (localSelectedReportType.code === "table") {
        handleGenerateTable();
      } else {
        handleGenerateReport();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedProcess,
    selectedReportType,
    selectedKpi,
    fromSelectedDate,
    toSelectedDate,
    isCumulativeResult,
    page,
  ]);

  const handleCumulChange = (e) => {
    setIsCumulativeResult(e.target.checked);
  };

  const getUpdatedPresets = () => {
    dispatch(fetchAnalyticsPresets((res) => {
      setFilterPresets(res.data?.list);
    }));
  };

  const handleDeletePreset = (presetId) => {
    if (presetId) {
      dispatch(deleteAnalyticsPreset(presetId, () => getUpdatedPresets()));
    }
  };

  const handleCreatePreset = (presetName) => {
    const presetData = {
      presetName,
      selectedProcesses: selectedProcess.map((process) => process?.id).toString(),
      kpi: selectedKpi.map((kpi) => kpi.id).toString(),
      type: selectedReportType?.id,
      fromTime: fromSelectedDate.toISOString(),
      toTime: toSelectedDate.toISOString(),
    };
    dispatch(createAnalyticsPreset(presetData, () => getUpdatedPresets()));
  };

  const getUpdatedKpis = () => {
    dispatch(fetchCustomKpis((res) => {
      const updatedCustomKpis = res.data.map((kpi) => ({ ...kpi, type: "count" }));
      const mergedSelectedKpi = selectedKpi.map((selected) => updatedCustomKpis.find((updatedKpi) => updatedKpi.id === selected.id) || selected)
      .filter((kpi) => !kpi.customKpiDetails || updatedCustomKpis.find((updatedKpi) => updatedKpi.id === kpi.id));
      setCustomKpis(updatedCustomKpis);
      setSelectedKpi(mergedSelectedKpi);
    }));
  };

  const handleCreateOrUpdateCustomKpi = (kpiData) => {
    dispatch(createOrUpdateCustomKpi(kpiData, () => getUpdatedKpis()));
  };

  const handleDeleteCustomKpi = (kpiId) => {
    dispatch(deleteCustomKpi(kpiId, () => getUpdatedKpis()));
  };
  const isTableSelected = selectedReportType?.code === "table"

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  };

  const handleNext = (page) => {
    setPage(page + 1)
  }
  const handlePrevious = (page) => {
    setPage(page - 1)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  useEffect(() => {
    setCount(selectedTags.length + selectedProcess.length)
  }, [selectedProcess, selectedTags]);

  return (
    <Grid
      container
      spacing={4}
      alignItems="stretch"
      xs={12}
      className={classes.rootGlobal}
    >
      <PageHeader title={t("routes.orchestrators.analytics")} />
      <Grid
        container
        item
        xs={12}
        direction="column"
        justify="space-between"
        id="reporting-container"
        style={{ maxWidth: "90vw" }}
      >
        <Grid container xs item id="filter-container">
          <Filter
            processes={processes}
            reportTypes={reportTypes}
            selectedReportType={selectedReportType}
            setSelectedReportType={setSelectedReportType}
            selectedProcess={selectedProcess}
            setSelectedProcess={setSelectedProcess}
            selectedKpi={selectedKpi}
            setSelectedKpi={setSelectedKpi}
            setDateRange={setDateRange}
            setAnchorEl={setAnchorEl}
            anchorEl={anchorEl}
            dateRange={dateRange}
            kpi={kpi}
            handleSaveReport={SaveCanvas}
            handleCopyToClipboard={copyCanvasToClipBoard}
            handleExportPdf={handleExportPDF}
            handleExportCsv={handleExportCsv}
            handleClear={handleClearCanvas}
            fromSelectedDate={fromSelectedDate}
            setFromDate={setFromDate}
            toSelectedDate={toSelectedDate}
            setToDate={setToDate}
            exportLoading={exportLoading}
            validReportTypes={validReportTypes}
            filterPresets={filterPresets}
            handleDeletePreset={handleDeletePreset}
            handleCreatePreset={handleCreatePreset}
            customKpis={customKpis}
            handleCreateOrUpdateCustomKpi={handleCreateOrUpdateCustomKpi}
            handleDeleteCustomKpi={handleDeleteCustomKpi}
            selectedCustomKpi={selecteCustomKpi}
            setSelecteCustomKpi={setSelecteCustomKpi}
            selectedTags={selectedTags}
            setSelectedTags={setSelectedTags}
          />
        </Grid>
        {isloading && selectedReportType ? (
          <Grid
            container
            item
            className={classes.circularLoaderContainer}
            justify="center"
            alignItems="center"
          >
            <CircularLoader />
          </Grid>
        ) : (
          selectedKpi.length > 0 && (
          <Grid item xs>
            <div id="chart_canvas">
              { (!isTableSelected && selectedReportType?.code) && (
              <Grid
                        container
                        item
                        direction="row"
                        xs={12}
                        className={clsx(
                            classes.reportContainer,
                            isEmpty(data) && classes.hideContainer,
                            isTableSelected ? classes.reportTableContainer : ""
                        )}
                    >
                <Grid
                          container
                          item
                          xs={12}
                          className={classes.graphTitle}
                          justify="space-between"
                      >
                  {["lineChart", "barChart"].includes(
                            selectedReportType?.code,
                        ) && (
                        <Grid
                                container
                                item
                                xs={12}
                                justify="flex-end"
                                style={{ marginBottom: 10 }}
                            >
                          <FormGroup>
                            <CustomSwitch
                                    label={t("reporting.cumul")}
                                    handleChange={handleCumulChange}
                                    checked={isCumulativeResult}
                                />
                          </FormGroup>
                        </Grid>
                        )}
                  <Grid container item xs={12}>
                    <Typography>{getTitle()}</Typography>
                  </Grid>
                  {!isTableSelected && (
                  <Grid item xs={12}>
                    {getReport()}
                  </Grid>
                  )}
                </Grid>
              </Grid>
                    )}
              {isTableSelected ? getReport() : null}
            </div>
          </Grid>

          )
        )}

        <Grid item xs style={{ marginTop: 30 }}>
          {((!isloading && data.length === 0) || !selectedReportType?.code) && (
            <DataNotFound
              message={t("no.analytics.message")}
              icon={AnalyticsNotFound}
            />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

const reportTypes = [
  { id: 0, code: "table", label: "reporting.table" },
  { id: 1, code: "lineChart", label: "reporting.lineChart" },
  { id: 2, code: "pieChart", label: "reporting.pieChart" },
  { id: 3, code: "barChart", label: "reporting.barChar" },
  // { id: 4, code: "columnChart", label: "reporting.columnChart" },
];

export const kpi = [
  {
    id: 0,
    code: "processExecutions",
    label: "reporting.processExecutions",
    type: "count",
  },
  {
    id: 1, code: "itemsProcessed", label: "reporting.itemsProcessed", type: "count",
  },
  {
    id: 2,
    code: "itemsCompleted",
    label: "reporting.itemsCompleted",
    type: "count",
  },
  {
    id: 3,
    code: "itemsBusinessExceptions",
    label: "reporting.itemsBusinessExceptions",
    type: "count",
  },
  {
    id: 4,
    code: "itemsSystemExceptions",
    label: "reporting.itemsSystemExceptions",
    type: "count",
  },
  {
    id: 5, code: "timeSaved", label: "reporting.timeSaved", type: "time",
  },
  {
    id: 6,
    code: "executionTime",
    label: "reporting.executionTime",
    type: "time",
  },
  {
    id: 11,
    code: "itemExecutionTime",
    label: "reporting.itemExecutionTime",
    type: "time",
  },
  {
    id: 9, code: "successRate", label: "reporting.successRate", type: "rate",
  },
  {
    id: 10,
    code: "exceptionRate",
    label: "reporting.exceptionRate",
    type: "rate",
  },
];

const colorsList = [
  "#303167",
  "#FF3F3F",
  "#FFB300",
  "#733999",
  "#28AC47",
  "#2EA8C0",
  "#29DCA7",
  "#FF0505",
  "#FFAC36",
  "#29556C",
  "#F65353",
];

export default Reporting;
