import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import HandlingTime from "./HandlingTime";
import ProcessActivity from "./ProcessActivity";
import RobotProductivity from "./RobotProductivity";
import TimeSavedChart from "./TimeSavedChart";
import DashboardFilter from "./DashboardFilter";
import DashboardStates from "./DashboardStates";
import useStyles from "./style";
import { useDispatch } from "react-redux";
import { fetchReducedFleet } from "redux/actions/services";
import { FETCH_CURRENT_USER } from "redux/constants";
import { useQuery } from "@redux-requests/react";

export default function Dashboard() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [userFleet, setUserFleet] = React.useState();
  const currentUser = useQuery({ type: FETCH_CURRENT_USER })?.data;
  useEffect(() => {
    dispatch(fetchReducedFleet(currentUser?.fleet?.id)).then((res) => {
      setUserFleet(res.data);
    })
  }, [dispatch]);

  return (
    <Grid container justify="space-between" className={classes.rootContainer}>
      <Box component={Grid} display="grid" gridTemplateRows="auto auto 1fr" gridTemplateColumns="repeat(1fr)" item xs={9}>
        <Box component={Grid} item container spacing={3} xs={12} mt={1} mb={1}>
          <DashboardFilter />
        </Box>
        <DashboardStates xs={12} />
        <TimeSavedChart xs={12} userFleet={userFleet} />
      </Box>
      <RobotProductivity xs={3} userFleet={userFleet} />
      <ProcessActivity xs={9} userFleet={userFleet} />
      <HandlingTime xs={3} />
    </Grid>
  );
}
