import { createSlice } from "@reduxjs/toolkit";
import { CriteriaTypes, TimeGranularity, TimeSpentCriteria } from "util/constants";
import { getDefaultFormat } from "../../util";

const buildRouteName = (str) => str.replace(/\s/g, "").toLowerCase();

const initialState = {
  mode: "create",
  steps: [],
  qualifications: [],
  isQualificationLoaded: false,
  isLoading: false,
  activeStep: 0,
  data: [],
  isCalculating: false,
  isSubmitting: false,
  isSaving: false,
  isProcessDataLoading: false,
  processDataLoaded: false,
  result: null,
  process: null,
  isLoadingConfirm: false,
  preventHandleClose: null,
  customHandleClose: () => "",
};

const stepperSlice = createSlice({
  name: "stepper",
  initialState,
  reducers: {
    getStepsData: (state, action) => {
      state.steps = action.payload.steps;
      state.isLoading = false;

      const data = [];

      if (state.mode === "create") {
        // init data
        state.steps.forEach((step) => {
          const formData = {};
          step.criterias.forEach((criteria) => {
            switch (criteria.type) {
              case CriteriaTypes.Date:
                formData[buildRouteName(criteria.name)] = new Date().toJSON();
                break;
              case CriteriaTypes.Boolean:
                formData[buildRouteName(criteria.name)] = false;
                break;
              default:
                formData[buildRouteName(criteria.name)] = "";
                break;
            }
            formData[`${buildRouteName(criteria.name)}-comment`] = "";
          });
          data.push(formData);
        });
      } else {
        state.steps.forEach((step) => {
          const formData = {};
          step.criterias.forEach((criteria) => {
            switch (criteria.type) {
              case CriteriaTypes.Date:
                // eslint-disable-next-line prefer-destructuring
                formData[buildRouteName(criteria.name)] = new Date(
                  state.process.qualifications.find(
                    (q) => q.criteria.id === criteria.id,
                  ).value,
                )
                  .toJSON()
                  .split("T")[0];
                break;
              case CriteriaTypes.Boolean:
                formData[buildRouteName(criteria.name)] = state.process.qualifications.find(
                  (q) => q.criteria.id === criteria.id,
                ).assessment.name === "true";
                break;
              case CriteriaTypes.String:
                if (criteria.assessments.length > 0) {
                  formData[buildRouteName(criteria.name)] = state.process.qualifications.find(
                    (q) => q.criteria.id === criteria.id,
                  ).assessment
                    ? state.process.qualifications.find(
                      (q) => q.criteria.id === criteria.id,
                    ).assessment.id
                    : "";
                } else {
                  formData[buildRouteName(criteria.name)] = state.process.qualifications.find(
                    (q) => q.criteria.id === criteria.id,
                  ).value;
                }
                break;
              default:
                formData[buildRouteName(criteria.name)] = state.process.qualifications.find(
                  (q) => q.criteria.id === criteria.id,
                ).value;
                break;
            }
            formData[`${buildRouteName(criteria.name)}-comment`] = state.process.qualifications.find(
              (q) => q.criteria.id === criteria.id,
            ).comment;
          });
          data.push(formData);
        });
      }
      state.data = data;
    },
    getQualificationData: (state, action) => {
      state.qualifications = action.payload.data;
      state.isQualificationLoaded = true;
    },
    getProcessData: (state, action) => {
      state.processDataLoaded = true;
      state.isProcessDataLoading = false;
      state.process = action.payload.process;

      state.mode = "edit";
    },
    saveStepData: (state, action) => {
      state.data[state.activeStep] = action.payload.data;
    },
    startProcessDataLoading: (state) => {
      state.isProcessDataLoading = true;
    },
    startLoading: (state) => {
      state.isLoading = true;
    },

    nextStep: (state, action) => {
      // save active step data
      state.data[state.activeStep] = action.payload.data;

      // move to next step
      state.activeStep += 1;
    },

    previousStep: (state) => {
      state.activeStep -= 1;
      // For the last step[exept result]
      state.result = null;
    },
    resetStepper: (state) => {
      state.activeStep = 0;
      state.data = [];
      state.steps = [];
      state.isLoading = false;
      state.result = null;
      state.isCalculating = false;
      state.isSaving = false;
      state.isSubmitting = false;
      state.process = null;
      state.isProcessDataLoading = false;
      state.processDataLoaded = false;
      state.mode = "create";
      state.isLoadingConfirm = false;
    },
    resetQualification: (state) => {
      state.qualifications = [];
      state.isQualificationLoaded = false;
    },
    calculateTotalTimeSpent: (state) => {
      const timeSpentOneExcecution = state.data[2][
        buildRouteName(TimeSpentCriteria.TimeSpentForOneExection)
      ];
      const timeGranularityId = state.data[2][buildRouteName(TimeSpentCriteria.TimeGranularity)];
      const occurenceTimeGranularityId = state.data[2][
        buildRouteName(TimeSpentCriteria.OccurenceTimeGranularity)
      ];

      const timeGranularity = state.steps[2].criterias
        .find((criteria) => criteria.name === TimeSpentCriteria.TimeGranularity)
        .assessments.find(
          (assessment) => assessment.id === timeGranularityId,
        ).name;
      // const OccurenceTimeGranularity = state.steps[2].criterias
      //   .find(
      //     (criteria) =>
      //       criteria.name === TimeSpentCriteria.OccurenceTimeGranularity
      //   )
      //   .assessments.find(
      //     (assessment) => assessment.id === occurenceTimeGranularityId
      //   ).name;

      const occurenceTimeGranularity = state?.steps[2]?.criterias && state.steps[2].criterias
        .find(
          (criteria) => criteria?.name === TimeSpentCriteria.OccurenceTimeGranularity,

        )
        .assessments.find(
          (assessment) => assessment.id === Number(occurenceTimeGranularityId),
        )?.name;

      const occurrence = getDefaultFormat(state.data[2][buildRouteName(TimeSpentCriteria.Occurrence)]);
      let totalTimeSpent = 0;
      let frequency = 0;

      switch (occurenceTimeGranularity?.toLowerCase()) {
        case TimeGranularity.Hours:
          frequency = 1760 / occurrence;
          break;
        case TimeGranularity.Days:
          frequency = 220 / occurrence;
          break;
        case TimeGranularity.Weeks:
          frequency = 52 / occurrence;
          break;
        case TimeGranularity.Months:
          frequency = 12 / occurrence;
          break;
        case TimeGranularity.Years:
          frequency = 1 / occurrence;
          break;

        default:
          // Nothing to do :-)
          break;
      }

      switch (timeGranularity.toLowerCase()) {
        case TimeGranularity.Seconds:
          totalTimeSpent = timeSpentOneExcecution * frequency * 0.000277777777777778;
          break;
        case TimeGranularity.Minutes:
          totalTimeSpent = timeSpentOneExcecution * frequency * 0.0166666666666667;
          break;
        case TimeGranularity.Hours:
          totalTimeSpent = timeSpentOneExcecution * frequency * 1;
          break;
        case TimeGranularity.Days:
          totalTimeSpent = timeSpentOneExcecution * frequency * 8;
          break;
        default:
          // Nothing to do :-)
          break;
      }

      state.data[3][
        buildRouteName(TimeSpentCriteria.TotalTimeSpentInHoursPerYear)
      ] = totalTimeSpent;
    },
    startCalculating: (state) => {
      state.isCalculating = true;
    },
    startSubmitting: (state) => {
      state.isSubmitting = true;
      state.isLoadingConfirm = true;
    },
    startSaving: (state) => {
      state.isSaving = true;
    },
    storeCalculationResult: (state, action) => {
      state.result = action.payload.result;
      state.isCalculating = false;
    },
    storePreventHandleClose: (state, action) => {
      state.preventHandleClose = action.payload;
    },
    resetPreventHandleClose: (state) => {
      state.preventHandleClose = null;
    },
    storeCustomHandleClose: (state, action) => {
      state.customHandleClose = action.payload;
    },
    resetCustomHandleClose: (state) => {
      state.customHandleClose = () => "";
    },
  },
});

// Processes reducer
export default stepperSlice.reducer;

// Actions
export const {
  getStepsData,
  startLoading,
  nextStep,
  previousStep,
  resetStepper,
  resetQualification,
  calculateTotalTimeSpent,
  storeCalculationResult,
  startCalculating,
  startSubmitting,
  submittingSuccess,
  startProcessDataLoading,
  getProcessData,
  getQualificationData,
  startSaving,
  saveStepData,
  isLoadingConfirm,
  storePreventHandleClose,
  resetPreventHandleClose,
  storeCustomHandleClose,
  resetCustomHandleClose,
} = stepperSlice.actions;

// load steps data async
// load Qualification data async

// load process data async
// calculate factors async
// submitProcess
// updateProcess
// editProcess
