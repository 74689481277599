import React, { useState } from "react";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";
import { Grid, Button } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { setProcessesAction } from "../../../../redux/slices/executionManagerSlice";
import {
  ASK_DELETE_QUEUE,
  CONFIRM_DELETE_QUEUE,
  ASK_ENABLE_QUEUE,
  CONFIRM_ENABLE_QUEUE,
  ASK_DISABLE_QUEUE,
  CONFIRM_DISABLE_QUEUE,
  CANCEL_QUEUE_ACTIONS,
  ASK_DELETE_QUEUE_FROM_ONE,
} from "util/constants"
import CustomDialog from "pages/Services/components/CustomDialog";

export function ProcessAssignDialog({
  openProcessAssignDialog,
  setOpenProcessAssignDialog,
  hasSchedules,
  dialogMsg,
  dialogMsg2,
  assignedProcesses,
  setAssignedProcesses,
  availableProcesses,
  setAvailableProcesses,
  selectedProcessId,
  classes
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [processesActionQueue, setProcessesActionForQueue] = useState([]);
  const { queueAction } = useSelector(({ executionManager }) => executionManager);

  const redragProcessToOrigin = (processId) => {
    const processAssigned = assignedProcesses.find(
      ({ id }) => id === processId
    );
    if (processAssigned) {
      setAssignedProcesses((prevState) => prevState.filter(({ id }) => id !== processId));
      setAvailableProcesses((prevState) => [...prevState, processAssigned]);
    } else {
      const processAvailable = availableProcesses.find(
        ({ id }) => id === processId
      );
      setAssignedProcesses((prevState) => [...prevState, processAvailable]);
      setAvailableProcesses((prevState) => prevState.filter(({ id }) => id !== processId));
    }
  };
  const postAssignedsProcessesAction = (action) => {
    if (action === "continue") {
      dispatch(
        setProcessesAction({
          processesAction: [],
          queueAction: getFeedBackAction(queueAction),
        })
      );
    } else {
      const newProcessesAction = [];
      if (assignedProcesses) {
        // Handle list of process
        assignedProcesses.forEach((assProc) => {
          /* newProcessesAction = processesAction.filter(
          (el) => el.id !== assProc.id
        ); */
          newProcessesAction.push({
            id: assProc.id,
            action,
          });
        });
        setProcessesActionForQueue(...newProcessesAction);
        dispatch(
          setProcessesAction({
            processesAction: newProcessesAction,
            queueAction: getFeedBackAction(queueAction),
          })
        );
        setOpenProcessAssignDialog(false);
      } else {
        postAssignProcessAction(action);
      }
    }
  };

  const postAssignProcessAction = (action) => {
    const newProcessesAction = processesActionQueue.filter(
      (el) => el.id !== selectedProcessId
    );
    newProcessesAction.push({
      id: selectedProcessId,
      action,
      robotId: null,
    });
    switch (action) {
      case "DELETE":
        setOpenProcessAssignDialog(false);
        redragProcessToOrigin(selectedProcessId);
        break;
      case "KEEP": {
         setOpenProcessAssignDialog(false);
        break;
      }
      default:
        break;
    }
    setProcessesActionForQueue(newProcessesAction);
  };

   // eslint-disable-next-line consistent-return
   const getFeedBackAction = (askingAction) => {
     switch (askingAction) {
       case ASK_DELETE_QUEUE:
         return CONFIRM_DELETE_QUEUE;
       case ASK_DELETE_QUEUE_FROM_ONE:
         return CONFIRM_DELETE_QUEUE;
       case ASK_ENABLE_QUEUE:
         return CONFIRM_ENABLE_QUEUE;
       case ASK_DISABLE_QUEUE:
         return CONFIRM_DISABLE_QUEUE;
       default:
         break;
     }
   };

   const cancelActionsProcess = () => {
    setOpenProcessAssignDialog(false);
    dispatch(
       setProcessesAction({
         processesAction: [],
         queueAction: CANCEL_QUEUE_ACTIONS,
       })
     );
   };
  return (
    <CustomDialog
          open={openProcessAssignDialog}
          aria-labelledby="confirm_message-dialog-title"
          maxWidth="xs"
        >
      <DialogContent>
        <DialogContentText id="confirm_message-dialog-content">
          <Grid container xs={12} justify="center">
            <Grid item xs={10}>
              {dialogMsg}
            </Grid>
            {dialogMsg2 && (
              <Grid container xs={10}>
                <Typography
                      variant="subtitle1"
                      style={{ fontWeight: "bold" }}
                    >
                  {dialogMsg2}
                </Typography>
              </Grid>
                )}
          </Grid>
        </DialogContentText>
      </DialogContent>
      <MuiDialogActions>
        <Button
              ml="1rem"
              variant="contained"
              size="medium"
              onClick={cancelActionsProcess}
              className={classes?.resetButton}
            >
          {t("Cancel")}
        </Button>
        {hasSchedules ? (
          <Grid container direction="row-reverse" spacing={1}>
            <Grid item>
              <Button
                    ml="1rem"
                    variant="contained"
                    color="secondary"
                    size="medium"
                    onClick={() => {
                      postAssignedsProcessesAction("KEEP");
                    }}
                  >
                {t("Yes")}
              </Button>
            </Grid>
            <Grid item>
              <Button
                    ml="1rem"
                    variant="contained"
                    size="medium"
                    className={classes.deleteButton}
                    onClick={() => postAssignedsProcessesAction("DELETE")}
                  >
                {t("No")}
              </Button>
            </Grid>
          </Grid>
            ) : (
              <Button
                ml="1rem"
                variant="contained"
                color="secondary"
                size="medium"
                onClick={() => {
                  postAssignedsProcessesAction("continue")
                  setOpenProcessAssignDialog(false) }}
                className={classes.resetButton}
              >
                {t("Continue")}
              </Button>
            )}
      </MuiDialogActions>
    </CustomDialog>
  );
}

export default ProcessAssignDialog;

ProcessAssignDialog.defaultProps = {
  hasSchedulesWithoutRobots: false
}
