import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import range from "lodash/range";
import { makeDay } from "../../../AlertsManagement/CustomAlerts/CustomAlertForm/CustomAlertInfo/ScheduleForm/utils";
import CustomSelectField from "../../../../components/FormFields/CustomSelectField";
import useStyles from "../ScheduleForm/style";

function TabPanel(props) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} style={{ alignItems: "left", justifyContent: "left" }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default function SchedulePeriod({
  startDate,
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    watch,
    formState: { errors },
    register,
      setValue
  } = useFormContext();
  const periods = [
    {
 value: "ONCE", label: t("Once"), frequency: once, frequencyLabel: t("Once")
},
    {
 value: "HOURLY", label: t("Hourly"), frequency: hours, frequencyLabel: t("Hour(s)")
},
    {
 value: "DAILY", label: t("Daily"), frequency: days, frequencyLabel: t("Day(s)")
},
    {
 value: "WEEKLY", label: t("Weekly"), frequency: weeks, frequencyLabel: t("Week(s)") + makeDay(startDate, t, true)
},
    {
 value: "MONTHLY", label: t("Monthly"), frequency: months, frequencyLabel: t("Month(s)") + makeDay(startDate, t)
},
    {
 value: "YEARLY", label: t("Yearly"), frequency: years, frequencyLabel: t("Year(s)")
},
  ]
  const [selectedPeriod, setSelectedPeriod] = useState(periods?.[watch("unitType") && watch("unitType") > 0 ? +watch("unitType") : 0]?.value)
  const targetPeriod = useMemo(() => periods.find(({ value }) => value === selectedPeriod), [selectedPeriod])
  useEffect(() => {
    const initialPeriod = periods?.[watch("unitType") && watch("unitType") > 0 ? +watch("unitType") : 0]?.value;
    setSelectedPeriod(initialPeriod);
  }, [watch("unitType")]);

  const handlePeriodChange = (e) => {
    setSelectedPeriod(e.target.value)
    periods?.forEach((period, index) => {
        if (`${period?.value}` === `${e.target.value}`) {
            setValue("unitType", index);
        }
    })
  }
  return (
    <>
      <Grid item xs={12}>
        <CustomSelectField
            options={periods}
            optionLabel="label"
            optionValue="value"
            value={selectedPeriod}
            onChange={handlePeriodChange}
            variant="standard"
            formControlClassName={classes.periodFormControl}
            selectMenuClassName={classes.periodFormControlLabel}
            isCustom
        />
      </Grid>
      <Box component={Grid} display="flex" alignItems="flex-end" height="revert !important" item xs={12}>
        {selectedPeriod !== "ONCE" && <Typography variant="subtitle2" className={classes.dateLabel}>{t("Every")}</Typography>}
        <CustomSelectField
            {...register("period")}
            options={targetPeriod?.frequency}
            value={watch("period")}
            variant="standard"
            formControlClassName={classes.selectEmpty}
            selectMenuClassName={classes.periodFormControlLabel}
            customOptionLabel={(row) => row}
            defaultValue={1}
            isCustom
        />
        <Typography variant="subtitle2" className={classes.dateLabel}>{targetPeriod?.frequencyLabel}</Typography>
      </Box>
      {errors?.period && (
      <Box component={Grid} py="0px !important" height="revert !important" item xs={12}>
        <Typography color="error" className={classes.periodError}>
          {t("Your input is required")}
        </Typography>
      </Box>
      )}
    </>
  );
}

const once = [1];
const hours = range(1, 24);
const days = range(1, 31);
const weeks = range(1, 52);
const months = range(1, 12);
const years = range(1, 11);
