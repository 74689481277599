import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";
import { useParams, useHistory } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "@redux-requests/react";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Tabs from "@material-ui/core/Tabs";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import EditIcon from "@material-ui/icons/Edit";
import Stepper from "@material-ui/core/Stepper"
import Step from "@material-ui/core/Step"
import StepLabel from "@material-ui/core/StepLabel"
import { toast } from "react-toastify";
import IconButton from "@material-ui/core/IconButton";
import CustomTab, {
  useIndicatorStyle,
} from "pages/Services/components/CustomTab";
import CustomDialog from "pages/Services/components/CustomDialog";
import CustomCloseButton from "pages/Services/components/CustomCloseButton";
import {
  Badge,
  DialogActions,
  DialogContent,
  DialogContentText,
  Tooltip,
} from "@material-ui/core";
import { formatImagePath, getInitiales } from "../../../../util";
import {
  addTag,
  updateTag,
  fetchTagById,
  fetchIcon,
  fetchProcessesByFleet,
  fetchAllFleets,
} from "../../../../redux/actions/services/index";
import ConfirmMessage from "components/ConfirmMessage/index.js";
import ProcessSelector from "../../ProcessSelector/index.js";
import { TabPanel } from "../../../Services/components/SharedService";
import IconList from "../../../Services/components/ModalService/IconList";
import {
  FETCH_ALL_FLEETS,
  FETCH_PROCESS_LOGOS,
  FETCH_PROCESSES_BY_FLEET,
} from "../../../../redux/constants/index";
import useStyles from "./style.js";
import AutoCompleteField from "../../../../components/FormFields/AutoCompleteField";
import {
  HasPermission,
  isPermitted,
} from "../../../../components/HasPermission";
import get from "lodash/get";
import CustomButton from "../../../../components/CustomButton";

const fetchIconFromStaticFile = (icon) => fetch(icon).then((response) => response.blob());
export default function TagForm(props) {
  const { _detail } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [availableProcesses, setAvailableProcesses] = useState([]);
  const [assignedProcesses, setAssignedProcesses] = useState([]);
  const [icon, setIcon] = useState(
    formatImagePath(_detail?.content.icon)
  );
  const { idTag, mode = "add" } = useParams();
  const [openLogoList, setOpenLogoList] = useState(false);
  const availableLogos = useQuery({ type: FETCH_PROCESS_LOGOS }).data;
  const entityBaseUrl = "/robotAdministration/tags";
  const [openMsgCancel, setOpenMsgCancel] = useState(false);
  const [openMsgConfirm, setOpenMsgConfirm] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [tag, setTag] = useState(null);
  const disableFields = mode === "view";
  const history = useHistory();
  const currentUser = useSelector(({ requests }) => get(requests, "queries.FETCH_CURRENT_USER.data"));
  const handleRejectCancelForm = () => {
    setOpenMsgCancel(false);
  };

  const {
    formState: { errors },
    handleSubmit,
    trigger,
    register,
    setValue,
    control,
    getValues,
    watch,
    formState: { isDirty },
    clearErrors,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      id: 0,
      icon: "img.png",
      name: "",
      description: "",
      status: "ACTIVE",
    },
  });

  const processes = useQuery({ type: FETCH_PROCESSES_BY_FLEET }).data?.list;
  const fleets = useQuery({ type: FETCH_ALL_FLEETS }).data;
  const fleetId = watch("fleetId");
  const handleCancel = () => {
    if (mode === "view" || !isDirty) {
      history.push(entityBaseUrl);
      return;
    }
    setOpenMsgCancel(true);
  };

  useEffect(() => {
    if (mode !== "add") {
      dispatch(fetchTagById(idTag)).then((res) => {
        if (res.status === 200) {
          setValue("name", res.data.name);
          setValue("description", res.data.description);
          setValue("fleetId", res.data.fleet.id);
          setTag(res.data);
          if (res.data.icon) {
            dispatch(fetchIcon(idTag)).then((res) => {
              const loadedIcon = res.data;
              if (loadedIcon) {
                setIcon(`data:image/svg+xml;utf8,${encodeURIComponent(loadedIcon)}`);
              }
            });
          }
        }
      });
    }
  }, [idTag, mode]);

  useEffect(() => {
    if (mode === "add") {
      setAvailableProcesses(processes);
      return;
    }
    if (Array.isArray(processes) && tag) {
      const _availableProcesses = processes.filter(
        (p) => !tag.processDtos.map(({ id }) => id).find((pq) => pq === p.id)
      );
      setAvailableProcesses(_availableProcesses);
      setAssignedProcesses(
        processes.filter((p) => tag.processDtos.map(({ id }) => id).includes(p.id))
      );
    }
  }, [processes, tag]);

  const handleAcceptCancelForm = () => {
    setOpenMsgCancel(false);
    history.push(entityBaseUrl);
  };

  function a11yProps(index) {
    return {
      "aria-controls": `tag-tabpanel-${index}`,
    };
  }

  const cancelConfirm = () => {
    setOpenMsgConfirm(false);
  };

  const handleChange = async (event, newValue) => {
    const result = await trigger();
    if (result) {
      setActiveStep(newValue);
    }
  };

  const handleButtonClick = async () => {
    const result = await trigger();
    if (result) {
      setActiveStep((prevActiveStep) => (prevActiveStep === 0 ? 0 : prevActiveStep - 1));
      setActiveStep(1);
    }
  };

  const handleBack = () => {
    if (activeStep <= 0) handleCancel();
    setActiveStep((prevActiveStep) => (prevActiveStep === 0 ? 0 : prevActiveStep - 1));
    setActiveStep(0)
  };

  const handleSaveClick = async () => {
    const result = await trigger();
    if (result) {
      if (activeStep === steps.length - 1) {
        setOpenMsgConfirm(true);
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    }
  };

  const confirmSave = () => {
    handleSubmit(onSubmit)();
  };

  const onSubmit = (data) => {
    const formData = new FormData();

    // Append individual form input values to the formData
    formData.append("name", data.name);
    formData.append("description", data.description);
    formData.append(
      "processesIds",
      assignedProcesses?.map((p) => p.id)
    );
    if (icon) {
      fetchIconFromStaticFile(icon).then((blob) => {
        formData.append(
          "icon",
          new File([blob], "icon.svg", { type: "image/svg+xml" })
        );
        dispatchSave(formData);
      });
    } else {
      dispatchSave(formData);
    }
  };
  const dispatchSave = (formData) => {
    const saveAction = mode === "add" ? addTag(formData) : updateTag(idTag, formData);
    const successMessage = mode === "add"
        ? t("tag.management.form.save.success")
        : t("tag.management.form.update.success");
    const errorMessage = mode === "add"
        ? t("tag.management.form.save.error")
        : t("tag.management.form.update.error");
    dispatch(saveAction).then((res) => {
      if (res.status === 201 || res.status === 200) {
        setOpenMsgConfirm(false);
        history.push({
          pathname: entityBaseUrl,
        });
        toast.success(successMessage);
      } else {
        setOpenMsgConfirm(false);
        toast.error(errorMessage);
      }
    });
  };

  useEffect(() => {
    dispatch(fetchAllFleets());
  }, [dispatch]);

  useEffect(() => {
    let sourceOfFleet = fleetId;
    if (mode === "add") {
      sourceOfFleet = currentUser?.fleet?.id;
    }
    sourceOfFleet && dispatch(fetchProcessesByFleet(sourceOfFleet, false));
  }, [currentUser, dispatch, fleetId]);

  const tabsStyle = useIndicatorStyle();

  const steps = [
    "General Info",
    "user.add.process",
  ];

  const handleStepClick = async (step) => {
    if (activeStep !== step && mode === "edit") {
      const result = await trigger();
      if (result) setActiveStep(step);
    }
  };

  const handleModalClose = () => {
    if (mode === "view") {
      history.goBack();
    }
  };

  return (
    <div className={classes.root}>
      <CustomDialog
        onClose={() => handleCancel()}
        aria-labelledby="customized-dialog-title"
        open="true"
        onBackdropClick={handleModalClose}
        maxWidth="md"
        fullWidth
      >
        <div>
          {mode === "view" ? (
            <Paper square>
              <Grid container direction="row" xs={12}>
                <Grid item xs={11}>
                  <Tabs
                    id="process-tabs-page"
                    className={classes.tabSize}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={handleChange}
                    aria-label="switch tabs"
                    value={activeStep}
                    TabIndicatorProps={{
                      style: tabsStyle,
                    }}
                  >
                    <CustomTab
                      id="tag-overview-tab"
                      label={t("General Info")}
                      {...a11yProps(0)}
                    />
                    <CustomTab
                      id="tag-process-tab"
                      label={t("user.add.process")}
                      {...a11yProps(1)}
                    />
                  </Tabs>
                </Grid>
                <Grid container item direction="row" xs={1} justify="flex-end">
                  <CustomCloseButton
                    aria-label="close"
                    onClick={() => handleCancel()}
                    className={classes.closeButton}
                  />
                </Grid>
              </Grid>
            </Paper>
          ) : (
            <>
              <Grid
                container
                direction="row"
                xs={12}
                justify="flex-end"
                className={classes.closebutton}
              >
                <CustomCloseButton
                  className={classes.closeIcon}
                  onClick={handleCancel}
                />
              </Grid>
              <Stepper
                activeStep={activeStep}
                alternativeLabel
                className={classes.stepper}
              >
                {steps.map((label, index) => (
                  <Step key={label}>
                    <StepLabel
                      style={{ cursor: "pointer" }}
                      onClick={() => handleStepClick(index)}
                    >
                      {t(label)}
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </>
          )}
        </div>
        <DialogContent className={classes.DialogContent}>
          <DialogContentText
            id="scroll-dialog-description"
            tabIndex={-1}
            component="div"
          >
            <>
              <TabPanel value={activeStep} index={0} className={classes.paper}>
                <Grid
                  container
                  direction="column"
                  justify="space-between"
                  spacing={3}
                >
                  <Grid item className={classes.tabPanleGitem}>
                    {mode === "view" && isPermitted(currentUser, "Edit tag") ? (
                      <Grid container justify="flex-end">
                        <Tooltip title={t("Edit")}>
                          <IconButton
                          aria-label="modify"
                          onClick={() => {
                            history.push(
                              `/robotAdministration/tags/edit/${idTag}`
                            );
                          }}
                        >
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                  ) : null}
                    { isPermitted(currentUser, "Edit tag") ? (
                      <Grid container justify="center">
                        <Badge
                          overlap="circle"
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                          }}
                          badgeContent={
                            !disableFields && (
                              <HasPermission name="Edit tag">
                                <IconButton
                                  className={classes.editIcon}
                                  onClick={() => setOpenLogoList(true)}
                                >
                                  <EditIcon fontSize="small" />
                                </IconButton>
                              </HasPermission>
                            )
                          }
                        >
                          {icon ? (
                            <img
                              src={icon}
                              align="left"
                              alt="Service"
                              className={classes.avatar}
                            />
                          ) : (
                            <Avatar alt="avatar" className={classes.avatar}>
                              {getInitiales(tag?.name)}
                            </Avatar>
                          )}
                        </Badge>
                      </Grid>
                    ) : null}
                  </Grid>
                </Grid>
                <DialogContent className={classes.formRow}>
                  <Grid container justify="space-between">
                    {mode !== "add" && (
                    <Grid item xs={6} className={classes.inputsSection}>
                      <Controller
                              {...register("fleetId")}
                              key={getValues("fleetId")}
                              control={control}
                              rules={{
                                required: t("user.management.formControl.required"),
                              }}
                              render={({ field }) => (
                                <AutoCompleteField
                                      options={fleets ?? []}
                                      optionLabel="companyName"
                                      value={fleets?.find((item) => item.id === fleetId)}
                                      onChange={(newValue) => field?.onChange(newValue.id)}
                                      label={`${t("fleet")}*`}
                                      error={!!errors.fleetId?.message}
                                      errorText={errors.fleetId?.message}
                                      disabled={disableFields || mode === "edit"}
                                  />
                              )}
                          />
                    </Grid>
                    )}
                    <Grid item xs={6} className={classes.inputsSection}>
                      <TextField
                        {...register("name", {
                          required: {
                            value: true,
                            message: t("user.management.formControl.required"),
                          },
                        })}
                        id="name"
                        label={`${t("tag.form.name")} *`}
                        InputLabelProps={{
                          shrink: !!getValues("name"),
                        }}
                        onChange={(event) => {
                          clearErrors("name");
                          setValue("name", event.target.value);
                        }}
                        fullWidth
                        error={!!errors.name?.message}
                        disabled={disableFields}
                      />
                      <p className={classes.validation_error}>
                        {errors.name?.message}
                      </p>
                    </Grid>
                    <Grid item xs={6} className={classes.inputsSection}>
                      <TextField
                        {...register("description", {
                          required: {
                            value: true,
                            message: t("user.management.formControl.required"),
                          },
                        })}
                        id="tagDescription"
                        label={`${t("tag.form.description")} *`}
                        InputLabelProps={{
                          shrink: !!getValues("description"),
                        }}
                        onChange={(event) => {
                          clearErrors("description");
                          setValue("description", event.target.value);
                        }}
                        fullWidth
                        error={!!errors.description?.message}
                        disabled={disableFields}
                      />
                      <p className={classes.validation_error}>
                        {errors.description?.message}
                      </p>
                    </Grid>
                  </Grid>
                </DialogContent>
                {!disableFields && (
                  <DialogActions className={classes.btnDown}>
                    <Box
                      ml="1rem"
                      component={Button}
                      variant="contained"
                      size="medium"
                      onClick={() => handleCancel()}
                    >
                      {t("user.button.cancel")}
                    </Box>
                    <CustomButton
                      size="medium"
                      view="primary"
                      onClick={handleButtonClick}
                    >
                      {t("tag.form.next")}
                    </CustomButton>
                  </DialogActions>
                )}
              </TabPanel>
              <TabPanel value={activeStep} index={1} className={classes.paper}>
                <Grid
                  container
                  direction="column"
                  justify="space-between"
                  style={{ flex: "1" }}
                >
                  {mode === "view" && isPermitted(currentUser, "Edit tag") ? (
                    <Grid container justify="flex-end">
                      <Tooltip title={t("Edit")}>
                        <IconButton
                          aria-label="modify"
                          onClick={() => {
                            history.push(
                              `/robotAdministration/tags/edit/${idTag}`
                            );
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  ) : null}
                  <Grid item xs={12}>
                    <ProcessSelector
                      availableProcesses={availableProcesses}
                      selectedProcesses={assignedProcesses}
                      setAvailableProcesses={setAvailableProcesses}
                      setSelectedProcesses={setAssignedProcesses}
                      isDisabled={disableFields}
                    />
                  </Grid>
                  <Grid item xs={12} className={classes.prevSaveBtns}>
                    {!disableFields && (
                      <DialogActions>
                        <Box
                          ml="1rem"
                          component={Button}
                          variant="contained"
                          size="medium"
                          onClick={handleBack}
                        >
                          {t("tag.add.previous")}
                        </Box>
                        <CustomButton
                          view="primary"
                          size="medium"
                          onClick={handleSaveClick}
                        >
                          {idTag
                            ? t("tag.management.update")
                            : t("tag.management.add")}
                        </CustomButton>
                      </DialogActions>
                    )}
                  </Grid>
                </Grid>
              </TabPanel>
            </>
          </DialogContentText>
        </DialogContent>
      </CustomDialog>
      {openMsgConfirm && (
        <ConfirmMessage
          message={
            idTag
              ? t("tag.management.update.confirm-msg")
              : t("tag.management.save.confirm-msg")
          }
          openStart={openMsgConfirm}
          onCancel={cancelConfirm}
          onConfirm={confirmSave}
          buttonCancel={t("Cancel")}
          buttonConfirm={
            idTag ? t("tag.management.update") : t("tag.management.add")
          }
          isLoading={false}
        />
      )}
      {openMsgCancel && (
        <ConfirmMessage
          message={t("user.delete.discard")}
          openStart={openMsgCancel}
          onCancel={handleRejectCancelForm}
          onConfirm={handleAcceptCancelForm}
          buttonCancel={t("Cancel")}
          buttonConfirm={t("fleet.button.discard")}
          isLoading={false}
        />
      )}
      <Grid container xs={12} justify="center">
        <IconList
          icons={availableLogos}
          isOpen={openLogoList}
          setOpen={setOpenLogoList}
          setIcon={setIcon}
          target="tags"
          _process={_detail?.content}
        />
      </Grid>
    </div>
  );
}
