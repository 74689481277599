import React from "react";
import get from "lodash/get";
import { useTranslation } from "react-i18next";
import { Typography, Avatar, Grid } from "@material-ui/core";
import PersonIcon from "@material-ui/icons/Person";
import useStyles from "./style.js";
import StatusBadge from "../../../../components/StatusBadge";
import { getApiDomain } from "../../../../util";

export default function AdminTableCell({ row, field }) {
  const { t } = useTranslation();

  const classes = useStyles();
  const getFieldValue = (row, {
    valuePath, translate = true, format, defaultValue,
  }) => {
    // eslint-disable-next-line no-nested-ternary
    const value = Array.isArray(valuePath)
      ? valuePath.reduce(
        (acc, currentValue) => (`${acc} ${t(get(row, currentValue))}`).trim(),
        "",
      )
      : translate
        ? t(get(row, valuePath))
        : get(row, valuePath);
    if (!value && defaultValue) return translate ? t(defaultValue) : defaultValue;
    if (format && typeof format === "function") return format(value);
    return value;
  };

  const getImageURL = (path) => {
    const imageUrl = field.id === "tagIcon"
      ? `${getApiDomain()}api/orchestrator/tags/${row.id}/icon`
      : `${getApiDomain()}api/admin${path}`;

    // Create a function to check if the image exists
    const checkImageExists = (url) => new Promise((resolve) => {
      const img = new Image();
      img.onload = () => resolve(true);
      img.onerror = () => resolve(false);
      img.src = url;
    });

    return checkImageExists(imageUrl) ? imageUrl : null;
  };

  const getInitiales = (name) => {
    if (!name) return null;
    return name
      .split(" ")
      .reduce((acc, word, index) => {
        if (index === 0) {
          return word.charAt(0).toUpperCase();
        }
        return acc;
      }, "");
  };

    return (
      <Grid item xs={field.gridSize} align={field.align}>
        {(() => {
          switch (true) {
            case Boolean(field.action):
              return field.action.component({ row });
            case field.isAvatar:
              let avatarComponent = null;
              const avatarPath = get(row, field.avatarPath);
              const valuePath = get(row, field.valuePath);
                if (avatarPath) {
                  avatarComponent = (
                    <Avatar
                      className={classes.userAvatar}
                      alt={valuePath}
                      src={getImageURL(avatarPath)}
                    />
                  );
                } else if (getInitiales(valuePath)) {
                  avatarComponent = (
                    <Avatar className={`${classes.userAvatar} ${classes.avatarColor}`}>
                      {getInitiales(valuePath)}
                    </Avatar>
                  );
                } else {
                  avatarComponent = (
                    <Avatar className={`${classes.userAvatar} ${classes.avatarColor}`}>
                      <PersonIcon className={classes.avatarIcon} />
                    </Avatar>
                  );
                }
              return (
                <Grid item xs={field.gridSize} align={field.align}>
                  {avatarComponent}
                </Grid>
              );
            case field.isStatus:
              return <StatusBadge level={getFieldValue(row, field)} />;
            default:
              return (
                <Typography color="secondary" variant="body2" align={field.align}>
                  {field.customRender ? field.customRender(row) : getFieldValue(row, field)}
                </Typography>
              );
          }
        })()}
      </Grid>
    );
}
