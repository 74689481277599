import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import Tabs from "@material-ui/core/Tabs"
import TextField from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"
import MenuItem from "@material-ui/core/MenuItem"
import Select from "@material-ui/core/Select"
import CustomDialog from "pages/Services/components/CustomDialog";
import CustomTab, { useIndicatorStyle } from "pages/Services/components/CustomTab";
import CustomCloseButton from "pages/Services/components/CustomCloseButton";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import Divider from "@material-ui/core/Divider";
import { timeSlots } from "../../utils";
import CustomButton from "../../../../components/CustomButton";
import useStyles from "./style";
import DataNotFound from "../../../../components/DataNotFound";
import OccurrencesNotFound from "../../../../assets/Process_Overview.svg"
import { toast } from "react-toastify";

function RiskManagement({
  openStart,
  handleClose,
  dispatch,
  t,
  reloadPlannerData,
  updateProcessRiskTime,
  processes,
}) {
  const classes = useStyles();
  const {
    register, handleSubmit, setValue, getValues,
  } = useForm({
    mode: "onChange",
  });

  const onSubmit = (data) => {
    const newData = data.riskManagement.map((e) => ({
      ...e,
      riskTime: e.riskTime
        ? e.riskTime
        : 0,
    }));
    dispatch(updateProcessRiskTime(newData)).then((result) => {
      if (result?.status === 200) {
        toast.success(t("calendar.formControl.saveRiskSuccess"));
        reloadPlannerData();
        handleClose();
      }
      else
      {
        toast.error(t("Unexpected Error"));
      }
    });
  };
  useEffect(() => {
    if (processes) {
      processes.map((row, index) => (setValue(
        `riskManagement[${index}].riskTime`,
        row.riskTime,
      )));
    }
  }, [processes]);

  const indicatorStyle = useIndicatorStyle();

  return (
    <CustomDialog
      open={openStart}
      aria-labelledby="customized-dialog-title"
      maxWidth="lg"
      onClose={handleClose}
      classes={{
        paper: classes.dialogPaper,
      }}
    >
      <Paper square>
        <Grid container direction="row" xs={12}>
          <Grid container direction="row" xs={11}>
            <Tabs
              id="process-tabs-page"
              indicatorColor="primary"
              textColor="primary"
              TabIndicatorProps={{
                style: indicatorStyle,
              }}
            >
              <CustomTab
                id="process-overview-tab"
                label={t("Risk management")}
              />
            </Tabs>
          </Grid>
          <Grid container direction="row" xs={1} justify="flex-end">
            <CustomCloseButton aria-label="close" onClick={handleClose} />
          </Grid>
        </Grid>
      </Paper>
      <Grid container item xs={12}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={classes.root}
          noValidate
          autoComplete="off"
        >
          {processes?.length ? (
            <div className={classes.contentContainer}>
              <Grid container xs={12} className={classes.grid}>
                <Grid item alignItems="center" xs={9}>
                  <Typography variant="body2" className={classes.typography}>
                    {t("Schedule Name")}
                  </Typography>
                </Grid>
                <Grid item container xs={3}>
                  <Typography variant="body2" className={classes.typography}>
                    {t("Risk management")}
                  </Typography>
                </Grid>
              </Grid>
              <Paper className={classes.paper}>
                {processes
                  && processes.map((row, index) => (
                    <Grid container xs={12} className={classes.grid}>
                      <Grid item xs={9}>
                        <TextField
                          value={row?.scheduleName}
                          style={{ width: "23rem" }}
                          onChange={setValue(
                            `riskManagement[${index}].scheduleId`,
                            row.id,
                          )}
                          InputProps={{
                            readOnly: true,
                            disableUnderline: true,
                          }}
                        />
                      </Grid>
                      <Grid item container xs={3}>
                        <Select
                          {...register(`riskManagement[${index}].riskTime`)}
                          fullWidth
                          defaultValue={getValues(
                            `riskManagement[${index}].riskTime`,
                          )}
                          style={{ width: "10rem" }}
                          onChange={(e) => {
                            setValue(
                              `riskManagement[${index}].riskTime`,
                              e.target.value,
                            );
                          }}
                        >
                          {timeSlots
                            && timeSlots.map((e, index) => (
                              <MenuItem
                                key={`timeSlots-${index}`}
                                value={e.value}
                              >
                                {e.label}
                              </MenuItem>
                            ))}
                        </Select>
                      </Grid>
                    </Grid>
                  ))}
              </Paper>
            </div>
          ) : (
            <DataNotFound
              message={t("ERRORS_NO_DATA")}
              icon={OccurrencesNotFound}
              boxShadow="none"
            />
          )}
          <Divider />
          <Grid item container xs={12} direction="row" justify="flex-end">
            <Grid item container xs="auto" direction="row" justify="flex-end">
              <Grid
                item
                container
                xs="auto"
                direction="row"
                justify="flex-end"
                className={classes.cardActions}
              >
                <CustomButton view="cancel" onClick={handleClose}>
                  {t("Cancel")}
                </CustomButton>
                <CustomButton
                  view="primary"
                  style={{ marginLeft: "8px" }}
                  type="submit"
                  disabled={!processes?.length}
                >
                  {t("apply")}
                </CustomButton>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </CustomDialog>
  );
}
export default RiskManagement;
