import React from "react";
import { useTranslation } from "react-i18next";
import {
  Card, CardContent, Typography, Grid,
} from "@material-ui/core";
import useStyles from "./style.js";

export default function AlertsTableHeader({ headerCells }) {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <Card className={classes.header}>
      <CardContent className={classes.content}>
        <Grid container>
          <Grid
            container
            item
            xs={12}
            justify="flex-start"
            alignItems="flex-end"
          >
            {headerCells.fields.map((cell, index) => (
              <Grid key={index} item xs={cell.gridSize}>
                <Typography
                  variant="body2"
                  align={cell.align}
                  color="textSecondary"
                >
                  {t(cell.label)}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
