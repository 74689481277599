import React, { useEffect } from "react";
import Avatar from "@material-ui/core/Avatar"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import Paper from "@material-ui/core/Paper"
import TextField from "@material-ui/core/TextField"
import Box from "@material-ui/core/Box"
import Typography from "@material-ui/core/Typography"
import Tooltip from "@material-ui/core/Tooltip"
import CircularProgress from "@material-ui/core/CircularProgress"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import FormControl from "@material-ui/core/FormControl"
import InputLabel from "@material-ui/core/InputLabel"
import CustomTab, { useIndicatorStyle } from "pages/Services/components/CustomTab";
import Tabs from "@material-ui/core/Tabs";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import SettingsEthernetIcon from "@material-ui/icons/SettingsEthernet";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import get from "lodash/get";
import SettingsIcon from "@material-ui/icons/Settings";
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck";
import clsx from "clsx";
import ConfirmMessage from "../../../../components/ConfirmMessage";
import CustomCloseButton from "pages/Services/components/CustomCloseButton";
import {
  fetchOrchestrator,
  createOrchestrator,
  modifyOrchestrator,
  fetchProcessesByOrchestrator,
  testOrchestratorConnection,
  fetchRobotsByOrchestrator,
  testOrchestratorUipathConnection,
} from "../../../../redux/actions/services/index";
import ProcessesNameCard from "../components/ProcessesNameCard";
import CircularLoader from "../../../../components/Loaders/CircularLoader";
import InformationIcon from "../../../../components/InformationIcon";
import RobotsCard from "../components/RobotsCard";
import { isFleetAdministrator } from "../../../../util";
import ConfigurationDrawer from "../components/ConfigurationDrawer";
import StatusDrawer from "../components/StatusDrawer";
import useStylesTwo from "../../FleetManagement/FleetForm/style";
import useStyles from "./style.js";
import { toast } from "react-toastify";
import CustomSwitch from "../../../Services/components/CustomSwitch";
import CustomButton from "../../../../components/CustomButton";

export default function OrchestratorForm() {
  const tabsStyle = useIndicatorStyle();
  const classes = useStyles();
  const classesTwo = useStylesTwo();
  const dispatch = useDispatch();
  const {
    formState: { errors },
    handleSubmit,
    setValue,
    trigger,
    register,
    watch,
    formState: { isDirty },
    getValues,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      name: "",
      description: "",
      databaseServer: "",
      serverPort: "",
      databaseName: "",
      dbUsername: "",
      dbPassword: "",
      orchestratorType: "blueprism",
      serverType: "sqlserver",
      uipathOrchestratorDetail: {
        apiClientId: "",
        apiClientSecret: "",
        organizationName: "",
        tenantName: "",
        orchestratorApiName: "",
      }
    },
  });

  const history = useHistory();
  const { t } = useTranslation();
  const { idOrchestrator, mode = "add" } = useParams();
  const [orchestratorName, setOrchestratorName] = React.useState();

  const [isLoading, setIsLoading] = React.useState(false);
  const [connSuccess, setConnSuccess] = React.useState(-1);
  const [testConnLoading, setTestConnLoading] = React.useState(false);
  const [processListLoading, setProcessListLoading] = React.useState(false);
  const [robotListLoading, setRobotListLoading] = React.useState(false);
  const [configurationDrawer, setConfigurationDrawer] = React.useState(false);
  const [statusDrawer, setStatusDrawer] = React.useState(false);
  const [sessionNumber, setSessionNumber] = React.useState(0);
  const [dialogMsg, setDialogMsg] = React.useState("");

  const orchestratorTypes = [
    { key: "Blue Prism", value: "blueprism" },
    ...!process.env.REACT_APP_DISABLE_UIPATH ? [{ key: "Uipath", value: "uipath" }] : [],
  ];

  const serverTypes = [
    { key: "SQL Server", value: "sqlserver" },
    { key: "MySQL", value: "mysql" },
  ];

  const currentUser = useSelector(({ requests }) => get(requests, "queries.FETCH_CURRENT_USER.data"));

  const isUiPathOrchestrator = () => (watch("orchestratorType") === "uipath");

  const [processesByOrchestrator, setProcessesByOrchestrator] = React.useState(
    [],
  );

  const [robotsByOrchestrator, setRobotsByOrchestrator] = React.useState([]);

  useEffect(() => {
    if (idOrchestrator)
    {
        setRobotListLoading(true);
        dispatch(fetchRobotsByOrchestrator(idOrchestrator)).then((res) => {
          setRobotListLoading(false);
          setRobotsByOrchestrator(res?.data ?? []);
        });
    }
  }, [idOrchestrator]);

  useEffect(() => {
    if (mode !== "add") {
      setProcessListLoading(true);
      dispatch(fetchOrchestrator(idOrchestrator)).then((res) => {
        setOrchestratorName(res.data.name);
        setValue("name", res.data.name);
        setValue("description", res.data.description);
        setValue("databaseServer", res.data.databaseServer);
        setValue("serverPort", res.data.serverPort);
        setValue("databaseName", res.data.databaseName);
        setValue("dbUsername", res.data.dbUsername);
        setValue("dbPassword", res.data.dbPassword);
        setValue("orchestratorType", res.data.orchestratorType);
        setValue("serverType", res.data.serverType);
        setValue("uipathOrchestratorDetail", res.data.uipathOrchestratorDetail);
        setSessionNumber(res.data.sessionNumber);
        dispatch(fetchProcessesByOrchestrator(res.data.id)).then((res) => {
          setProcessesByOrchestrator(res?.data ?? []);
          setProcessListLoading(false);
        });
      });
    }
  }, [idOrchestrator, dispatch, setValue, mode]);

  const [openMsgCancel, setOpenMsgCancel] = React.useState(false);

  const handleCancel = () => {
    if (mode === "view" || !isDirty) {
      redirectToList();
      return;
    }
    setOpenMsgCancel(true);
  };
  const handleNotification = (message, status) => {
    const msg = t(message);
    setDialogMsg(msg);
    if (status) {
      toast.success(msg);
    } else {
      toast.error(msg);
    }
  }
  const redirectToList = () => history.push("/robotAdministration/orchestrators");

  const handleSaveClick = async () => {
    const result = await trigger();
    if (result) {
      setOpenMsgConfirm(true);
    }
  };

  const handleRejectCancelForm = () => setOpenMsgCancel(false);
  const handleAcceptCancelForm = () => {
    setOpenMsgCancel(false);
    setIsLoading(false);
    history.push("/robotAdministration/orchestrators");
  }

  const [openMsgConfirm, setOpenMsgConfirm] = React.useState(false);

  const cancelConfirm = () => {
    setOpenMsgConfirm(false);
    setIsLoading(false);
  };

  const getInitiales = () => {
    if (mode === "add") {
      return "SR";
    }
    return orchestratorName
      ?.split(" ")
      .filter((x, index) => {
        if (index === 0 || index === orchestratorName.split(" ").length - 1) return x;
        return "";
      })
      .reduce((acc, x) => (acc + x.charAt(0).toUpperCase()).trim(), "");
  };

  const confirmSave = () => {
    handleSubmit(onSubmit)();
  };

  const onSubmit = (data) => {
    setIsLoading(true);
    if (mode === "add") {
      dispatch(
        createOrchestrator(data, null, (res) => {
          // file is null but implementation is taking into account adding an image
          if (res?.status === 200) {
            setTimeout(() => {
              history.push({
                pathname: "/robotAdministration/orchestrators",
              });
              toast.success(t("orchestrator.formControl.saveSuccess"))
              setIsLoading(false);
            }, 400);
          }
        }),
      )
        .then((res) => {
          if (res.response.status !== "OK") {
            setOpenMsgConfirm(false);
            setIsLoading(false);
            handleNotification(res.response?.data?.message, false);
          }
        })
        .catch(() => {});
    } else {
      dispatch(
        modifyOrchestrator(idOrchestrator, data, null, (res) => {
          if (res?.status === 200) {
            setTimeout(() => {
              history.push({
                pathname: "/robotAdministration/orchestrators",
              });
              toast.success(t("orchestrator.formControl.updateSuccess"))
              setIsLoading(false);
            }, 400);
          }
        }),
      )
        .then((res) => {
          if (res.response.status !== "OK") {
            setOpenMsgConfirm(false);
            setIsLoading(false);
            handleNotification("Unexpected Error", false);
          }
        })
        .catch(() => {});
    }
  };

  const getTestConnIcon = () => {
    if (testConnLoading) {
      return <CircularProgress size="1rem" color="inherit" />;
    }
    switch (connSuccess) {
      case 1:
        return <CheckCircleOutlineIcon style={{ color: "green" }} />;
      case 0:
        return <ErrorOutlineIcon color="error" />;
      default:
        return <SettingsEthernetIcon />;
    }
  };

  const testConnection = (data) => {
    setTestConnLoading(true);
    const onSuccess = (res) => {
      setTestConnLoading(false);
      if (res.data === true) {
        setConnSuccess(1);
        handleNotification("orchestrator.management.testConnection.successMessage", true);
      } else {
        setConnSuccess(0);
        handleNotification("orchestrator.management.testConnection.failureMessage", false);
      }
    };
    const onError = () => {
      setTestConnLoading(false);
      setConnSuccess(0);
      handleNotification("orchestrator.management.testConnection.failureMessage", false);
    };
    if (watch("orchestratorType") === "uipath") {
      dispatch(testOrchestratorUipathConnection(data?.uipathOrchestratorDetail, onSuccess, onError));
    } else
    {
      let connectionCredentials = data;
      if (mode !== "add") {
        connectionCredentials = { orchestratorId: idOrchestrator, ...connectionCredentials }
      }
      dispatch(testOrchestratorConnection(connectionCredentials, onSuccess, onError));
    }
  };

  const disableFields = !(mode === "add" || mode === "edit");
  const uipathIsSelected = watch("orchestratorType") === "uipath";
  const steps = [
    { label: "fleet.formSection.fleetInfo" },
    { label: "Process.list.names.by.orchestrator" },
    { label: "Robot.list.names.by.orchestrator", hidden: uipathIsSelected },
  ];

  const [activeStep, setActiveStep] = React.useState(0);

  const handleChange = (_, newValue) => {
    setActiveStep(newValue);
  };

  const getServerTypeIndex = () => {
    const orchType = watch("orchestratorType")
    return +(orchType === "uipath");
  }

  const setDbVendor = () => {
    const index = getServerTypeIndex();
    return <MenuItem value={serverTypes[index].value}>{serverTypes[index].key}</MenuItem>
  }

  useEffect(() => {
    const index = getServerTypeIndex();
    setValue("serverType", serverTypes[index].value)
  }, [watch("orchestratorType")]);
  const UiPathFields = () => (
    <>
      <Grid item xs={12} container className={classes.formRow}>
        <Grid item xs={6} className={classes.inputsSection}>
          <TextField
            {...register("uipathOrchestratorDetail.apiClientId", {
              required: {
                value: true,
                message: "orchestrator.management.formControl.required",
              },
            })}
            fullWidth
            disabled={disableFields}
            id="rpa-orchestrator-apiClientId-input-field"
            InputLabelProps={{
              shrink: !!watch("uipathOrchestratorDetail.apiClientId"),
            }}
            label={`${t("orchestrator.management.formControl.apiClientId")} *`}
            error={!!errors.uipathOrchestratorDetail?.apiClientId?.message}
          />
          <p className={classes.validation_error}>
            {t(errors.uipathOrchestratorDetail?.apiClientId?.message)}
          </p>
        </Grid>
        <Grid item xs={6} className={classes.inputsSection}>
          <TextField
            {...register("uipathOrchestratorDetail.apiClientSecret", {
              required: {
                value: true,
                message: "orchestrator.management.formControl.required",
              },
            })}
            fullWidth
            disabled={disableFields}
            id="rpa-orchestrator-apiClientSecret-input-field"
            InputLabelProps={{
              shrink: !!watch("uipathOrchestratorDetail.apiClientSecret"),
            }}
            label={`${t(
              "orchestrator.management.formControl.apiClientSecret"
            )} *`}
            error={!!errors.uipathOrchestratorDetail?.apiClientSecret?.message}
          />
          <p className={classes.validation_error}>
            {t(errors.uipathOrchestratorDetail?.apiClientSecret?.message)}
          </p>
        </Grid>
      </Grid>
      <Grid item xs={12} container className={classes.formRow}>
        <Grid item xs={6} className={classes.inputsSection}>
          <TextField
            {...register("uipathOrchestratorDetail.organizationName", {
              required: {
                value: true,
                message: "orchestrator.management.formControl.required",
              },
            })}
            fullWidth
            disabled={disableFields}
            id="rpa-orchestrator-organizationName-input-field"
            InputLabelProps={{
              shrink: !!watch("uipathOrchestratorDetail.organizationName"),
            }}
            label={`${t(
              "orchestrator.management.formControl.organizationName"
            )} *`}
            error={!!errors.uipathOrchestratorDetail?.organizationName?.message}
          />
          <p className={classes.validation_error}>
            {t(errors.uipathOrchestratorDetail?.organizationName?.message)}
          </p>
        </Grid>
        <Grid item xs={6} className={classes.inputsSection}>
          <TextField
            {...register("uipathOrchestratorDetail.tenantName", {
              required: {
                value: true,
                message: "orchestrator.management.formControl.required",
              },
            })}
            fullWidth
            disabled={disableFields}
            id="rpa-orchestrator-tenantName-input-field"
            InputLabelProps={{
              shrink: !!watch("uipathOrchestratorDetail.tenantName"),
            }}
            label={`${t("orchestrator.management.formControl.tenantName")} *`}
            error={!!errors.uipathOrchestratorDetail?.tenantName?.message}
          />
          <p className={classes.validation_error}>
            {t(errors.uipathOrchestratorDetail?.tenantName?.message)}
          </p>
        </Grid>
      </Grid>
      <Grid item xs={12} container className={classes.formRow}>
        <Grid item xs={6} className={classes.inputsSection}>
          <TextField
            {...register("uipathOrchestratorDetail.orchestratorApiName", {
              required: {
                value: true,
                message: "orchestrator.management.formControl.required",
              },
            })}
            fullWidth
            disabled={disableFields}
            id="rpa-orchestrator-orchestratorApiName-input-field"
            InputLabelProps={{
              shrink: !!watch("uipathOrchestratorDetail.orchestratorApiName"),
            }}
            label={`${t(
              "orchestrator.management.formControl.orchestratorApiName"
            )} *`}
            error={
              !!errors.uipathOrchestratorDetail?.orchestratorApiName?.message
            }
          />
          <p className={classes.validation_error}>
            {t(errors.uipathOrchestratorDetail?.orchestratorApiName?.message)}
          </p>
        </Grid>
      </Grid>
    </>
  );

  const getStepContent = (stepIndex) => {
    if (mode !== "view") stepIndex = 0;
    switch (stepIndex) {
      case 0:
        return (
          <Grid container alignItems="flex-end" justify="flex-end">
            <Grid container xs={12} item>
              <Grid
                item
                xs={12}
                container
                alignItems="center"
                justify="center"
                className={classes.formRow}
              >
                <Avatar className={classes.avatar} alt="avatar">
                  {getInitiales(getValues("name"))}
                </Avatar>
              </Grid>

              <Grid item xs={12} container className={classes.formRow}>
                <Grid item xs={6} className={classes.inputsSection}>
                  <Select
                    {...register("orchestratorType", {
                      required: {
                        value: true,
                        message: "orchestrator.management.formControl.required",
                      },
                    })}
                    value={watch("orchestratorType")}
                    fullWidth
                    disabled={disableFields}
                    id="rpa-orchestrator-orchestratorType-input-field"
                    InputLabelProps={{
                      shrink: !!watch("orchestratorType"),
                    }}
                    label={`${t(
                      "orchestrator type",
                    )} *`}
                    error={!!errors.orchestratorType?.message}
                  >
                    {orchestratorTypes.map((orchestratorType) => (
                      <MenuItem value={orchestratorType.value}>{orchestratorType.key}</MenuItem>
                    ))}
                  </Select>
                  <p className={classes.validation_error}>
                    {t(errors.orchestratorType?.message)}
                  </p>
                </Grid>
              </Grid>
              <Grid item xs={12} container className={classes.formRow}>
                <Grid container item xs={6} className={classes.inputsSection}>
                  <TextField
                    {...register("name", {
                      required: {
                        value: true,
                        message: "orchestrator.management.formControl.required",
                      },
                    })}
                    fullWidth
                    id="rpa-orchestrator-orchestratorName-input-field"
                    name="name"
                    label={`${t(
                      "orchestrator.management.formControl.orchestratorName",
                    )} *`}
                    disabled={disableFields}
                    InputLabelProps={{
                      shrink: !!watch("name"),
                    }}
                    error={!!errors.name?.message}
                  />
                  <p className={classes.validation_error}>
                    {t(errors.name?.message)}
                  </p>
                  <Grid item className={classes.informationIcon}>
                    <InformationIcon
                      titleContent={t(
                        "orchestrator.management.formControl.orchestratorName.information",
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={6} className={classes.inputsSection}>
                  <TextField
                    {...register("description")}
                    fullWidth
                    multiline
                    disabled={disableFields}
                    id="rpa_orchestrator_description-input-field"
                    InputLabelProps={{
                      shrink: !!watch("description"),
                    }}
                    label={t("orchestrator.management.formControl.description")}
                  />
                  <Grid item className={classes.informationIcon}>
                    <InformationIcon
                      titleContent={t(
                        "orchestrator.management.formControl.description.information",
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} container className={classes.formRow}>
                <Grid item xs={6} className={classes.inputsSection}>
                  <TextField
                    {...register("databaseServer", {
                      required: {
                        value: true,
                        message: "orchestrator.management.formControl.required",
                      },
                    })}
                    fullWidth
                    disabled={disableFields}
                    id="rpa-orchestrator-databaseServer-input-field"
                    InputLabelProps={{
                      shrink: !!watch("databaseServer"),
                    }}
                    label={`${t(
                      "orchestrator.management.formControl.databaseServer",
                    )} *`}
                    error={!!errors.databaseServer?.message}
                  />
                  <p className={classes.validation_error}>
                    {t(errors.databaseServer?.message)}
                  </p>
                  <Grid item className={classes.informationIcon}>
                    <InformationIcon
                      titleContent={t(
                        "orchestrator.management.formControl.databaseServer.information",
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={6} className={classes.inputsSection}>
                  <TextField
                    {...register("serverPort", {
                      required: {
                        value: true,
                        message: "orchestrator.management.formControl.required",
                      },
                      validate: (data) => {
                        const input = parseInt(data);
                        if (
                          !Number.isInteger(input)
                          || input < 0
                          || input >= 65535
                        ) {
                          return "orchestrator.management.formControl.invalidPort";
                        }
                        return true;
                      },
                    })}
                    fullWidth
                    disabled={disableFields}
                    id="rpa-orchestrator-serverPort-input-field"
                    InputLabelProps={{
                      shrink: !!watch("serverPort"),
                    }}
                    label={`${t(
                      "orchestrator.management.formControl.serverPort",
                    )} *`}
                    error={!!errors.serverPort?.message}
                  />
                  <p className={classes.validation_error}>
                    {t(errors.serverPort?.message)}
                  </p>
                  <Grid item className={classes.informationIcon}>
                    <InformationIcon
                      titleContent={t(
                        "orchestrator.management.formControl.serverPort.information",
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
              {!isUiPathOrchestrator() && <Grid item xs={12} container className={classes.formRow}>
                <Grid item xs={12} className={classes.inputsSection}>
                  <TextField
                    {...register("databaseName", {
                      required: {
                        value: true,
                        message: "orchestrator.management.formControl.required",
                      },
                    })}
                    fullWidth
                    disabled={disableFields}
                    id="rpa-orchestrator-databaseName-input-field"
                    InputLabelProps={{
                      shrink: !!watch("databaseName"),
                    }}
                    label={`${t(
                      "orchestrator.management.formControl.databaseName",
                    )} *`}
                    error={!!errors.databaseName?.message}
                  />
                  <p className={classes.validation_error}>
                    {t(errors.databaseName?.message)}
                  </p>
                  <Grid item className={classes.informationIcon}>
                    <InformationIcon
                      titleContent={t(
                        "orchestrator.management.formControl.databaseName.information",
                      )}
                    />
                  </Grid>
                </Grid>
                { /* eslint-disable-next-line react/jsx-closing-tag-location */ }
              </Grid>}
              <Grid item xs={12} container className={classes.formRow}>
                <Grid item xs={6} className={classes.inputsSection}>
                  <TextField
                    {...register("dbUsername", {
                      required: {
                        value: true,
                        message: "orchestrator.management.formControl.required",
                      },
                    })}
                    fullWidth
                    disabled={disableFields}
                    id="rpa-orchestrator-dbUsername-input-field"
                    InputLabelProps={{
                      shrink: !!watch("dbUsername"),
                    }}
                    label={`${t(
                      "orchestrator.management.formControl.dbUsername",
                    )} *`}
                    error={!!errors.dbUsername?.message}
                  />
                  <p className={classes.validation_error}>
                    {t(errors.dbUsername?.message)}
                  </p>
                  <Grid item className={classes.informationIcon}>
                    <InformationIcon
                      titleContent={t(
                        "orchestrator.management.formControl.dbUsername.information",
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={6} className={classes.inputsSection}>
                  <TextField
                    {...register("dbPassword", {
                      required: {
                        value: true,
                        message: "orchestrator.management.formControl.required",
                      },
                    })}
                    type="password"
                    fullWidth
                    disabled={disableFields}
                    id="rpa-orchestrator-dbPassword-input-field"
                    InputLabelProps={{
                      shrink: !!watch("dbPassword"),
                    }}
                    label={`${t(
                      "orchestrator.management.formControl.dbPassword",
                    )} *`}
                    error={!!errors.dbPassword?.message}
                  />
                  <p className={classes.validation_error}>
                    {t(errors.dbPassword?.message)}
                  </p>
                  <Grid item className={classes.informationIcon}>
                    <InformationIcon
                      titleContent={t(
                        "orchestrator.management.formControl.dbPassword.information",
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} container className={classes.formRow}>
                <Grid item xs={6} className={classes.inputsSection}>
                  <FormControl fullWidth>
                    <InputLabel>
                      {`${t(
                        "server Type",
                    )} *`}
                    </InputLabel>
                    <Select
                      {...register("serverType", {
                        required: {
                          value: true,
                          message: "orchestrator.management.formControl.required",
                        },
                      })}
                      value={watch("serverType")}
                      fullWidth
                      disabled={disableFields}
                      id="rpa-orchestrator-serverType-input-field"
                      InputLabelProps={{
                        shrink: !!watch("serverType"),
                      }}
                      error={!!errors.serverType?.message}
                  >
                      {setDbVendor()}
                    </Select>
                  </FormControl>
                  <p className={classes.validation_error}>
                    {t(errors.serverType?.message)}
                  </p>
                </Grid>
                <Grid item xs={6} className={classes.inputsSection}>
                  {!isUiPathOrchestrator() && <TextField
                    fullWidth
                    disabled
                    value={sessionNumber}
                    id="rpa-orchestrator-sessionNumber-input-field"
                    label={t(
                      "orchestrator.management.formControl.sessionNumber",
                    )}
                  />}
                  {!isUiPathOrchestrator() && <Grid item className={classes.informationIcon}>
                    <InformationIcon
                      titleContent={t(
                        "orchestrator.management.formControl.sessionNumber.information",
                      )}
                    />
                    { /* eslint-disable-next-line react/jsx-closing-tag-location */ }
                  </Grid>}
                </Grid>
              </Grid>
              {uipathIsSelected && <UiPathFields />}
            </Grid>

            <Grid
              container
              item
              xs={12}
              justify="center"
              className={classes.testConnectionBox}
            >
              <Grid item>
                <Tooltip title={dialogMsg}>
                  <CustomButton
                    variant="outlined"
                    color="secondary"
                    startIcon={getTestConnIcon()}
                    onClick={() => handleSubmit(testConnection)()}
                  >
                    {t("orchestrator.management.testConnection")}
                  </CustomButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
        );
      case 1:
        return (
          <Grid
            container
            xs={12}
            direction="column"
            alignItems="center"
            spacing={1}
            className="h-100"
          >
            <Grid
              item
              xs={12}
              className="d-flex"
              style={{ flexDirection: "column" }}
            >
              {!processListLoading ? (
                <ProcessesNameCard t={t} processes={processesByOrchestrator} />
              ) : (
                <CircularLoader />
              )}
            </Grid>
          </Grid>
        );
      case 2:
        return !uipathIsSelected && (
          <Grid
            container
            xs={12}
            direction="column"
            alignItems="center"
            spacing={1}
            className="h-100"
          >
            <Grid
              item
              xs={12}
              className="d-flex"
              style={{ flexDirection: "column" }}
            >
              {!robotListLoading ? (
                <RobotsCard robots={robotsByOrchestrator} t={t} />
              ) : (
                <CircularLoader />
              )}
            </Grid>
          </Grid>
        );
      default:
        return null;
    }
  };
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        {mode === "view" && isFleetAdministrator(currentUser) ? (
          <Paper square>
            <Grid container direction="row" xs={12}>
              <Grid container direction="row" xs={11}>
                <Tabs
                  id="process-tabs-page"
                  className={classes.tabSize}
                  value={activeStep}
                  indicatorColor="primary"
                  textColor="primary"
                  onChange={handleChange}
                  aria-label="switch tabs"
                  TabIndicatorProps={{
                    style: tabsStyle,
                  }}
                >
                  {steps.map(({ label, hidden }, index) => !hidden && (
                  <CustomTab
                          key={label}
                          id="process-overview-tab"
                          value={index}
                          label={t(label)}
                      />
                  ))}
                </Tabs>
              </Grid>
              <Grid container direction="row" xs={1} justify="flex-end">
                <CustomCloseButton
                aria-label="close"
                onClick={handleCancel}
                className={classesTwo.closeIcon}
                />
              </Grid>
            </Grid>
          </Paper>
        ) : (
          <Grid container direction="row" xs={12}>
            <Grid className={classesTwo.boxTitle}>
              <Typography variant="h5" component="div">
                {t(steps[0]?.label)}
              </Typography>
            </Grid>
            <Grid
              container
              direction="row"
              xs={1}
              justify="flex-end"
              className={classesTwo.closebutton}
            >
              <CustomCloseButton
              aria-label="close"
              onClick={handleCancel}
              className={classesTwo.closeIcon}
              />
            </Grid>
          </Grid>
        )}
        <div className={classesTwo.formContainer}>
          {mode === "view" && isFleetAdministrator(currentUser) ? (
            <Grid container justify="flex-end" spacing={2}>
              <Grid item>
                <Tooltip title={t("Edit")}>
                  <IconButton
                    aria-label="modify"
                    onClick={() => {
                      history.push(
                        `/robotAdministration/orchestrators/edit/${idOrchestrator}`,
                      );
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item>
                <Tooltip title={t("Status")}>
                  <IconButton onClick={() => setStatusDrawer(true)}>
                    <PlaylistAddCheckIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item>
                <Tooltip title="Configuration">
                  <IconButton onClick={() => setConfigurationDrawer(true)}>
                    <SettingsIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          ) : null}
          <form className={classes.fleetForm}>
            <Grid
              container
              alignItems="center"
              xs={12}
              className={clsx(
                classesTwo.inputsContainer,
                classes.inputsContainer,
              )}
            >
              {getStepContent(activeStep)}
            </Grid>
            {!disableFields && (
              <Grid>
                <Grid item xs={12} className={classesTwo.nopadding}>
                  {currentUser?.fleet?.instanceOwner && isFleetAdministrator(currentUser) && (
                  <Grid item container xs={6} alignItems="flex-end" className={classes.inputsSection}>
                    <CustomSwitch
                        {...register("isPrivate")}
                        disabled={disableFields}
                        checked={watch("isPrivate")}
                        handleChange={() => (watch("isPrivate") ? setValue("isPrivate", false) : setValue("isPrivate", true))}
                        label={t("orchestrator.management.formControl.isPrivate")}
                    />
                  </Grid>
                  )}
                  <MuiDialogActions className={classesTwo.modalActionButtons}>
                    <Box
                      ml="1rem"
                      component={Button}
                      variant="contained"
                      size="medium"
                      onClick={handleCancel}
                      className={classes.resetButton}
                    >
                      {t("user.button.cancel")}
                    </Box>
                    <CustomButton
                      view="primary"
                      size="medium"
                      type="button"
                      onClick={handleSaveClick}
                    >
                      {idOrchestrator
                        ? t("orchestrator.button.update")
                        : t("orchestrator.button.save")}
                    </CustomButton>
                  </MuiDialogActions>
                </Grid>
              </Grid>
            )}
          </form>
        </div>
        {openMsgConfirm && (
          <ConfirmMessage
            message={
              idOrchestrator
                ? t("orchestrator.update.confirmMsg")
                : t("orchestrator.save.confirmMsg")
            }
            openStart={openMsgConfirm}
            onCancel={cancelConfirm}
            onConfirm={confirmSave}
            buttonConfirm={
              idOrchestrator
                ? t("orchestrator.button.update")
                : t("orchestrator.button.save")
            }
            buttonCancel={t("Cancel")}
            isLoading={isLoading}
          />
        )}

        {openMsgCancel && (
          <ConfirmMessage
            message={t("orchestrator.delete.discard")}
            openStart={openMsgCancel}
            onCancel={handleRejectCancelForm}
            onConfirm={handleAcceptCancelForm}
            buttonConfirm={t("Discard")}
            buttonCancel={t("Cancel")}
            isLoading={false}
          />
        )}
      </Paper>

      <ConfigurationDrawer
        open={configurationDrawer}
        setOpen={setConfigurationDrawer}
        orchestratorId={idOrchestrator}
      />
      <StatusDrawer
        open={statusDrawer}
        setOpen={setStatusDrawer}
        orchestratorId={idOrchestrator}
      />
    </div>
  );
}
