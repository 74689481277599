import React from "react";
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import TextField from "@material-ui/core/TextField"
import { useTranslation } from "react-i18next";
import CustomDialog from "pages/Services/components/CustomDialog/index.js";
import CustomButton from "../../../../../components/CustomButton";
import useStyles from "../../../style.js";

function CreatePresetDialog({ open, onClose, onSubmit }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [presetName, setPresetName] = React.useState("");
  const [errorHelperText, setErrorHelperText] = React.useState("");

  const handleSubmit = () => {
    if (presetName.length === 0) {
      setErrorHelperText("Required field");
      return
    }
    onSubmit(presetName);
    setPresetName("");
  }

  const onTextChange = (event) => {
    setPresetName(event.currentTarget.value)
    if (event.currentTarget.value.length !== 0) setErrorHelperText("")
  }

  const onCancel = () => {
    setErrorHelperText("")
    onClose()
  }

  return (
    <CustomDialog open={open} onClose={onCancel} disableBackdropClick fullWidth className={classes.createDialog}>
      <DialogTitle>{t("analytics.preset.dialog.label")}</DialogTitle>
      <DialogContent>
        <TextField
          error={errorHelperText.length !== 0}
          label={t("analytics.preset.dialog.field.label")}
          fullWidth
          onChange={(event) => onTextChange(event)}
          required
          helperText={errorHelperText}
        />
      </DialogContent>
      <DialogActions>
        <CustomButton variant="outlined" onClick={onCancel}>
          {t("Cancel")}
        </CustomButton>
        <CustomButton
          view="primary"
          onClick={handleSubmit}
        >
          {t("analytics.preset.dialog.field.submit")}
        </CustomButton>
      </DialogActions>
    </CustomDialog>
  );
}

export default (CreatePresetDialog);
