import React from "react";
import { useTranslation } from "react-i18next";
import { Controller } from "react-hook-form";
import {
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@material-ui/core";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import { Skeleton } from "@material-ui/lab";
import AutoCompleteField from "components/FormFields/AutoCompleteField";

export default function CustomTriggerRow({
  errors,
  classes,
  remove,
  control,
  append,
  fields,
  menuItems,
  disableFields,
  setValue,
  watch,
  processMenuItem,
  mode
}) {
  const { t } = useTranslation();
  const process = watch("process");

  const formHelperText = (index, field) => (
    <FormHelperText className={classes.validation_error}>
      {errors.customRules?.[`${index}`]?.[`${field}`]?.message}
    </FormHelperText>
  );

  const processField = (index) => (
    index === 0 ? (
      <Grid item xs md={3}>
        <AutoCompleteField
          options={processMenuItem ?? []}
          optionLabel="processDescription.processDisplayName"
          value={processMenuItem?.find((item) => item.id === process)}
          onChange={(newValue) => { setValue("process", newValue?.id) }}
          label={t("alerts.management.process.placeholder")}
          error={!!errors?.process}
          errorText={errors?.process?.message}
          disabled={disableFields}
        />
      </Grid>
    ) : (
      <Grid item xs md={3}>
        <AutoCompleteField
            options={processMenuItem ?? []}
            optionLabel="processDescription.processDisplayName"
            value={processMenuItem?.find((item) => item.id === watch("process"))}
            label={t("alerts.management.process.placeholder")}
            disabled={disableFields || index > 0}
        />
      </Grid>
    )
  );

  return (
    <>
      {fields.map((customRule, index) => (
        <Grid key={customRule.id} container item spacing={3} wrap="nowrap">
          <Grid item xs={1} className={classes.triggerGridIcon}>
            <span className={classes.triggerIcon}>
              <NotificationsActiveIcon />
            </span>
          </Grid>
          <Grid item xs md={3}>
            <Controller
              control={control}
              name={`customRules[${index}].when`}
              defaultValue=""
              rules={{
                required: {
                  value: true,
                  message: t("alerts.management.required.fields"),
                },
              }}
              render={({ field }) => (
                <FormControl size="small" fullWidth>
                  <InputLabel id="when">
                    {t("alerts.management.when.placeholder")}
                  </InputLabel>

                  <Select
                    {...field}
                    className={classes.select}
                    disabled={disableFields}
                    error={errors.customRules?.[`${index}`]?.when}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                  >
                    {menuItems.whenMenuItem.map((item, index) => (
                      <MenuItem key={index} value={item}>
                        <Tooltip title={t(item)}>
                          <span>{t(item)}</span>
                        </Tooltip>
                      </MenuItem>
                    ))}
                  </Select>

                  {formHelperText(index, "when")}
                </FormControl>
              )}
            />
          </Grid>
          {(processMenuItem && (process || mode === "add")) ? processField(index)
          : (
            <Grid item xs md={3}>
              <Skeleton variant="rectangular" className={classes.processSkeleton} />
            </Grid>
          )}

          <Grid item xs md={2}>
            <Controller
              control={control}
              name={`customRules[${index}].operator`}
              defaultValue=""
              rules={{
                required: {
                  value: true,
                  message: t("alerts.management.required.fields"),
                },
              }}
              render={({ field }) => (
                <FormControl size="small" fullWidth>
                  <InputLabel id="operator">
                    {t("alerts.management.operator.placeholder")}
                  </InputLabel>
                  <Select
                    {...field}
                    className={classes.select}
                    disabled={disableFields}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                    error={errors.customRules?.[`${index}`]?.operator}
                  >
                    {menuItems.operatorMenuItem.map((item, index) => (
                      <MenuItem key={index} value={item}>
                        <Tooltip title={t(item)}>
                          <span>
                            {t(item)}
                            {" "}
                          </span>
                        </Tooltip>
                      </MenuItem>
                    ))}
                  </Select>
                  {formHelperText(index, "operator")}
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs md={2}>
            <Controller
              control={control}
              name={`customRules[${index}].condition`}
              rules={{
                required: {
                  value: true,
                  message: t("alerts.management.required.fields"),
                },
              }}
              render={({ field }) => (
                <FormControl size="small" fullWidth>
                  <TextField
                    {...field}
                    id="condition"
                    label={t("alerts.management.condition.label")}
                    disabled={disableFields}
                    error={errors.customRules?.[`${index}`]?.condition}
                    helperText={
                      errors.customRules?.[`${index}`]?.condition?.message
                    }
                    type="number"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {(watch(`customRules[${index}].when`)
                            === "Total time saved"
                            || watch(`customRules[${index}].when`)
                              === "Process execution duration")
                            && t("Minutes")}
                          {(watch(`customRules[${index}].when`)
                            === "Items success rate"
                            || watch(`customRules[${index}].when`)
                              === "Items exception rate")
                            && "%"}
                        </InputAdornment>
                      ),
                      inputProps: { min: 0 },
                    }}
                  />
                </FormControl>
              )}
            />
          </Grid>

          {!disableFields && (
            <Grid
              item
              xs={1}
              style={{
                marginTop: "10px",
                display: "flex",
                marginLeft: "15px",
              }}
              justify="flex-start"
            >
              <Tooltip placement="top" title={t("tooltip.action.delete")}>
                <IconButton
                  className={classes.deleteIcon}
                  disabled={fields.length === 1}
                  aria-label="delete"
                  onClick={() => {
                    remove(index);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
              {index === fields.length - 1 && (
                <Tooltip placement="top" title={t("tooltip.action.add")}>
                  <IconButton
                    aria-label="add"
                    color="secondary"
                    onClick={() => {
                      append({
                        when: "",
                        operator: "",
                        condition: "",
                      });
                    }}
                  >
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              )}
            </Grid>
          )}
        </Grid>
      ))}
    </>
  );
}
