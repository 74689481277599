import esLocale from "date-fns/locale/es";
import frLocale from "date-fns/locale/fr";
import enUSLocale from "date-fns/locale/en-US";
import { subWeeks, subMonths, subYears } from "date-fns";
import { format } from "date-fns-tz";
import truncate from "lodash/truncate";
import { Duration } from "util/constants";
import i18n from "../i18n/index";
import moment from "moment";
import "react-toastify/dist/ReactToastify.css";
import { getDatePattern, formatDate } from "./helpers/date.helpers";
import { formtNumberByLanguage, getDefaultFormat } from "./helpers/number.helpers";
import theme from "../style/orchestratorTheme"
import { isPartitioningDisabled, isSRQueuesDisabled } from "./constants/PermitedFeature";

export const defaultManDayHours = 8;
export const defaultDaysPerYear = 228;
export const defaultRecommendedOcccupancy = 17;

export const robotStatus = {
  ACTIVE: "ACTIVE",
  DISABLE: "DISABLE"
}

export const tagStatus = {
  ACTIVE: "ACTIVE",
  DISABLED: "DISABLED"
}

export const STATUS = {
  READY: "READY",
  DONE: "DONE",
  TAKEN: "TAKEN",
};
export const exceptionCurrentModule = {
  ITEMS: "itemsExceptions",
  PROCESSES: "processesExceptions",
};
export const alertCurrentModule = {
  CUSTOM_ALERT: "customAlerts",
  DEFAULT_ALERT: "defaultAlerts",
};

export const handleInputChanges = (
  e,
  isFloatNumber = true,
  inputLength = undefined,
) => {
  let inputData = String(e.target.value);
  const countPoint = inputData.length - inputData.replaceAll(".", "").length;
  const point = !isFloatNumber || countPoint > 1 ? "" : ".";
  if (inputLength && inputData.length > inputLength) {
    inputData = inputData.slice(0, 9);
  }
  const lastElem = inputData[inputData.length - 1];
  if (Number(lastElem) !== 0 && !Number(lastElem) && lastElem !== point) {
    inputData = inputData.slice(0, -1);
  }
  e.target.value = inputData;
};
export const fromatInputOnBlure = (e) => {
  const value = getDefaultFormat(e.target.value);
  const newValue = formtNumberByLanguage(value);
  e.target.value = newValue;
};
export const fromatInputOnFocus = (e) => {
  const newValue = getDefaultFormat(e.target.value);
  e.target.value = newValue;
};

export const formatGraphNumbers = (tooltipItem, data) => (
  `${data.datasets[tooltipItem.datasetIndex].label
  }: ${formtNumberByLanguage(
    data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index],
  )}`
);
export const formatDateByLanguage = (
  date,
  withTime = true,
  customFormat = null,
) => {
  if (!date) return null;
  const { language } = i18n;
  const hasTime = date.toString().includes("T");
  if (customFormat !== null) date = moment(date, customFormat);
  if (!withTime) return formatDate(date, language, withTime);
  return formatDate(date, language, hasTime);
};

export const formatDatePickerByLanguage = (withTime = true) => {
  const { language } = i18n;
  return getDatePattern(language, withTime);
};

export function secondsToTime(s, t) {
  const time = new Date(s * 1000);
  const months = time.getUTCMonth();
  const days = time.getUTCDate() - 1;
  const hours = time.getUTCHours();
  const minutes = time.getUTCMinutes();
  const seconds = time.getUTCSeconds();
  const daysFormated = days > 0 ? `${days} ${t("timeUnite.days")} ` : "";
  const monthsFormated = months > 0 ? `${months} ${t("timeUnite.months")} ` : "";

  return (`${monthsFormated}${daysFormated}${hours} ${t("timeUnite.hrs")} ${minutes} ${t(
    "timeUnite.min",
  )}  ${seconds} ${t("timeUnite.sec")}`).toLowerCase();
}

export function secondsToFTPTime(hours, md, ftp, t) {
  const hoursFormated = abbreviateNumber(hours);
  const days = abbreviateNumber(hours / md);
  const years = abbreviateNumber(days / ftp);
  if (hours < md) {
    return `${formtNumberByLanguage(hoursFormated)} ${t("timeUnite.hrs")}`;
  }
  if (days < ftp) {
    return `${formtNumberByLanguage(days)} ${t("timeUnite.manDay")}`;
  }
  if (days >= ftp) {
    return `${formtNumberByLanguage(years)} ${t("timeUnite.fte")}`;
  }
  return `${formtNumberByLanguage(hoursFormated)} ${t("timeUnite.hrs")}`;
}
export function secondsToFTPTimeLabel(hours, md, ftp, t) {
  if (hours < md) {
    return secondsToFTPTime(hours, md, ftp, t);
  }
  return `${secondsToFTPTime(hours, md, ftp, t)} (${formtNumberByLanguage(hours)}${t("timeUnite.hrs")})`;
}

export const hoursToManDay = (time, manDayRate) => {
  if (time >= manDayRate) {
    return time / manDayRate;
  }
  return time;
};

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export const defaultTimeZone = () => "Europe/Paris";

export const formatDateTime = (date, language) => {
  const pattern = getDatePattern(language);
  return format(new Date(date), pattern);
};

export function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export const numberFloatFormat = (num) => {
  if (num === null || num === undefined) return "-";
  return parseFloat(num).toFixed(2) !== "0.00"
    ? formtNumberByLanguage((num && parseFloat(num).toFixed(2)) || 0)
    : "0";
};

export const rateFloatFormat = (num) => {
  if (num === null || num === undefined || isNaN(num)) return "--";
  return num
    ? formtNumberByLanguage((num && parseFloat(num).toFixed(2)) || 0)
    : "0";
};

export const numberRoiFloatFormat = (num) => {
  if (num === null || num === undefined || Number.isNaN(+num)) return "0";
  return num
    ? formtNumberByLanguage(
      (num && parseFloat(num > 0 ? num : -num).toFixed(2)) || 0,
    )
    : "0";
};

export const capitalize = (string) => string.charAt(0).toUpperCase() + string.slice(1);

export const round = (number, decimals) => {
  // return Math.round((number + Number.EPSILON) * Math.pow(10, decimals)) / Math.pow(10, decimals)
  decimals = number === 0 ? 0 : decimals;
  return number?.toFixed(decimals);
};

export const truncateString = (str, num) => {
  if (str.length <= num) {
    return str;
  }
  return `${str.slice(0, num)}...`;
};

export const REGEX = {
  NAME: /^[a-zA-ZÀ-ÿ'.[`~!@#$%^&*()_\-|+=÷¿?;:'",<>{}/\\\]\s]{0,25}$/,
  EMAIL: /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9-_][a-zA-Z0-9-_.]*\.[a-zA-Z]{2,}$/,
  TEL: /^\+?[0-9]{10,18}$/,
  LINKEDIN:
    /(ftp|http|https):\/\/?(?:www\.)?linkedin.com(\w+:{0,1}\w*@)?(\S+)(:([0-9])+)?(\/|\/([\w#!:.?+=&%@!-]))?/,
  FUNCTION: /.{0,150}/,
  AMOUNT: /^([0-9 ]{0,12}(\.,?[0-9]{0,2})?)$/,
  FAX: /^(\+?\d{1,}(\s?|-?)\d*(\s?|-?)\(?\d{2,}\)?(\s?|-?)\d{3,}\s?\d{3,})$/,
  // eslint-disable-next-line max-len
  IP: /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$|^(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))$|^$/,
  SIRET: /^\d{14}$/,
  SIREN: /^\d{9}$/,
  ALPHANUM: /^[a-zA-Z0-9]+$/,
};

export const getDate = (dateIn) => {
  const dt = new Date(dateIn);
  const pattern = "yyyy/MM/dd HH:mm:ss";
  return format(dt, pattern);
};

export const getLocalDate = (date) => {
  const dt = new Date(date);
  const pattern = "yyyy/MM/dd HH:mm:ss";
  return format(dt, pattern);
};
export const displayStatus = {
  IDLE: "idle",
  WORKING: "working",
  OFFLINE: "offline",
};
export const getStatusBGColor = (status) => {
  const statusColors = {
    [displayStatus.IDLE]: theme.custom.color.blueStatus,
    [displayStatus.WORKING]: theme.custom.color.greenStatus,
  }
  return statusColors[status?.toLowerCase()] || theme.custom.color.redStatus;
};
const checkImageExists = (url) => new Promise((resolve) => {
  const img = new Image();
  img.onload = () => resolve(true);
  img.onerror = () => resolve(false);
  img.src = url;
});
const getImageURL = (path, id) => {
  const imageUrl = `${getApiDomain()}api/orchestrator/tags/${id}/icon`
  return checkImageExists(imageUrl) ? imageUrl : null;
};
export const formatImagePath = (oldPath, id) => {
  let iconPath = null;
  try {
    // old process icons were on the server and had a /process/logo prefix
    let image = oldPath;
    if (image && image.includes("processes/logo/")) {
      image = image.replace("processes/logo/", "");
    }
    // eslint-disable-next-line global-require
    iconPath = require(`../pages/Services/components/resources/svg/${image}`);
  } catch (e) { /* empty */ }
  return iconPath || !id ? iconPath : getImageURL(oldPath, id);
};

export const getLocale = () => {
  const ln = localStorage.getItem("lgn");
  if (ln === "fr") {
    return frLocale;
  }
  if (ln === "es") {
    return esLocale;
  }
  return enUSLocale;
};

export const getInitiales = (name) => {
  if (!name) return "MP";

  const words = name.split(" ");

  const filteredWords = words.filter((word) => /^[A-Za-z]/.test(word));

  if (!filteredWords.length) return "MP";

  const firstLetter = filteredWords[0].charAt(0).toUpperCase();
  if (filteredWords.length === 1) return firstLetter;
  const lastLetter = filteredWords[filteredWords.length - 1].charAt(0).toUpperCase();

  return firstLetter + lastLetter;
};

export const arrIsNullEmpty = (arr) => !(Array.isArray(arr) && arr.length);

export const getRandomColorRGB = (id) => {
  const r = parseInt(
    ((Math.abs(id)
      .toString()
      .split("")
      .reduce((a, aa) => parseInt(a) + parseInt(aa), 0)
      % 10)
      * 256)
    / 10,
  );
  const g = parseInt(
    ((Math.abs(id - r)
      .toString()
      .split("")
      .reduce((a, aa) => parseInt(a) + parseInt(aa), 0)
      % 10)
      * 256)
    / 10,
  );
  const b = parseInt(
    ((Math.abs(id + r)
      .toString()
      .split("")
      .reduce((a, aa) => parseInt(a) + parseInt(aa), 0)
      % 10)
      * 256)
    / 10,
  );
  return `rgba(${r}, ${g}, ${b})`;
};

export const accumulate = (arr) => arr?.map(
  (
    // eslint-disable-next-line no-return-assign
    (sum) => (value) => (sum += value)
  )(0),
);

export const handleDisplayedLabel = (label, keepCaseLabel = false) => {
  if (label && keepCaseLabel) {
    return label;
  }
  // eslint-disable-next-line max-len, no-unsafe-optional-chaining
  return label ? label?.charAt(0).toUpperCase() + label?.slice(1).toLowerCase() : ""
};

export const isValidSiren = (sirenValue) => {
  if (!sirenValue || sirenValue?.length === 0) return true;
  if (sirenValue.length !== 9 || isNaN(sirenValue)) return false;
  const array = Array.from(sirenValue, (num) => Number(num)).map((e, index) => (index % 2 === 1 ? e * 2 : e * 1));
  const arrayFinal = array.map((e) => (e >= 10
    ? Array.from(e.toString(), (num) => Number(num)).reduce(
      (a, b) => a + b,
      0,
    )
    : e));
  const sum = arrayFinal.reduce((a, b) => a + b, 0);
  if (sum % 10 === 0) return true;
  return false;
};

export const isValidSiret = (siretValue) => {
  let isValid;
  if (!siretValue || siretValue?.length === 0) return true;
  if (siretValue.length !== 14 || isNaN(siretValue)) isValid = false;
  else {
    // le SIRET est un numérique à 14 chiffres
    // Les 9 premiers chiffres sont ceux du SIREN (ou RCS), les 4 suivants correspondent au numéro d'établissement
    // et enfin le dernier chiffre est une clef de LUHN.
    let somme = 0;
    let tmp;
    // eslint-disable-next-line no-plusplus
    for (let cpt = 0; cpt < siretValue.length; cpt++) {
      if (cpt % 2 === 0) {
        // Les positions impaires : 1er, 3è, 5è, etc...
        tmp = siretValue.charAt(cpt) * 2; // Position paire >> On le multiplie par 2
        if (tmp > 9) tmp -= 9; // Si le résultat est supérieur à 9, on lui soustrait 9
      } else tmp = siretValue.charAt(cpt); // Position impaire
      somme += parseInt(tmp);
    }
    if (somme % 10 === 0) isValid = true; // Si la somme est un multiple de 10 alors le SIRET est valide
    else isValid = false;
  }
  return isValid;
};

export const colors = [
  theme.custom.color.chartColor2,
  theme.custom.color.chartColor3,
  theme.palette.primary.main,
  theme.custom.color.chartColor1,
  theme.custom.color.chartColor4,
  "#7d4fff",
  "#FF8CC4",
  "#0047B2",
  "#B2C0FF",
  "#9bedff",
  "#ffd78a",
  "#2E8B57",
  "#9932CC",
  "#FF8C00",
  "#4B0082",
  "#20B2AA",
  "#FF1493",
  "#00BFFF",
  "#1E90FF",
  "#6A5ACD",
  "#FF4500",
  "#FFD700",
  "#ADFF2F",
  "#00FA9A",
  "#CD5C5C",
  "#9370DB",
  "#48D1CC",
  "#FF69B4",
  "#4682B4",
  "#2F4F4F",
  "#FA8072",
  "#FFE4E1",
  "#00CED1",
  "#FFA500",
  "#7B68EE",
  "#6B8E23",
  "#CD853F",
  "#008B8B",
  "#DC143C",
  "#9400D3",
  "#00FF7F",
  "#556B2F",
  "#E9967A",
  "#7FFFD4",
  "#8B008B",
  "#FF6347",
  "#40E0D0",
  "#D2691E",
  "#3CB371",
  "#6B8E23",
];

export const getRandomColor = (i) => (colors.length - 1 >= i ? colors[i] : colors[i % (colors.length - 1)])

export const getGraphLabels = (labels, t) => labels.map((label) => {
  let result = t(label);
  if (result === label) {
    let parts = result.split("hours ago");
    if (parts.length > 1) {
      result = t("hours ago").replace("#NB#", parts[0]);
    } else if (!result.includes("ago")) {
      parts = result.split(" ");
      if (parts.length > 1) {
        result = `${t(parts[0])} ${parts[1]}`;
      }
    }
  }
  return result;
});
const permissionsSubMenus = {
  divisions: "Divisions Module",
  srQueues: "SR Queues",
  tags: "Tags Module",
  userAdministration: "User Administration",
}

export const defaultPermissions = [
  {
    index: 0,
    name: "Smart Services",
    enabled: false,
    modules: [
      {
        index: 1,
        name: "Dashboard",
        enabled: false,
        actions: [],
      },
    ],
  },
  {
    index: 1,
    name: "Smart Robot",
    enabled: true,
    modules: [
      {
        index: 0,
        name: "Dashboard",
        enabled: true,
        actions: [],
      },
      {
        index: 14,
        name: "Monitoring Dashboard",
        enabled: true,
        actions: [
          { index: 1, name: "Business exceptions", enabled: false },
          { index: 2, name: "System exceptions", enabled: false },
          { index: 3, name: "All time stats", enabled: false },
          { index: 4, name: "FTEs saved", enabled: false },
        ],
      },
      {
        index: 1,
        name: "My Processes",
        enabled: true,
        actions: [
          { index: 1, name: "Start process", enabled: false },
          { index: 2, name: "Schedule process", enabled: false },
          { index: 3, name: "KPI parameters", enabled: false },
          { index: 5, name: "Edit process details", enabled: false },
          { index: 6, name: "Enable/Disable process", enabled: false },
          { index: 7, name: "SLA", enabled: false },
          { index: 8, name: "Process data mapping", enabled: false },
        ],
      },
      {
        index: 2,
        name: "Executions",
        label: "Executions",
        subMenu: "Executions",
        enabled: true,
        actions: [
          {
            index: 0,
            name: "Delete process exceptions",
            enabled: false,
          },
        ],
      },
      {
        index: 3,
        name: "Process Item",
        label: "Process Item",
        subMenu: "Process Item",
        enabled: true,
        actions: [
          {
            index: 1,
            name: "Retry item exceptions",
            enabled: false,
            parentOf: "Retry item exceptions with changed data",
          },
          { index: 2, name: "Workflow", enabled: true },
          {
            index: 3,
            name: "Retry item exceptions with changed data",
            enabled: false,
            dependsOn: "Retry item exceptions",
          },
        ],
      },
      {
        index: 4,
        name: "Analytics",
        enabled: true,
        actions: [],
      },
      {
        index: 5,
        name: "Resource Planner",
        enabled: false,
        actions: [{ index: 0, name: "Risk management", enabled: false }],
      },
      {
        index: 6,
        name: "Alerts Management",
        label: "Default Alerts",
        subMenu: "Default Alerts",
        enabled: true,
        actions: [
          { index: 0, name: "Add default alert", enabled: true },
          { index: 1, name: "Edit default alerts", enabled: true },
          { index: 2, name: "Delete default alert", enabled: false },
        ],
      },
      {
        index: 7,
        name: "Alerts Management",
        label: "Custom Alerts",
        subMenu: "Custom Alerts",
        enabled: true,
        actions: [
          { index: 0, name: "Add custom alert", enabled: true },
          { index: 1, name: "Edit custom alerts", enabled: true },
          { index: 2, name: "Delete custom alert", enabled: false },
        ],
      },
      {
        index: 8,
        name: "Process Assessment",
        enabled: true,
        actions: [
          { index: 1, name: "Add opportunity", enabled: true },
          { index: 2, name: "Accept / Reject / Reconsider", enabled: false },
          { index: 3, name: "Archive / Backlog", enabled: false },
          { index: 4, name: "Delete opportunity", enabled: false },
        ],
      },
      {
        index: 9,
        name: "Financial Parameters",
        label: "Financial Parameters",
        subMenu: "Financial Parameters",
        enabled: false,
        actions: [
          { index: 0, name: "Add cost", enabled: false },
          { index: 1, name: "Edit cost", enabled: false },
          { index: 2, name: "Delete cost", enabled: false },
        ],
      },
      ...!isPartitioningDisabled ? [
            {
              index: 10,
              name: "Divisions Module",
              label: "Divisions Module",
              subMenu: permissionsSubMenus.divisions,
              enabled: false,
              actions: [
                { index: 0, name: "Add division", enabled: false },
                { index: 1, name: "Edit division", enabled: false },
                { index: 2, name: "Delete division", enabled: false },
              ],
            },
          ] : [],
      {
        index: 11,
        name: "Tags Module",
        label: "Tags Module",
        subMenu: permissionsSubMenus.tags,
        enabled: true,
        actions: [
          { index: 0, name: "Add tag", enabled: true },
          { index: 1, name: "Edit tag", enabled: false },
          { index: 2, name: "Delete tag", enabled: false },
          { index: 3, name: "Archive", enabled: false },
        ],
      },
      {
        index: 12,
        name: "Administration",
        label: "User Administration",
        subMenu: permissionsSubMenus.userAdministration,
        enabled: false,
        actions: [
          { index: 1, name: "Add user", enabled: false },
          { index: 2, name: "Edit user", enabled: false },
          { index: 3, name: "Delete user", enabled: false },
          { index: 4, name: "User Permissions", enabled: false },
        ],
      },
      ...!isSRQueuesDisabled ? [
        {
          index: 13,
          name: "SR Queues",
          label: "SR Queues",
          subMenu: permissionsSubMenus.srQueues,
          enabled: false,
          actions: [
            { index: 1, name: "Add SR Queue", enabled: false },
            { index: 2, name: "Edit SR Queue", enabled: false },
            { index: 3, name: "Delete SR Queue", enabled: false },
          ],
        },
      ] : [],
    ],
  },
];
export const defaultCOEPermissions = [
  {
    index: 0,
    name: "Smart Services",
    enabled: false,
    modules: [
      {
        index: 1,
        name: "Dashboard",
        enabled: false,
        actions: [],
      },
    ],
  },
  {
    index: 1,
    name: "Smart Robot",
    enabled: true,
    modules: [
      {
        index: 0,
        name: "Dashboard",
        enabled: true,
        actions: [],
      },
      {
        index: 14,
        name: "Monitoring Dashboard",
        enabled: true,
        actions: [
          { index: 1, name: "Business exceptions", enabled: false },
          { index: 2, name: "System exceptions", enabled: false },
          { index: 3, name: "All time stats", enabled: false },
          { index: 4, name: "FTEs saved", enabled: false },
        ],
      },
      {
        index: 1,
        name: "My Processes",
        enabled: true,
        actions: [
          { index: 1, name: "Start process", enabled: true },
          { index: 2, name: "Schedule process", enabled: true },
          { index: 3, name: "KPI parameters", enabled: true },
          { index: 5, name: "Edit process details", enabled: true },
          { index: 6, name: "Enable/Disable process", enabled: true },
          { index: 7, name: "SLA", enabled: true },
          { index: 8, name: "Process data mapping", enabled: true },
        ],
      },
      {
        index: 2,
        name: "Executions",
        label: "Executions",
        subMenu: "Executions",
        enabled: true,
        actions: [
          {
            index: 0,
            name: "Delete process exceptions",
            enabled: true,
          },
        ],
      },
      {
        index: 3,
        name: "Process Item",
        label: "Process Item",
        subMenu: "Process Item",
        enabled: true,
        actions: [
          {
            index: 1,
            name: "Retry item exceptions",
            enabled: true,
            parentOf: "Retry item exceptions with changed data",
          },
          { index: 2, name: "Workflow", enabled: true },
          {
            index: 3,
            name: "Retry item exceptions with changed data",
            enabled: false,
            dependsOn: "Retry item exceptions",
          },
        ],
      },
      {
        index: 4,
        name: "Analytics",
        enabled: true,
        actions: [],
      },
      {
        index: 5,
        name: "Resource Planner",
        enabled: true,
        actions: [{ index: 0, name: "Risk management", enabled: true }],
      },
      {
        index: 6,
        name: "Alerts Management",
        label: "Default Alerts",
        subMenu: "Default Alerts",
        enabled: true,
        actions: [
          { index: 0, name: "Add default alert", enabled: true },
          { index: 1, name: "Edit default alerts", enabled: true },
          { index: 2, name: "Delete default alert", enabled: true },
        ],
      },
      {
        index: 7,
        name: "Alerts Management",
        label: "Custom Alerts",
        subMenu: "Custom Alerts",
        enabled: true,
        actions: [
          { index: 0, name: "Add custom alert", enabled: true },
          { index: 1, name: "Edit custom alerts", enabled: true },
          { index: 2, name: "Delete custom alert", enabled: true },
        ],
      },
      {
        index: 8,
        name: "Process Assessment",
        enabled: true,
        actions: [
          { index: 1, name: "Add opportunity", enabled: true },
          { index: 2, name: "Accept / Reject / Reconsider", enabled: true },
          { index: 3, name: "Archive / Backlog", enabled: true },
          { index: 4, name: "Delete opportunity", enabled: true },
        ],
      },
      {
        index: 9,
        name: "Financial Parameters",
        label: "Financial Parameters",
        subMenu: "Financial Parameters",
        enabled: true,
        actions: [
          { index: 0, name: "Add cost", enabled: true },
          { index: 1, name: "Edit cost", enabled: true },
          { index: 2, name: "Delete cost", enabled: true },
        ],
      },
      ...!isPartitioningDisabled ? [
            {
              index: 10,
              name: "Divisions Module",
              label: "Divisions Module",
              subMenu: permissionsSubMenus.divisions,
              enabled: false,
              actions: [
                { index: 0, name: "Add division", enabled: false },
                { index: 1, name: "Edit division", enabled: false },
                { index: 2, name: "Delete division", enabled: false },
              ],
            },
          ] : [],
      {
        index: 11,
        name: "Tags Module",
        label: "Tags Module",
        subMenu: permissionsSubMenus.tags,
        enabled: true,
        actions: [
          { index: 0, name: "Add tag", enabled: true },
          { index: 1, name: "Edit tag", enabled: true },
          { index: 2, name: "Delete tag", enabled: true },
          { index: 3, name: "Archive", enabled: true },
        ],
      },
      {
        index: 12,
        name: "Administration",
        label: "User Administration",
        subMenu: permissionsSubMenus.userAdministration,
        enabled: true,
        actions: [
          { index: 1, name: "Add user", enabled: false },
          { index: 2, name: "Edit user", enabled: false },
          { index: 3, name: "Delete user", enabled: false },
          { index: 4, name: "User Permissions", enabled: false },
        ],
      },
      ...!isSRQueuesDisabled ? [
        {
          index: 13,
          name: "SR Queues",
          label: "SR Queues",
          subMenu: permissionsSubMenus.srQueues,
          enabled: true,
          actions: [
            { index: 1, name: "Add SR Queue", enabled: true },
            { index: 2, name: "Edit SR Queue", enabled: true },
            { index: 3, name: "Delete SR Queue", enabled: true },
          ],
        },
      ] : [],
    ],
  },
];
export const defaultSMEPermissions = [
  {
    index: 0,
    name: "Smart Services",
    enabled: false,
    modules: [
      {
        index: 1,
        name: "Dashboard",
        enabled: false,
        actions: [],
      },
    ],
  },
  {
    index: 1,
    name: "Smart Robot",
    enabled: true,
    modules: [
      {
        index: 0,
        name: "Dashboard",
        enabled: false,
        actions: [],
      },
      {
        index: 14,
        name: "Monitoring Dashboard",
        enabled: true,
        actions: [
          { index: 1, name: "Business exceptions", enabled: false },
          { index: 2, name: "System exceptions", enabled: false },
          { index: 3, name: "All time stats", enabled: false },
          { index: 4, name: "FTEs saved", enabled: false },
        ],
      },
      {
        index: 1,
        name: "My Processes",
        enabled: true,
        actions: [
          { index: 1, name: "Start process", enabled: false },
          { index: 2, name: "Schedule process", enabled: false },
          { index: 3, name: "KPI parameters", enabled: false },
          { index: 5, name: "Edit process details", enabled: false },
          { index: 6, name: "Enable/Disable process", enabled: false },
          { index: 7, name: "SLA", enabled: false },
          { index: 8, name: "Process data mapping", enabled: false },
        ],
      },
      {
        index: 2,
        name: "Executions",
        label: "Executions",
        subMenu: "Executions",
        enabled: true,
        actions: [
          {
            index: 0,
            name: "Delete process exceptions",
            enabled: true,
          },
        ],
      },
      {
        index: 3,
        name: "Process Item",
        label: "Process Item",
        subMenu: "Process Item",
        enabled: true,
        actions: [
          {
            index: 1,
            name: "Retry item exceptions",
            enabled: true,
            parentOf: "Retry item exceptions with changed data",
          },
          { index: 2, name: "Workflow", enabled: true },
          {
            index: 3,
            name: "Retry item exceptions with changed data",
            enabled: false,
            dependsOn: "Retry item exceptions",
          },
        ],
      },
      {
        index: 4,
        name: "Analytics",
        enabled: true,
        actions: [],
      },
      {
        index: 5,
        name: "Resource Planner",
        enabled: false,
        actions: [{ index: 0, name: "Risk management", enabled: false }],
      },
      {
        index: 6,
        name: "Alerts Management",
        label: "Default Alerts",
        subMenu: "Default Alerts",
        enabled: true,
        actions: [
          { index: 0, name: "Add default alert", enabled: true },
          { index: 1, name: "Edit default alerts", enabled: true },
          { index: 2, name: "Delete default alert", enabled: false },
        ],
      },
      {
        index: 7,
        name: "Alerts Management",
        label: "Custom Alerts",
        subMenu: "Custom Alerts",
        enabled: true,
        actions: [
          { index: 0, name: "Add custom alert", enabled: true },
          { index: 1, name: "Edit custom alerts", enabled: true },
          { index: 2, name: "Delete custom alert", enabled: false },
        ],
      },
      {
        index: 8,
        name: "Process Assessment",
        enabled: true,
        actions: [
          { index: 1, name: "Add opportunity", enabled: true },
          { index: 2, name: "Accept / Reject / Reconsider", enabled: false },
          { index: 3, name: "Archive / Backlog", enabled: false },
          { index: 4, name: "Delete opportunity", enabled: false },
        ],
      },
      {
        index: 9,
        name: "Financial Parameters",
        label: "Financial Parameters",
        subMenu: "Financial Parameters",
        enabled: false,
        actions: [
          { index: 0, name: "Add cost", enabled: false },
          { index: 1, name: "Edit cost", enabled: false },
          { index: 2, name: "Delete cost", enabled: false },
        ],
      },
      ...!isPartitioningDisabled ? [
            {
              index: 10,
              name: "Divisions Module",
              label: "Divisions Module",
              subMenu: permissionsSubMenus.divisions,
              enabled: false,
              actions: [
                { index: 0, name: "Add division", enabled: false },
                { index: 1, name: "Edit division", enabled: false },
                { index: 2, name: "Delete division", enabled: false },
              ],
            },
          ] : [],
      {
        index: 11,
        name: "Tags Module",
        label: "Tags Module",
        subMenu: permissionsSubMenus.tags,
        enabled: true,
        actions: [
          { index: 0, name: "Add tag", enabled: true },
          { index: 1, name: "Edit tag", enabled: true },
          { index: 2, name: "Delete tag", enabled: false },
          { index: 3, name: "Archive", enabled: false },
        ],
      },
      {
        index: 12,
        name: "Administration",
        label: "User Administration",
        subMenu: permissionsSubMenus.userAdministration,
        enabled: false,
        actions: [
          { index: 1, name: "Add user", enabled: false },
          { index: 2, name: "Edit user", enabled: false },
          { index: 3, name: "Delete user", enabled: false },
          { index: 4, name: "User Permissions", enabled: false },
        ],
      },
      ...!isSRQueuesDisabled ? [
        {
          index: 13,
          name: "SR Queues",
          label: "SR Queues",
          subMenu: permissionsSubMenus.srQueues,
          enabled: false,
          actions: [
            { index: 1, name: "Add SR Queue", enabled: false },
            { index: 2, name: "Edit SR Queue", enabled: false },
            { index: 3, name: "Delete SR Queue", enabled: false },
          ],
        },
      ] : [],
    ],
  },
];
export const defaultMMGTPermissions = [
  {
    index: 0,
    name: "Smart Services",
    enabled: false,
    modules: [
      {
        index: 1,
        name: "Dashboard",
        enabled: false,
        actions: [],
      },
    ],
  },
  {
    index: 1,
    name: "Smart Robot",
    enabled: true,
    modules: [
      {
        index: 0,
        name: "Dashboard",
        enabled: false,
        actions: [],
      },
      {
        index: 14,
        name: "Monitoring Dashboard",
        enabled: true,
        actions: [
          { index: 1, name: "Business exceptions", enabled: false },
          { index: 2, name: "System exceptions", enabled: false },
          { index: 3, name: "All time stats", enabled: false },
          { index: 4, name: "FTEs saved", enabled: false },
        ],
      },
      {
        index: 1,
        name: "My Processes",
        enabled: true,
        actions: [
          { index: 1, name: "Start process", enabled: false },
          { index: 2, name: "Schedule process", enabled: false },
          { index: 3, name: "KPI parameters", enabled: false },
          { index: 5, name: "Edit process details", enabled: false },
          { index: 6, name: "Enable/Disable process", enabled: false },
          { index: 7, name: "SLA", enabled: false },
          { index: 8, name: "Process data mapping", enabled: false },
        ],
      },
      {
        index: 2,
        name: "Executions",
        label: "Executions",
        subMenu: "Executions",
        enabled: true,
        actions: [
          {
            index: 0,
            name: "Delete process exceptions",
            enabled: false,
          },
        ],
      },
      {
        index: 3,
        name: "Process Item",
        label: "Process Item",
        subMenu: "Process Item",
        enabled: true,
        actions: [
          {
            index: 1,
            name: "Retry item exceptions",
            enabled: false,
            parentOf: "Retry item exceptions with changed data",
          },
          { index: 2, name: "Workflow", enabled: false },
          {
            index: 3,
            name: "Retry item exceptions with changed data",
            enabled: false,
            dependsOn: "Retry item exceptions",
          },
        ],
      },
      {
        index: 4,
        name: "Analytics",
        enabled: true,
        actions: [],
      },
      {
        index: 5,
        name: "Resource Planner",
        enabled: false,
        actions: [{ index: 0, name: "Risk management", enabled: false }],
      },
      {
        index: 6,
        name: "Alerts Management",
        label: "Default Alerts",
        subMenu: "Default Alerts",
        enabled: true,
        actions: [
          { index: 0, name: "Add default alert", enabled: true },
          { index: 1, name: "Edit default alerts", enabled: true },
          { index: 2, name: "Delete default alert", enabled: false },
        ],
      },
      {
        index: 7,
        name: "Alerts Management",
        label: "Custom Alerts",
        subMenu: "Custom Alerts",
        enabled: true,
        actions: [
          { index: 0, name: "Add custom alert", enabled: true },
          { index: 1, name: "Edit custom alerts", enabled: true },
          { index: 2, name: "Delete custom alert", enabled: false },
        ],
      },
      {
        index: 8,
        name: "Process Assessment",
        enabled: true,
        actions: [
          { index: 1, name: "Add opportunity", enabled: true },
          { index: 2, name: "Accept / Reject / Reconsider", enabled: false },
          { index: 3, name: "Archive / Backlog", enabled: false },
          { index: 4, name: "Delete opportunity", enabled: false },
        ],
      },
      {
        index: 9,
        name: "Financial Parameters",
        label: "Financial Parameters",
        subMenu: "Financial Parameters",
        enabled: true,
        actions: [
          { index: 0, name: "Add cost", enabled: false },
          { index: 1, name: "Edit cost", enabled: false },
          { index: 2, name: "Delete cost", enabled: false },
        ],
      },
      ...!isPartitioningDisabled ? [
            {
              index: 10,
              name: "Divisions Module",
              label: "Divisions Module",
              subMenu: permissionsSubMenus.divisions,
              enabled: false,
              actions: [
                { index: 0, name: "Add division", enabled: false },
                { index: 1, name: "Edit division", enabled: false },
                { index: 2, name: "Delete division", enabled: false },
              ],
            },
          ] : [],
      {
        index: 11,
        name: "Tags Module",
        label: "Tags Module",
        subMenu: permissionsSubMenus.tags,
        enabled: true,
        actions: [
          { index: 0, name: "Add tag", enabled: true },
          { index: 1, name: "Edit tag", enabled: false },
          { index: 2, name: "Delete tag", enabled: false },
          { index: 3, name: "Archive", enabled: false },
        ],
      },
      {
        index: 12,
        name: "Administration",
        label: "User Administration",
        subMenu: permissionsSubMenus.userAdministration,
        enabled: false,
        actions: [
          { index: 1, name: "Add user", enabled: false },
          { index: 2, name: "Edit user", enabled: false },
          { index: 3, name: "Delete user", enabled: false },
          { index: 4, name: "User Permissions", enabled: false },
        ],
      },
      ...!isSRQueuesDisabled ? [
        {
          index: 13,
          name: "SR Queues",
          label: "SR Queues",
          subMenu: permissionsSubMenus.srQueues,
          enabled: false,
          actions: [
            { index: 1, name: "Add SR Queue", enabled: false },
            { index: 2, name: "Edit SR Queue", enabled: false },
            { index: 3, name: "Delete SR Queue", enabled: false },
          ],
        },
      ] : [],
    ],
  },
];

export const resolveAdminPermissions = (permissions) => {
  const permissionsCopy = [...permissions];
  const { modules } = permissionsCopy[1];
  const targetModules = [permissionsSubMenus.srQueues, permissionsSubMenus.tags, permissionsSubMenus.divisions, permissionsSubMenus.userAdministration];
  targetModules.forEach((target) => {
    const moduleIndex = modules.findIndex((item) => item.subMenu === target);
    if (moduleIndex > -1)
    {
      modules[moduleIndex].enabled = true;
      modules[moduleIndex].actions = modules[moduleIndex].actions.map((obj) => ({ ...obj, enabled: true }));
    }
  })
  return permissionsCopy;
}
export const selectDurationValue = (
  selectedDuration,
  setToDate,
  setshowCustomDate,
  setFromDate,
  // eslint-disable-next-line default-param-last
  handleChangeDates = null,
  dateFormat,
) => {
  if (selectedDuration !== "CUSTOM") {
    const now = new Date();
    let toDate = now;
    let fromDate;
    switch (selectedDuration) {
      case Duration.DAY:
        fromDate = toDate;
        break;
      case Duration.WEEK:
        fromDate = subWeeks(now, 1);
        fromDate.setDate(fromDate.getDate() + 1);
        break;
      case Duration.MONTH:
        fromDate = subMonths(now, 1);
        fromDate.setDate(fromDate.getDate() + 1);
        break;
      case Duration.YEAR:
        fromDate = subYears(now, 1);
        fromDate.setDate(fromDate.getDate());
        break;
      case Duration.ALL_TIME:
        fromDate = null;
        toDate = null;
        break;
      default:
        break;
    }
    toDate = toDate
      ? format(toDate.setHours(23, 59, 59, 999), dateFormat)
      : null;
    fromDate = fromDate
      ? format(fromDate.setHours(0, 0, 0, 0), dateFormat)
      : null;
    setToDate(toDate);
    setFromDate(fromDate);
    setshowCustomDate(false);
    if (handleChangeDates) {
      handleChangeDates(fromDate, toDate, selectedDuration);
    }
  } else {
    const now = new Date();
    setToDate(format(now, dateFormat));
    setshowCustomDate(true);
    setFromDate(format(now, dateFormat));
    if (handleChangeDates) {
      handleChangeDates(now, now, selectedDuration);
    }
  }
};

export const bePeriods = [
  { label: "fleet.management.be.period.1", days: 30 },
  { label: "fleet.management.be.period.2", days: 90 },
  { label: "fleet.management.be.period.3", days: 180 },
  { label: "fleet.management.be.period.4", days: 360 },
];

export const onExportSuccess = (response, exportName) => {
  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(new Blob([response.data]));
  const disposition = response.headers["content-disposition"];
  if (disposition && disposition.indexOf("attachment") !== -1) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(disposition);
    if (matches !== null && matches[1]) {
      const filename = matches[1].replace(/['"]/g, "");
      link.setAttribute("download", filename);
    } else {
      link.setAttribute("download", exportName);
    }
  }
  document.body.appendChild(link);
  link.click();
  link.remove();
  return response;
}
export const formatDateParam = (dateString, pattern) => {
  if (dateString) {
    if (pattern) return format(new Date(dateString), pattern);
    return format(new Date(dateString), "yyyy-MM-dd");
  }
  return null;
};

export const formatDateTimeIsoParam = (dateString) => {
  if (dateString) {
    return format(new Date(dateString), "yyyy-MM-dd'T'HH:mm:ss");
  }
  return null;
};

export const abbreviateNumber = (number) => {
  if (number >= 1000000) {
    return `${(number / 1000000)?.toFixed(1)?.replace(/\.0$/, "")}M`;
  } if (number >= 1000) {
    return `${(number / 1000)?.toFixed(1)?.replace(/\.0$/, "")}k`;
  }
    return Number?.isInteger(+number) ? number?.toString() : number?.toFixed(2);
};

export const minimizeString = (word, length = 20) => truncate(word, {
  length,
  omission: "..."
});

export const formatFromTo = (date, from, to) => moment(date, from).format(to)
export const getApiDomain = () => process.env.REACT_APP_DOMAIN;
export const defaultBePeriods = 30;
export const fetchAllProcesses = true;
export const CustomScrollBar = (
    elementMaxHeight,
    scrollColor = "#D9D9D9",
    scrollWidth = "0.8em",
    trackColor = "rgba(0, 0, 0, 0.0)"
) => ({
  maxHeight: elementMaxHeight,
  overflowY: "auto !important",
  mt: 1,
  "&::-webkit-scrollbar": { width: scrollWidth },
  "&::-webkit-scrollbar-track": {
    "-webkit-box-shadow": `inset 0 0 6px ${trackColor}`,
  },
  "&::-webkit-scrollbar-thumb": {
    outline: "none",
    backgroundColor: scrollColor,
  },
});
const currencyList = [
  { code: "EUR", label: "EUR", symbole: "€" },
  { code: "USD", label: "USD", symbole: "$" },
  { code: "PLN", label: "PLN", symbole: "zł" },
  { code: "GBP", label: "GBP", symbole: "£" },
  { code: "JPY", label: "JPY", symbole: "¥" },
  { code: "CAD", label: "CAD", symbole: "C$" },
  { code: "SAR", label: "SAR", symbole: "SAR" },
];

function findCurrency(code) {
  return currencyList.reduce((result, item) => (item.code === code ? item : result), null);
}

export const getCurrencySymbole = (fleet, isLabel = false) => {
  const currency = fleet?.currency;
  const objectIndex = isLabel ? "label" : "symbole";

  if (!currency && isEnvParamEnabled("REACT_APP_DISPLAY_RIYAD_BANK_LOGO")) return findCurrency("SAR")[objectIndex];
  if (fleet?.companyName?.toLowerCase()?.includes("lussier")) return findCurrency("CAD")[objectIndex];

  const selectedCurrency = findCurrency(currency) || findCurrency("EUR");
  return selectedCurrency[objectIndex];
};

export const isEnvParamEnabled = (param) => process.env?.[param] === "true";

export * from "./helpers/date.helpers";
export * from "./helpers/number.helpers";
export * from "./helpers/user.helpers";
export * from "./helpers/qualification.helpers"
