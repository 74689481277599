import {
  CardContent,
  CardHeader,
  Grid,
  List,
  ListItem,
  Tooltip,
  Typography,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { HorizontalBar } from "react-chartjs-2";
import { colors } from "util";
import { LINE_CHART_TOOLTIP_OPTIONS } from "pages/newDashboard/dashboardUtils";

function ExceptionsReasons({
  classes, data, t, width, height, legendOpts, formatExceptionGraphNumbers, getExceptionPercentage,
}) {
  const [kpi, setKpi] = useState({});
  const [legend, setLegend] = useState();

  const formatData = () => (data
    ? {
      labels: data.labels,
      datasets: [
        {
          data: data?.values,
          backgroundColor: colors,
          barThickness: 20,
          tooltipCaretSize: 0,
        },
      ],
    }
    : {});

  const buildDatasets = () => {
    setKpi(formatData());
  };

  useEffect(() => {
    buildDatasets(data);
  }, [data]);

  const onRefChange = React.useCallback((node) => {
    if (node !== null) {
      setLegend(node.chartInstance.generateLegend());
    }
  }, [kpi]);

  return (
    <div className={classes.container}>
      <CardHeader
        title={t("exception.analytics.by.reason")}
        titleTypographyProps={{
          variant: "h3",
          style: { fontWeight: "bold" },
        }}
        classes={{
          action: classes.select,
        }}
      />
      <CardContent className={classes.cardContent}>
        <Grid
          item
          container
          alignItems="center"
          justify="center"
          direction="column"
          spacing={1}
          style={{ height: "calc(100%)" }}
        >
          <Grid item xs={12} className={classes.graphContainer}>
            <HorizontalBar
              ref={onRefChange}
              width={width}
              height={height}
              data={kpi}
              options={{
                layout: {
                  padding: {
                    bottom: "30",
                  },
                },
                plugins: {
                  legend: {
                    labels: {
                      maxWidth: 120
                    }
                  }
                },
                tooltips: {
                  callbacks: {
                    label(tooltipItem) {
                      return `${formatExceptionGraphNumbers(tooltipItem, kpi)} - ${getExceptionPercentage(tooltipItem, kpi)}`;
                    },
                    title: () => "",
                  },
                  intersect: false,
                  ...LINE_CHART_TOOLTIP_OPTIONS
                },
                legend: legendOpts,
                legendCallback: () => (
                  <List className={classes.chartLegend}>
                    {kpi?.labels
                        && kpi?.labels.map((label, index) => (
                          <ListItem
                            key={`legend-item-exception-reasons-${index}`}
                            className={classes.noPadding}
                          >
                            <div>
                              <span
                                style={{
                                  backgroundColor:
                                    kpi.datasets[0].backgroundColor[
                                      index
                                    ],
                                  width: "10px",
                                  height: "10px",
                                  borderRadius: "50%",
                                  position: "absolute",
                                  left: 0,
                                  top: 0,
                                }}
                              />
                              {" "}
                              &nbsp;&nbsp;
                              <Tooltip title={label} arrow placement="top">
                                <Typography
                                  component="span"
                                  className={classes.chartItemLegendItem}
                                  style={{
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                    maxWidth: "100%",
                                    position: "absolute",
                                    left: 20,
                                    top: "-4px",
                                  }}
                                >
                                  {t(label)}
                                </Typography>
                              </Tooltip>
                            </div>
                          </ListItem>
                        ))}
                  </List>
                  ),
                scales: {
                  xAxes: [
                    {
                      gridLines: {
                        display: false,
                      },
                      display: true,
                      stacked: true,
                    },
                  ],
                  yAxes: [
                    {
                      gridLines: {
                        display: false,
                      },
                      display: false,
                      stacked: true,
                    },
                  ],
                },
              }}
            />
            <div className={classes.legendContainer}>{legend}</div>
          </Grid>
        </Grid>
      </CardContent>
    </div>
  );
}

export default ExceptionsReasons;
