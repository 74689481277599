import React from "react";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import AssessmentOutlinedIcon from "@material-ui/icons/AssessmentOutlined";
import AccountTreeOutlinedIcon from "@material-ui/icons/AccountTreeOutlined";
import DonutLargeOutlinedIcon from "@material-ui/icons/DonutLargeOutlined";
import CheckBoxOutlinedIcon from "@material-ui/icons/CheckBoxOutlined";
import DashboardOutlinedIcon from "@material-ui/icons/DashboardOutlined";
import EqualizerRoundedIcon from "@material-ui/icons/EqualizerRounded";
import MoneyOutlinedIcon from "@material-ui/icons/MoneyOutlined";
import CalendarTodayOutlined from "@material-ui/icons/CalendarTodayOutlined";
import ListAlt from "@material-ui/icons/ListAlt";
import NotificationsNone from "@material-ui/icons/NotificationsNone";
import SupervisorAccountOutlinedIcon from "@material-ui/icons/SupervisorAccountOutlined";
import Services from "../pages/Services";
import ProcessAssessment from "../pages/ProcessAssessment";
import Reporting from "../pages/Reporting";
import AlertsManagement from "../pages/AlertsManagement";
import CalendarComponent from "../pages/Calendar";
import FinancialParameters from "../pages/Financial";
import UsersAdministration from "../pages/Administration/UsersAdministration";
import RobotsAdministration from "../pages/Administration/RobotsAdministration";
import ProcessExecutionPage from "pages/ProcessExecutionPage";
import ProcessItemPage from "pages/ProcessItemPage";
import NewDashboard from "../pages/newDashboard";
import Dashboard from "../pages/Dashboard";
import { ROLES } from "../util/constants";
import { isEnvParamEnabled } from "util/index";

const menus = [
  {
    name: "Monitoring Dashboard",
    path: "/monitoring-dashboard",
    component: () => <NewDashboard />,
    icon: DashboardOutlinedIcon,
    isHidden: !isEnvParamEnabled("REACT_APP_ENABLE_MONITORING_DASHBOARD"),
  },
  {
    name: "Dashboard",
    path: "/performance-dashboard",
    component: () => <Dashboard />,
    icon: EqualizerRoundedIcon,
  },
  {
    name: "My Processes",
    path: "/myProcesses",
    component: () => <Services />,
    icon: DonutLargeOutlinedIcon,
  },
  {
    name: "Executions",
    path: "/executions",
    component: () => <ProcessExecutionPage />,
    icon: ListAlt,
  },
  {
    name: "Process Item",
    path: "/items/itemsExceptions",
    component: () => <ProcessItemPage />,
    icon: CheckBoxOutlinedIcon,
  },
  {
    name: "Analytics",
    path: "/analytics",
    component: () => <Reporting />,
    icon: AssessmentOutlinedIcon,
  },
  {
    name: "Resource Planner",
    path: "/resourcePlanner",
    component: () => <CalendarComponent />,
    icon: CalendarTodayOutlined,
  },
  {
    name: "Alerts Management",
    path: "/alertsManagement",
    component: () => <AlertsManagement />,
    icon: NotificationsNone,
  },
  {
    name: "Process Assessment",
    path: "/processAssessment",
    component: () => <ProcessAssessment />,
    icon: AccountTreeOutlinedIcon,
  },
  {
    name: "Financial Parameters",
    path: "/Financial",
    component: () => <FinancialParameters />,
    icon: MoneyOutlinedIcon,
  },
  {
    name: "User Administration",
    permissionKey: "Administration",
    path: "/userAdministration",
    component: () => <UsersAdministration />,
    icon: SupervisorAccountOutlinedIcon,
    moduleEnabled: ["User Administration"],
    isAdmin: ROLES.admin
  },
  {
    name: "Robot Administration",
    path: "/robotAdministration",
    component: () => <RobotsAdministration />,
    icon: SettingsOutlinedIcon,
    moduleEnabled: ["SR Queues", "Tags Module"],
    isAdmin: ROLES.admin
  },
];

export default menus;
