import React from "react";

import { get } from "lodash";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";
import Alert from "@material-ui/lab/Alert";
import { useSelector } from "react-redux";
import StatusLegend from "components/StatusLegend";
import { formatDateByLanguage, isFleetAdministrator } from "../../../util";
import AdminTable from "../AdminTable";
import {
  enableOrDisableFleet,
  fetchFleets,
  deleteFleet,
  deleteFleets,
} from "../../../redux/actions/services/index";
import { FETCH_FLEETS } from "../../../redux/constants/index";
import PageHeader from "../../../components/PageHeader";
import useStyles from "./style.js";

const headerCells = {
  fields: [
    {
      id: "status",
      gridSize: 1,
      align: "center",
      label: "fleet.management.status",
    },
    {
      id: "logo",
      gridSize: 1,
      align: "center",
      label: "",
    },
    {
      id: "companyName",
      gridSize: 2,
      align: "left",
      label: "fleet.management.companyName",
    },
    {
      id: "email",
      gridSize: 3,
      align: "left",
      label: "fleet.management.email",
    },
    {
      id: "clientSince",
      gridSize: 2,
      align: "left",
      label: "fleet.management.clientSince",
    },
  ],
};

const displayFields = [
  {
    id: "status",
    gridSize: 1,
    align: "center",
    valuePath: "status",
    isStatus: true,
    translate: false,
  },
  {
    id: "logo",
    gridSize: 1,
    align: "center",
    valuePath: "companyName",
    avatarPath: "logo",
    isAvatar: true,
  },
  {
    id: "companyName",
    gridSize: 2,
    align: "left",
    valuePath: "companyName",
  },
  {
    id: "email",
    gridSize: 3,
    align: "left",
    valuePath: "email",
  },

  {
    id: "clientSince",
    gridSize: 2,
    align: "left",
    valuePath: "clientSince",
    defaultValue: "--:--:--",
    translate: false,
    format: (v) => formatDateByLanguage(v, false),
  },
];

const statusList = [
  { code: "ACTIVE", label: "ACTIVE" },
  { code: "DISABLED", label: "DISABLED" },
];

const sortByList = [
  { code: "companyName", label: "fleet.management.companyName" },
  { code: "email", label: "fleet.management.email" },
];

export default function FleetManagement() {
  const currentUser = useSelector(({ requests }) => get(requests, "queries.FETCH_CURRENT_USER.data"));

  const entityBaseUrl = "/userAdministration/groups";
  const enableDisableEntity = enableOrDisableFleet;
  const fetchEntities = fetchFleets;
  const deleteEntity = deleteFleet;
  const deleteEntities = deleteFleets;
  const entityQuery = FETCH_FLEETS;
  const searchField = "searchCriteria";
  const classes = useStyles();
  const { t } = useTranslation();

  const labels = {
    title: "fleet.management.title",
    addButton: "fleet.management.add.button",
    deleteEntity: (
      <div>
        <Alert severity="error">{t("fleet.management.delete.warning")}</Alert>
        <p>{t("fleet.management.delete.confirmMsg")}</p>
      </div>
    ),
    deleteEntities: "fleet.management.deleteFleetsActionMessage",
    enableEntity: "fleet.management.enaDes.confirmMsg",
    enableConfirm: "fleet.management.enaDes.button.confirm",
    disableEntity: "fleet.management.desaDes.confirmMsg",
    disableConfirm: "fleet.management.desaDes.button.confirm",
    searchPlaceHolder: "Search",
    entityDeleted: "fleet deleted successfully",
    confirmAction: "Delete",
    deleteAll: "tooltip.action.delete",
  };
  const statues = ["ACTIVE", "DISABLE"]

  return (
    <div className={classes.root}>
      <div className={classes.paper}>
        <PageHeader title={labels.title} />
        <Grid container>
          <Grid container item xs justify="center">
            <StatusLegend statues={statues} />
          </Grid>
        </Grid>
        <AdminTable
          statusList={statusList}
          sortByList={sortByList}
          displayFields={displayFields}
          headerCells={headerCells}
          entityBaseUrl={entityBaseUrl}
          enableDisableEntity={enableDisableEntity}
          fetchEntities={fetchEntities}
          deleteEntity={deleteEntity}
          deleteEntities={deleteEntities}
          entityQuery={entityQuery}
          labels={labels}
          searchField={searchField}
          isFleetAdministrationPage
          showAddIcon={isFleetAdministrator(currentUser)}
          addButtonText={t(labels.addButton)}
        />
      </div>
    </div>
  );
}
