import React, { useEffect } from "react";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import SaveIcon from "@material-ui/icons/Save";
import CancelIcon from "@material-ui/icons/Cancel";
import EditIcon from "@material-ui/icons/Edit";
import { useForm } from "react-hook-form";
import { connect, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import get from "lodash/get";
import {
  fetchProcesses,
  updateprocessdescription,
} from "../../../../redux/actions/services";
import {
  HasPermission,
  isPermitted,
} from "../../../../components/HasPermission";
import CustomButton from "../../../../components/CustomButton";

const CustomTextField = withStyles((theme) => ({
  root: {
    "& .MuiInput-underline": {
        color: theme.palette.primary.main
    },
    "& .MuiFormLabel-root": {
      color: theme.palette.primary.main,
      fontSize: 15,
      fontWeight: 600
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: theme.custom.color.chartColor1,
      borderWidth: 3,
    },
    "& .MuiInput-underline:hover:before": {
      borderBottomColor: theme.custom.color.chartColor1,
      borderWidth: 3,
    },
    "& .MuiInput-underline:before": {
      borderBottomColor: theme.custom.color.chartColor2,
      borderBottomStyle: "solid",
      borderWidth: 3,
    },
    "& .MuiInput-underline:focused": {
      borderBottomColor: theme.custom.color.chartColor2,
      borderBottomStyle: "solid",
      borderWidth: 3,
    },
    "& .MuiInput-underline:focus": {
      borderBottomColor: theme.custom.color.chartColor3,
      borderWidth: 3,
    }
  },
}))(TextField);

function DialogTitle(props) {
  const {
    children, classes, onClose, process, showInpu, setIsDataEdited, showIco, ...other
  } = props;
  const [showInput, setShowInput] = React.useState(showInpu || false);
  const [isLoading, setIsLoading] = React.useState(false);
  const { t } = useTranslation();
  const filterProcess = useSelector(({ filterProcess: i }) => i);
  const {
 handleSubmit, reset, register, setValue, watch
} = useForm({
    mode: "onChange",
    defaultValues: {
      processDisplayName: children || "",
    },
  });
  const onSubmit = (data) => {
    setIsLoading(true);
    const { processDescription } = process;
    processDescription.processDisplayName = data.processDisplayName;
    props
      .updateprocessdescription(process.id, processDescription)
      .then(() => {
        setIsLoading(false);
        editStatut();
        props.fetchProcesses(
          filterProcess.search,
          filterProcess.fleet,
          filterProcess.division,
          filterProcess.showAll,
          filterProcess.tags,
          filterProcess.pageNo,
          filterProcess.pageSize,
          filterProcess.sortBy
        );
        reset({ processDisplayName: data.processDisplayName });
      });
    editStatut();
  };
  const editStatut = () => {
    setIsDataEdited(false);
    setValue("processDisplayName", children)
    setShowInput(!showInput);
  };

  useEffect(() => {
    setIsDataEdited(children !== watch("processDisplayName"));
  }, [watch("processDisplayName")])

  const currentUser = useSelector(({ requests }) => get(requests, "queries.FETCH_CURRENT_USER.data"));

  useEffect(() => {
    if (children) setValue("processDisplayName", children)
    return () => {
      reset()
    }
  }, [children])

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      {!showInput && (
        <Grid container direction="row">
          <Grid
            container
            xs={12}
            direction="row"
            justify="flex-start"
            alignItems="center"
            style={{
              imarginLeft: isPermitted(currentUser, "Edit process details")
                ? 48
                : 0,
            }}
          >
            <Typography className={classes.processTitle} color="primary" variant="h5">{children}</Typography>
            <HasPermission name="Edit process details">
              {!showIco && (
                <Tooltip title={t("process.modify")}>
                  <IconButton className={classes.pencil} onClick={editStatut}>
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              )}
            </HasPermission>
          </Grid>
        </Grid>
      )}
      {showInput && (
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={classes.root}
          noValidate
          autoComplete="off"
        >
          <Grid container alignItems="center">
            <Grid item xs={8}>
              <CustomTextField
                className={classes.editTexField}
                value={props.value}
                onChange={props.onChange}
                inputRef={props.ref}
                disabled={isLoading}
                {...register("processDisplayName", { required: true })}
              />
            </Grid>
            <Grid item xs={4} className={classes.cardActions}>
              <CustomButton
                variant="outlined"
                onClick={editStatut}
                size="small"
                className={classes.cancelButton}
                startIcon={<CancelIcon />}
                disabled={isLoading}
              >
                {t("Cancel")}
              </CustomButton>
              <CustomButton
                view="primary"
                size="small"
                type="submit"
                className={classes.saveButton}
                startIcon={<SaveIcon />}
                disabled={isLoading}
              >
                {t("Save")}
              </CustomButton>
            </Grid>
          </Grid>
        </form>
      )}
    </MuiDialogTitle>
  );
}

const mapDispatchToProps = {
  updateprocessdescription,
  fetchProcesses,
};
export default connect(null, mapDispatchToProps)(DialogTitle);
