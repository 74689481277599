import React, { useEffect, useState } from "react";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import CustomDialog from "pages/Services/components/CustomDialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import SaveOutlinedIcon from "@material-ui/icons/SaveOutlined";
import { useDispatch } from "react-redux";
import {
  deleteCosts,
  deleteSharedByList,
  fetchAllocations,
  fetchProcessesSortedByUsage,
  fetchShared,
  saveCost,
  saveShardAll,
  updateCostAllocation, updateListShared,
  updateProcess,
} from "redux/actions/services";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Typography from "@material-ui/core/Typography";
import keys from "lodash/keys";
import moment from "moment";
import ProcessList from "./ProcessList";
import TimeSaved from "./TimeSaved";
import FinancialTable from "./Table";
import DeleteItem from "./DeleteItem";
import CostAllocationForm from "./CostAllocations";
import CostShared from "./CostShared";
import { formtNumberByLanguage, getDefaultFormat } from "util";
import i18n from "../../i18n";
import {
  DEFAULT_FTE_COST,
  TOTAL_PROCESS_WEIGHT,
} from "../../util/constants/financial";
import ConfirmMessage from "components/ConfirmMessage";
import { formatDateTimeIsoParam } from "../../util";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import { toast } from "react-toastify";
import CustomButton from "../../components/CustomButton";
import DataNotFound from "../../components/DataNotFound";
import CircularLoader from "../../components/Loaders/CircularLoader";
import CustomCloseButton from "../Services/components/CustomCloseButton";
import useStyles from "./style";

const INITIAL_COST = {
  processId: "",
  weight: "",
  allocatedAmount: "",
};

const RECURRING_LABEL = "recurring";

const SHARED_TYPE = "shared";

export default function FinancialParameters() {
  const classes = useStyles();
  const [openDialog, setOpenDialog] = useState(false);
  const [open, setOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const dispatch = useDispatch();
  const [processesData, setProcessesData] = useState(null);
  const [isProcessesDataLoading, setIsProcessesDataLoading] = useState(false);
  const [selectedProcess, setSelectedProcess] = useState("");
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [showDates, setShowDates] = useState(false);
  const [selectedId, setSelectedId] = useState();
  const [allocationsData, setAllocationsData] = useState([]);
  const [sharedData, setSharedData] = useState([]);
  const [selectedSharedId, setSelectedSharedId] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [saveAll, setSaveAll] = useState(false);
  const [deleteShared, setDeleteShared] = useState(false);
  const form = useForm();
  const [showCostShared, setShowCostShared] = useState(false);
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { language } = i18n;
  const [sharedLoading, setSharedLoading] = useState(false);
  const [allocationLoading, setAllocationLoading] = useState(false);
  const [openMsgCancel, setOpenMessageCancel] = useState(false);
  const [isSaveLoading, setIsSaveLoading] = useState(false);

  const handleRejectCancelForm = () => {
    setOpenMessageCancel(false);
  }

  const handleAcceptCancelForm = () => {
    form.reset();
    setOpenMessageCancel(false);
    handleCostFormClose();
  };

  const handleAddClick = () => {
    form.setValue("sharedData", [...form.watch("sharedData"), INITIAL_COST]);
    setSaveAll(isEdit);
  };

  const handleSplitClick = () => {
    const currentSharedData = form.watch("sharedData")
    const newSharedData = []
    const splitedWeight = 100 / currentSharedData.length
    currentSharedData.forEach((el, index) =>
    {
     newSharedData.push({ ...el, weight: formtNumberByLanguage(splitedWeight), allocatedAmount: formtNumberByLanguage((splitedWeight / 100) * getDefaultFormat(form.watch("allocatedAmount"))) })
     form.clearErrors([`sharedData.${index}.allocatedAmount`, `sharedData.${index}.weight`]);
    })
    form.setValue("sharedData", newSharedData);
  };

  const handleDeleteClick = (index) => {
    form.setValue(
      "sharedData",
      form.watch("sharedData").filter((_, i) => i !== index)
    );
    setDeleteShared(true);
  };

  const resetCostForm = () => {
    const excludedData = ["fteCost", "fteExecutionTime", "fteItemTime"];
    keys(form.watch()).forEach((key) => {
      if (!excludedData.includes(key)) form.setValue(key, "");
    });
  };
  const handleClickOpen = () => {
    resetCostForm();
    setShowDates(false);
    setIsReadOnly(false);
    setShowCostShared(false);
    setOpen(true);
    setIsEdit(false);
    setDeleteShared(false);
    setDeleteShared(false);
  };

  const handleCostFormClose = () => {
    setIsReadOnly(false);
    setIsEdit(false);
    setOpen(false);
    setSaveAll(false);
    setDeleteShared(false);
    setShowCostShared(false);
    setShowDates(false);
  };

  const saveData = async (data) => {
    const { category, type } = form.watch();
    const savedCost = await dispatch(
      saveCost({
        ...data,
        processId: selectedProcess.id,
        category,
        type,
      })
    );
    await Promise.all([
      dispatch(
        saveShardAll(
          data?.sharedData?.map((processAllocation) => ({
            ...processAllocation,
            costAllocations: {
              name: data.name,
              category,
              type,
              allocatedAmount: Number(data?.allocatedAmount),
              processId: selectedProcess.id,
              id: savedCost.data.id,
            },
          })),
          savedCost.data.id,
          onSuccess,
          onError
        )
      ),
    ]);
  };

  const fetchAllocationsData = () => {
    dispatch(fetchAllocations()).then((response) => {
      setAllocationLoading(false);
      const allocations = response.data.list || [];
      const filteredAllocations = allocations.filter(
        (allocation) => allocation.processId === selectedProcess.id
      );
      setAllocationsData(filteredAllocations);
    })
  };

  const fetchSharedData = () => {
    dispatch(fetchShared()).then((sharedResponse) => {
      setSharedLoading(false);
      const sharedAllocations = sharedResponse.data.list || [];
      const groupedSharedData = sharedAllocations?.reduce((acc, item) => {
        const costAllocationsId = item.costAllocations.id;
        if (!acc[costAllocationsId]) {
          acc[costAllocationsId] = {
            costAllocations: item.costAllocations,
            sharedData: [],
          };
        }
        acc[costAllocationsId].sharedData.push(item);
        return acc;
      }, {});
      const groupedSharedDataArray = Object.values(groupedSharedData);
      // prettier-ignore
      const allowed = groupedSharedDataArray.filter(({ sharedData }) => sharedData.map(({ processId }) => processId).includes(selectedProcess.id));
      setSharedData(allowed);
  })
  };

  const fetchAllData = () => {
    fetchAllocationsData();
    fetchSharedData();
    setOpen(false);
  };

  const onSuccess = () => toast.success(t("changes.saved.successfully"))

  const onError = () => toast.error(t("changes.saving.failed"))

  const saveOnly = async (data) => {
    const { category, type } = form.watch();
    if (data.type === SHARED_TYPE && !isEdit) {
      await saveData(data);
      fetchAllData();
      setIsEdit(false);
      setSelectedSharedId(null);
      setSelectedId(null);
      setOpen(false);
    }
    if (data.type === "exclusive" && !isEdit) {
      dispatch(
        saveCost({
          ...data,
          processId: selectedProcess.id,
          category,
          type,
        }, () => {
          onSuccess();
          fetchAllocationsData();
        }, onError)
      );
      setIsEdit(false);
      setOpen(false);
    }
  };

  const editSharedData = async (data) => {
    const selectedCost = sharedData[selectedId];
    await saveData(data);
    const sortedSharedData = sharedData[selectedId].sharedData.sort(
      (a, b) => b.id - a.id
    );
    const ids = sortedSharedData.map((shared) => shared.id);
    await dispatch(deleteSharedByList(ids));
    await dispatch(deleteCosts(selectedCost.costAllocations.id, onSuccess, onError));
    setSharedData(sharedData.filter((index) => index !== selectedCost.id));
    await fetchAllData();
    setOpen(false);
  };

  const handleChangeType = async (data) => {
    const selectedAllocations = allocationsData[selectedId];
    await saveData(data);
    await dispatch(deleteCosts(selectedAllocations.id, onSuccess, onError));
    await fetchAllData();
    setOpen(false);
  };

  const resolvePayload = (shared, data) => {
    const selectedAllocations = sharedData[selectedId];
    const { category, type } = form.watch();
    const payload = {
      id: shared.id,
      processId: shared.processId,
      weight: shared.weight,
      allocatedAmount: +getDefaultFormat(shared.allocatedAmount),
      costAllocations: {
        id: selectedAllocations.costAllocations.id,
        name: data.name,
        category,
        type,
        allocatedAmount: Number(data.allocatedAmount),
        processId: selectedProcess.id,
      }
    }
    if (category !== RECURRING_LABEL) return payload;
    return { ...payload, costAllocations: { ...payload.costAllocations, startDate: data.startDate, endDate: data.endDate } }
  }
  const UpdatedSharedData = async (data) => {
    const sharedList = getFieldValue("sharedData").map((shared) => (
        resolvePayload(shared, data)
    ))
    dispatch(updateListShared(sharedList, onListSharedSavedSuccess, onError))
  };
  const onListSharedSavedSuccess = () => {
    onSuccess();
    fetchAllData();
    setOpen(false);
  }
  const handleSharedSubmit = async (data) => {
    let dataToSend = data;
    if (data?.category !== RECURRING_LABEL) {
      dataToSend = { ...data, startDate: undefined, endDate: undefined };
    }
    // prettier-ignore
    if (
      (saveAll && sharedData && sharedData[selectedSharedId])
      || (!saveAll && sharedData[selectedSharedId] && deleteShared)
    ) {
      await editSharedData(dataToSend);
    } else if (
      sharedData
      && sharedData[selectedSharedId]
      && !saveAll
      && !deleteShared
    ) {
      await UpdatedSharedData(dataToSend);
    } else if (!saveAll || saveAll) {
      await handleChangeType(dataToSend);
    }
    fetchAllocationsData();
    await fetchSharedData();
  };

  const totalProcessWeightValue = Array.isArray(form.watch().sharedData)
    ? form
        .watch()
        .sharedData.reduce((acc, val) => Number(acc) + Number(val?.weight), 0)
    : 0;

  const onSubmit = async (data) => {
    const { type } = data;
    const tempSharedData = []
    if (data?.sharedData) data.sharedData?.forEach((el) => tempSharedData.push({ ...el, weight: +getDefaultFormat(el?.weight), allocatedAmount: +getDefaultFormat(el?.allocatedAmount) }))
    const dataToSend = { ...data, allocatedAmount: +getDefaultFormat(data?.allocatedAmount), sharedData: tempSharedData }
    saveOnly(dataToSend);
    if (type === SHARED_TYPE && isEdit) {
      if (totalProcessWeightValue === TOTAL_PROCESS_WEIGHT) {
        handleSharedSubmit(dataToSend);
      }
    }
    if (type === "exclusive" && isEdit) {
      await handleExclusiveSubmit(dataToSend);
    }
    setOpen(false);
    setIsEdit(false);
  };
  const handleExclusiveSubmit = async (data) => {
    const { category, type } = form.watch();
    let dataToSend = data;
    if (category !== RECURRING_LABEL) {
      dataToSend = { ...data, startDate: undefined, endDate: undefined };
    }
    if (isSubmitting) {
      return;
    }
    setIsSubmitting(true);
    const selectedCostAllocation = allocationsData[selectedId];
    const hasSharedData = Array.isArray(sharedData) && sharedData.length > 0;
    let found = false;
    if (hasSharedData) {
      for (let i = 0; i < sharedData.length; i += 1) {
        if (selectedCostAllocation?.id === sharedData?.[i]?.costAllocations?.id) {
          found = true;
          break;
        }
      }
    }
    if (!found || !hasSharedData) {
      await dispatch(
        updateCostAllocation({
          ...dataToSend,
          processId: selectedProcess.id,
          id: selectedCostAllocation.id,
          category,
          type,
        }, onSuccess, onError)
      );
      await fetchAllocationsData();
    } else {
      const selectedSharedData = sharedData[selectedId];
      await dispatch(
        saveCost({
          ...dataToSend,
          processId: selectedProcess.id,
          category,
          type,
        }, onSuccess, onError)
      );
      const sortedSharedData = selectedSharedData.sharedData.sort(
        (a, b) => b.id - a.id
      );
      const ids = sortedSharedData.map((shared) => shared.id);
      await dispatch(deleteSharedByList(ids));
      await dispatch(deleteCosts(selectedSharedData.costAllocations.id, onSuccess, onError));
      setSharedData(
        sharedData.filter((index) => index !== selectedSharedData.id)
      );
      form.reset();
      await fetchAllData();
    }
    setIsSubmitting(false);
  };

  const handleChange = () => {
    const { category, type, sharedData } = form.watch();
    setShowDates(category === RECURRING_LABEL);
    // prettier-ignore
    if (type === SHARED_TYPE) {
      (!sharedData || sharedData.length === 0)
        && form.setValue("sharedData", [{ ...INITIAL_COST, weight: 100 }]);
    } else setShowCostShared(false);
  };

  const handleDelete = (itemId, index) => {
    // If we get an item with this find, the  id is one of a shared cost and we will get it
    let getItem = sharedData.find((item) => item.costAllocations.id === itemId);

    // If the id was not one of a shared cost, it is one of an exclusive cost, and we get it with the find on allocationsCosts
    if (getItem === undefined) {
      getItem = allocationsData.find((item) => item.id === itemId);
    }
    setSelectedItem(getItem);
    setOpenDialog(true);
    setSelectedId(index);
  };

  const handleDeleteConfirm = async () => {
    if (selectedItem?.type === "exclusive") {
      await dispatch(deleteCosts(selectedItem.id, onSuccess, onError));
      setAllocationsData(
        allocationsData.filter((index) => index !== selectedItem.id)
      );
      fetchAllocationsData();
      setOpenDialog(false);
    } else if (selectedItem?.costAllocations.type === SHARED_TYPE) {
      const sortedSharedData = sharedData[selectedId].sharedData.sort(
        (a, b) => b.id - a.id
      );
      const ids = sortedSharedData.map((shared) => shared.id);
      dispatch(deleteSharedByList(ids, () => {
        dispatch(deleteCosts(selectedItem.costAllocations.id, onDeleteSuccess, onDeleteError));
      }), onError)
    }
  };
  const onDeleteSuccess = () => {
    onSuccess();
    setSharedData(sharedData.filter((index) => index !== selectedItem.id));
    setOpenDialog(false);
    fetchSharedData();
  }
  const onDeleteError = () => {
    setOpenDialog(false);
    onError();
  }
  const handleEditShow = (item, index, isEdit = false) => {
    setSelectedSharedId(index);
    setSelectedId(index);
    setIsEdit(isEdit);
    setIsReadOnly(!isEdit);
    Object.keys(item.costAllocations).forEach((key) => {
      if (key.includes("Date")) {
        const date = item.costAllocations[key];
        form.setValue(
          key,
          date ? formatDateTimeIsoParam(moment(date)) : null
        );
      } else form.setValue(key, item.costAllocations[key]);
    });
    Object.keys(item).forEach((key) => {
      form.setValue(key, item[key]);
    });
    setOpen(true);
  };

  useEffect(() => {
    setShowCostShared(form.watch("type") === SHARED_TYPE && +getDefaultFormat(form.watch("allocatedAmount")) > 0);
  }, [form.watch("type"), form.watch("allocatedAmount")])

  useEffect(() => {
    setAllocationLoading(true);
    setSharedLoading(true);
    fetchAllData();
  }, [dispatch, selectedProcess?.id]);

  useEffect(() => {
    setIsProcessesDataLoading(true);
    dispatch(fetchProcessesSortedByUsage()).then((data) => {
      if (data.data) {
        setSelectedProcess(data.data[0]);
        setProcessesData(data.data);
      }
      setIsProcessesDataLoading(false);
    });
  }, [dispatch]);

  const handleSave = () => {
    const updatedProcessData = {
      fteItemTime: getDefaultFormat(form.getValues("fteItemTime")),
      fteExecutionTime: getDefaultFormat(form.getValues("fteExecutionTime")),
      fteCost: getDefaultFormat(form.getValues("fteCost")),
      id: selectedProcess?.processRoiDetail?.id
    };
    setIsSaveLoading(true);
    dispatch(updateProcess(selectedProcess?.id, updatedProcessData, onSuccess, onError)).then(
      (result) => {
        if (result?.status === 200)
          { updateProcessCosts(updatedProcessData) }
         setIsSaveLoading(false);
      }
    );
  };
  const updateProcessCosts = (updatedCosts) => {
    setProcessesData((prevState) => prevState.map((process) => {
            if (selectedProcess.id === process.id) return { ...process, processRoiDetail: { ...process.processRoiDetail, ...updatedCosts } }
            return process;
          }))
  }
  const getFieldValue = (field) => form.watch(field);

  const setFieldValue = (field, value) => form.setValue(field, value);

  const handleFteChange = (process) => {
    const tempFteCost = formtNumberByLanguage(
      process?.processRoiDetail?.fteCost
    );
    form.setValue(
      "fteCost",
      tempFteCost && tempFteCost !== 0 ? tempFteCost : DEFAULT_FTE_COST
    );
    form.setValue(
      "fteItemTime",
      formtNumberByLanguage(process?.processRoiDetail?.fteItemTime)
    );
    form.setValue(
      "fteExecutionTime",
      formtNumberByLanguage(process?.processRoiDetail?.fteExecutionTime)
    );
  };

  const handleSelectProcess = (process, isSaving = false) => {
    isSaving ? handleSave() : handleFteChange(process);
    setSelectedProcess(process);
  };

  const handleEditTimeSavingCancel = () => {
    handleFteChange(
      processesData?.find(({ id }) => id === selectedProcess?.id)
    );
  };
  // prettier-ignore
  const isAbleToSave = !(
    form.watch("fteCost")
      === formtNumberByLanguage(selectedProcess?.processRoiDetail?.fteCost)
    && form.watch("fteItemTime")
      === formtNumberByLanguage(selectedProcess?.processRoiDetail?.fteItemTime)
    && form.watch("fteExecutionTime")
      === formtNumberByLanguage(selectedProcess?.processRoiDetail?.fteExecutionTime)
  );

  const handleSharedProcessChange = (target, processId) => {
    form.setValue(
      target,
      processId
    );
  };

  const handlePercentChange = (field, target, isMultipled) => {
    const allocatedValue = (form.watch(field) / getDefaultFormat(form.watch("allocatedAmount"))) * 100
    form.setValue(
      target,
      isMultipled
          ? (allocatedValue).toFixed(`${allocatedValue}`.includes(".") ? 2 : 0)
        : formtNumberByLanguage(((form.watch(field) / 100) * getDefaultFormat(form.watch("allocatedAmount"))).toFixed(2))
    );
  };

  useEffect(() => {
    handleChange();
  }, [form.watch("category"), form.watch("type")]);

  useEffect(() => {
    if (selectedProcess) {
      form.reset();
      handleFteChange(selectedProcess);
    }
  }, [selectedProcess, language]);

  useEffect(() => {
    const sharedData = form.watch("sharedData");
    if (Array.isArray(sharedData)) {
      const tempSharedData = sharedData.map((cost) => ({
        ...cost,
        allocatedAmount: formtNumberByLanguage((cost.weight / 100) * getDefaultFormat(form.watch("allocatedAmount"))),
      }));
      form.setValue("sharedData", tempSharedData);
    }
  }, [form.watch("allocatedAmount"), form.watch("type")]);
  return (
      // eslint-disable-next-line no-nested-ternary
      !processesData
        ? <CircularLoader size={50} justifyContent="center" height="80vh" />
      : processesData?.length === 0
          ? (<DataNotFound
              backgroundColor="transparent"
              boxShadow="none"
              iconHeight={150}
              iconWidth={150}
              width="100%"
           />
          )
      : (
        <div>
          <FormProvider {...form}>
            <Grid container className={classes.pageHeaderContainer}>
              <Box component={Grid} ml={3} item xs={12} mt={2}>
                <Typography component="h2" className={classes.title}>
                  {t("Financial parameters")}
                </Typography>
              </Box>
            </Grid>
            <Grid container alignItems="center" justify="center">
              <Grid item container xs={12} className={classes.parentContainer}>
                <TimeSaved
                    getFieldValue={getFieldValue}
                    setFieldValue={setFieldValue}
                    isSaveLoading={isSaveLoading}
                    processesList={processesData}
                    selectedProcess={selectedProcess}
                    handleSelectProcess={handleSelectProcess}
                />
                <Divider orientation="vertical" className={classes.divider} />
                <ProcessList
                    processesData={processesData}
                    selectedProcess={selectedProcess}
                    handleSelectProcess={handleSelectProcess}
                    isLoading={isProcessesDataLoading}
                />
                <Grid
                    container
                    item
                    xs={6}
                    alignItems="center"
                    justify="flex-end"
                    className={classes.timesaved}
                >
                  <Grid item>
                    <CustomButton
                        view="cancel"
                        onClick={handleEditTimeSavingCancel}
                        className={classes.timeSavedButton}
                    >
                      {t("Cancel")}
                    </CustomButton>
                  </Grid>
                  <Grid item>
                    <CustomButton
                        view={isSaveLoading ? "cancel" : "primary"}
                        disabled={isSaveLoading && isAbleToSave}
                        startIcon={isSaveLoading ? null : <SaveOutlinedIcon />}
                        onClick={() => handleSelectProcess(selectedProcess, true)}
                        className={classes.timeSavedButton}
                    >
                      {isSaveLoading ? (
                        <CircularProgress color="secondary" size={18} />
                                ) : (
                                    t("Save ")
                                )}
                    </CustomButton>
                  </Grid>
                </Grid>
              </Grid>
              <Box
                  component={Grid}
                  mt={4}
                  item
                  container
                  xs={12}
                  className={classes.parentContainer}
              >
                <FinancialTable
                    sharedData={sharedData}
                    sharedLoading={sharedLoading}
                    allocationLoading={allocationLoading}
                    allocationsData={allocationsData}
                    handleEdit={handleEditShow}
                    handleDelete={handleDelete}
                    handleClickOpen={handleClickOpen}
                    disabaleAddCost={processesData?.length === 0}
                    selectedProcess={selectedProcess}
                />
                <DeleteItem
                    openDialog={openDialog}
                    setOpenDialog={setOpenDialog}
                    selectedItem={selectedItem}
                    handleDeleteConfirm={handleDeleteConfirm}
                />
                <CustomDialog
                    open={open}
                    onClose={handleCostFormClose}
                    paperStyle={{
                                style: {
                                  margin: "auto",
                                  minWidth: "75%",
                                  minHeight: "45%",
                                },
                                component: "form",
                              }}
                    onSubmit={form.handleSubmit((newData) => {
                                onSubmit(newData);
                              })}
                >
                  <DialogTitle>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                      {t("Cost Configuration")}
                      <CustomCloseButton
                          edge="end"
                          aria-label="cancel"
                          onClick={() => {
                            if (isReadOnly) {
                              setOpen(false);
                              return;
                            }
                            setOpenMessageCancel(true);
                          }}
                      />
                    </Box>
                  </DialogTitle>
                  <DialogContent className={classes.dialogBody}>
                    <Grid container justify="center" spacing={6}>
                      <Grid item xs={4}>
                        <CostAllocationForm
                            isReadOnly={isReadOnly}
                            isEdit={isEdit}
                            showDates={showDates}
                        />
                      </Grid>
                      <Grid item xs={8}>
                        <CostShared
                            showCostShared={showCostShared}
                            processAllocations={getFieldValue("sharedData")}
                            processesData={processesData}
                            isReadOnly={isReadOnly}
                            handleAddClick={handleAddClick}
                            handleSplitClick={handleSplitClick}
                            handleDeleteClick={handleDeleteClick}
                            totalWeight={totalProcessWeightValue}
                            handlePercentChange={handlePercentChange}
                            watch={getFieldValue}
                            handleSharedProcessChange={handleSharedProcessChange}
                        />
                      </Grid>
                    </Grid>
                  </DialogContent>
                  <DialogActions className={isReadOnly ? classes.hide : classes.show}>
                    <CustomButton
                        view=""
                        className={classes.popupBtnCancel}
                        onClick={() => setOpenMessageCancel(true)}
                    >
                      {t("Cancel")}
                    </CustomButton>
                    <CustomButton
                        view="primary"
                        className={classes.popupBtnSave}
                        type="submit"
                        disabled={isReadOnly}
                    >
                      {t("Save ")}
                    </CustomButton>
                  </DialogActions>
                </CustomDialog>
              </Box>
            </Grid>
          </FormProvider>
          {openMsgCancel && (
          <ConfirmMessage
                       message={t("user.delete.discard")}
                       openStart={openMsgCancel}
                       onCancel={handleRejectCancelForm}
                       onConfirm={handleAcceptCancelForm}
                       buttonCancel={t("Cancel")}
                       buttonConfirm={t("Discard")}
                       isLoading={false}
                />
                    )}
        </div>
              )
  );
}
