import React from "react";
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import Typography from "@material-ui/core/Typography"
import CustomDialog from "pages/Services/components/CustomDialog/index.js";
import { useTranslation } from "react-i18next";
import CustomButton from "../../../../../components/CustomButton";
import useStyles from "../../../style.js";

function DeletePresetDialog({ presetId, onClose, onSubmit }) {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
      <CustomDialog open={presetId !== -1} onClose={onClose} disableBackdropClick fullWidth className={classes.deleteDialog}>
        <DialogTitle>{t("analytics.preset.dialog.delete.label")}</DialogTitle>
        <DialogContent>
          <Typography>{t("analytics.preset.dialog.delete.text")}</Typography>
        </DialogContent>
        <DialogActions>
          <CustomButton variant="outlined" onClick={onClose}>
            {t("Cancel")}
          </CustomButton>
          <CustomButton
                view="primary"
                onClick={() => onSubmit(presetId)}
            >
            {t("analytics.preset.dialog.delete.submit")}
          </CustomButton>
        </DialogActions>
      </CustomDialog>
    );
}

export default DeletePresetDialog;
