import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  cardActions: {
    margin: 0,
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1.25),
    marginRight: theme.spacing(1.25),
    justifyContent: "center"
  },
  startMessage: {
    display: "flex",
    alignItems: "center",
    color: theme.palette.grey[800],
  },
  resetButton: {
    marginLeft: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(0),
    padding: theme.spacing(0),
  },
  cancelButton: {
    paddingBottom: theme.spacing(0),
    paddingTop: theme.spacing(0),
    backgroundColor: theme.custom.color.color4,
    color: "white",

  },
  saveButton: {
    paddingBottom: theme.spacing(0),
    paddingTop: theme.spacing(0),
    backgroundColor: theme.custom.color.color1,
    color: "white",

  },

  dialogContent: {
    marginTop: theme.spacing(1),
  },

  deleteButton: {
    color: "white",
    background: theme.palette.error.main,
  },

}));
