import React, { useState, useEffect } from "react";
import DialogActions from "@material-ui/core/DialogActions";
import CustomSwitch from "../../../Services/components/CustomSwitch";
import Divider from "@material-ui/core/Divider";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Grid from "@material-ui/core/Grid";
import Checkbox from "@material-ui/core/Checkbox";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import Tabs from "@material-ui/core/Tabs";
import CustomTab, { useIndicatorStyle } from "pages/Services/components/CustomTab";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Link from "@material-ui/core/Link";
import DialogContent from "@material-ui/core/DialogContent";
import Box from "@material-ui/core/Box";
import { Add, Close } from "@material-ui/icons";
import {
  orderBy, get, cloneDeep, differenceBy,
} from "lodash";
import kebabCase from "lodash/kebabCase";
import { useSelector } from "react-redux";
import {
  defaultPermissions, defaultCOEPermissions, defaultMMGTPermissions, defaultSMEPermissions
} from "util/index";
import { TabPanel } from "../../../Services/components/SharedService";
import {
  updateUserPermissions,
  fetchUserPermissionsTemplates,
  deleteUserPermissionsTemplate,
  fetchCurrentUser,
  fetchUser
} from "../../../../redux/actions/services";
import ConfirmMessage from "../../../../components/ConfirmMessage";
import CreateTemplateForm from "../CreateTemplateForm";
import { disableModuleChange } from "../../../../components/HasPermission";
import CustomDialog from "pages/Services/components/CustomDialog";
import CustomCloseButton from "pages/Services/components/CustomCloseButton";
import CustomButton from "../../../../components/CustomButton";
import { toast } from "react-toastify";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const PermissionManagement = ({
  isOpen,
  setIsOpen,
  t,
  classes,
  row,
  dispatch,
  fetchEntities,
}) => {
  const initPermissions = (userPermissions) => {
    // Create a deep copy of the defaultPermissions array
    const defaultPerm = cloneDeep(defaultPermissions);

    if (userPermissions) {
      // add missing menus
      const oldPermissions = JSON.parse(userPermissions);

      const menuDifference = differenceBy(defaultPerm, oldPermissions, "name");
      if (menuDifference && menuDifference.length !== 0) {
        oldPermissions.push(...menuDifference);
      }

      // add missing menu modules
      oldPermissions.forEach((menu, menuIndex) => {
        let modulesClone = [...menu.modules];

        const defaultPermMenuIndex = defaultPerm.findIndex(
          (el) => el?.index === menu?.index,
        );

        const defaultPermMenu = defaultPerm[defaultPermMenuIndex];
        modulesClone = oldPermissions[menuIndex]?.modules?.filter((module) => {
          const defaultModules = defaultPermissions[menuIndex]?.modules.map(
            (m) => m.subMenu || m.name,
          );
          return defaultModules?.includes(module?.subMenu || module?.name);
        });

        // eslint-disable-next-line no-unused-expressions
        menu?.modules?.forEach((module, moduleIndex) => {
          // remove unused modules
          const defaultPermissionModuleIndex = defaultPermMenu.modules?.findIndex(
            (el) => el?.index === module?.index,
          );
          const defaultPermModule = defaultPermMenu.modules[defaultPermissionModuleIndex];
          const actionsDifference = differenceBy(
            defaultPermModule?.actions,
            module.actions,
            "name",
          );

          let actionsClone = [...module.actions];
          const uniqueName = [];
          actionsClone.push(...actionsDifference);
          actionsClone = actionsClone.filter((a) => {
            if (uniqueName.includes(a.name)) return false;

            uniqueName.push(a.name);
            return true;
          });
          const moduleClone = { ...module, actions: actionsClone };

          oldPermissions[menuIndex].modules[moduleIndex] = moduleClone;
          modulesClone[moduleIndex] = moduleClone;
        });
        const modulesDifference = differenceBy(
          defaultPermMenu?.modules,
          menu.modules,
          "name",
        );
        modulesClone.unshift(...modulesDifference);
        const menuClone = { ...menu, modules: modulesClone };
        oldPermissions[menuIndex] = menuClone;
      });
      return oldPermissions;
    }
    return defaultPerm;
  };
  // if you want enable the division module , you must just remove the arrow function (map)
  const getInitialPermissions = (process.env.REACT_APP_DISABLE_PARTITIONING
      ? initPermissions(get(row, "permissions")).map((permission) => {
        permission.modules = permission.modules.filter((module) => module.name !== "Divisions Module");
        return permission;
      }) : initPermissions(get(row, "permissions")))

  const [permissions, setPermissions] = useState(getInitialPermissions);
  const [isAdmin, setIsAdmin] = React.useState(false);
  const [value, setValue] = React.useState(1);
  const [openMsgConfirm, setOpenMsgConfirm] = useState(false);
  const [openMsgConfirmDeleteTemplate, setOpenMsgConfirmDeleteTemplate] = useState(false);
  const [toDeleteTemplateId, setToDeleteTemplateId] = useState(null);
  const [selectedTemplateId, setSelectedTemplateId] = useState(null);
  const [showTemplateList, setShowTemplateList] = React.useState(false);
  const [openSavePermissionAsTemplate, setOpenSavePermissionAsTemplate] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState({
    template: false,
    confirm: false,
  });
  const [selectedTemplate, setSelectedTemplate] = React.useState(null);
  const handleClose = () => {
    setPermissions(null);
    setIsOpen(!isOpen);
  };

  const cancelConfirm = () => {
    setIsLoading({ ...isLoading, confirm: false });
    setOpenMsgConfirm(false);
  };

  const cancelConfirmDeleteTemplate = () => {
    setIsLoading({ ...isLoading, template: false });
    setOpenMsgConfirmDeleteTemplate(false);
  };

  const handleSave = () => {
    setOpenMsgConfirm(true);
  };

  const savePermissions = () => {
    setIsLoading({ ...isLoading, confirm: true });
    dispatch(updateUserPermissions(get(row, "id"), permissions)).then((res) => {
      if (res.status === 200) {
        fetchEntities();
        dispatch(fetchCurrentUser());
      }
      cancelConfirm();
    });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    dispatch(fetchUserPermissionsTemplates());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchUser(get(row, "id"))).then((res) => {
      setIsAdmin(res?.data?.isFleetAdmin)
    })
  }, [])
  const handleModuleChange = (event, name) => {
    const permissionsCopy = [...permissions];
    permissionsCopy.forEach((menuItem) => {
      if (menuItem.index === value) {
        permissionsCopy[value].modules.forEach((module) => {
          if (module.index === name) {
            module.enabled = event.target.checked;
          }
        });
      }
    });
    setPermissions(permissionsCopy);
    setSelectedTemplateId(null);
    setSelectedTemplate(null);
    setToDeleteTemplateId(null);
  };

  const handleActionChange = (event, moduleName, actionName) => {
    const permissionsCopy = [...permissions];
    permissionsCopy.forEach((menuItem) => {
      if (menuItem.index === value) {
        permissionsCopy[value].modules.forEach((module) => {
          if (module.name === moduleName) {
            let dependsOn;
            let parentOf;
            module.actions.forEach((action) => {
              if (action.name === actionName) {
                action.enabled = event.target.checked;
                if (action.dependsOn) {
                  dependsOn = action.dependsOn;
                  parentOf = action.name;
                }
                if (action.parentOf) {
                  dependsOn = action.name;
                  parentOf = action.parentOf;
                }
              }
            });
            if (dependsOn || parentOf) {
              module.actions.forEach((action) => {
                if (action.name === dependsOn && !event.target.checked) {
                  module.actions.forEach((act) => {
                    if (act.name === parentOf) {
                      act.enabled = event.target.checked;
                    }
                  });
                } else if (action.name === dependsOn && event.target.checked) action.enabled = event.target.checked;
              });
            }
          }
        });
      }
    });
    setPermissions(permissionsCopy);
    setSelectedTemplate(null);
    setSelectedTemplateId(null);
    setToDeleteTemplateId(null);
  };
  const handleSaveAsTemplate = () => {
    setIsLoading({ ...isLoading, template: false });
    setOpenSavePermissionAsTemplate(true);
  };

  const userPermissionsTemplate = useSelector(
    ({ requests }) => requests.queries.FETCH_USERS_PERMISSIONS_TEMPLATES
      && requests.queries.FETCH_USERS_PERMISSIONS_TEMPLATES.data,
  );

  const handleTemplateChange = (e) => {
    if (e.target.value) {
      setSelectedTemplate(e.target.value.name);
      setSelectedTemplateId(e.target.value.id);
      setPermissions(JSON.parse(e.target.value?.permissions));
    }
  };
  const handleApplyTemplateClick = () => {
    setShowTemplateList(true);
  };

  const handleDeleteTemplate = () => {
    setIsLoading({ ...isLoading, template: true });
    dispatch(deleteUserPermissionsTemplate(toDeleteTemplateId)).then((res) => {
      if (res.status === 200) {
        dispatch(fetchUserPermissionsTemplates());
        if (selectedTemplateId === toDeleteTemplateId) {
          setSelectedTemplate(null)
          setPermissions(getInitialPermissions)
        }
        toast.success(t("user.permission.templateDelete.success"))
      }
      cancelConfirmDeleteTemplate();
    });
  };

  const tabsStyle = useIndicatorStyle();

  return (
    <>
      <CustomDialog open={isOpen} onClose={handleClose} fullWidth maxWidth="md">
        <Paper square>
          <Grid container direction="row" xs={12}>
            <Grid container direction="row" xs={11}>
              <Tabs
                className={classes.tabSize}
                value={value}
                indicatorColor="primary"
                textColor="primary"
                onChange={handleChange}
                aria-label="switch tabs"
                TabIndicatorProps={{
                  style: tabsStyle,
                }}
              >
                {permissions
                  && orderBy(permissions, "index", "asc")
                    .filter((item) => item.name !== "Smart Services")
                    .map((menuItem) => (
                      <CustomTab
                        value={menuItem.index}
                        label={t("user.add.permissions")}
                        {...a11yProps(0)}
                      />
                    ))}
              </Tabs>
            </Grid>
            <Grid container direction="row" xs={1} justify="flex-end">
              <CustomCloseButton aria-label="close" onClick={handleClose} />
            </Grid>
          </Grid>
        </Paper>
        <Grid container item xs={12}>
          <Box component={Grid} container item xs={3} py={2} px={2}>
            {!showTemplateList ? (
              <Link
                onClick={handleApplyTemplateClick}
                underline="always"
                className={classes.templateLink}
              >
                <Grid container item alignItems="flex-start">
                  <Add fontSize="small" />
                  {t("permission.action.applyTemplate")}
                </Grid>
              </Link>
            ) : (
              <FormControl className={classes.formControl} fullWidth>
                <InputLabel id="permissionsTemplate-select" shrink={!!selectedTemplate}>
                  {`${t("user.add.template")} *`}
                </InputLabel>

                <Select
                  fullWidth
                  labelId="permissionsTemplate-select"
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                  }}
                  onChange={handleTemplateChange}
                  defaultValue={null}
                  renderValue={() => selectedTemplate}
                  value={selectedTemplate}
                >
                  <MenuItem
                    key="permission-template-ex-2"
                    value={{ permissions: JSON.stringify(defaultCOEPermissions), name: `COE (${t("user.permissions.default")})` }}
                  >
                    {`COE (${t("user.permissions.default")})`}
                  </MenuItem>
                  <MenuItem
                    key="permission-template-ex-3"
                    value={{ permissions: JSON.stringify(defaultSMEPermissions), name: `SME (${t("user.permissions.default")})` }}
                  >
                    {`SME (${t("user.permissions.default")})`}
                  </MenuItem>
                  <MenuItem
                    key="permission-template-ex-4"
                    value={{ permissions: JSON.stringify(defaultMMGTPermissions), name: `MMGT (${t("user.permissions.default")})` }}
                  >
                    {`MMGT (${t("user.permissions.default")})`}
                  </MenuItem>

                  {userPermissionsTemplate
                    && userPermissionsTemplate.map((elt, i) => (
                      <MenuItem
                        key={`permission-template-ex-${i + 5}`}
                        value={elt}
                      >
                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation()
                            setToDeleteTemplateId(elt?.id);
                            setOpenMsgConfirmDeleteTemplate(true);
                          }}
                          className={classes.templateFormat}
                        >
                          <Close style={{ fontSize: 14 }} />
                        </IconButton>
                        { t(elt.name) }
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            )}
          </Box>
        </Grid>
        <DialogContent className={classes.permissionsContainer}>
          {permissions
              && permissions
                  .filter((item) => item.name !== "Smart Services")
                  .map((menuItem) => (
                    <TabPanel value={menuItem.index} index={menuItem.index}>
                      <FormGroup>
                        {permissions
                              && permissions[menuItem.index]
                              && permissions[menuItem.index].modules
                              && permissions[menuItem.index].modules.map(
                                  (module, index2) => (
                                    <>
                                      <Grid
                                            container
                                            item
                                            xs={12}
                                            className={classes.permissionsForm}
                                        >
                                        <Grid item container xs={3}>
                                          <FormControlLabel
                                                key={`menu-item-${index2}`}
                                                control={(
                                                  <CustomSwitch
                                                        id={`menu-item-switch-${kebabCase(
                                                            module.subMenu || module.name,
                                                        )}`}
                                                        checked={module.enabled || (disableModuleChange(module.name) && isAdmin)}
                                                        handleChange={(e) => handleModuleChange(e, module.index)}
                                                        disabled={disableModuleChange(module.name) && isAdmin}
                                                    />
                                                )}
                                                label={t(module.label || module.name)}
                                            />
                                        </Grid>
                                        <Grid container item xs={9}>
                                          {module
                                                && module.actions
                                                && module.actions.filter((action) => action.name !== "Archive").map((action, index3) => (
                                                  <Grid
                                                        item
                                                        container
                                                        xs={module.actions.length > 1 ? 4 : 9}
                                                    >
                                                    <FormControlLabel
                                                          key={`menu-item-action-${index3}`}
                                                          control={(
                                                            <Checkbox
                                                                  id={`menu-item-action-${action.name}`}
                                                                  checked={action.enabled || (disableModuleChange(module.name) && isAdmin)}
                                                                  disabled={!module.enabled || (disableModuleChange(module.name) && isAdmin)}
                                                                  onChange={(e) => handleActionChange(
                                                                      e,
                                                                      module.name,
                                                                      action.name,
                                                                  )}
                                                              />
                                                          )}
                                                          label={t(action.name)}
                                                      />
                                                  </Grid>
                                                ))}
                                        </Grid>
                                      </Grid>
                                      <Divider className={classes.menuItemDivider} />
                                    </>
                                  ),
                              )}
                      </FormGroup>
                    </TabPanel>
                  ))}
        </DialogContent>
        <DialogActions>
          <Grid
            container
            item
            xs={12}
            direction="row"
            justify="flex-end"
            style={{ margin: "10px" }}
          >
            <CustomButton
              id="cancel-user-permission-template-btn"
              view="cancel"
              onClick={handleClose}
              style={{ marginRight: 10 }}
            >
              {t("Cancel")}
            </CustomButton>
            <CustomButton
              id="save-user-permissions-button-btn"
              view="primary"
              onClick={handleSave}
              style={{ marginRight: 10 }}
            >
              {t("user.permission.save")}
            </CustomButton>
            <CustomButton
              id="create-user-permission-template-btn"
              variant="outlined"
              color="secondary"
              onClick={handleSaveAsTemplate}
              style={{ marginRight: 10 }}
            >
              {t("user.permission.saveAsTemplace")}
            </CustomButton>
          </Grid>
        </DialogActions>
      </CustomDialog>
      <ConfirmMessage
        message={t("user.permission.confirmSave")}
        openStart={openMsgConfirm}
        onCancel={cancelConfirm}
        onConfirm={savePermissions}
        buttonConfirm={t("Save")}
        buttonCancel={t("Cancel")}
        isLoading={isLoading.confirm}
      />
      <ConfirmMessage
        message={t("user.permission.confirmDelete")}
        openStart={openMsgConfirmDeleteTemplate}
        onCancel={cancelConfirmDeleteTemplate}
        onConfirm={handleDeleteTemplate}
        buttonConfirm={t("Delete")}
        buttonCancel={t("Cancel")}
        isLoading={isLoading.template}
      />
      <CreateTemplateForm
        dispatch={dispatch}
        t={t}
        row={row}
        setOpenSavePermissionAsTemplate={setOpenSavePermissionAsTemplate}
        openSavePermissionAsTemplate={openSavePermissionAsTemplate}
        permissions={permissions}
      />
    </>
  );
};

export default PermissionManagement;
