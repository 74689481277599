import {
  Box,
  ButtonBase,
  Card,
  CardContent,
  Checkbox,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { CopyToClipboard } from "react-copy-to-clipboard";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import CheckRoundedIcon from "@material-ui/icons/CheckRounded";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import React, { useState } from "react";
import VisibilityIcon from "@material-ui/icons/Visibility";
import ReplayIcon from "@material-ui/icons/Replay";
import { SrWorkflowIcon } from "components/CustomIcons";
import { updatePageNumber } from "redux/slices/exceptionFilter";
import { useDispatch } from "react-redux";
import ExceptionsNotFound from "../../../assets/Exceptions.svg";
import DataNotFound from "../../../components/DataNotFound";
import { useTranslation } from "react-i18next";
import { HasPermission } from "../../../components/HasPermission";
import { formatImagePath, minimizeString } from "../../../util";
import AttemptChip from "../../../components/AttemptChip";
import WorkflowChip from "../ItemExceptions/Workflow/WorkflowChip";
import { ReactComponent as ProcessDefaultIcon } from "assets/ProcessDefaultIcon.svg"
import useStyles from "../style";

function ExceptionsContent({
  isSelected,
  onClickRow,
  data,
  getDate,
  getDateDifference,
  handleShowExceptionDetails,
  deleteException,
  handleClick,
  deletePermission,
  isItemList,
  endDateLabel,
  setRetryItems,
  setWorkflowItem,
  setOriginalItemId,
}) {
  const selectRow = (e, row) => {
    if (e.target.id !== "checkbox") onClickRow(row);
  };
  const [isExceptionReasonTooltipOpen, setIsExceptionReasonTooltipOpen] = useState(false)
  const [isExceptionReasonCopied, setIsExceptionReasonCopied] = useState(false)

  const { t } = useTranslation();
  const classes = useStyles();

  const dispatch = useDispatch();
  const handleAttempClick = (originalItemId) => {
    setOriginalItemId(originalItemId);
    dispatch(updatePageNumber({ pageNo: 0 }));
  };
  return data.length > 0
    ? data?.map((row, index) => {
      const isItemSelected = isSelected(row?.id);
      return (
        <Card key={index} className={classes.root} elevation={1}>
          <CardContent
            style={{ padding: "0", position: "relative" }}
            className={classes.content}
          >
            <ButtonBase
                // Process exception is not clickable.
              style={{
                padding: "16px 0px",
                cursor: isItemList ? "pointer" : "default",
              }}
              onClick={(e) => (isItemList ? selectRow(e, row) : null)}
              disableRipple={!isItemList || isExceptionReasonTooltipOpen}
            >
              <Grid container xs={12}>
                <Grid
                  container
                  item
                  xs={4}
                  alignItems="center"
                  className={classes.processCard}
                >
                  <Grid item xs={3}>
                    {row?.processDto?.icon ? (
                      <img
                        src={formatImagePath(row.processDto.icon)}
                        alt="Service"
                        className={classes.image}
                      />
                    ) : (
                      <ProcessDefaultIcon className={classes.image} />
                    )}
                  </Grid>
                  <Grid item xs={9}>
                    <Typography variant="body2" align="left">
                      <Box fontWeight="fontWeightBold">
                        {" "}
                        {row?.processDto?.processDescription
                          ?.processDisplayName
                          ? row?.processDto?.processDescription
                            ?.processDisplayName
                          : "---"}
                      </Box>
                    </Typography>

                    {row?.details && (
                      <Tooltip
                          placement="left-start"
                          classes={{ tooltip: classes.exceptionReasonTooltip }}
                          title={row?.details}
                      >
                        <Typography variant="body2" align="left">
                          <Box style={{ fontStyle: "italic", paddingTop: "10px" }}>
                            {minimizeString(row?.details, 300)}
                          </Box>
                        </Typography>
                      </Tooltip>
                    )}

                  </Grid>
                </Grid>
                <Grid container item xs={8}>
                  <Grid item xs={2}>
                    <Typography variant="body2" align="left">
                      <Box fontWeight="fontWeightBold" pb={1.2}>
                        {t("Creation date")}
                      </Box>
                    </Typography>
                  </Grid>
                  <Grid item xs={4} className={classes.itemExceptionData}>
                    <Typography variant="body2" align="left">
                      {row?.startDateTime
                        ? getDate(row?.startDateTime)
                        : "---"}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography variant="body2" align="left">
                      <Box fontWeight="fontWeightBold" pb={1.2}>
                        {t("Exception Reason")}
                      </Box>
                    </Typography>
                  </Grid>
                  <Grid item xs={4} className={classes.itemExceptionData}>
                    <Tooltip
                        placement="left-start"
                        classes={{ tooltip: classes.exceptionReasonTooltip }}
                        interactive
                        open={isExceptionReasonTooltipOpen === row?.id}
                        onOpen={() => setIsExceptionReasonTooltipOpen(row?.id)}
                        onClose={() => {
                          setIsExceptionReasonCopied(false)
                          setIsExceptionReasonTooltipOpen(null)
                        }}
                        title={
                          <>
                            <Typography
variant="body2"
align="left"
onClick={(e) => {
                          e.stopPropagation()
                        }}>
                              {row?.exceptionReason}
                            </Typography>
                            {row?.exceptionReason && row?.exceptionReason?.length > 1500 && (
                            <CopyToClipboard text={row?.exceptionReason}>
                              <IconButton
className={classes.iconCopy}
onClick={(e) => {
                                e.stopPropagation()
                                setIsExceptionReasonCopied(true)
                              }}>
                                {isExceptionReasonCopied ? (<CheckRoundedIcon fontSize="inherit" />) : (<FileCopyIcon fontSize="inherit" />)}
                              </IconButton>
                            </CopyToClipboard>
                        )}
                          </>
                      }>
                      <ButtonBase className={classes.exceptionReasonButton}>
                        <Typography variant="body2" align="left" className={classes.exceptionReason}>
                          {row?.exceptionReason ? minimizeString(row?.exceptionReason, 300) : "---"}
                        </Typography>
                      </ButtonBase>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={2} style={{ marginTop: 7 }}>
                    <Typography variant="body2" align="left">
                      <Box fontWeight="fontWeightBold" pb={1.2}>
                        {endDateLabel}
                      </Box>
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    className={classes.itemExceptionData}
                    style={{ marginTop: 7 }}
                  >
                    <Typography variant="body2" align="left">
                      {row?.endDateTime ? getDate(row?.endDateTime) : "---"}
                    </Typography>
                  </Grid>
                  <Grid item xs={2} style={{ marginTop: 7 }}>
                    <Typography variant="body2" align="left">
                      <Box fontWeight="fontWeightBold" pb={1.2}>
                        {t("Exception Type")}
                      </Box>
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    style={{ marginTop: 7 }}
                    className={classes.itemExceptionData}
                  >
                    <Typography
                      variant="body2"
                      align="left"
                      className={classes.itemExceptionData}
                    >
                      {row?.exceptionType ? row?.exceptionType : "---"}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography variant="body2" align="left">
                      <Box fontWeight="fontWeightBold">
                        {t("Execution Time")}
                      </Box>
                    </Typography>
                  </Grid>
                  <Grid item xs={4} className={classes.itemExceptionData}>
                    <Typography
                      variant="body2"
                      align="left"
                      className={classes.itemExceptionData}
                    >
                      {row?.startDateTime && row?.endDateTime
                        ? getDateDifference(row?.executionDuration)
                        : "---"}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    {isItemList && (
                    <Typography variant="body2" align="left">
                      <Box fontWeight="fontWeightBold">
                        {t("Execution detail")}
                      </Box>
                    </Typography>
                    )}
                  </Grid>
                  <Grid item xs={4} className={classes.itemExceptionData}>
                    <Typography variant="body2" align="left">
                      {isItemList
                          && (row?.data ? (
                            <Tooltip
                              title={t("Details")}
                              style={{ position: "relative" }}
                            >
                              <IconButton
                                style={{ padding: 0, width: 24, height: 24 }}
                                edge="end"
                                aria-label="view"
                                onClick={() => {
                                  handleShowExceptionDetails(row);
                                }}
                              >
                                <MoreHorizIcon />
                              </IconButton>
                            </Tooltip>
                          ) : (
                            "N/A"
                          ))}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </ButtonBase>
          </CardContent>
          {isItemList && <WorkflowChip item={row} />}
          <Grid
            container
            item
            xs={2}
            alignItems="center"
            justify={isItemList ? "space-between" : "flex-end"}
            className={classes.action}
          >
            <Box display="flex" alignItems="center" flex="1" justifyContent="flex-end">
              {isItemList && (
              <Grid item style={{ display: "flex", justifyContent: "flex-start", flex: "1" }}>
                <AttemptChip attempt={row.attempt} handleAttempClick={handleAttempClick} originalItemId={row.originalItemId} />
              </Grid>
              )}
              <Grid item>
                {!isItemList && (
                <HasPermission name={deletePermission}>
                  <Tooltip
                    title={
                          row.isDeleted
                            ? t("tooltip.action.show")
                            : t("tooltip.action.hide")
                        }
                  >
                    <IconButton
                      className={
                            row.isDeleted
                              ? classes.showIcon
                              : classes.deleteIcon
                          }
                      edge="end"
                      aria-label="view"
                      onClick={(e) => {
                        e.stopPropagation();
                        deleteException(e, row);
                      }}
                    >
                      {row.isDeleted ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  </Tooltip>
                </HasPermission>
                )}
                {isItemList && (
                <>
                  <HasPermission name="Workflow">
                    <Tooltip title={t("exception-workflow.open")}>
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          setWorkflowItem(row);
                        }}
                      >
                        <SrWorkflowIcon />
                      </IconButton>
                    </Tooltip>
                  </HasPermission>
                  <HasPermission name="Retry item exceptions">
                    <Tooltip title={t("retry")}>
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          setRetryItems([row]);
                        }}
                      >
                        <ReplayIcon />
                      </IconButton>
                    </Tooltip>
                  </HasPermission>
                </>
                )}
                <Checkbox
                  id="checkbox"
                  checked={isItemSelected}
                  onChange={(event) => handleClick(event, row)}
                />
              </Grid>
            </Box>
          </Grid>
        </Card>
      );
    })
    : (isItemList && (
    <DataNotFound
      message={t("no.items.exception.message")}
      icon={ExceptionsNotFound}
    />
    ))
        || (!isItemList && (
          <DataNotFound
            message={t("no.process.exception.message")}
            icon={ExceptionsNotFound}
          />
        ));
}

export default ExceptionsContent;
