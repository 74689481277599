import React from "react";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useQuery } from "@redux-requests/react";
import {
  enableOrDisableTag,
  fetchTags,
  deleteTag,
  deleteTags,
  archiveTag, archiveTags
} from "../../../redux/actions/services";
import CustomDialog from "pages/Services/components/CustomDialog";
import { FETCH_TAG, FETCH_CURRENT_USER } from "../../../redux/constants";
import AdminTable from "../AdminTable";
import StatusLegend from "components/StatusLegend";
import PageHeader from "../../../components/PageHeader";
import useStyles from "../FleetManagement/style";
import { isPermitted } from "components/HasPermission";
import { isFleetAdministrator } from "util";
import { disabledFunctionality } from "../../../util/constants/PermitedFeature";

const headerCells = {
  fields: [
    !disabledFunctionality && {
      id: "tagStatus",
      gridSize: 1,
      align: "center",
      label: "tag.status",
    },
    {
      id: "tagIcon",
      gridSize: 3,
      align: "center",
      label: "tag.icon",
    },
    {
      id: "tagDisplayName",
      gridSize: 3,
      align: "center",
      label: "tag.name",
    },
    {
      id: "tagDescription",
      gridSize: 3,
      align: "center",
      label: "tag.description",
    },
  ],
};

const displayFields = [
  !disabledFunctionality && {
    id: "tagStatus",
    gridSize: 1,
    align: "center",
    valuePath: "status",
    isStatus: true,
    translate: false,
  },
  {
    id: "tagIcon",
    gridSize: 3,
    align: "center",
    valuePath: "name",
    avatarPath: "icon",
    isAvatar: true,
  },
  {
    id: "tagDisplayName",
    gridSize: 3,
    align: "center",
    valuePath: "name",
    translate: false,
  },
  {
    id: "tagDescription",
    gridSize: 3,
    align: "center",
    valuePath: "description",
  },
];

const statusList = [
  { code: "ACTIVE", label: "ACTIVE" },
  { code: "DISABLED", label: "DISABLED" },
];

const labels = {
  title: "tag.management.title",
  addButton: "tag.management.add.button",
  deleteEntity: "tag.management.hide.confirmMsg",
  restoreEntity: "tag.management.show.confirmMsg",
  deleteAll: "tooltip.action.delete",
  deleteEntityConfirmButton: "tooltip.action.delete",
  restoreEntityConfirmButton: "tooltip.action.show",
  restoreEntityTooltip: "tooltip.action.delete",
  deleteEntities: "tag.management.deleteTagActionMessage",
  enableEntity: "tag.management.enable.confirmMsg",
  enableConfirm: "tag.management.enable.button.confirm",
  disableEntity: "tag.management.disable.confirmMsg",
  disableConfirm: "tag.management.disable.button.confirm",
  searchPlaceHolder: "Search",
  entityDeleted: "tag.deleted.success",
  confirmAction: "Delete",
  confirmActionArchive: "tag.management.btn.confirm",
  archiveConfirmationMsg: "archive.confirm.msg",
  archivedChip: "tag.management.archived",
  archiveTooltip: "tooltip.action.archive",
  tagArchivedSuccess: "tag.archived.success"
};
const rowCustomActions = null;
const sortByList = [
  { code: "name", label: "tag.management.name" },
  {
    code: "description",
    label: "tag.management.formControl.desc",
  },
];
const statues = ["ACTIVE", "DISABLE"]

function TagManagement(props) {
  const entityBaseUrl = "/robotAdministration/tags";
  const enableDisableEntity = enableOrDisableTag;
  const fetchEntities = fetchTags;
  const entityQuery = FETCH_TAG;
  const searchField = "searchCriteria";
  const classes = useStyles();
  const { t } = useTranslation();
  const currentUser = useQuery({ type: FETCH_CURRENT_USER })?.data;
  const history = useHistory();

  return (
    <div className={classes.root}>
      <div className={classes.paper}>
        <PageHeader title={labels.title} />
        <Grid container>
          <Grid container item xs justify="center">
            {!disabledFunctionality && (
            <StatusLegend statues={statues} />
            )}
          </Grid>
        </Grid>
        <AdminTable
          statusList={statusList}
          sortByList={sortByList}
          displayFields={displayFields}
          headerCells={headerCells}
          entityBaseUrl={entityBaseUrl}
          enableDisableEntity={enableDisableEntity}
          fetchEntities={fetchEntities}
          deleteEntity={deleteTag}
          deleteEntities={deleteTags}
          archiveEntity={archiveTag}
          archiveEntities={archiveTags}
          entityQuery={entityQuery}
          labels={labels}
          searchField={searchField}
          rowCustomActions={rowCustomActions}
          showStatusSwitch={false}
          isTagAdministrationPage
          open={open}
          showAddIcon={isPermitted(currentUser, "Add tag") || isFleetAdministrator(currentUser)}
          addButtonText={t(labels.addButton)}
        />
        {props.showEditModal && (
          <CustomDialog
                  open={props.showEditModal}
                  aria-labelledby="tag-dialog-title"
                  aria-describedby="tag-dialog-description"
                  onBackdropClick={() => {
                    history.push("/robotAdministration/tags");
                  }}
              >
            {props.modalComponent}
          </CustomDialog>
          )}
      </div>
    </div>
  );
}

export default TagManagement;
