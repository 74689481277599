import React, { useEffect, useState } from "react";
import Avatar from "@material-ui/core/Avatar"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import TextField from "@material-ui/core/TextField"
import Button from "@material-ui/core/Button"
import Box from "@material-ui/core/Box"
import Tooltip from "@material-ui/core/Tooltip"
import Stepper from "@material-ui/core/Stepper"
import Step from "@material-ui/core/Step"
import StepLabel from "@material-ui/core/StepLabel"
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import MuiDialogActions from "@material-ui/core/DialogActions";
import CustomTab, { useIndicatorStyle } from "pages/Services/components/CustomTab";
import Tabs from "@material-ui/core/Tabs";
import { getInitiales, isFleetAdministrator } from "../../../../util";
import ConfirmMessage from "../../../../components/ConfirmMessage";
import ProcessSelector from "../../ProcessSelector";
import {
  fetchAssignedProcessesByRobot,
  fetchProcessesByOrchestrator,
  fetchRobot,
  updateRobot,
} from "../../../../redux/actions/services/index";
import CustomCloseButton from "pages/Services/components/CustomCloseButton";
import useStyles from "../../FleetManagement/FleetForm/style";
import { toast } from "react-toastify";
import CustomButton from "../../../../components/CustomButton";

export default function RobotForm() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    setValue, formState: { isDirty }, register, getValues, trigger,
  } = useForm();
  const history = useHistory();
  const { t } = useTranslation();
  const { idRobot, mode = "view" } = useParams();
  const [availableProcesses, setAvailableProcesses] = useState([]);
  const [assignedProcesses, setAssignedProcesses] = useState([]);
  const [openMsgCancel, setOpenMsgCancel] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openMsgConfirm, setOpenMsgConfirm] = useState(false);

  const disableFields = !(mode === "add" || mode === "edit");

  const [robot, setRobot] = React.useState({
    robotDisplayName: "",
  });

  useEffect(() => {
    dispatch(fetchRobot(idRobot)).then((res) => {
      dispatch(fetchAssignedProcessesByRobot(idRobot));
      if (res?.data) {
        setRobot(res.data);
        setValue("robotId", res.data.robotId);
        setValue("processesRunning", res.data.processesRunning);
        setValue("robotDisplayName", res.data.robotDisplayName);
        setValue("robotName", res.data.robotName);
        setValue("actionsRunning", res.data.actionsRunning);
        setValue("displayStatus", res.data.displayStatus ? t(res.data.displayStatus) : t("Offline"));
      }
    });
  }, [idRobot, dispatch, setValue]);

  useEffect(() => {
    if (robot?.orchestrator?.id) dispatch(fetchProcessesByOrchestrator(robot.orchestrator.id)).then();
  }, [dispatch, robot]);

  const robotPreAssignedProcesses = useSelector(
    ({ requests }) => requests.queries.FETCH_ROBOT_PROCESSES?.data,
  );

  const processList = useSelector(
    ({ requests }) => requests.queries.FETCH_PROCESSES_BY_ORCHESTRATOR?.data,
  );

  const currentUser = useSelector(
    ({ requests }) => requests.queries.FETCH_CURRENT_USER?.data,
  );

  useEffect(() => {
    if (robotPreAssignedProcesses && processList) {
      setAssignedProcesses(robotPreAssignedProcesses?.set || []);
      setAvailableProcesses(
        processList?.filter(
          (process) => !robotPreAssignedProcesses.set?.find(
            (assigned) => assigned.id === process.id,
          ),
        ),
      );
    }
  }, [robotPreAssignedProcesses, processList]);

  const handleStepClick = async (step) => {
    if (activeStep !== step && mode === "edit") {
      const result = await trigger();
      if (result) setActiveStep(step);
    }
  };

  const handleSaveClick = async () => {
    const result = await trigger();
    if (result) {
      if (activeStep === 1) {
        setOpenMsgConfirm(true);
      }
      if (activeStep < 1) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    }
  };

  const handleCancel = () => {
    if (mode === "view" || !isDirty) {
      redirectToList();
      return;
    }
    setOpenMsgCancel(true);
  };

  const redirectToList = () => history.push("/robotAdministration/robots");

  const handleBack = () => {
    if (activeStep <= 0) handleCancel();
    setActiveStep((prevActiveStep) => (prevActiveStep === 0 ? 0 : prevActiveStep - 1));
  };

  const cancelConfirm = () => {
    setOpenMsgConfirm(false);
  };

  const confirmSave = () => {
    setIsLoading(true);
    submitUpdate();
    toast.success(t("robot.formControl.succesUpdate"))
  };

  const submitUpdate = () => {
    dispatch(
      updateRobot(idRobot, assignedProcesses.map((el) => el.id).join(","), getValues("robotDisplayName")),
    ).then(() => {
      setIsLoading(false);
      setOpenMsgConfirm(false);
      history.push("/robotAdministration/robots");
    });
  };

  const handleAcceptCancelForm = () => {
    setOpenMsgCancel(false);
    history.push("/robotAdministration/robots");
  };

  const handleRejectCancelForm = () => setOpenMsgCancel(false);

  const steps = [
    "fleet.formSection.fleetInfo",
    "fleet.formSection.processes",
  ];

  const [activeStep, setActiveStep] = React.useState(0);

  const handleChange = (_, newValue) => {
    setActiveStep(newValue);
  };

  const tabsStyle = useIndicatorStyle();

  const getStepContent = (stepIndex) => {
    const inputProps = { style: { color: "grey" } };
    switch (stepIndex) {
      case 0:
        return (
          <Grid container xs={12} item spacing={1}>
            <Grid
              item
              xs={12}
              container
              alignItems="center"
              justify="center"
              className={classes.formRow}
            >
              <Avatar className={classes.avatar} alt="avatar">
                {getInitiales(robot?.robotDisplayName)}
              </Avatar>
            </Grid>
            <Grid item xs={12} container className={classes.formRow}>
              <Grid item xs={12} className={classes.inputsSection}>
                <TextField
                  {...register("robotDisplayName")}
                  fullWidth
                  disabled={disableFields}
                  id="robotDisplayName"
                  label={t("robot.management.formControl.robotName")}
                  InputLabelProps={{
                    shrink: !!getValues("robotDisplayName"),
                  }}
                  inputProps={disableFields && inputProps}
                  autoFocus
                />
              </Grid>
            </Grid>
            <Grid item xs={12} container className={classes.formRow}>
              <Grid item xs={12} className={classes.inputsSection}>
                <TextField
                  {...register("robotName")}
                  fullWidth
                  disabled
                  id="robotName"
                  label={t("robot.management.formControl.robotOriginalName")}
                  InputLabelProps={{
                    shrink: !!getValues("robotName"),
                  }}
                  inputProps={inputProps}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} container className={classes.formRow}>
              <Grid item xs={12} className={classes.inputsSection}>
                <TextField
                  {...register("robotId")}
                  fullWidth
                  disabled
                  id="robotId"
                  label={t("robot.management.formControl.robotId")}
                  InputLabelProps={{
                    shrink: !!getValues("robotId"),
                  }}
                  inputProps={inputProps}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} container className={classes.formRow}>
              <Grid item xs={6} className={classes.inputsSection}>
                <TextField
                  {...register("actionsRunning")}
                  fullWidth
                  id="actionsRunning"
                  name="actionsRunning"
                  label={`${t(
                    "robot.management.formControl.actionsRunning",
                  )} *`}
                  disabled
                  InputLabelProps={{
                    shrink: typeof getValues("actionsRunning") !== "undefined",
                  }}
                  inputProps={inputProps}
                />
              </Grid>
              <Grid item xs={6} className={classes.inputsSection}>
                <TextField
                  {...register("displayStatus")}
                  fullWidth
                  disabled
                  id="displayStatus"
                  label={t("robot.management.displayStatus")}
                  InputLabelProps={{
                    shrink: !!getValues("displayStatus"),
                  }}
                  inputProps={inputProps}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} container className={classes.formRow}>
              <Grid item xs={6} className={classes.inputsSection}>
                <TextField
                  {...register("processesRunning")}
                  fullWidth
                  disabled
                  id="processesRunning"
                  label={`${t(
                    "robot.management.formControl.processesRunning",
                  )} *`}
                  InputLabelProps={{
                    shrink:
                        typeof getValues("processesRunning") !== "undefined",
                  }}
                  inputProps={inputProps}
                />
              </Grid>
            </Grid>
          </Grid>
        );
      case 1:
        return (
          <Grid item xs={12} style={{ height: "100%" }}>
            <ProcessSelector
              availableProcesses={availableProcesses}
              selectedProcesses={assignedProcesses}
              isDisabled={mode === "view"}
              setAvailableProcesses={setAvailableProcesses}
              setSelectedProcesses={setAssignedProcesses}
            />
          </Grid>
        );
      default:
        return null;
    }
  };
  return (
    <div className={classes.root}>
      {/* <AdminPageHeader title={headerTitle} /> */}
      <Paper className={classes.paper}>
        { mode === "view" ? (
          <Paper square>
            <Grid container direction="row" xs={12}>
              <Grid container direction="row" xs={11}>
                <Tabs
                  id="process-tabs-page"
                  className={classes.tabSize}
                  value={activeStep}
                  indicatorColor="primary"
                  textColor="primary"
                  onChange={handleChange}
                  aria-label="switch tabs"
                  TabIndicatorProps={{
                    style: tabsStyle,
                  }}
                >
                  {steps.map((label, index) => (
                    <CustomTab
                      key={label}
                      id="process-overview-tab"
                      value={index}
                      label={t(label)}
                    />
                  ))}
                </Tabs>
              </Grid>
              <Grid container direction="row" xs={1} justify="flex-end">
                <CustomCloseButton
                 aria-label="close"
                 className={classes.closeIcon}
                 onClick={handleCancel}
                />
              </Grid>
            </Grid>
          </Paper>
        )
          : (
            <>
              <Grid container direction="row" xs={1} justify="flex-end" className={classes.closebutton}>
                <CustomCloseButton
                 aria-label="close"
                 className={classes.closeIcon}
                 onClick={handleCancel}
                />
              </Grid>
              <Stepper activeStep={activeStep} alternativeLabel className={classes.stepper}>
                {steps.map((label, index) => (
                  <Step key={label}>
                    <StepLabel style={{ cursor: "pointer" }} onClick={() => handleStepClick(index)}>{t(label)}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </>
          )}
        <div className={classes.formContainer}>
          {mode === "view" && isFleetAdministrator(currentUser) ? (
            <Grid container justify="flex-end" className={classes.editContainer}>
              <Tooltip title={t("robot.modify")}>
                <IconButton
                  aria-label="modify"
                  onClick={() => {
                    history.push(`/robotAdministration/robots/edit/${idRobot}`);
                  }}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          ) : null}
          <form className={classes.fleetForm}>
            <Grid container alignItems="center" justify="center" xs={12} className={classes.inputsContainer}>
              {getStepContent(activeStep)}
            </Grid>
            <Grid item xs>
              {mode === "edit" ? (
                <Grid item xs={12} className={classes.nopadding}>
                  <MuiDialogActions className={classes.modalActionButtons}>
                    <Box
                      ml="1rem"
                      component={Button}
                      variant="contained"
                      size="medium"
                      onClick={handleBack}
                      className={classes.resetButton}
                    >
                      {activeStep <= 0
                        ? t("robot.button.cancel")
                        : t("fleet.add.previous")}
                    </Box>
                    <CustomButton
                      view="primary"
                      size="medium"
                      type="button"
                      onClick={handleSaveClick}
                    >
                      {/* eslint-disable-next-line no-nested-ternary */}
                      {activeStep === steps.length - 1
                        ? idRobot
                          ? t("robot.button.update")
                          : t("user.button.save")
                        : t("next")}
                    </CustomButton>
                  </MuiDialogActions>
                </Grid>
              ) : null}
            </Grid>
          </form>
        </div>

      </Paper>
      {openMsgConfirm && (
        <ConfirmMessage
          message={t("robot.update.confirmMsg")}
          openStart={openMsgConfirm}
          onCancel={cancelConfirm}
          onConfirm={confirmSave}
          buttonConfirm={t("robot.button.update")}
          buttonCancel={t("robot.button.cancel")}
          isLoading={isLoading}
        />
      )}
      {openMsgCancel && (
        <ConfirmMessage
          message={t("robot.update.discard")}
          openStart={openMsgCancel}
          onCancel={handleRejectCancelForm}
          onConfirm={handleAcceptCancelForm}
          buttonConfirm={t("robot.button.discard")}
          buttonCancel={t("robot.button.cancel")}
          isLoading={false}
        />
      )}
    </div>
  );
}
