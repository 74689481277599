import {
    CREATE_USER,
    DELETE_LIST_USERS, DELETE_USER_BY_ID, FETCH_FLEET_USERS,
    FETCH_CURRENT_USER, FETCH_USER,
    FETCH_USERS, FETCH_USERS_CIVILITIES, FETCH_USERS_JOBS, FETCH_USERS_PERMISSIONS_TEMPLATES,
    UPDATE_PASSWORD, UPDATE_USER,
    UPDATE_USER_STATUS, FETCH_TINY_USERS_FLEET
} from "../../../constants";
import {
 URL_CURRENT_USER, URL_UPDATE_PASSWORD, URL_USER, URL_USER_PERMISSIONS
} from "../../../constants/endpoints";

export const fetchUsers = (filter = {}) => {
    const {
        searchCriteria, pageNo, pageSize, status, fleets, sortBy, divisions
    } = filter;
    const fleetIds = fleets?.map((c) => c.id);
    return {
        type: `${FETCH_USERS}`,
        request: {
            url: encodeURI(`${URL_USER}/list${searchCriteria ? `?userName=${searchCriteria}` : ""}`),
            params: {
                pageNo,
                pageSize,
                sortBy,
                status: status ? status.join() : null,
                fleets: fleetIds ? fleetIds.join() : null,
                divisionsIds: divisions?.join(),
            },
        },
    };
};
export const updateProfile = (user, file, onSuccess, onError) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("user", JSON.stringify(user));
    return {
        type: "UPDATE_PROFILE",
        request: {
            url: `${URL_USER}/profile`,
            method: "put",
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
        },
        meta: {
            onSuccess: (response) => {
                if (onSuccess) onSuccess(response);
                return response;
            },
            onError: (error) => {
                if (onError) onError(error);
                return error;
            },
        },
    };
};
export const updateUserPassword = (
    token,
    userCredentials,
    onSuccess,
    onError,
) => ({
    type: UPDATE_PASSWORD,
    request: {
        url: `${URL_UPDATE_PASSWORD}?token=${token}`,
        method: "post",
        data: userCredentials,
    },
    meta: {
        onSuccess: (response) => {
            if (onSuccess) onSuccess();
            return response;
        },
        onError: () => {
            if (onError) onError();
        },
    },
});
export const postUpdatePassword = (userCredentials, onSuccess, onError) => ({
    type: UPDATE_PASSWORD,
    request: {
        url: `${URL_UPDATE_PASSWORD}/request`,
        method: "post",
        data: userCredentials,
    },
    meta: {
        onSuccess: (response) => {
            if (onSuccess) onSuccess();
            return response;
        },
        onError: () => {
            if (onError) onError();
        },
    },
});

export const fetchCurrentUser = () => ({
    type: FETCH_CURRENT_USER,
    request: { url: `${URL_CURRENT_USER}` },
});

export const enableOrDisableUser = (id, userStatus, onSuccess, onError) => ({
    type: UPDATE_USER_STATUS,
    request: {
        url: `${URL_USER}/status/${id}`,
        method: "put",
        data: { status: userStatus },
        headers: { "Content-Type": "application/json" },
    },
    meta: {
        onSuccess: (response) => {
            if (onSuccess) onSuccess(response);
            return response;
        },
        onError: (error) => {
            if (onError) onError(error);
            return error;
        },
    },
});
export const deleteUsers = (idUsers, onSuccess, onError) => ({
    type: DELETE_LIST_USERS,
    request: {
        url: `${URL_USER}/`,
        method: "delete",
        params: { idUsers: idUsers.join(",") },
    },
    meta: {
        onSuccess: (response) => {
            if (onSuccess) onSuccess(response);
            return response;
        },
        onError: (error) => {
            if (onError) onError();
            return error;
        },
    },
});
export const deleteUser = (id, onSuccess, onError) => ({
    type: DELETE_USER_BY_ID,
    request: {
        url: `${URL_USER}/${id}`,
        method: "delete",
    },
    meta: {
        onSuccess: (response) => {
            if (onSuccess) onSuccess();
            return response;
        },
        onError: (error) => {
            if (onError) onError();
            return error;
        },
    },
});
export const createUser = (fleet, file, onSuccess, onError) => {
    const data = new FormData();
    data.append("file", file);
    data.append("user", JSON.stringify(fleet));
    return {
        type: CREATE_USER,
        request: {
            url: `${URL_USER}/`,
            method: "post",
            data,
            headers: { "Content-Type": "multipart/form-data" },
        },
        meta: {
            onSuccess: (response) => {
                if (onSuccess) onSuccess(response);
                return response;
            },
            onError: (error) => {
                if (onError) onError(error);
                return error;
            },
        },
    };
};
export const fetchUser = (id) => ({
    type: `${FETCH_USER}`,
    request: {
        url: `${URL_USER}/${id}`,
    },
});
export const logout = () => ({
    type: "LOGOUT",
    request: {
        url: `${process.env.REACT_APP_GATEWAY}/logout`,
    },
    meta: {
        onSuccess: (response) => {
            const currectUrl = window.location.origin;
            window.location.href = `${process.env.REACT_APP_GATEWAY
            }/login/sso?redirect_url=${encodeURI(currectUrl)}`;
            return response;
        },
    },
});
export const validateToken = (token) => ({
    type: UPDATE_PASSWORD,
    request: {
        url: `${URL_UPDATE_PASSWORD}/?token=${token}`,
        method: "get",
    },
});
export const updateUserPermissions = (id, permissions) => ({
    type: "UPDATE_USER_PERMISSION",
    request: {
        url: `${URL_USER_PERMISSIONS}/${id}`,
        method: "put",
        data: permissions,
    },
});

export const createUserPermissionsTemplate = (id, name, permissions) => ({
    type: "CREATE_USER_PERMISSION_TEMPLATE",
    request: {
        url: `${URL_USER_PERMISSIONS}/${id}/template`,
        method: "post",
        data: { name, permissions },
    },
});
export const fetchJobs = () => ({
    type: FETCH_USERS_JOBS,
    request: {
        url: `${URL_USER}/jobs`,
    },
});

export const fetchUserPermissionsTemplates = () => ({
    type: FETCH_USERS_PERMISSIONS_TEMPLATES,
    request: {
        url: `${URL_USER_PERMISSIONS}/template`,
    },
});
export const deleteUserPermissionsTemplate = (id) => ({
    type: "DELETE_USER_PERMISSIONS_TEMPLATE",
    request: {
        url: `${URL_USER_PERMISSIONS}/template/${id}`,
        method: "delete",
    },
});
export const modifyUser = (userId, fleet, file, onSuccess, onError) => {
    const data = new FormData();
    data.append("file", file);
    data.append("user", JSON.stringify(fleet));
    return {
        type: UPDATE_USER,
        request: {
            url: `${URL_USER}/${userId}`,
            method: "put",
            data,
            headers: { "Content-Type": "multipart/form-data" },
        },
        meta: {
            onSuccess: (response) => {
                if (onSuccess) onSuccess(response);
                return response;
            },
            onError: (error) => {
                if (onError) onError(error);
                return error;
            },
        },
    };
};
export const fetchCivilities = () => ({
    type: FETCH_USERS_CIVILITIES,
    request: {
        url: `${URL_USER}/civility`,
    },
});
export const fetchFleetUsers = (fleetId, processId = null) => ({
    type: FETCH_FLEET_USERS,
    request: {
        url: `${URL_USER}/fleet-users/${fleetId}`,
        method: "get",
        params: {
            processId
        }
    },
});
export const fetchTinyUsersFleet = (fleetId = null) => ({
    type: FETCH_TINY_USERS_FLEET,
    request: {
        url: `${URL_USER}/tiny-users/filters`,
        method: "get",
        params: {
            fleetId
        }
    },
});
