import React from "react";
import { useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";
import get from "lodash/get";
import AdminTable from "../AdminTable";
import useStyles from "./style.js";
import {
  fetchUsers,
  deleteUser,
  enableOrDisableUser,
  deleteUsers,
} from "../../../redux/actions/services/index";
import { FETCH_USERS } from "../../../redux/constants/index";
import StatusLegend from "components/StatusLegend";
import PageHeader from "../../../components/PageHeader";
import { formatDateByLanguage, isFleetAdministrator } from "../../../util";
import { isPermitted } from "../../../components/HasPermission";

const headerCells = {
  fields: [
    {
      id: "status",
      numeric: false,
      disablePadding: false,
      gridSize: 1,
      align: "center",
      label: "administration.status",
    },
    {
      id: "logo",
      numeric: false,
      disablePadding: false,
      gridSize: 1,
      align: "center",
      label: "",
    },
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      gridSize: 2,
      align: "left",
      label: "administration.name",
    },
    {
      id: "creationDate",
      numeric: false,
      disablePadding: true,
      gridSize: 2,
      align: "left",
      label: "administration.creationDate",
    },
    {
      id: "email",
      numeric: false,
      disablePadding: false,
      gridSize: 3,
      align: "left",
      label: "administration.email",
    },
    {
      id: "fleet",
      numeric: false,
      disablePadding: false,
      gridSize: 1,
      align: "left",
      label: "user.administration.fleet",
    },
    {
      id: "lastLogin",
      numeric: false,
      disablePadding: false,
      gridSize: 2,
      align: "left",
      label: "user.administration.lastLogin",
    },
  ],
};
const displayFields = [
  {
    id: "status",
    gridSize: 1,
    align: "center",
    valuePath: "status",
    isStatus: true,
    translate: false,
  },
  {
    id: "logo",
    gridSize: 1,
    align: "center",
    valuePath: "",
    isAvatar: true,
    avatarPath: "logo",
  },
  {
    id: "name",
    gridSize: 2,
    align: "left",
    valuePath: ["civility.code", "firstName", "lastName"],
  },
  {
    id: "creationDate",
    gridSize: 2,
    align: "left",
    valuePath: "creationDate",
    defaultValue: "--:--:--",
    translate: false,
    format: (v) => formatDateByLanguage(v, false),
  },
  {
    id: "email",
    gridSize: 3,
    align: "left",
    valuePath: "email",
  },
  {
    id: "fleet",
    gridSize: 1,
    align: "left",
    valuePath: "fleet.companyName",
  },
  {
    id: "lastLogin",
    gridSize: 2,
    align: "left",
    valuePath: "lastLogin",
    defaultValue: "---",
    format: (v) => formatDateByLanguage(v),
    translate: false,
  },
];

const statusList = [
  { code: "ACTIVE", label: "ACTIVE" },
  { code: "DISABLE", label: "DISABLE" },
];

const sortByList = [
  { code: "firstName", label: "firstName" },
  { code: "lastName", label: "lastName" },
  { code: "fleet", label: "user.management.formLabel.fleet" },
];

const labels = {
  title: "user.management.title",
  addButton: "user.management.add.button",
  deleteEntity: "user.management.delete.confirmMsg",
  deleteEntities: "user.management.deleteUsersActionMessage",
  enableEntity: "user.management.enaDes.confirmMsg",
  enableConfirm: "user.management.enaDes.button.confirm",
  disableEntity: "user.management.desaDes.confirmMsg",
  disableConfirm: "user.management.desaDes.button.confirm",
  searchPlaceHolder: "Search",
  confirmAction: "Delete",
  deleteAll: "tooltip.action.delete",
  entityDeleted: "user deleted successfully"
};
export default function UserManagement() {
  const searchField = "userName";
  const entityBaseUrl = "/userAdministration/users";
  const enableDisableEntity = enableOrDisableUser;
  const fetchEntities = fetchUsers;
  const deleteEntity = deleteUser;
  const deleteEntities = deleteUsers;
  const entityQuery = FETCH_USERS;
  const classes = useStyles();
  const { t } = useTranslation();
  const currentUser = useSelector(({ requests }) => get(requests, "queries.FETCH_CURRENT_USER.data"));
  const statues = ["ACTIVE", "DISABLE"]

  return (
    <div className={classes.root}>
      <div className={classes.paper}>
        <PageHeader title={labels.title} />
        <Grid container xs={12}>
          <Grid container item xs justify="center">
            <StatusLegend statues={statues} />
          </Grid>
        </Grid>
        <AdminTable
          statusList={statusList}
          sortByList={sortByList}
          displayFields={displayFields}
          headerCells={headerCells}
          entityBaseUrl={entityBaseUrl}
          enableDisableEntity={enableDisableEntity}
          fetchEntities={fetchEntities}
          deleteEntity={deleteEntity}
          deleteEntities={deleteEntities}
          entityQuery={entityQuery}
          searchField={searchField}
          labels={labels}
          isUserAdministrationPage
          showAddIcon={(isFleetAdministrator(currentUser)
              || isPermitted(currentUser, "Add user"))}
          addButtonText={t(labels.addButton)}
        />
      </div>
    </div>
  );
}
