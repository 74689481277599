import React, { useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";
import Box from "@material-ui/core/Box";
import clsx from "clsx";
import { isEmpty } from "lodash";
import { formatImagePath } from "../../../util";
import EmptyDropSection from "../../../components/EmptyDropSection";
import SearchField from "../../../components/EmptyDropSection/SearchField";
import DataNotFound from "../../../components/DataNotFound";
import { ReactComponent as ProcessDefaultIcon } from "assets/ProcessDefaultIcon.svg"
import useStyles from "./style.js";

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: `${grid}px ${grid * 2}px`,
  margin: `0 0 ${grid}px 0`,
  boxShadow:
      "0px 1px 2px 0px rgb(172,186,255)",
  fontWeight: 400,
  fontSize: 14,
  borderRadius: "2px",
  // change background colour if dragging
  background: "#FFFFFF",

  // styles we need to apply on draggables
  ...draggableStyle,
});

const ProcessSelector = ({
  availableProcesses,
  selectedProcesses,
  isDisabled,
  setAvailableProcesses,
  setSelectedProcesses,
  onAssignProcess,
  onUnAssignProcess,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [availableSearchTerm, setAvailableSearchTerm] = useState("")
  const [assignedSearchTerm, setAssignedSearchTerm] = useState("")

  const getList = (id) => (id === "droppable" ? availableProcesses : selectedProcesses);

  const validateDragDrop = (
    source,
    destination,
    droppableSource,
    droppableDestination,
  ) => {
    const sourceClone = Array.from(source);
    const [removed] = sourceClone.splice(droppableSource.index, 1);
    if (droppableDestination.droppableId === "droppable") {
      if (onUnAssignProcess) return onUnAssignProcess(removed);
    } else if (onAssignProcess) return onAssignProcess(removed);
    return true;
  };

  const onDragEnd = async (result) => {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      const items = reorder(
        getList(source.droppableId),
        source.index,
        destination.index,
      );

      // let state = { items };
      if (source.droppableId === "droppable2") {
        setSelectedProcesses(items);
      } else {
        setAvailableProcesses(items);
      }
    } else {
      const result = move(
        getList(source.droppableId),
        getList(destination.droppableId),
        source,
        destination,
      );
      setAvailableProcesses(result.droppable);
      setSelectedProcesses(result.droppable2);

      const res = await validateDragDrop(
        getList(source.droppableId),
        getList(destination.droppableId),
        source,
        destination,
      );
      if (!res) {
        setSelectedProcesses(getList(source.droppableId));
        setAvailableProcesses(getList(destination.droppableId));
      }
    }
  };
  const isBothEmpty = isEmpty(selectedProcesses) && isEmpty(availableProcesses);

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Grid item container xs={12}>
        <Grid
          item
          container
          className={clsx(classes.dropabbleTitle, classes.processContainer)}
        >
          <Typography className={classes.droppableTitle}>{t("processesSelector.availableProcess")}</Typography>
        </Grid>
        <Grid item className={classes.processSeparator} />
        <Grid
          item
          container
          className={clsx(classes.dropabbleTitle, classes.processContainer)}
        >
          <Typography className={classes.droppableTitle}>{t("processesSelector.assignedProcess")}</Typography>
        </Grid>
        <Grid item container xs={12}>
          <SearchField
              searchTerm={availableSearchTerm}
              setSearchTerm={setAvailableSearchTerm}
              placeholder={t("search.available.processes")}
              disabled={isEmpty(availableProcesses)}
              justify="flex-start"
          />
          <SearchField
              searchTerm={assignedSearchTerm}
              setSearchTerm={setAssignedSearchTerm}
              placeholder={t("search.assigned.processes")}
              disabled={isEmpty(selectedProcesses)}
              justify="flex-end"
          />
        </Grid>
      </Grid>
      <Grid item container xs={12}>
        <Grid item className={classes.processContainer}>
          <Card className={classes.card}>
            <Droppable droppableId="droppable" className={classes.droppableContainer}>
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  className={classes.droppableContainer}
                >
                  {/* eslint-disable-next-line no-nested-ternary */}
                  {isBothEmpty ? <DataNotFound iconHeight={40} />
                  : isEmpty(availableProcesses)
                          ? <EmptyDropSection />

                  : availableProcesses?.map((item, i) => ({ ...item, i })).filter((item) => (availableSearchTerm.trim() !== "" ? item?.processDescription?.processDisplayName?.toLowerCase()?.includes(availableSearchTerm.trim()) : true))?.map((item) => (
                    <Draggable
                      key={`available-process-draggable-${item.id}`}
                      draggableId={`available-process-draggable-${item.id}`}
                      index={item.i}
                      isDragDisabled={isDisabled}
                    >
                      {(provided, snapshot) => (
                        <Paper
                          // style={{
                          //   borderLeft: `2px solid ${getRandomColorRGB(
                          //     item?.processDescription?.id
                          //   )}`,
                          // }}
                          style={{ background: "none", boxShadow: "none" }}
                        >
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style,
                            )}
                          >
                            <Box display="flex" alignItems="center" gridColumnGap={10}>
                              {item?.icon ? (
                                <img
                                      src={formatImagePath(item?.icon)}
                                      align="left"
                                      alt="Service"
                                      className={classes.processAvatar}
                                  />
                              ) : (
                                <ProcessDefaultIcon className={classes.processAvatar} />
                              )}
                              {item?.processDescription?.processDisplayName}
                            </Box>
                          </div>
                        </Paper>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </Card>
        </Grid>
        <Grid item className={classes.processSeparator} />
        <Grid item container justify="flex-end" className={classes.processContainer}>
          <Card className={classes.card}>
            <Droppable droppableId="droppable2" className={classes.droppableContainer}>
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  className={classes.droppableContainer}
                >
                  {/* eslint-disable-next-line no-nested-ternary */}
                  {isBothEmpty ? <DataNotFound iconHeight={40} />
                  : isEmpty(selectedProcesses) ? (
                    <EmptyDropSection />
                  )
                      : (
                      selectedProcesses?.map((item, i) => ({ ...item, i })).filter((item) => (assignedSearchTerm.trim() !== "" ? item?.processDescription?.processDisplayName?.toLowerCase()?.includes(assignedSearchTerm.trim()) : true))?.map((item) => (
                        <Draggable
                              key={`selected-process-draggable-${item.id}`}
                              draggableId={`selected-process-draggable-${item.id}`}
                              index={item.i}
                              isDragDisabled={isDisabled}
                          >
                          {(provided, snapshot) => (
                            <Paper style={{ background: "none", boxShadow: "none" }}>
                              <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      style={getItemStyle(
                                          snapshot.isDragging,
                                          provided.draggableProps.style,
                                      )}
                                  >
                                <Box display="flex" alignItems="center" gridColumnGap={10}>
                                  {item?.icon ? (
                                    <img
                                          src={formatImagePath(item?.icon)}
                                          align="left"
                                          alt="Service"
                                          className={classes.processAvatar}
                                      />
                                  ) : (
                                    <ProcessDefaultIcon className={classes.processAvatar} />
                                  )}
                                  {item?.processDescription?.processDisplayName}
                                </Box>
                              </div>
                            </Paper>
                            )}
                        </Draggable>
                      ))
                  )}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </Card>
        </Grid>
      </Grid>
    </DragDropContext>
  );
};

export default ProcessSelector;
