import React from "react";
import Drawer from "@material-ui/core/Drawer"
import Box from "@material-ui/core/Box"
import Typography from "@material-ui/core/Typography"
import Grid from "@material-ui/core/Grid"
import CustomCloseButton from "pages/Services/components/CustomCloseButton";
import OutlinedInput from "@material-ui/core/OutlinedInput"
import { useTranslation } from "react-i18next";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import { Alert, Autocomplete } from "@material-ui/lab";
import { useDispatch, useSelector } from "react-redux";
import get from "lodash/get";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import {
  bulkAssignException,
  bulkMarkExceptionAsDone,
  bulkReleaseException,
  bulkTakeException,
  bulkReopenException,
  assignNotify,
  fetchFleetUsers,
} from "../../../../../redux/actions/services";
import { isSelectionOkforBulkActions } from "../workflowUtil";
import ConfirmMessage from "../../../../../components/ConfirmMessage";
import { isPermitted } from "../../../../../components/HasPermission";
import { toast } from "react-toastify";
import CustomButton from "../../../../../components/CustomButton";
import useStyles from "../style";

function WorkflowBulkDrawer({
  open, onClose, items, fetchData, resetSelected, updateAssigners,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selectedUser, setSelectedUser] = React.useState(null);
  const [confirmDialog, setConfirmDialog] = React.useState({
    open: false,
    message: null,
    onConfirm: null,
  });
  const [actionLoading, setActionLoading] = React.useState(false);
  const currentUser = useSelector(({ requests }) => get(requests, "queries.FETCH_CURRENT_USER.data"));
  const users = useSelector(({ requests }) => get(requests, "queries.FETCH_FLEET_USERS.data"));

  React.useEffect(() => {
    if (currentUser) {
      dispatch(fetchFleetUsers(currentUser?.fleet?.id));
    }
  }, [currentUser]);

  React.useEffect(() => {
    if (!actionLoading) {
      setConfirmDialog({
        open: false,
        message: null,
        onConfirm: null,
      });
    }
  }, [actionLoading]);

  const makeResponseSideEffects = (res, actionMessage) => {
    if (res.status === 200) {
      fetchData();
      toast.success(actionMessage)
    } else {
      toast.error(t("exception-workflow.snackbar.error"))
    }
  };

  const bulkTake = () => {
    setActionLoading(true);
    const actionMessage = t("exception-workflow.snackbar.success.bulkTake");
    dispatch(bulkTakeException(items.map((i) => i.id))).then((res) => {
      resetSelected([]);
      setActionLoading(false);
      makeResponseSideEffects(res, actionMessage);
      updateAssigners();
      onClose();
    });
  };

  const bulkAssign = () => {
    if (selectedUser) {
      setActionLoading(true);
      const actionMessage = t("exception-workflow.snackbar.success.bulkAssign");
      dispatch(bulkAssignException(items.map((i) => i.id), selectedUser.id)).then((res) => {
        resetSelected([]);
        setActionLoading(false);
        makeResponseSideEffects(res, actionMessage);
        updateAssigners();
        onClose();
      });
      assignExceptionNotify();
    }
  };

  const assignExceptionNotify = () => {
    if (selectedUser) {
      const actionMessage = t("exception-workflow.snackbar.success.assign.notify");
      dispatch(assignNotify(selectedUser.id, items.length)).then((res) => {
        makeResponseSideEffects(res, actionMessage);
      });
    }
  };

  const bulkRelease = () => {
    setActionLoading(true);
    const actionMessage = t("exception-workflow.snackbar.success.bulkRelease");
    dispatch(bulkReleaseException(items.map((i) => i.id))).then((res) => {
      resetSelected([]);
      setActionLoading(false);
      makeResponseSideEffects(res, actionMessage);
      updateAssigners();
      onClose();
    });
  };

  const bulkMarkAsDone = () => {
    setActionLoading(true);
    const actionMessage = t("exception-workflow.snackbar.success.bulkMarkAsDone");
    dispatch(bulkMarkExceptionAsDone(items.map((i) => i.id))).then((res) => {
      resetSelected([]);
      setActionLoading(false);
      makeResponseSideEffects(res, actionMessage);
      onClose();
    });
  };

  const bulkReopen = () => {
    setActionLoading(true);
    const actionMessage = t("exception-workflow.snackbar.success.bulkReopen");
    dispatch(bulkReopenException(items.map((i) => i.id))).then((res) => {
      resetSelected([]);
      setActionLoading(false);
      makeResponseSideEffects(res, actionMessage);
      onClose();
    });
  };

  const selectionOkforBulkActions = isSelectionOkforBulkActions(items, currentUser);

  if (!items) return null;

  return (
    <>
      <Drawer
        anchor="right"
        open={open}
        onClose={onClose}
        PaperProps={{
          className: classes.drawerPaper,
        }}
      >
        <Box
          py={2}
          mb={2}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box display="flex" alignItems="center" gridColumnGap={10}>
            <AccountTreeIcon />
            <Typography variant="h6" display="inline">
              {t("exception-workflow.open")}
            </Typography>
          </Box>
          <CustomCloseButton onClick={onClose} />
        </Box>
        <Typography align="center" color="textSecondary">
          {t("exception-workflow.selected-message", { count: items.length })}
        </Typography>
        {Object.values(selectionOkforBulkActions).some((s) => s === true) ? (
          <Grid container className={classes.workflowActions}>
            <Grid container item spacing={2}>
              {
                            // isActionAllowed(actions.TAKE) &&
                            selectionOkforBulkActions.TAKE
                            && (
                            <Grid item xs={6}>
                              <CustomButton
                                view="primary"
                                fullWidth
                                disableElevation
                                onClick={() => {
                                  bulkTake();
                                }}
                              >
                                {t("exception-workflow.action.take")}
                              </CustomButton>
                            </Grid>
                            )
                        }
              {
                            // isActionAllowed(actions.MARK_AS_DONE) &&
                            selectionOkforBulkActions.MARK_AS_DONE
                            && (
                            <Grid item xs={6}>
                              <CustomButton
                                view="primary"
                                fullWidth
                                disableElevation
                                onClick={() => setConfirmDialog({
                                  open: true,
                                  message: t("exception-workflow.confirm.bulk-markAsDone", { count: items.length }),
                                  onConfirm: bulkMarkAsDone,
                                })}
                              >
                                {t("exception-workflow.action.markAsDone")}
                              </CustomButton>
                            </Grid>
                            )
                        }
              {
                            // isActionAllowed(actions.RELEASE) &&
                            selectionOkforBulkActions.RELEASE
                            && (
                            <Grid item xs={6}>
                              <CustomButton
                                view="primary"
                                fullWidth
                                disableElevation
                                onClick={() => {
                                  bulkRelease();
                                }}
                              >
                                {t("exception-workflow.action.release")}
                              </CustomButton>
                            </Grid>
                            )
                        }
              {
                            // isActionAllowed(actions.REOPEN) &&
                            selectionOkforBulkActions.REOPEN
                            && (
                            <Grid item xs={6}>
                              <CustomButton
                                view="primary"
                                fullWidth
                                disableElevation
                                onClick={() => setConfirmDialog({
                                  open: true,
                                  message: t("exception-workflow.confirm.bulk-reopen", { count: items.length }),
                                  onConfirm: bulkReopen,
                                })}
                              >
                                {t("exception-workflow.action.reopen")}
                              </CustomButton>
                            </Grid>
                            )
                        }
              {
                            selectionOkforBulkActions.ASSIGNEE
                            && (
                            <Grid container item xs={12}>
                              <Grid item xs={8}>
                                <Autocomplete
                                        // eslint-disable-next-line eqeqeq
                                  options={users.filter((u) => isPermitted(u, "Workflow") && u?.id !== currentUser.id)}
                                  getOptionLabel={(user) => `${user.firstName} ${user.lastName}`}
                                  renderInput={(params) => (
                                    <Box ref={params.InputProps.ref} height="36.5px">
                                      <OutlinedInput
                                        {...params.inputProps}
                                        placeholder={t("exception-workflow.choose-user")}
                                        style={{
                                          height: "100%", width: "100%", backgroundColor: "white", borderTopRightRadius: 0, borderBottomRightRadius: 0,
                                        }}
                                        startAdornment={<AccountCircleIcon style={{ marginRight: "10px" }} />}
                                      />
                                    </Box>
                                  )}
                                  onChange={(e, val) => setSelectedUser(val)}
                                />
                              </Grid>
                              <Grid item xs={4}>
                                <CustomButton
                                  view="primary"
                                  fullWidth
                                  disableElevation
                                  style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                                  disabled={!selectedUser}
                                  onClick={() => setConfirmDialog({
                                    open: true,
                                    message: t("exception-workflow.confirm.bulk-assign", { count: items.length, selectedUser }),
                                    onConfirm: bulkAssign,
                                  })}
                                >
                                  {t("exception-workflow.action.assign")}
                                </CustomButton>
                              </Grid>
                            </Grid>
                            )
                        }
            </Grid>
          </Grid>
        ) : Object.values(selectionOkforBulkActions).every((s) => s === false) && (
        <Alert severity="warning" style={{ marginTop: "24px" }}>
          {t("exception-workflow.no-bulk-action-possible")}
        </Alert>
        )}

      </Drawer>

      <ConfirmMessage
        message={confirmDialog.message}
        openStart={confirmDialog.open}
        onCancel={() => setConfirmDialog({
          open: false,
          message: null,
          onConfirm: null,
        })}
        onConfirm={confirmDialog.onConfirm}
        buttonConfirm={t("Confirm")}
        buttonCancel={t("Cancel")}
        isLoading={actionLoading}
      />
    </>
  );
}

export default WorkflowBulkDrawer;
