import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { fetchDashboardRobotProductivity } from "../../../redux/actions/services/dashboard";
import { handleRequestMonitoringMode } from "../dashboardUtils";
import CircularLoader from "../../../components/Loaders/CircularLoader";
import OccupancyChart from "./OccupancyChart";
import ProductivityChart from "./ProductivityChart";
import AllRobotsProductivity from "pages/Dashboard/RobotProductivity/AllRobotsProductivity";
import useStyles from "../style";

export default function RobotProductivity({ xs, userFleet }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const filterDashboard = useSelector(({ filterDashboard }) => filterDashboard);
  const refreshTime = filterDashboard?.monitoringMode;
  const fetchIntervalRef = React.useRef(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [openTable, setOpenTable] = React.useState(false);
  const [robotProductivity, setRobotProductivity] = React.useState();

  const fetchRobotProductivity = () => {
      if (userFleet && filterDashboard) {
          setIsLoading(true);
          dispatch(
              fetchDashboardRobotProductivity({
                  ...filterDashboard
              })
          ).then((res) => {
              setRobotProductivity(res?.data);
              setIsLoading(false);
          });
      }
  }

  useEffect(() => {
    fetchRobotProductivity()
  }, [filterDashboard, userFleet?.id]);

  useEffect(() => {
      handleRequestMonitoringMode(refreshTime, fetchIntervalRef, fetchRobotProductivity)
  }, [refreshTime])

  return (
    <>
      <Grid item container xs={xs} className={classes.container}>
        <Typography className={classes.title}>
          {t("Robot Productivity")}
        </Typography>
        {isLoading || !robotProductivity ? (
          <CircularLoader height={500} />
              ) : (
                <>
                  <Grid item xs={12}>
                    <ProductivityChart data={robotProductivity?.productivity} userFleet={userFleet} setOpenTable={setOpenTable} />
                  </Grid>
                  <Grid item xs={12}>
                    <OccupancyChart data={robotProductivity?.occupancy} />
                  </Grid>
                </>
              )}
      </Grid>
      {open && (
      <AllRobotsProductivity
                userFleet={userFleet}
                open={openTable}
                handleDialogClose={() => setOpenTable(false)}
              />
          )}
    </>
  );
}
