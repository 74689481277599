import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  root: {
    width: "100%",
    background: "transparent",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
    padding: theme.spacing(4),
  },
  rootGrid: {
    marginTop: theme.spacing(5),
  },
  trigger: {
    color: theme.palette.secondary.main,
    fontSize: "1.5rem",
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  notification: {
    color: theme.custom.color.color4,
    fontSize: "1.5rem",
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  help: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  notificationHelper: {
    fontSize: "0.875rem",
    fontFamily: "Roboto",
    fontWeight: "400",
  },
  NotificationIcons: {
    color: theme.custom.color.alertDefaultIconColor,
    marginTop: "20px",
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  flagLabel: {
    fontSize: "1rem",
  },
  flagLabelGrey: {
    fontSize: "1rem",
    color: "grey",
  },
  triggerIcon: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    color: theme.palette.secondary.main,
  },
  triggerGridIcon: {
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(1),
  },
  validation_error: {
    color: "red",
    margin: "0",
    marginTop: theme.spacing(1),
  },
  select: {
    height: "30px",
  },
  error: {
    color: "#FFF",
    backgroundColor: theme.custom.color.errorChipColor,
    marginRight: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
  },
  triggerError: {
    color: theme.custom.color.errorChipColor,
  },
  valid: {
    color: "#FFF",
    backgroundColor: theme.palette.secondary.main,
    marginRight: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
  },
  deleteIcon: {
    color: theme.custom.color.color4,
  },
  warningColor: {
    color: theme.custom.color.color3,
  },
  errorColor: {
    color: theme.custom.color.color4,
  },
  er: {
    color: theme.custom.color.color4,
    fontSize: "1.5rem",
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  infoColor: {
    color: theme.palette.secondary.main,
  },
  hourly: {
    position: "relative",
    bottom: theme.spacing(3),
  },
  daily: {
    position: "relative",
    top: theme.spacing(2),
  },
  weekly: {
    position: "relative",
    top: theme.spacing(8),
  },
  monthly: {
    position: "relative",
    top: theme.spacing(14),
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    width: "25%",
  },

  cardActions: {
    justifyContent: "center",
    marginTop: theme.spacing(2),
    padding: theme.spacing(1),
  },

  typograpgy: {
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
  },
  selectEmpty: {
    width: theme.spacing(20),
  },
  schedulerRoot: {
    padding: theme.spacing(2),
  },
  input: {
    width: theme.spacing(30),
  },
  tableRow: {
    background: theme.palette.common.white,
    cursor: "pointer",
    padding: "20px 0px",
  },
  tableCell: {
    fontWeight: "400",
    paddingLeft: theme.spacing(3),
  },
  tableCellGrey: {
    fontWeight: "400",
    paddingLeft: theme.spacing(3),
    color: "grey",
  },
  statusCell: {
    fontWeight: "400",
    paddingLeft: theme.spacing(5),
  },
  viewClickContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  schedulerTitles: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    fontWeight: "700"
  },
  radioSchedulerTitles: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
  },
  personIcon: {
    color: theme.palette.secondary.main,
    marginTop: "10px",
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  processSkeleton: {
    with: "100%",
    height: "50%",
    marginTop: theme.spacing(2),
  },
}));
