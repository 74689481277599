import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { format } from "date-fns-tz";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, useDispatch, useSelector } from "react-redux";
import OccurrencesNotFound from "../../../assets/Process_Overview.svg";
import DataNotFound from "../../../components/DataNotFound";
import CircularLoader from "../../../components/Loaders/CircularLoader";
import PageHeader from "../../../components/PageHeader";
import StatusButton from "../../../components/StatusButton";
import { ReactComponent as ProcessDefaultIcon } from "assets/ProcessDefaultIcon.svg"
import {
  fetchExecutions,
  fetchAllStatus,
  exportFilteredExecutionsXLSX,
  fetchExportedFiles,
  fetchTagsForFilters,
  fetchExecutionsCount,
  fetchFilterProcesses,
  fetchExecutionsRobots
} from "../../../redux/actions/services";
import {
  formatImagePath, secondsToTime, formatDateByLanguage
} from "../../../util";
import Filter from "./Filter";
import useStyles from "./style";
import { toast } from "react-toastify";
import i18n from "../../../i18n";
import CustomPagination from "pages/Services/components/CustomPagination";
import { useQuery } from "@redux-requests/react";
import { FETCH_CURRENT_USER } from "../../../redux/constants";

const dateFormat = "yyyy/MM/dd";
function ExecutionsPage(props) {
  const classes = useStyles();
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("launchingDate");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [loaded, setLoaded] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [count, setCount] = React.useState();
  const [processName, setProcessName] = React.useState([]);
  const [processes, setProcesses] = React.useState([]);
  const [tagName, setTagName] = React.useState([]);
  const [tags, setTags] = useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [status, setStatus] = React.useState([]);
  const [statusRef, setStatusRef] = React.useState([]);
  const [robotName, setRobotName] = React.useState([]);
  const [robots, setRobots] = React.useState([]);
  const [triggers, setTriggers] = React.useState([]);
  const [executionStartTime, setExecutionStartTime] = React.useState(null);
  const [executionEndTime, setExecutionEndTime] = React.useState(null);
  const [searchText, setSearchText] = React.useState(null);
  const executionsFilter = useSelector(({ executionsFilter }) => executionsFilter);
  const [showCustomDate, setShowCustomDate] = React.useState(false);
  const [exportLoading, setExportLoading] = React.useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const EMPTY_DEFAULT_VALUE = "---";
  const currentUser = useQuery({ type: FETCH_CURRENT_USER }).data;

  useEffect(() => {
    if (!loaded && currentUser) {
      setLoaded(true);
      props.fetchFilterProcesses().then((result) => {
        setProcesses(result?.data?.list);
        props.fetchExecutionsRobots([]).then((result) => {
          setRobots(result?.data);
        })
      });
      props.fetchTagsForFilters().then((result) => {
        setTags(result.data);
      });
      props.fetchAllStatus().then((result) => {
        let statusList = result.data;
        if (result?.data) {
          statusList = result?.data;
          statusList = statusList.sort((a, b) => (`${a.statusLabel}`).localeCompare(b.statusLabel));
        }
        setStatusRef(statusList);
      });
      getExecutions(
        page,
        rowsPerPage,
        executionsFilter.order.id,
        executionsFilter.order.order,
        executionsFilter.process,
        executionsFilter.status,
        executionsFilter.executionFromDate,
        executionsFilter.executionToDate,
        executionsFilter.searchText,
        executionsFilter.robot,
        executionsFilter.trigger,
        executionsFilter.tags
      );
    }
  }, []);

  const getDateDifference = (time) => secondsToTime(time, t);

  const handleChangeProcess = (values) => {
    setProcessName(values);
    setPage(0);
    getExecutions(
      0,
      rowsPerPage,
      orderBy,
      order,
      values,
      status,
      executionStartTime,
      executionEndTime,
      searchText,
      robotName,
      triggers,
      tagName
    );
  };
  const handleChangeTags = (values) => {
    setTagName(values);
    setPage(0);
    getExecutions(
        0,
        rowsPerPage,
        orderBy,
        order,
        processName,
        status,
        executionStartTime,
        executionEndTime,
        searchText,
        robotName,
        triggers,
        values
    );
  }
  const handleChangeDates = (from, to) => {
    if (from && to) {
      const fromString = `${from}`;
      const toString = `${to}`;
      from = fromString.includes("/") ? from : format(from, dateFormat);
      to = toString.includes("/") ? to : format(to, dateFormat);
    }
    setExecutionStartTime(from);
    setExecutionEndTime(to);
    setPage(0);
    getExecutions(
      0,
      rowsPerPage,
      orderBy,
      order,
      processName,
      status,
      from,
      to,
      searchText,
      robotName,
      triggers,
      tagName
    );
  };

  const handleChangeStatus = (values) => {
    setStatus(values);
    setPage(0);
    getExecutions(
      0,
      rowsPerPage,
      orderBy,
      order,
      processName,
      values,
      executionStartTime,
      executionEndTime,
      searchText,
      robotName,
      triggers,
      tagName
    );
  };

  const handleChangeRobot = (values) => {
    setRobotName(values);
    setPage(0);
    getExecutions(
      0,
      rowsPerPage,
      orderBy,
      order,
      processName,
      status,
      executionStartTime,
      executionEndTime,
      searchText,
      values,
      triggers,
      tagName
    );
  };

  const handleChangeTrigger = (values) => {
    setTriggers(values);
    setPage(0);
    getExecutions(
      0,
      rowsPerPage,
      orderBy,
      order,
      processName,
      status,
      executionStartTime,
      executionEndTime,
      searchText,
      robotName,
      values,
      tagName
    );
  };

  const handleRequestSort = (property) => {
    setOrder(property.order);
    setOrderBy(property.id);
    getExecutions(
      page,
      rowsPerPage,
      property.id,
      property.order,
      processName,
      status,
      executionStartTime,
      executionEndTime,
      searchText,
      robotName,
      triggers,
      tagName
    );
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getExecutions(
      newPage,
      rowsPerPage,
      orderBy,
      order,
      processName,
      status,
      executionStartTime,
      executionEndTime,
      searchText,
      robotName,
      triggers,
      tagName
    );
  };

  const handleNext = (page) => {
    setPage(page + 1);
    getExecutions(
      page + 1,
      rowsPerPage,
      orderBy,
      order,
      processName,
      status,
      executionStartTime,
      executionEndTime,
      searchText,
      robotName,
      triggers,
      tagName
    );
    }
    const handlePrevious = (page) => {
      setPage(page - 1);
    getExecutions(
      page - 1,
      rowsPerPage,
      orderBy,
      order,
      processName,
      status,
      executionStartTime,
      executionEndTime,
      searchText,
      robotName,
      triggers,
      tagName
    );
    }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
    getExecutions(
      0,
      event.target.value,
      orderBy,
      order,
      processName,
      status,
      executionStartTime,
      executionEndTime,
      searchText,
      robotName,
      triggers,
      tagName
    );
  };

  const getExecutions = (
    page,
    size,
    sortField,
    sortOrder,
    processesList = [],
    status = [],
    executionStartTime = null,
    executionEndTime = null,
    searchText = "",
    robots = [],
    triggers = [],
    tags = []
  ) => {
    setIsLoading(true);
    const processID = !processesList || processes?.length === processesList?.length ? [] : processesList.map((e) => e.id);
    const statusId = status ? status.map((e) => e.id) : [];
    const robotId = robots ? robots.map((e) => e.id) : [];
    const tagId = tags ? tags.map((e) => e.id) : [];
    props
      .fetchExecutions(
        page,
        size,
        sortField,
        sortOrder,
        processID,
        statusId,
        executionStartTime,
        executionEndTime,
        searchText,
        robotId,
        triggers,
        tagId
      )
      .then((result) => {
        setData(result?.data);
        setIsLoading(false);
      });
    props
        .fetchExecutionsCount(
            processID,
            statusId,
            executionStartTime,
            executionEndTime,
            searchText,
            robotId,
            triggers,
            tagId
        )
        .then((result) => {
          setCount(result?.data);
        });
  };
  const exportFilteredExecutions = () => {
    localStorage.setItem("lgn", i18n.language);
    setExportLoading(true)
    const processID = processName ? processName.map(({ id }) => id) : [];
    const statusId = status ? status.map(({ id }) => id) : [];
    const robotId = robotName ? robotName.map(({ id }) => id) : [];
    const tagId = tagName ? tagName.map(({ id }) => id) : [];
    dispatch(
        exportFilteredExecutionsXLSX(
            orderBy,
            order,
            processID,
            statusId,
            executionStartTime,
            executionEndTime,
            searchText,
            robotId,
            triggers,
            tagId,
            onSuccesExport,
            onErrorExport
        )
    )
  }
  const onSuccesExport = () => {
    setExportLoading(false);
    dispatch(fetchExportedFiles());
    toast.success(t("export.successful.notification"));
  }
  const onErrorExport = () => {
    setExportLoading(false);
    toast.error(t("something went wrong"));
  }

  const handleChangeSearchText = (value) => {
    setSearchText(value);
    setPage(0);
    getExecutions(
      0,
      rowsPerPage,
      orderBy,
      order,
      processName,
      status,
      executionStartTime,
      executionEndTime,
      value,
      robots,
      triggers,
      tagName
    );
  };
  const handleClearFilter = () => {
    getExecutions(
      0,
      rowsPerPage,
      columns[0].id,
      columns[0].order,
    );
  }
  return (
    <Grid
      container
      alignItems="stretch"
      className={classes.rootGlobal}
    >
      <PageHeader title="Executions history" />
      <Grid container item xs={12} direction="row" justify="space-between">
        <Filter
          processes={processes}
          status={statusRef}
          robots={robots}
          tags={tags}
          handleChangeProcess={handleChangeProcess}
          handleChangeStatus={handleChangeStatus}
          handleChangeRobot={handleChangeRobot}
          handleChangeDates={handleChangeDates}
          handleChangetags={handleChangeTags}
          handleRequestSort={handleRequestSort}
          handleChangeTrigger={handleChangeTrigger}
          handleChangeSearchText={handleChangeSearchText}
          handleClearFilter={handleClearFilter}
          columns={columns}
          showCustomDate={showCustomDate}
          setShowCustomDate={setShowCustomDate}
          exportFilteredExecutions={exportFilteredExecutions}
          exportLoading={exportLoading}
          showExportButton={data && data.length > 0}
        />
      </Grid>
      {
        isLoading || !data ? (
          <CircularLoader />
        ) : (
          <>
            {(data && data.length > 0 && (
            <Grid container xs={12} className={classes.headerSpacing}>
              <Grid container item xs={2}>
                <Grid container item xs={3} />
                <Grid container item xs={9} direction="column" justify="center">
                  <Typography
                    variant="body2"
                    align="center"
                    className={classes.content}>
                    {t("Process")}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container item xs={2} direction="column" justify="center">
                <Typography
                  variant="body2"
                  align="center"
                  className={classes.content}
              >
                  {t("Robot")}
                </Typography>
              </Grid>
              <Grid container item xs={3} direction="column" justify="center">
                <Typography
                  variant="body2"
                  align="center"
                  className={classes.content}
              >
                  {t("Dates")}
                </Typography>
              </Grid>
              <Grid container item xs={3} direction="column" justify="center">
                <Typography
                  variant="body2"
                  align="center"
                  className={classes.content}
              >
                  {t("Execution detail")}
                </Typography>
              </Grid>
              <Grid container item xs={1} direction="column" justify="center">
                <Typography
                  variant="body2"
                  align="center"
                  className={classes.content}
              >
                  {t("Trigger")}
                </Typography>
              </Grid>
              <Grid container item xs={1} direction="column" justify="center">
                <Typography
                  variant="body2"
                  align="center"
                  className={classes.content}
              >
                  {t("Status")}
                </Typography>
              </Grid>
            </Grid>
          ))}
            {(data && data.length > 0
              ? data.map((row, index) => (
                <Grid container key={index}>
                  <Card
                        key={index}
                        className={classes.root}
                        style={{ width: "100%" }}
                        elevation={1}
                    >
                    <CardContent className={classes.content}>
                      <Grid container xs={12}>
                        <Grid container item xs={2} alignItems="center">
                          <Grid item xs={3}>
                            <Box display="flex" justifyContent="center">
                              {row
                                && row.process
                                && row.process.icon ? (
                                  <img
                                        src={formatImagePath(
                                            row.process.icon
                                        )}
                                        align="left"
                                        alt="Service"
                                        className={classes.image}
                                    />
                                ) : (
                                  <ProcessDefaultIcon className={classes.image} />
                                )}
                            </Box>
                          </Grid>
                          <Grid item xs={9}>
                            <Typography variant="body2" align="left">
                              <Box fontWeight="fontWeightBold" display="flex" justifyContent="center">
                                {row.process?.processName
                                      ? row.process?.processName
                                      : EMPTY_DEFAULT_VALUE}
                              </Box>
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container alignItems="center" justify="center" item xs={2}>
                          <Typography variant="body2">
                            {row?.robot?.name || row?.startingRobot?.name || EMPTY_DEFAULT_VALUE}
                          </Typography>
                        </Grid>
                        <Grid container alignItems="center" item xs={3}>
                          <Grid item xs={5}>
                            <Typography variant="body2" align="left">
                              <Box fontWeight="fontWeightBold" display="flex">
                                {t("Launching date")}
                              </Box>
                            </Typography>
                          </Grid>
                          <Grid item xs={7}>
                            <Typography variant="body2" align="left">
                              <Box display="flex" justifyContent="center">
                                {row?.launchingTime
                                      ? formatDateByLanguage(row?.launchingTime)
                                      : EMPTY_DEFAULT_VALUE}
                              </Box>
                            </Typography>
                          </Grid>
                          {row?.executionStartTime
                                && (
                                <>
                                  <Grid item xs={5}>
                                    <Typography variant="body2" align="left">
                                      <Box fontWeight="fontWeightBold">
                                        {t("Start date")}
                                      </Box>
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={7} className={classes.gridPadding}>
                                    <Typography variant="body2" align="left">
                                      <Box display="flex" justifyContent="center">
                                        {row?.executionStartTime
                                                ? formatDateByLanguage(row?.executionStartTime)
                                                : EMPTY_DEFAULT_VALUE}
                                      </Box>
                                    </Typography>
                                  </Grid>
                                </>
                                )}
                          {row?.executionEndTime
                                && (
                                <>
                                  <Grid item xs={5}>
                                    <Typography variant="body2" align="left">
                                      <Box fontWeight="fontWeightBold">
                                        {t("End date")}
                                      </Box>
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={7}>
                                    <Typography variant="body2" align="left">
                                      <Box display="flex" justifyContent="center">
                                        {row?.executionEndTime
                                                ? formatDateByLanguage(row?.executionEndTime)
                                                : EMPTY_DEFAULT_VALUE}
                                      </Box>
                                    </Typography>
                                  </Grid>
                                </>)}

                        </Grid>

                        <Grid container alignItems="center" justify="center" item xs={3}>
                          {row?.executionDuration > 0 && (
                            <>
                              <Grid item xs={5}>
                                <Typography variant="body2">
                                  <Box fontWeight="fontWeightBold">
                                    {t("Execution Time")}
                                  </Box>
                                </Typography>
                              </Grid>
                              <Grid item xs={5}>
                                <Typography variant="body2">
                                  {getDateDifference(row?.executionDuration)}
                                </Typography>
                              </Grid>
                            </>
                            )}
                          {(row?.srStatus === "MISSED_P" || row?.srStatus === "MISSED")
                                && (
                                <>
                                  <Grid item xs={5}>
                                    <Typography variant="body2">
                                      <Box fontWeight="fontWeightBold" display="flex" justifyContent="center">
                                        {t("missed raison")}
                                      </Box>
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={5}>
                                    <Typography variant="body2">
                                      <Box display="flex" justifyContent="center">
                                        {t(row?.missedReason)}
                                      </Box>
                                    </Typography>
                                  </Grid>
                                </>)}

                        </Grid>
                        <Grid container alignItems="center" justify="center" item xs={1}>
                          <Typography variant="body2" align="center">
                            {row?.executionTrigger
                                  ? t(row?.executionTrigger)
                                  : EMPTY_DEFAULT_VALUE}
                          </Typography>
                        </Grid>
                        <Grid container alignItems="center" justify="center" item xs={1}>
                          {(row.statusCode || row.srStatus)

                                && <StatusButton
                                    status={row.statusCode ? row.statusCode : row.srStatus}
                                    label={row.statusLabel ? row.statusLabel : row.srStatus}
                                />}
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              ))
              : (
                <DataNotFound
                      message={t("no.executions.message")}
                      icon={OccurrencesNotFound}
                  />
              ))}
          </>
        )
      }

      <Grid container xs={12} direction="row" justify="flex-end">
        {!isLoading && data && data.length > 0 && (
        <CustomPagination
                rowsPerPageOptions={[5, 10, 25]}
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                onNext={handleNext}
                onPrevious={handlePrevious}
            />
        )}
      </Grid>
    </Grid>
  );
}

const mapDispatchToProps = {
  fetchExecutions,
  fetchFilterProcesses,
  fetchAllStatus,
  fetchTagsForFilters,
  fetchExecutionsRobots,
  fetchExecutionsCount
};
export default connect(null, mapDispatchToProps)(ExecutionsPage);

const columns = [
  {
    id: "launchingDate",
    label: "Launching date (Descending)",
    order: "desc",
  },
  {
    id: "launchingDate",
    label: "Launching date (Ascending)",
    order: "asc",
  },
  {
    id: "executionDuration",
    label: "Execution Time (Descending)",
    order: "desc",
  },
  {
    id: "executionDuration",
    label: "Execution Time (Ascending)",
    order: "asc",
  },
];
