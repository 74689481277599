import React from "react";
import { useHistory } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";
import { formatDateByLanguage, isFleetAdministrator } from "../../../util";
import {
  deleteDivision,
  deleteDivisions,
  enableOrDisableDivision,
  fetchDivisions,
} from "../../../redux/actions/services";
import { FETCH_DIVISION } from "../../../redux/constants";
import { useSelector } from "react-redux";
import AdminTable from "../AdminTable";
import PageHeader from "../../../components/PageHeader";
import StatusLegend from "components/StatusLegend";
import { get } from "lodash";
import { isPermitted } from "../../../components/HasPermission";
import CustomDialog from "pages/Services/components/CustomDialog";
import CustomButton from "../../../components/CustomButton";
import useStyles from "./style";

const headerCells = {
  fields: [
    {
      id: "status",
      gridSize: 1,
      align: "center",
      label: "divisions.status",
    },
    {
      id: "division",
      gridSize: 3,
      align: "left",
      label: "divisions.division",
    },
    {
      id: "fleet",
      gridSize: 3,
      align: "left",
      label: "divisions.fleet",
    },
    {
      id: "creationDate",
      gridSize: 3,
      align: "left",
      label: "divisions.creation.date",
    },
  ],
};

const displayFields = [
  {
    id: "status",
    gridSize: 1,
    align: "center",
    valuePath: "status",
    isStatus: true,
    translate: false,
  },
  {
    id: "divisionName",
    gridSize: 3,
    align: "left",
    valuePath: "divisionName",
    avatarPath: "logo",
  },
  {
    id: "fleet",
    gridSize: 3,
    align: "left",
    valuePath: "fleet.label",
  },
  {
    id: "creationDate",
    gridSize: 3,
    align: "left",
    valuePath: "creationDate",
    defaultValue: "--:--:--",
    translate: false,
    format: (v) => formatDateByLanguage(v, false),
  },
];

const statusList = [
  { code: true, label: "ACTIVE" },
  { code: false, label: "DISABLED" },
];

const sortByList = [
  { code: "createdAt,DESC", label: "Creation date (Descending)" },
  { code: "createdAt", label: "Creation date (ascending)" },
];

export default function DivisionManagement(props) {
  const currentUser = useSelector(({ requests }) => get(requests, "queries.FETCH_CURRENT_USER.data"));
  const { t } = useTranslation();
  const entityBaseUrl = "/userAdministration/divisions";
  const enableDisableEntity = enableOrDisableDivision;
  const fetchEntities = fetchDivisions;
  const deleteEntity = deleteDivision;
  const deleteEntities = deleteDivisions;
  const entityQuery = FETCH_DIVISION;
  const searchField = "searchCriteria";
  const history = useHistory();
  const classes = useStyles();

  const labels = {
    title: "divisions.module.title",
    addButton: "divisions.module.add.button",
    deleteEntity: (
      <div>
        <p>{t("divisions.module.delete.confirmMsg")}</p>
      </div>
    ),
    deleteEntities: "divisions.module.deleteClientsActionMessage",
    enableEntity: "divisions.module.enaDes.confirmMsg",
    enableConfirm: "divisions.module.enaDes.button.confirm",
    disableEntity: "divisions.module.desaDes.confirmMsg",
    disableConfirm: "divisions.module.desaDes.button.confirm",
    searchPlaceHolder: "divisions.module.searchClient",
    entityDeleted: "division deleted successfully",
    confirmAction: "Delete",
    deleteAll: "tooltip.action.delete",
  };
  const statues = ["ACTIVE", "DISABLE"]

  return (
    <div className={classes.root}>
      <div className={classes.paper}>
        <PageHeader title={labels.title} />
        <Grid container>
          <Grid container item xs justify="center">
            <StatusLegend statues={statues} />
          </Grid>
          <Grid item xs="auto">
            {(isFleetAdministrator(currentUser) || isPermitted(currentUser, "Add division")) && (
              <CustomButton
                      view="primary"
                      type="submit"
                      onClick={() => {
                        history.push(`${entityBaseUrl}/add`);
                      }}
                  >
                <AddIcon />
                {t(labels.addButton)}
              </CustomButton>
              )}
          </Grid>
        </Grid>
        <AdminTable
              statusList={statusList}
              sortByList={sortByList}
              displayFields={displayFields}
              headerCells={headerCells}
              entityBaseUrl={entityBaseUrl}
              enableDisableEntity={enableDisableEntity}
              fetchEntities={fetchEntities}
              deleteEntity={deleteEntity}
              deleteEntities={deleteEntities}
              entityQuery={entityQuery}
              labels={labels}
              searchField={searchField}
              isDivisionAdministrationPage
          />
        {props.showEditModal && (
          <CustomDialog
                  open={props.showEditModal}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  onBackdropClick={() => {
                    history.push("/userAdministration/divisions");
                  }}
              >
            {props.modalComponent}
          </CustomDialog>
          )}
      </div>
    </div>
  );
}
