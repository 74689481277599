import {
    FETCH_DASHBOARD_ALL_TIME_STATS,
    FETCH_DASHBOARD_AVERAGE_HANDLING_TIME,
    FETCH_DASHBOARD_BREAK_EVEN,
    FETCH_DASHBOARD_HANDLING_TIME, FETCH_DASHBOARD_PENDING_ITEMS_COUNT,
    FETCH_DASHBOARD_PROCESSES_ACTIVITY, FETCH_DASHBOARD_PROCESSES_STATUS_COUNT,
    FETCH_DASHBOARD_ROBOT_PRODUCTIVITY,
    FETCH_DASHBOARD_ROI,
    FETCH_DASHBOARD_TIME_SAVED, FETCH_DASHBOARD_TODAY_STATS,
    FETCH_DASHBOARD_UNHANDLED_EXCEPTIONS,
    FETCH_DASHBOARD_WEEKLY_FTE_SAVED,
    FETCH_DASHBOARD_WEEKLY_SUCCESS_RATE, FETCH_DASHBOARD_WEEKLY_TRANSACTIONS,
} from "../../constants";
import { URL_DASHBOARD } from "../../constants/endpoints";
import { formatDateTimeIsoParam } from "util/index";

export const fetchRoi = (filter = {}) => {
    const {
        selectedDurationValue: period, process, fromDate, toDate, tags
    } = filter;
    return {
        type: FETCH_DASHBOARD_ROI,
        request: {
            url: `${URL_DASHBOARD}roi`,
            params: {
                period,
                processIds: process?.join(),
                from: formatDateTimeIsoParam(fromDate),
                to: formatDateTimeIsoParam(toDate),
                tagsIds: tags?.join(),
            },
        },
    };
};

export const fetchBreakEven = (filter = {}) => {
    const {
        selectedDurationValue: period, process, fromDate, toDate, tags
    } = filter;
    return {
        type: FETCH_DASHBOARD_BREAK_EVEN,
        request: {
            url: `${URL_DASHBOARD}break-even`,
            params: {
                period,
                processIds: process?.join(),
                from: formatDateTimeIsoParam(fromDate),
                to: formatDateTimeIsoParam(toDate),
                tagsIds: tags?.join(),
            },
        },
    };
};

export const fetchUnhandledExceptions = (filter = {}) => {
    const {
        selectedDurationValue: period, process, fromDate, toDate, tags
    } = filter;
    return {
        type: FETCH_DASHBOARD_UNHANDLED_EXCEPTIONS,
        request: {
            url: `${URL_DASHBOARD}unhandled-exceptions`,
            params: {
                period,
                processIds: process?.join(),
                from: formatDateTimeIsoParam(fromDate),
                to: formatDateTimeIsoParam(toDate),
                tagsIds: tags?.join(),
            },
        },
    };
};

export const fetchDashboardTimeSaved = (filter = {}) => {
    const {
        selectedDurationValue: period, process, fromDate, toDate, tags, by
    } = filter;
    return {
        type: FETCH_DASHBOARD_TIME_SAVED,
        request: {
            url: `${URL_DASHBOARD}time-saved`,
            params: {
                period,
                processIds: process?.join(),
                from: formatDateTimeIsoParam(fromDate),
                to: formatDateTimeIsoParam(toDate),
                tagsIds: tags?.join(),
                by
            },
        },
    };
};

export const fetchDashboardRobotProductivity = (filter = {}) => {
    const {
        selectedDurationValue: period, process, fromDate, toDate, tags
    } = filter;
    return {
        type: FETCH_DASHBOARD_ROBOT_PRODUCTIVITY,
        request: {
            url: `${URL_DASHBOARD}robot-productivity`,
            params: {
                period,
                processIds: process?.join(),
                from: formatDateTimeIsoParam(fromDate),
                to: formatDateTimeIsoParam(toDate),
                tagsIds: tags?.join()
            },
        },
    };
};

export const fetchDashboardProcessesActivity = (filter = {}) => {
    const {
        selectedDurationValue: period, process, fromDate, toDate, tags
    } = filter;
    return {
        type: FETCH_DASHBOARD_PROCESSES_ACTIVITY,
        request: {
            url: `${URL_DASHBOARD}process-activity`,
            params: {
                period,
                processIds: process?.join(),
                from: formatDateTimeIsoParam(fromDate),
                to: formatDateTimeIsoParam(toDate),
                tagsIds: tags?.join()
            },
        },
    };
};

export const fetchDashboardAllRobotsProductivity = (params = {}) => {
    const {
        searchQuery: searchCriteria, period, page, size, sort
    } = params;
    return {
        type: FETCH_DASHBOARD_ROBOT_PRODUCTIVITY,
        request: {
            url: encodeURI(`${URL_DASHBOARD}robot-productivity/paged${searchCriteria ? `?searchCriteria=${searchCriteria}` : ""}`),
            params: {
                period,
                page,
                size,
                sort
            },
        },
    };
};

export const fetchDashboardAllRobotsProductivityCount = (params = {}) => {
    const {
        searchQuery: searchCriteria, period, page, size
    } = params;
    return {
        type: FETCH_DASHBOARD_ROBOT_PRODUCTIVITY,
        request: {
            url: `${URL_DASHBOARD}robot-productivity/count`,
            params: {
                searchCriteria,
                period,
                page,
                size
            },
        },
    };
};

export const fetchDashboardHandlingTime = (filter = {}) => {
    const {
        selectedDurationValue: period, process, fromDate, toDate, tags
    } = filter;
    return {
        type: FETCH_DASHBOARD_HANDLING_TIME,
        request: {
            url: `${URL_DASHBOARD}avg-handling-time`,
            params: {
                period,
                processIds: process?.join(),
                from: formatDateTimeIsoParam(fromDate),
                to: formatDateTimeIsoParam(toDate),
                tagsIds: tags?.join()
            },
        },
    };
};

export const fetchDashboardWeeklyFTESaved = (filter = {}) => {
    const {
        process, tags, selectedDurationValue: period
    } = filter;
    return {
        type: FETCH_DASHBOARD_WEEKLY_FTE_SAVED,
        request: {
            url: `${URL_DASHBOARD}n/hours-saved`,
            params: {
                processIds: process?.join(),
                tagsIds: tags?.join(),
                period
            },
        },
    };
};

export const fetchDashboardWeeklySuccessRate = (filter = {}) => {
    const {
        process, tags, selectedDurationValue: period
    } = filter;
    return {
        type: FETCH_DASHBOARD_WEEKLY_SUCCESS_RATE,
        request: {
            url: `${URL_DASHBOARD}n/success-rate`,
            params: {
                processIds: process?.join(),
                tagsIds: tags?.join(),
                period
            },
        },
    };
};

export const fetchDashboardWeeklyTransactions = (filter = {}) => {
    const {
        process, tags, selectedDurationValue: period
    } = filter;
    return {
        type: FETCH_DASHBOARD_WEEKLY_TRANSACTIONS,
        request: {
            url: `${URL_DASHBOARD}n/transactions`,
            params: {
                processIds: process?.join(),
                tagsIds: tags?.join(),
                period
            },
        },
    };
};

export const fetchDashboardAverageHandlingTime = (filter = {}) => {
    const {
          process, tags, selectedDurationValue: period
    } = filter;
    return {
        type: FETCH_DASHBOARD_AVERAGE_HANDLING_TIME,
        request: {
            url: `${URL_DASHBOARD}n/average-handling-time`,
            params: {
                processIds: process?.join(),
                tagsIds: tags?.join(),
                period
            },
        },
    };
};

export const fetchDashboardTodayData = (filter = {}, onSuccess) => {
    const {
        process,
        tags,
    } = filter;
    return {
        type: FETCH_DASHBOARD_TODAY_STATS,
        request: {
            url: `${URL_DASHBOARD}n/today-stats`,
            params: {
                processIds: process?.join(),
                tagsIds: tags?.join(),
            },
        },
        meta: {
            onSuccess: (response) => {
                if (onSuccess) onSuccess(response);
                return response;
            }
        },
    };
};

export const fetchDashboardAllTimeData = () => ({
    type: FETCH_DASHBOARD_ALL_TIME_STATS,
    request: {
      url: `${URL_DASHBOARD}n/all-time-stats`,
    },
});

export const fetchDashboardPendingItemsCount = (filter = {}, onSuccess) => {
    const { process, tags } = filter;
    return {
      type: FETCH_DASHBOARD_PENDING_ITEMS_COUNT,
      request: {
        url: `${URL_DASHBOARD}n/pending-count`,
        params: {
          processIds: process?.join(),
          tagsIds: tags?.join(),
        },
      },
        meta: {
            onSuccess: (response) => {
                if (onSuccess) onSuccess(response);
                return response;
            }
        },
    };
};

export const fetchDashboardProcessesStatus = (filter = {}, useTagsAsProccesses = false, onSuccess) => {
    const { process, tags } = filter;
    return {
        type: FETCH_DASHBOARD_PROCESSES_STATUS_COUNT,
        request: {
            url: `${URL_DASHBOARD}n/processes/Busyness`,
            params: {
                processIds: process?.join(),
                tagsIds: tags?.join(),
                useTagsAsProccesses,
            },
        },
        meta: {
            onSuccess: (response) => {
                if (onSuccess) onSuccess(response);
                return response;
            }
        },
    };
};
