import React, { useEffect, useState } from "react";
import { useSelector, connect, useDispatch } from "react-redux";
import get from "lodash/get";
import { useTranslation } from "react-i18next";
import {
  fetchScheduleByProcess,
  updateProcess,
  startProcess,
  startProcessInSRQueues,
  checkManualPendingInSrQueue,
  isProcessAssignedToQueue,
  testOrchestratorConnectionWithId,
  isEnoughLicenses,
  fetchAssignedRobotsByProcess,
  fetchRobotList, getQueuRobots,
  fetchProcessRestriction
} from "../../../../redux/actions/services";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Badge from "@material-ui/core/Badge";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import ScheduleIcon from "@material-ui/icons/Schedule";
import Box from "@material-ui/core/Box";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import clsx from "clsx";
import DialogTitle from "../DialogTitle";
import useStyles from "./style";
import HistoricalInfo from "../HistoricalInfo";
import SlaInfo from "../SlaInfo";
import GeneralInfo from "../GeneralInfo";
import ConfirmMessage from "../../../../components/ConfirmMessage";
import ScheduleForm from "../ScheduleForm";
import IconList from "./IconList";
import {
  formatImagePath,
} from "util";
import KpiParametersForm from "../KpiParametersForm";
import {
  HasPermission,
  isPermitted,
} from "../../../../components/HasPermission";
import RobotDialog from "../Robot";
import { TabPanel } from "../SharedService";
import DataMapping from "../DataMapping";
import { useQuery } from "@redux-requests/react";
import { FETCH_ROBOTS } from "../../../../redux/constants";

import { toast } from "react-toastify";
import { displayStatus } from "../../../../util";
import { isSRQueuesDisabled } from "../../../../util/constants/PermitedFeature";
import { PlayArrowOutlined } from "@material-ui/icons";
import { storeCustomHandleClose, resetCustomHandleClose } from "redux/slices/stepperSlice";
import CustomTab, { useIndicatorStyle } from "../CustomTab";
import CustomDialog from "../CustomDialog";
import CircularLoader from "../../../../components/Loaders/CircularLoader";
import CustomCloseButton from "../CustomCloseButton";
import CustomButton from "../../../../components/CustomButton";
import { ReactComponent as ProcessDefaultIcon } from "assets/ProcessDefaultIcon.svg"

function a11yProps(index) {
  return {
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function CustomizedDialogs(props) {
  const { t } = useTranslation();
  const {
    handleClose,
    open,
    _process,
    setProcess,
    value,
    setValue,
    callAfterClose,
    statusData,
    changePriority,
    isLoading
  } = props;
  const [valueButtonStart, setValueButtonStart] = useState(
    t("Start"),
  );
  const [openLogoList, setOpenLogoList] = useState(false);
  const [openMsgConfirm, setOpenMsgConfirm] = useState(false);
  const [showScheduleForm, setShowSchedule] = useState(false);
  const [queueRobots, setQueueRobots] = useState([]);
  const [isAssignedToQueue, setIsAssignedToQueue] = useState(false);
  const [availableRobots, setAvailableRobots] = useState([]);
  const [assignedRobots, setAssignedRobots] = useState(_process?.robots);
  const allRobots = useQuery({ type: FETCH_ROBOTS })?.data?.content;
  const classes = useStyles();
  const currentUser = useSelector(({ requests }) => get(requests, "queries.FETCH_CURRENT_USER.data"));
  const preventHandleClose = useSelector(({ stepper }) => stepper?.preventHandleClose);
  const availableLogos = useSelector(({ requests }) => get(requests, "queries.FETCH_PROCESS_LOGOS.data"));

  const [openRobotDialog, setOpenRobotDialog] = useState(false);
  const [selectedRobotName, setSelectedRobotName] = useState(null);
  const [openMsgConfirmRestriction, setOpenMsgConfirmRestriction] = useState(false);
  const [selectedProcessIcon, setSelectedProcessIcon] = useState(_process?.icon);
  const [icon, setIcon] = useState(formatImagePath(selectedProcessIcon));
  const [isDataNotSaved, setIsDataNotSaved] = useState(false);
  const [isDataEdited, setIsDataEdited] = useState(false);
  const processIsSchedulable = _process?.isSchedulable && _process?.orchestrator?.status !== "DISABLE";
  const processIsManuallyAllowed = _process?.isManualAllowed && _process?.orchestrator?.status !== "DISABLE";

  const dispatch = useDispatch()
  const cancelRobotConfirm = () => {
    setOpenRobotDialog(false);
  };
  const confirmRobotSave = () => {
    setOpenRobotDialog(false);
    setOpenMsgConfirm(true);
  };

  useEffect(() => {
    setIcon(formatImagePath(selectedProcessIcon));
  }, [selectedProcessIcon])

  useEffect(() => {
    if (allRobots) {
      setAvailableRobots(assignedRobots ? allRobots.filter((p) => !assignedRobots.find((ar) => ar.id === p.id)) : allRobots);
      setAssignedRobots(assignedRobots || []);
    }
   }, [allRobots, _process]);

  useEffect(() => {
   if (_process) {
    setAssignedRobots(_process?.robots)
    dispatch(fetchRobotList({ orchestrator: [_process?.orchestrator] }));
   }
  }, [_process])

  const handleChange = (event, newValue) => {
    if (preventHandleClose) {
      dispatch(storeCustomHandleClose(() => {
        setValue(newValue);
        setShowSchedule(false);
        dispatch(resetCustomHandleClose())
      }));
      preventHandleClose()
    }
    else {
      setValue(newValue);
      setShowSchedule(false);
    }
  };
  const handleClickStartProcessDisabledSRQ = () => {
    ensureConnection(_process?.orchestrator?.id, _process?.orchestrator?.name)
  }

  const startProcess = () => {
    setOpenMsgConfirmRestriction(false);
    props.isProcessAssignedToQueue(_process?.id).then((result) => {
      setIsAssignedToQueue(result.data);
      if (result.data)
      {
        dispatch(getQueuRobots(_process?.queueId)).then((response) => {
          setQueueRobots(response.data);
          prepareRobotDialog();
        })
      }
      else
      {
        ensureConnection(_process?.orchestrator?.id, _process?.orchestrator?.name)
      }
    });
  }
  const handleClickStartProcess = () => {
    dispatch(fetchProcessRestriction(_process?.id))
        .then((res) => {
          if (res?.status === 200) {
            const currentDate = new Date();
            const dayOfWeek = currentDate.getDay();
            const adjustedDayOfWeek = (dayOfWeek === 0) ? 7 : dayOfWeek;
            if (!res?.data?.restrictionDays || res?.data?.restrictionDays?.split(",").includes(adjustedDayOfWeek.toString())) {
              startProcess();
            } else {
              setOpenMsgConfirmRestriction(true)
            }
          }
        })
  };
   const prepareRobotDialog = () => {
     setOpenRobotDialog(true);
     setSelectedRobotName(null);
   };

  const fetchRobotDialog = () => {
    if (isAssignedToQueue)
    {
      dispatch(getQueuRobots(_process?.queueId)).then((response) => {
        setQueueRobots(response.data);
      })
    }
  }

  useEffect(() => {
    fetchRobotDialog()
  }, [isAssignedToQueue]);

  const ensureConnection = (orchId, orchName) => {
    const onSuccess = (response) => {
      // succefull connection
      if (response.data)
      {
        checkForLicenses(orchId)
      }
      else
      {
        toast.error(t("check.connectivity", { serverName: orchName }))
      }
    }
    dispatch(testOrchestratorConnectionWithId(orchId, onSuccess))
  }
  const checkForLicenses = (orchId) => {
    dispatch(isEnoughLicenses(orchId)).then((res) => {
      if (res.data === -1)
      {
        // enough licenses
        prepareRobotDialog()
      }
      else
      {
        const messageSuffix = (res.data === 1) ? t("number of runing executions singular") : t("number of runing executions plural");
        toast.error(messageSuffix + t("not enough licenses"))
      }
    })
  }

  const confirmSave = () => {
    setOpenMsgConfirm(false);
    if (isAssignedToQueue) {
      props.checkManualPendingInSrQueue({ id: _process?.id, robotName: selectedRobotName || " " })
      .then((result) => {
        if (result.data) {
          toast.error(t("An execution trigerred manually on the same robot has already been requested for this process"))
        }
        else {
          props
            .startProcessInSRQueues({ id: _process?.id, robotName: selectedRobotName || " " })
            .then((result) => {
              processStarted(result.status === 200);
            });
        }
      })
    } else {
        props
          .startProcess({ id: _process?.id, robotName: selectedRobotName || " " })
          .then((result) => {
            processStarted(result.status === 200);
          })
      }
  };

  const processStarted = (isSuccess) => {
    if (isSuccess) {
      toast.success(isAssignedToQueue ? t("The process has been added to the SR Queues.") : t("This process was started successfully"));
      setTimeout(() => {
        setValueButtonStart(t("Start"));
      }, 30000);
    } else {
      toast.error(t("The process start failed! Please try again later"))
    }
    setSelectedRobotName(null);
  }

  const cancelConfirm = () => {
    setOpenMsgConfirm(false);
  };
  const cancelMsgConfirmRestriction = () => {
    setOpenMsgConfirmRestriction(false);
  };
  const showSchedule = () => {
    setValue(1);
    setShowSchedule(true);
  };
  const handleChangeStatus = (event) => {
    if (event === "RUNNING") {
      setValueButtonStart(t("Executing"));
    } else {
      setValueButtonStart(t("Start"));
    }
  };

  const handleDialogClose = () => {
    if (isDataEdited) {
      setIsDataNotSaved(true);
    }
    else if (preventHandleClose) {
      dispatch(storeCustomHandleClose(() => {
        handleClose()
        dispatch(resetCustomHandleClose())
      }));
      preventHandleClose()
    }
    else {
      handleClose();
    }
  };

  const tabsStyle = useIndicatorStyle();
  return (
    <div>
      <CustomDialog
        onClose={handleDialogClose}
        aria-labelledby="customized-dialog-title"
        maxWidth="lg"
        fullWidth
        open={open}
        disableBackdropClick
        style={{ overflow: "unset" }}
        PaperProps={{
          square: true
        }}
      >
        <Paper
square
               className={classes.tabSize}>
          <Grid container direction="row" xs={12}>
            <Grid container direction="row" xs={11}>
              <Tabs
                id="process-tabs-page"
                value={value}
                textColor="primary"
                onChange={handleChange}
                aria-label="switch tabs"
                TabIndicatorProps={{
                  style: tabsStyle,
                }}
              >
                <CustomTab
                  id="process-overview-tab"
                  value={0}
                  label={t("Overview")}
                  {...a11yProps(0)}
                  className={classes.tabsTextColor}
                />
                {isPermitted(currentUser, "Schedule process")
                  && _process?.isSchedulable && (
                    <CustomTab
                      id="process-schedule-tab"
                      value={1}
                      label={t("Schedule")}
                      {...a11yProps(1)}
                      className={classes.tabsTextColor}
                    />
                )}
                {isPermitted(currentUser, "Process data mapping") && (
                  <CustomTab
                    id="process-process-data-mapping-tab"
                    value={3}
                    label={t("Process data mapping")}
                    {...a11yProps(3)}
                    className={classes.tabsTextColor}
                  />
                )}
                {isPermitted(currentUser, "SLA") && (
                  <CustomTab
                    id="process-cli-tab"
                    value={4}
                    label={t("SLA")}
                    {...a11yProps(4)}
                    className={classes.tabsTextColor}
                  />
                )}
                <CustomTab
                  id="process-historical-tab"
                  value={5}
                  label={t("Historical")}
                  {...a11yProps(5)}
                  className={classes.tabsTextColor}
                />
                {isPermitted(currentUser, "KPI parameters") && (
                  <CustomTab
                    id="process-kpi-params-tab"
                    value={6}
                    label={t("KPI parameters")}
                    {...a11yProps(6)}
                    className={classes.tabsTextColor}
                  />
                )}
              </Tabs>
            </Grid>
            <Grid container direction="row" xs={1} justify="flex-end">
              <CustomCloseButton
                aria-label="close"
                className={classes.closeButton}
                onClick={handleDialogClose}
              />
            </Grid>
          </Grid>
        </Paper>
        <DialogContent classes={{ root: classes.dialogContent }}>
          {isLoading || !_process?.id ? (
            <TabPanel value={value} index={value} className={classes.pannel}>
              <CircularLoader height={500} />
            </TabPanel>
          ) : (
            <>
              <div>
                <TabPanel value={value} index={0} className={classes.pannel}>
                  <div className={classes.contentContainer}>
                    <Grid container>
                      <Box mb={2} mt={4} component={Grid} container xs={12} justify="space-between" alignItems="flex-end">
                        <Grid item>
                          <Badge
                              overlap="circle"
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                              }}
                              classes={{ badge: classes.customBadge }}
                              badgeContent={(
                                <HasPermission name="Edit process details">
                                  <IconButton
                                        className={classes.editIcon}
                                        onClick={() => setOpenLogoList(true)}
                                    >
                                    <EditIcon fontSize="small" />

                                  </IconButton>
                                </HasPermission>
                              )}
                           >
                            {icon ? (
                              <img
                                    src={icon}
                                    align="left"
                                    alt="Service"
                                    className={classes.avatarImg}
                                />
                            ) : (
                              <ProcessDefaultIcon className={classes.avatarImg} />
                            )}
                          </Badge>

                        </Grid>

                      </Box>
                      <DialogTitle
                            id="customized-dialog-title"
                            align="left"
                            showIco={false}
                            process={_process}
                            onClose={handleClose}
                            classes={classes}
                            setIsDataEdited={setIsDataEdited}
                        >
                        {_process?.processDescription?.processDisplayName}
                      </DialogTitle>
                    </Grid>
                    <GeneralInfo
                          status={_process?.processStatus}
                          process={_process}
                          setProcess={setProcess}
                          onChangeStatus={handleChangeStatus}
                          classes={classes}
                          content={_process?.processDescription?.processDescription}
                          handleClose={handleClose}
                          callAfterClose={callAfterClose}
                          changePriority={changePriority}
                          statusData={statusData}
                          setIsDataEdited={setIsDataEdited}
                      />
                  </div>
                  <DialogActions className={classes.cardActions}>
                    {isPermitted(currentUser, "Schedule process")
                          && processIsSchedulable && (
                          <CustomButton
                                  view="cancel"
                                  size="medium"
                                  onClick={showSchedule}
                                  startIcon={<ScheduleIcon />}
                              >
                            {t("Schedule")}
                          </CustomButton>
                          )}
                    {isPermitted(currentUser, "Start process")
                          && processIsManuallyAllowed && (
                          <CustomButton
                                  view="primary"
                                  size="medium"
                                  onClick={!isSRQueuesDisabled ? handleClickStartProcess : handleClickStartProcessDisabledSRQ}
                                  startIcon={<PlayArrowOutlined className={classes.playBtn} />}
                              >
                            {valueButtonStart}
                          </CustomButton>
                          )}
                  </DialogActions>
                </TabPanel>
              </div>

              {isPermitted(currentUser, "Schedule process") && (
                <TabPanel
                        value={value}
                        index={1}
                        className={clsx(classes.pannel, classes.tablebox)}
                    >
                  <ScheduleForm
                          showFromParent={showScheduleForm}
                          process={_process}
                      />
                </TabPanel>
                )}
              {isPermitted(currentUser, "SLA") && (
                <TabPanel value={value} index={4} className={classes.pannel}>
                  <SlaInfo
                          handleClose={handleClose}
                          _process={_process}
                          currentUser={currentUser}
                          availableRobots={availableRobots}
                          setAvailableRobots={setAvailableRobots}
                          assignedRobots={assignedRobots}
                          setAssignedRobots={setAssignedRobots}
                          setProcess={setProcess}
                      />
                </TabPanel>
                )}
              <TabPanel value={value} index={5} className={classes.pannel}>
                <HistoricalInfo
                      process={_process}
                      startTime={t("Start time")}
                      endTime={t("End time")}
                      status={t("Staus")}
                      classes={classes}
                  />
              </TabPanel>
              {isPermitted(currentUser, "Process data mapping") && (
                <TabPanel value={value} index={3} className={classes.pannel}>
                  <DataMapping processId={_process?.id} classes={classes} />
                </TabPanel>
                )}
              {isPermitted(currentUser, "KPI parameters") && (
                <TabPanel value={value} index={6} className={classes.pannel}>
                  <KpiParametersForm
                          t={t}
                          setProcess={setProcess}
                          classes={classes}
                          handleClose={handleClose}
                          _process={_process}
                      />
                </TabPanel>
                )}
            </>
          )}
        </DialogContent>
      </CustomDialog>
      {openMsgConfirm && (
        <ConfirmMessage
          message={t("Are you sure you want to start this process ?")}
          openStart={openMsgConfirm}
          onCancel={cancelConfirm}
          onConfirm={confirmSave}
          buttonConfirm={t("Start Process")}
          buttonCancel={t("Cancel")}
        />
      )}
      <ConfirmMessage
            message={t("process.start.restriction")}
            openStart={openMsgConfirmRestriction}
            onCancel={cancelMsgConfirmRestriction}
            onConfirm={startProcess}
            buttonConfirm={t("Start Process")}
            buttonCancel={t("Cancel")}
      />
      {openRobotDialog && (
        <RobotDialog
          message={t("Select a robot")}
          openStart={openRobotDialog}
          onCancel={cancelRobotConfirm}
          onConfirm={confirmRobotSave}
          buttonConfirm={t("Validate")}
          buttonCancel={t("Cancel")}
          _robots={(isAssignedToQueue) ? queueRobots : _process?.robots?.filter((item) => item?.statusId !== null && item?.displayStatus?.toLowerCase() === displayStatus.IDLE)}
          setSelectedRobotName={setSelectedRobotName}
          selectedRobotName={selectedRobotName}
          isRobotRequired={!isAssignedToQueue}
        />
      )}
      {isDataNotSaved && (
        <ConfirmMessage
          message={t("Are you sure you want to disregard the changes ?")}
          openStart={isDataNotSaved}
          onCancel={() => setIsDataNotSaved(false)}
          onConfirm={handleClose}
          buttonConfirm={t("Discard")}
          buttonCancel={t("Cancel")}
        />
      )}
      <Grid container xs={12} justify="center">
        <IconList
          icons={availableLogos}
          isOpen={openLogoList}
          setOpen={setOpenLogoList}
          setIcon={setIcon}
          _process={_process}
          setSelectedProcessIcon={setSelectedProcessIcon}
        />
      </Grid>
    </div>
  );
}

const mapDispatchToProps = {
  updateProcess,
  fetchScheduleByProcess,
  startProcess,
  startProcessInSRQueues,
  checkManualPendingInSrQueue,
  isProcessAssignedToQueue,
  fetchAssignedRobotsByProcess,
  fetchProcessRestriction
};
export default connect(null, mapDispatchToProps)(CustomizedDialogs);
