import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(1.7)",
      opacity: 0,
    },
  },
  dot: {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    height: "16px",
    width: "16px",
    borderRadius: "50%",
  },
  green: {
    backgroundColor: theme.custom.color.greenStatus,
    color: theme.custom.color.greenStatus,
  },
  blue: {
    color: theme.custom.color.blueStatus,
    backgroundColor: theme.custom.color.blueStatus,
  },
  orange: {
    color: theme.custom.color.orangeStatus,
    backgroundColor: theme.custom.color.orangeStatus,
  },
  red: {
    color: theme.custom.color.redStatus,
    backgroundColor: theme.custom.color.redStatus,
  },
  separator: {
    color: theme.custom.color.staticInput,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "12px 0",
  },
  badgeContainer: {
    padding: "12px 24px 12px 0",
  },
}));
