import React, { useEffect, useMemo } from "react";
import Grid from "@material-ui/core/Grid";
import { Query } from "@redux-requests/react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import get from "lodash/get";
import { updateFilter } from "redux/slices/adminFilterSlice.js";
import CircularLoader from "components/Loaders/CircularLoader/index.js";
import QueueTableHeader from "./QueueTableHeader/index.js";
import DataNotFound from "components/DataNotFound";
import QueueTableRow from "./QueueTableRow/index.js";
import OrchestratorNotFound from "assets/No_Orchestrator.svg";
import CustomPagination from "pages/Services/components/CustomPagination/index.js";
import useStyles from "./style.js";

export default function QueueTable({
  displayFields,
  headerCells,
  entityBaseUrl,
  enableDisableEntity,
  resetEntity,
  fetchEntities,
  deleteEntity,
  entityQuery,
  labels,
  preDeleteEntity,
  showStatusSwitch,
  enableEdit,
  enableDelete,
  enableDeleteMultiple,
  deleteEntities
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const [showTableHeader, setShowTableHeader] = React.useState(true)

  const [countQueues, setCountQueues] = React.useState(0);
  const [selected, setSelected] = React.useState([]);
  const { t } = useTranslation();
  const [fetchedData, setFetchedData] = React.useState([]);
  const filter = useSelector(({ adminFilter }) => adminFilter);
  const handleSelectedAll = (checked) => {
    const data = fetchedData.map((d) => d.id);
    if (!checked) {
      const filteredData = selected.filter((fd) => !data.includes(fd));
      setSelected(filteredData);
    } else {
      setSelected(data);
    }
  };
  const handleChangeShowDeleted = (event) => {
    dispatch(
      updateFilter({
        showDeleted: event.target.checked,
      })
    );
  };

  const fetchEntitiesReset = () => {
    const pageNo = (countQueues - 1) % filter[filter.currModule].pageSize === 0
        ? filter[filter.currModule].pageNo - 1
        : filter[filter.currModule].pageNo;
    dispatch(
      updateFilter({
        pageNo: pageNo === -1 ? 0 : pageNo,
      })
    );
    setSelected([]);
  };
  const isAllSelected = useMemo(
    () => fetchedData?.every((d) => selected?.includes(d.id)),
    [fetchedData, selected]
  );

  useEffect(() => {
    dispatch(fetchEntities({ ...filter[filter.currModule] }));
  }, []);

  useEffect(() => {
    dispatch(fetchEntities({ ...filter[filter.currModule] }));
  }, [dispatch, fetchEntities, filter, history.location.pathname]);

  const fetchEntitiesWithFilter = () => dispatch(fetchEntities({ ...filter[filter.currModule] }));
  const handleChangePage = (event, newPage) => {
    dispatch(
        updateFilter({
          pageNo: newPage,
        }),
    );
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(
        updateFilter({
          pageNo: 0,
          pageSize: parseInt(event.target.value, 10),
        }),
    );
  };

  const handleNext = (page) => {
    dispatch(
      updateFilter({
        pageNo: page + 1,
      }),
    );
    }
    const handlePrevious = (page) => {
      dispatch(
        updateFilter({
          pageNo: page - 1,
        }),
      );
    }

  return (
    <div>
      <Grid
        container
        alignItems="center"
        className={classes.legend}
        justify="space-between"
      >
        <Grid container item alignItems="flex-end" xs={12}>
          {showTableHeader && <QueueTableHeader
            selected={selected}
            setSelected={setSelected}
            handleSelectedAll={handleSelectedAll}
            checkedAll={
          fetchedData?.length > 0
          && fetchedData.every((d) => selected.includes(d.id))
            }
            checkedindeterminate={
          fetchedData?.length > 0
          && !isAllSelected
          && fetchedData.some((d) => selected.includes(d.id))
            }
            fetchedData={fetchedData}
            fetchEntities={fetchEntities}
            deleteEntities={deleteEntities}
            deleteEntitiesLabel={labels.deleteEntities}
            deleteAllLabel={labels?.deleteAll}
            page={filter[filter.currModule].pageNo}
            rowsPerPage={filter[filter.currModule].pageSize}
            showDeleted={filter[filter.currModule].showDeleted}
            handleChangeShowDeleted={handleChangeShowDeleted}
            enableDeleteMultiple={enableDeleteMultiple}
            confirmActionLabel={labels.confirmAction}
            entityDeleted={labels.entityDeleted}
          />}
        </Grid>
      </Grid>
      <Query type={entityQuery} loadingComponent={CircularLoader}>
        {({ data }) => {
          if (!data?.content?.length) setCountQueues(0);
          setFetchedData(data?.content);
          const length = data.totalElements;
          setCountQueues(length);
          (data?.content?.length > 0) ? setShowTableHeader(true) : setShowTableHeader(false)
          return data?.content?.length > 0 ? (
            data.content?.map((row, index) => (
              <QueueTableRow
                headerCells={headerCells}
                key={`admin-table-${index}-${Math.random()}`}
                row={row}
                index={index}
                handleClick={() => {
                  history.push(`${entityBaseUrl}/${get(row, "id")}`);
                }}
                selected={selected}
                setSelected={setSelected}
                displayFields={displayFields}
                entityBaseUrl={entityBaseUrl}
                enableDisableEntity={enableDisableEntity}
                resetEntity={resetEntity}
                deleteEntity={deleteEntity}
                preDeleteEntity={preDeleteEntity}
                enableLabel={labels.enableEntity}
                enableConfirm={labels.enableConfirm}
                disableLabel={labels.disableEntity}
                disableConfirm={labels.disableConfirm}
                deleteEntityLabel={labels.deleteEntity}
                restoreEntityLabel={labels.restoreEntity}
                deleteEntityTooltip={labels.deleteEntityTooltip}
                fetchEntitiesReset={fetchEntitiesReset}
                showStatusSwitch={showStatusSwitch}
                enableDeleteMultiple={enableDeleteMultiple}
                fetchEntities={fetchEntitiesWithFilter}
                enableEdit={enableEdit}
                enableDelete={enableDelete}
                confirmActionArchive={labels.confirmActionArchive}
                restoreEntityTooltip={labels.restoreEntityTooltip}
                entityDeleted={labels.entityDeleted}
              />
            ))
          ) : (
            <DataNotFound
              message={t("queue.management.no-data")}
              icon={OrchestratorNotFound}
            />
          );
        }}
      </Query>
      {showTableHeader && <CustomPagination
          rowsPerPageOptions={[5, 10, 25]}
          count={countQueues ?? 0}
          rowsPerPage={filter[filter.currModule].pageSize}
          page={filter[filter.currModule].pageNo}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          onNext={handleNext}
          onPrevious={handlePrevious}
      />}
    </div>
  );
}
