import React from "react";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import PersonIcon from "@material-ui/icons/Person";
import { useTranslation } from "react-i18next";
import get from "lodash/get";
import { getApiDomain } from "util";
import StatusBadge from "components/StatusBadge";
import useStyles from "./style.js";

export default function QueueTableCell({ row, field }) {
  const { t } = useTranslation();

  const classes = useStyles();
  const getFieldValue = (
    row,
    {
 valuePath, translate = true, format, defaultValue
}
  ) => {
    // eslint-disable-next-line no-nested-ternary
    const value = Array.isArray(valuePath)
      ? valuePath.reduce(
          (acc, currentValue) => `${acc} ${t(get(row, currentValue))}`.trim(),
          ""
        )
      : translate
      ? t(get(row, valuePath))
      : get(row, valuePath);
    if (!value && defaultValue) return defaultValue;
    if (format && typeof format === "function") return format(value);
    return value;
  };

  const getImageURL = (path) => {
    const imageUrl = field.id === "tagIcon"
        ? `${getApiDomain()}api/orchestrator/tags/${row.id}/icon`
        : `${getApiDomain()}api/admin${path}`;

    // Create a function to check if the image exists
    const checkImageExists = (url) => new Promise((resolve) => {
        const img = new Image();
        img.onload = () => resolve(true);
        img.onerror = () => resolve(false);
        img.src = url;
      });

    return checkImageExists(imageUrl) ? imageUrl : null;
  };

  const getInitiales = (name) => {
    if (!name) return null;
    return name.split(" ").reduce((acc, word, index) => {
      if (index === 0) {
        return word.charAt(0).toUpperCase();
      }
      return acc;
    }, "");
  };

  return (
    <Grid item xs={field.gridSize} align="center">
      {(() => {
        switch (true) {
          case Boolean(field.action):
            return field.action.component({ row });
          case field.isAvatar:
            let avatarComponent = null;
            const avatarPath = get(row, field.avatarPath);
            const valuePath = get(row, field.valuePath);
            if (avatarPath) {
              avatarComponent = (
                <Avatar
                  className={classes.userAvatar}
                  alt={valuePath}
                  src={getImageURL(avatarPath)}
                />
              );
            } else if (getInitiales(valuePath)) {
              avatarComponent = (
                <Avatar
                  className={`${classes.userAvatar} ${classes.avatarColor}`}
                >
                  {getInitiales(valuePath)}
                </Avatar>
              );
            } else {
              avatarComponent = (
                <Avatar
                  className={`${classes.userAvatar} ${classes.avatarColor}`}
                >
                  <PersonIcon className={classes.avatarIcon} />
                </Avatar>
              );
            }
            return (
              <Grid item xs={field.gridSize} align="left">
                {avatarComponent}
              </Grid>
            );
          case field.isStatus:
            return <Box component={Grid} item xs={field.gridSize} align="center" pr={1.5}><StatusBadge level={getFieldValue(row, field)} /></Box>;
          default:
            return (
              <Typography color="secondary" variant="body2" align="center">
                {getFieldValue(row, field)}
              </Typography>
            );
        }
      })()}
    </Grid>
  );
}
