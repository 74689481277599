import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, useDispatch, useSelector } from "react-redux";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import get from "lodash/get";
import {
  updateprocessdescription,
  updateProcessStatus,
  fetchProcesses,
  checkIfPrioriryAlreadyExists, updateProcessPartially,
} from "../../../../redux/actions/services";
import StatusButton from "../../../../components/StatusButton";
import {
  isPermitted,
} from "../../../../components/HasPermission";
import ConfirmMessage from "../../../../components/ConfirmMessage";
import CustomSwitch from "../CustomSwitch";
import Field from "./Field";

function GeneralInfo(props) {
  const {
    classes, process, statusData, setIsDataEdited, setProcess
  } = props;
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(process?.isDisabled);
  const { status } = props;
  const { t } = useTranslation();
  const [openSlide, setOpenSlide] = useState(false);
  const currentUser = useSelector(({ requests }) => get(requests, "queries.FETCH_CURRENT_USER.data"));
  useEffect(() => {
    setDisabled(process?.isDisabled)
  }, [process]);
  const filterProcess = useSelector(({ filterProcess: i }) => i);
  const onSubmitDeveloper = (data) => dispatch(updateProcessPartially(process?.id, { processDescription: { developer: data?.developer } }))
  const onSubmitProcessOwner = (data) => dispatch(updateProcessPartially(process?.id, { processDescription: { processOwner: data?.processOwner } }))
  const onSubmitDescription = (data) => dispatch(updateProcessPartially(process?.id, { processDescription: { processDescription: data?.processDescription } }))
  const handleCloseSlide = () => {
    setOpenSlide(false);
  };
  const handleCloseSlideAndUpdatePriority = () => {
    props.changePriority();
    updateStatus(false);
    setOpenSlide(false);
    props.handleClose();
    props.callAfterClose(process?.id);
  };

  const checkIfPriorityExist = () => dispatch(
    checkIfPrioriryAlreadyExists(
      process?.id,
      parseInt(process?.processRestriction?.priority),
    ),
  );

  const updateStatus = (checked) => {
    process.isDisabled = checked;
    setDisabled(checked);
    dispatch(updateProcessStatus(process?.id, checked)).then((res) => {
      if (res.status === 200) {
        dispatch(
          fetchProcesses(
            filterProcess.search,
            filterProcess.fleet,
            filterProcess.division,
            filterProcess.showAll,
            filterProcess.tags,
            filterProcess.pageNo,
            filterProcess.pageSize,
            filterProcess.sortBy
          ),
        ).then(() => {
          if (checked) props.handleClose();
        });
      }
    })
  };
  const handleToggleProcessStatus = (event) => {
    const { checked } = event.target;
    if (process?.isDisabled) {
      checkIfPriorityExist().then((res) => {
        if (!res.data) {
          updateStatus(checked);
        } else setOpenSlide(true);
      });
    } else {
      updateStatus(checked);
    }
  };

  const fieldsData = [
      {
        field: "processOriginalName",
        label: "processOriginalName",
        submit: null,
        content: process?.processDescription?.processOriginalName,
        isEditable: false,
      },
      {
       field: "processDescription", label: "Description", submit: onSubmitDescription, content: process?.processDescription?.processDescription
      },
     {
       field: "developer", label: "Developer", submit: onSubmitDeveloper, content: process?.processDescription?.developer
      },
     {
       field: "processOwner", label: "process.owner", submit: onSubmitProcessOwner, content: process?.processDescription?.processOwner
      }
  ]
  return (
    <>
      <Grid container direction="column" justify="space-around">
        {fieldsData.map((item) => (
          <Field
                label={item.label}
                field={item.field}
                setProcess={setProcess}
                content={item.content}
                classes={classes}
                setIsDataEdited={setIsDataEdited}
                isEditable={item.isEditable}
                onSubmit={item.submit} />
        ))}
        <Grid container className={classes.field}>
          <Grid container xs={2} direction="column" justify="center">
            <Typography className={classes.processTitle} color="primary" variant="subtitle1">{t("orchestrator")}</Typography>
          </Grid>
          <Grid container direction="row" alignItems="flex-end" justify="flex-start" xs={10}>
            <Typography className={classes.processTitle} color="primary" variant="subtitle1">{process?.orchestrator?.name}</Typography>
          </Grid>
        </Grid>
        <Grid container className={classes.field}>
          <Grid container xs={2} direction="column" justify="center">
            <Typography className={classes.processTitle} color="primary" variant="subtitle1">{t("Status")}</Typography>
          </Grid>
          <Grid container direction="row" alignItems="flex-end" justify="flex-start" xs={10}>
            <Box>
              <StatusButton
                isProcess
                status={status}
                label={statusData[status]?.label || "Ready"}
              />
            </Box>
            {process?.lastStage && status === "RUNNING"
              && (
              <Box ml={1}>
                <StatusButton
                  isProcess
                  status="STOPPING"
                  label={t("process.laststage", { lastStage: process?.lastStage })}
                />
              </Box>
              )}
          </Grid>
        </Grid>
        {isPermitted(currentUser, "Enable/Disable process") && (
          <Grid container xs={12}>
            <Grid container xs={2} direction="column" justify="center">
              <Typography className={classes.processTitle} color="primary" variant="subtitle1">{t("process.hidden")}</Typography>
            </Grid>
            <Grid container xs={10} className={classes.switchBtn}>
              <Grid item>
                <CustomSwitch name="HideProcess" checked={disabled} handleChange={handleToggleProcessStatus} />
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
      {openSlide && (
        <ConfirmMessage
          message={t("priority.error.unhide")}
          openStart={openSlide}
          onCancel={handleCloseSlide}
          onConfirm={handleCloseSlideAndUpdatePriority}
          buttonConfirm={t("Accept")}
          buttonCancel={t("Cancel")}
        />
      )}
    </>
  );
}
const mapDispatchToProps = {
  updateprocessdescription,
};
export default connect(null, mapDispatchToProps)(GeneralInfo);
