import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { useParams, useHistory } from "react-router-dom";

import CustomTab, { useIndicatorStyle } from "pages/Services/components/CustomTab";
import Tabs from "@material-ui/core/Tabs";
import Stepper from "@material-ui/core/Stepper"
import Step from "@material-ui/core/Step"
import StepLabel from "@material-ui/core/StepLabel"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import Paper from "@material-ui/core/Paper"
import Avatar from "@material-ui/core/Avatar"
import MenuItem from "@material-ui/core/MenuItem"
import Select from "@material-ui/core/Select"
import FormControl from "@material-ui/core/FormControl"
import InputLabel from "@material-ui/core/InputLabel"
import TextField from "@material-ui/core/TextField"
import Box from "@material-ui/core/Box"
import Tooltip from "@material-ui/core/Tooltip"
import CustomSwitch from "../../../Services/components/CustomSwitch";

import Autocomplete from "@material-ui/lab/Autocomplete";
import CustomCloseButton from "pages/Services/components/CustomCloseButton";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import PersonIcon from "@material-ui/icons/Person";
import get from "lodash/get";
import ImageProfileCmp from "../../ImageProfile";

import useStyles from "../../FleetManagement/FleetForm/style";
import {
  fetchUser,
  createUser,
  modifyUser,
  fetchUserProcesses,
  fetchCivilities,
  fetchJobs,
  fetchAllFleets,
  fetchUserPermissionsTemplates,
  fetchDivisionByFleetId,
  fetchDivisionsByUserId,
  fetchAllTagsOfUser,
  fetchProcessesByDivisionIds,
  fetchDivisionProcessesByUserId,
  fetchFleetTags,
  fetchFilterProcesses,
} from "../../../../redux/actions/services/index";
import {
  defaultCOEPermissions, defaultMMGTPermissions,
  defaultPermissions, defaultSMEPermissions,
  isFleetAdministrator,
  REGEX, resolveAdminPermissions,
} from "../../../../util";
import { isPermitted } from "../../../../components/HasPermission";
import ConfirmMessage from "../../../../components/ConfirmMessage";
import { toast } from "react-toastify";
import DivisionSelector from "../../DivisionSelector";
import ProcessAndTagSelector from "pages/Administration/ProcessAndTagSelector";
import { isPartitioningDisabled } from "../../../../util/constants/PermitedFeature";
import CustomButton from "../../../../components/CustomButton";

const AddUser = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { idUser, mode = "add" } = useParams();

  const civilities = useSelector(
    ({ requests }) => requests.queries.FETCH_USERS_CIVILITIES?.data,
  );

  const fleets = useSelector(
    ({ requests }) => requests.queries.FETCH_ALL_FLEETS?.data,
  );

  const jobs = useSelector(
    ({ requests }) => requests.queries.FETCH_USERS_JOBS?.data,
  );

  const userProcesses = useSelector(
    ({ requests }) => requests.queries.FETCH_USER_PROCESSES?.data,
  );

  const userTags = useSelector(
    ({ requests }) => requests.queries.FETCH_ALL_TAGS_OF_USER?.data,
  );

  const currentUser = useSelector(({ requests }) => get(requests, "queries.FETCH_CURRENT_USER.data"));

  const [fleet, setFleet] = useState({});
  const [updatedPermissions, setUpdatedPermissions] = useState();

  const [logo, setLogo] = React.useState(null);
  const [logoPath, setLogoPath] = React.useState();

  const [assignedTags, setAssignedTags] = useState([]);
  const [availableTags, setAvailableTags] = useState([]);

  const [openMsgConfirm, setOpenMsgConfirm] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [openMsgCancel, setOpenMsgCancel] = React.useState(false);
  const [availableProcesses, setAvailableProcesses] = useState([]);
  const [assignedProcesses, setAssignedProcesses] = useState([]);
  const [availableDivisions, setAvailableDivisions] = useState([]);
  const [assignedDivisions, setAssignedDivisions] = useState([]);
  const [userPermissionsTemplate, setUserPermissionsTemplate] = useState([]);
  const [fleetChanged, setFleetChanged] = useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const divisionIds = assignedDivisions?.map(({ id }) => id)
  const isCurrentUserAdmin = React.useMemo(
      () => isFleetAdministrator(currentUser),
      [currentUser],
  );

  const {
    formState: { errors },
    control,
    handleSubmit,
    setValue,
    getValues,
    trigger,
    clearErrors,
    formState: { isDirty },
    register,
      watch
  } = useForm({
    mode: "onChange",
    defaultValues: {
      civility: "",
      fleet: {},
      lastName: "",
      firstName: "",
      email: "",
      phoneNumber: "",
      requireOtp: false,
      isFleetAdmin: false,
    },
  });
  useEffect(() => {
    dispatch(fetchCivilities()).then((response) => {
      dispatch(fetchAllFleets());
      dispatch(fetchJobs());
      dispatch(fetchUserPermissionsTemplates()).then((res) => {
        if (res.status === 200) {
          let { data } = res;
          data = data?.map((template) => ({
            ...template,
            permissions: JSON.parse(template?.permissions),
          }));
          setUserPermissionsTemplate(data);
        }
      });
      if (mode === "add") {
        setValue("civility", response?.data[0]?.code);
      }
    });

    if (mode !== "add") {
      dispatch(fetchUser(idUser)).then((res) => {
        setValue("civility", res?.data?.civility?.code);
        setValue("lastName", res?.data?.lastName);
        setValue("firstName", res?.data?.firstName);
        setValue("email", res?.data?.email);
        setValue("fleet", res?.data?.fleet);
        setValue("phoneNumber", res?.data?.phoneNumber);
        setValue("requireOtp", res?.data?.requireOtp);
        setValue("isFleetAdmin", res?.data?.isFleetAdmin);
        setUpdatedPermissions(res?.data?.permissions);
        setValue("requireOtp", res?.data?.requireOtp);
        setLogoPath(res?.data?.logo);
        setFleet(res?.data?.fleet);
      });
    }
  }, [mode, idUser, dispatch, setValue]);
  useEffect(() => {
    if (mode === "add") {
      if (!isPartitioningDisabled) {
        dispatch(fetchProcessesByDivisionIds(divisionIds)).then(
            (res) => {
              setAvailableProcesses(res?.data?.filter(({ id }) => !assignedProcesses?.map(({ id }) => id)?.includes(id)) ?? [])
              setAssignedProcesses((prevState) => prevState.filter(({ id }) => res?.data.map(({ id }) => id)?.includes(id)))
            },
        );
      }
      else if (fleet?.id)
      {
        dispatch(fetchFilterProcesses(fleet.id)).then(
            (res) => {
              setAvailableProcesses(res?.data?.list ?? [])
              setAssignedProcesses([])
            },
        );
      }
    } else if (mode === "view") {
      if (idUser) {
        if (!isPartitioningDisabled)
        {
          dispatch(fetchDivisionProcessesByUserId(idUser, assignedDivisions.map(({ id }) => id))).then(
              (res) => {
                setAvailableProcesses(res?.data?.list ?? []);
              },
          );
          setAssignedProcesses(userProcesses?.list);
        }
        else distributeProcesses();
      }
    } else if (mode === "edit") {
      if (idUser) {
        if (!isPartitioningDisabled)
        {
          setAssignedProcesses(userProcesses?.list);
          dispatch(fetchDivisionProcessesByUserId(idUser, assignedDivisions.map(({ id }) => id))).then(
              (res) => {
                setAvailableProcesses(res?.data?.list ?? []);
              },
          );
        }
        else distributeProcesses();
      }
    }
  }, [fleet, fleetChanged, idUser, activeStep]);

  const distributeProcesses = () => {
    if (fleet?.id) {
      setAssignedProcesses(userProcesses?.list);
      dispatch(fetchFilterProcesses(fleet.id)).then(
          (res) => {
            const userPorcessIds = userProcesses?.list?.map(({ id }) => id);
            const available = res?.data?.list?.filter(({ id }) => !userPorcessIds.includes(id)) ?? [];
            setAvailableProcesses(available);
          },
      );
    }
  }

  useEffect(() => {
    if (mode === "view" || mode === "edit") {
      if (idUser) {
        dispatch(fetchDivisionsByUserId(idUser)).then(
            (res) => {
              setAssignedDivisions(res?.data ?? []);
            },
        );
      }
    }
  }, [fleet, fleetChanged, idUser]);

  useEffect(() => {
    // TODO update this function to use userTags
    if (mode === "add" && fleet?.id) {
      dispatch(fetchFleetTags(fleet.id)).then((result) => {
        setAvailableTags(result?.data?.filter((element) => !assignedTags?.map((tag) => tag.id)?.includes(element.id)));
        setAssignedTags((prevState) => prevState.filter((element) => result?.data?.map((tag) => tag.id)?.includes(element.id)));
      });
    } else if (mode === "view" && idUser && fleet?.id) {
      dispatch(fetchFleetTags(fleet.id)).then((result) => {
        setAvailableTags(result?.data?.filter((element) => !userTags?.map((tag) => tag.id)?.includes(element.id)));
      });
      setAssignedTags(userTags ?? []);
    } else if (mode === "edit" && idUser && fleet?.id) {
      setAssignedTags(userTags ?? []);
      dispatch(fetchFleetTags(fleet.id)).then((result) => {
        setAvailableTags(result?.data?.filter((element) => !userTags?.map((tag) => tag.id)?.includes(element.id)));
      });
    }
  }, [idUser, activeStep, fleet])

  useEffect(() => {
    if (idUser) {
      dispatch(fetchUserProcesses(idUser, false));
      dispatch(fetchAllTagsOfUser(idUser));
    }
  }, [idUser, dispatch]);

  useEffect(() => {
    if (idUser) setAssignedProcesses(userProcesses?.list ?? []);
  }, [idUser, userProcesses]);

  useEffect(() => {
    if (idUser) setAssignedTags(userTags?.list ?? []);
  }, [idUser, userTags])

  useEffect(() => {
    if (mode === "add" && currentUser && currentUser?.fleet) {
      setFleet(currentUser?.fleet);
      setValue("fleet", currentUser?.fleet);
    }
  }, [currentUser]);

  const handleCancel = () => {
    if (mode === "view" || !isDirty) {
      redirectToList();
      return;
    }
    setOpenMsgCancel(true);
  };
  const redirectToList = () => history.push("/userAdministration/users");

  const handleBack = () => {
    if (activeStep <= 0) handleCancel();
    setActiveStep((prevActiveStep) => (prevActiveStep === 0 ? 0 : prevActiveStep - 1));
  };

  const handleStepClick = async (step) => {
    if (activeStep !== step && mode === "edit") {
      const result = await trigger();
      if (result) setActiveStep(step);
    }
  };

  const handleSaveClick = async () => {
    const result = await trigger();
    if (result) {
      if (activeStep === steps.length - 1) {
        setOpenMsgConfirm(true);
      }
      if (activeStep < steps.length - 1) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    }
  };

  const cancelConfirm = () => {
    setOpenMsgConfirm(false);
    setIsLoading(false);
  };

  const handleAcceptCancelForm = () => {
    setOpenMsgCancel(false);
    history.push("/userAdministration/users");
  };

  const handleRejectCancelForm = () => setOpenMsgCancel(false);

  const confirmSave = () => {
    handleSubmit(onSubmit)();
  };

  const resolvePermissions = (dataPermissions, isFleetAdmin) => {
    let toResolveFrom;
    if (mode === "add")
    {
      switch (dataPermissions)
      {
        case "default": toResolveFrom = defaultPermissions; break;
        case "COE-default": toResolveFrom = defaultCOEPermissions; break;
        case "SME-default": toResolveFrom = defaultSMEPermissions; break;
        case "MMGT-default": toResolveFrom = defaultMMGTPermissions; break;
        default: toResolveFrom = JSON.parse(dataPermissions);
      }
    }
    else toResolveFrom = JSON.parse(updatedPermissions);
    return JSON.stringify(isFleetAdmin ? resolveAdminPermissions(toResolveFrom) : toResolveFrom);
  }
  const onSubmit = (data) => {
    const jobTitle = jobs?.find((j) => j.code === "smartroby_user");
    const isFleetAdmin = (currentUser?.id === idUser) ? isCurrentUserAdmin : data.isFleetAdmin;
    const dataToSubmit = {
      ...data,
      civility: civilities.find((civ) => civ.code === data.civility),
      jobTitle,
      divisionIds: assignedDivisions?.map(({ id }) => id),
      permissions: resolvePermissions(data.permissions, isFleetAdmin),
      tagsIds: assignedTags.map((element) => element.id),
      processIds: assignedProcesses.map((element) => element.id),
      isFleetAdmin
    };
    setIsLoading(true);
    if (mode === "add") {
      dispatch(
        createUser(dataToSubmit, logo, (res) => {
          if (res?.status === 200) {
            setOpenMsgConfirm(false);
            history.push({
              pathname: "/userAdministration/users",
            });
            toast.success(t("user.formControl.saveSuccess"))
          }
          setIsLoading(false);
        }),
      )
        .then((res) => {
          if (res.response.status !== "OK") {
            setOpenMsgConfirm(false);
            toast.error(t(res.response?.data?.detail))
          }
        })
        .catch(() => {});
    } else if (mode === "edit") {
      dispatch(
        modifyUser(idUser, dataToSubmit, logo, (res) => {
          if (res?.status === 200) {
            setOpenMsgConfirm(false);
            history.push({
              pathname: "/userAdministration/users",
            });
            toast.success(t("user.formControl.updateSuccess"))
          }
          setIsLoading(false);
        }),
      )
        .then((res) => {
          if (res.response.status !== "OK") {
            setOpenMsgConfirm(false);
            toast.error(t(res.response.data.title))
          }
        })
        .catch(() => {});
    }
  };

  const getImageURL = (path) => `${process.env.REACT_APP_DOMAIN}api/admin${path}`;

  const disableFields = !(mode === "add" || mode === "edit");

  const steps = [
    "fleet.formSection.fleetInfo",
    ...!process.env.REACT_APP_DISABLE_PARTITIONING ? ["fleet.formSection.divisions"] : [],
    "fleet.formSection.processesTags",
  ];

  const handleChange = (_, newValue) => {
    setActiveStep(newValue);
  };

  useEffect(() => {
    if (watch("fleet")?.id) { dispatch(fetchDivisionByFleetId(watch("fleet")?.id)).then((res) => {
      setAvailableDivisions(res?.data?.filter(({ id }) => !assignedDivisions.map(({ id }) => id)?.includes(id))) }) }
  }, [watch("fleet"), assignedDivisions])

  useEffect(() => {
    if (!process.env.REACT_APP_DISABLE_PARTITIONING) {
      const tempProcesses = assignedDivisions.flatMap((division) => (division?.processesIds ? division?.processesIds?.map((proc) => proc) : division?.processes?.map(({ id }) => id)))
      setAssignedProcesses((prevState) => prevState?.filter(({ id }) => tempProcesses?.includes(id)))
    }
  }, [availableProcesses, availableDivisions])

  const switchFields = [
    {
 label: "user.add.mfa", fieldName: "requireOtp", disabled: disableFields, isAuthorized: true
},
    {
 label: "user.add.fleet.admin", fieldName: "isFleetAdmin", disabled: disableFields || currentUser?.id === idUser, isAuthorized: isCurrentUserAdmin
}]

  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          <>
            <Grid
              item
              xs={12}
              container
              alignItems="center"
              justify="center"
              className={classes.formRow}
            >
              {/* eslint-disable-next-line no-nested-ternary */}
              {mode === "add" || mode === "edit" ? (
                <ImageProfileCmp
                  src={logoPath}
                  changeValue={setLogo}
                  disabled={disableFields}
                  allowedExtensions=".png,.jpeg,.bmp,.jpg"
                  maxMbSize={2}
                />
              ) : logoPath ? (
                <Avatar
                  alt="Client logo"
                  className={classes.large}
                  src={getImageURL(logoPath)}
                />
              ) : (
                <Avatar className={classes.large}>
                  <PersonIcon className={classes.avatarIcon} />
                </Avatar>
              )}
            </Grid>
            <Grid item xs={12} container className={classes.formRow}>
              <Grid item xs={6} className={classes.inputsSection}>
                <Controller
                  control={control}
                  name="fleet"
                  rules={{
                    required: {
                      value: true,
                      message: t("user.management.formControl.required"),
                    },
                  }}
                  render={(field) => (
                    <Autocomplete
                      {...field}
                      value={fleet}
                      disabled={
                         mode === "view" || (!isFleetAdministrator(currentUser) && mode === "edit")
                      }
                      rules={{
                        required: {
                          value: true,
                          message: t("user.management.formControl.required"),
                        },
                      }}
                      options={fleets || []}
                      getOptionLabel={(option) => option?.companyName}
                      renderOption={(option) => (
                        <span>{option?.companyName}</span>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={`${t("user.management.formLabel.fleet")} *`}
                          error={!!errors.fleet?.message}
                        />
                      )}
                      onChange={(_, data) => {
                        clearErrors("fleet");
                        setFleet(data);
                        setFleetChanged(true);
                        return field.field?.onChange(data);
                      }}
                      disableClearable
                    />
                  )}
                />
                <p className={classes.validation_error}>
                  {errors.fleet?.message}
                </p>
              </Grid>
              <Grid item xs={6} className={classes.inputsSection}>
                <FormControl className={classes.formControl} fullWidth>
                  <InputLabel id="civility-select">
                    {`${t("user.add.civility")} *`}
                  </InputLabel>
                  <Select
                    {...register("civility", {
                      required: {
                        value: true,
                        message: t("user.management.formControl.required"),
                      },
                    })}
                    disabled={disableFields}
                    fullWidth
                    labelId="civility-select"
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                    onChange={(e) => {
                      clearErrors("civility");
                      setValue("civility", e.target.value);
                    }}
                    value={getValues("civility")}
                  >
                    {civilities
                      && civilities.map((elt, i) => (
                        <MenuItem
                          key={`user-management-form-civility-option-${i}`}
                          value={elt.code}
                        >
                          {t(elt.label)}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>

                <p className={classes.validation_error}>
                  {errors.subscription?.message}
                </p>
              </Grid>
            </Grid>
            <Grid item xs={12} container className={classes.formRow}>
              <Grid item xs={6} className={classes.inputsSection}>
                <TextField
                  {...register("lastName", {
                    required: {
                      value: true,
                      message: t("user.management.formControl.required"),
                    },
                  })}
                  id="lastName"
                  label={`${t("user.add.lastName")} *`}
                  disabled={disableFields}
                  fullWidth
                  InputLabelProps={{
                    shrink: !!getValues("lastName"),
                  }}
                  error={!!errors.lastName?.message}
                  onChange={(event) => {
                    clearErrors("lastName");
                    setValue("lastName", event.target.value);
                  }}
                />

                <p className={classes.validation_error}>
                  {errors.lastName?.message}
                </p>
              </Grid>
              <Grid item xs={6} className={classes.inputsSection}>
                <TextField
                  id="firstName"
                  {...register("firstName", {
                    required: {
                      value: true,
                      message: t("user.management.formControl.required"),
                    },
                  })}
                  label={`${t("user.add.firstName")} *`}
                  disabled={disableFields}
                  fullWidth
                  InputLabelProps={{
                    shrink: !!getValues("firstName"),
                  }}
                  error={!!errors.firstName?.message}
                  onChange={(event) => {
                    clearErrors("firstName");
                    setValue("firstName", event.target.value);
                  }}
                />

                <p className={classes.validation_error}>
                  {errors.firstName?.message}
                </p>
              </Grid>
            </Grid>
            <Grid item xs={12} container className={classes.formRow}>
              <Grid item xs={6} className={classes.inputsSection}>
                <TextField
                  id="email"
                  {...register("email", {
                    required: {
                      value: true,
                      message: t("user.management.formControl.required"),
                    },
                    pattern: {
                      value: REGEX.EMAIL,
                      message: t("user.management.formControl.email"),
                    },
                  })}
                  label={`${t("user.add.email")} *`}
                  disabled={mode !== "add"}
                  fullWidth
                  InputLabelProps={{
                    shrink: !!getValues("email"),
                  }}
                  error={!!errors.email?.message}
                  onChange={(event) => {
                    clearErrors("email");
                    setValue("email", event.target.value);
                  }}
                />

                <p className={classes.validation_error}>
                  {errors.email?.message}
                </p>
              </Grid>
              <Grid item xs={6} className={classes.inputsSection}>
                <TextField
                  id="phoneNumber"
                  {...register("phoneNumber", {
                    pattern: {
                      value: REGEX.TEL,
                      message: t("user.management.formControl.phone"),
                    },
                  })}
                  label={t("fleet.management.formLabel.phoneNumber")}
                  disabled={disableFields}
                  fullWidth
                  error={!!errors.phoneNumber?.message}
                  onChange={(event) => {
                    clearErrors("phoneNumber");
                    setValue("phoneNumber", event.target.value);
                  }}
                  InputLabelProps={{
                    shrink: !!getValues("phoneNumber"),
                  }}
                />
                <p className={classes.validation_error}>
                  {errors.phoneNumber?.message}
                </p>
              </Grid>
            </Grid>

            <Grid item xs={12} container className={classes.formRow}>
              {mode === "add" ? (
                <Grid item xs={6} className={classes.inputsSection}>
                  <Controller
                    control={control}
                    name="permissions"
                    defaultValue=""
                    rules={{
                      required: {
                        value: true,
                        message: t("user.management.formControl.required"),
                      },
                    }}
                    render={(props) => (
                      <FormControl className={classes.formControl} fullWidth>
                        <InputLabel id="permissionsTemplate-select">
                          {`${t("user.add.permissions")} *`}
                        </InputLabel>
                        <Select
                          {...props}
                          disabled={disableFields}
                          fullWidth
                          labelId="permissionsTemplate-select"
                          MenuProps={{
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                            getContentAnchorEl: null,
                          }}
                          onChange={(event) => {
                            clearErrors("permissions");
                            setValue("permissions", event.target.value);
                          }}
                          value={watch("permissions")}
                        >
                          <MenuItem key={`default-permission${1}`} value="COE-default">
                            {`COE (${t("user.permissions.default")})`}
                          </MenuItem>
                          <MenuItem key={`default-permission${2}`} value="SME-default">
                            {`SME (${t("user.permissions.default")})`}
                          </MenuItem>
                          <MenuItem key={`default-permission${3}`} value="MMGT-default">
                            {`MMGT (${t("user.permissions.default")})`}
                          </MenuItem>
                          {userPermissionsTemplate
                            && userPermissionsTemplate.map((elt, i) => (
                              <MenuItem
                                key={`userPermissionsTemplate-${i}`}
                                value={JSON.stringify(elt.permissions)}
                              >
                                {t(elt.name)}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    )}
                  />
                  <p className={classes.validation_error}>
                    {errors.permissions?.message}
                  </p>
                </Grid>
              ) : (
                <Grid item xs={6} className={classes.inputsSection} />
              )}
              <Grid
                item
                container
                xs={12}
                direction="row"
                alignContent="flex-end"
                className={classes.inputsSection}
                style={{ paddingBottom: 0 }}
              >
                <Grid container item xs={12}>
                  {switchFields.map((field, i) => (field.isAuthorized ? (
                    <Grid item xs={4} alignItems="center" key={`${field.fieldName}-${i}`}>
                      <CustomSwitch
                        key={watch(field.fieldName)}
                        {...register(field.fieldName)}
                        checked={watch(field.fieldName)}
                        disabled={field.disabled}
                        handleChange={() => (watch(field.fieldName) ? setValue(field.fieldName, false) : setValue(field.fieldName, true))}
                        label={t(field.label)}
                      />
                    </Grid>
                  ) : null))}
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              container
              className={classes.formRow}
              justify="flex-end"
            />
          </>
        );
      case (!process.env.REACT_APP_DISABLE_PARTITIONING && 1):
        return (
          <Grid item xs={12} style={{ height: "100%" }}>
            <DivisionSelector
              availableDivisions={availableDivisions}
              selectedDivisions={assignedDivisions}
              isDisabled={disableFields}
              setAvailableDivisions={setAvailableDivisions}
              setSelectedDivisions={setAssignedDivisions}
            />
          </Grid>
        );
      case (process.env.REACT_APP_DISABLE_PARTITIONING ? 1 : 2):
        return (
          <Grid item xs={12} style={{ height: "100%" }}>
            <ProcessAndTagSelector
              availableProcesses={availableProcesses}
              selectedProcesses={assignedProcesses}
              isDisabled={disableFields}
              setAvailableProcesses={setAvailableProcesses}
              setSelectedProcesses={setAssignedProcesses}
              availableTags={availableTags}
              selectedTags={assignedTags}
              setAvailableTags={setAvailableTags}
              setSelectedTags={setAssignedTags}
            />
          </Grid>
        );
      default:
        return null;
    }
  };

  const tabsStyle = useIndicatorStyle();

  return (
    <div className={classes.root}>
      {/* <AdminPageHeader title={headerTitle} /> */}
      <Paper className={classes.paper}>
        {mode === "view" ? (
          <Paper square>
            <Grid container direction="row" xs={12}>
              <Grid container direction="row" xs={11}>
                <Tabs
                  id="process-tabs-page"
                  className={classes.tabSize}
                  value={activeStep}
                  indicatorColor="primary"
                  textColor="primary"
                  onChange={handleChange}
                  aria-label="switch tabs"
                  TabIndicatorProps={{
                    style: tabsStyle,
                  }}
                >
                  {steps.map((label, index) => (
                    <CustomTab
                      key={label}
                      id="process-overview-tab"
                      value={index}
                      label={t(label)}
                    />
                  ))}
                </Tabs>
              </Grid>
              <Grid container direction="row" xs={1} justify="flex-end">
                <CustomCloseButton
                className={classes.closeIcon}
                onClick={handleCancel}
                />
              </Grid>
            </Grid>
          </Paper>
        ) : (
          <>
            <Grid
              container
              direction="row"
              xs={1}
              justify="flex-end"
              className={classes.closebutton}
            >
              <CustomCloseButton
                className={classes.closeIcon}
                onClick={handleCancel}
                />
            </Grid>
            <Stepper
              activeStep={activeStep}
              alternativeLabel
              className={classes.stepper}
            >
              {steps.map((label, index) => (
                <Step key={label}>
                  <StepLabel
                    style={{ cursor: "pointer" }}
                    onClick={() => handleStepClick(index)}
                  >
                    {t(label)}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </>
        )}
        <div className={classes.formContainer}>
          {mode === "view" && isPermitted(currentUser, "Edit user") ? (
            <Grid container justify="flex-end">
              <Tooltip title={t("Edit")}>
                <IconButton
                  aria-label="modify"
                  onClick={() => {
                    history.push(`/userAdministration/users/edit/${idUser}`);
                  }}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          ) : null}
          <form className={classes.fleetForm}>
            <Grid
              container
              alignItems="center"
              justify="center"
              xs={12}
              className={classes.inputsContainer}
            >
              {/* <Grid container xs={12} item> */}
              {getStepContent(activeStep)}
            </Grid>
            <Grid>
              {!disableFields ? (
                <Grid item xs={12} className={classes.nopadding}>
                  <MuiDialogActions className={classes.modalActionButtons}>
                    <Box
                      ml="1rem"
                      component={Button}
                      variant="contained"
                      size="medium"
                      onClick={handleBack}
                      className={classes.resetButton}
                    >
                      {activeStep <= 0 ? t("Cancel") : t("fleet.add.previous")}
                    </Box>
                    <CustomButton
                      view="primary"
                      size="medium"
                      type="button"
                      onClick={handleSaveClick}
                    >
                      {/* eslint-disable-next-line no-nested-ternary */}
                      {activeStep === steps.length - 1
                        ? idUser
                          ? t("user.button.update")
                          : t("user.button.save")
                        : t("next")}
                    </CustomButton>
                  </MuiDialogActions>
                </Grid>
              ) : null}
            </Grid>
          </form>
        </div>
      </Paper>

      {openMsgConfirm && (
        <ConfirmMessage
          message={
            idUser ? t("user.update.confirmMsg") : t("user.save.confirmMsg")
          }
          openStart={openMsgConfirm}
          onCancel={cancelConfirm}
          onConfirm={confirmSave}
          buttonConfirm={
            idUser ? t("user.button.update") : t("user.button.save")
          }
          buttonCancel={t("Cancel")}
          isLoading={isLoading}
        />
      )}
      {openMsgCancel && (
        <ConfirmMessage
          message={t("user.delete.discard")}
          openStart={openMsgCancel}
          onCancel={handleRejectCancelForm}
          onConfirm={handleAcceptCancelForm}
          buttonCancel={t("Cancel")}
          buttonConfirm={t("Discard")}
          isLoading={false}
        />
      )}
    </div>
  );
};

export default AddUser;
