import React from "react";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import MuiDialogActions from "@material-ui/core/DialogActions";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import CustomDialog from "pages/Services/components/CustomDialog";
import CustomButton from "../CustomButton";
import useStyles from "./style";

function ConfirmMessage({
  message,
  openStart,
  onConfirm,
  onCancel,
  buttonConfirm,
  buttonCancel,
  isDelete,
  isLoading,
}) {
  const classes = useStyles();
  return (
    <CustomDialog
      open={openStart}
      aria-labelledby="confirm_message-dialog-title"
      maxWidth="200px"
    >
      <DialogContent className={classes.dialogContent}>
        <DialogContentText id="confirm_message-dialog-content">
          {message}
        </DialogContentText>
      </DialogContent>
      <MuiDialogActions className={classes.cardActions}>
        <Box
          component={Button}
          variant="contained"
          size="medium"
          className={classes.resetButton}
          onClick={onCancel}
          disabled={isLoading}
        >
          {buttonCancel}
        </Box>
        <CustomButton
          view="primary"
          ml="1rem"
          size="medium"
          onClick={onConfirm}
          className={isDelete && classes.deleteButton}
          disabled={isLoading}
        >
          {isLoading && <CircularProgress size={14} />}
&nbsp;
          {buttonConfirm}
        </CustomButton>
      </MuiDialogActions>
    </CustomDialog>
  );
}

export default ConfirmMessage;
