import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    rootContainer: {
        marginTop: 15
    },
    container: {
        backgroundColor: theme.palette.background.default,
        padding: 30,
        marginTop: 10,
        marginLeft: -8
     },
    handlingTimeItem: {
        padding: "10px 15px !important",
        border: `3px solid ${theme.custom.color.containerBackground}`,
        marginTop: 5,
        color: theme.palette.primary.main,
    },
    title: {
        fontSize: "1.35rem",
        color: theme.palette.primary.main,
        fontWeight: 700,
        marginBottom: "10px",
    },
    seeAll: {
        fontSize: 13,
        color: theme.palette.primary.main,
        fontWeight: 700,
        marginBottom: "10px",
        textDecoration: "underline",
        cursor: "pointer"
    },
    subtitle: {
        fontSize: 13,
        color: theme.palette.primary.main,
        fontWeight: 700,
        marginBottom: "10px"
    },
    subItem: {
        color: theme.palette.primary.main,
        fontSize: "0.9rem",
        fontWeight: 700,
        wordWrap: "break-word"
    },
    subTime: {
        fontWeight: 600
    },
    percentage: {
        fontSize: "1.1rem",
        fontWeight: 800,
        color: theme.palette.primary.main,
    },
    percentIcon: {
        width: 13,
        height: 13,
        marginLeft: 5,
        color: theme.custom.color.chartColor2,
    },
    percentIconUp: {
        color: `${theme.custom.color.chartColor3}`,
    },
    chartButton: {
        minWidth: "100px !important",
        borderRadius: 0,
        color: theme.palette.primary.main,
        fontSize: 14,
        textTransform: "revert",
        fontWeight: 600
    },
    activeChartButton: {
       border: "0  !important",
       backgroundColor: theme.custom.color.chartColor1,
       "&:hover": {
         backgroundColor: theme.custom.color.chartColor1,
        }
    },
    inactiveChartButton: {
        backgroundColor: "transparent",
        border: `2px solid ${theme.custom.color.chartColor1} !important`,
        "&:hover": {
            backgroundColor: "transparent",
        }
    },
    statesContainer: {
        padding: 15,
    },
    stateIcon: {
        width: 40,
        height: 40,
        color: theme.custom.color.chartColor1,
        marginInline: "16px",
    },
    stateTitle: {
        fontSize: "1.3rem",
        color: theme.palette.primary.main,
        fontWeight: 700,
    },
    stateSubtitle: {
        fontSize: 14,
        color: theme.palette.primary.main,
        fontWeight: 600,
    },
    popupIcon: {
        color: theme.custom.color.chartColor1,
    },
    inputLabel: {
    color: theme.palette.primary.main,
    fontSize: 14
    },
    divider: {
        height: 30,
        width: 3,
        backgroundColor: theme.custom.color.runningBackground,
        opacity: 0.4,
    },
    dataNotFound: {
        height: 720
    }
}));
