import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { ReactComponent as ProcessDefaultIcon } from "assets/ProcessDefaultIcon.svg"
import useStyles from "../style";
import { formatImagePath } from "../../../../../util";

function ProcessesNameCard({ processes, t }) {
  const classes = useStyles();

  return processes?.length > 0 ? (
    processes.map((process) => (
      <Grid
        container
        item
        directiom="row"
        alignItems="center"
        style={{ padding: "0 30px 0 30px", margin: "unset" }}
        spacing="2"
      >
        <Grid item xs={3}>
          {process?.icon ? (
            <img
              src={formatImagePath(process.icon)}
              alt="Service"
              className={classes.image}
            />
          ) : (
            <ProcessDefaultIcon className={classes.image} />
          )}
        </Grid>
        <Grid item xs={9} style={{ paddingRight: "40px" }}>
          <Typography variant="subtitle1" className={classes.content}>
            {process?.processDescription?.processDescription}
          </Typography>
        </Grid>
      </Grid>
    ))
  ) : (
    <Alert severity="warning" className={classes.alert}>
      <Typography variant="subtitle1">
        {t("administration.orchestrator.process.list.not.available")}
      </Typography>
    </Alert>
  );
}

export default ProcessesNameCard;
