import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    closeButton: {
        position: "absolute",
        right: 3,
        top: 3,
        color: theme.palette.grey[500],
    },
    buttonsContainer: {
        display: "flex",
        justifyContent: "flex-end",
        gap: "0.5rem",
        marginBottom: 10
    }
}))
