import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    overflowX: "hidden",
    overflowY: "hidden",
    backgroundColor: "unset",
    height: "100%",
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    width: "25%",
  },
  typograpgy: {
    marginTop: theme.spacing(2),
  },
  cardActions: {
    margin: theme.spacing(1.5),
    padding: "0px",
  },
  formControl: {
    margin: theme.spacing(1),
  },
  selectEmpty: {
    width: theme.spacing(9),
    margin: "0 20px"
  },
  buttonRight: {
    textAlign: "right",
  },
  deleteIcon: {
    color: theme.custom.color.color6,
  },
  hourly: {
    position: "relative",
    bottom: theme.spacing(3),
  },
  daily: {
    position: "relative",
    top: theme.spacing(2),
  },
  weekly: {
    position: "relative",
    top: theme.spacing(8),
  },
  monthly: {
    position: "relative",
    top: theme.spacing(14),
  },
  yearly: {
    position: "relative",
    top: theme.spacing(20),
  },
  tableCell: {
    paddingLeft: theme.spacing(1),
    color: theme.palette.primary.main,
    padding: "2px 10px",
    fontWeight: "700",
    borderBottom: `2px solid ${theme.custom.color.staticInput}`
  },
  autoComplete: {
    width: "220px",
    marginLeft: 0,
    marginBottom: 10,
  },
  pagination: {
    fontSize: theme.spacing(0.875),
  },
  paper: {
    backgroundColor: "unset",
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  scheduleTable: {
    height: "370px",
  },
  scheduleContainer: {
    padding: "35px 35px",
    backgroundColor: theme.custom.color.containerBackground,
  },
  editIcon: {
    justifyContent: "left"
  },
  weeklySchedule: {
    textTransform: "capitalize"
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: 20,
    fontWeight: 700,
    paddingBottom: 0
  },
  input: {
    color: theme.palette.primary.main,
    fontSize: 14,
    fontWeight: "400",
    "& .MuiInput-underline:before": {
      borderBottomColor: theme.custom.color.chartColor2,
      borderWidth: 3,
    },
    "& .MuiInput-underline:not(.Mui-error):after": {
      borderBottomColor: theme.custom.color.chartColor2,
    },
    "& .MuiInput-underline:hover:before": {
      borderBottomColor: theme.custom.color.chartColor2,
      borderWidth: 3,
    },
    "& .MuiInput-underline.Mui-disabled:before": {
      borderBottomStyle: "solid",
      borderBottomColor: theme.custom.color.chartColor4,
    },
    "& .MuiInput-underline.Mui-error:before": {
      borderWidth: 3,
      borderBottomColor: theme.custom.color.chartColor3,
    },
    "& .MuiInputLabel-root": {
      color: theme.palette.primary.main,
      fontSize: 15,
      fontWeight: 600
    }
  },
  inputDisabled: {
    "& .MuiInputLabel-root": {
      color: theme.custom.color.chartColor4,
    }
  },
  popupIcon: {
      color: theme.palette.primary.main
  },
  popupIconDisabled: {
      color: theme.custom.color.chartColor4
  },
  dateLabel: {
    color: theme.palette.primary.main,
    fontSize: 15,
    fontWeight: 600
  },
  periodFormControl: {
    width: "50%"
  },
  periodFormControlLabel: {
    color: theme.palette.primary.main,
    fontSize: 14,
    fontWeight: 700
  },
  tasksContainer: {
    padding: "30px 0 0 30px",
  },
  tasksGrid: {
    height: 380,
    overflowY: "auto",
    paddingRight: 20,
    paddingLeft: 10
  },
  periodError: {
    fontSize: 13
  }
}));
