import React from "react";
import {
  Grid,
  Typography,
  Box,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import {
  formatDateByLanguage,
  formatImagePath,
  secondsToTime,
} from "util";
import { ReactComponent as ProcessDefaultIcon } from "assets/ProcessDefaultIcon.svg"

const WorkqueueitemRow = ({
  row,
  handleOpenOccurenceDetails,
  isPendingItemsPage,
  classes,
  t,
}) => {
  const getDateDifference = (s) => secondsToTime(s, t);

  return (
    <Grid container alignItems="center">
      <Grid
        container
        item
        xs={3}
        alignItems="center"
        className={classes.processCard}
      >
        <Grid item xs={2}>
          {row
            && row.processExecution
            && row.processExecution.process
            && row.processExecution.process.icon ? (
              <img
                src={formatImagePath(row.processExecution.process.icon)}
                align="left"
                alt="Service"
                className={classes.image}
              />
            ) : (
              <ProcessDefaultIcon className={classes.image} />
            )}
        </Grid>
        <Grid item xs={9}>
          <Typography variant="body2" align="left">
            <Box fontWeight="fontWeightBold">
              {" "}
              {row?.processExecution?.process?.processDescription
                ?.processDisplayName
                ? row?.processExecution?.process?.processDescription
                  ?.processDisplayName
                : "---"}
            </Box>
          </Typography>
          {row?.details && (
          <Typography variant="body2" align="left">
            <Box style={{ fontStyle: "italic", paddingTop: "10px" }}>
              {row?.details}
            </Box>
          </Typography>
          )}
        </Grid>
      </Grid>
      <Grid container alignItems="center" item xs={4}>
        <Grid item xs={5}>
          <Typography variant="body2" align="left">
            <Box fontWeight="fontWeightBold">{t("Creation date")}</Box>
          </Typography>
        </Grid>
        <Grid item xs={7} className={classes.gridPadding}>
          <Typography variant="body2" align="left">
            {row?.loadedDatetime ? formatDateByLanguage(row?.loadedDatetime) : "---"}
          </Typography>
        </Grid>
        {!isPendingItemsPage && (
          <Grid item xs={5}>
            <Typography variant="body2" align="left">
              <Box fontWeight="fontWeightBold">{t("End date")}</Box>
            </Typography>
          </Grid>
        )}
        {!isPendingItemsPage && (
          <Grid item xs={7}>
            <Typography variant="body2" align="left">
              {/* eslint-disable-next-line no-nested-ternary */}
              {row?.completedDatetime
                ? formatDateByLanguage(row?.completedDatetime)
                : row?.exceptionTime
                  ? formatDateByLanguage(row?.exceptionTime)
                  : "---"}
            </Typography>
          </Grid>
        )}
      </Grid>

      <Grid container alignItems="center" item xs={4}>
        <Grid item xs={5}>
          <Typography variant="body2" align="left">
            <Box fontWeight="fontWeightBold">{t("Execution detail")}</Box>
          </Typography>
        </Grid>
        <Grid item xs={7}>
          <Typography variant="body2" align="left">
            {row.data ? "" : "N/A"}
            {row?.data && (
              <Tooltip title={t("Details")}>
                <IconButton
                  edge="end"
                  aria-label="view"
                  onClick={() => {
                    handleOpenOccurenceDetails(row);
                  }}
                >
                  <MoreHorizIcon />
                </IconButton>
              </Tooltip>
            )}
          </Typography>
        </Grid>

        {!isPendingItemsPage && (
          <>
            <Grid item xs={5}>
              <Typography variant="body2" align="left">
                <Box fontWeight="fontWeightBold">{t("Execution Time")}</Box>
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <Typography variant="body2" align="left">
                {getDateDifference(row?.worktime)}
              </Typography>
            </Grid>
          </>
        )}
      </Grid>

    </Grid>
  );
};

export default WorkqueueitemRow;
