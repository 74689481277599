import React, { useState } from "react";
import Card from "@material-ui/core/Card";
import DialogActions from "@material-ui/core/DialogActions";
import Grid from "@material-ui/core/Grid";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
import { useTranslation } from "react-i18next";
import AddIcon from "@material-ui/icons/Add";
import { FormHelperText } from "@material-ui/core";
import { get } from "lodash";
import QuestionMarkIcon from "../../../../../components/QuestionMarkIcon";
import { useSelector } from "react-redux";
import TextInputWithIcon from "pages/AlertsManagement/components/TextInputWithIcon";
import DescriptionIcon from "@material-ui/icons/Description";
import NotificationImportantIcon from "@material-ui/icons/NotificationImportant";
import CustomTriggerRow from "./CustomTriggerRow/index";
// eslint-disable-next-line import/no-cycle
import ScheduleForm from "./ScheduleForm/index";
import AlertSeverityInput from "../../../components/AlertSeverityInput";
import ScheduleInfo from "./ScheduleInfo";
import EditAlertButton from "../../../components/EditButton";
import SubscribersInput from "pages/AlertsManagement/components/SubscribersInput";
import isEmpty from "lodash/isEmpty";
import Box from "@material-ui/core/Box";
import CloseAlertButton from "pages/AlertsManagement/components/CloseButton";
import CustomButton from "../../../../../components/CustomButton";
import CustomSwitch from "pages/Services/components/CustomSwitch";

export default function CustomAlertInfo({
  classes,
  register,
  disableFields,
  errors,
  clearErrors,
  showDefaultData,
  trigger,
  fields,
  append,
  remove,
  menuItems,
  isValid,
  idAlert,
  handleCancel,
  control,
  handleChangeEndDate,
  handleChangeStartDate,
  endDate,
  startDate,
  activeCheck,
  handleSchedulerSwitch,
  watch,
  open,
  handleClickOpen,
  handleCloseDialog,
  handleChangeFrequency,
  discardSchedulerChanges,
  frequency,
  mode,
  handleChangeTab,
  value,
  setValue,
  confirmSaveScheduler,
  emailList,
  smsList,
  processMenuItem,
  submittedScheduler,
  handleDeleteScheduler,
  resetCustomAlertScheduler,
  isLoading,
  useInterval,
  handleChangeUseInterval,
  fromSelectedDate,
  onFromDateChange,
  toSelectedDate,
  onToDateChange,
  disableSchedulerInfo,
  entityBaseUrl,
  sameFleetUsers,
  selectedSubscribers,
  handleSubscribersChange,
  getValues,
  smsStatus,
  emailStatus,
  setSmsStatus,
  setEmailStatus,
}) {
  const { t } = useTranslation();

  const currentUser = useSelector(({ requests }) => get(requests, "queries.FETCH_CURRENT_USER.data"));

  const [schedulerError, setSchedulerError] = useState();

  const verifySubmittedScheduler = () => {
    if (isEmpty(submittedScheduler)) {
      setSchedulerError(t("scheduler.is.required"))
    }
  }

  const handleSmsToggle = () => {
    setSmsStatus((prev) => !prev);
  };

  const handleEmailToggle = () => {
    setEmailStatus((prev) => !prev);
  };

  return (
    <Grid container className={classes.rootGrid} justify="center">
      <Grid container item xs={12} md={12} lg={10}>
        <Card className={classes.paper} square>
          <Grid container justify="flex-end" spacing={4}>
            <Grid item>
              <EditAlertButton
                mode={mode}
                currentUser={currentUser}
                url={`${entityBaseUrl}/edit/${idAlert}`}
              />
            </Grid>
            <Grid item>
              { mode === "view" && (
                <CloseAlertButton
                url={entityBaseUrl}
              />
              )}
            </Grid>
          </Grid>
          <Grid item container xs={12} direction="column" spacing={7}>
            <Grid container item direction="column" spacing={2}>
              <TextInputWithIcon
                Icon={NotificationImportantIcon}
                name="alertName"
                label={t("alerts.management.name.label")}
                placeholder={t("alerts.management.name.placeholder")}
                disableFields={disableFields}
                errors={errors}
                classes={classes}
                control={control}
                InputLabelProps={{
                  shrink: !!watch("alertName"),
                }}
                rules={{
                  required: {
                    value: true,
                    message: t("alerts.management.required.fields"),
                  },
                  maxLength: {
                    value: 55,
                    message: t("alerts.management.alertName.maxLength.error"),
                  },
                }}
              />
              <TextInputWithIcon
              fullWidth
                Icon={DescriptionIcon}
                name="alertDescription"
                label={t("alerts.management.alertDescription.label")}
                placeholder={t(
                  "alerts.management.alertDescription.placeholder"
                )}
                disableFields={disableFields}
                errors={errors}
                classes={classes}
                control={control}
                rules={{
                  maxLength: {
                    value: 65535,
                    message: t(
                      "alerts.management.alertDescription.maxLength.error"
                    ),
                  },
                }}
              />
              <AlertSeverityInput
                classes={classes}
                control={control}
                errors={errors}
                disableFields={disableFields}
              />
              <Grid container item xs={6} justify="space-between">
                <CustomSwitch
                    label={t("alerts.management.smsStatus.label")}
                    checked={smsStatus}
                    handleChange={handleSmsToggle}
                    disabled={disableFields}
                />
                <CustomSwitch
                    label={t("alerts.management.emailStatus.label")}
                    checked={emailStatus}
                    handleChange={handleEmailToggle}
                    disabled={disableFields}
                />
              </Grid>
            </Grid>
            <Grid container item spacing={1}>
              <Grid item>
                <span className={classes.trigger}>
                  <InfoOutlinedIcon />
                  <span style={{ marginLeft: "20px" }}>
                    {t("alerts.management.triggers")}
                  </span>
                  {!disableFields && (
                  <QuestionMarkIcon titleContent={t("alert.management.trigger.helper")} />
                  )}
                </span>
              </Grid>
              <CustomTriggerRow
                showDefaultData={showDefaultData}
                errors={errors}
                register={register}
                clearErrors={clearErrors}
                classes={classes}
                fields={fields}
                append={append}
                remove={remove}
                disableFields={disableFields}
                menuItems={menuItems}
                control={control}
                setValue={setValue}
                watch={watch}
                processMenuItem={processMenuItem}
                getValues={getValues}
                mode={mode}
              />
            </Grid>
            {(mode !== "view"
              || emailList?.length > 0
              || smsList?.length > 0) && (
              <Grid container item spacing={2}>
                <Grid item>
                  <span className={classes.trigger}>
                    <NotificationsNoneIcon />
                    <span style={{ marginLeft: "20px" }}>
                      {t("alerts.management.subscribers")}
                    </span>
                  </span>
                </Grid>
                <SubscribersInput
                  sameFleetUsers={sameFleetUsers}
                  mode={mode}
                  disableFields={disableFields}
                  selectedSubscribers={selectedSubscribers}
                  handleSubscribersChange={handleSubscribersChange}
                  control={control}
                  errors={errors}
                  classes={classes}
                />
              </Grid>
            )}

            <Grid container item>
              {submittedScheduler?.frequency ? (
                <ScheduleInfo
                  classes={classes}
                  submittedScheduler={submittedScheduler}
                  handleClickOpen={handleClickOpen}
                  handleDeleteScheduler={handleDeleteScheduler}
                  disableFields={disableFields}
                  resetCustomAlertScheduler={resetCustomAlertScheduler}
                  idAlert={idAlert}
                  isLoading={isLoading}
                />
              ) : (
                !disableFields && (
                  <>
                    <CustomButton
                      view="primary"
                      onClick={handleClickOpen}
                      startIcon={<AddIcon />}
                    >
                      {t("Choose.the.checking.frequency")}
                    </CustomButton>
                    <Box
                      component={Grid}
                      item
                      xs={12}
                      mt={1}
                      ml={1}
                      fontSize={10}
                    >
                      <FormHelperText className={classes.validation_error}>
                        {schedulerError}
                      </FormHelperText>
                    </Box>
                  </>
                )
              )}
            </Grid>
            {(open || showDefaultData) && (
              <ScheduleForm
                trigger={trigger}
                classes={classes}
                value={value}
                handleChangeTab={handleChangeTab}
                open={open}
                handleCloseDialog={handleCloseDialog}
                register={register}
                handleChangeEndDate={handleChangeEndDate}
                handleChangeStartDate={handleChangeStartDate}
                endDate={endDate}
                startDate={startDate}
                activeCheck={activeCheck}
                handleSchedulerSwitch={handleSchedulerSwitch}
                disableFields={disableFields}
                showDefaultData={showDefaultData}
                watch={watch}
                handleChangeFrequency={handleChangeFrequency}
                discardSchedulerChanges={discardSchedulerChanges}
                frequency={frequency}
                confirmSaveScheduler={confirmSaveScheduler}
                submittedScheduler={submittedScheduler}
                useInterval={useInterval}
                handleChangeUseInterval={handleChangeUseInterval}
                fromSelectedDate={fromSelectedDate}
                onFromDateChange={onFromDateChange}
                toSelectedDate={toSelectedDate}
                onToDateChange={onToDateChange}
                errors={errors}
                disableSchedulerInfo={disableSchedulerInfo}
              />
            )}
          </Grid>
        </Card>
        {!disableFields && (
          <Grid item xs={12} className={classes.inputsSection}>
            <DialogActions className={classes.cardActions}>
              <CustomButton variant="contained" size="medium" onClick={handleCancel}>
                {t("alerts.management.button.exit")}
              </CustomButton>
              <CustomButton
                view="primary"
                size="medium"
                type="submit"
                disabled={isValid}
                onClick={verifySubmittedScheduler}
              >
                {idAlert
                  ? t("alerts.management.button.update")
                  : t("alerts.management.button.save")}
              </CustomButton>
            </DialogActions>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}
