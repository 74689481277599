import React, {
 useState, useEffect, useMemo, useRef, useCallback
} from "react";
import "react-big-calendar/lib/css/react-big-calendar.css";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { Add, Settings } from "@material-ui/icons";
import moment from "moment";
import { Calendar, momentLocalizer } from "react-big-calendar";
import { useTranslation, withTranslation } from "react-i18next";
import { getRandomColor, isEnvParamEnabled } from "../../util";
import {
  fetchProcessesExecutionsPlanner,
  fetchProcesses,
  fetchOrchestratorsNamesAndIds,
  fetchPlannerProcessesList,
  updateProcessRiskTime,
  fetchTagsForPlannerFilter,
  fetchPlannerFilterRobots,
} from "../../redux/actions/services";
import { useDispatch, useSelector } from "react-redux";
import CalendarScheduleForm from "./Components/ScheduleForm";
import RiskManagement from "./Components/RiskManagement";
import CustomToolbar from "./Components/CustomToolBar";
import TreeDaysView from "./Components/TreeDaysView";
import {
  getDayGridRowStart,
  getSpanNumber,
  getWeekGridRowStart,
} from "./utils";
import {
  setOrchestrator,
  setFullScreen,
  setRobot,
} from "../../redux/slices/plannerSlice";
import { groupBy, orderBy } from "lodash";
import clsx from "clsx";
import PageHeader from "../../components/PageHeader";
import ClearFilter from "../../components/ClearFilter";
import NoDataMenu from "../../components/NoData/NoDataMenu";
import CustomSelectField from "../../components/FormFields/CustomSelectField";
import CustomAutoComplete from "../../components/FormFields/CustomAutoComplete";
import CustomButton from "../../components/CustomButton";
import PlannerWarnings from "./Components/PlannerWarnings";
import { HasPermission } from "../../components/HasPermission";
import CircularLoader from "components/Loaders/CircularLoader";
import useStyles, { StyledCard } from "./style";
import "./style.css";

const Event = ({
  event, handleModalOpen, children, currentDate = null
}) => {
  const { title } = children.props;
  const classes = useStyles();
  const ref = useRef(null);
  const [isVertical, setIsVertical] = useState(true);

  useEffect(() => {
    if (ref.current) {
      if (ref.current.offsetWidth >= 70) {
        setIsVertical(false);
      }
    }
  }, [ref.current]);

  return (
    <Tooltip
            title={title}
        >
      <StyledCard
              ref={ref}
              gridrowstart={currentDate ? getDayGridRowStart(moment(currentDate).format("dddd, MMMM D yyyy"), event.start) : getWeekGridRowStart(event.start)}
              span={getSpanNumber(event.start, event.end)}
              backgroundcolor={event.hexColor}
              onClick={() => {
                handleModalOpen(event?.id)
              }}
          >
        <Typography
              className={clsx(isVertical ? classes.vertical : classes.horizontal, classes.eventTooltip)}
          >
          {event?.title}
        </Typography>
      </StyledCard>
    </Tooltip>
  );
};

const CalendarComponent = ({ i18n }) => {
  const classes = useStyles();
  moment.updateLocale(i18n.language, {
    week: {
      dow: 1,
    },
  });
  const localizer = momentLocalizer(moment);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [plannerData, setPlannerData] = useState();
  const [isModalOpen, setModalOpen] = useState(false);
  const [eventId, setEventId] = useState(null);
  const plannerState = useSelector(({ planner }) => planner);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [SchedulesData, setSchedulesData] = useState(null);
  const [robotData, setRobotData] = useState(null);
  const [orchestrator, setOrchastrator] = useState(null);
  const [selectedProcesses, setSelectedProcesses] = useState([]);
  const [selectedOrchestrator, setSelectedOrchestrator] = useState("");
  const [selectedRobot, setSelectedRobot] = useState(null);
  const [openSettings, setSettings] = useState(false);
  const [openWarnings, setWarnings] = useState(false);
  const [warningsData, setWarningsData] = useState([]);
  const [warningsDataForSelectedDate, setWarningsDataForSelectedDate] = useState([]);
  const [warningsCount, setWarningsCount] = useState([]);
  const [selectedDay, setSelectedDay] = useState();
  const [tags, setTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [isPlannerDataLoading, setIsPlannerDataLoading] = useState(false);
  const [currentView, setCurrentView] = useState("week");
  const isRobotConditionActive = selectedRobot || !isEnvParamEnabled("REACT_APP_DISPLAY_RIYAD_BANK_LOGO") || !isEnvParamEnabled("REACT_APP_IS_IVECO_ENV");

  const plannerDTO = useSelector(
      ({ requests }) => requests.queries.FETCH_PROCESS_PLANNER?.data,
  );

  const processPlannerData = useMemo(() => plannerDTO?.processPlannerDtos, [plannerDTO?.processPlannerDtos]);
  const schedulePlannerData = useMemo(() => plannerDTO?.scheduleDtos, [plannerDTO?.scheduleDtos]);

  const isInFullScreenValue = () => (
    (document.fullscreenElement && document.fullscreenElement !== null)
      || (document.webkitFullscreenElement
        && document.webkitFullscreenElement !== null)
      || (document.mozFullScreenElement
        && document.mozFullScreenElement !== null)
      || (document.msFullscreenElement && document.msFullscreenElement !== null)
  );

  const toggleFullScreen = () => {
    const isInFullScreen = isInFullScreenValue();
    const docElm = document.documentElement;
    if (!isInFullScreen) {
      if (docElm.requestFullscreen) {
        docElm.requestFullscreen();
      } else if (docElm.mozRequestFullScreen) {
        docElm.mozRequestFullScreen();
      } else if (docElm.webkitRequestFullScreen) {
        docElm.webkitRequestFullScreen();
      } else if (docElm.msRequestFullscreen) {
        docElm.msRequestFullscreen();
      }
    } else if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
    dispatch(setFullScreen(!plannerState?.isFullScreen));
  };

  const exitHandler = () => {
    if (
      !document.fullscreenElement
      && !document.webkitIsFullScreen
      && !document.mozFullScreen
      && !document.msFullscreenElement
    ) {
      dispatch(setFullScreen(false));
    }
  };

  // adding listenners prefixes for cross-browser codes

  document.addEventListener("fullscreenchange", exitHandler);
  document.addEventListener("webkitfullscreenchange", exitHandler);
  document.addEventListener("mozfullscreenchange", exitHandler);
  document.addEventListener("MSFullscreenChange", exitHandler);

  const processes = useSelector(
    ({ requests }) => requests.queries.FETCH_PLANNER_PROCESSES_LIST?.data,
  );

  const orchestrators = useSelector(
    ({ requests }) => requests.queries.FETCH_ORCHESTRATORS_NAMES_AND_IDS?.data,
  );

  const robots = useSelector(
    ({ requests }) => requests.queries.FETCH_PLANNER_FILTER_ROBOTS?.data?.list,
  );
  const plannerSelectedRobot = useSelector(
    ({ planner }) => planner.selectedRobot,
  );

  const getFilteredRobots = () => {
    if (robotData) {
      if (selectedOrchestrator) return robotData.filter((e) => e.orchestratorId === selectedOrchestrator?.id)
      return robotData
    } return []
  }

  const robotList = getFilteredRobots(robotData)

  useEffect(() => {
    dispatch(fetchPlannerProcessesList()).then(() => {
      dispatch(fetchPlannerFilterRobots())
  })
    if (!orchestrators) dispatch(fetchOrchestratorsNamesAndIds());
  }, []);

  useEffect(() => {
    if (orchestrators) {
      setOrchastrator(
        orchestrators?.map((e) => ({
          id: e?.id,
          title: e?.name,
          sessionNumber: e?.sessionNumber,
        })),
      );
    }
  }, [orchestrators]);

  useEffect(() => {
    if (robots?.length) {
      setRobotData(
        robots?.map((e) => ({
          id: e?.id,
          title: e?.name,
          orchestratorId: e?.orchestratorId,
        })),
      );
    }
  }, [robots]);

  useEffect(() => {
    if (robotList?.length && robotList.length >= 0 && !selectedRobot && (isEnvParamEnabled("REACT_APP_DISPLAY_RIYAD_BANK_LOGO") || isEnvParamEnabled("REACT_APP_IS_IVECO_ENV"))) setSelectedRobot(robotList?.[0])
  }, [robotList, selectedOrchestrator]);

  useEffect(() => {
    if (robotData?.length === 1) {
      dispatch(setRobot(...robotData));
    }
  }, [robotData]);
  useEffect(() => {
      setSelectedRobot(plannerSelectedRobot);
  }, [plannerSelectedRobot]);

  useEffect(() => {
    if (schedulePlannerData) {
      setSchedulesData(
          schedulePlannerData
          .map((e, i) => ({
            ...e,
            hexColor: getRandomColor(i),
          })),
      );
    }
  }, [schedulePlannerData]);

  useEffect(() => {
    if (orchestrator && plannerState?.selectedOrchestrator) {
      handleChangeOrchestrator(
        Object.values(orchestrator)?.find(
          ({ id }) => id === plannerState?.selectedOrchestrator.id,
        ),
      );
    } else if (orchestrator) {
      handleChangeOrchestrator(orchestrator[0]);
    }
  }, [orchestrator]);

  const handleChangeOrchestrator = (value) => {
    reloadPlannerData();
    dispatch(setOrchestrator(value));
    if (value) setSelectedOrchestrator(value);
    if ((isEnvParamEnabled("REACT_APP_DISPLAY_RIYAD_BANK_LOGO") || isEnvParamEnabled("REACT_APP_IS_IVECO_ENV"))) setSelectedRobot(robotList?.[0]);
    setSelectedProcesses([]);
  };

  const handleChangeProcess = (newProcess) => {
    if (newProcess) setSelectedProcesses(newProcess);
  };

  const handleChangeRobot = (newRobot) => {
    if (newRobot) {
      dispatch(setRobot(newRobot));
      setSelectedRobot(newRobot);
      setSelectedProcesses([]);
    }
  };

  const handleClose = () => {
    setEventId(null)
    setModalOpen(false);
  };
  const handleOpenSettings = () => {
    setSettings(true);
  };

  const handleCloseSettings = () => {
    setSettings(false);
  };

  const handleOpenWarnings = (event, date) => {
    setSelectedDay(date);
    event.stopPropagation();
    setWarnings(true);
  };

  const warningsLogic = () => {
    const warningsCount = [];
    const warningsOfWeekDays = [];
    if (plannerData?.length > 0) {
      // eslint-disable-next-line no-plusplus
      for (let j = 0; j < 7; j++) {
        const warningsOffAllTheWeek = [];
        const firstDayOfWeek = moment(currentDate).startOf("week").toDate();
        const iDayOfWeek = moment(firstDayOfWeek).add(j, "days");
        const plannerDataForChosenDate = getPlannerDataForChosenDate(
          plannerData,
          iDayOfWeek,
        );
        if (plannerDataForChosenDate && plannerDataForChosenDate.length > 0) {
          let numberOfWarningForChosenDate = 0;
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < 48; i++) {
            let counter = 0;
            const startTimeInterval = moment(iDayOfWeek).add(i * 0.5, "hours");
            const endTimeInterval = moment(startTimeInterval).add(0.5, "hours");
            const warningObj = {
              startDateRange: startTimeInterval.toDate(),
              endDateRange: endTimeInterval.toDate(),
              planners: [],
              reason: [],
              processes: [],
            };
            const processes = [];
            plannerDataForChosenDate.forEach((planner) => {
              if (
                (planner.start <= startTimeInterval && planner.end >= endTimeInterval)
                || (planner.start < startTimeInterval && planner.end > startTimeInterval && planner.end <= endTimeInterval)
                || (planner.start >= startTimeInterval && planner.start < endTimeInterval)
              ) {
                counter += 1;
                warningObj.planners.push(planner);
                processes.push({
                  robotName: planner.robotName,
                  robotDisplayName: planner.robotDisplayName,
                  processName: planner.processName,
                  // to prioritize the executions of scheduled processes, we assume that a process without restrictions has the highest priority of 100
                  processPriority: planner.priority || 100,
                  processStartDate: planner.start,
                });
              }
            });
            let addWarning = false;
            if (counter > selectedOrchestrator.sessionNumber) {
              addWarning = true;
              warningObj.reason.push(
                t(
                  "allowedSessionsExceededForAnOrchestrator",
                  { parallelExecutions: counter, OrhcestratorName: selectedOrchestrator.title, allowedSessions: selectedOrchestrator.sessionNumber },
                ),
              );
            }
            const groupedExecutionsByRobot = groupBy(processes, "robotDisplayName");
            let robotConcurentExecutionsMessage = "";
            // eslint-disable-next-line guard-for-in, no-restricted-syntax
            for (const key in groupedExecutionsByRobot) {
              if (groupedExecutionsByRobot[key].length > 1) {
                addWarning = true;
                robotConcurentExecutionsMessage = t(
                  "concurrentExecutionInTheSameRobotMessage",
                  {
                    robotName: key,
                    plannerStart: moment(startTimeInterval).format("HH:mm A"),
                    plannerEnd: moment(endTimeInterval).format("HH:mm A"),
                    executionDate: moment(startTimeInterval).format("HH:mm A"),
                  },
                );
                const orderedExecutionsByPriority = orderBy(
                  groupedExecutionsByRobot[key],
                  "processPriority",
                  "asc",
                );
                robotConcurentExecutionsMessage += orderedExecutionsByPriority.map(({ processName }) => processName).join(", ");
                warningObj.reason.push(robotConcurentExecutionsMessage);
              }
              robotConcurentExecutionsMessage = "";
            }
            if (addWarning) {
              numberOfWarningForChosenDate += 1;
              warningObj.processes.push(processes);
              warningsOffAllTheWeek.push(warningObj);
            }
          }
          warningsOfWeekDays.push(warningsOffAllTheWeek);
          warningsCount.push({ iDayOfWeek, numberOfWarningForChosenDate });
        } else {
          warningsOfWeekDays.push(null);
        }
      }
    }
    setWarningsData(warningsOfWeekDays);
    setWarningsCount(warningsCount);
  };

  useEffect(() => {
    warningsLogic();
  }, [plannerData, t]);

  useEffect(() => {
    if (warningsData?.length > 0 && selectedDay) {
      setWarningsDataForSelectedDate(
        warningsData[
          moment(selectedDay).day() - 1 !== -1
            ? moment(selectedDay).day() - 1
            : 6
        ],
      );
    }
  }, [selectedDay]);

  const getPlannerDataForChosenDate = (plannerData, selectedDay) => plannerData.filter(
    (planner) => planner.start >= selectedDay
      && planner.end <= moment(selectedDay).add(1, "days"),
  );

  const handleCloseWarnings = () => {
    setWarnings(false);
  };
  const handleOpen = () => {
    setModalOpen(true);
  };

  const reloadPlannerData = (date, isViewSwitch) => {
    if (isRobotConditionActive && selectedOrchestrator) {
      if (!isViewSwitch) setIsPlannerDataLoading(true);
      let processId = [];
      if (selectedProcesses) processId = selectedProcesses.map((e) => e?.id);
      dispatch(
          fetchProcessesExecutionsPlanner(
              moment(date || currentDate).format("yyyy-MM-DD"),
              selectedOrchestrator?.id,
              processId,
              selectedRobot?.id,
              selectedTags
          ),
      ).then(() => setIsPlannerDataLoading(false))
    }
  };
  useEffect(() => {
    if (selectedOrchestrator?.id && isRobotConditionActive) reloadPlannerData();
  }, [selectedOrchestrator]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (selectedProcesses && isRobotConditionActive) reloadPlannerData();
  }, [selectedProcesses]);

  useEffect(() => {
    if (selectedRobot) reloadPlannerData();
  }, [selectedRobot]);

  useEffect(() => {
    if (selectedTags && isRobotConditionActive) reloadPlannerData();
  }, [selectedTags]);

  const refreshData = useCallback(
    (date) => {
      if (Math.abs(moment(date).startOf("week").diff(moment(currentDate).startOf("week").toDate(), "days")) >= 7
        || Math.abs(moment(date).startOf("week").diff(moment(currentDate).endOf("week").toDate(), "days")) > 0) {
        reloadPlannerData(date, true);
        setCurrentDate(date);
      }
    },
    [selectedOrchestrator, selectedRobot, selectedProcesses, currentDate],
  ); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (processPlannerData) {
      const data = [];
      processPlannerData.forEach((e) => {
        // Split times if start date and end date not in the same day
        if (moment(e.start).toDate().getDate() !== moment(e.end).toDate().getDate()) {
          const startPlanner = { ...e };
          data.push({
            ...startPlanner,
            start: moment(e.start).toDate(),
            end: new Date(moment(e.start).endOf("day").toDate()),
            originalStart: startPlanner.originalStartDate
              ? new Date(startPlanner.originalStartDate)
              : null,
            originalEnd: startPlanner.originalEndDate ? new Date(startPlanner.originalEndDate) : null,
          });
          const endPlanner = { ...e };
          data.push({
            ...endPlanner,
            start: new Date(moment(e.end).startOf("day").toDate()),
            end: moment(e.end).toDate(),
            originalStart: endPlanner.originalStartDate
              ? new Date(endPlanner.originalStartDate)
              : null,
            originalEnd: endPlanner.originalEndDate ? new Date(endPlanner.originalEndDate) : null,
          });
        } else {
          data.push({
            ...e,
            start: new Date(e.start),
            end: new Date(e.end),
            originalStart: e.originalStartDate
                ? new Date(e.originalStartDate)
                : null,
            originalEnd: e.originalEndDate ? new Date(e.originalEndDate) : null,
          });
        }
      });

      const displayedData = SchedulesData
        ? data?.map((e) => ({
          ...e,
          hexColor: Object.values(SchedulesData)
            .filter((f) => f?.scheduleName === e?.title)
            .map((color) => color.hexColor),
        }))
        : [];
      setPlannerData(displayedData);
    }
  }, [processPlannerData, SchedulesData]);

  // eslint-disable-next-line no-nested-ternary
  const getFilteredProcesses = (processesData) => (processesData
      ? selectedOrchestrator
          ? processesData.filter((e) => (selectedRobot
              ? e.orchestratorId === selectedOrchestrator?.id
              && e?.robots?.map(({ id }) => id)?.includes(selectedRobot.id)
              : e.orchestratorId === selectedOrchestrator?.id))
          : processesData
      : []);

  // eslint-disable-next-line no-nested-ternary

  const handleClear = () => {
    setSelectedProcesses([]);
  };

  const customHeader = ({ date, culture, localizer }) => (
    <span className={classes.schedulerTitles}>
      {`${t(localizer.format(date, "dddd", culture))} 
        ${localizer.format(date, "DD")} 
      `}
    </span>
  );
  useEffect(() => {
    dispatch(fetchTagsForPlannerFilter()).then((res) => {
      setTags(res.data);
    });
  }, [])
  const handleChangeTagsFilter = (event) => {
      setSelectedTags(event?.map(({ id }) => id))
      reloadPlannerData()
  };

  const handleModalOpen = (editId) => {
    setEventId(editId)
    setModalOpen(true)
  }

  return (
    <>
      <Grid container item xs={12} spacing={1} className={classes.mainContainer}>
        <PageHeader title="Planner" />
        <Grid container item xs={2} className={clsx(classes.filtersContainers)}>
          <CustomSelectField
              options={orchestrator || []}
              optionLabel="title"
              value={selectedOrchestrator}
              onChange={(event) => handleChangeOrchestrator(event.target.value)}
              label={t("orchestrator")}
              formControlClassName={classes.filtersFormControl}
              additionalMenuNode={(!orchestrator || orchestrator.length === 0) && (
              <Box paddingX={1}><NoDataMenu message={t("no.orchestrator.message")} /></Box>
              )}
              isCustom
          />
        </Grid>
        <Grid container item xs={2} className={classes.filtersContainers}>
          <FormControl fullWidth className={classes.filtersFormControl}>
            <CustomAutoComplete
              options={robotList ?? []}
              optionLabel="title"
              value={selectedRobot}
              noOptionsNode={<NoDataMenu message={t("no.robot.message")} />}
              onChange={handleChangeRobot}
              label={t("robot")}
              disableCloseOnSelect={false}
              translateLabel={false}
          />
          </FormControl>
        </Grid>
        <Grid container item xs={2} className={classes.filtersContainers}>
          <FormControl fullWidth className={classes.filtersFormControl}>
            <CustomAutoComplete
                multiple
                options={tags || []}
                optionLabel="name"
                value={tags?.filter(({ id }) => selectedTags.includes(id))}
                noOptionsNode={<NoDataMenu message={t("no.tags.message")} />}
                onChange={(event) => handleChangeTagsFilter(event)}
                label={t("Tags")}
            />
          </FormControl>
        </Grid>
        <Grid container item xs={2} className={classes.filtersContainers}>
          <FormControl fullWidth className={classes.filtersFormControl}>
            <CustomAutoComplete
                multiple
                options={getFilteredProcesses(processes) ?? []}
                optionLabel="processDisplayName"
                value={selectedProcesses}
                noOptionsNode={<NoDataMenu message={t("no.process.planned")} />}
                onChange={handleChangeProcess}
                label={t("process")}
            />
          </FormControl>
        </Grid>
        <Grid container item xs={4} justify="space-between">
          <Grid
              container
              item
              xs={2}
              alignItems="flex-end"
              className={classes.clearFilterContainer}
          >
            <ClearFilter
                clearFilter={handleClear}
                size="24px"
                label={t("reporting.clear")}
            />
          </Grid>
          <Grid
              container
              item
              xs={10}
              alignItems="flex-end"
              justify="flex-end"
          >
            <HasPermission name="Risk management">
              <CustomButton
                  color="secondary"
                  className={classes.btnBasicMargin}
                  title={t("Risk management")}
                  onClick={handleOpenSettings}
              >
                <Settings />
              </CustomButton>
            </HasPermission>
            <CustomButton
                view="primary"
                className={classes.buttonAddScheduler}
                startIcon={<Add />}
                onClick={handleOpen}
            >
              {t("create.scheduler")}
            </CustomButton>
            <CustomButton
                color="secondary"
                className={classes.btnBasicMargin}
                title={
                  plannerState?.isFullScreen
                      ? t("planner.fullScreenExit")
                      : t("planner.fullScreen")
                }
                onClick={toggleFullScreen}
            >
              {plannerState?.isFullScreen ? (
                <FullscreenExitIcon />
              ) : (
                <FullscreenIcon />
              )}
            </CustomButton>
          </Grid>
        </Grid>
      </Grid>
      {isPlannerDataLoading ? (
        <CircularLoader height={450} />
        )
        : (
          <Paper style={{ padding: "20px" }}>
            <Calendar
                className={
                  plannerState?.isFullScreen
                      ? classes.calendarFullScreen
                      : classes.calendar
                }
                localizer={localizer}
                components={{
                  toolbar: (props) => <CustomToolbar {...props} handleOpenWarnings={handleOpenWarnings} warningsCount={warningsCount} />,
                  header: (props) => customHeader(props),
                  day: {
                    eventWrapper: (props) => Event({ ...props, handleModalOpen, currentDate }),
                  },
                  work_week: {
                    eventWrapper: (props) => Event({ ...props, handleModalOpen }),
                  },
                  week: {
                    eventWrapper: (props) => Event({ ...props, handleModalOpen }),
                  },
                }}
                events={plannerData}
                defaultView={currentView}
                defaultDate={new Date()}
                startAccessor="start"
                endAccessor="end"
                selectable
                views={{
                  day: true,
                  week: true,
                  work_week: TreeDaysView,
                }}
                step={15}
                onNavigate={(date) => refreshData(date)}
                onView={(view) => setCurrentView(view)}
            />
          </Paper>
        )}

      {isModalOpen
        && <CalendarScheduleForm
        scheduleId={eventId}
      editMode={!!eventId}
      openStart={isModalOpen}
      handleClose={handleClose}
      setModalOpen={setModalOpen}
      reloadPlannerData={reloadPlannerData}
    />}
      <RiskManagement
        t={t}
        processes={SchedulesData}
        openStart={openSettings}
        handleClose={handleCloseSettings}
        dispatch={dispatch}
        updateProcessRiskTime={updateProcessRiskTime}
        reloadPlannerData={reloadPlannerData}
        fetchProcesses={fetchProcesses}
      />
      {selectedDay && (
        <PlannerWarnings
          openStart={openWarnings}
          handleClose={handleCloseWarnings}
          t={t}
          warnings={warningsDataForSelectedDate}
          selectedDay={selectedDay}
          orchestrator={selectedOrchestrator}
        />
      )}
    </>
  );
};

export default withTranslation()(CalendarComponent);
