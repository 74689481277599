import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({

  closeButton: {
    color: theme.custom.color.chartColor3
  },
  paper: {
    height: 485,
    width: "100%"
  },
  tabs: {
    backgroundColor: theme.palette.secondary.main,
  },
  editIcon: {
    padding: 5,
    backgroundColor: theme.palette.primary.main,
    color: "white",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: "white",
    }

  },
  stepper: {
    "& .MuiStepIcon-active": {
      color: "#27ae60 !important",
    },
    "& .MuiStepIcon-completed": {
      color: "#27ae60",
    },
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(4),
  },
  editIconBtn: {
    marginBottom: theme.spacing(1),
  },
  prevSaveBtns: {
    marginTop: "20px"
  },
  icon: {
    width: "50px",
    height: "50px",
    borderRadius: "50%",
  },
  inputsSection: {
    padding: theme.spacing(1, 2),
  },
  formRow: {
    marginTop: "20px",
    height: "300px"
  },
  DialogContent: {
    marging: "0px",
    padding: "0px"
  },
  tabPanleGitem: {
    margin: "5px"
  },
  avatar: {
    height: 60,
    width: 60
  },
  validation_error: {
    color: "red",
    margin: "0",
  },
}));
