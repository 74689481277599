import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import { get } from "lodash";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchSrQueues,
  deleteSrQueue,
  deleteSrQueues,
  resetSrQueues,
  enableOrDisableSrQueue,
  handlePreDeleteSRQueue,
  fetchAssignedProcessesByQueue
} from "../../../redux/actions/services/index";
import { updateFilter } from "redux/slices/adminFilterSlice.js";

import { triggerQueueAction, updateQueueAction } from "../../../redux/slices/executionManagerSlice";
import { FETCH_SR_QUEUES } from "../../../redux/constants/index";
import { formatDateByLanguage } from "../../../util";
import {
  ASK_DELETE_QUEUE,
  CONFIRM_DELETE_QUEUE,
  ASK_ENABLE_QUEUE,
  QUEUE_ASSIGN_PROCESS_ACTION,
  ASK_DISABLE_QUEUE,
  QUEUE_UNASSIGN_PROCESS_ACTION,
  CONFIRM_ENABLE_QUEUE,
  CONFIRM_DISABLE_QUEUE,
  ASK_DELETE_ONE_QUEUE,
  ASK_DELETE_QUEUE_FROM_ONE,
} from "util/constants";
import { isFleetAdministrator } from "util";
import PageHeader from "../../../components/PageHeader";
import ExecutionManager from "../../ExecutionManager";
import { isPermitted } from "../../../components/HasPermission";
import ConfirmMessage from "../../../components/ConfirmMessage";
import QueueTable from "./QueueTable";
import { ProcessAssignDialog } from "./ProcessAssignDialog/index"
import AdminTableFilter from "../AdminTable/AdminTableFilter";
import useStyles from "./style.js";
import StatusLegend from "../../../components/StatusLegend";

const headerCells = {
  fields: [
    {
      id: "status",
      gridSize: 2,
      align: "center",
      label: "queue.management.status",
    },
    {
      id: "name",
      gridSize: 2,
      align: "center",
      label: "queue.management.name",
    },
    {
      id: "creationDate",
      gridSize: 3,
      align: "center",
      label: "queue.management.creation-date",
    },
    {
      id: "activeSince",
      gridSize: 3,
      align: "center",
      label: "queue.management.active-since",
    },
    {
      id: "nbLicences",
      gridSize: 2,
      align: "center",
      label: "queue.management.nb-licences",
    }
  ],
};

const displayFields = [
  {
    id: "status",
    gridSize: 2,
    align: "center",
    valuePath: "status",
    isStatus: true,
    translate: false,
  },
  {
    id: "name",
    gridSize: 2,
    align: "center",
    valuePath: "name",
  },
  {
    id: "creationDate",
    gridSize: 3,
    align: "center",
    valuePath: "createdAt",
    translate: false,
    format: (v) => formatDateByLanguage(v)
  },
  {
    id: "activeSince",
    gridSize: 3,
    align: "center",
    valuePath: "activeSince",
    translate: false,
    format: (v) => formatDateByLanguage(v)
  },
  {
    id: "nbLicences",
    gridSize: 2,
    align: "center",
    valuePath: "nbLicences",
  }
];

const statusList = [
  { code: "ACTIVE", label: "ACTIVE" },
  { code: "DISABLED", label: "DISABLED" },
];

const sortByList = [
  { code: "name", label: "queue.management.name" },
  { code: "createdAt", label: "queue.management.creation-date" },
  { code: "activeSince", label: "queue.management.active-since" },
];

export default function QueueManagement() {
  const currentUser = useSelector(({ requests }) => get(requests, "queries.FETCH_CURRENT_USER.data"));
  const entityBaseUrl = "/robotAdministration/queues";
  const dispatch = useDispatch();
  const enableDisableEntity = enableOrDisableSrQueue;
  const fetchEntities = fetchSrQueues;
  const deleteEntity = deleteSrQueue;
  const deleteEntities = deleteSrQueues;
  const preDeleteEntity = handlePreDeleteSRQueue;
  const entityQuery = FETCH_SR_QUEUES;
  const searchField = "searchCriteria";
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const [openMsgConfirm, setOpenMsgConfirm] = useState(false);
  const [dialogMsg, setDialogMsg] = useState("");
  const [queueId, setQueueId] = useState(null);
  const [openProcessAssignDialog, setOpenProcessAssignDialog] = useState(false);
  const [assignedProcesses, setAssignedProcesses] = useState([]);
  const [dialogMsg2, setDialogMsg2] = useState("");
  const [hasSchedules, setHasSchedules] = useState(false);
  const [confirmAction, setConfirmAction] = useState(false);
  const filter = useSelector(({ adminFilter }) => adminFilter);
  const statues = ["ACTIVE", "DISABLE"]

  const handleChangeStatus = (event) => {
    dispatch(
      updateFilter({
        status: event?.map(({ code }) => code),
        pageNo: 0,
      })
    );
  };
  const handleChangeOrchestrator = (value) => {
    dispatch(
      updateFilter({
        orchestrator: value,
        pageNo: 0,
      })
    );
  };
  const handleChangeFleet = (values) => {
    dispatch(
      updateFilter({
        fleets: values,
        pageNo: 0,
      })
    );
  };
  const handleChangeDivisions = (values) => {
    dispatch(
      updateFilter({
        divisions: values.map(({ id }) => id),
        pageNo: 0,
      })
    );
  };
  const handleChangeSortBy = (value) => {
    dispatch(
      updateFilter({
        sortBy: value?.code,
        pageNo: 0,
      })
    );
  };
  const handleFilterValueChange = (value) => {
    dispatch(
      updateFilter({
        pageNo: 0,
        searchCriteria: value,
      })
    );
  };
  const { queueAction, entityId } = useSelector(({ executionManager }) => executionManager);

  const labels = {
    title: "queue.management.title",
    addButton: "queue.management.new",
    deleteEntity: "queue.management.delete.confirm-msg",
    deleteEntities: "queue.management.bulk-delete.confirm-msg",
    enableEntity: "queue.management.enable.confirm-msg",
    enableConfirm: "queue.management.enable.confirm",
    disableEntity: "queue.management.disable.confirm-msg",
    disableConfirm: "queue.management.disable.confirm",
    searchPlaceHolder: "queue.management.search",
    confirmAction: "Delete",
    entityDeleted: "queue deleted successfully"
  };

  const askingForQueueAction = (actionKey) => [ASK_DELETE_QUEUE, ASK_DELETE_ONE_QUEUE, ASK_ENABLE_QUEUE, ASK_DISABLE_QUEUE, ASK_DELETE_QUEUE_FROM_ONE].includes(
      actionKey
    );

  // eslint-disable-next-line consistent-return
  const actionMessageKey = (actionKey) => {
    if (actionKey === ASK_ENABLE_QUEUE) {
      return QUEUE_ASSIGN_PROCESS_ACTION;
    } if (
        actionKey === ASK_DELETE_QUEUE
        || actionKey === ASK_DISABLE_QUEUE
        || actionKey === ASK_DELETE_ONE_QUEUE
        || actionKey === ASK_DELETE_QUEUE_FROM_ONE
    ) {
      return QUEUE_UNASSIGN_PROCESS_ACTION;
    }
  };
  // eslint-disable-next-line consistent-return
  const getFeedBackAction = (askingAction) => {
    switch (askingAction) {
      case ASK_DELETE_QUEUE:
        return CONFIRM_DELETE_QUEUE;
      case ASK_DELETE_QUEUE_FROM_ONE:
        return CONFIRM_DELETE_QUEUE;
      case ASK_ENABLE_QUEUE:
        return CONFIRM_ENABLE_QUEUE;
      case ASK_DISABLE_QUEUE:
        return CONFIRM_DISABLE_QUEUE;
      default:
        break;
    }
  };

  useEffect(() => {
    if (
      queueAction && askingForQueueAction(queueAction)
      && entityId
    ) {
      dispatch(fetchAssignedProcessesByQueue(entityId, onSuccessGetAssignedByQueue));
    }
  }, [queueAction]);

  const onSuccessGetAssignedByQueue = (assignedProcessesRes) => {
    const assignedProcesses = assignedProcessesRes?.data ? assignedProcessesRes?.data : [];
    const isQueueHasProcesses = assignedProcesses?.length > 0;
    const isQueueHasSchedule = [];
    // eslint-disable-next-line no-unsafe-optional-chaining
    isQueueHasSchedule.push(...assignedProcesses?.filter(((proc) => proc.countSchedules > 0)));

    setHasSchedules(isQueueHasSchedule.length > 0);
    const nbSchedules = assignedProcesses.reduce(
      (accumulator, currentValue) => accumulator + currentValue.countSchedules,
      0
    );
    setAssignedProcesses(assignedProcesses)
    if (!isQueueHasProcesses) {
      if (queueAction === ASK_DELETE_QUEUE_FROM_ONE) {
        dispatch(updateQueueAction({ queueAction: getFeedBackAction(queueAction) }));
      } else {
        // Si le résultat est vide, donc le queue n'a pas de process
        /** - Donc confirmer la suppression ou desactivation sans aucun feedback ok ! */
        dispatch(
          triggerQueueAction({
            queueAction: getFeedBackAction(queueAction),
            id: entityId,
          })
        );
      }
    } else if (isQueueHasSchedule.length >= 0) {
      // Le queue dont il a des process assigné, mais ces dérnières n'ont pas de schedules
      /** Donc - Préparer un message pour (Confirmer Ou Annuler la délagation dans le cas SUPPRIMER | REACTIVER | DESACTIVER) */
      setOpenProcessAssignDialog(true);
      setDialogMsg(t(`queue.management.${actionMessageKey(queueAction)}.process.part1`, { this_process: t("thisProcess_plural") }));
      setDialogMsg2(nbSchedules === 0 ? null
        : t(`queue.management.${actionMessageKey(queueAction)}.process.part2`, {
          count: nbSchedules,
          this_process: t("thisProcess_plural"),
          at_its: t("atIts_plural"),
          nbSchedules: t("some")
        }));
    }
  }

  const resetEntity = (id) => {
    setQueueId(id);
    setOpenMsgConfirm(true);
  }

  const confirmReset = () => {
    dispatch(resetSrQueues(queueId)).then((res) => {
      if (res?.status === 200) {
        setDialogMsg(t("queue reseted successfully"));
        toast.success(t("queue reseted successfully"))
      } else {
        setDialogMsg(t("error while reseting queue"));
        toast.error(t("error while reseting queue"))
      }
      setOpenMsgConfirm(false);
    });
  };

  const cancelConfirm = () => {
    setOpenMsgConfirm(false);
  };

  return (
    <>
      <div className={classes.root}>
        <div className={classes.paper}>
          <PageHeader title={labels.title} />
          <Grid container>
            <Grid container item xs justify="center">
              <StatusLegend statues={statues} />
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="flex-end"
            className={classes.filter}
          >
            <Grid item xs={10}>
              <AdminTableFilter
                statusList={statusList}
                sortByList={sortByList}
                pageSize={filter.pageSize}
                fetchEntities={fetchEntities}
                className={classes.userFilter}
                searchLabel={labels.searchPlaceHolder}
                searchField={searchField}
                handleChangeStatus={handleChangeStatus}
                handleChangeOrchestrator={handleChangeOrchestrator}
                handleFilterValueChange={handleFilterValueChange}
                handleChangeSortBy={handleChangeSortBy}
                handleChangeFleets={handleChangeFleet}
                handleChangeDivisions={handleChangeDivisions}
              />
            </Grid>
            <Grid item container xs={2} justify="flex-end">
              {(isPermitted(currentUser, "Add SR Queue")
                || isFleetAdministrator(currentUser)) && (
                <Button
                  type="submit"
                  color="secondary"
                  variant="contained"
                  onClick={() => {
                    history.push(`${entityBaseUrl}/add`);
                  }}
                >
                  <AddIcon />
                    {" "}
                    {t(labels.addButton)}
                </Button>
              )}
            </Grid>
          </Grid>
          <QueueTable
            enableDeleteMultiple={isPermitted(currentUser, "Delete SR Queue") || isFleetAdministrator(currentUser)}
            showStatusSwitch={isPermitted(currentUser, "Edit SR Queue") || isFleetAdministrator(currentUser)}
            enableEdit={isPermitted(currentUser, "Edit SR Queue") || isFleetAdministrator(currentUser)}
            enableDelete={isPermitted(currentUser, "Delete SR Queue") || isFleetAdministrator(currentUser)}
            statusList={statusList}
            sortByList={sortByList}
            displayFields={displayFields}
            headerCells={headerCells}
            entityBaseUrl={entityBaseUrl}
            enableDisableEntity={enableDisableEntity}
            resetEntity={resetEntity}
            fetchEntities={fetchEntities}
            deleteEntity={deleteEntity}
            deleteEntities={deleteEntities}
            entityQuery={entityQuery}
            labels={labels}
            searchField={searchField}
            preDeleteEntity={preDeleteEntity}
            entityDeleted={labels.entityDeleted}
          />
        </div>
      </div>

      <ExecutionManager />

      <ProcessAssignDialog
        assignedProcesses={assignedProcesses}
        classes={classes}
        dialogMsg={dialogMsg}
        dialogMsg2={dialogMsg2}
        hasSchedules={hasSchedules}
        setAssignedProcesses={setAssignedProcesses}
        openProcessAssignDialog={openProcessAssignDialog}
        setOpenProcessAssignDialog={setOpenProcessAssignDialog}
        confirmAction={confirmAction}
        setConfirmAction={setConfirmAction}
      />

      <ConfirmMessage
        message={t("queue.management.reset.confirm-msg")}
        openStart={openMsgConfirm}
        onCancel={cancelConfirm}
        onConfirm={confirmReset}
        buttonConfirm={t("Reset")}
        buttonCancel={t("Cancel")}
        isDelete="true"
      />
    </>
  );
}
