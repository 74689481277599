import {
    CREATE_DEFAULT_ALERT,
    DELETE_DEFAULT_ALERT_BY_ID,
    FETCH_DEFAULT_ALERT,
    FETCH_DEFAULT_ALERTS,
    UPDATE_DEFAULT_ALERT,
    UPDATE_DEFAULT_ALERT_EMAIL_STATUS,
    UPDATE_DEFAULT_ALERT_SMS_STATUS
} from "../../../constants";
import { URL_DEFAULT_ALERT } from "../../../constants/endpoints";

export const fetchDefaultAlerts = (filter = {}) => {
    const {
        searchCriteria, pageNo, pageSize, status, types, fleets, divisions
    } = filter;
    return {
        type: FETCH_DEFAULT_ALERTS,
        request: {
            url: encodeURI(`${URL_DEFAULT_ALERT}${searchCriteria ? `?searchCriteria=${searchCriteria}` : ""}`),
            params: {
                pageNo,
                pageSize,
                status: status ? status.join(",") : null,
                types: types ? types.join(",") : null,
                fleetsIds: fleets ? fleets?.join(",") : null,
                divisionsIds: divisions ? divisions?.join(",") : null,
            },
        },
    };
};
export const deleteDefaultAlert = (id, onSuccess, onError) => ({
    type: DELETE_DEFAULT_ALERT_BY_ID,
    request: {
        url: `${URL_DEFAULT_ALERT}${id}`,
        method: "delete",
    },
    meta: {
        onSuccess: (response) => {
            if (onSuccess) onSuccess();
            return response;
        },
        onError: (error) => {
            if (onError) onError();
            return error;
        },
    },
});
export const enableOrDisableEmailForDefaultAlert = (id, emailStatus) => ({
    type: UPDATE_DEFAULT_ALERT_EMAIL_STATUS,
    request: {
        url: `${URL_DEFAULT_ALERT}email/${id}/${emailStatus ? "disable" : "enable"
        }`,
        method: "put",
        headers: { "Content-Type": "application/json" },
    },
});
export const updateDefaultAlert = (
    defaultAlertId,
    defaultAlert,
    onSuccess,
    onError,
) => ({
    type: UPDATE_DEFAULT_ALERT,
    request: {
        url: `${URL_DEFAULT_ALERT}${defaultAlertId}`,
        method: "put",
        data: JSON.stringify(defaultAlert),
        headers: { "Content-Type": "application/json" },
    },
    meta: {
        onSuccess: (response) => {
            if (onSuccess) onSuccess(response);
            return response;
        },
        onError: (error) => {
            if (onError) onError(error);
            return error;
        },
    },
});
export const fetchDefaultAlert = (id) => ({
    type: `${FETCH_DEFAULT_ALERT}`,
    request: {
        url: `${URL_DEFAULT_ALERT}${id}`,
    },
});
export const createDefaultAlert = (defaultAlert, onSuccess, onError) => ({
    type: CREATE_DEFAULT_ALERT,
    request: {
        url: `${URL_DEFAULT_ALERT}`,
        method: "post",
        data: JSON.stringify(defaultAlert),
        headers: { "Content-Type": "application/json" },
    },
    meta: {
        onSuccess: (response) => {
            if (onSuccess) onSuccess(response);
            return response;
        },
        onError: (error) => {
            if (onError) onError(error);
            return error;
        },
    },
});
export const enableOrDisableSmsForDefaultAlert = (id, smsStatus) => ({
    type: UPDATE_DEFAULT_ALERT_SMS_STATUS,
    request: {
        url: `${URL_DEFAULT_ALERT}sms/${id}/${smsStatus ? "disable" : "enable"}`,
        method: "put",
        headers: { "Content-Type": "application/json" },
    },
});
