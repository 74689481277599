import React from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { useTranslation } from "react-i18next";
import CustomDialog from "pages/Services/components/CustomDialog";
import CustomButton from "../../../components/CustomButton";

export default function DeleteItem({
  selectedItem,
  handleDeleteConfirm,
  openDialog,
  setOpenDialog
}) {
  const { t } = useTranslation();
  const Delete = "Delete";
  const Cancel = "Cancel";

  return (
    <div>
      {selectedItem && (
        <CustomDialog open={openDialog} onClose={() => setOpenDialog(false)}>
          <DialogTitle>
            {t("Delete Item")}
            {" "}
            {selectedItem && selectedItem.type === "exclusive"
              ? `"${selectedItem.name}"`
              : `"${selectedItem.costAllocations.name}"`}
          </DialogTitle>
          <DialogContent>
            {selectedItem && selectedItem.type === "exclusive"
              ? t("Are you sure you want to delete this cost ?")
              : t("This cost is shared with multiple processes, if you would like to remove the cost allocated to this process individually, please edit the cost and remove the processes from the Sharing paramteres. If you are sure you want to delete this cost from all processes, proceed with the delete.")}
          </DialogContent>
          <DialogActions>
            <CustomButton variant="outlined" onClick={() => setOpenDialog(false)}>{t(Cancel)}</CustomButton>
            <CustomButton
                  view="primary"
                  onClick={handleDeleteConfirm}>
              {t(Delete)}
            </CustomButton>
          </DialogActions>
        </CustomDialog>
      )}
    </div>
  );
}
