import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Badge from "@material-ui/core/Badge";
import Box from "@material-ui/core/Box";
import { useTranslation } from "react-i18next";
import useStyles from "./style";

function StatusLegend(props) {
  const { statues } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const badgeColor = (level) => {
    switch (level) {
      case "WORKING":
      case "ACTIVE":
        return classes.green;
      case "IDLE":
        return classes.blue;
      case "WAITING":
        return classes.orange;
      case "DISCONNECTED":
      case "OFFLINE":
      case "DISABLE":
        return classes.red;
      default:
        return classes.red;
    }
  };

  const CustomBadge = ({
 label, level, badgeColor, classes
}) => (
  <Grid
      container
      item
      alignItems="center"
      justify="flex-start"
      className={classes.badgeContainer}
    >
    <Grid item justify="center">
      <Box
          alignItems="center"
          paddingX={1}
          marginRight={2}
          display="flex"
          flexDirection="column"
        >
        <Badge
            overlap="circle"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            variant="dot"
            classes={{ badge: badgeColor(level), dot: classes.dot }}
          />
      </Box>
    </Grid>
    <Grid item>
      <Typography component="span" variant="body2" color="textSecondary">
        {label}
      </Typography>
    </Grid>
  </Grid>
  );

  return (
    <Grid container item alignItems="center" justify="flex-start">
      {statues.map((statue, index) => (
        <>
          <Grid item key={index}>
            <CustomBadge
              label={t(statue)}
              level={statue}
              badgeColor={badgeColor}
              classes={classes}
            />
          </Grid>
          {index < statues.length - 1 && (
            <Grid item xs={1} className={classes.separator} key={index}>
              |
            </Grid>
          )}
        </>
      ))}
    </Grid>
  );
}

export default StatusLegend;
