import
{
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
} from "@material-ui/core";
import React from "react";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import { useTranslation } from "react-i18next";
import { Controller, useWatch } from "react-hook-form";
import { isSRQueuesDisabled } from "../../../../../../util/constants/PermitedFeature";

export default function DefaultTriggerRow({
  classes,
  errors,
  disableFields,
  menuItems,
  control,
  processMenuItem,
}) {
  const { t } = useTranslation();
  const watch = useWatch({ control });
  const ofArr = ["A license is one month away from expiry",
      ...!isSRQueuesDisabled ? ["execution is deleted", "execution is missed", "deleted by sr policy"] : [],
      "A license is two months away from expiry"]
  return (
    <>
      <Grid item xs={1} className={classes.triggerGridIcon}>
        <span className={classes.triggerIcon}>
          <NotificationsActiveIcon />
        </span>
      </Grid>
      <Grid item xs={5}>
        <Controller
          name="when"
          control={control}
          rules={{
            required: {
              value: true,
              message: t("alerts.management.required.fields"),
            },
          }}
          defaultValue=""
          render={({ field }) => (
            <FormControl fullWidth size="small">
              <InputLabel id="when" error={errors?.when}>
                {t("alerts.management.when.placeholder")}
              </InputLabel>
              <Select
                {...field}
                id="when"
                className={classes.select}
                error={errors?.when}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
                disabled={disableFields}
              >
                {menuItems.whenMenuItem.map((item, index) => (
                  <MenuItem key={index} value={item}>
                    <Tooltip title={t(item)}>
                      <span>
                        {" "}
                        {t(item)}
                        {" "}
                      </span>
                    </Tooltip>
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText className={classes.validation_error}>
                {errors?.when?.message}
              </FormHelperText>
            </FormControl>
          )}
        />
      </Grid>
      {ofArr.includes(watch.when) && (
        <Grid item xs={5}>
          <Controller
            name="processId"
            control={control}
            rules={{
              required: {
                value: true,
                message: t("alerts.management.required.fields"),
              },
            }}
            defaultValue=""
            render={({ field }) => (
              <FormControl fullWidth size="small" error={errors?.processId}>
                <InputLabel id="processId">
                  {t("alerts.management.of.placeholder")}
                </InputLabel>
                <Select
                  {...field}
                  id="processId"
                  className={classes.select}
                  error={errors?.processId}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                  }}
                  disabled={disableFields}
                >
                  {processMenuItem.map((item, index) => (
                    <MenuItem key={index} value={item?.id}>
                      <Tooltip
                        title={item?.processDescription?.processDisplayName}
                      >
                        <span>
                          {item?.processDescription?.processDisplayName}
                        </span>
                      </Tooltip>
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText className={classes.validation_error}>
                  {errors?.processId?.message}
                </FormHelperText>
              </FormControl>
            )}
          />
        </Grid>
      )}
    </>
  );
}
