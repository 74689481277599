import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  pageHeaderContainer: {
    padding: theme.spacing(2, 1),
  },
  pageHeader: {
    margin: theme.spacing(0),
    fontWeight: 700
  },
}));
