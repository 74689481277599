import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { useQuery } from "@redux-requests/react";
import { useTranslation } from "react-i18next";
import { Duration } from "util/constants";
import { useDispatch, useSelector } from "react-redux";
import {
     fetchFilterProcesses,
    fetchTagsForFilters,
} from "redux/actions/services";
import { FETCH_FILTER_PROCESSES, FETCH_TAG_FOR_FILTERS } from "redux/constants";
import {
    updateDateFilter,
    updateProcessFilter,
    updateTagsFilter,
} from "redux/slices/dashboardSlice";
import DateFilter from "../../Services/components/DateFilter";
import CustomAutoComplete, {
} from "../../../components/FormFields/CustomAutoComplete";
import NoDataMenu from "../../../components/NoData/NoDataMenu";

export default function DashboardFilter() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const filterDashboard = useSelector(({ dashboardFilters }) => dashboardFilters);
  const userProcesses = useQuery({ type: FETCH_FILTER_PROCESSES })?.data
    ?.list;
  const tags = useQuery({ type: FETCH_TAG_FOR_FILTERS })?.data;
  const handleChangeProcessesFilter = (value) => {
    dispatch(updateProcessFilter(value?.map(({ id }) => id)));
  };

  const handleChangeTagsFilter = (value) => {
    dispatch(updateTagsFilter(value?.map(({ id }) => id)));
  };

  const onSelectDurationValue = (value) => {
    dispatch(
      updateDateFilter(value)
    );
  };

  useEffect(() => {
    dispatch(fetchFilterProcesses());
    dispatch(fetchTagsForFilters());
  }, []);

  return (
    <Grid container style={{ marginLeft: "0.15rem" }} spacing={2}>
      <DateFilter
        selectedDuration={filterDashboard.selectedDurationValue}
        onSelectDurationValue={onSelectDurationValue}
        isCustom
        xs={4}
        DurationList={Duration.reducedList}
      />
      <Grid item xs={4}>
        <CustomAutoComplete
          multiple
          options={tags || []}
          optionLabel="name"
          value={tags?.filter(({ id }) => filterDashboard.tags?.includes(id))}
          noOptionsNode={<NoDataMenu message={t("no.tags.message")} />}
          onChange={handleChangeTagsFilter}
          label={t("Tags")}
        />
      </Grid>
      <Grid item xs={4}>
        <CustomAutoComplete
          multiple
          options={userProcesses || []}
          optionLabel="processName"
          value={userProcesses?.filter(({ id }) => filterDashboard.process?.includes(id))}
          noOptionsNode={<NoDataMenu message={t("no.process.message")} />}
          onChange={handleChangeProcessesFilter}
          label={t("processes")}
        />
      </Grid>
    </Grid>
  );
}
