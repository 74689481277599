import React, { useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Avatar from "@material-ui/core/Avatar";
import Badge from "@material-ui/core/Badge";
import Divider from "@material-ui/core/Divider";
import Popover from "@material-ui/core/Popover";
import CardActionArea from "@material-ui/core/CardActionArea";
import ListItemText from "@material-ui/core/ListItemText";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";
import clsx from "clsx";
import get from "lodash/get";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { fetchExportedFiles, logout } from "../../../../redux/actions/services";

import useStyles from "../style.js";
import riyadbankLogo from "../riyadbank.png";
import logo from "../logo.png";
import logoWhite from "../logo-white.png";
import UserProfile from "../../../../assets/navigationIcons/account.svg";
import TimezonePicker from "../components/TimezonePicker";
import GetAppIcon from "@material-ui/icons/GetApp";
import ExportDialog from "WrapperContainer/components/ExportDialog";
import { FETCH_EXPORTED_FILES } from "redux/constants";
import { useQuery } from "@redux-requests/react";
import { isEnvParamEnabled } from "util";
import LanguageHeader from "../../../../components/Language";
import Box from "@material-ui/core/Box";
import Tooltip from "@material-ui/core/Tooltip";

// eslint-disable-next-line import/no-anonymous-default-export
export default ({
  isSticky, nbrExeptions, dark,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const menuExpanded = useSelector(({ appState }) => appState.menuExpanded);
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const currentUser = useSelector(({ requests }) => get(requests, "queries.FETCH_CURRENT_USER.data"));
  const [highlightExport, setHighlightExport] = useState(false);
  const isCustomLogo = isEnvParamEnabled("REACT_APP_DISPLAY_RIYAD_BANK_LOGO");
  const SRLogo = dark ? logoWhite : logo;
  const getImageURL = (path) => `${process.env.REACT_APP_DOMAIN}api/admin${path}`;
  const getUserInitials = () => {
    const f = get(currentUser, "firstName")
      ? currentUser.firstName.charAt(0).toUpperCase()
      : "";
    const l = get(currentUser, "lastName")
      ? currentUser.lastName.charAt(0).toUpperCase()
      : "";

    return f + l;
  };
  const handleClickOpenExeption = () => {
    history.push("/items/itemsExceptions");
  };

  const alertsColor = (nbrExeptions) => {
    if (nbrExeptions < 11) {
      return classes.alertLow;
    }
    if (nbrExeptions < 20) {
      return classes.alertMeduim;
    }
    return classes.alertHigh;
  };
  const colorExeptions = alertsColor(nbrExeptions);
  const { t } = useTranslation();
  const location = useLocation();
  const [exportOpen, setExportOpen] = React.useState(false);
  const exportedFiles = useQuery({ type: FETCH_EXPORTED_FILES }).data;
  const [numberOfExport, setNumberOfExport] = React.useState(0);
  const onExportedFileOpen = () => {
    setExportOpen(true);
    dispatch(fetchExportedFiles(() => setHighlightExport(false)));
  }

  React.useEffect(() => {
    if (exportedFiles?.length > numberOfExport) {
      setHighlightExport(true);
    }
    setNumberOfExport(exportedFiles?.length);
  }, [exportedFiles?.length]);

  const openProfile = () => {
    history.push("/profile/general");
    setAnchorEl(null);
  }
  return (
    <AppBar
      color="inherit"
      position="sticky"
      className={clsx(
        classes.appBar,
        menuExpanded && classes.appBarShift,
        dark && classes.dark,
        location.pathname === "/404" && classes.withourAppBar,
      )}
      elevation={0}
    >
      <Toolbar className={classes.toolbar}>
        <div
          className={clsx(
            "align-items-center d-inline-flex",
            classes.leftToolbar,
          )}
        >
          {isSticky && (
            <img
                    src={isCustomLogo ? riyadbankLogo : SRLogo}
                    alt="Logo"
                    className={isCustomLogo ? classes.riyadbanklogo : classes.logo}
                />
          )}
        </div>
        <div className="align-items-center d-inline-flex">
          <Box mx={1}>
            <TimezonePicker />
            <LanguageHeader />
          </Box>
          {isSticky && (
            <Divider
              orientation="vertical"
              flexItem
              className={classes.darkDivider}
            />
          )}
          <Box mx={2}>
            <Tooltip title={t("exporting.title")}>
              <IconButton onClick={onExportedFileOpen} className={classes.iconsButtons}>
                <Badge
                    badgeContent={exportedFiles?.length > 0 ? exportedFiles?.length : 0}
                    classes={{ badge: clsx(highlightExport ? classes.badgeStyled : colorExeptions, classes.exceptionBadge) }}
                >
                  <GetAppIcon color="primary" fontSize="small" />
                </Badge>
              </IconButton>
            </Tooltip>

            {isSticky && (
            <>
              <Tooltip title={t("Item Exceptions")}>
                <IconButton
                    onClick={handleClickOpenExeption}
                    className={clsx(classes.iconsButtons, dark ? classes.darkIconBtn : "")}
                >
                  <Badge
                      badgeContent={nbrExeptions}
                      classes={{ badge: clsx(colorExeptions, classes.exceptionBadge) }}
                  >
                    <ReportProblemOutlinedIcon color="primary" fontSize="small" />
                  </Badge>
                </IconButton>
              </Tooltip>
              <Tooltip title={t("Profile")}>
                <IconButton onClick={(e) => setAnchorEl(e.currentTarget)} className={classes.iconsButtons}>
                  <Badge
                      overlap="circle"
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                  >
                    <Avatar
                        alt="Supplier logo"
                        src={
                          get(currentUser, "logo")
                              ? getImageURL(get(currentUser, "logo"))
                              : UserProfile
                        }
                        className={classes.purple}
                    >
                      {getUserInitials()}
                    </Avatar>
                  </Badge>
                </IconButton>
              </Tooltip>
              <Popover
                      id="profile-popover"
                      open={Boolean(anchorEl)}
                      anchorEl={anchorEl}
                      onClose={() => setAnchorEl(null)}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                  >
                <CardActionArea
                        onClick={openProfile}
                    >
                  <ListItemText
                          primary={t("Profile")}
                          className={classes.popupItems}
                      />
                </CardActionArea>
                <CardActionArea onClick={() => dispatch(logout())}>
                  <ListItemText
                          primary={t("Logout")}
                          className={classes.popupItems}
                      />
                </CardActionArea>
              </Popover>
            </>
            )}
          </Box>
        </div>
      </Toolbar>
      <ExportDialog setExportOpen={setExportOpen} exportOpen={exportOpen} />
    </AppBar>
  );
}
