import React from "react";
import { Link } from "react-router-dom";
import { useQuery } from "@redux-requests/react";
import { useTranslation } from "react-i18next";
import { isEnvParamEnabled } from "util/index";
import { isMenuPermitted } from "components/HasPermission";
import { FETCH_CURRENT_USER } from "redux/constants";
import "../../../style/Error.css";

const NotFound = () => {
  const { t } = useTranslation();
  const { data: currentUser } = useQuery({ type: FETCH_CURRENT_USER });
  const dashboardLink = () => {
    if (isEnvParamEnabled("REACT_APP_ENABLE_MONITORING_DASHBOARD") && isMenuPermitted(currentUser, "Monitoring Dashboard")) {
      return "/monitoring-dashboard";
    }
    if (isMenuPermitted(currentUser, "Dashboard")) {
      return "/performance-dashboard";
    }
    return "/";
  };

  return (

    <div className="error_page">
      <p className="text-error">404</p>
      <p className="error-text">{t("Sorry the page you are looking for could not be found")}</p>
      <Link to={dashboardLink()} className="error_link">
        {t("Go to the home page")}
        {" "}
      </Link>
    </div>
  );
};
export default NotFound;
