import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { useParams, useHistory } from "react-router-dom";
import ConfirmMessage from "../../../../components/ConfirmMessage";
import CustomTab, { useIndicatorStyle } from "pages/Services/components/CustomTab";
import Tabs from "@material-ui/core/Tabs";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Stepper from "@material-ui/core/Stepper"
import Step from "@material-ui/core/Step"
import StepLabel from "@material-ui/core/StepLabel"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import Paper from "@material-ui/core/Paper"
import TextField from "@material-ui/core/TextField"
import Box from "@material-ui/core/Box"
import Tooltip from "@material-ui/core/Tooltip"

import MuiDialogActions from "@material-ui/core/DialogActions";
import useStyles from "./style";
import {
  fetchSrQueue,
  createOrUpdateSrQueue,
  fetchOrchestrators,
  fetchNotAssignedProcessesByOrchestrator,
  fetchRobotList,
  getRemainingLicencesForOrchestrator,
  processCountSchedules, fetchQueueRobotPendingExecutionsCount, hasQueuePendingExecutions,
} from "../../../../redux/actions/services/index";
import ProcessSelector from "../../ProcessSelector";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import get from "lodash/get";
import RobotSelector from "../../RobotSelector";
import { merge } from "lodash";
import { Autocomplete } from "@material-ui/lab";
import { FETCH_CURRENT_USER, FETCH_NOT_ASSIGNED_PROCESSES_BY_ORCHESTRATOR, FETCH_ROBOTS } from "../../../../redux/constants";
import { useQuery } from "@redux-requests/react";
import { isPermitted } from "../../../../components/HasPermission";
import Typography from "@material-ui/core/Typography";
import { toast } from "react-toastify";
import CustomDialog from "pages/Services/components/CustomDialog";
import CustomCloseButton from "pages/Services/components/CustomCloseButton";
import CustomButton from "../../../../components/CustomButton";
import {
  handleInputChanges, formatInputOnBlur, formatInputOnFocus, getDefaultFormat, isFleetAdministrator
} from "util";

const QueueForm = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { queueId, mode = "add" } = useParams();
  const orchestrators = useSelector(({ requests }) => get(requests, "queries.FETCH_ORCHESTRATORS.data.content"));

  const entityBaseUrl = "/robotAdministration/queues";
  const { data: processes } = useQuery({ type: FETCH_NOT_ASSIGNED_PROCESSES_BY_ORCHESTRATOR });
  const robots = useQuery({ type: FETCH_ROBOTS })?.data?.content;
  const { data: currentUser } = useQuery({ type: FETCH_CURRENT_USER });
  const [queue, setQueue] = useState(null);
  const [openMsgConfirm, setOpenMsgConfirm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openMsgCancel, setOpenMsgCancel] = useState(false);
  const [availableProcesses, setAvailableProcesses] = useState([]);
  const [assignedProcesses, setAssignedProcesses] = useState([]);
  const [processesAction, setProcessesAction] = useState([]);
  const [availableRobots, setAvailableRobots] = useState([]);
  const [assignedRobots, setAssignedRobots] = useState([]);
  const [dialogMsg, setDialogMsg] = useState("");
  const [dialogMsg2, setDialogMsg2] = useState("");
  const [selectedOrchestrator, setSelectedOrchestrator] = useState(null);
  const [remainingLicences, setRemainingLicences] = useState(0);
  const [openProcessAssignDialog, setOpenProcessAssignDialog] = useState(false);
  const [hasSchedules, setHasSchedules] = useState(false);
  const [selectedProcess, setSelectedProcess] = useState(null);
  const [isLoadingSchedules, setIsLoadingSchedules] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [openPendingExecutionWarning, setOpenPendingExecutionWarning] = useState(false);
  const [processToUnAssign, setProcessToUnAssign] = useState(null);
  const [remainingLicensesLoading, setRemainingLicensesLoading] = useState(false);
  const {
    formState: { errors },
    handleSubmit,
    setValue,
    getValues,
    trigger,
    clearErrors,
    register,
    control,
    formState: { isDirty },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      name: "",
      nbLicences: 1,
      orchestrator: null,
    },
  });

  useEffect(() => {
    dispatch(fetchOrchestrators());
    if (mode !== "add") {
      dispatch(fetchSrQueue(queueId)).then((res) => {
        if (res.status === 200) {
          setValue("name", res.data.name);
          setValue("nbLicences", res.data.nbLicences);
          setQueue(res.data);
        }
      });
    }
  }, [currentUser, queueId, mode]);

  useEffect(() => {
    if (selectedOrchestrator) {
      if (mode === "add" || mode === "edit") {
        setRemainingLicensesLoading(true);
        dispatch(getRemainingLicencesForOrchestrator(selectedOrchestrator.id)).then((res) => {
          if (res.status === 200) {
            // In edit mode, we want to include the current queue's licences in the remaining licences
            const rl = mode === "edit" ? res.data + queue.nbLicences : res.data;
            setRemainingLicences(rl);
            setRemainingLicensesLoading(false);
          }
        })
      }
      dispatch(fetchNotAssignedProcessesByOrchestrator(selectedOrchestrator.id, mode === "add" ? null : queueId));
      dispatch(fetchRobotList({ orchestrator: [selectedOrchestrator] }));
    }
  }, [selectedOrchestrator]);

  useEffect(() => {
    if (orchestrators && queue) {
      setValue("orchestrator", orchestrators.find((o) => o.id === queue.orchestrator.id));
      setSelectedOrchestrator(queue.orchestrator);
    }
  }, [orchestrators, queue]);

  useEffect(() => {
    if (processes) {
      const _availableProcesses = queue ? processes.filter((p) => !queue.processes.find((pq) => pq.id === p.id)) : processes;
      setAvailableProcesses(_availableProcesses.filter((p) => !p.queue));
      setAssignedProcesses(queue ? processes.filter((p) => Boolean(queue.processes.find((pq) => pq.id === p.id))) : []);
    }
  }, [processes, queue]);

  useEffect(() => {
    if (robots) {
      setAvailableRobots(queue ? robots.filter((p) => !queue.robots.find((rq) => rq.id === p.id)) : robots);
      setAssignedRobots(queue ? robots.filter((r) => Boolean(queue.robots.find((rq) => rq.id === r.id))) : []);
    }
  }, [robots, queue]);

  const steps = [
    "queue.management.section.info",
    "queue.management.section.processes",
    "queue.management.section.robots",
  ];

  const disableFields = !(mode === "add" || mode === "edit");

  const handleCancel = () => {
    if (mode === "view" || !isDirty) {
      history.push(entityBaseUrl);
      return;
    }
    setOpenMsgCancel(true);
  };

  const handleBack = () => {
    if (activeStep <= 0) handleCancel();
    setActiveStep((prevActiveStep) => (prevActiveStep === 0 ? 0 : prevActiveStep - 1));
  };

  const handleStepClick = async (step) => {
    if (activeStep !== step && mode === "edit") {
      const result = await trigger();
      if (result) setActiveStep(step);
    }
  };

  const handleSaveClick = async () => {
    const result = await trigger();
    if (result) {
      if (activeStep === steps.length - 1) {
        setOpenMsgConfirm(true);
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    }
  };

  const cancelConfirm = () => {
    setOpenMsgConfirm(false);
    setIsLoading(false);
  };

  const handleAcceptCancelForm = () => {
    setOpenMsgCancel(false);
    history.push(entityBaseUrl);
  };

  const handleRejectCancelForm = () => setOpenMsgCancel(false);
  const confirmSave = () => {
    handleSubmit(onSubmit)();
  };

  const onSubmit = (data) => {
    let dataToSubmit = data;
    if (queue) {
      dataToSubmit = merge(queue, dataToSubmit);
    }
    dataToSubmit.orchestratorId = dataToSubmit.orchestrator.id;
    dataToSubmit.robots = assignedRobots.map((r) => r.id);
    dataToSubmit.processes = assignedProcesses.map((p) => p.id);
    dataToSubmit.processActions = processesAction;
    dataToSubmit.nbLicences = +getDefaultFormat(dataToSubmit.nbLicences)
    setIsLoading(true);
    dispatch(createOrUpdateSrQueue(dataToSubmit)).then((res) => {
      if (res.status === 200) {
        setOpenMsgConfirm(false);
        history.push({
          pathname: entityBaseUrl,
        });
        toast.success(mode === "add" ? t("queue.management.form.save.success") : t("queue.management.form.update.success"))
      } else {
        setOpenMsgConfirm(false);
        setDialogMsg(mode === "add" ? "queue.management.form.save.error" : "queue.management.form.update.error");
        toast.error(mode === "add" ? t("queue.management.form.save.error") : t("queue.management.form.update.error"))
      }
      setIsLoading(false);
    });
  };

  const handleChange = (_, newValue) => {
    setActiveStep(newValue);
  };

  const onAssignProcess = (process) => assignUnassingProcess("assign", process);

  const onUnAssignProcess = (process) => dispatch(hasQueuePendingExecutions(queueId, process.id)).then((res) => {
      if (res.data) {
        setOpenPendingExecutionWarning(true);
        setProcessToUnAssign(process);
        return true;
      }
      return assignUnassingProcess("unassign", process);
    });
  const confirmDeletePendingExecutions = () => {
    setOpenPendingExecutionWarning(false);
    assignUnassingProcess("unassign", processToUnAssign);
  }
  const assignUnassingProcess = (action, process) => {
    setDialogMsg("");
    setSelectedProcess(process);

    // If the process is moved back to its original emplacement - do nothing
    const originalAvailableProcessList = queue ? processes.filter((p) => Boolean(queue.processes.find((pq) => pq.id === p.id))) : [];
    if (originalAvailableProcessList) {
      const originalAssigned = originalAvailableProcessList.filter((p) => p.id === process.id);
      if ((action === "assign" && originalAssigned && originalAssigned.length > 0)
        || (action === "unassign" && (!originalAssigned || originalAssigned.length === 0))) {
        postAssignProcessAction("RESET", process.id);
        return true;
      }
    }
    setIsLoadingSchedules(true);
    return dispatch(processCountSchedules(process.id)).then((res) => {
      if (res.status === 200) {
        if (!res.data.migrationPossible) {
          toast.warn(t("process.migration.not.possible"));
          setIsLoadingSchedules(false);
          return false;
        }
        const nbSchedules = res.data.countSchedules;
        setIsLoadingSchedules(false);
        setOpenProcessAssignDialog(true);
        setHasSchedules(nbSchedules !== 0);
        setDialogMsg(t(`queue.management.${action}.process.part1`, { this_process: t("thisProcess") }));
        setDialogMsg2(nbSchedules === 0 ? null
          : t(`queue.management.${action}.process.part2`, {
            count: nbSchedules,
            this_process: t("thisProcess"),
            at_its: t("atIts"),
            nbSchedules
          }));
        return true;
      }
      return false;
    });
  }
  const onUnAssignRobot = (robot) => dispatch(fetchQueueRobotPendingExecutionsCount(queueId, robot.id))
          .then((res) => {
              const { data } = res;
              const scheduledExecs = data?.SCHEDULED ?? 0;
              const countExecutions = (data?.MANUALLY ?? 0) + scheduledExecs;
              if (countExecutions === 0) return true;
              const mssg = t("queue.management.robot.has.pendingExecutions", { count: countExecutions })
              toast.error(mssg, {
                     onClose: () => {
                                          if (scheduledExecs > 0) {
                                              toast.error(
                                                  t("queue.management.robot.has.scheduled.pendingExecutions", { count: scheduledExecs })
                                              )
                                          }
                                      }
                    });

              return false;
          })

  const postAssignProcessAction = (action, processId) => {
    if (!processId) processId = selectedProcess.id;
    const newProcessesAction = processesAction.filter((el) => el.id !== processId);
    switch (action) {
      case "DELETE":
        newProcessesAction.push({ id: processId, action, robotId: null });
        setOpenProcessAssignDialog(false);
        break;
      case "KEEP": {
        newProcessesAction.push({ id: processId, action, robotId: null });
        setOpenProcessAssignDialog(false)
        break;
      }
      default:
        break;
    }
    setProcessesAction(newProcessesAction);
  }
  const redragProcessToOrigin = (processId) => {
    const processAssigned = assignedProcesses.find(({ id }) => id === processId)
    if (processAssigned)
    {
      setAssignedProcesses((prevState) => prevState.filter(({ id }) => id !== processId))
      setAvailableProcesses((prevState) => [...prevState, processAssigned])
    }
    else
    {
      const processAvailable = availableProcesses.find(({ id }) => id === processId)
      setAssignedProcesses((prevState) => [...prevState, processAvailable])
      setAvailableProcesses((prevState) => prevState.filter(({ id }) => id !== processId))
    }
  }

  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          <>
            <Grid item xs={12} container className={classes.formRow}>
              <Grid item xs={6} className={classes.inputsSection}>
                <TextField
                  {...register("name", {
                    required: {
                      value: true,
                      message: t("queue.management.form.validation.required"),
                    },
                  })}
                  id="name"
                  label={`${t("queue.management.name")} *`}
                  fullWidth
                  disabled={disableFields}
                  InputLabelProps={{
                    shrink: !!getValues("name"),
                  }}
                  error={!!errors.name?.message}
                  onChange={(event) => {
                    clearErrors("name");
                    setValue("name", event.target.value);
                  }}
                />

                <p className={classes.validation_error}>
                  {errors.name?.message}
                </p>
              </Grid>
              <Grid item xs={6} className={classes.inputsSection}>
                <Controller
                  control={control}
                  name="orchestrator"
                  rules={{
                    required: {
                      value: true,
                      message: t("queue.management.form.validation.required"),
                    },
                  }}
                  render={(field) => (
                    <Autocomplete
                      {...field}
                      disabled={disableFields}
                      value={field.field.value}
                      rules={{
                        required: {
                          value: true,
                          message: t("queue.management.form.validation.required"),
                        },
                      }}
                      options={orchestrators ?? []}
                      getOptionLabel={(option) => option.name}
                      renderOption={(option) => (
                        <span>{option.name}</span>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={`${t("queue.management.orchestrator")} *`}
                          error={!!errors.orchestrator?.message}
                        />
                      )}
                      onChange={(_, data) => {
                        clearErrors("orchestrator");
                        setSelectedOrchestrator(data);
                        return field.field.onChange(data);
                      }}
                      disableClearable
                    />
                  )}
                />
                <p className={classes.validation_error}>
                  {errors.orchestrator?.message}
                </p>
              </Grid>
            </Grid>
            <Grid item xs={12} container className={classes.formRow}>
              <Grid item xs={6} className={classes.inputsSection}>
                <TextField
                  {...register("nbLicences", {
                    required: {
                      value: true,
                      message: t("queue.management.form.validation.required"),
                    },
                    validate: (nb) => {
                      if (getDefaultFormat(nb) < 0) return t("queue.management.form.validation.negative-nb-licences");
                      if (getDefaultFormat(nb) > remainingLicences) return t("queue.management.form.validation.nb-licences-bigger-than-remaining", { count: remainingLicences });
                      return true;
                    }
                  })}
                  id="nbLicences"
                  type="text"
                  label={`${t("queue.management.nb-licences")} *`}
                  fullWidth
                  disabled={disableFields || !selectedOrchestrator}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={!!errors.nbLicences?.message}
                  onChange={(event) => {
                    clearErrors("nbLicences");
                    setValue("nbLicences", +(event.target.value));
                  }}
                  onInput={(e) => handleInputChanges(e, 9)}
                  onBlur={() => formatInputOnBlur("nbLicences", { getValues, setValue })}
                  onFocus={() => formatInputOnFocus("nbLicences", { getValues, setValue })}
                  inputProps={{
                    min: 0
                  }}
                />

                <p className={classes.validation_error}>
                  {errors.nbLicences?.message}
                </p>
              </Grid>
            </Grid>
          </>
        );
      case 1:
        return (
          <Grid item xs={12} style={{ height: "100%" }}>
            <ProcessSelector
              availableProcesses={availableProcesses}
              selectedProcesses={assignedProcesses}
              setAvailableProcesses={setAvailableProcesses}
              setSelectedProcesses={setAssignedProcesses}
              isDisabled={disableFields}
              onAssignProcess={onAssignProcess}
              onUnAssignProcess={onUnAssignProcess}
            />
          </Grid>
        );
      case 2:
        return (
          <Grid item xs={12} style={{ height: "100%" }}>
            <RobotSelector
              availableRobots={availableRobots}
              selectedRobots={assignedRobots}
              setAvailableRobots={setAvailableRobots}
              setSelectedRobots={setAssignedRobots}
              isDisabled={disableFields}
              onUnAssignRobot={onUnAssignRobot}
            />
          </Grid>
        );
      default:
        return null;
    }
  };

  const tabsStyle = useIndicatorStyle();

  return (
    <div className={classes.root}>
      <Backdrop className={classes.backdrop} open={isLoadingSchedules}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Paper className={classes.paper}>
        {mode === "view" ? (
          <Paper square>
            <Grid container direction="row" xs={12}>
              <Grid container direction="row" xs={11}>
                <Tabs
                  className={classes.tabSize}
                  value={activeStep}
                  indicatorColor="primary"
                  textColor="primary"
                  onChange={handleChange}
                  TabIndicatorProps={{
                    style: tabsStyle,
                  }}
                >
                  {steps.map((label, index) => (
                    <CustomTab
                      key={label}
                      id="process-overview-tab"
                      value={index}
                      label={t(label)}
                    />
                  ))}
                </Tabs>
              </Grid>
              <Grid container direction="row" xs={1} justify="flex-end">
                <CustomCloseButton
                className={classes.closeIcon}
                onClick={handleCancel}
                />
              </Grid>
            </Grid>
          </Paper>
        ) : (
          <>
            <Grid
              container
              direction="row"
              xs={1}
              justify="flex-end"
              className={classes.closebutton}
            >
              <CustomCloseButton
                className={classes.closeIcon}
                onClick={handleCancel}
                />
            </Grid>
            <Stepper
              activeStep={activeStep}
              alternativeLabel
              className={classes.stepper}
            >
              {steps.map((label, index) => (
                <Step key={label}>
                  <StepLabel
                    style={{ cursor: "pointer" }}
                    onClick={() => handleStepClick(index)}
                  >
                    {t(label)}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </>
        )}
        <div className={classes.formContainer}>
          {mode === "view" && (isFleetAdministrator(currentUser) || isPermitted(currentUser, "Edit SR Queue")) ? (
            <Grid container justify="flex-end">
              <Tooltip title={t("Edit")}>
                <IconButton
                  aria-label="modify"
                  onClick={() => {
                    history.push(`/robotAdministration/queues/edit/${queueId}`);
                  }}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          ) : null}
          <form className={classes.fleetForm}>
            <Grid
              container
              alignItems="center"
              justify="center"
              xs={12}
              className={classes.inputsContainer}
            >
              {getStepContent(activeStep)}
            </Grid>
            <Grid>
              {!disableFields && (
                ((isPermitted(currentUser, "Add SR Queue") || isFleetAdministrator(currentUser)) && mode === "add")
                || ((isPermitted(currentUser, "Edit SR Queue") || isFleetAdministrator(currentUser)) && mode === "edit")
              ) ? (
                <Grid item xs={12} className={classes.nopadding}>
                  <MuiDialogActions className={classes.modalActionButtons}>
                    <Box
                      ml="1rem"
                      component={Button}
                      variant="contained"
                      size="medium"
                      onClick={handleBack}
                      className={classes.resetButton}
                    >
                      {activeStep <= 0 ? t("Cancel") : t("fleet.add.previous")}
                    </Box>
                    <CustomButton
                      view="primary"
                      size="medium"
                      type="button"
                      startIcon={remainingLicensesLoading && <CircularProgress color="inherit" size={13} />}
                      onClick={handleSaveClick}
                      disabled={remainingLicensesLoading}
                    >
                      {/* eslint-disable-next-line no-nested-ternary */}
                      {activeStep === steps.length - 1
                        ? queueId
                          ? t("queue.management.update")
                          : t("queue.management.add")
                        : t("next")}
                    </CustomButton>
                  </MuiDialogActions>
                </Grid>
              ) : null}
            </Grid>
          </form>
        </div>
      </Paper>

      {openMsgConfirm && (
        <ConfirmMessage
          message={
            queueId ? t("queue.management.update.confirm-msg") : t("queue.management.save.confirm-msg")
          }
          openStart={openMsgConfirm}
          onCancel={cancelConfirm}
          onConfirm={confirmSave}
          buttonConfirm={
            queueId ? t("queue.management.update") : t("queue.management.add")
          }
          buttonCancel={t("Cancel")}
          isLoading={isLoading}
        />
      )}
      {openMsgCancel && (
        <ConfirmMessage
          message={t("user.delete.discard")}
          openStart={openMsgCancel}
          onCancel={handleRejectCancelForm}
          onConfirm={handleAcceptCancelForm}
          buttonCancel={t("Cancel")}
          buttonConfirm={t("Discard")}
          isLoading={false}
        />
      )}
      {openPendingExecutionWarning && (
        <ConfirmMessage
          message={t("pending.executions.warning")}
          openStart={openPendingExecutionWarning}
          onCancel={() => {
            setOpenPendingExecutionWarning(false)
            redragProcessToOrigin(processToUnAssign.id)
          }}
          onConfirm={confirmDeletePendingExecutions}
          buttonCancel={t("Cancel")}
          buttonConfirm={t("Continue")}
          isLoading={false}
        />
      )}
      {openProcessAssignDialog && (
        <CustomDialog
          open={openProcessAssignDialog}
          aria-labelledby="confirm_message-dialog-title"
          maxWidth="xs"
        >
          <DialogContent>
            <DialogContentText id="confirm_message-dialog-content">
              <Grid container xs={12} justify="center">
                <Grid item xs={10}>
                  <Typography variant="subtitle1">
                    {dialogMsg}
                  </Typography>
                </Grid>
                {dialogMsg2
                  && (
                  <Grid container xs={10}>
                    <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
                      {dialogMsg2}
                    </Typography>
                  </Grid>)}
              </Grid>
            </DialogContentText>
          </DialogContent>
          <MuiDialogActions>
            <Button
              ml="1rem"
              variant="contained"
              size="medium"
              onClick={() => {
                redragProcessToOrigin(selectedProcess.id)
                setOpenProcessAssignDialog(false)
              }}
              className={classes?.resetButton}
            >
              {t("Cancel")}
            </Button>
            {hasSchedules ? (
              <Grid container direction="row-reverse" spacing={1}>
                <Grid item>
                  <Button
                    ml="1rem"
                    variant="contained"
                    color="secondary"
                    size="medium"
                    onClick={() => postAssignProcessAction("KEEP")}
                  >
                    {t("Yes")}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    ml="1rem"
                    variant="contained"
                    size="medium"
                    className={classes.deleteButton}
                    onClick={() => postAssignProcessAction("DELETE")}
                  >
                    {t("No")}
                  </Button>
                </Grid>
              </Grid>
            ) : (
              <Button
                ml="1rem"
                variant="contained"
                color="secondary"
                size="medium"
                onClick={() => setOpenProcessAssignDialog(false)}
                className={classes.resetButton}
              >
                {t("Continue")}
              </Button>
            )}

          </MuiDialogActions>
        </CustomDialog>

      )}
    </div>
  );
};

export default QueueForm;
