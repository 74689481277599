import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  root: {
    width: "100%",
    background: "transparent",
    // margin: -theme.spacing(3),
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
    padding: theme.spacing(0, 4, 2, 4),
  },
  rootGlobal: {
    width: "100%",
    paddingLeft: theme.spacing(2),
  },
}));
