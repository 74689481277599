import React, { useEffect, useMemo } from "react";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import ClearRounded from "@material-ui/icons/ClearRounded";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import Box from "@material-ui/core/Box";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import CustomAutoComplete, {
  CustomTextField,
} from "../../../../../components/FormFields/CustomAutoComplete";
import NoDataMenu from "../../../../../components/NoData/NoDataMenu";
import TaskSession from "./TaskSession";
import useStyles from "./style";

export default function TaskForm({ task, isEdit, index }) {
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    watch,
    formState: { errors },
    register,
    setValue,
    unregister,
  } = useFormContext();

  function clearTask(row, taskType) {
    const taskName = row?.[taskType]?.name;
    if (taskName === task.name) {
      row[taskType] = null;
    }
  }

  const onDeleteTask = () => {
    const fieldPath = "tasks";
    const newTasks = watch(fieldPath)?.filter((row, i) => index !== i);
    newTasks?.forEach((row) => {
      clearTask(row, "onCompleteTask");
      clearTask(row, "onExceptionTask");
    });
    unregister("tasks");
    setValue(
      fieldPath,
      newTasks
    );
  };

  const actionsTasks = useMemo(
    () => watch("tasks").filter((elm, i) => i !== index),
    [watch("tasks")]
  );
  const stopTask = { id: 0, name: t("task.stop") };
  const abordTask = { id: 0, name: t("task.abort") };

  useEffect(() => {
    if (watch("initialTask") && task?.name === watch("initialTask")?.name) setValue(`tasks.${index}.isInitial`, true);
    else setValue(`tasks.${index}.isInitial`, false);
  }, [watch("initialTask")]);

  useEffect(() => {
    if (isEdit && !task?.onCompleteTask) setValue(`tasks.${index}.onCompleteTask`, stopTask);
    if (isEdit && !task?.onExceptionTask) setValue(`tasks.${index}.onExceptionTask`, abordTask);
  }, [isEdit]);

  return (
    <Grid
      component={Box}
      position="relative"
      item
      xs={12}
      container
      className={classes.taskContainer}
    >
      <Box
        position="absolute"
        top={-20}
        left={17}
        bgcolor="background.default"
        padding={1}
      >
        <Typography className={classes.taskTitle}>
          {`${t("task")} ${index + 1}`}
        </Typography>
      </Box>
      <Box position="absolute" top={-13} right={-11} height="fit-content">
        <Tooltip title={t("task.remove")}>
          <IconButton
            size="small"
            onClick={onDeleteTask}
            className={classes.deleteTaskIconButton}
          >
            <ClearRounded className={classes.deleteTaskIcon} />
          </IconButton>
        </Tooltip>
      </Box>
      <Grid item xs={4}>
        <FormControl margin="dense" fullWidth className={classes.formControls}>
          <CustomTextField
            fullWidth
            type="text"
            label={t("task.name")}
            id={`task-name-${index}`}
            value={watch(`tasks.${index}.name`)}
            {...register(`tasks.${index}.name`, {
              required: {
                value: true,
                message: t("Your input is required"),
              },
              validate: (value) => (watch("tasks")
                  ?.map(({ name }) => name)
                  ?.filter((elm) => elm === value).length > 1
                  ? t("task.name.already.exist")
                  : true),
            })}
            error={errors?.tasks?.[index]?.name}
            helperText={errors?.tasks?.[index]?.name?.message}
          />
        </FormControl>
      </Grid>
      <Grid item xs={8}>
        <FormControl margin="dense" fullWidth className={classes.formControls}>
          <CustomTextField
            fullWidth
            type="text"
            label={t("task.description")}
            id={`task-description-${index}`}
            value={watch(`tasks.${index}.description`)}
            {...register(`tasks.${index}.description`)}
          />
        </FormControl>
      </Grid>
      <Grid item xs={4}>
        <FormControl margin="normal" fullWidth className={classes.formControls}>
          <CustomAutoComplete
            options={actionsTasks?.concat(stopTask) || []}
            optionLabel="name"
            optionUuid="name"
            noOptionsNode={<NoDataMenu message={t("no.task.message")} />}
            {...register(`tasks.${index}.onCompleteTask`, {
              required: true,
            })}
            value={watch(`tasks.${index}.onCompleteTask`)}
            onChange={(newVal) => setValue(`tasks.${index}.onCompleteTask`, { name: newVal?.name })}
            label={t("task.on.complete")}
            error={errors?.tasks?.[index]?.onCompleteTask}
            helperText={errors?.tasks?.[index]?.onCompleteTask && t("Your input is required")}
            translateLabel={false}
            disableCloseOnSelect={false}
          />
        </FormControl>
      </Grid>
      <Grid item xs={4}>
        <FormControl margin="normal" fullWidth className={classes.formControls}>
          <CustomAutoComplete
            options={actionsTasks?.concat(abordTask) || []}
            optionLabel="name"
            optionUuid="name"
            noOptionsNode={<NoDataMenu message={t("no.task.message")} />}
            {...register(`tasks.${index}.onExceptionTask`, {
              required: t("Your input is required"),
            })}
            label={t("task.on.exception")}
            value={watch(`tasks.${index}.onExceptionTask`)}
            onChange={(newVal) => {
              setValue(`tasks.${index}.onExceptionTask`, {
                name: newVal?.name,
              });
            }}
            error={errors?.tasks?.[index]?.onExceptionTask}
            helperText={errors?.tasks?.[index]?.onExceptionTask?.message}
            translateLabel={false}
            disableCloseOnSelect={false}
          />
        </FormControl>
      </Grid>
      <Grid item xs={4}>
        <FormControl margin="normal" fullWidth className={classes.formControls}>
          <CustomTextField
            fullWidth
            type="number"
            label={t("task.post.completion.delay")}
            id={`task-postCompletionDelay-${index}`}
            value={watch(`tasks.${index}.postCompletionDelay`)}
            {...register(`tasks.${index}.postCompletionDelay`, {
              required: true,
            })}
            error={errors?.tasks?.[index]?.postCompletionDelay}
            helperText={
              errors?.tasks?.[index]?.postCompletionDelay
              && t("Your input is required")
            }
          />
        </FormControl>
      </Grid>
      {task?.taskSessions?.map((session, i) => (
        <TaskSession
          task={task}
          index={index}
          i={i}
          key={`task-session-${index}-${i}`}
        />
      ))}
    </Grid>
  );
}
