import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    overflowX: "hidden",
    overflowY: "hidden",
    backgroundColor: "none",
    padding: 0,
    color: theme.palette.primary.main,
    fontWeight: "bold"
  },
  infoIcon: {
    fontSize: 13,
    color: theme.custom.color.color6,
  },
  hourTypography: {
    display: "flex"
  },
  serviceModalContainer: {
    height: 750,
    overflow: "unset"
  },
  ressourcegrid: {
    marginBottom: "10px",
    justifyContent: "center",
    margin: "auto",
  },
  type: {
    width: "7.4rem",
    marginTop: "0.5rem",
    marginLeft: "2rem",
  },
  oneLineField: {
    width: "23rem",
  },
  grid: {
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "5px",
    margin: "auto",
  },
  typography: {
    color: theme.palette.primary.main,
    fontSize: 14,
  },
  marginauto: {
    marginTop: "17px",
    padding: "5px",
  },
  executionTime: {
    display: "flex",
    alignItems: "center",
  },
  executionTimeT: {
    display: "inline",
    marginRight: "20px",
  },
  dialogRoot: {
    margin: 0,
  },
  DialogContentRoot: {
   flex: 1,
  },
  closeButton: {
    color: theme.custom.color.chartColor3
  },
  tabs: {
    backgroundColor: theme.palette.secondary.main,
  },
  cardHeader: {
    textAlign: "center",
  },

  cardActions: {
    padding: 0,
    marginTop: 10
  },
  span: {
    backgroundColor: theme.palette.grey[200],

    width: "30%",
  },
  content: {
    justifyContent: "center",
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(0),
  },
  title: {
    marginLeft: theme.spacing(2),
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  tableCellHistorical: {
    textAlign: "center",
  },
  tableCellAction: {
    minWidth: 300,
    fontSize: 15,
    textAlign: "left",
    paddingLeft: 0,
  },
  resources: {
    maxHeight: "250px",
    minWidth: "70%",
    overflow: "auto",
  },

  resetButton: {
    marginRight: theme.spacing(1),
  },
  successStar: {
    color: theme.custom.color.color1,
  },
  errorStart: {
    color: theme.custom.color.color4,
  },
  startMessage: {
    display: "flex",
    alignItems: "center",
    color: theme.palette.grey[800],
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  editTexField: {
    width: "95%",
  },
  editTexAreaField: {
    width: "95%",
  },

  button: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  cancelButton: {
    borderColor: theme.custom.color.chartColor2,
    color: theme.custom.color.chartColor2
  },
  saveButton: {
    marginLeft: theme.spacing(1),
    backgroundColor: theme.custom.color.chartColor2,
    "&:hover": {
      backgroundColor: theme.custom.color.color7,
    },
    color: "white",
  },
  pending: {
    color: theme.custom.color.color4,
    borderColor: theme.custom.color.color4,
  },
  running: {
    color: theme.custom.color.color2,
    borderColor: theme.custom.color.color2,
  },
  done: {
    color: theme.custom.color.color1,
    borderColor: theme.custom.color.color1,
  },
  status: {
    background: "transparent",
    border: "2px solid ",
    borderRadius: "1rem",
    padding: 2,
    fontSize: "12px",
  },
  fullWidth: {
    width: "100%"
  },
  padding: {
    padding: "2px",
  },
  paper: {
    width: "100%",
    backgroundColor: "unset",
    margin: "0px",
    display: "flex",
    flexDirection: "column",
  },
  autoComplete: {
    width: 220,
    marginBottom: 10,
  },
  pagination: {
    fontSize: theme.spacing(0.875),
  },
  toDelete: {
    background: "transparent",
    color: "#3c44b1",
    border: "2px solid #3c44b1",
    borderRadius: "1rem",
    padding: 0,
  },
  editIconBtn: {
    marginBottom: theme.spacing(1.5),
  },
  box: {
    position: "relative",
  },
  icon: {
    width: "15px",
    height: "15px",
    borderRadius: "50%",
  },
  iconUserGuide: {
    marginLeft: theme.spacing(0.8),
    fontSize: "16px",
    marginTop: "5px",
  },
  beFormContainer: {
    "&:not(:last-child)": {
      marginBottom: theme.spacing(4),
      position: "relative",
    },
  },
  dateContainer: {
    marginLeft: theme.spacing(2),
    width: 300,
  },
  validation_error: {
    color: "red",
    margin: "0",
    marginTop: theme.spacing(1),
    position: "absolute",
  },
  tooltip: {
    fontSize: "0.875rem",
    fontFamily: "Roboto",
    fontWeight: "400",
  },
  pannel: {
    "& > *": {
      padding: "0px",
    },
  },
  contentContainer: {
    height: "100%",
    overflowY: "auto",
    minHeight: 480
  },
  smallContentContainer: {
    overflowY: "auto",
    padding: "15px",
  },
  heightLimiterContainer: {
    height: "450px",
    overflowY: "auto",
  },
  tablebox: {
    height: "550px",
    overflowY: "auto",
    "& .MuiBox-root": {
      height: "100%",
    },
  },
  formProcessGap: {
    display: "flex",
    flexDirection: "column",
    gap: "24px 0px",
  },
  historicalInfoTableContainer: {
    height: "370px",
  },
  dataMappingTableContainer: {
    height: 430,
    marginBottom: "20px",
    marginTop: 30
  },
  containerChip: {
    gap: "5px 6px",
  },
  chip: {
    display: "flex",
    flexDirection: "column",
    height: 51,
    width: 31,
    gap: "5px",
    padding: 0,
    alignItems: "center",
    borderRadius: 4,
    fontSize: 10,
    fontWeight: 500
  },
  chipIcon: {
    fontSize: 16,
    margin: 0
  },
  historyDialogContent: {
    marginTop: theme.spacing(2),
  },
  itemChip: {
    width: "130px",
  },
  estimatedExecutionInputs: {
    width: "7rem",
    marginRight: "2rem",
  },
  customTextColor: {
    color: "red"
  },
  robotAvatar: {
    width: "80px",
    height: "80px"
  },
  dialogTitle: {
    marginBottom: "20px",
    color: theme.palette.primary.main
  },
  editProfileImage: {
    backgroundColor: theme.custom.color.chartColor3,
    color: "white"
  },
  pencil: {
    color: theme.custom.color.chartColor3,
    padding: "0px 0px 5px 5px",

  },
  fieldContent: {
    fontWeight: "700"
  },
  field: {
    margin: "10px 0px 10px 0px"
  },
  editIcon: {
    padding: 5,
    backgroundColor: theme.palette.primary.main,
    color: "white",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: "white",
    }

  },
  avatarImage: {
    marginBottom: 10
  },
  tabsTextColor: {
    color: theme.palette.primary.main,
  },
  playBtn: {
    width: 25,
    height: 25,
  },
  pencilSize: {
    fontSize: "18px",
  },
  avatarImg: {
    width: 80,
    height: 80
  },
  dialogContent: {
    paddingLeft: 35,
    paddingRight: 35,
    paddingBottom: 35,
    height: 600
  },
  itemsFinished: {
    width: "70%",
    color: theme.palette.primary.main,
  },
  itemsFinishedIcon: {
    color: theme.custom.color.chartColor2,
    fontSize: 14
  },
  itemsExceptionIcon: {
    color: theme.custom.color.color6,
    fontSize: 14
  },
  tableCellText: {
    fontWeight: "700",
    paddingLeft: theme.spacing(0.6)
  },
  slaContainer: {
    padding: theme.spacing(3, 3, 0, 3),
  },
  slaSectionContainer: {
    padding: 20,
    border: `1px solid ${theme.custom.color.containersBorder}`,
    marginTop: 25
  },
  parametersLeftContainer: {
    padding: theme.spacing(1, 2, 0, 1),
  },
  optionsContainer: {
    padding: theme.spacing(1.7, 0)
  },
  applyBtnContainer: {
    height: 110
  },
  optSpacing: {
    marginBottom: theme.spacing(1)
  },
  optLabel: {
    color: theme.palette.primary.main
  },
  inputAdorn: {
    color: theme.custom.color.color6,
  },
  optText: {
    fontWeight: "800"
  },
  inputLabel: {
    color: theme.palette.primary.main,
    fontSize: 14
  },
  stateContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1FR)",
    gap: "25px"
  },
  rootContainer: {
    display: "grid",
    gridTemplateColumns: "9FR 4FR",
    gap: "25px"
  },
  executionTimeTitle: {
    fontSize: 13,
    color: theme.custom.color.color6,
    fontWeight: 600
  },
  executionTimeLabel: {
    fontSize: 14,
    color: theme.palette.primary.main,
  },
  executionTimeInput: {
    width: 90,
    fontSize: 11,
    "&::-webkit-calendar-picker-indicator": {
      cursor: "pointer",
      filter: "invert(0.5)"
    }
  },
  daysContainer: {
    display: "grid",
    rowGap: "10px",
    backgroundColor: theme.custom.color.containerBackground,
    padding: 15,
    marginTop: 10,
  },
  keepPolicyContainer: {
    display: "grid",
    gridTemplateColumns: "8FR 1FR 2FR 1FR",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: "10px"
  },
  checkboxRoot: {
    color: theme.custom.color.chartColor1,
  },
  checkboxChecked: {
    color: `${theme.custom.color.chartColor2} !important`,
  },
  processTitle: {
    fontWeight: "700",
  },
  switchBtn: {
    marginTop: 10
  },
  modalActions: {
    marginTop: 20
  },
  transparentTableCell: {
    borderBottom: "none !important",
    color: theme.palette.primary.main,
    fontSize: 15,
    fontWeight: 600
  },
  dataMappingTableBody: {
    backgroundColor: theme.custom.color.containerBackground,
  },
  tableCellInput: {
    width: 190
  },
  dateInput: {
    color: theme.palette.primary.main,
    fontSize: 12,
    fontWeight: "400",
    "& .MuiInput-underline:before": {
      borderBottomColor: theme.custom.color.chartColor2,
      borderWidth: 3,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: theme.custom.color.chartColor2,
    },
  },
  calendarIcon: {
    color: theme.custom.color.chartColor2,
    fontSize: 12
  },
  tabSize: {
    zIndex: 10
  },
  checkboxContainer: {
    width: "fit-content",
    margin: "9px 0"
  },
  checkboxNoPadding: {
    padding: 0,
    marginRight: "9px"
  }
}));
