import { makeStyles, darken, fade } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  root: {
    display: "flex",
    flex: "0 0 100%",
    marginBottom: "1%",
    cursor: "pointer",
    boxShadow:
      "rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px",
    background: "white !important",
    transition: theme.transitions.create("transform", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.short,
    }),
    "&:hover": {
      background: "white !important",
      boxShadow: theme.shadows[25],
      transform: "scale(1.01)",
    },
  },
  rootGlobal: {
    width: "100%",
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(2),
  },
  autoComplete: {
    position: "relative"
  },
  reportContainer: {
    boxShadow: theme.custom.shadow,
    margin: theme.spacing(2, 0),
    paddingBottom: theme.spacing(5),
    backgroundColor: "#fff",
  },
  exportBtn: {
    height: "100%",
    width: "100%",
    fontSize: 13
  },
  clearBtn: {
    height: 40,
    backgroundColor: theme.custom.color.color4,
    marginLeft: theme.spacing(0.5),
    color: "white",
    "&:hover": {
      background: darken(theme.custom.color.color4, 0.2),
    },
  },
  graphTitle: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  hideContainer: {
    display: "none",
  },
  exportButtonContainer: {
    height: "70%",
    width: "100%"
  },
  clearFilterContainer: {
    marginBottom: "-10px",
    paddingLeft: "10px",
  },
  help: {
    fontSize: 13,
    position: "absolute",
    right: -15,
    bottom: 5,
    color: theme.custom.color.chartColor3
  },
  helpAutoComplete: {
    fontSize: 13,
    position: "absolute",
    right: -5,
    bottom: 18,
    color: theme.custom.color.chartColor3
    },
  circularLoaderContainer: {
    height: "200px",
    width: "100%",
  },
  createDialog: {
    margin: "auto",
    width: "500px",
    height: "450px"
  },
  createKpiDialog: {
    margin: "auto",
    width: "900px",
    minHeight: "600px"
  },
  kpiNameTextField: {
    marginBottom: "20px"
  },
  createKpiDialogContent: {
    overflowY: "visible",
    marginRight: "5px"
  },
  addKpiButton: {
    color: "black"
  },
  deleteDialog: {
    margin: "auto",
    width: "450px",
    height: "400px"
  },
  deleteButton: {
    padding: "6px",
    marginRight: "7px",
    height: "35px",
    width: "35px",
    borderRadius: "20px",
    transition: "background-color 0.2s ease-in-out",
    "&:hover": {
      transition: "background-color 0.2s ease-in-out",
      backgroundColor: "#ECECEC",
      borderRadius: "20px",
      cursor: "pointer",
    }
  },
  validation_error: {
    color: "red",
    margin: "0",
  },
  popupIcon: {
    color: theme.custom.color.chartColor2
  },
    input: {
  color: theme.palette.primary.main,
  fontSize: 14,
  fontWeight: "400",
  "& .MuiInput-underline:before": {
    borderBottomColor: theme.custom.color.chartColor2,
    borderWidth: 3,
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: theme.custom.color.chartColor2,
  },
  "& .MuiInput-underline:hover:before": {
    borderBottomColor: theme.custom.color.chartColor2,
    borderWidth: 3,
  },
},
  datesContainer: {
    marginTop: 2.5,
    display: "grid",
    gridTemplateColumns: "repeat(2, 1FR)",
    columnGap: "24px"
  },
  filter: {
    marginLeft: -25
  },
  autoCompleteProcesses: {
    marginTop: 2
  },
  kpiButton: {
    fontSize: "1.1rem"
  },
  chipText: {
    maxWidth: "100%",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden"
  },
  customRenderOptionText: {
    maxWidth: "85%"
  },
  dateContainer: {
    padding: theme.spacing(1, 6),
    backgroundColor: fade(theme.custom.color.chartColor1, 0.7),
    textAlign: "center"
  },
  dateLabel: {
    fontWeight: 800,
    fontSize: "0.8rem",
    marginRight: 10,
    color: theme.palette.primary.main
  },
  dateValue: {
    fontSize: "0.8rem",
    fontWeight: 600,
    width: "100%",
    color: theme.palette.primary.main
  }
}));
