import React, { useState, useEffect, useRef } from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { fetchDashboardAllTimeData } from "redux/actions/services/dashboard";
import renderValue, { MONITORING_TIME, MONITORING_TIME_Params } from "../dashboardUtils";
import { secondsToFTPTime } from "util";
import { handleRequestMonitoringMode } from "pages/Dashboard/dashboardUtils";
import { isEnvParamEnabled } from "util/index";
import Transactions from "../../../assets/NewDashboardIcons/transactions.svg";
import Bots from "../../../assets/NewDashboardIcons/bots.svg";
import Time from "../../../assets/NewDashboardIcons/timeIcon.svg";
import SuccessRate from "../../../assets/NewDashboardIcons/checked.svg";
import StateItem from "./StateItem";
import useStyles from "../style";

export default function DashboardStates({ userFleet, xs }) {
  const { t } = useTranslation();
  const classes = useStyles()();
  const dispatch = useDispatch();
  const filterDashboard = useSelector(({ dashboardFilters }) => dashboardFilters);
  const [transactions, setTransactions] = useState();
  const [successRate, setSuccessRate] = useState();
  const [botsTotalHours, setBotsTotalHours] = useState();
  const [fteSaved, setFteSaved] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const isRiyadBankEnvEnabled = isEnvParamEnabled("REACT_APP_DISPLAY_RIYAD_BANK_LOGO");
  const states = [
    {
      label: isRiyadBankEnvEnabled ? t("transactions.header.label") : t("items.header.label"),
      tooltipTitle: t("transactions.header.tooltip"),
      state: renderValue({ value: transactions }),
      icon: Transactions,
      loading: isLoading,
      xs: 3,
    },
    {
      label: "successRate.header.label",
      tooltipTitle: t("successRate.header.tooltip"),
      state: renderValue({
        value: successRate,
        suffix: "%",
        test: 5,
        t,
      }),
      icon: SuccessRate,
      loading: isLoading,
      xs: 3,
    },
    {
      label: "Robot Productivity",
      tooltipTitle: t("bots.header.tooltip"),
      state: botsTotalHours ? secondsToFTPTime(botsTotalHours, userFleet?.hoursPerManDay, userFleet?.daysPerYear, t) : 0,
      icon: Bots,
      loading: isLoading,
      xs: 3,
    },
    {
      label: "Time Saved",
      tooltipTitle: t("FTESaved.header.tooltip"),
      state: renderValue({
        value: fteSaved,
        suffix: ` ${t("timeUnite.fte")}s`,
        t,
      }),
      icon: Time,
      loading: isLoading,
      xs: 3,
    },
  ];

  const fetchNewDashboardStates = (withLoading = true) => {
    setIsLoading(withLoading);

    dispatch(fetchDashboardAllTimeData()).then((res) => {
      setIsLoading(false);
      if (res.status === 200) {
        setTransactions(res.data.allTransactions);
        setSuccessRate(res.data.successRate);
        setBotsTotalHours(res.data.botsTotalHours);
        setFteSaved(res.data.ftesSaved);
      }
    });
  };

  const fetchIntervalRef = useRef(null);
  useEffect(() => {
    handleRequestMonitoringMode(MONITORING_TIME, fetchIntervalRef, fetchNewDashboardStates, MONITORING_TIME_Params)
    return () => {
      clearInterval(fetchIntervalRef.current)
    }
  }, [filterDashboard])

  useEffect(() => {
    fetchNewDashboardStates();
  }, [userFleet]);

  return (
    <Box
      component={Grid}
      item
      container
      xs={xs}
      className={`${classes.container} ${classes.statesContainer}`}
    >
      {states.map((state, index) => (
        <StateItem
          state={state}
          isLoading={state.loading}
          key={index}
          isLast={states.length === index + 1}
        />
      ))}
    </Box>
  );
}
