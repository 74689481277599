import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  root: {
    display: "flex",
    marginBottom: "1%",
  },
  content: {
    display: "flex",
    flex: "0 0 85%",
    flexDirection: "column",
    padding: 0,
  },

  rowActions: {
    flex: "0 0 15%",
    justifyContent: "flex-end",
    padding: theme.spacing(2, 3),
  },
  rowActionButtons: {
    padding: theme.spacing(1, 1),
  },
  menuItemContainer: {
    padding: theme.spacing(1),
  },
  menuItemDivider: {
    width: "100%",
  },
  permissionContainer: {
    padding: theme.spacing(0, 1),
  },
  templateLink: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  deleteIcon: {
    color: theme.custom.color.color4,
  },
}));
