import React from "react";
import { Alert } from "@material-ui/lab";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import Collapse from "@material-ui/core/Collapse";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Input from "@material-ui/core/Input";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import CustomDialog from "pages/Services/components/CustomDialog";
import { useTranslation } from "react-i18next";
import { cloneDeep } from "lodash";
import { useDispatch } from "react-redux";
import { useQuery } from "@redux-requests/react";
import { bulkRetryItemException, checkPendingRetry, retryItemException } from "../../../../redux/actions/services";
import { isActionPemitted } from "../../../../components/HasPermission";
import { FETCH_CURRENT_USER } from "../../../../redux/constants";
import { toast } from "react-toastify";
import CustomButton from "../../../../components/CustomButton";
import useStyles from "./style";

function RetryDialog({
  open, onClose, items = [], processIsRetryable, fetchData, resetSelected
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [pendingRetryCheck, setPendingRetryCheck] = React.useState(null);
  const [dataType, setDataType] = React.useState("same");
  const [data, setData] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [loadingMarkAsDone, setLoadingMarkAsDone] = React.useState(false);
  const currentUser = useQuery({ type: FETCH_CURRENT_USER }).data;
  const isRetryableWithData = items[0]?.processDto?.isRetryable && items[0]?.processDto?.isRetryableWithData;
  React.useEffect(() => {
    if (items.length === 1) {
      setData(items[0].parsedData);
      dispatch(checkPendingRetry(items[0].originalItemId)).then((res) => {
        if (res.status === 200) {
          // res.data can either be true or false
          setPendingRetryCheck(false);
        } else {
          onClose();
        }
      });
    }
  }, [items]);

  const retry = (markAsDone = false) => {
    changeLoadingState(markAsDone, true)
    if (items.length === 1) {
      dispatch(retryItemException(items[0].id, dataType === "changed", data, markAsDone, handleRetrySuccess, handleRetryError))
    } else if (items.length > 1) {
      dispatch(bulkRetryItemException(items.map((i) => i.id), markAsDone, handleRetrySuccess, handleRetryError))
    }
  };

  const handleRetrySuccess = (response, markAsDone, isBulk) => {
    if (response.data) {
      const successMessage = isBulk
        ? (`${t("retry-dialog.message.success.multiple-items", { count: response.data })}${response.data < items.length ? ` ${t("retry-dialog.message.warning.pending")}` : ""}`)
        : t("retry-dialog.message.success.one-item");
      toast.success(successMessage)
      if (markAsDone) {
        fetchData();
      }
      resetSelected();
      onClose();
    }
    else if (isBulk) {
      toast.error(t("retry-dialog.pending-alert-multiple"))
    }
    else {
      setPendingRetryCheck(true)
    }
    changeLoadingState(markAsDone, false);
  }

  const handleRetryError = (markAsDone, isBulk) => {
    const errorMessage = isBulk ? t("retry-dialog.message.error.multiple-items") : t("retry-dialog.message.error.one-item");
    toast.error(errorMessage)
    changeLoadingState(markAsDone, false);
  }

  const changeLoadingState = (markAsDone, isLoading) => {
    (markAsDone) ? setLoadingMarkAsDone(isLoading) : setLoading(isLoading);
  }

  const handleChange = (e, row) => {
    const idx = data.findIndex((o) => o.name === row.name);
    const newData = cloneDeep(data);
    newData[idx].value = e.target.value;
    setData(newData);
  };

  const getOneItemContent = () => {
    // pending check request in progress
    if (pendingRetryCheck === null) {
      return (
        <Box px={10} py={1.5} display="flex" alignItems="center" justifyContent="center">
          <CircularProgress />
        </Box>
      );
    }
    return pendingRetryCheck ? (
      <Alert severity="error">{t("retry-dialog.pending-alert")}</Alert>
    ) : (
      <>
        <DialogContentText>{t("retry-dialog.text.one-item")}</DialogContentText>
        <RadioGroup
          value={dataType}
          onChange={(e) => setDataType(e.target.value)}
        >
          <FormControlLabel
            value="same"
            control={<Radio />}
            label={t("retry-dialog.radio.same-data")}
          />
          {isActionPemitted(
            currentUser,
            "Process Item",
            "Retry item exceptions with changed data"
          )
            && isRetryableWithData && (
              <FormControlLabel
                value="changed"
                control={<Radio />}
                label={t("retry-dialog.radio.changed-data")}
              />
            )}
        </RadioGroup>
        <Collapse in={dataType === "changed"}>
          <Table style={{ marginTop: "8px" }}>
            <TableHead>
              <TableRow>
                <TableCell>{t("retry-dialog.table.name")}</TableCell>
                <TableCell>{t("retry-dialog.table.old-value")}</TableCell>
                <TableCell>{t("retry-dialog.table.new-value")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items[0].parsedData
                .filter((row) => row.editForRetry)
                .map((row, idx) => (
                  <TableRow key={idx}>
                    <TableCell className={classes.nameCell}>
                      <Typography color="textSecondary">{row.name}</Typography>
                    </TableCell>
                    <TableCell className={classes.oldValueCellCell}>
                      <Typography>{row.value}</Typography>
                    </TableCell>
                    <TableCell className={classes.newValueCell}>
                      <Input
                        fullWidth
                        defaultValue={row.value}
                        onChange={(e) => handleChange(e, row)}
                      />
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Collapse>
      </>
    );
  };
    return (
      <CustomDialog
          open={open}
          disableBackdropClick
          PaperProps={{
            style: {
              maxWidth: 900,
            },
          }}
        >
        {processIsRetryable ? (
          <>
            <DialogContent>
              {
                items.length === 1
                  ? getOneItemContent()
                  : (
                    <DialogContentText>
                      {t("retry-dialog.text.multiple-items", { count: items.length })}
                    </DialogContentText>
                  )
              }
            </DialogContent>
            <DialogActions>
              <CustomButton
                view="cancel"
                onClick={onClose}
                disabled={loading}
              >
                {t("Cancel")}
              </CustomButton>
              {
                ((pendingRetryCheck === false) || (items.length > 1))
                 && (
                 <CustomButton
                   view="primary"
                   onClick={() => retry(false)}
                   disabled={loading}
                   startIcon={loading && <CircularProgress size={20} />}
                 >
                   {t("retry")}
                 </CustomButton>
                 )
              }
              {
                ((pendingRetryCheck === false) || (items.length > 1))
                  && (
                  <CustomButton
                      view="primary"
                      onClick={() => retry(true)}
                      disabled={loadingMarkAsDone}
                      startIcon={loadingMarkAsDone && <CircularProgress size={20} />}
                  >
                    {t("retry.mark.done")}
                  </CustomButton>
                  )
              }
            </DialogActions>
          </>
        ) : (
          <>
            <DialogTitle>
              {items.length === 1 ? t("retry-dialog.title.one-item") : t("retry-dialog.title.multiple-items", { count: items.length })}
            </DialogTitle>
            <DialogContent>
              <Alert severity="error">
                {t("retry-dialog.not-retryable")}
              </Alert>
            </DialogContent>
            <DialogActions>
              <CustomButton
                variant="contained"
                onClick={onClose}
                disabled={loading}
              >
                {t("Cancel")}
              </CustomButton>
            </DialogActions>
          </>
        )}
      </CustomDialog>
    );
}

export default RetryDialog;
