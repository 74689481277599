import { fade, makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    formControls: {
        padding: "10px 20px",
        margin: 0
    },
    taskContainer: {
        padding: "5px 5px 20px",
        border: `2px solid ${theme.custom.color.bakgroundColor}`,
        height: "fit-content !important",
        flexBasis: "content",
        margin: "20px 0"
    },
    taskTitle: {
        fontSize: 14,
        color: theme.custom.color.chartColor1
    },
    addSessionIcon: {
        color: theme.custom.color.chartColor2
    },
    deleteSessionIcon: {
        color: theme.custom.color.chartColor3
    },
    deleteTaskIconButton: {
        padding: 2,
        fontSize: 10,
        backgroundColor: theme.custom.color.chartColor4,
        "&.MuiIconButton-root:hover": {
            backgroundColor: fade(theme.custom.color.chartColor4, 0.8),
        }
    },
    deleteTaskIcon: {
        color: "white",
        fontSize: 16,
    },
    help: {
        fontSize: 14,
        marginBottom: -10,
        color: theme.custom.color.chartColor4
    }
}));
