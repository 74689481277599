import React, { useEffect, useRef, useState } from "react";
import Typography from "@material-ui/core/Typography";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";
import { Doughnut } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { getQuery } from "@redux-requests/core";
import { fetchDashboardPendingItemsCount } from "redux/actions/services/dashboard";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "@redux-requests/react";
import { FETCH_CURRENT_USER } from "redux/constants";
import renderValue, {
    DOUGHNUT_CHART_DATASET_DATA,
    DOUGHNUT_CHART_DATASET_OPTION, MONITORING_TIME,
    renderContent, translationX, translationY
} from "pages/newDashboard/dashboardUtils";
import { isEnvParamEnabled } from "util/index";
import { isPermitted } from "components/HasPermission";
import { handleRequestMonitoringMode } from "pages/Dashboard/dashboardUtils";
import Circle from "assets/NewDashboardIcons/circle.svg";
import useStyles from "../../style";

const Transactions = ({
  transactionsCompleted,
  exceptionsCounts,
  isLoading,
}) => {
  const { t } = useTranslation();
  const [percentage, setPercentage] = useState(0);
  const classes = useStyles({ translationX: translationX(percentage), translationY: translationY(percentage) })();
  const [transactionsInProgress, setTransactionsInProgress] = useState();
  const isTransactionsInProgressLoading = useSelector((state) => getQuery(state, { type: "FETCH_DASHBOARD_PENDING_ITEMS_COUNT" }).loading);
  const dispatch = useDispatch();
  const currentUser = useQuery({ type: FETCH_CURRENT_USER })?.data;
  const filterDashboard = useSelector(
        ({ dashboardFilters }) => dashboardFilters,
    );
  const isRiyadBankEnvEnabled = isEnvParamEnabled("REACT_APP_DISPLAY_RIYAD_BANK_LOGO");
  const fetchTransactionsInProgress = () => {
    dispatch(
        fetchDashboardPendingItemsCount(filterDashboard, (res) => {
            setTransactionsInProgress(res?.data);
        }),
    );
  };
  const totalCount = transactionsCompleted + (exceptionsCounts?.businessExceptions ?? 0) + (exceptionsCounts?.systemExceptions ?? 0) + (isRiyadBankEnvEnabled ? transactionsInProgress : 0);

  const fetchIntervalRef = useRef(null);
  useEffect(() => {
    handleRequestMonitoringMode(MONITORING_TIME, fetchIntervalRef, fetchTransactionsInProgress)
    return () => {
        clearInterval(fetchIntervalRef.current)
    }
  }, [filterDashboard])

  useEffect(() => {
    if (filterDashboard?.selectedDurationValue === "DAY" && isRiyadBankEnvEnabled) {
        fetchTransactionsInProgress();
    }
  }, [filterDashboard]);

  useEffect(() => {
    if (transactionsCompleted + transactionsInProgress) {
      setPercentage((transactionsCompleted / totalCount) * 100)
    }
  }, [transactionsInProgress, transactionsCompleted]);

  const Component = () => (
    <Grid container item alignItems="center" direction="column" spacing={2}>
      <Grid item className={classes.chartContainer}>
        <Doughnut data={DOUGHNUT_CHART_DATASET_DATA(percentage)} options={DOUGHNUT_CHART_DATASET_OPTION} />
        <img src={Circle} alt="circle" className={classes.circleImage} />
      </Grid>
      <Grid
        container
        item
        direction="column"
        alignItems="center"
        className={classes.centeredContent}
        xs={2}
      >
        <Typography className={classes.textBold}>
          {renderValue({
            value: totalCount,
          })}
        </Typography>
        <Typography className={classes.textLight}>
          {isRiyadBankEnvEnabled ? t("transactions.header.label") : t("items.header.label")}
        </Typography>
      </Grid>
      {isRiyadBankEnvEnabled && (
        <Grid container item justify="space-between" alignItems="center">
          <Grid item>
            <Typography className={classes.textRegular}>
              {t("inProgress.transactions.status")}
            </Typography>
          </Grid>
          <Grid item>
            <Chip label={renderValue({ value: transactionsInProgress })} className={clsx(classes.chip, classes.defaultChip)} />
          </Grid>
        </Grid>
      )}
      <Grid container item justify="space-between" alignItems="center">
        <Grid item>
          <Typography className={classes.textRegular}>
            {t("completed.transactions.status")}
          </Typography>
        </Grid>
        <Grid item>
          <Chip label={renderValue({ value: transactionsCompleted })} className={clsx(classes.chip, classes.darkChip)} />
        </Grid>
      </Grid>
      {isPermitted(currentUser, "System exceptions") && (
        <Grid container item justify="space-between" alignItems="center">
          <Grid item>
            <Typography className={classes.textRegular}>
              {t("System exceptions")}
            </Typography>
          </Grid>
          <Grid item>
            <Chip
label={renderValue({ value: exceptionsCounts?.systemExceptions })}
                          className={clsx(classes.chip, classes.fushiaChip)} />
          </Grid>
        </Grid>
        )}
      {isPermitted(currentUser, "Business exceptions") && (
        <Grid container item justify="space-between" alignItems="center">
          <Grid item>
            <Typography className={classes.textRegular}>
              {t("Business exceptions")}
            </Typography>
          </Grid>
          <Grid item>
            <Chip
label={renderValue({ value: exceptionsCounts?.businessExceptions })}
                          className={clsx(classes.chip, classes.fushiaChip)} />
          </Grid>
        </Grid>
        )}
    </Grid>
  );

  return (
    <Box className={classes.cardContainer}>
      <CardHeader
        title={isRiyadBankEnvEnabled ? t("transactions.header.label") : t("items.header.label")}
        classes={{
          title: classes.cardHeaderTitle,
        }}
      />
      <CardContent>
        { renderContent(isLoading || isTransactionsInProgressLoading, [transactionsCompleted, exceptionsCounts?.businessExceptions, exceptionsCounts?.systemExceptions, ...isRiyadBankEnvEnabled ? [transactionsInProgress] : []], <Component />) }
      </CardContent>
    </Box>
  );
};

export default Transactions;
