import React from "react";
import Grid from "@material-ui/core/Grid";
import { Card } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Duration } from "util/constants";
import { formtNumberByLanguage, round } from "../../../util";
import { useSelector } from "react-redux";
import ExceptionsProcesses from "./components/ExceptionsProcesses";
import ExceptionsTrend from "./components/ExceptionsTrend";
import ExceptionsTypes from "./components/ExceptionsTypes";
import ExceptionsReasons from "./components/ExceptionsReasons";
import CircularLoader from "../../../components/Loaders/CircularLoader";
import useStyles from "./style";

function Analytics({ kpiData, containerRef, analyticsLoading }) {
  const classes = useStyles();
  const { t } = useTranslation();

  const [dimensions, setDimensions] = React.useState({ width: 0, height: 0 });

  React.useEffect(() => {
    // eslint-disable-next-line no-unsafe-optional-chaining
    const width = containerRef?.current?.offsetWidth / 6;
    // width == height to preservce dimension and graph quality
    setDimensions({
      width,
      height: width,
    });
  }, []);

  const legendOpts = {
    display: false,
    //   align: "start",
    //   position: "top",
  };

  const layout = {
    padding: {
      left: 0,
      right: 0,
      top: 0,
      bottom: 30
    }
  };

  // eslint-disable-next-line consistent-return
  const totalExceptionPer = (tooltipItem, kpi, unit) => {
    switch (unit) {
      case Duration.DAY:
        let total = 0;
        // eslint-disable-next-line no-unused-expressions
        kpi?.datasets?.map(
          // eslint-disable-next-line no-return-assign, no-unsafe-optional-chaining
          (d) => total += d?.data[tooltipItem.index],
        );
        if (typeof total === "object") return total.data[tooltipItem.index];
        return total;
      case Duration.ALL_TIME:
        return kpi?.datasets[tooltipItem.datasetIndex].data.reduce(
          (prev, curr) => prev + curr,
        );
      default:
        break;
    }
  };

  const formatExceptionGraphNumbers = (tooltipItem, kpi) => `${formtNumberByLanguage(
    kpi?.datasets[tooltipItem.datasetIndex].data[tooltipItem?.index],
  )} ${t("exceptions")}`;
  const getExceptionPercentage = (
    tooltipItem,
    kpi,
    unit = Duration.ALL_TIME,
  ) => {
    const totalException = totalExceptionPer(tooltipItem, kpi, unit);
    const exceptionValue = kpi?.datasets[tooltipItem.datasetIndex].data[tooltipItem?.index];
    let exceptionPercentage = (exceptionValue * 100) / totalException;
    exceptionPercentage = round(exceptionPercentage, 2);
    return `${formtNumberByLanguage(exceptionPercentage)}%`;
  };

  const filterExceptionList = useSelector(
      ({ filterListException }) => filterListException,
  );

  return (
    kpiData && (
      <Grid container xs={12} direction="row" spacing={2} key={`${filterExceptionList}`}>
        {(kpiData?.exceptionProcessesAnalytics || analyticsLoading.exceptionProcessesAnalyticsLoading) && (
        <Grid item xs={3}>
          <Card elevation={1} className={classes.card}>
            { analyticsLoading.exceptionProcessesAnalyticsLoading ? (
              <CircularLoader height="100%" />
            )
            : (
              <ExceptionsProcesses
                key={`exceptionprocess-${dimensions.width}${kpiData?.exceptionProcessesAnalytics}`}
                data={kpiData?.exceptionProcessesAnalytics ?? null}
                classes={classes}
                t={t}
                width={dimensions?.width}
                height={dimensions?.height}
                legendOpts={legendOpts}
                layout={layout}
                formatExceptionGraphNumbers={formatExceptionGraphNumbers}
                getExceptionPercentage={getExceptionPercentage}
            />
            )}
          </Card>
        </Grid>
        )}
        {(kpiData?.exceptionReasonsAnalytics || analyticsLoading.exceptionReasonsAnalyticsLoading) && (
        <Grid item xs={3}>
          <Card elevation={1} className={classes.card}>
            {analyticsLoading.exceptionReasonsAnalyticsLoading ? (
              <CircularLoader height="100%" />
            )
            : (
              <ExceptionsReasons
                key={`exceptionreasions-${dimensions.width}${kpiData?.exceptionReasonsAnalytics}`}
                data={kpiData?.exceptionReasonsAnalytics ?? null}
                classes={classes}
                t={t}
                width={dimensions?.width}
                height={dimensions?.height}
                legendOpts={legendOpts}
                formatExceptionGraphNumbers={formatExceptionGraphNumbers}
                getExceptionPercentage={getExceptionPercentage}
            />
            )}
          </Card>
        </Grid>
        )}
        {(kpiData?.exceptionTypeAnalytics || analyticsLoading.exceptionTypeAnalyticsLoading) && (
        <Grid item xs={3}>
          <Card elevation={1} className={classes.card}>
            {analyticsLoading.exceptionTypeAnalyticsLoading ? (
              <CircularLoader height="100%" />
                )
                : (
                  <ExceptionsTypes
                key={`exceptiontype-${dimensions.width}${kpiData?.exceptionTypeAnalytics}`}
                data={kpiData?.exceptionTypeAnalytics ?? null}
                classes={classes}
                t={t}
                width={dimensions?.width}
                height={dimensions?.height}
                legendOpts={legendOpts}
                layout={layout}
                formatExceptionGraphNumbers={formatExceptionGraphNumbers}
                getExceptionPercentage={getExceptionPercentage}
            />)}
          </Card>
        </Grid>
        )}
        {(kpiData?.exceptionTrendsAnalytics || analyticsLoading.exceptionTrendsAnalyticsLoading) && (
        <Grid item xs={3}>
          <Card elevation={1} className={classes.card}>
            {analyticsLoading.exceptionTrendsAnalyticsLoading ? (
              <CircularLoader height="100%" />
                )
                : (
                  <ExceptionsTrend
                key={`exceptiontred-${dimensions.width}${JSON.stringify(kpiData?.exceptionTrendsAnalytics)}`}
                data={kpiData?.exceptionTrendsAnalytics ?? null}
                classes={classes}
                t={t}
                width={dimensions?.width}
                height={dimensions?.height}
                legendOpts={legendOpts}
                layout={layout}
                formatExceptionGraphNumbers={formatExceptionGraphNumbers}
                getExceptionPercentage={getExceptionPercentage}
            />
            )}
          </Card>
        </Grid>
        )}
      </Grid>
    )
  );
}

export default Analytics;
