import { FETCH_FILTER_PROCESSES, FETCH_FILTER_ROBOTS, FETCH_PLANNER_FILTER_ROBOTS } from "../../../constants";
import { URL_PROCESSES, URL_ROBOT } from "../../../constants/endpoints";

export const fetchFilterProcesses = (fleetId) => ({
    type: FETCH_FILTER_PROCESSES,
    request: {
        url: `${URL_PROCESSES}filter`,
        params: {
            fleetId
        }
    },
});

export const fetchFilterRobots = (processesIds) => ({
    type: FETCH_FILTER_ROBOTS,
    request: {
        url: `${URL_ROBOT}filter`,
        params: {
            processesIds: processesIds?.join(","),
        }
    },
});

export const fetchPlannerFilterRobots = (processesIds) => ({
  type: FETCH_PLANNER_FILTER_ROBOTS,
  request: {
      url: `${URL_ROBOT}filter/planner`,
      params: {
          processesIds: processesIds?.join(","),
      }
  },
});
