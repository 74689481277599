import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  root: {
    display: "flex",
    marginBottom: "1%",
  },
  content: {
    display: "flex",
    flex: "0 0 78%",
    flexDirection: "column",
  },
  viewClickContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  userAvatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  rowActions: {
    flex: "0 0 22%",
    justifyContent: "flex-end",
    padding: theme.spacing(2, 3),
  },
  rowActionButtons: {
    padding: theme.spacing(1, 1),
  },
  menuItemContainer: {
    padding: theme.spacing(1),
  },
  menuItemDivider: {
    width: "100%",
  },
  permissionContainer: {
    padding: theme.spacing(0, 1),
  },
  templateLink: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  showIcon: {
    color: theme.custom.color.color1,
  },
  instanceOwnerTag: {
    color: theme.custom.color.alertDefaultIconColor
  },
  archiveColor: {
    color: "blue"
  },
  permissionsContainer: {
    maxHeight: 500
  },
  permissionsForm: {
    padding: "10px 0 0 10px",
  },
  templateFormat: {
    padding: "0px 5px 0px 0px"
  }
}));
