import { makeStyles } from "@material-ui/core/styles";
import { fade } from "@material-ui/core/styles/colorManipulator";

export default makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: fade(theme.palette.background.default, 0.8),
    overflowX: "hidden",
    overflowY: "hidden",
    // maxWidth: '60%'
  },

  tabSize: {
    width: "500px",
    minWidth: theme.spacing(45),
    // minHeight: theme.spacing(80),
  },
  image: {
    width: 75,
    height: 75,
    bottom: theme.spacing(3),
    padding: theme.spacing(1),
  },
  iconsContainer: {
    margin: theme.spacing(0, 0, 2, 0),
    width: "100%"
  },
  iconsListHeader: {
    marginLeft: "20px"
  },
  dialogIconsContainer: {
    display: "flex",
    justifyContent: "center",
  },
  closeBtnIcon: {
    color: theme.custom.color.color6,
    marginTop: 5
  },
  iconsList: {
    maxHeight: "500px",
    overflow: "hidden"
  },
  dialogTitle: {
    padding: "0 24px"
  },
  dialogActions: {
    display: "flex",
    justifyContent: "space-between",
    padding: theme.spacing(2, 4),
  },
  selected: {
    backgroundColor: theme.custom.color.color7,
    "&:hover": {
      backgroundColor: theme.custom.color.color7,
    },
  }
}));
