import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  root: {
    backgroundColor: "white",
    width: "100%",
  },
  toolbar: {
    paddingRight: 24,
    justifyContent: "space-between",
    height: 55,
    minHeight: 50
  },
  leftToolbar: theme.mixins.toolbar,
  appBar: {
    marginLeft: theme.spacing(8),
    width: `calc(100% - ${theme.spacing(8)}px)`,
    boxShadow:
        "14px 6px 35px -23px rgba(123, 176, 255, 0.43)",
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: theme.custom.variables.menuLeftWidth,
    width: `calc(100% - ${theme.custom.variables.menuLeftWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  withourAppBar: {
    width: "100%",
    margin: 0,
  },
  logo: {
    width: 150,
    cursor: "pointer"
  },
  riyadbanklogo: {
    width: 110,
  },
  search: {
    position: "relative",
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(1),
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1,
    top: -2,
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "30ch",
    },
  },
  switcher: {
    position: "absolute",
    left: "50%",
    transform: "translateX(-50%)",
  },
  centerBox: {
    textAlign: "center",
  },

  alertLow: {
    backgroundColor: "#0da8ad",
    color: theme.palette.common.white,
  },
  alertMeduim: {
    backgroundColor: "#FF9800",
    color: theme.palette.common.white,
  },
  alertHigh: {
    backgroundColor: theme.custom.color.color6,
    color: theme.palette.common.white,
  },
  popupItems: {
    margin: "5px 10px",
    width: "120px",
    textAlign: "center",
  },
  purple: {
    height: 18,
    width: 18
  },
  headerCardWrapper: {
    width: "50%",
  },
  headerCard: {
    "&:not(:last-child):after": {
      content: "\"\"",
      width: "1px",
      height: "80%",
      backgroundColor: "rgba(0, 0, 0, 0.12)",
    },
  },
  darkHeaderCard: {
    color: "#FFFFFF",
    "&:not(:last-child):after": {
      backgroundColor: "#FFFFFF",
    },
  },
  dark: {
    background: theme.palette.secondary.main,
  },
  darkDivider: {
    height: 25,
    width: 2,
    backgroundColor: theme.custom.color.chartColor4,
    margin: "auto",
    opacity: 0.4,
  },
  darkIconBtn: {
    color: "#FFFFFF",
  },
  infoIcon: {
    fontSize: "0.825rem",
    marginLeft: theme.spacing(0.2),
  },
  "@keyframes badgeAnimation": {
    "0%": {
      transform: "scale(1) translate(50%, -50%)",
    },
    "50%": {
      transform: "scale(1.2) translate(50%, -50%)"
    },
    "100%": {
      transform: "scale(1) translate(50%, -50%)"
    },
  },
  badgeStyled: {
    backgroundColor: theme.custom.color.chartColor3,
    color: theme.palette.common.white,
    animation: "$badgeAnimation 1.7s ease-in-out infinite",
  },
  iconsButtons: {
    padding: 6
  },
  exceptionBadge: {
    fontSize: 9,
    padding: "0 5px"
  }
}));
