// eslint-disable-next-line import/no-anonymous-default-export
export default {
  "dashboar.refresh": "Aktualisieren",
  "dashboard.refresh.desc": "Alle {{time}} {{timeUnit}}",
  "All rights reserved": "Alle Rechte vorbehalten.",
  Dashboard: "Leistungsdashboard",
  Planner: "Ressourcenplaner",
  "Resource Planner": "Ressourcen-planer",
  "Business management": "Unternehmensführung",
  Orders: "Bestellungen",
  Invoices: "Rechnungen",
  Regulations: "Zahlungen",
  Suppliers: "Lieferanten",
  Rapports: "Berichte",
  Search: "Suche",
  "Add your Invoice": "Rechnung hinzufügen oder ",
  browse: "durchsuchen",
  "Show all": "Alle anzeigen",
  Supplier: "Lieferant",
  "Invoice No.": "Rechnungsnummer",
  "restriction.form.estimationExecutaion.required.error":
    "Bitte geben Sie eine Ausführungszeit ein",
  "restriction.form.allowedSession":
    "Anzahl der erlaubten parallelen Ausführungen",
  "restriction.form.to.error": "Datum muss größer sein als ",
  "restriction.form.allDays": "Alle Tage",
  "restriction.form.MinimumExecutionFrequency":
    "Minimale Ausführungshäufigkeit",
  "user has exception item": "Dieses Benutzerkonto hat Ausnahmen zugewiesen.",
  "Unexpected Error": "Unerwarteter Fehler",
  "robot.hidden.success": "Roboter {{status}} erfolgreich",
  hidden: "versteckt",
  unhidden: "sichtbar",
  "user deleted successfully": "Benutzer wurde erfolgreich gelöscht",
  "users not deleted": "{{usersNumber}} Benutzer konnten nicht gelöscht werden, da ihnen Ausnahmen zugewiesen sind.",
  "fleet deleted successfully": "Kunde wurde erfolgreich gelöscht",
  "process.filter.fleet": "Kunde",
  "orchestrator deleted successfully":
    "Orchestrator wurde erfolgreich gelöscht",
  "queue deleted successfully": "SR-Warteschlange wurde erfolgreich gelöscht",
  Mon: "Mo",
  Tue: "Di",
  Wed: "Mi",
  Thu: "Do",
  Fri: "Fr",
  Sat: "Sa",
  Sun: "So",
  "restriction.form.numberInteger.error":
    "Bitte geben Sie eine gültige Ganzzahl ein",
  "restriction.form.minValue.error": "Wert muss größer oder gleich 0 sein.",
  "restriction.form.maxValue.error":
    "Anzahl der Stellen muss kleiner oder gleich 9 sein.",
  "restriction.form.frequency": "Häufigkeit",
  "restriction.form.occurence": "Auftreten",
  "restriction.form.priority.required.error": "Bitte geben Sie eine Zahl ein",
  "restriction.form.priority.minValue.error":
    "Bitte geben Sie einen Wert größer oder gleich 1 ein",
  "restriction.form.priority.maxValue.error":
    "Bitte geben Sie einen Wert kleiner oder gleich 100 ein",
  "Release date": "Ausgabedatum",
  "Due date": "Fälligkeitsdatum",
  "Price WT": "Betrag vor Steuern",
  "Taxe Rate": "MwSt.-Satz",
  Price: "Betrag inkl. Steuern",
  "Last actions": "Letzte Aktionen",
  Today: "Heute",
  Yesterday: "Gestern",
  "Last week": "Letzte Woche",
  "Estimated Execution":
    "Basierend auf den Auswahlkriterien beträgt die geschätzte Ausführungszeit für diesen Prozess ",
  "Use Historical data": "Historische Daten verwenden?",
  "Update using historical data": "Mit historischen Daten aktualisieren",
  "Choose the period":
    "Wählen Sie den Zeitraum aus, der bei der Berechnung der geschätzten Ausführungszeit für diesen Prozess berücksichtigt werden soll:",
  Status: "Status",
  "Execution.time": "Ausführungszeit",
  "Default Alerts": "Standardbenach-richtigungen",
  "Choose frequency": "Frequenz auswählen",
  "View default alerts": "Standardbenach-richtigungen anzeigen",
  "Edit default alerts": "Standardbenach-richtigungen bearbeiten",
  "Delete default alert": "Standardbenach-richtigung löschen",
  "Add default alert": "Standardbenach-richtigung hinzufügen",
  "Robot Administration": "Roboter-Verwaltung",
  "Manage robot": "Roboter verwalten",
  "Custom Alerts": "Benutzerdefinierte Benachrichtigungen",
  "View custom alerts": "Benutzerdefinierte Benachrichtigungen anzeigen",
  "Edit custom alerts": "Benutzerdefinierte Benachrichtigungen bearbeiten",
  "Delete custom alert": "Benutzerdefinierte Benachrichtigung löschen",
  "Add custom alert": "Benutzerdefinierte Benachrichtigung hinzufügen",
  Date: "Datum",
  "My tasks": "Meine Rechnungen",
  "Invoices to verify": "Zu überprüfen",
  "Invoices to be checked": "Zu überprüfen",
  "Invoices to Payers": "Zur Zahlung bereit",
  "Paid invoices": "Bezahlte Rechnungen",
  "Unpaid invoices": "Unbezahlte Rechnungen",
  "Total amount of invoices": "Gesamtbetrag der Rechnungen",
  "Total VAT amount": "Gesamtbetrag der Mehrwertsteuer",
  "Statistical overview of purchases": "Rechnungshistorie",
  "Statistical overview of product sales":
    "Statistische Übersicht der Produktverkäufe",
  "Total Purchases": "Gesamtbestellungen",
  "Total of expenses": "Gesamtzahl der Rechnungen",
  Late: "Überfällig",
  "restriction.form.resources": "Robots",
  "restriction.form.priority": "Priorität",
  Day: "Tag",
  Week: "Woche",
  "3 DAYS": "3 Tage",
  "restriction.form.priority.label": "Priorität Ihres Prozees",
  "restriction.form.executionTimeLine": "Zulässige Ausführungszeit",
  "restriction.form.executionDay": "Ausführungstag",
  "restriction.form.executionTime": "Ausführungszeit",
  "restriction.form.from": "Von",
  "restriction.form.to": "Bis",
  "restriction.form.estimatedExecutionDuration": "Geschätzte Ausführungsdauer",
  "restriction.form.estimatedTime": "Geschätzte Zeit",
  "restriction.userGuide.resources":
    "Gibt an, auf welchen Maschinen (Robots) dieser Prozess ausgeführt werden kann. Um geeignete Roboter hinzuzufügen, kontaktieren Sie bitte Ihr RPA-Team.",
  "restriction.userGuide.priority":
    "Wenn alle SLAs gleich sind, wird der Prozess mit der höchsten Priorität (der niedrigsten Zahl) zuerst ausgeführt.",
  "sla.update.success": "SLAs erfolgreich aktualisiert",
  "restriction.userGuide.executionTimeLine":
    "Wählen Sie die Tage und Zeiten aus, an denen dieser Prozess ausgeführt werden darf.",
  "restriction.userGuide.estimatedExecutionTime":
    "Die durchschnittliche Zeit, die für die Ausführung dieses Prozesses benötigt wird.",
  "restriction.userGuide.allowedSession":
    "Die Anzahl der parallelen Ausführungen, die für diesen Prozess erlaubt sind, sofern genügend Lizenzen und Maschinen zum Ausführen vorhanden sind.",
  "restriction.userguide.frequencyOccurence":
    "Die minimale Ausführungshäufigkeit, die für den Prozess erforderlich ist.",
  "sla.update.error": "Fehler beim Aktualisieren der SLAs.",
  Administrator: "Administrator",
  "administration.status": "Status",
  "administration.company": "Unternehmen",
  "administration.userId": "Benutzer-ID",
  "administration.name": "Nutzername",
  "administration.creationDate": "Erstellungsdatum",
  "administration.role": "Rolle",
  "administration.email": "E-Mail",
  "administration.phone": "Telefon",
  "administration.departement": "Abteilung",
  "administration.subscription": "Abonnement",
  "administration.activate.deactivate.user.message":
    "Sind Sie sicher, dass Sie diesen Benutzer aktivieren/deaktivieren möchten?",
  "administration.orchestrator.robot.list.not.available":
    "Es sind keine Roboter verfügbar",
  "administration.orchestrator.process.list.not.available":
    "Es sind keine Prozesse verfügbar",
  "user.add.civility": "Anrede",
  "user.add.lastName": "Nachname",
  "user.add.firstName": "Vorname",
  "user.add.creationDate": "Erstellungsdatum",
  "user.add.entreprise": "Unternehmen",
  "user.add.subscription": "Abonnement",
  "user.add.email": "E-Mail",
  "user.add.pass1": "Passwort",
  "user.add.pass2": "Passwort wiederholen",
  "user.add.comm.lang": "Kommunikationssprache",
  "user.add.comm.lang.helperText":
    "Alle Kommunikationen und E-Mails von SmartRoby werden in der ausgewählten Sprache verschickt.",
  "New suppliers": "Neue Lieferanten",
  "user.add.process": "Prozess",
  "user.add.availableProcess": "Verfügbare Prozesse",
  "user.add.affectedProcess": "Betroffene Prozesse",
  "user.add.button": "BENUTZER HINZUFÜGEN",
  "user.add.mfa": "OTP erforderlich",
  "user.add.division.admin": "Abteilungsadministrator",
  "user.add.fleet.admin": "Fleet-Administrator",
  "user.add.division": "Abteilung",
  "user.status.active": "Aktiviert",
  "user.modify": "Bearbeiten",
  "process.modify": "Bearbeiten",
  "process.hidden": "Versteckt",
  "user.add": "Hinzufügen",
  "user.button.save": "Benutzer hinzufügen",
  "user.button.update": "Aktualisieren",
  "user.button.cancel": "Abbrechen",
  "user.add.processus": "Prozess",
  "user.status.disable": "Deaktiviert",
  "user.title.add": "Benutzer hinzufügen",
  "user.title.details": "Benutzerdetails",
  "user.save.confirmMsg":
    "Sind Sie sicher, dass Sie diesen Benutzer hinzufügen möchten?",
  "user.update.confirmMsg":
    "Sind Sie sicher, dass Sie die Details dieses Benutzers aktualisieren möchten?",
  "user.delete.confirmMsg":
    "Sind Sie sicher, dass Sie diesen Benutzer löschen möchten?",
  "user.delete.discard":
    "Sind Sie sicher, dass Sie diese Änderungen verwerfen möchten?",
  "orchestrator.button.save": "Orchestrator hinzufügen",
  orchestrator: "Orchestrator",
  "orchestrator.save.confirmMsg":
    "Sind Sie sicher, dass Sie diesen Orchestrator hinzufügen möchten?",
  "orchestrator.update.confirmMsg":
    "Sind Sie sicher, dass Sie die Details dieses Orchestrators aktualisieren möchten?",
  "exception.type": "Ausnahmeart",
  "exception.time": "Ausnahmezeit",
  Mr: "Herr",
  Mrs: "Frau",
  Ms: "Frau",
  "tooltip.action.delete": "Löschen",
  "tooltip.action.edit": "Bearbeiten",
  "tooltip.action.view": "Anzeigen",
  "tooltip.action.add": "Hinzufügen",
  "Visualise Scheduler": "Zeitplan visualisieren",
  firstName: "Vorname",
  lastName: "Nachname",
  companyName: "Unternehmen",
  "720 hours/year": "720 Stunden/Jahr",
  "1800 hours/year": "1800 Stunden/Jahr",
  "60 days/year": "60 Tage/Jahr",
  "120 days/year": "120 Tage/Jahr",
  "3 months/year": "3 Monate/Jahr",
  "5 months/year": "5 Monate/Jahr",
  "user.roles.admin": "Administrator",
  "user.roles.user": "Benutzer",
  "sorted descending": "absteigend sortiert",
  "sorted ascending": "aufsteigend sortiert",
  Invoicing: "Rechnungsstellung",
  Alerts: "Benachrichtigungen",
  "User Management": "Benutzerverwaltung",
  "Audit Logs": "Audit-Protokolle",
  Documentation: "Dokumentation",
  Services: "Dienstleistungen",
  "Carte d'identité nationale": "Personalausweis",
  "Driving license": "Führerschein",
  Passport: "Reisepass",
  "Green card": "Grüne Karte",
  "Car registration papers": "Kfz-Zulassungspapiere",
  Facture: "Rechnung",
  Invoice: "Rechnung",
  RIB: "Bankkontoauszug",
  "Purchase order": "Bestellung",
  "Payroll statemenet": "Gehaltsabrechnung",
  "Avis d'imposition": "Steuerbescheid",
  "Trigger Details": "Auslöserdetails",
  "Start Process": "Prozess starten",
  "Stop Process": "Prozess stoppen",
  Schedule: "Zeitplan",
  Prioritize: "Priorisieren",
  Sheet: "Blatt",
  Parameters: "Parameter",
  "Processes launcher": "Prozessstarter",
  "Drop process": "Hier ablegen, um den Prozess zu starten",
  "Time saved": "Gesparte Zeit",
  "Total time saved": "Insgesamt gesparte Zeit",
  "Process overview": "Ausführungszeit nach Prozess",
  "Robot overview": "Ausführungszeit nach Roboter",
  "Number of executions": "Anzahl der Ausführungen",
  "Latest Transcriptions": "Aktuellste Transkriptionen",
  "Break-even": "Break-even",
  "Return on Investment": "Rentabilitätsverhältnis",
  "Analyze a new file": "Eine neue Datei analysieren",
  Receipt: "Beleg",
  "EDF proof": "EDF-Nachweis",
  "Click here or drop a file to download":
    "Klicken Sie hier oder ziehen Sie eine Datei zum Downloaden",
  "Loaded File": "Geladene Datei",
  "File preview": "Dateivorschau",
  Informations: "Informationen",
  "Document name": "Dokumentenname",
  "File content type": "Dateityp",
  userId: "Benutzer-ID",
  "First name": "Vorname",
  "Last name": "Nachname",
  CIN: "Personalausweisnummer",
  "Date of Birth": "Geburtsdatum",
  "Place of birth": "Geburtsort",
  "Expiration date": "Ablaufdatum",
  Address: "Adresse",
  Gender: "Geschlecht",
  CivilStatusNo: "Familienstand",
  "Use of APIs": "API-Nutzung",
  "under construction":
    "Dieser Abschnitt befindet sich im Aufbau. Unser Team arbeitet daran. Bleiben Sie dran...",
  Overview: "Übersicht",
  Historical: "Historisch",
  "General Information": "Allgemeine Informationen",
  "Schedule Information": "Zeitplaninformationen",
  Description: "Beschreibung",
  processOriginalName: "Ursprünglicher Prozessname",
  Priority: "Priorität",
  "Start date": "Startdatum",
  "End date": "Enddatum",
  Cron: "Cron",
  "FTE Time to Process": "FTE-Zeit für die Verarbeitung",
  "FTE Cost": "FTE-Kosten",
  "Process cost": "Prozesskosten",
  "add.schedule": "Zeitplan erstellen",
  "add.process": "Prozess hinzufügen",
  Reset: "Zurücksetzen",
  Update: "Aktualisieren",
  Save: "Speichern",
  Cancel: "Abbrechen",
  "Are you sure delete this row ?":
    "Sind Sie sicher, dass Sie diese Zeile löschen möchten?",
  "Field must not be empty": "Feld darf nicht leer sein",
  "This process was started successfully":
    "Dieser Prozess wurde erfolgreich gestartet",
  "The process has been added to the SR Queues.":
    "Der angeforderte Prozess wurde zur SR-Warteschlange hinzugefügt.",
  "An execution trigerred manually on the same robot has already been requested for this process": "Eine manuell ausgelöste Ausführung auf demselben Roboter wurde für diesen Prozess bereits angefordert",
  "The process start failed! Please try again later":
    "Der Prozessstart ist fehlgeschlagen! Bitte versuchen Sie es später erneut",
  ERRORS_EMPTY_FIELD: "Das Feld darf nicht leer sein.",
  ERRORS_INVALID_DATA: "Bitte geben Sie gültige Daten ein.",
  ERRORS_NO_DATA: "Keine Daten verfügbar!",
  "Start date must be less than the end date":
    "Das Startdatum muss vor dem Enddatum liegen",
  "End date must be greater than the start date":
    "Das Enddatum muss nach dem Startdatum liegen",
  "Start time": "Startzeit",
  "End time": "Endzeit",
  "How much time does it take an employee to execute this process?":
    "Wie viel Zeit benötigt ein Mitarbeiter, um diesen Prozess auszuführen?",
  "How much does the hour cost for an employee executing this task?":
    "Wie viel kostet die Stunde für einen Mitarbeiter, der diese Aufgabe ausführt?",
  "How much does this process cost per month ?":
    "Wie viel kostet dieser Prozess pro Monat?",
  Recurrence: "Wiederholung",
  Previous: "Vorherige",
  "Enter your mail": "Geben Sie Ihre E-Mail ein",
  "Confirm your mail": "Bestätigen Sie Ihre E-Mail",
  "Enter your password": "Geben Sie Ihr Passwort ein",
  Login: "Anmelden",
  "Sign up to SmartRoby": "Registrieren Sie sich bei SmartRoby",
  "Go to SmartRoby": "Gehe zu SmartRoby",
  "Registration was successfull, you will be redirected":
    "Die Registrierung war erfolgreich, Sie werden weitergeleitet",
  "Registration failed": "Registrierung fehlgeschlagen",
  Close: "Schließen",
  "Aws registration token missing": "AWS-Registrierungstoken fehlt",
  "Email not matching": "E-Mail stimmt nicht überein",
  Register: "Registrieren",
  "You are already signed up": "Sie haben sich bereits angemeldet",
  "Forgot your password ?": "Passwort vergessen?",
  "Login or password incorrect !": "Anmeldung oder Passwort inkorrekt!",
  "Copyright © Smart-Roby All rights reserved.":
    "Urheberrecht © Smart-Roby. Alle Rechte vorbehalten.",
  English: "Englisch",
  French: "Französisch",
  Spanish: "Spanisch",
  Polish: "Polnisch",
  Germany: "Deutschland",
  "Process executions": "Prozessausführungen",
  Ready: "Bereit",
  "Executed by": "Ausgeführt von",
  "you want to modify this data ?": "Möchten Sie diese Daten wirklich ändern?",
  Yes: "Ja",
  No: "Nein",
  "Data saved with success": "Daten erfolgreich gespeichert",
  "Data not saved": "Daten nicht gespeichert",
  "Hour(s)": "Stunde(n)",
  "Day(s)": "Tag(e)",
  "Week(s)": "Woche(n)",
  "Month(s)": "Monat(e)",
  "Year(s)": "Jahr(e)",
  Every: "Alle",
  "Choose period": "Zeitraum auswählen",
  "Your input is required": "Ihre Eingabe ist erforderlich",
  "The process": "Der Prozess",
  "is running, try again when it's completed.":
    "wird ausgeführt, versuchen Sie es erneut, wenn er abgeschlossen ist.",
  "Are you sure you want to execute this process ?":
    "Sind Sie sicher, dass Sie diesen Prozess ausführen möchten?",
  Domain: "Bereich",
  "Process name": "Prozessname",
  "Process reference": "Prozessreferenz",
  "Number used of systems and applications":
    "Anzahl verwendeter Systeme und Anwendungen",
  "SAP environments (if applicable)": "SAP-Umgebungen (falls zutreffend)",
  Frequency: "Häufigkeit",
  Volume: "Volumen",
  "Execution per": "Ausführung pro",
  "Rule-based": "Regelbasiert",
  Standardization: "Standardisierung",
  "Structured data": "Strukturierte Daten",
  "IT environment": "IT-Umgebung",
  "Test enabled": "Test aktiviert",
  "Documentation Readiness": "Dokumentationsbereitschaft",
  "Authentication mode": "Authentifizierungsmodus",
  "Process UoM": "Prozess-Einheit der Maßeinheit",
  "Time spent for one execution": "Zeitaufwand für eine Ausführung",
  "Frequency of occurrence": "Häufigkeit des Auftretens",
  "Time spent": "Verbrachte Zeit",
  "Time granularity": "Zeitliche Granularität der Ausführung",
  "Occurrence time granularity": "Zeitliche Granularität des Auftretens",
  Occurrence: "Auftreten",
  "Process Exceptions": "Prozessausnahmen",
  "Item Exceptions": "Elementausnahmen",
  "Occurrence driver": "Ereignis-Treiber",
  "Total time spent in hours per year":
      "Zeit (in Stunden) pro Jahr an das Unternehmen zurückgegeben",
  "What does an execution represent?": "Wofür steht eine Ausführung?",
  "Re-usability": "Wiederverwendbarkeit",
  "Expected qualitative benefits": "Erwartete qualitative Vorteile",
  "Process Stability": "Prozessstabilität",
  "Number of steps": "Anzahl der Schritte",
  "Number of windows": "Anzahl der Fenster",
  "Business Rules": "Geschäftsregeln",
  "Business Applications": "Geschäftsanwendungen",
  "Complex functions - Unstructured data / OCR":
    "Komplexe Funktionen - Unstrukturierte Daten / OCR",
  "Complex functions - DataBase": "Komplexe Funktionen - Datenbank",
  "Complex functions - Powershell": "Komplexe Funktionen - Powershell",
  "Complex functions - Active Directory":
    "Komplexe Funktionen - Active Directory",
  "Complex functions - VB Script": "Komplexe Funktionen - VB-Skript",
  "Complex functions - Web Services": "Komplexe Funktionen - Webdienste",
  "Type of applications - Web application":
    "Art der Anwendungen - Webanwendung",
  "Type of applications - Windows application":
    "Art der Anwendungen - Windows-Anwendung",
  "Type of applications - Mainframe application":
    "Type of applications - Mainframe-Anwendung",
  "Type of applications - Citrix application":
    "Type of applications - Citrix-Anwendung",
  "Automation without RPA": "Automatisierung ohne RPA",
  "Basic documentation is available":
    "Grundlegende Dokumentation ist verfügbar",
  "Few simple applications with a unique ERP instance custom application":
    "Einige einfache Anwendungen mit einer individuellen ERP-Instanz-Anwendung",
  "No - Judgment based": "Nein - Entscheidungsgrundlage",
  "Yes - Based on objective criteria":
    "Ja - Basierend auf objektiven Kriterien",
  Accounting: "Rechnungswesen",
  Controlling: "Controlling",
  Other: "Andere",
  Others: "Andere",
  "assessment.Fully Core process compliant": "Vollständig konform mit Kernprozessen",
  "assessment.Partially Core process compliant": "Teilweise konform mit Kernprozessen",
  "assessment.Local (zone or country specific)": "Lokal (zone- oder länderspezifisch)",
  "assessment.Major": "Haupt",
  "assessment.High": "Hoch",
  "assessment.Medium": "Mittel",
  "assessment.Low": "Niedrig",
  "assessment.Minor": "Geringfügig",
  "assessment.No change is planned": "Es sind keine Änderungen geplant",
  "assessment.No change is planned in the next 2 years": "Es sind keine Änderungen in den nächsten 2 Jahren geplant",
  "assessment.Process/system may change in next 2 years": "Der Prozess/das System kann sich in den nächsten 2 Jahren ändern",
  "assessment.Process/system update planned in next 2 years": "Prozess-/Systemaktualisierung in den nächsten 2 Jahren geplant",
  "assessment.Frequent changes occur in the process": "Häufige Änderungen treten im Prozess auf",
  "assessment.1-16": "1-16",
  "assessment.17-45": "17-45",
  "assessment.46-94": "46-94",
  "assessment.More than 94": "Mehr als 94",
  "assessment.1-4": "1-4",
  "assessment.5-10": "5-10",
  "assessment.11-20": "11-20",
  "assessment.More than 20": "Mehr als 20",
  "assessment.Between 2 and 3": "Zwischen 2 und 3",
  "assessment.Between 4 and 10": "Zwischen 4 und 10",
  "assessment.More than 10": "Mehr als 10",
  "assessment.Between 4 and 5": "Zwischen 4 und 5",
  "assessment.More than 5": "Mehr als 5",
  "assessment.1": "1",
  "assessment.Basic documentation is available": "Grundlegende Dokumentation ist verfügbar",
  "assessment.Yes - Data-sets fully available to conduct multiple tests": "Ja - Datensätze vollständig verfügbar, um mehrere Tests durchzuführen",
  "assessment.No - No Data-sets available to conduct multiple tests": "Nein - Keine Datensätze verfügbar, um mehrere Tests durchzuführen",
  "assessment.A detailed documentation is available": "Eine ausführliche Dokumentation ist verfügbar",
  "assessment.A high level documentation is available": "Eine Dokumentation auf hoher Ebene ist verfügbar",
  "assessment.No documentation": "Keine Dokumentation vorhanden",
  "assessment.Standard authentication mode (ID and password)": "Standard-Authentifizierungsmodus (ID und Passwort)",
  "assessment.SSO only authentication mode": "Nur SSO-Authentifizierungsmodus",
  "assessment.Additional authentication required (e.g. USB key)": "Zusätzliche Authentifizierung erforderlich (z. B. USB-Schlüssel)",
  "assessment.Few simple applications (Excel, Outlook, …)": "Einige einfache Anwendungen (Excel, Outlook, …)",
  "assessment.Few simple applications with a unique ERP instance or another / custom application": "Einige einfache Anwendungen mit einer eindeutigen ERP-Instanz oder einer anderen / benutzerdefinierten Anwendung",
  "assessment.Few complex / custom applications or ERP instances": "Einige komplexe / benutzerdefinierte Anwendungen oder ERP-Instanzen",
  "assessment.Several complex / custom applications, and a cloud application": "Mehrere komplexe / benutzerdefinierte Anwendungen und eine Cloud-Anwendung",
  "assessment.Large number of applications, (i.e. several ERP instances)": "Große Anzahl von Anwendungen (z. B. mehrere ERP-Instanzen)",
  "assessment.Yes - Completely structured (e.g. in a form/table/spreadsheet)":
    "Ja - Vollständig strukturiert (z. B. in einem Formular / einer Tabelle / einer Tabelle)",
  "assessment.No - Completely un-structured e.g. non standardized email":
    "Nein - Vollständig unstrukturiert, z. B. nicht standardisierte E-Mail",
  "assessment.Completely optimized process, managed at Group level":
    "Vollständig optimierter Prozess, auf Gruppenebene verwaltet",
  "assessment.Largely standardized process, mostly managed at group level":
    "Überwiegend standardisierter Prozess, größtenteils auf Gruppenebene verwaltet",
  "assessment.Good level of standardisation, managed regionaly":
    "Gutes Maß an Standardisierung, regional verwaltet",
  "assessment.Disaggregated process and split responsibilities":
    "Aufgespaltener Prozess und geteilte Verantwortlichkeiten",
  "assessment.Low degree of standardization and split responsibilities":
    "Geringer Grad an Standardisierung und geteilte Verantwortlichkeiten",
  "Yes - Completely based on objective criteria":
    "Ja - Vollständig auf objektiven Kriterien basierend",
  "No - Completely judgment-based": "Nein - Vollständig auf Urteilen basierend",
  "Completely optimized process, managed at Group level":
    "Vollständig optimierter Prozess, auf Gruppenebene verwaltet",
  "Largely standardized process, mostly managed at group level":
    "Überwiegend standardisierter Prozess, größtenteils auf Gruppenebene verwaltet",
  "Good level of standardisation, managed regionaly":
    "Gutes Maß an Standardisierung, regional verwaltet",
  "Low degree of standardization and split responsibilities":
    "Geringer Grad an Standardisierung und geteilte Verantwortlichkeiten",
  "Disaggregated process and split responsibilities":
    "Aufgespaltener Prozess und geteilte Verantwortlichkeiten",
  "Yes - Completely structured (e.g. in a form/table/spreadsheet)":
    "Ja - Vollständig strukturiert (z.B. in einem Formular/Tabelle/Tabellenkalkulation)",
  "No - Completely un-structured e.g. non standardized email":
    "Nein - Vollständig unstrukturiert, z.B. nicht standardisierte E-Mail",
  "Few simple applications (Excel, Outlook, …)":
    "Einige einfache Anwendungen (Excel, Outlook, ...)",
  "Few simple applications with a unique ERP instance or another / custom application":
    "Einige einfache Anwendungen mit einer individuellen ERP-Instanz oder einer anderen/benutzerdefinierten Anwendung",
  "Few complex / custom applications or ERP instances":
    "Einige komplexe/benutzerdefinierte Anwendungen oder ERP-Instanzen",
  "Several complex / custom applications, and a cloud application":
    "Mehrere komplexe/benutzerdefinierte Anwendungen und eine Cloud-Anwendung",
  "Large number of applications, (i.e. several ERP instances)":
    "Große Anzahl von Anwendungen (z.B. mehrere ERP-Instanzen)",
  "Yes - Data-sets fully available to conduct multiple tests":
    "Ja - Datensätze vollständig verfügbar, um mehrere Tests durchzuführen",
  "No - No Data-sets available to conduct multiple tests":
    "Nein - Keine Datensätze verfügbar, um mehrere Tests durchzuführen",
  "A detailed documentation is available":
    "Eine ausführliche Dokumentation ist verfügbar",
  "A high level documentation is available":
    "Eine Dokumentation auf hohem Niveau ist verfügbar",
  "No documentation": "Keine Dokumentation",
  "Standard authentication mode (ID and password)":
    "Standard-Authentifizierungsmodus (ID und Passwort)",
  "SSO only authentication mode": "Nur SSO-Authentifizierungsmodus",
  "Additional authentication required (e.g. USB key)":
    "Zusätzliche Authentifizierung erforderlich (z.B. USB-Schlüssel)",
  Seconds: "Sekunden",
  Minutes: "Minuten",
  Minute: "Minute",
  Hours: "Stunden",
  Days: "Tage",
  "No process execution found.":
    "Es wurden keine Prozessausführungen gefunden.",
  "m/d": "m/d",
  "Fully Core process compliant": "Vollständig Core-Prozess-konform",
  "Partially Core process compliant": "Teilweise Core-Prozess-konform",
  "Local (zone or country specific)": "Lokal (zonen- oder länderspezifisch)",
  Major: "Haupt",
  High: "Hoch",
  Medium: "Mittel",
  Low: "Niedrig",
  Minor: "Geringfügig",
  "No change is planned": "Es ist keine Änderung geplant",
  "No change is planned in the next 2 years":
    "Es sind keine Änderungen in den nächsten 2 Jahren geplant",
  "Process/system may change in next 2 years":
    "Prozess/System kann sich in den nächsten 2 Jahren ändern",
  "Process/system update planned in next 2 years":
    "Prozess/Systemaktualisierung in den nächsten 2 Jahren geplant",
  "Frequent changes occur in the process": "Häufige Änderungen im Prozess",
  "1-16": "1-16",
  "17-45": "17-45",
  "46-94": "46-94",
  "More than 94": "Mehr als 94",
  "1-4": "1-4",
  "5-10": "5-10",
  "11-20": "11-20",
  "More than 20": "Mehr als 20",
  "Between 2 and 3": "Zwischen 2 und 3",
  "Between 4 and 10": "Zwischen 4 und 10",
  "More than 10": "Mehr als 10",
  "Between 4 and 5": "Zwischen 4 und 5",
  "More than 5": "Mehr als 5",
  1: "1",
  true: "wahr",
  false: "falsch",
  "planned.executions": "Geplante Ausführungen",
  robot: "Roboter",
  "planner.fullScreenExit": "Vollbildmodus beenden",
  "planner.fullScreen": "Planner im Vollbildmodus öffnen",
  "Risk management": "Risikomarge",
  "planner.risktime": "Risikozeit",
  "Latest Exceptions": "Letzte Ausnahmen",
  Process: "Prozess",
  Executing: "Ausführung",
  Planned: "Geplant",
  Paused: "Angehalten",
  "Rows Per page": "Zeilen pro Seite",
  "pagination.of": "von",
  Completed: "Abgeschlossen",
  Running: "In Ausführung",
  RUNNING: "In Ausführung",
  "How long will it take an employee to complete this process?":
    "Wie lange dauert es, bis ein Mitarbeiter diesen Prozess abschließt?",
  "How much does it cost per hour for an employee to perform this task?":
    "Wie hoch sind die Kosten pro Stunde, wenn ein Mitarbeiter diese Aufgabe ausführt?",

  unknown: "unbekannt",
  Unknown: "Unbekannt",
  UNKNOWN: "Unbekannt",
  "This week": "Diese Woche",
  "This Month": "Dieser Monat",
  "This Year": "Dieses Jahr",
  "All time": "Gesamt",
  Discard: "Verwerfen",
  "Exception Reason": "Ausnahmebegründung",
  "Exception Workflow Status": "Status",
  "Exception Assignee": "Zuständiger",
  "Show more filters": "Mehr Filter anzeigen",
  "Hide filters": "Filter ausblenden",
  "This Week": "Diese Woche",
  READY: "Bereit",
  TAKEN: "Zugewiesen",
  Name: "Name",
  createdBy: "Erstellt von",
  updatedBy: "Zuletzt geändert von",
  "Last Update ": "Letztes Update",
  "Last update": "Zuletzt geändert am",
  Feasibility: "Machbarkeit",
  Impact: "Auswirkung",
  Roi: "Rentabilit-ätsverhältnis",
  "RPA Opportunities": "RPA-Chancen",
  Accepted: "Akzeptiert",
  Rejected: "Abgelehnt",
  Pending: "Ausstehend",
  PENDING: "Ausstehend",
  DELETED: "Gelöscht",
  MISSED: "Verpasst",
  MISSED_P: "Ausstehend (Verpasst)",
  PENDING_SR: "Ausstehend SR",
  DONE: "Erledigt",
  EXCEPTION: "Ausnahme",
  "Exception Type": "Ausnahmetyp",
  "My cases": "Meine Fälle",
  "Schedule Name": "Zeitplanname",
  Period: "Zeitraum",
  Exceptions: "Ausnahmen",
  "header.kpi.exceptions": "Nicht behandelte Ausnahmen",
  Actions: "Aktionen",
  "Loaded Time": "Ladezeit",
  Reason: "Grund",
  "Exception Time": "Ausnahmezeit",
  "How much time does it take an employee to execute an Item in this process? (in minutes)":
    "Wie viel Zeit benötigt ein Mitarbeiter, um einen Punkt in diesem Prozess auszuführen? (in Minuten)",
  "How much does this Process cost to maintain per Year?":
    "Wie hoch sind die jährlichen Wartungskosten für diesen Prozess?",
  "Are you sure you want to update the parameters ?":
    "Sind Sie sicher, dass Sie die Parameter aktualisieren möchten?",
  "Are you sure you want to create this schedule ?":
    "Sind Sie sicher, dass Sie diesen Zeitplan erstellen möchten?",
  "Are you sure you want to update this schedule ?":
    "Sind Sie sicher, dass Sie diesen Zeitplan aktualisieren möchten?",
  "The schedule was created successfully":
      "Ihre Anfrage wurde an BP gesendet und der Zeitplan wird bald erstellt",
  "Failed to create the schedule! Please try again later":
    "Fehler beim Erstellen des Zeitplans! Bitte versuchen Sie es später erneut",
  "Failed to update parameters": "Fehler beim Aktualisieren der Parameter",
  "Are you sure you want to disable this schedule ?":
    "Sind Sie sicher, dass Sie diesen Zeitplan deaktivieren möchten?",
  "Are you sure you want to enable this schedule ?":
    "Sind Sie sicher, dass Sie diesen Zeitplan aktivieren möchten?",
  "button.deactivate.confirm": "Deaktivieren",
  "button.activate.confirm": "Aktivieren",
  hours: "Stunden",
  Once: "Einmalig",
  Hourly: "Stündlich",
  Daily: "Täglich",
  Weekly: "Wöchentlich",
  Monthly: "Monatlich",
  Yearly: "Jährlich",
  Trimestrial: "Vierteljährlich",
  Quarterly: "Quartalsweise",
  "Start Date": "Startdatum",
  "Are you sure you want to save the changes ?":
    "Sind Sie sicher, dass Sie die Änderungen speichern möchten?",
  "Failed to modify schedule": "Fehler beim Ändern des Zeitplans",
  "Are you sure you want to disregard the changes ?":
    "Sind Sie sicher, dass Sie die Änderungen verwerfen möchten?",
  "Changes disregarded": "Änderungen verworfen",
  "Are you sure you want to delete this exception ?":
    "Sind Sie sicher, dass Sie diese Ausnahme verbergen möchten?",
  "Are you sure you want to show this exception ?":
    "Sind Sie sicher, dass Sie diese Ausnahme anzeigen möchten?",
  "Are you sure you want to delete these exception ?":
    "Sind Sie sicher, dass Sie diese Ausnahmen verbergen möchten?",
  "Exception Deleted Successfully": "Ausnahme erfolgreich versteckt!",
  "Exception Restored Successfully": "Ausnahme erfolgreich wiederhergestellt!",
  "Exceptions Deleted Successfully": "Ausnahmen erfolgreich versteckt",
  "Are you sure you want to delete":
    "Sind Sie sicher, dass Sie verbergen möchten",
  "Are you sure you want to show X exceptions?":
    "Sind Sie sicher, dass Sie {{count}} Ausnahme(n) anzeigen möchten?",
  exceptions: "Ausnahme(n)",
  "exceptions successfully deleted": "Ausnahmen erfolgreich versteckt",
  "X exceptions successfully restored":
    "{{count}} Ausnahme(n) erfolgreich wiederhergestellt",

  // rpa qual

  Submitted: "Eingereicht",
  Draft: "Entwurf",
  draft: "ENTWURF",
  "Not submitted": "Nicht eingereicht",
  "Are you sure?": "Sind Sie sicher?",
  "You won't be able to revert this!":
    "Sie können dies nicht rückgängig machen!",
  warning: "Warnung",
  "Yes, submit it!": "Ja, einreichen!",
  "Yes, save it!": "Ja, speichern!",
  "Submit later": "Später validieren",
  Back: "Zurück",
  Result: "Ergebnis",
  "Impact/Feasibility Matrix": "Auswirkung/Machbarkeitsmatrix",
  next: "Weiter",
  "Red zone": "Rote Zone",
  "Impact and feasibility are limited":
    "Auswirkung und Machbarkeit sind begrenzt",
  "The process is likely to be reprioritized":
    "Der Prozess wird wahrscheinlich neu priorisiert",
  "Yellow zone": "Gelbe Zone",
  "Here either the impact or the feasibility is relevant":
    "Hier ist entweder die Auswirkung oder die Machbarkeit relevant",
  "Depending on the case and arbitration, the process could be deprioritized":
    "Abhängig vom Fall und der Schiedsentscheidung kann der Prozess neu priorisiert werden",
  "Green zone": "Grüne Zone",
  "Both impact and feasibility show some great signs":
    "Sowohl die Auswirkung als auch die Machbarkeit zeigen gute Anzeichen",
  "The process is likely to be prioritized":
    "Der Prozess wird wahrscheinlich priorisiert",
  "Saving (per year)": "Einsparungen (pro Jahr)",
  "FTE Gains": "FTE-Einsparungen",
  "FTE Costs": "FTE-Kosten",
  "Total in $": "Gesamt in $",
  "Development costs": "Entwicklungskosten (in Tsd./Tag)",
  Developer: "Entwickler",
  "Business analyst": "Business Analyst",
  "Running costs (per year)": "Betriebskosten (pro Jahr)",
  "Running costs in $": "Betriebskosten in $",
  "Payback period (year)": "Amortisationsdauer (Jahr)",
  "Yes, accept it": "Ja, akzeptieren",
  "Yes, reject it": "Ja, ablehnen",
  "Yes, delete it": "Ja, löschen",
  NAME: "NAME",
  "CREATED BY": "ERSTELLT VON",
  "Date created": "Erstellungsdatum",
  "LAST UPDATE": "Letztes Update",
  Edit: "Bearbeiten",
  Exit: "Beenden",
  "Home - RPA Qualification Tool By Novelis innovation":
    "Startseite - RPA Qualification Tool von Novelis Innovation",
  "No opportunities found !": "Keine Chancen gefunden!",
  "create.opportunity": "Chance Gelegenheit",
  "No cases found !": "Keine Fälle gefunden!",
  "Tool for discovering, evaluating and classifying business processes":
    "Werkzeug zur Entdeckung, Bewertung und Klassifizierung von Geschäftsprozessen",
  "Sign in": "Anmelden",
  "Invalid email address": "Ungültige E-Mail-Adresse",
  "Email Address": "E-Mail-Adresse",
  "Password is required": "Passwort ist erforderlich",
  "Password must contains at least 8 characters":
    "Passwort muss mindestens 8 Zeichen enthalten",
  Password: "Passwort",
  "No network": "Kein Netzwerk",
  "Please verify your internet connection and try again !":
    "Bitte überprüfen Sie Ihre Internetverbindung und versuchen Sie es erneut!",
  OK: "OK",
  "New process": "Neuer Prozess",
  "Page not found": "Seite nicht gefunden",
  "Sorry, the page you are looking for can't be found !":
    "Entschuldigung, die von Ihnen gesuchte Seite konnte nicht gefunden werden!",
  Reject: "Ablehnen",
  Submit: "Einreichen",
  "rpaQual.action.submit": "Validieren",
  "assessment.submit": "Einreichen",
  Accept: "Akzeptieren",
  Delete: "Löschen",
  "priority.error":
    "Der Wert existiert bereits, bitte wählen Sie einen anderen Wert.",
  "priority.error.unhide":
    "Der Prioritätswert dieses Prozesses existiert bereits. Ihr Prozess wird ohne festgelegte Priorität wieder angezeigt.",
  "rpaqual.submit": "Validieren",
  "Time period": "Zeitraum",
  "Select a Department": "Wählen Sie eine Abteilung aus",
  "name your process": "Geben Sie Ihrem Prozess einen Namen",
  "Select a robot": "Wählen Sie einen Roboter aus",
  "describe briefly your process": "Beschreiben Sie kurz Ihren Prozess",
  "What is the value that would drive a round of process executions":
    "Welchen Wert treibt eine Runde von Prozessausführungen an",

  "Select if process is rule-based":
    "Wählen Sie aus, ob der Prozess regelbasiert ist",

  "Select if process is standardized":
    "Wählen Sie aus, ob der Prozess standardisiert ist",

  "Select if data used in the process is structured":
    "Wählen Sie aus, ob die im Prozess verwendeten Daten strukturiert sind",

  "Select the sentence that fits the most with the context of your process":
    "Wählen Sie den Satz aus, der am besten zum Kontext Ihres Prozesses passt",

  "Select if testing will be possible":
    "Wählen Sie aus, ob Tests möglich sein werden",

  "the documentation level that fits the most with the context of your process":
    "das Dokumentationsniveau, das am besten zum Kontext Ihres Prozesses passt",

  "what authentication mode is applied for the systems in scope":
    "Welcher Authentifizierungsmodus wird für die betroffenen Systeme verwendet",

  "Time required for a single but complete process execution":
    "Zeit, die für eine einzelne, aber vollständige Prozessausführung erforderlich ist",

  "Time granularity of the above mentionned time":
    "Zeitgenauigkeit der oben genannten Zeit",

  "Weekly=52, Monthly=12, Daily=220.Details of your calculations in the comment section":
    "Wöchentlich=52, Monatlich=12, Täglich=220. Einzelheiten Ihrer Berechnungen im Kommentarbereich",

  "This field is populated automatically from the details time spent below":
    "Dieses Feld wird automatisch aus den unten angegebenen Details zur verbrachten Zeit ausgefüllt",

  "Assess re-usability to the best of your knowledge":
    "Bewerten Sie die Wiederverwendbarkeit nach bestem Wissen",

  "Explain what are the benefits expected, e.g. better quality":
    "Erklären Sie, welche Vorteile erwartet werden, z.B. bessere Qualität",

  "Select the number of steps": "Wählen Sie die Anzahl der Schritte aus",

  "number of screens that change during the execution":
    "Anzahl der Bildschirme, die sich während der Ausführung ändern",

  "number of the business rules governing the process":
    "Anzahl der Geschäftsregeln, die den Prozess regeln",

  "number of business applications involved in the process":
    "Anzahl der am Prozess beteiligten Geschäftsanwendungen",

  "whether unstructured data or any OCR processing will be processed":
    "ob unstrukturierte Daten oder eine OCR-Verarbeitung verarbeitet werden",

  "communication with a database to establish or any sql commands will be executed":
    "Kommunikation mit einer Datenbank zur Einrichtung oder Ausführung von SQL-Befehlen",

  "if any Powershell script to install or any powershell code to execute in the command line":
    "falls ein Powershell-Skript zur Installation oder ein Powershell-Code zur Ausführung in der Befehlszeile vorhanden ist",

  "if the robots will have a Windows account and communicate by email or Teams":
    "ob die Roboter über ein Windows-Konto verfügen und per E-Mail oder Teams kommunizieren",

  "if there is a need to create VB scripts or run macros directly on blue prism":
    "ob VB-Skripte erstellt oder Makros direkt in Blue Prism ausgeführt werden müssen",

  "if there is a possibility to use web services or to expose them for other processes":
    "ob die Möglichkeit besteht, Webdienste zu verwenden oder sie für andere Prozesse freizugeben",

  "if the process interacts with web applications via browsers":
    "ob der Prozess über Browser mit Webanwendungen interagiert",

  "if the process interacts with Windows applications installed on the computer":
    "ob der Prozess mit auf dem Computer installierten Windows-Anwendungen interagiert",

  "if there is any application that we access via an emulator":
    "ob eine Anwendung über einen Emulator zugegriffen wird",

  "if there is any application that we access via the cloud":
    "ob eine Anwendung über die Cloud zugegriffen wird",

  "the number of steps": "die Anzahl der Schritte",

  // scheduler
  "Are you sure you want to disregard this schedule?":
    "Sind Sie sicher, dass Sie diesen Zeitplan ignorieren möchten?",
  "Schedule disregarded!": "Zeitplan ignoriert!",
  "Are you sure you want to execute this process?":
    "Sind Sie sicher, dass Sie diesen Prozess ausführen möchten?",

  // kpi
  January: "Januar",
  March: "März",
  April: "April",
  May: "Mai",
  June: "Juni",
  July: "Juli",
  August: "August",
  September: "September",
  October: "Oktober",
  November: "November",
  December: "Dezember",
  "Weeks ago": "Wochen zurück",

  "7 Weeks ago": "Vor 7 Wochen",
  "6 Weeks ago": "Vor 6 Wochen",
  "5 Weeks ago": "Vor 5 Wochen",
  "4 Weeks ago": "Vor 4 Wochen",
  "3 Weeks ago": "Vor 3 Wochen",
  "2 Weeks ago": "Vor 2 Wochen",
  "Last Week": "Letzte Woche",

  "hours ago": "Vor #NB# Stunden",
  "1 hour ago": "Vor 1 Stunde",

  Monday: "Montag",
  Tuesday: "Dienstag",
  Wednesday: "Mittwoch",
  Thursday: "Donnerstag",
  Friday: "Freitag",
  Saturday: "Samstag",
  Sunday: "Sonntag",

  "Total executions": "Gesamtausführungen",
  "Number of process executions": "Anzahl der Prozessausführungen",
  "Latest activities": "Letzte Aktivitäten",

  "Creation date (Descending)": "Erstellungsdatum (absteigend)",
  "Creation date (Ascending)": "Erstellungsdatum (aufsteigend)",
  "Exception Time (Descending)": "Ausnahmezeit (absteigend)",
  "Exception Time (Ascending)": "Ausnahmezeit (aufsteigend)",
  "End date (Descending)": "Enddatum (absteigend)",
  "End date (Ascending)": "Enddatum (aufsteigend)",
  "Creation date": "Erstellungsdatum",
  "Exception date": "Ausnahmedatum",
  "Sort By": "Sortieren nach",
  "Document Name": "Dokumentenname",
  "Document Status": "Dokumentenstatus",
  Preview: "Vorschau",
  "The document was sent to the orchestrator":
    "Das Dokument wurde an den Orchestrator gesendet",
  "The process has started successfully":
    "Der Prozess wurde erfolgreich gestartet",
  "Are you sure you want to delete this document ?":
    "Möchten Sie dieses Dokument wirklich löschen?",
  "Document Deleted Successfully": "Dokument erfolgreich gelöscht",
  "Show empty fields": "Leere Felder anzeigen",
  "Are you sure you want to delete this Opportunity ?":
    "Möchten Sie diese Gelegenheit wirklich löschen?",
  "Opportunity deleted successfully": "Gelegenheit erfolgreich gelöscht",
  // added by zineb

  "Are you sure you want to start this process ?":
    "Sind Sie sicher, dass Sie diesen Prozess starten möchten?",
  START: "START",
  Of: "von",
  FOREVER: "IMMER",
  "Are you sure you want to delete this schedule ?":
    "Möchten Sie diesen Zeitplan wirklich löschen?",
  "Are you sure you want to delete this frequency ?": "Sind Sie sicher, dass Sie diese Frequenz löschen möchten?",
  "Schedule Deleted Successfully": "Zeitplan erfolgreich gelöscht",
  "Failed to delete schedule": "Fehler beim Löschen des Zeitplans",
  "No options": "Keine Optionen",
  Clear: "Löschen",
  "save as draft": "Als Entwurf speichern",
  "Save as draft": "Als Entwurf speichern",
  "Save draf": "Entwurf speichern",
  calculate: "berechnen",
  Calculate: "Berechnen",

  // Process Assessment help Added By Zineb

  "Can a defined rule be applied to each step of this process?":
    "Kann eine definierte Regel auf jeden Schritt dieses Prozesses angewendet werden?",
  "To what extent is the process standard across locations?":
    "Inwieweit ist der Prozess in den verschiedenen Standorten standardisiert?",
  "The more optimized & centrally managed the process is, the easier the automation tests & implementation will be.":
    "Je besser optimiert und zentral verwaltet der Prozess ist, desto einfacher werden die Automatisierungstests und die Implementierung sein.",
  "Are the process inputs provided in a structured manner?":
    "Werden die Eingaben für den Prozess strukturiert bereitgestellt?",
  "Is a full data set availbale to test this process ?":
    "Steht ein vollständiger Datensatz zur Verfügung, um diesen Prozess zu testen?",
  "What is the level of IT environment complexity?":
    "Wie hoch ist der Komplexitätsgrad der IT-Umgebung?",
  "What level of documentation exists to support this process?":
    "Welche Dokumentationsstufe gibt es zur Unterstützung dieses Prozesses?",
  "What is scale of the security challenge for authentication?":
    "Wie hoch ist das Ausmaß der Sicherheitsherausforderung bei der Authentifizierung?",
  "What is the Time required for a single but complete process execution?":
    "Wie viel Zeit wird für eine einzige, aber vollständige Prozessausführung benötigt?",
  "What is the frequency which this process occurs?":
    "Mit welcher Häufigkeit tritt dieser Prozess auf?",
  "What is the value that would drive a round of process executions?":
    "Welcher Wert würde eine Runde von Prozessausführungen antreiben?",
  "Please indicate the nature of the driver for the occurrence of the process (number of company codes, accounts, postings, etc.)":
    "Geben Sie bitte die Art des Treibers für das Auftreten des Prozesses an (Anzahl der Firmencodes, Konten, Buchungen usw.)",
  "Weekly=52, Monthly=12, Daily=220, etc. For specific frequencies, please add the details of your calculations in the comment section":
    "Wöchentlich=52, monatlich=12, täglich=220 usw. Für spezifische Häufigkeiten fügen Sie bitte die Details Ihrer Berechnungen im Kommentarbereich hinzu",

  "This field is populated automatically from the details from the Time Spent Section":
    "Dieses Feld wird automatisch mit den Details aus dem Abschnitt 'Verbrachte Zeit' ausgefüllt",
  "Select gravity of benefits and mention in the comment area what are the benefits expected, e.g. better quality, productivity gains, reduced lead time, etc.":
    "Wählen Sie die Schwere der Vorteile aus und geben Sie im Kommentarbereich an, welche Vorteile erwartet werden, z.B. bessere Qualität, Produktivitätssteigerungen, verkürzte Durchlaufzeiten usw.",
  "To what extent is this same process performed worldwide?":
    "In welchem Maße wird dieser gleiche Prozess weltweit durchgeführt?",
  "Are systems/tool changes planned that would impact this process? (i.e. new report development)":
    "Sind geplante Änderungen an Systemen/Werkzeugen vorgesehen, die sich auf diesen Prozess auswirken würden? (z. B. Entwicklung neuer Berichte)",

  "Select the number of steps (Clicks, navigation, reading and writing in each application window), if necessary precise in comments":
    "Wählen Sie die Anzahl der Schritte (Klicks, Navigation, Lesen und Schreiben in jedem Anwendungsfenster) aus, falls erforderlich, geben Sie dies bitte in den Kommentaren genau an",
  "Select the number of windows (screens) that change during the execution of the process, if necessary precise in comments":
    "Wählen Sie die Anzahl der Fenster (Bildschirme) aus, die sich während der Ausführung des Prozesses ändern, falls erforderlich, geben Sie dies bitte in den Kommentaren genau an",
  "Select the number of the business rules governing the process, if necessary precise in comments":
    "Wählen Sie die Anzahl der Geschäftsregeln aus, die den Prozess steuern, falls erforderlich, geben Sie dies bitte in den Kommentaren genau an",
  "Select the number of business applications involved in the process (Number of objects to be created), if necessary precise in comments":
    "Wählen Sie die Anzahl der Geschäftsanwendungen aus, die am Prozess beteiligt sind (Anzahl der zu erstellenden Objekte), falls erforderlich, geben Sie dies bitte in den Kommentaren genau an",
  "Select whether unstructured data (PDF, Excel, e-mail, Txt file) or any OCR processing will be processed, if necessary precise in comments":
    "Wählen Sie aus, ob unstrukturierte Daten (PDF, Excel, E-Mail, Textdatei) oder eine OCR-Verarbeitung verarbeitet wird, falls erforderlich, geben Sie dies bitte in den Kommentaren genau an",
  "Select whether a communication with a database to establish or any SQL commands will be executed if necessary precise in comments":
    "Wählen Sie aus, ob eine Kommunikation mit einer Datenbank hergestellt oder SQL-Befehle ausgeführt werden sollen, falls erforderlich, geben Sie dies bitte in den Kommentaren genau an",
  "Select if there is any PowerShell script to install or any PowerShell code to execute in the command line, if necessary precise in comments":
    "Wählen Sie aus, ob ein PowerShell-Skript installiert oder ein PowerShell-Code in der Befehlszeile ausgeführt werden soll, falls erforderlich, geben Sie dies bitte in den Kommentaren genau an",
  "Select if the robots will have a Windows account and communicate by email or Teams, if necessary precise in comments":
    "Wählen Sie aus, ob die Roboter über ein Windows-Konto verfügen und per E-Mail oder Teams kommunizieren sollen, falls erforderlich, geben Sie dies bitte in den Kommentaren genau an",
  "Select if there is a need to create VB scripts or run macros directly on Blue Prism if necessary precise in comments":
    "Wählen Sie aus, ob es erforderlich ist, VB-Skripte zu erstellen oder Makros direkt auf Blue Prism auszuführen, falls erforderlich, geben Sie dies bitte in den Kommentaren genau an",
  "Select if there is a possibility to use web services or to expose them for other processes, if necessary precise in comments":
    "Wählen Sie aus, ob die Möglichkeit besteht, Webdienste zu nutzen oder sie für andere Prozesse freizugeben, falls erforderlich, geben Sie dies bitte in den Kommentaren genau an",
  "Select if the process interacts with web applications via browsers (IE, Chrome, Firefox), if necessary precise in comments":
    "Wählen Sie aus, ob der Prozess über Webanwendungen über Browser (IE, Chrome, Firefox) interagiert, falls erforderlich, geben Sie dies bitte in den Kommentaren genau an",
  "Select if the process interacts with Windows applications installed on the computer, if necessary precise in comments":
    "Wählen Sie aus, ob der Prozess mit Windows-Anwendungen interagiert, die auf dem Computer installiert sind, falls erforderlich, geben Sie dies bitte in den Kommentaren genau an",
  "Select if there is any application that we access via an emulator (IBM, AS400, HUB 5250), if necessary precise in comments":
    "Wählen Sie aus, ob es eine Anwendung gibt, auf die über einen Emulator (IBM, AS400, HUB 5250) zugegriffen wird, falls erforderlich, geben Sie dies bitte in den Kommentaren genau an",
  "Select if there is any application that we access via the cloud (image), if necessary precise in comments":
    "Wählen Sie aus, ob es eine Anwendung gibt, auf die über die Cloud (Image) zugegriffen wird, falls erforderlich, geben Sie dies bitte in den Kommentaren genau an",
  "Select if this process can be automated without the use of RPA technologies":
    "Wählen Sie aus, ob dieser Prozess ohne den Einsatz von RPA-Technologien automatisiert werden kann",

  "Are you sure you want to save this case as a draft?":
    "Sind Sie sicher, dass Sie diesen Fall als Entwurf speichern möchten?",
  "Case saved as a draft": "Fall wurde als Entwurf gespeichert",
  "Are you sure you want to save and submit this case later?":
    "Sind Sie sicher, dass Sie diesen Fall später speichern und einreichen möchten?",
  "Case saved but not submitted":
    "Fall wurde gespeichert, aber nicht eingereicht",
  "Are you sure you want to save and submit this case?":
    "Sind Sie sicher, dass Sie diesen Fall speichern und einreichen möchten?",
  "Case saved and submitted for approval":
    "Fall wurde gespeichert und zur Genehmigung eingereicht",
  "rpaQual.transition.accepted":
    "Sind Sie sicher, dass Sie diesen Fall als Automatisierungsmöglichkeit akzeptieren möchten?",
  "Case accepted as an opportunity":
    "Fall wurde als Automatisierungsmöglichkeit akzeptiert",
  "Are you sure you want to reject this case?":
    "Sind Sie sicher, dass Sie diesen Fall ablehnen möchten?",
  "Case rejected": "Fall wurde abgelehnt",
  "This case is not yet submitted. Are you sure you want to delete it?":
    "Dieser Fall wurde noch nicht eingereicht. Sind Sie sicher, dass Sie ihn löschen möchten?",
  "Case deleted successfully": "Fall erfolgreich gelöscht",
  "Case archived successfully": "Fall erfolgreich archiviert",
  "This case is submitted and awaiting approval. Are you sure you want to delete it?":
    "Dieser Fall wurde eingereicht und wartet auf Genehmigung. Sind Sie sicher, dass Sie ihn löschen möchten?",
  "This case is an accepted opportunity for automation. Are you sure you want to delete it?":
    "Dieser Fall ist eine angenommene Automatisierungsmöglichkeit. Sind Sie sicher, dass Sie ihn löschen möchten?",
  "Are you sure you want to delete this case?":
    "Sind Sie sicher, dass Sie diesen Fall löschen möchten?",
  "Your progress will be lost, are you sure you want to exit?":
    "Ihr Fortschritt geht verloren. Sind Sie sicher, dass Sie beenden möchten?",
  "Total in man-days": "Gesamt in Personentagen",
  "Running costs in man-days": "Laufende Kosten in Personentagen",
  months: "Monate",
  Months: "Monate",
  Weeks: "Wochen",
  Years: "Jahre",
  days: "Tage",
  weeks: "Wochen",
  years: "Jahre",
  left: "verbleibend",
  ago: "vor {{value}} {{unit}}",
  Logout: "Abmelden",
  Profile: "Profil",
  "Choose your timezone": "Wählen Sie Ihre Zeitzone",
  "Execution Count": "Ausführungen",
  "Exception.count": "Ausnahmen",
  process: "Prozess",
  "point.amortissage.negative": "{{value}} {{unit}} übrig",
  "of the total": "des Gesamten",
  SearchProcess: "Prozess suchen",
  searchUser: "Benutzer suchen",
  status: "Status",
  sortBy: "Sortieren nach",
  "Failed To create User": "Benutzererstellung fehlgeschlagen",
  "Failed To create Scheduler": "Zeitplan konnte nicht erstellt werden",
  "user.keycloak.exists": "Benutzer existiert bereits in Keycloak",
  "user.email.exists": "Benutzer existiert bereits",
  "User with same email already exists":
    "Benutzer mit derselben E-Mail existiert bereits",
  "user.update.fail": "Benutzeraktualisierung fehlgeschlagen",
  "user.update.success": "Benutzer erfolgreich aktualisiert!",
  "invalid.value": "Ungültiger Wert",
  Required: "Erforderlich",
  deleteUsersActionMessage:
    "Sind Sie sicher, dass Sie diese Benutzer löschen möchten?",
  "profile.update-password.current-password-required":
    "Aktuelles Passwort ist erforderlich",
  "profile.update-password.new-password-required":
    "Neues Passwort ist erforderlich",
  "profile.update-password.password-required": "Passwort ist erforderlich",
  "profile.update-password.password-not-matched":
    "Passwörter stimmen nicht überein",
  "profile.update-password.current-password": "Aktuelles Passwort",
  "profile.update-password.new-password": "Neues Passwort",
  "profile.update-password.confirm-password": "Passwort bestätigen",
  "profile.update-password.reset-password": "Passwort zurücksetzen",
  "profile.update-password.password-reset": "Passwort zurückgesetzt",
  "profile.update-password.password-reset-success":
    "Ihr Passwort wurde erfolgreich geändert",
  "profile.update-password.password-email-send":
    "Eine E-Mail wurde gesendet. Bitte überprüfen Sie Ihre E-Mails.",
  "profile.update-password.password-reset-done": "Erledigt",
  "profile.update-password.update-password": "Passwort ändern",
  "profile.update-password.email": "Ihre aktuelle E-Mail",
  "profile.update-password.tokenError":
    "Ungültiges Token. Das Passwort wurde bereits aktualisiert!",
  "token.validation.noteExist":
    "Anforderung zur Passwortänderung wurde bereits gesendet",
  "Process CompletedItems": "Abgeschlossene Elemente",
  "Completed time": "Abgeschlossene Zeit",
  "traited item": "verarbeitete Elemente",
  "Items Count": "Verarbeitete Elemente",
  "process.devCost.edit.cancel":
    "Ihre Änderungen gehen verloren. Sind Sie sicher, dass Sie abbrechen möchten?",
  "process.devCost.edit.confirm":
    "Sind Sie sicher, dass Sie Ihre Änderungen speichern möchten?",
  "process.status.pending": "AUSSTEHEND",
  "not submitted": "NICHT EINGEREICHT",
  "Process Assessment": "Prozess-bewertung",
  "title.processassessment": "PROZESSBEWERTUNG",
  "no.exception.message": "Keine Ausnahmen festgestellt",
  "show.all.exception.button": "Alle anzeigen",
  manageUsers: "Benutzer verwalten",
  "assessment.Accounting & Finance": "Buchhaltung und Finanzen",
  "assessment.Compliance": "Compliance",
  "assessment.Customer Service": "Kundenservice",
  "assessment.Purchasing": "Beschaffung",
  "assessment.IT Operations": "IT-Betrieb",
  "assessment.Maintenance": "Wartung",
  "assessment.Sales": "Vertrieb",
  "assessment.Marketing": "Marketing",
  "assessment.Research and Development": "Forschung und Entwicklung",
  "assessment.Production": "Produktion",
  "assessment.Human Resources Management ": "Personalmanagement",
  "assessment.Software Development": "Softwareentwicklung",
  "assessment.Other": "Sonstiges",
  "assessment.Seconds": "Sekunden",
  "assessment.Minutes": "Minuten",
  "assessment.Hours": "Stunden",
  "assessment.Days": "Tage",
  "assessment.Months": "Monate",
  "assessment.Years": "Jahre",
  "assessment.Weeks": "Wochen",
  "assessment.No - Judgment based": "Nein - Urteilsbasiert",
  "assessment.Yes - Based on objective criteria": "Ja - Auf objektiven Kriterien basierend",
  "orchestrator.management.title": "Orchestrator-Verwaltung",
  "orchestrator.management.menu.title": "Orchestratoren",
  "robot.management.title": "Roboterverwaltung",
  "robot.management.menu.title": "Roboter",
  "Process Occurence": "Abgeschlossene Elemente",
  "Process Item": "Prozesselemente",
  "orchestrator.management.add.button": "Orchestrator verbinden",
  "orchestrator.management.delete.confirmMsg":
    "Sind Sie sicher, dass Sie den Orchestrator löschen möchten? Alle Daten gehen dauerhaft verloren.",
  "orchestrator.management.deleteOrchestratorsActionMessage":
    "Sind Sie sicher, dass Sie die ausgewählten Orchestratoren löschen möchten?",
  "orchestrator.management.enaDes.confirmMsg":
    "Sind Sie sicher, dass Sie den Orchestrator aktivieren möchten? Die Verbindung zur RPA-Umgebung wird neu initialisiert.",
  "orchestrator.management.enaDes.button.confirm": "Aktivieren",
  "orchestrator.management.desaDes.confirmMsg":
    "Sind Sie sicher, dass Sie den Orchestrator deaktivieren möchten? Die Verbindung zur RPA-Umgebung geht verloren.",
  "orchestrator.management.desaDes.button.confirm": "Deaktivieren",
  "orchestrator.management.searchOrchestrator": "Orchestratoren suchen",
  "orchestrator.management.status": "Status",
  "orchestrator.management.name": "Name",
  "orchestrator.management.solutionProvider": "Lösungsanbieter",
  "orchestrator.management.isActionable": "Ist handlungsfähig",
  "orchestrator.management.orchestratorIp": "Öffentliche IP",
  "orchestrator.management.isAcionableToggle":
    "Sind Sie sicher, dass dieser Orchestrator nicht handlungsfähig ist?",
  "orchestrator.management.isAcionableToggle.confirm": "Ja, ich bin sicher",
  "orchestrator.management.isNotAcionableToggle":
    "Sind Sie sicher, dass Sie diesen Orchestrator handlungsfähig machen möchten?",
  "orchestrator.management.isNotAcionableToggle.confirm":
    "Handlungsfähig machen",
  "orchestrator.management.editHeader": "Orchestrator bearbeiten",
  "orchestrator.management.addHeader": "Orchestrator hinzufügen",
  "orchestrator.management.displayHeader": "Orchestrator-Details",
  "orchestrator.management.formControl.required": "Pflichtfeld",
  "orchestrator.management.formControl.orchestratorId": "Orchestrator-ID",
  "orchestrator.management.formControl.orchestratorName": "Orchestrator-Name",
  "orchestrator.management.formControl.orchestratorName.information":
    "Der Name des Orchestrators",
  "orchestrator.management.formControl.description.information":
    "Hier können Sie beliebige Informationen hinzufügen",
  "orchestrator.management.formControl.orchestratorIp.information":
    "Öffentliche oder private IP der Orchestrator-Maschine",
  "orchestrator.management.formControl.sessionNumber.information":
    "Die Anzahl der erlaubten Sitzungen in der aktuellen aktiven BP-Lizenz",
  "orchestrator.management.formControl.databaseServer.information":
    "Der Hostname des Datenbankservers",
  "orchestrator.management.formControl.serverPort.information":
    "Dies muss mit dem konfigurierten Port des SQL-Servers übereinstimmen",
  "orchestrator.management.formControl.databaseName.information":
    "Der Name der von BluePrism verwendeten Datenbank",
  "orchestrator.management.formControl.dbUsername.information":
    "Der Benutzername der Datenbankverbindung. Der Benutzer muss über die Berechtigungen db_datareader und db_datawriter für die BluePrism-Datenbank verfügen",
  "orchestrator.management.formControl.dbPassword.information":
    "Das Kennwort des Benutzernamens für die Datenbankverbindung",
  "orchestrator.management.formControl.solutionProvider": "Lösungsanbieter",
  "orchestrator.management.formControl.orchestratorIp": "Orchestrator-IP",
  "orchestrator.management.formControl.apiToken": "API-Token",
  "orchestrator.management.formControl.apiUrl": "API-URL",
  "orchestrator.management.formControl.yes": "Ja",
  "orchestrator.management.formControl.no": "Nein",
  "orchestrator.management.formControl.orchestratorId.actionnable":
    "Handlungsfähig",
  "orchestrator.management.formControl.apiPath": "API-Pfad",
  "orchestrator.button.update": "Aktualisieren",
  "orchestrator.delete.discard":
    "Sind Sie sicher, dass Sie die Änderungen verwerfen möchten?",
  "orchestrator.management.save.confirmMsg":
    "Sind Sie sicher, dass Sie diesen Orchestrator speichern möchten?",
  "user.enaDes.confirmMsg":
    "Sind Sie sicher, dass Sie den Benutzer löschen möchten?",
  "orchestrator.management.formControl.description": "Beschreibung",
  "orchestrator.management.formControl.databaseServer": "Datenbankserver",
  "orchestrator.management.databaseServer": "Datenbankserver",
  "orchestrator.management.formControl.serverPort": "Port",
  "orchestrator.management.serverPort": "Port",
  "orchestrator.management.formControl.databaseName": "Datenbankname",
  "orchestrator.management.formControl.dbUsername": "Benutzername",
  "orchestrator.management.formControl.dbPassword": "Kennwort",
  "orchestrator.management.formControl.invalidPort":
    "Die Portnummer muss zwischen 1 und 65.535 liegen",
  "orchestrator.management.formControl.apiClientId": "API-Client-ID",
  "orchestrator.management.formControl.apiClientSecret": "API-Client-Geheimnis",
  "orchestrator.management.formControl.organizationName":
    "Uipath Organisationsname",
  "orchestrator.management.formControl.tenantName": "Uipath Mandantenname",
  "orchestrator.management.formControl.orchestratorApiName":
    "Uipath Orchestrator Name",
  "orchestrator.management.testConnection": "Verbindung testen",
  "orchestrator.management.testConnection.successMessage":
    "Verbindung erfolgreich",
  "orchestrator.management.testConnection.failureMessage":
    "Verbindung fehlgeschlagen",
  "Are you sure you want to accept this case as an opportunity for automation ?":
    "Sind Sie sicher, dass Sie diesen Fall als Automatisierungschance annehmen möchten?",
  "Are you sure you want to save the changes?":
    "Sind Sie sicher, dass Sie die Änderungen speichern möchten?",
  "Schedule modified successfully": "Ihre Anfrage wurde an BP gesendet und der Zeitplan wird bald aktualisiert",
  "Schedule saved successfully": "Frequenz erfolgreich gespeichert",
  "Frequency Deleted Successfully": "Häufigkeit Erfolgreich gelöschte",
  "Failed to update the schedule! Please try again later":
    "Fehler beim Aktualisieren des Zeitplans! Bitte versuchen Sie es später erneut",
  "Create schedule": "Zeitplan erstellen",
  "Edit schedule": "Zeitplan bearbeiten",
  "Confirm frequency": "Bestätigen Sie die Frequenz",
  "Update frequency": "Aktualisieren Sie die Frequenz",
  "Choose Process": "Prozess auswählen",
  "Choose Robot": "Roboter auswählen",
  "Disregard changes": "Verwerfen",
  "Save changes": "Änderungen speichern",
  apply: "ANWENDEN",
  reset: "ZURÜCKSETZEN",
  processes: "Prozesse",
  ACTIVE: "Aktiv",
  IDLE: "Inaktiv",
  WORKING: "Arbeitend",
  OFFLINE: "Getrennt",
  Idle: "Inaktiv",
  Working: "Arbeitend",
  Offline: "Getrennt",
  DISABLED: "Inaktiv",
  DISABLE: "Inaktiv",
  Disabled: "Deaktiviert",
  Missing: "Fehlt",
  "Process status": "Prozessstatus",
  "timeUnite.millis": " ms",
  "timeUnite.sec": " Sek.(s)",
  "timeUnite.min": " Min.(s)",
  "timeUnite.hrs": " Std.(s)",
  "timeUnite.days": " Tage(s)",
  "timeUnite.months": " Monat(e)",
  "timeUnite.manDay": "MD(s)",
  "timeUnite.fte": "FTE",
  "Choose icon": "Symbol auswählen",
  "Edit Icon": "Symbol bearbeiten",
  export: "Exportieren",
  "user.management.title": "Benutzerverwaltung",
  "user.management.menu.title": "Benutzer",
  "User Administration": "Nutzerverwaltung",
  Administration: "Verwaltung",
  "user.management.add.button": "Chance Benutzer",
  "user.management.delete.confirmMsg":
    "Sind Sie sicher, dass Sie diesen Benutzer löschen möchten?",
  "user.management.deleteUsersActionMessage":
    "Sind Sie sicher, dass Sie die ausgewählten Benutzer löschen möchten?",
  "user.management.enaDes.confirmMsg":
    "Sind Sie sicher, dass Sie diesen Benutzer aktivieren möchten?",
  "user.management.enaDes.button.confirm": "Aktivieren",
  "user.management.desaDes.confirmMsg":
    "Sind Sie sicher, dass Sie diesen Benutzer deaktivieren möchten?",
  "user.management.desaDes.button.confirm": "Deaktivieren",
  "user.management.searchUser": "Benutzer suchen",
  depuis: "Seit",
  "depuis la premiere execution": "seit der ersten gefundenen Ausführung",
  "kpi.parameter.ConsiderBusinessExceptionsAsCompletedItems":
    "Geschäftsausnahmen als abgeschlossene Elemente in den Überwachungskennzahlen berücksichtigen",
  "kpi.parameter.ConsiderBusinessExceptionsAsCompletedItems.tooltip":
    "Wenn aktiviert, werden alle Elementausnahmen mit dem Typ Geschäftsausnahme in den Überwachungskennzahlen als abgeschlossene Elemente betrachtet.",
  "kpi.parameter.be": "Daten seit einschließen",
  "kpi.parameter.includeException":
    "Geschäftsausnahmen in den finanziellen KPIs berücksichtigen",
  "kpi.parameter.executionsIncludeException":
    "Prozessausnahmen in den finanziellen KPIs berücksichtigen",
  "kpi.parameter.invalidRate": "Ungültige Rate",
  "kpiParam.update.success": "Einstellungen erfolgreich aktualisiert",
  "kpiParam.update.error": "Fehler beim Aktualisieren der KPI-Parameter",
  "kpiParam.reset.success": "Einstellungen erfolgreich zurückgesetzt",
  "kpiParam.reset.error": "Fehler beim Zurücksetzen der KPI-Parameter",
  "kpi.parameter.includeSince.tooltip":
    "Wählen Sie aus, ab wann SmartRoby Prozessdaten in Modulen und Schlüsselkennzahlen im gesamten Portal verwenden und anzeigen soll.",
  "kpi.parameter.includeExceptFinancial.tooltip":
    "Wählen Sie den prozentualen Zeitersparnis pro Element aus, der für jede Geschäftsausnahme berücksichtigt werden soll.",
  "kpi.parameter.includeExceptFinancialKPI.tooltip":
    "Wählen Sie den prozentualen Zeitersparnis pro Ausführung aus, der für jede Prozessausnahme berücksichtigt werden soll.",
  "kpi.parameter.breakEvenCalculationPeriod.tooltip":
    "Wählen Sie die historische Laufzeit aus, die bei der Schätzung des Break-even-Punkts für diesen Prozess berücksichtigt werden soll.",
  "routes.home": "Startseite",
  "routes.orchestrators": "Orchestratoren",
  "routes.orchestrators.processAssessment": "Prozessbewertung",
  "routes.orchestrators.processAssessment.rpa": "RPA",
  "routes.orchestrators.processAssessment.rpa.view": "Ansicht",
  "routes.orchestrators.processAssessment.rpa.create": "Erstellen",
  "routes.orchestrators.processAssessment.rpa.edit": "Bearbeiten",
  "routes.orchestrators.smartRobots": "Intelligente Roboter",
  "routes.orchestrators.myProcesses": "Meine Prozesse",
  "My Processes": "Meine Prozesse",
  "routes.orchestrators.smartRobots.documents": "Dokumente",
  "routes.orchestrators.smartRobots.documents.details": "Details",
  "routes.orchestrators.administration": "Verwaltung",
  "routes.orchestrators.administration.users": "Benutzer",
  "routes.orchestrators.administration.users.add": "Hinzufügen",
  "routes.orchestrators.administration.users.edit": "Bearbeiten",
  "routes.orchestrators.administration.users.view": "Anzeigen",
  "routes.orchestrators.protected": "Geschützt",
  "routes.orchestrators.protected.user": "Benutzer",
  "routes.orchestrators.protected.user.profile": "Profil",
  "routes.orchestrators.administration.orchestrators": "Orchestratoren",
  "routes.orchestrators.administration.orchestrators.add": "Hinzufügen",
  "routes.orchestrators.administration.orchestrators.edit": "Bearbeiten",
  "routes.orchestrators.administration.orchestrators.view": "Anzeigen",
  "routes.orchestrators.smartocr": "Smart OCR",
  "routes.orchestrators.smartocr.identity_card": "Personalausweis",
  "routes.orchestrators.smartocr.passport": "Reisepass",
  "routes.orchestrators.smartocr.driver_licence": "Führerschein",
  "routes.orchestrators.smartocr.car_registration": "Fahrzeugregistrierung",
  "routes.orchestrators.smartocr.green_card": "Grüne Karte",
  "routes.orchestrators.smartocr.rib": "RIB",
  "routes.orchestrators.smartocr.invoice": "Rechnung",
  "routes.orchestrators.smartocr.purchase_order": "Bestellung",
  "routes.orchestrators.smartocr.tax_notice": "Steuerbescheid",
  "routes.orchestrators.smartocr.receipt": "Quittung",
  "routes.orchestrators.smartocr.edf": "EDF",
  "routes.orchestrators.smartocr.payroll_statemenet": "Lohnabrechnung",
  "routes.orchestrators.exceptions": "Ausnahmen",
  "routes.orchestrators.exceptions.processes": "Prozessausnahmen",
  "routes.orchestrators.exceptions.items": "Elementausnahmen",
  "routes.orchestrators.process-occurrence": "Prozessvorkommen",
  "routes.orchestrators.alertsManagement": "Alert-Verwaltung",
  "routes.orchestrators.alertsManagement.defaultAlerts":
    "Standardbenachrichtigungen",
  "routes.orchestrators.alertsManagement.customAlerts":
    "Benutzerdefinierte Benachrichtigungen",
  "routes.orchestrators.alertsManagement.defaultAlerts.add": "Hinzufügen",
  "routes.orchestrators.alertsManagement.defaultAlerts.edit": "Bearbeiten",
  "routes.orchestrators.alertsManagement.defaultAlerts.view": "Anzeigen",
  "routes.orchestrators.alertsManagement.customAlerts.add": "Hinzufügen",
  "routes.orchestrators.alertsManagement.customAlerts.edit": "Bearbeiten",
  "routes.orchestrators.alertsManagement.customAlerts.view": "Anzeigen",
  "exceptions.item.tab.title": "Elementausnahmen",
  "exceptions.processes.tab.title": "Prozessausnahmen",
  "routes.orchestrators.process-occurence": "Prozessvorkommen",
  "routes.orchestrators.profile": "Profil",
  "routes.orchestrators.administration.fleets": "Kunden",
  "routes.orchestrators.administration.fleets.add": "Hinzufügen",
  "routes.orchestrators.administration.fleets.edit": "Bearbeiten",
  "routes.orchestrators.administration.fleets.view": "Anzeigen",
  "routes.orchestrators.analytics": "Analytics",
  Analytics: "Analytisen",
  "Alerts Management": "Alert-Verwaltung",
  "save draft": "ENTWURF SPEICHERN",
  "user.title.editHeader": "Benutzer bearbeiten",
  "fleet.management.status": "Status",
  "fleet.management.companyName": "Gruppenname",
  "fleet.management.email": "E-Mail",
  "fleet.management.clientSince": "Gruppe erstellt seit",
  "fleet.management.title": "Kundenverwaltung",
  "fleet.management.menu.title": "Kunden",
  "fleet.management.add.button": "Chance Band",
  "fleet.management.delete.confirmMsg":
    "Möchten Sie diesen Kunden wirklich löschen?",
  "fleet.management.deleteClientsActionMessage":
    "Möchten Sie die ausgewählten Kunden wirklich löschen?",
  "fleet.management.enaDes.confirmMsg":
    "Möchten Sie diesen Kunden wirklich aktivieren?",
  "fleet.management.enaDes.button.confirm": "aktivieren",
  "fleet.management.desaDes.confirmMsg":
    "Möchten Sie diesen Kunden wirklich deaktivieren?",
  "fleet.formSection.fleetInfo": "Allgemeine Informatio",
  "fleet.management.desaDes.button.confirm": "deaktivieren",
  "fleet.management.searchFleet": "Kunden suchen",
  "fleet.management.addHeader": "Kunde hinzufügen",
  "fleet.management.editHeader": "Kunde bearbeiten",
  "fleet.management.displayHeader": "Kundendetails",
  "fleet.management.formControl.required": "Pflichtfeld",
  "fleet.management.formLabel.companyName": "Kundenname",
  "fleet.management.formControl.email": "Falsches E-Mail-Format",
  "fleet.management.formControl.siret.notValid": "Ungültige SIRET-Nummer",
  "fleet.management.formControl.siren.notValid": "Ungültige SIREN-Nummer",
  "fleet.management.formLabel.email": "E-Mail",
  "fleet.management.formLabel.phoneNumber": "Telefonnummer",
  "fleet.management.formLabel.vatNumber": "Umsatzsteuer-ID",
  "fleet.management.formControl.vatNumber.notValid":
    "Wert muss eine Zahl sein",
  "fleet.management.formLabel.address": "Vollständige Adresse",
  "fleet.management.formLabel.subscription": "Abonnement",
  "fleet.management.formLabel.clientSince": "Kunde seit",
  "fleet.button.update": "Aktualisieren",
  "fleet.button.save": "Hinzufügen",
  "fleet.button.cancel": "Abbrechen",
  "fleet.update.confirmMsg":
    "Möchten Sie die Kundendaten wirklich aktualisieren?",
  "fleet.save.confirmMsg": "Möchten Sie den Kunden wirklich hinzufügen?",
  "fleet.delete.discard": "Möchten Sie Ihre Änderungen wirklich verwerfen?",
  "fleet.button.discard": "Verwerfen",
  "fleet.formControl.updateSuccess": "Kundendaten erfolgreich aktualisiert",
  "fleet.formControl.updateError": "Fehler beim Aktualisieren der Kundendaten",
  "fleet.formControl.saveError": "Fehler beim Hinzufügen des neuen Kunden",
  "fleet.management.formControl.invalidDate": "Ungültiges Datumsformat",
  "orchestrator.formControl.updateSuccess":
    "Orchestrator-Daten erfolgreich aktualisiert",
  "user.management.formLabel.fleet": "Kunde",
  "processesSelector.availableProcess": "Verfügbare Prozesse",
  "processesSelector.assignedProcess": "Zugewiesene Prozesse",
  "processTagSelector.assignedProcessTags": "Zugewiesene Prozesse & Tags",
  "tagSelector.availableTags": "Verfügbare tags",
  "user.formControl.saveError": "Fehler beim Hinzufügen des neuen Benutzers",
  "user.formControl.updateError": "Fehler beim Aktualisieren der Benutzerdaten",
  "user.formControl.updateSuccess": "Benutzerdaten erfolgreich aktualisiert",
  "user.formControl.saveSuccess": "Neuer Benutzer erfolgreich hinzugefügt",
  "user.permissions.title": "Benutzerberechtigungen",
  "user.permission.save": "Speichern",
  "user.permissions.template.createBtn": "Vorlage erstellen",
  "user.add.template": "Vorlage",
  "user.permission.confirmSave":
    "Möchten Sie die Benutzerberechtigungen wirklich aktualisieren?",
  "user.permission.confirmDelete":
    "Möchten Sie diese Vorlage wirklich löschen?",
  "user.permission.saveAsTemplace": "Speichern und Vorlage erstellen",
  "user.permission.saveAsTemplaceTitle": "Neue Vorlage",
  "user.permission.templateCreate.success": "Vorlage erfolgreich erstellt",
  "user.permission.templateDelete.success": "Vorlage erfolgreich gelöscht",
  "user.permission.templateCreate.error": "Vorlagenname existiert bereits",
  "permission.action.applyTemplate": "Vorlage anwenden",
  "user.permissions.template.name": "Vorlagenname",
  "user.permissions.default": "Standard",
  "user.add.permissions": "Berechtigungen",
  "fleet.formControl.saveSuccess": "Neuer Kunde erfolgreich hinzugefügt",
  "orchestrator.formControl.saveSuccess":
    "Neuer Orchestrator erfolgreich hinzugefügt",
  "fleet.formControl.unassignProcessError":
    "Der Prozess ist bereits {{users}} Benutzern zugewiesen",
  "fleet.management.delete.warning":
    "Das Löschen eines Kunden führt automatisch zum Löschen aller Benutzer und zum Aufheben der Prozesszuordnung!",
  "user.administration.fleet": "Band",
  "user.administration.lastLogin": "Letzte Anmeldung",
  "tooltip.action.enable": "Aktivieren",
  "tooltip.action.disable": "Deaktivieren",
  "tooltip.action.permissions": "Berechtigungen",
  "user.management.formControl.required": "Erforderliches Feld",
  "user.management.formControl.email": "Falsches E-Mail-Format",
  "fleet.management.formControl.phone":
    "Telefonnummer-Format wird nicht akzeptiert",
  "user.management.formControl.phone": "Falsches Telefonformat",
  "fleet.formSection.clientInfo": "Allgemeine Informationen",
  "fleet.formSection.clientParams": "Kundenparameter",
  "fleet.formSection.subscriptionInfo": "Abonnementinformationen",
  "fleet.formSection.clientParameters": "Kundeneinstellungen",
  "fleet.management.formLabel.rate": "Preis",
  "fleet.management.formLabel.overConsumptionRate": "Überverbrauchsrate",
  "fleet.management.formLabel.subscriptionPeriod": "Abonnementdauer",
  "fleet.management.formLabel.paymentTerm": "Zahlungsbedingungen",
  "fleet.formSection.processes": "Prozesse",
  "fleet.formSection.processesTags": "Prozesse & tags",
  "fleet.formSection.summary": "Zusammenfassung des aktiven Abonnements",
  "fleet.management.formLabel.rateUnit": "SAR/Stunde",
  "fleet.management.formLabel.subscriptionPeriodUnit": "Monate",
  "fleet.management.formLabel.siret": "SIRET",
  "fleet.management.formLabel.siren": "SIREN",
  "fleet.management.formLabel.manDayValue": "1 Mitarbeiter-Tag (md)",
  "fleet.management.formLabel.fullTime": "1 Vollzeitmitarbeiter (FTE)",
  "fleet.management.formLabel.bePeriod": "Break-even-Berechnungszeitraum",
  "fleet.management.formLabel.recommendedOccupancy": "Empfohlene Belegung",
  "fleet.management.be.period.1": "Letzter Monat",
  "fleet.management.be.period.2": "Letzte 3 Monate",
  "fleet.management.be.period.3": "Letzte 6 Monate",
  "fleet.management.be.period.4": "Letztes Jahr",
  "fleet.add.previous": "Zurück",
  client: "Kunde",
  "process.filter.client": "Kunde",
  "process.enabled": "Angezeigt",
  "process.filter.showAll": "Ausgeblendete Prozesse anzeigen",
  Unremarkable: "< 5 Jahre",
  "fleet.management.formControl.number":
    "Umsatzsteuer-Identifikationsnummer wird nicht akzeptiert",
  "fleet.management.formControl.occupancyNumber":
    "Empfohlene Belegung muss eine Zahl sein",
  "fleet.management.formControl.occupancyHigherThan":
    "Empfohlene Belegung darf nicht höher als 24 sein",
  "fleet.management.formControl.occupancyLowerThan":
    "Empfohlene Belegung darf nicht niedriger als 0 sein",
  "fleet.management.hoursPerDay": "Stunden pro Tag",
  "reporting.copyToClipboard": "In Zwischenablage kopieren",
  "reporting.process": "Prozess",
  "reporting.kpi": "KPI",
  "reporting.reportType": "Berichtstyp",
  "reporting.date": "Datum",
  "reporting.table": "Tabelle",
  "reporting.lineChart": "Liniendiagramm",
  "reporting.pieChart": "Kreisdiagramm",
  "reporting.barChar": "Balkendiagramm",
  "reporting.columnChart": "Säulendiagramm",
  "reporting.processExecutions": "Prozessausführungen",
  "reporting.itemsProcessed": "Verarbeitete Elemente",
  "reporting.itemsCompleted": "Abgeschlossene Elemente",
  "reporting.successRate": "Erfolgsrate der Elemente",
  "reporting.exceptionRate": "Ausnahmerate der Elemente",
  "reporting.itemsBusinessExceptions": "Geschäftliche Ausnahmen",
  "reporting.itemsSystemExceptions": "Systemausnahmen",
  "reporting.totalException": "Gesamtausnahmen",
  "Exception rate": "Ausnahmerate",
  "reporting.timeSaved": "Zeitersparnis",
  "reporting.executionTime": "Prozessausführungszeit",
  "reporting.itemExecutionTime": "Elementausführungszeit",
  "reporting.breakEven": "Break-even",
  "reporting.roi": "Rentabilitätsverhältnis",
  "reporting.roiShort": "Rentabilitätsverhältnis",
  "reporting.occupancyRate": "Belegungsrate",
  "reporting.roiPercentage": "Rentabilitätsverhältnis",
  "reporting.executedProcesses": "Ausgeführte Prozesse",
  "reporting.save": "Als PNG exportieren",
  "reporting.copyClipboard": "In Zwischenablage kopieren",
  "reporting.exportPDF": "Als PDF exportieren",
  "reporting.clear": "Löschen",
  "reporting.from": "Von",
  "reporting.to": "Bis",
  "reporting.exceptionCount": "Ausnahmeanzahl",
  "reporting.reportTitle": "zwischen {{from}} und {{to}}",
  "reporting.and": "und",
  for: "für",
  "reporting.cumul": "Kumulatives Ergebnis",
  "reporting.date.errorFuture": "Der Wert sollte größer als {{dateValue}} sein",
  "reporting.date.errorPast": "Der Wert sollte kleiner als {{dateValue}} sein",
  "Process launcher": "Prozessstarter",
  "Execution detail": "Ausführungsdetails",
  "Business Exception": "Geschäftsausnahme",
  "System Exception": "Systemausnahme",
  "Process.list.names.by.orchestrator": "Prozesse",
  "Robot.list.names.by.orchestrator": "Roboter",
  "fleet.management.formLabel.effectiveStartDate": "Effektives Startdatum",
  "rpaQual.transition.delete": "Löschen",
  "rpaQual.transition.deleteMsg":
    "Möchten Sie diese Gelegenheit wirklich löschen?",
  "rpaQual.button.delete": "Möchten Sie diese Qualifikation wirklich löschen?",
  "rpaQual.transition.reconsider": "Überdenken",
  "rpaQual.transition.reconsiderMsg":
    "Möchten Sie diesen Fall wirklich überdenken?",
  "rpaQual.transition.archive": "Archivieren",
  "rpaQual.transition.archiveMsg":
    "Möchten Sie diesen Fall wirklich archivieren?",
  "rpaQual.process.archived.success": "Fall archiviert",
  "rpaQual.process.roadMap.success": "Zur Roadmap hinzugefügt",
  "rpaQual.process.reconsider.success": "Gelegenheit überdacht",
  "rpaQual.transition.addToRoadMap": "Zur Roadmap hinzufügen",
  "rpaQual.transition.addToRoadMapMsg":
    "Möchten Sie diesen Fall wirklich Ihrer Roadmap hinzufügen?",
  "rpaQual.filter.show": "Archivierte einschließen",
  "rpaQual.display.nonArchived": "Nicht archiviert",
  "rpaQual.display.archived": "Archiviert",
  "rpaQual.display.all": "Alle",
  "breadcrumbs.label.details": "Details",
  "rpaQual.opportunity.list.empty": "Keine Gelegenheit gefunden!",
  "rpaQual.case.list.empty": "Kein Fall gefunden!",
  "rpaQual.status.submitted": "Ausstehend",
  "rpaQual.status.reconsider": "Ausstehend",
  "rpaQual.status.accepted": "Akzeptiert",
  "rpaQual.status.draft": "Entwurf",
  "rpaQual.status.not submitted": "Nicht validiert",
  "rpaQual.status.rejected": "Abgelehnt",
  "rpaQual.status.roadMap": "Roadmap",
  "filter.graph": "Grafik",
  "filter.export": "Exportieren",

  // Analytics - help
  "help.process": "Bitte wählen Sie den/die von der Analyse betroffenen Prozess(e)",
  "help.tags": "Bitte wählen Sie die betroffenen Tag(s) für die Analyse aus",
  "help.kpi": "Bitte wählen Sie die KPI(s)",
  "help.reportType":
    "Die angezeigte Liste der Berichtstypen basiert auf den gewählten KPI(s)",

  "Select All": "Alle auswählen",
  // permissions

  "Start process": "Prozess starten",
  "Schedule process": "Prozess zeitplan",
  "KPI parameters": "Einstellungen",
  "Financial Parameters": "Finanzielle Parameter",
  "Edit process details": "Prozessdetails bearbeiten",
  "Enable/Disable process": "Prozesse anzeigen/ausblenden",
  "Item exceptions": "Ausnahmen von der Regel",
  "Delete item exceptions": "Artikelausnahmen ausblenden",
  "Retry item exceptions with changed data":
    "Wiederholung von Artikelausnahmen mit geänderten Daten",
  "Retry item exceptions": "Ausnahmen bei der Wiederholung von Artikeln",
  Workflow: "Workflow",
  "Process execution exceptions": "Ausnahmen bei der Prozessausführung",
  "Delete process execution exceptions": "Ausnahmen bei der Prozessausführung löschen",
  "View process exceptions": "Prozessausnahmen anzeigen",
  "Delete process exceptions": "Prozessausnahmen ausblenden",
  "View item exceptions": "Artikel ansehen Ausnahmen",
  "Add opportunity": "Gelegenheit hinzufügen",
  "Accept / Reject / Reconsider": "Annehmen / Ablehnen / Überdenken",
  "Archive / Backlog": "Archiv / Backlog",
  "Delete opportunity": "Gelegenheit löschen",
  "Add client": "Add client",
  "Edit client": "Edit client",
  "Delete client": "Delete client",
  "Add user": "Add user",
  "Edit user": "Edit user",
  "Delete user": "Delete user",
  "User Permissions": "User Permissions",
  "Add Orchestrators": "Add orchestrators",
  "Edit Orchestrators": "Edit orchestrators",
  "Delete Orchestrators": "Delete orchestrators",

  "process-occurence.cutsomdate": "Benutzerdefiniertes Datum",
  "exception.analytics.by.process": "Ausnahmen nach Prozess",
  "exception.analytics.by.type": "Zusammenfassung der Ausnahmearten",
  "exception.analytics.by.reason": "Top-Ausnahmeursachen",
  "exception.analytics.by.trend": "Ausnahmetrend",

  "Execution Time (Descending)": "Ausführungsdauer (absteigend)",
  "Execution Time (Ascending)": "Ausführungsdauer (aufsteigend)",

  "no.process.message": "Keine Prozesse erkannt",

  "no.completedItems.message": "Keine abgeschlossenen Elemente erkannt",
  "no.pendingItems.message": "Keine ausstehenden Elemente erkannt",
  "no.orchestrator.message": "Keine Orchestratoren vorhanden",
  "no.robot.message": "Keine Roboter vorhanden",
  "no.fleets.message": "Keine Bandn vorhanden",
  "no.users.message": "Keine Benutzer vorhanden",
  "no.robots.message": "Keine Roboter vorhanden",
  "no.items.exception.message": "Keine Elementausnahmen gefunden",
  "no.process.exception.message": "Keine Prozessausnahmen gefunden",
  "no.analytics.message": "Keine Prozesse erkannt",
  "no.planned.execution.message": "Keine geplanten Ausführungen gefunden",
  "no.activities.message": "Keine Aktivitäten gefunden",
  "no.execution.message": "Keine Prozessausführung gefunden",
  "no.scheduler.message": "Kein Zeitplan gefunden",

  // ALERT MANAGEMENT
  "alerts.default.tab.title": "Standard-Warnungen",
  "alerts.custom.tab.title": "Benutzerdefinierte Warnungen",

  // DEFAULT ALERTS TABLE HEADER CELLS
  "alert.status": "Status",
  "alert.alertName": "Name",
  "default.alert.event": "Veranstaltung",
  "alert.operator": "Betreiber",
  "alert.condition": "Bedingung",
  "alert.type": "Alert-Typ",
  "report.type": "Berichtstyp",
  "alert.searchAlerts": "Suche Alarme",

  "no.alerts.message": "Keine Alarmierung gefunden!",
  "alerts.default.addButton": "Alarm erstellen",

  // CUSTOM ALERTS TABLE HEADER CELLS
  "custom.alert.KPI": "KPI",
  "custom.alert.process": "Prozess",
  "custom.alert.searchCustomAlerts": "Suche Alerts",
  "alerts.custom.addButton": "Alert hinzufügen",
  "alert.management.delete.confirmMsg":
    "Sind Sie sicher, dass Sie diese Meldung löschen möchten?",

  // Add & Edit alert headers
  "alerts.addHeader": "Alarm hinzufügen",
  "alerts.editHeader": "Alert bearbeiten",
  "alerts.displayHeader": "Details zum Alert",

  // error messages
  "alerts.management.required.fields": "Required Field",

  // confirmation buttons
  "alerts.management.button.update": "Update",
  "alerts.management.button.add": "hinzufügen",
  "alerts.management.button.cancel": "stornieren",
  "alerts.management.button.save": "speichern",
  "alerts.management.button.exit": "beenden",
  "alerts.management.button.discard": "Ablegen",

  "alerts.management.trigger": "Triggern",
  "alerts.management.triggers": "Trigger",
  "alerts.management.notifications": "Notifications",
  "alerts.management.subscribers": "Abonnenten",
  "alerts.management.name.placeholder": "Alarm Name eingeben",
  "alerts.management.name.label": "Alarm Name *",
  "alerts.management.condition.label": "Betrag *",
  "alerts.management.alertDescription.placeholder":
    "Enter the description of the alert",
  "alerts.management.alertDescription.label": "Beschreibung",
  "alerts.management.when.placeholder": "Wenn *",
  "alerts.management.operator.placeholder": "Ist *",
  "alerts.management.process.placeholder": "Von *",
  "alerts.management.of.placeholder": "Von *",

  "alerts.management.email.invalidEmail": "Ungültiges E-Mail-Adressformat",
  "alerts.management.email.invalidEmails": "Ungültiges Format für E-Mail-Adressen",
  "alerts.management.email.invalidPhoneNumber": "Ungültiges Rufnummernformat",
  "alerts.management.email.invalidPhoneNumbers": "Ungültiges Telefonnummernformat",
  "alerts.management.severity": "Alarmtyp *",
  "alerts.management.save.confirmMsg":
    "Sind Sie sicher, dass Sie diese Meldung speichern wollen?",
  "alerts.management.update.confirmMsg":
    "Sind Sie sicher, dass Sie die Alarminformationen aktualisieren möchten?",
  "alerts.management.delete.discard":
    "Sind Sie sicher, dass Sie Ihre Änderungen verwerfen wollen?",
  "Alert Frequency": "Frequenz",
  "alert.management.formControl.saveSuccess": "Neue Ausschreibung erfolgreich hinzugefügt",
  "alert.management.formControl.updateSuccess":
    "Alarminformationen erfolgreich aktualisiert",
  "alert.management.formControl.delete": "Benachrichtigung wurde gelöscht",
  "alert.management.enable.email.notification":
    "Sind Sie sicher, dass Sie die Benachrichtigung per E-Mail für diese Meldung aktivieren möchten?",
  "alert.management.disable.email.notification":
    "Sind Sie sicher, dass Sie die Benachrichtigungen per E-Mail für diese Warnung deaktivieren möchten?",
  "alert.management.enable.sms.notification":
    "Sind Sie sicher, dass Sie die Benachrichtigungen per SMS für diese Warnung aktivieren möchten?",
  "Success Rate": "Erfolgsrate",
  "alert.management.disable.sms.notification":
    "Sind Sie sicher, dass Sie die Benachrichtigungen per SMS für diese Warnung deaktivieren möchten?",
  "Execution Time": "Ausführungsdauer",
  "Process execution duration": "Ausführungsdauer des Prozesses",
  "Total Executions": "Gesamtausführungen",
  "Items success rate": "Erfolgsrate der Elemente",
  "Exceptions Rate": "Ausnahmerate",
  "Items exception rate": "Ausnahmerate der Elemente",
  "Time Saved": "Zeit gespart",
  "Break-Even": "Break-even",
  "Number of completed items": "Anzahl der abgeschlossenen Elemente",
  "create.scheduler": "Zeitplan erstellen",
  "Edit Scheduler": "Zeitplan bearbeiten",
  "First day of month": "Erster Tag des Monats",
  "Last day of month": "Letzter Tag des Monats",
  "Day number": "Tagnummer",
  "alert.management.email.helper":
    "Sie können die Hinzufügung Ihrer E-Mail entweder durch Drücken der Eingabetaste oder der Leertaste bestätigen.",
  "alert.management.sms.helper":
    "Sie können die Hinzufügung Ihrer Telefonnummer entweder durch Drücken der Eingabetaste oder der Leertaste bestätigen.",
  "alert.management.trigger.helper":
    "Im Falle mehrerer Auslöser müssen alle Auslöser wahr sein, damit die Benachrichtigung gesendet wird.",
  Active: "Aktiv",
  info: "Information",
  error: "Fehler",
  EQUAL: "GLEICH",
  TRUE: "WAHR",
  FALSE: "FALSCH",
  "GREATER THAN": "Größer als",
  "LESS THAN": "Kleiner als",
  "GREATER OR EQUAL TO": "Größer oder gleich",
  "LESS OR EQUAL TO": "Kleiner oder gleich",
  "alerts.management.alertName.maxLength.error":
    "Dieses Feld darf maximal 55 Zeichen lang sein!",
  "alerts.management.alertDescription.maxLength.error":
    "Dieses Feld darf maximal 65535 Zeichen lang sein!",
  "select.all": "Alles auswählen",
  "add.fleet.form.vat.number.email.company.name.already.exist":
    "Ein Kunde mit derselben E-Mail-Adresse, Umsatzsteueridentifikationsnummer und Firmennamen existiert bereits.",
  "add.fleet.form.vat.number.email.already.exist":
    "Ein Kunde mit derselben E-Mail-Adresse und derselben Umsatzsteueridentifikationsnummer existiert bereits.",
  "add.fleet.form.vat.number.company.name.already.exist":
    "Ein Kunde mit derselben Umsatzsteueridentifikationsnummer und demselben Firmennamen existiert bereits.",
  "add.fleet.form.email.company.name.already.exist":
    "Ein Kunde mit derselben E-Mail-Adresse und demselben Firmennamen existiert bereits.",
  "add.fleet.form.email.already.exist":
    "Ein Kunde mit derselben E-Mail-Adresse existiert bereits.",
  "add.fleet.form.vat.number.already.exist":
    "Ein Kunde mit derselben Umsatzsteueridentifikationsnummer existiert bereits.",
  "add.fleet.form.company.name.already.exist":
    "Ein Kunde mit demselben Firmennamen existiert bereits.",
  "add.fleet.form.vat.number.email.company.name.siren.already.exist":
    "Ein Kunde mit derselben E-Mail-Adresse, Umsatzsteueridentifikationsnummer, Firmennamen und SIREN existiert bereits.",
  "add.fleet.form.vat.number.email.siren.already.exist":
    "Ein Kunde mit derselben E-Mail-Adresse, Umsatzsteueridentifikationsnummer und SIREN existiert bereits.",
  "add.fleet.form.vat.number.company.name.siren.already.exist":
    "Ein Kunde mit derselben Umsatzsteueridentifikationsnummer, Firmennamen und SIREN existiert bereits.",
  "add.fleet.form.vat.number.siren.already.exist":
    "Ein Kunde mit derselben Umsatzsteueridentifikationsnummer und derselben SIREN existiert bereits.",
  "add.fleet.form.email.company.name.siren.already.exist":
    "Ein Kunde mit derselben E-Mail-Adresse, Firmennamen und SIREN existiert bereits.",
  "add.fleet.form.email.siren.already.exist":
    "Ein Kunde mit derselben E-Mail-Adresse und derselben SIREN existiert bereits.",
  "add.fleet.form.company.name.siren.already.exist":
    "Ein Kunde mit demselben Firmennamen und derselben SIREN existiert bereits.",
  "add.fleet.form.siren.already.exist":
    "Ein Kunde mit derselben SIREN existiert bereits.",
  "robot.management.name": "Name",
  "robot.management.formControl.actionsRunning": "Laufende Aktionen",
  "robot.management.formControl.processesRunning": "Laufende Prozesse",
  "robot.management.formControl.robotName": "Robotname",
  "robot.management.formControl.robotOriginalName":
    "Ursprünglicher Ressourcenname",
  "robot.management.formControl.statusDescription": "Statusbeschreibung",
  "robot.management.formControl.robotId": "Robot-ID",
  "robot.management.formControl.unitsAllocated": "Zugewiesene Einheiten",
  "robot.management.solutionProvider": "Lösungsanbieter",
  "robot.management.displayStatus": "Anzeigestatus",
  "robot.management.orchestratorName": "Orchestrator",
  "robot.management.attributeName": "Robot-Typ",
  "robot.management.processesRunning": "Laufende Prozesse",
  "robot.management.robotId": "Robot-ID",
  "robot.management.robotName": "Robotname",
  "robot.management.searchRobot": "Robot suchen",
  "Routes.orchestrators.administration.robots": "Robots",
  "robot.management.displayHeader": "Roboterdetails",
  "robot.management.enable.confirmMsg":
    "Sind Sie sicher, dass Sie diesen Roboter aktivieren möchten?",
  "robot.management.disable.confirmMsg":
    "Sind Sie sicher, dass Sie diesen Roboter deaktivieren möchten?",
  "robot.management.disable.button.confirm": "Deaktivieren",
  "robot.management.enable.button.confirm": "Aktivieren",
  "robot.button.update": "Aktualisieren",
  "robot.button.cancel": "Abbrechen",
  "robot.button.discard": "Verwerfen",
  "robot.update.confirmMsg":
    "Sind Sie sicher, dass Sie die Robotinformationen aktualisieren möchten?",
  "robot.update.discard":
    "Sind Sie sicher, dass Sie Ihre Änderungen verwerfen möchten?",
  "robot.modify": "Bearbeiten",
  "routes.orchestrators.administration.robots.view": "Anzeigen",
  "routes.orchestrators.administration.robots.edit": "Bearbeiten",
  "routes.orchestrators.administration.robots": "Robots",
  "administration.detail.entity.processes.title": "Prozesse",
  "start.process.no.robot.assigned": "Keine zugewiesenen Roboter",
  "no.process.planned": "Keine geplanten Prozesse",
  Client: "Kunde",
  "Orchestrator Administration": "Orchestrator-Verwaltung",
  "New opportunity created": "Neue Chance erstellt",
  "Opportunity accepted": "Chance akzeptiert",
  "Opportunity rejected": "Chance abgelehnt",
  "Opportunity added to the road map": "Chance zur Roadmap hinzugefügt",
  "Opportunity archived": "Chance archiviert",
  "Process activated": "Prozess aktiviert",
  "Process disabled": "Prozess deaktiviert",
  "Orchestrator enabled": "Orchestrator aktiviert",
  "Orchestrator disabled": "Orchestrator deaktiviert",
  "Orchestrator actionable": "Orchestrator ausführbar",
  "Orchestrator not actionable": "Orchestrator nicht ausführbar",
  "Schedule added": "Plan hinzugefügt",
  "Time Spent": "Verstrichene Zeit",
  "IT Review": "Komplexität",
  Comment: "Kommentar",
  Schedules: "Pläne",
  "Delete Schedule": "Plan löschen",
  "Filter List": "Liste filtern",
  Validate: "Bestätigen",
  "kpi.header.breakEven.tooltip":
    "Der Break-Even-Punkt wird basierend auf historischen Daten geschätzt. Er beantwortet die Frage, wann die Investition in die Prozesse vollständig zurückgewonnen wird. Die Ergebnisse basieren auf den für jeden Prozess konfigurierten finanziellen Parametern und dem Zeitfilter des Dashboards.",
  "kpi.header.roi.tooltip":
    "Beantwortet die Frage, wie viel von der anfänglichen Investition bereits verdient wurde. Die Ergebnisse basieren auf den für jeden Prozess konfigurierten finanziellen Parametern und dem Zeitfilter des Dashboards.",
  "kpi.header.breakEven.tooltip.nodata":
    "Es werden weitere Ausführungen erwartet, um den Break-Even-Punkt zu schätzen.",
  "kpi.header.roi.tooltip.nodata":
    "Es werden weitere Ausführungen erwartet, um das Rentabilitätsverhältnis zu berechnen.",
  "kpi.header.success.rate.tooltip":
    "Die Erfolgsquote wird für Prozesselemente berechnet. Die Ergebnisse basieren auf den für jeden Prozess konfigurierten Einstellungen und dem Zeitfilter des Dashboards.",
  "kpi.header.exception.rate.tooltip":
    "Die Ausnahmequote wird für Prozesselemente berechnet. Die Ergebnisse basieren auf den für jeden Prozess konfigurierten Einstellungen und dem Zeitfilter des Dashboards.",
  "dashboard.graph.executionTime": "Execution time (hr)",
  "dashboard.graph.treatedItems": "Abgeschlossener Artikel",
  "dashboard.graph.pendingItems": "Ausstehender Artikel",
  "dashboard.graph.exceptionItems": "Ausnahmeartikel",
  "dashboard.graph.totalItems": "Gesamtartikel",
  "Submitting...": "Submitting...",
  "NOT VALIDATED": "NOT VALIDATED",
  "tooltip.action.hide": "Ausblenden",
  "tooltip.action.show": "anzeigen",
  "robot.management.deleteRobotActionMessage":
    "Die Sichtbarkeit der ausgewählten Elemente wird entweder auf sichtbar oder auf unsichtbar gesetzt.",
  "tooltip.action.showHiddenItems": "Versteckt anzeigen",
  "exception.analytics.typeNotFound": "Exception type not specified",
  "orchestrator.management.formControl.sessionNumber":
    "Anzahl der erlaubten Sitzungen",
  "profile.user.general.title": "Allgemeine Informationen",
  "profile.user.general.security": "Security",
  "rpa.export.rpa": "RPA",
  "rpa.export.qualification": "QUALIFICATION",
  "rpa.creat.businessApplication": "Liste hier Anwendungen auf",
  "user.add.admin": "Admin Benutzer",
  "Items status by process": "Status der Elemente nach Prozess",
  "Average Handling Time": "Durchschnittliche Bearbeitungszeit",
  "Current Period": "aktuelle Periode",
  "Last Period": "letzte Periode",
  "use intervals": "Intervalle verwenden",
  "use cumulative": "Kumulative verwenden",
  "please enter at least one email to enable email notification":
    "Bitte geben Sie mindestens eine E-Mail ein, um die E-Mail-Benachrichtigung zu aktivieren.",
  "please enter at least one phone number to activate the sms notification":
    "Bitte geben Sie mindestens eine Rufnummer ein, um die SMS-Benachrichtigung zu aktivieren.",
  Exception: "Exception",
  Success: "Success",
  "The calculation of the KPIs will be based on an interval":
    "Die Berechnung der KPIs erfolgt auf der Grundlage eines Intervalls.",
  "Calculation of the KPIs for the whole time":
    "Berechnung der KPIs für die gesamte Zeit.",
  "Receive alerts from the following date":
    "Sie erhalten Benachrichtigungen ab dem folgenden Datum.",
  "Stop receiving alerts from the following date":
    "ab dem folgenden Datum keine Benachrichtigungen mehr erhalten.",
  "dashboard.tooltip.timesaved":
    "Dieses Diagramm zeigt die Verteilung der eingesparten Zeit (in Stunden) über den im Dashboard-Zeitfilter ausgewählten Zeitraum. Die Ergebnisse werden auf der Grundlage der für jeden Prozess konfigurierten Finanzparameter und des Prozessfilters des Dashboards berechnet.",
  "dashboard.tooltip.processOverview":
    "Dieses Diagramm zeigt die Verteilung der Ausführungszeit der einzelnen Prozesse im Vergleich zu allen Prozessen. Die Ergebnisse basieren auf dem Zeitfilter des Dashboards.",
  "dashboard.tooltip.itemsProcess":
    "Dieses Diagramm zeigt die Aufschlüsselung des Status der Elemente für die wichtigsten Prozesse. Die Ergebnisse basieren auf dem Zeitfilter des Dashboards.",
  "dashboard.tooltip.executionsProcess":
    "Dieses Diagramm zeigt die Aufschlüsselung der Prozessausführungen über den im Zeitfilter des Dashboards ausgewählten Zeitraum.",
  "dashboard.tooltip.averageTime":
    "Die durchschnittliche Bearbeitungszeit ist die durchschnittliche Zeit, die für die Ausführung einer Position in diesem Prozess in dem im Zeitfilter ausgewählten Zeitraum benötigt wurde.",
  STOPPED: "Gestoppt",
  COMPLETED: "Abgeschlossen",
  DEBUGGING: "Debugging",
  ARCHIVED: "Archiviert",
  STOPPING: "Stoppen",
  WARNING: "Warnung",
  "status.running.with.warning": "Läuft mit einer Warnung",
  "process.history.duration": "Dauer der Ausführung",
  "process.history.completedItems": "Abgeschlossener Artikel",
  "process.history.exceptionItems": "Ausnahmeartikel",
  "process.history.robot": "Roboter",
  AUTOMATIC: "Automatisch",
  MANUAL: "Manuell",

  "Process data mapping": "Kartierung von Daten",
  "Are you sure you want to update the mapping ?":
    "Sind Sie sicher, dass Sie das Mapping aktualisieren wollen?",
  "no.data-mapping.message":
    "Das Mapping kann erst nach der Verarbeitung des ersten Prozesspunktes erfolgen.",
  Data: "Daten",
  "New name": "neuer Name",
  Display: "Anzeige",
  "display.in.items.list": "In der Artikelliste anzeigen",
  "Orchestrator configuration": "Orchestrator configuration",
  "SmartRoby will add columns and create views and triggers on your orchestrator database":
    "SmartRoby will add columns and create views and triggers on your orchestrator database.",
  "Initialize connectors to synchronize data between your orchestrator and SmartRoby":
    "Initialize connectors to synchronize data between your orchestrator and SmartRoby.",
  "sync.Execute": "Ausführen",
  "sync.Initialize": "Initialisieren",
  "Database configuration": "Database configuration",
  "Orchestrator/SmartRoby synchronization":
    "Orchestrator/SmartRoby synchronization",
  "Orchestrator status": "Orchestrator status",
  "sync.Refresh": "aktualisieren",
  "sync.General": "Allgemein",
  "Database connection": "Verbindung zur Datenbank",
  "Synchronization engine": "Synchronization engine",
  "sync.Synchronization": "Synchronization",
  "sync.process": "Prozesse",
  "sync.execution": "Vollstreckungen",
  "sync.resource": "Ressourcen",
  "sync.schedule": "Zeitpläne",
  "sync.work_queue_item": "Artikel",
  "Number of items with Business Exceptions":
    "Number of items with Business Exceptions",
  "Number of items with System/Internal Exception":
    "Number of items with System/Internal Exception",
  "Number of process executions with Business Exceptions":
    "Number of process executions with Business Exceptions",
  "Number of process executions with System/Internal Exception":
    "Number of process executions with System/Internal Exception",
  "sync.deleted_record": "Records deletion",
  "sync.license": "Licenses",
  "robot.management.hide.confirmMsg":
    "Are you sure you want to hide this robot?",
  "robot.management.show.confirmMsg":
    "Are you sure you want to show this robot?",
  "filter.clear": "Filter löschen",
  Reasons: "Reasons",
  "List of conflicts for": "List of conflicts for ",
  allowedSessionsExceededForAnOrchestrator:
    "{{parallelExecutions}} parallel executions were found whereas the orchestrator : '{{OrhcestratorName}}' has only {{allowedSessions}} session(s) allowed.",
  concurrentExecutionInTheSameRobotMessage:
    "More than one execution was found in robot : '{{robotName}}' at {{executionDate}} between {{plannerStart}} and {{plannerEnd}}. The other planned executions will begin after the first one has been completed according to this order : ",
  "Number of pending items": "Number of pending items",
  "kpi.parameter.allowScheduling": "Kann geplant werden",
  "kpi.parameter.allowManual": "Kann manuell gestartet werden",
  "kpi.parameter.retryable": "Ausnahmesendungen können erneut versucht werden",
  "kpi.parameter.retryableWithData":
    "Ausnahmesituationen können mit anderen Daten erneut versucht werden",
  "kpiParam.schedule.warning":
    "Dieser Prozess hat bereits Zeitpläne. Wenn Sie fortfahren, werden sie alle gelöscht.",
  "Warnings Management": "Verwaltung von Warnungen",
  "processStore.year": "Jahr",
  "processStore.years": "Jahre",
  "can.be.edited.for.retry": "Kann für einen erneuten Versuch bearbeitet werden",
  retry: "Wiederholung",
  "retry-dialog.title.one-item": "Wiederholung der Item-Ausnahme",
  "retry-dialog.title.multiple-items": "Wiederholung von {{count}} Elementausnahmen",
  "retry-dialog.text.one-item":
    "Sind Sie sicher, dass Sie diese Ausnahme wiederholen wollen? Das Element wird bei der nächsten Ausführung des Prozesses erneut versucht.",
  "retry-dialog.text.multiple-items":
    "Sind Sie sicher, dass Sie {{count}} Ausnahmen wiederholen wollen? Die Elemente werden bei der nächsten Ausführung ihrer Prozesse erneut versucht.",
  "retry-dialog.pending-alert":
    "Für dieses Element existiert bereits ein ausstehender Wiederholungsversuch.",
  "retry-dialog.pending-alert-multiple": "Für diese Elemente gibt es bereits ausstehende Wiederholungsversuche",
  "retry-dialog.message.warning.pending": " (Artikel, für die bereits ein Wiederholungsversuch ansteht, werden nicht erneut versucht)",
  "retry-dialog.not-retryable":
    "Die ausgewählten Elemente gehören zu einem Prozess, der nicht wiederholt werden kann.",
  "retry-dialog.radio.same-data": "Mit denselben Daten wiederholen",
  "retry-dialog.radio.changed-data": "Mit geänderten Daten wiederholen",
  "retry-dialog.table.name": "Name",
  "retry-dialog.table.old-value": "Alter Wert",
  "retry-dialog.table.new-value": "Neuer Wert",
  "retry-dialog.message.success.one-item":
    "Der Wiederholungsversuch des Elements wurde erfolgreich erstellt.",
  "retry-dialog.message.success.multiple-items":
    "{{count}} Wiederholungsversuche wurden erfolgreich erstellt.",
  "retry-dialog.message.error.one-item":
    "Fehler beim Erstellen des Wiederholungsversuchs des Elements.",
  "retry-dialog.message.error.multiple-items":
    "Fehler beim Erstellen der Wiederholungsversuche.",
  "restriction.form.hour.maxValue.error":
    "Der Wert muss kleiner oder gleich 23 sein.",
  "restriction.form.min.sec.maxValue.error":
    "Der Wert muss kleiner oder gleich 59 sein.",
  attempt: "Versuch",
  "attempt.chip.tooltip": "Dies ist der {{attempt}}. Versuch dieses Elements",
  "exception-workflow.status.ready": "Bereit",
  "exception-workflow.status.taken": "Zugewiesen an {{firstname}} {{lastname}}",
  "exception-workflow.status.done": "Erledigt",
  "exception-workflow.action.take": "Übernehmen",
  "exception-workflow.action.assign": "Zuweisen",
  "exception-workflow.action.release": "Freigeben",
  "exception-workflow.action.reopen": "Wiedereröffnen",
  "exception-workflow.action.markAsDone": "Als erledigt markieren",
  "exception-workflow.log.take":
    "Übernommen von <b>{{user.firstname}} {{user.lastname}}</b>",
  "exception-workflow.log.assign":
    "Zugewiesen an <b>{{targetUser.firstname}} {{targetUser.lastname}}</b> von <b>{{user.firstname}} {{user.lastname}}</b>",
  "exception-workflow.log.markAsDone":
    "Als erledigt markiert von <b>{{user.firstname}} {{user.lastname}}</b>",
  "exception-workflow.log.release":
    "Freigegeben von <b>{{user.firstname}} {{user.lastname}}</b>",
  "exception-workflow.log.reopen":
    "Wiedereröffnet von <b>{{user.firstname}} {{user.lastname}}</b>",
  "exception-workflow.snackbar.success":
    "Die Aktion wurde erfolgreich durchgeführt",
  "exception-workflow.snackbar.error":
    "Die Aktion konnte nicht durchgeführt werden",
  "exception-workflow.confirm.take":
    "Möchten Sie diese Ausnahme wirklich übernehmen?",
  "exception-workflow.confirm.assign":
    "Möchten Sie diese Ausnahme wirklich an {{selectedUser.firstName}} {{selectedUser.lastName}} zuweisen?",
  "exception-workflow.confirm.markAsDone":
    "Möchten Sie diese Ausnahme wirklich als erledigt markieren?",
  "exception-workflow.confirm.release":
    "Möchten Sie diese Ausnahme wirklich freigeben?",
  "exception-workflow.confirm.reopen":
    "Möchten Sie diese Ausnahme wirklich wiedereröffnen?",
  "exception-workflow.open": "Workflow",
  "exception-workflow.open-bulk": "Workflow",
  "exception-workflow.no-bulk-action-possible":
    "Für die ausgewählte Kombination von Ausnahmen ist keine Aktion möglich",
  "exception-workflow.selected-message": "{{count}} Ausnahmen sind ausgewählt",
  "exception-workflow.confirm.bulk-take":
    "Möchten Sie wirklich {{count}} Ausnahmen übernehmen?",
  "exception-workflow.confirm.bulk-assign":
    "Möchten Sie wirklich {{count}} Ausnahmen an {{selectedUser.firstName}} {{selectedUser.lastName}} zuweisen?",
  "exception-workflow.confirm.bulk-markAsDone":
    "Möchten Sie wirklich {{count}} Ausnahmen als erledigt markieren?",
  "exception-workflow.confirm.bulk-release":
    "Möchten Sie wirklich {{count}} Ausnahmen freigeben?",
  "exception-workflow.confirm.bulk-reopen":
    "Möchten Sie wirklich {{count}} Ausnahmen wiedereröffnen?",
  "exception-workflow.choose-user": "Benutzer auswählen",
  "exception-workflow.snackbar.success.take":
    "Die Ausnahme wurde erfolgreich übernommen",
  "exception-workflow.snackbar.success.assign":
    "Die Ausnahme wurde erfolgreich zugewiesen",
  "exception-workflow.snackbar.success.assign.notify":
    "Der Zuweiser wurde benachrichtigt",
  "exception-workflow.snackbar.success.release":
    "Die Ausnahme wurde erfolgreich freigegeben",
  "exception-workflow.snackbar.success.markAsDone":
    "Die Ausnahme wurde erfolgreich als erledigt markiert",
  "exception-workflow.snackbar.success.reopen":
    "Die Ausnahme wurde erfolgreich wiedereröffnet",
  "exception-workflow.snackbar.success.bulkTake":
    "Die Ausnahmen wurden erfolgreich übernommen",
  "exception-workflow.snackbar.success.bulkAssign":
    "Die Ausnahmen wurden erfolgreich zugewiesen",
  "exception-workflow.snackbar.success.bulkRelease":
    "Die Ausnahmen wurden erfolgreich freigegeben",
  "exception-workflow.snackbar.success.bulkMarkAsDone":
    "Die Ausnahmen wurden erfolgreich als erledigt markiert",
  "exception-workflow.snackbar.success.bulkReopen":
    "Die Ausnahmen wurden erfolgreich wiedereröffnet",
  "processe.schedules.onDay.first": " - Am {{startDay}}. Tag",
  "processe.schedules.onDay.second": " - Am {{startDay}}. Tag",
  "processe.schedules.onDay.third": " - Am {{startDay}}. Tag",
  "processe.schedules.onDay": " - Am {{startDay}}. Tag",
  "orchestrator.license.originalId": "Original-Lizenz-ID",
  "orchestrator.license.starts": "Beginnt",
  "orchestrator.license.expires": "Läuft ab",
  "orchestrator.license.installedOn": "Installiert am",
  "orchestrator.license.maxConcurrentSessions": "Anzahl der Sitzungen",
  "orchestrator.license.status": "Status",
  "orchestrator.license.status.ACTIVE": "Aktiv",
  "orchestrator.license.status.EXPIRED": "Abgelaufen",
  "orchestrator.license.status.NOT_YET_ACTIVE": "Noch nicht aktiv",
  "tooltip.action.license": "Lizenzen",
  "A license is one month away from expiry":
    "Eine Lizenz läuft in einem Monat ab",
  "A license is two months away from expiry":
    "Eine Lizenz läuft in zwei Monaten ab",
  "Orchestrator licenses": "Orchestrator-Lizenzen",
  "process.laststage": "LETZTES STADIUM: {{lastStage}}",
  "analytics.preset.button.label": "Voreinstellungen",
  "analytics.preset.load": "Vorhandene Voreinstellung laden",
  "analytics.preset.create": "Neue Voreinstellung erstellen",
  "analytics.preset.noPresets": "Keine gespeicherten Voreinstellungen",
  "analytics.preset.dialog.label": "Neue Voreinstellung",
  "analytics.preset.dialog.field.label": "Name der Voreinstellung",
  "analytics.preset.dialog.field.submit": "Voreinstellung erstellen",
  "analytics.preset.dialog.delete.label": "Voreinstellung löschen",
  "analytics.preset.dialog.delete.text":
    "Sind Sie sicher, dass Sie diese Voreinstellung löschen möchten?",
  "analytics.preset.dialog.delete.submit": "Voreinstellung löschen",
  "robot.tooltip.hide": "Sind Sie sicher, dass Sie {{count}} Roboter ausblenden wollen?",
  "robot.tooltip.unhide": "Sind Sie sicher, dass Sie {{count}} Roboter ausblenden wollen?",
  "export.confirmation.radio.grouped": "Export mit gruppierten Artikeldetails.",
  "export.confirmation.radio.columns": "Export mit Artikeldetails in Spalten.",
  "export.confiramtion.warnning":
    "Um diese Option zu aktivieren, müssen Sie einen Prozess auswählen.",
  FINISHED: "ENDGÜLTIG",
  EXPORTING: "EXPORT",
  COLLECTING: "COLLECTING",
  "exporting.title": "Dateien exportieren",
  "exporting.no.data": "Keine Dateien zum Herunterladen",
  "exporting.btn.download.all": "Alle herunterladen",
  "exporting.btn.delete.all": "Alle löschen",
  Executions: "Prozessaus-führungen",
  "Executions history": "Geschichte der Exekutionen",
  "routes.orchestrators.executions": "Prozess-Ausführungen",
  "no.executions.message": "Keine Hinrichtungen gefunden",
  Robot: "Roboter",
  Trigger: "Triggern",
  MANUALLY: "Manuell",
  SCHEDULED: "Geplant",
  KEEP: "In der Warteschlange behalten",
  DELETE: "Aus Warteschlange löschen",
  Dates: "Daten",
  "Launching date": "Datum der Einführung",
  "Launching date (Descending)": "Einführungsdatum (absteigend)",
  "Launching date (Ascending)": "Einführungsdatum (aufsteigend)",
  "queue.management.title": "SR Warteschlangenverwaltung",
  "SR Queues": "SR-Warteschlangen",
  "queue.management.status": "Status",
  "queue.management.name": "Name",
  "queue.management.creation-date": "Datum der Erstellung",
  "queue.management.active-since": "Aktiv seit",
  "queue.management.nb-licences": "Anzahl der Lizenzen",
  "queue.management.orchestrator": "Orchestrator",
  "queue.management.search": "Suchen",
  "queue.management.new": "SR-Warteschlange erstellen",
  "queue.management.add": "Warteschlange hinzufügen",
  "queue.management.delete.confirm-msg":
    "Sind Sie sicher, dass Sie die ausgewählte Warteschlange löschen möchten?",
  "queue.management.bulk-delete.confirm-msg":
    "Sind Sie sicher, dass Sie die ausgewählten Warteschlangen löschen wollen?",
  "queue.management.enable.confirm-msg":
    "Sind Sie sicher, dass Sie diese Warteschlange aktivieren wollen?",
  "queue.management.enable.confirm": "Warteschlange aktivieren",
  "queue.management.disable.confirm-msg":
    "Sind Sie sicher, dass Sie diese Warteschlange deaktivieren möchten?",
  "queue.management.disable.confirm": "Warteschlange deaktivieren",
  "queue.management.section.info": "Allgemeine Informationen",
  "queue.management.section.processes": "Prozesse",
  "queue.management.section.robots": "roboter",
  "queue.management.form.save.success": "Die SR-Warteschlange wurde erfolgreich erstellt",
  "queue.management.form.update.success":
    "Die SR-Warteschlange wurde erfolgreich aktualisiert",
  "queue.management.form.save.error": "Die SR-Warteschlange konnte nicht erstellt werden",
  "queue.management.form.update.error": "Aktualisierung der SR-Warteschlange fehlgeschlagen",
  "queue.management.form.validation.required": "Dieses Feld ist erforderlich",
  "queue.management.form.validation.negative-nb-licences":
    "Anzahl der Lizenzen kann nicht negativ sein",
  "queue.management.update": "Warteschlange aktualisieren",
  "queue.management.save.confirm-msg":
    "Sind Sie sicher, dass Sie diese SR-Warteschlange erstellen wollen?",
  "queue.management.update.confirm-msg":
    "Sind Sie sicher, dass Sie diese SR-Warteschlange aktualisieren wollen?",
  "queue.management.reset.confirm-msg":
    "Sind Sie sicher, dass Sie alle verpassten Ausführungen dieser SR-Warteschlange löschen wollen?",
  "restriction.form.missedExecutionsPolicy": "Politik der versäumten Hinrichtungen",
  "restriction.userGuide.missedExecutionsPolicy":
    "Gibt an, ob eine verpasste Ausführung in der SR-Warteschlange verbleiben oder aus ihr entfernt werden soll.",
  "queue.management.choose.robot":
    "Einige Zeitpläne sind keinem Roboter zugewiesen. Wenn Sie sie zum ursprünglichen Orchestrator migrieren, weisen Sie sie zu:",
  "queue.management.assign.process.part1":
    "Sie sind dabei, die Ausführung von {{this_process}} an die SR Queues zu delegieren. Um die erwartete Leistung zu gewährleisten, sollten Sie davon absehen, Zeitpläne zu erstellen und die Ausführung außerhalb von SmartRoby zu starten.",
  "queue.management.assign.process.part2":
    "Es wurde ein Zeitplan für {{diesen_Prozess}} bei seinem ursprünglichen Orchestrator gefunden. Möchten Sie ihn zur Verwendung mit den SR-Warteschlangen migrieren?",
  "queue.management.assign.process.part2_plural":
    "Es wurden Zeitpläne für {{nbSchedules}} für {{this_process}} bei seinem ursprünglichen Orchestrator gefunden. Möchten Sie diese für die Verwendung mit den SR-Warteschlangen migrieren?",
  "queue.management.unassign.process.part1":
    "Sie sind im Begriff, die Delegation der Ausführungen von {{this_process}} aus den SR-Warteschlangen aufzuheben. In Zukunft können alle Ausführungen und Zeitpläne entweder von SmartRoby oder von Ihrem ursprünglichen Orchestrator stammen.",
  "queue.management.unassign.process.part2":
    "Es wurde ein SR-Warteschlangenplan gefunden. Möchten Sie es auf den ursprünglichen Orchestrator migrieren?",
  "queue.management.unassign.process.part2_plural":
    "{{nbSchedules}} Es wurden Zeitpläne für SR-Warteschlangen gefunden. Möchten Sie sie zum ursprünglichen Orchestrator migrieren?",
  thisProcess: "this process",
  thisProcess_plural: "the queue processes",
  atIts: "at its",
  atIts_plural: "at their",
  some: "Some",
  "robot-selector.available-robots": "verfügbar Roboter",
  "robot-selector.assigned-robots": "Zugewiesene Roboter",
  "queue.management.form.validation.nb-licences-bigger-than-remaining":
    "Im ausgewählten Orchestrator sind noch {{count}} Lizenzen vorhanden.",
  "queue.management.no-data": "Noch keine Warteschlangen",
  "queue.management.open-queue": "In SR-Warteschlange öffnen",
  "queue.management.reset-queue": "SR-Warteschlange zurücksetzen",
  "execution.manager.title": "SR-Warteschlange",
  "queue reseted successfully": "Die SR-Warteschlange wurde erfolgreich zurückgesetzt.",
  "error while reseting queue": "Die SR-Warteschlange konnte nicht zurückgesetzt werden.",
  "Add SR Queue": "SR-Warteschlange hinzufügen",
  "Edit SR Queue": "SR-Warteschlange bearbeiten",
  "Delete SR Queue": "SR-Warteschlange löschen",
  "process.assigned.srqueue.tooltip":
    "Dieser Prozess ist einer SR-Warteschlange zugeordnet.",
  "number of running executions singular":
    "Eine Ausführung ist bereits im Gange",
  "number of running executions plural":
    "Einige Hinrichtungen sind bereits im Gange",
  "not enough licenses":
    ", Sie haben keine verfügbaren Lizenzen. Bitte wenden Sie sich an Ihren RPA-Administrator.",
  "check.connectivity":
    "Bitte überprüfen Sie die Verbindung zum App-Server {{serverName}}. Es scheint, dass die Verbindung verloren gegangen ist.",
  "deleted by sr policy": "Ein Zeitplan wird durch die SR-Warteschlangenrichtlinie gelöscht",
  "execution is deleted": "Manuell gelöschte SR-Queue-Ausführung",
  "execution is missed": "Es fehlt eine SR-Queue-Ausführung",
  "missed reason": "Verpasst, weil",
  UNAVAILABLE_ROBOTS: "Nicht verfügbare Roboter",
  NOT_ENOUGH_LICENSES: "Nicht genügend Lizenzen",
  BP_UNREACHABLE: "BP unerreichbar",
  BP_UNRESPONSIVE: "BP reagiert nicht",
  "robot.formControl.succesUpdate": "Roboterinformationen erfolgreich aktualisiert",
  "cant perform multi queue migration":
    "Für die ausgewählten Warteschlangen wurden einige SR-Warteschlangenpläne gefunden. Sie können nicht auf den ursprünglichen Orchestrator migriert werden.",
  "something went wrong":
    "Bei der Verarbeitung Ihrer Anfrage ist ein Fehler aufgetreten. Bitte erneut versuchen.",
  Continue: "Fortsetzen",
  "Something.Wrong": "Something went wrong",
  "export.successful.notification":
    "Ihre Datei wurde dem Bereich für Exportdateien hinzugefügt und steht nun für Sie zum Zugriff und Download bereit. Das Hinzufügen war erfolgreich.",
  revoke: "widerrufen",
  // Financial parameters
  "Time Saved per Item": "Zeitersparnis pro artikel",
  "Time saved for each completed item of this process (in minutes)": "Zeitersparnis für jedes abgeschlossene Element dieses Prozesses (in Minuten)",
  "Time Saved per Execution": "Zeitersparnis pro ausführung",
  " Time saved for each execution of this process excluding the time saved from every item (in minutes)": "Zeitersparnis bei jeder Ausführung dieses Prozesses ohne die Zeitersparnis bei jedem Element (in Minuten)",
  "FTE hourly cost": "Vollzeitäquivalent-Stundenkosten",
  "Cost Configuration": "Kosten Konfiguration",
  "Cancel ": "Abbrechen",
  "Save ": "Speichern sie",
  "Cost Allocation": "Kostenzuweisung",
  "Add cost": "Kosten hinzufügen",
  Category: "Kategorie",
  "End Date": "Enddatum",
  Type: "Typ",
  "Allocation Amount": "Zuteilungsbetrag",
  "Cost Name": "Name der Kosten",
  "Cost Type": "Kostenart",
  "Cost Amount": "Kosten Betrag",
  "division.formSection.divisionInfo": "Abteilungsinformationen",
  "division.formSection.divisionProcess": "Abteilungsprozesse",
  "division.formSection.divisionUser": "Abteilungsbenutzer",
  "division.formSection.divisionAdmin": "Abteilungsadministratoren",
  "division.name": "Abteilungsname",
  "division.description": "Beschreibung",
  "division.fleet": "Bandnname",
  "division.cancel": "Abbrechen",
  "division.previous": "Zurück",
  "division.save": "Speichern",
  "division.next": "Weiter",
  "divisions.module.add.button": "Neue Abteilung",
  "usersSelector.availableUsers": "Verfügbare Benutzeren",
  "usersSelector.assignedUsers": "Zugewiesene Benutzeren",
  "usersSelector.availableAdmins": "Verfügbare Administratoren",
  "usersSelector.assignedAdmins": "Zugewiesene Administratoren",
  "divisions.module.title": "Abteilungsverwaltung",
  "divisions.status": "Status",
  "divisions.division": "Abteilung",
  "divisions.fleet": "Band",
  "divisions.creation.date": "Erstellungsdatum",
  "division.form.updateSuccess": "Abteilung erfolgreich aktualisiert",
  "division.form.addSuccess": "Abteilung erfolgreich hinzugefügt",
  "divisions.module.delete.confirmMsg": "Möchten Sie diese Abteilung wirklich löschen?",
  "divisions.module.deleteClientsActionMessage": "Möchten Sie wirklich alle diese Abteilungen löschen?",
  "divisions.module.enaDes.confirmMsg": "Möchten Sie diese Abteilung wirklich aktivieren?",
  "divisions.module.enaDes.button.confirm": "Aktivieren",
  "divisions.module.desaDes.button.confirm": "Deaktivieren",
  "divisions.module.desaDes.confirmMsg": "Möchten Sie diese Abteilung wirklich deaktivieren?",
  "divisions.module.searchClient": "Abteilung suchen",
  "division deleted successfully": "Abteilung erfolgreich gelöscht",
  "no.division.message": "Keine Abteilung gefunden.",
  "fleet.formSection.divisions": "Abteilungen",
  "divisionsSelector.availableDivision": "Verfügbare Abteilungen",
  "divisionsSelector.assignedDivision": "Zugewiesene Abteilungen",
  "custom.kpi.item": "Elemente",
  "custom.kpi.process": "Prozess",
  "custom.kpi.processExecution": "Prozessausführungen",
  "custom.kpi.pending": "Ausstehend",
  "custom.kpi.completed": "erfolgreich Abgeschlossen",
  "custom.kpi.completedWithException": "Abgeschlossen mit Ausnahme",
  "custom.kpi.completedWithBusinessException": "Abgeschlossen mit geschäftlicher Ausnahme",
  "custom.kpi.completedWithIntSysException": "Abgeschlossen mit internem/technischem Fehler",
  "custom.kpi.executionTimeHours": "Ausführungszeit (Stunden)",
  "custom.kpi.timeSavedHours": "Zeitersparnis (Stunden)",
  "reporting.createKpi": "Neuen KPI erstellen",
  "custom.kpi.dialog.create.label": "Neuer KPI",
  "custom.kpi.dialog.field.name": "KPI-Name",
  "custom.kpi.dialog.field.element": "Für jedes",
  "custom.kpi.dialog.field.detail": "Detail",
  "custom.kpi.dialog.field.allocation": "zuordnen",
  "custom.kpi.dialog.field.unitofmesure": "Maßeinheit",
  "custom.kpi.formControl.required": "Pflichtfeld",
  "custom.kpi.formControl.pattern": "Bitte geben Sie eine Zahl ein",
  "custom.kpi.dialog.create.field.submit": "KPI ERSTELLEN",
  "custom.kpi.dialog.delete.label": "KPI löschen",
  "custom.kpi.dialog.delete.text": "Möchten Sie diesen KPI wirklich löschen?",
  "custom.kpi.dialog.delete.submit": "KPI löschen",
  "custom.kpi.dialog.create.label.edit": "KPI bearbeiten",
  "custom.kpi.dialog.create.field.edit": "KPI BEARBEITEN",
  "fleets.fleet": "Band",
  "no.fleet.message": "Noch keine Bandn",
  "instance.owner": "Instanzinhaber",
  "instance.owner.confirmMsg": "Sind Sie sicher, dass Sie mit der Zuweisung eines neuen Instanzinhabers fortfahren möchten?/nDiese Aktion führt dazu, dass Ihre Band ihren Instanzinhaber-Status verliert.",
  "instance.owner.can.not.be.disabled": "Der Instanzbesitzer kann nicht deaktiviert werden",
  "orchestrator.management.formControl.isPrivate": "Privater Orchestrator",
  private: "Privatgelände",
  "fleet.with.no.users": "Diese Band hat keine Benutzer, kann sie nicht zum Instanzbesitzer machen",
  "user.fleet.disabled.message": "Die Band dieses Benutzers ist deaktiviert, dieser Benutzer kann nicht aktiviert werden.",
  "instance.owner.only.active.admin.can.not.be.disabled": "Der einzige aktive Administrator des Instanzeigentümers kann nicht deaktiviert werden",
  "fleet.with.no.admins": "Diese Band hat keine Administratoren, kann nicht als Instanzbesitzer festgelegt werden",
  "search.available.processes": "verfügbare Prozesse durchsuchen",
  "search.assigned.processes": "zugewiesene Prozesse durchsuchen",
  "search.available.tags": "verfügbare Tag durchsuchen",
  "search.available.users": "verfügbare Benutzer durchsuchen",
  "search.assigned.users": "zugewiesene Benutzer durchsuchen",
  "search.available.divisions": "verfügbare Abteilungen durchsuchen",
  "search.assigned.divisions": "zugewiesene Abteilungen durchsuchen",
  "search.available.robots": "verfügbare Roboter durchsuchen",
  "search.assigned.robots": "zugewiesene Roboter durchsuchen",
  "drag.and.drop.here": "Hier ziehen und ablegen",
  "no.tags.message": "Noch keine Tags vorhanden",
  "tooltip.action.archive": "archivieren",
  "archive.confirm.msg": "Ausgewählte Tag(s) werden archiviert",
  "tag.management.btn.confirm": "Archivieren",
  "tag.archived.success": "Tag(s) erfolgreich archiviert",
  "tag.management.deleteTagActionMessage": "Tag(s) erfolgreich archiviert",
  "tag.management.archived": "Archiviert",
  "tag.management.title": "Tag Verwaltung",
  "tag.management.add.button": "Chance tag",
  "tag.status": "Status",
  "tag.icon": "Symbol",
  "tag.name": "Name des Tags",
  "tag.description": "Beschreibung des Tags",
  "tag.management.name": "name",
  "tag.management.formControl.desc": "Beschreibung",
  "wrong.file.type": "Ungültiger Dateityp",
  "invalid license file": "Ungültige Lizenzdatei",
  "invalid signature": "Ungültige Signatur",
  "empty file": "Leere Datei",
  "wrong file extension": "Falsche Dateierweiterung",
  "missing file": "Fehlende Datei",
  "the submitted license file is invalid": "Die übermittelte Lizenzdatei ist ungültig",
  "the signature of the license is invalid": "Die Signatur der Lizenz ist ungültig",
  "the submitted file is empty": "Die übermittelte Datei ist leer",
  "we only accept .lic files": "Wir akzeptieren nur .lic-Dateien",
  "please upload a license file": "Bitte laden Sie eine Lizenzdatei hoch",
  activation: "Aktivierung",
  license: "Lizenz",
  "import your license here": "Importieren Sie hier Ihre Lizenz...",
  activate: "Aktivieren",
  "licence.id": "Lizenz-ID",
  "licence.licensee": "Lizenznehmer",
  "licence.start.at": "Beginnt am",
  "licence.end.at": "Endet am",
  "licence.validation.valid": "Sie haben eine gültige Lizenz.",
  "licence.validation.expire": "Ihre Lizenz ist abgelaufen. Bitte erneuern Sie sie.",
  "licence.goback.btn": "Zur Startseite gehen",
  "tag.management.searchTag": "Tags suchen",
  "tag.management.hide.confirmMsg": "Sind Sie sicher, dass Sie das Tag löschen möchten? Alle Daten werden dauerhaft verloren gehen.",
  "productivity.hrs": "Produktivität",
  Occupancy: "Auslastung (%)",
  "robots.occupancy": "Auslastung",
  "dashboard.monitoring.mode": "Überwachungsmodus",
  "HRS Saved": "Gespeicherte HRS",
  "Robot Productivity": "Roboter-Produktivität",
  "Process Activity": "Prozessaktivität",
  "Working Time (HRS)": "Arbeitszeit (HRS)",
  "Processed Items": "Verarbeitete elemente",
  "HRS Runtime": "HRS-Laufzeit",
  mins: "mins",
  "recently.used.processes": "Am häufigsten verwendete Prozesse",
  "process.total.cost": "Gesamtkosten des Prozesses",
  "tag.management.form.save.success": "Tag erfolgreich gespeichert",
  "tag.management.form.update.success": "Tag erfolgreich aktualisiert",
  "tag.management.form.save.error": "Tag konnte nicht erstellt werden",
  "tag.management.form.update.error": "Tag konnte nicht aktualisiert werden",
  "scheduler.is.required": "Zeitplan ist erforderlich",
  "tooltip.action.close": "Schließen",
  "tag.deleted.success": "Tag(s) erfolgreich gelöscht",
  "try.active.alert": "Der Vorgang {{processName}} ist ausgeblendet. Um diese Warnung zu aktivieren, blenden Sie sie bitte ein.",
  "filter by": "Filtern nach",
  showing: "Zeigt",
  to: "bis",
  from: "von",
  entries: "Einträgen",
  "Data mapping": "Datenzuordnung",
  Temps: "Zeit",
  "server Type": "Servertyp",
  "Automation Impact": "Automatisierungsauswirkung",
  "process.start.restriction": "Der Prozess, den Sie starten, soll heute aufgrund seiner SLAs nicht ausgeführt werden. Möchten Sie wirklich fortfahren?",
  ERRORS_NO_DATA_AVAILABLE: "Keine Daten verfügbar",
  "queue.management.robot.has.pendingExecutions": "Sie haben {{count}} ausstehende Ausführungen mit diesem Roboter, bitte löschen Sie sie, wenn Sie mit dieser Operation fortfahren möchten.",
  "queue.management.robot.has.scheduled.pendingExecutions": "Es sind {{count}} Ausführungen geplant, bitte überprüfen Sie die dem Roboter zugewiesenen Zeitpläne.",
  "occupancy.waiting.more": "Warten auf weitere Ausführung, um die Roboterbelegung zu berechnen",
  "select.process": "Wählen Sie einen Prozess aus",
  "changes.saved.successfully": "Änderungen erfolgreich gespeichert",
  "changes.saving.failed": "Speichern der Änderungen fehlgeschlagen",
  "process.owner": "Prozessinhaber",
  "end date cannot be earlier than the start date": "Das Enddatum darf nicht früher sein als das Startdatum",
  "end date cannot be in the future": "Das Enddatum darf nicht in der Zukunft liegen",
  "no.period.message": "Kein Zeitraum gefunden",
  "no.status.message": "Keine Statusmeldung gefunden",
  "pending.executions.warning": "Warnung: Dieser Vorgang hat ausstehende Ausführungen. Das Entfernen wird sie alle löschen.",
  "calendar.formControl.saveRiskSuccess": "Risikomarge erfolgreich hinzugefügt",
  "export.confirm.msg.delete.all": "Sind Sie sicher, dass Sie alle Dateien löschen möchten ?",
  "export.confirm.msg.delete.one": "Sind Sie sicher, dass Sie diese Datei löschen möchten ?",
  on: "An",
  language: "Sprache",
  "Choose.the.checking.frequency": "Wählen Sie die Überprüfungshäufigkeit",
  "robots.show.hidden": "Verborgen robots tonen",
  "user.logo.allowed.extensions": "Wir akzeptieren nur die Erweiterungen jpg, jpeg, png und bmp",
  "allowed.extensions": "Wir akzeptieren nur die Erweiterungen {{extensions}}",
  "max.size.allowed": "Die maximale Dateigröße beträgt {{size}} MB",
  "items.pending.locked": "Dieser Artikel wird derzeit bearbeitet",
  "retry.mark.done": "Erneut versuchen und als erledigt markieren",
  "synchronized.processes": "Zu synchronisierender Prozess",
  "max.processes.reached": "Maximale Anzahl von Prozessen erreicht",
  "process.in.orchestrator": "Prozess im Orchestrator",
  "process.synchronize": "Zu synchronisierender Prozess",
  "orchestrator.formControl.processes.saveSuccess": "Erfolgreich hinzugefügter Prozess zur Synchronisierung",
  "orchestrator.formControl.processes.updateSuccess": "Erfolgreich aktualisierter Prozess zur Synchronisierung",
  "task.initial.task": "Initiale Aufgabe",
  "task.post.completion.delay": "Verzögerung nach Abschluss",
  "task.remove": "Aufgabe entfernen",
  "task.name": "Aufgabenname",
  "task.description": "Aufgabenbeschreibung",
  "task.on.complete": "Bei Abschluss",
  "task.on.exception": "Bei Ausnahme",
  "task.add.session": "Sitzung hinzufügen",
  "task.delete.session": "Sitzung löschen",
  "task.name.already.exist": "Aufgabenname existiert bereits",
  "task(s)": "Aufgabe(n)",
  "add.task": "Aufgabe hinzufügen",
  task: "Aufgabe",
  "no.tasks.yet": "Noch keine Aufgaben",
  "no.task.message": "Keine Aufgabe gefunden",
  "task.stop": "Stop",
  "task.abort": "Abbrechen",
  "task.required.process": "Bitte wählen Sie einen Prozess aus",
  "task.required.first.session.process": "Bitte wählen Sie den Prozess für die erste Sitzung",
  "process.migration.not.possible": "Dieser Prozess kann nicht migriert werden, da er Multitasking-Zeitpläne enthält",
  "see.all": "Alle anzeigen",
  "sync.task_session": "Aufgabensitzungen",
  "sync.sch_task": "Aufgaben",
  "unique.schedule.name.exception": "Der Zeitplanname existiert bereits",
  "awaiting.synchro": "Oczekiwanie na synchronizację",
  "pendingCount.descending": "Ausstehende Elemente (absteigend)",
  pendingCount: "Ausstehende Elemente",
  "completedCount.descending": "Abgeschlossene Elemente (absteigend)",
  completedCount: "Abgeschlossene Elemente",
  "exceptionCount.descending": "Ausnahme-Elemente (absteigend)",
  exceptionCount: "Ausnahme-Elemente",
  "productivity.descending": "Produktivität (absteigend)",
  "occupancy.ascending": "Auslastung",
  "occupancy.descending": "Auslastung (absteigend)",
  "processRestriction.priority": "Priorität",
  "processRestriction.priority.descending": "Priorität (absteigend)",
  "average.FTEs.saved": "Durchschnittlich eingesparte Zeit",
  "success.rate": "Erfolgsquote",
  "average.success.rate": "Durchschnittliche Erfolgsquote",
  transactions: "Transaktionen",
  "total.transactions": "Gesamttransaktionen",
  "average.transactions": "Durchschnittliche Transaktionen",
  min: "min",
  week: "Woche",
  "newDashboard.AllStats.title": "Allzeitstatistiken",
  "newDashboard.CurrentStats.title": "Aktuelle Statistiken",
  "transactions.header.tooltip": "Gesamtanzahl der Transaktionen",
  "successRate.header.tooltip": "Prozentsatz erfolgreicher Transaktionen aller Zeiten",
  "bots.header.tooltip": "Gesamtarbeitszeit der Bots",
  "FTESaved.header.tooltip": "Gesamte gesparte Vollzeitäquivalente (FTE)",
  "transactions.header.label": "Alle Vorgänge",
  "items.header.label": "Elemente",
  "successRate.header.label": "Erfolgsrate",
  "transactions.cardHeader": "Transaktionen",
  "AvgHandlingTime.cardHeader": "Durchschnittliche Bearbeitungszeit",
  "inProgress.transactions.status": "In Bearbeitung",
  "completed.transactions.status": "Beendet",
  "all.processes": "Masterprozesse",
  "working.processes.status": "Laufen gemäß den Zeitplänen",
  "notWorking.processes.status": "Zeitpläne mit fehlenden Ausführungen",
  "Processes.activity.tooltip": "Dieses KPI verfolgt die Anzahl der Prozesse, die gemäß Zeitplan laufen und wie geplant ausgeführt werden, im Vergleich zur Anzahl der geplanten Prozesse, die jedoch nicht gemäß dem geplanten Zeitplan verlaufen.",
  "til.now": "Bis jetzt",
  "Monitoring Dashboard": "Überwachungsdashboard",
  "System exceptions": "Systemausnahmen",
  "Business exceptions": "Geschäftsausnahmen",
  "reporting.exportCSV": "Exportieren als CSV",
  "reporting.exportCSV.all": "Alle exportieren",
  "alerts.management.smsStatus.label": "SMS",
  "alerts.management.emailStatus.label": "Email",
};
