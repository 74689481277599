import React, { useCallback, useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import { fetchDashboardTimeSaved } from "../../../redux/actions/services/dashboard";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  LINE_CHART_LABELS,
  LINE_CHART_X_AXES,
  LINE_CHART_DATASET_OPTION,
  handleRequestMonitoringMode,
  LINE_CHART_TOOLTIP_OPTIONS,
} from "../dashboardUtils";
import theme from "../../../style/orchestratorTheme";
import {
  abbreviateNumber, formtNumberByLanguage, getCurrencySymbole, secondsToFTPTimeLabel
} from "../../../util";
import MixedChart from "../MixedChart";
import CircularLoader from "../../../components/Loaders/CircularLoader";
import DataNotFound from "../../../components/DataNotFound";
import CustomButton from "../../../components/CustomButton";
import useStyles from "../style";

const data = (data) => ({
  labels: data?.chartPeriodLabels,
  datasets: [
    {
      yAxisID: "hrs",
      data: data?.timeSaved,
      ...LINE_CHART_DATASET_OPTION,
      fill: true,
    },
  ],
});

const options = (yAxesLabel, chartStep, datalabels, userFleet, t) => ({
  elements: {
    line: {
      tension: 0,
    },
  },
  legend: {
    display: false,
    labels: LINE_CHART_LABELS(),
  },
  scales: {
    xAxes: LINE_CHART_X_AXES,
    yAxes: [
      {
        id: "hrs",
        display: true,
        position: "left",
        gridLines: {
          lineWidth: 0.3,
        },
        ticks: {
          stepSize: chartStep,
          beginAtZero: true,
          fontColor: theme.palette.primary.main,
          callback: (value) => abbreviateNumber(value),
        },
        scaleLabel: {
          display: true,
          labelString: yAxesLabel,
          fontColor: theme.palette.primary.main,
        },
      },
    ],
  },
  plugins: {
    datalabels,
  },
  layout: {
    padding: {
      top: 30,
    },
  },
  tooltips: {
    callbacks: {
      label: (tooltipItem, data) => {
        if (userFleet) return secondsToFTPTimeLabel((+data.datasets[0].data[tooltipItem.index]), userFleet?.hoursPerManDay, userFleet?.daysPerYear, t)
        return `${abbreviateNumber(data.datasets[0].data[tooltipItem.index])} (${formtNumberByLanguage(data.datasets[0].data[tooltipItem.index])})` || 0
      }
    },
    ...LINE_CHART_TOOLTIP_OPTIONS
  }
});

export default function TimeSavedChart({ xs, userFleet }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [timeSavedData, setTimeSavedData] = React.useState();
  const filterDashboard = useSelector(({ filterDashboard }) => filterDashboard);
  const refreshTime = filterDashboard?.monitoringMode;
  const fetchIntervalRef = React.useRef(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const currencyLabel = getCurrencySymbole(userFleet, true);
  const chartItem = [
    {
      id: "CURRENCY",
      label: currencyLabel,
      options: options(currencyLabel, 5000000, { display: false }),
    },
    {
      id: "FTE",
      label: t("timeUnite.fte"),
      options: options(t("Time Saved"), 100000, { display: false }, userFleet, t),
    },
  ];

  const [selectedChartItem, setSelectedChartItem] = useState(chartItem[0]);
  const [tempSelectedChartItem, setTempSelectedChartItem] = useState();

  const fetchTimeSavedData = useCallback(() => {
    setIsLoading(true);
    dispatch(
        fetchDashboardTimeSaved({ ...filterDashboard, by: selectedChartItem.id })
    ).then((res) => {
      setTimeSavedData(data(res?.data));
      setIsLoading(false);
      setTempSelectedChartItem(res?.action?.meta?.requestAction?.request)
    }) }, [selectedChartItem, filterDashboard])

  useEffect(() => {
    if (userFleet && filterDashboard && selectedChartItem) {
      fetchTimeSavedData();
    }
  }, [userFleet?.id, filterDashboard, selectedChartItem?.id]);

  useEffect(() => {
    handleRequestMonitoringMode(refreshTime, fetchIntervalRef, fetchTimeSavedData)
  }, [refreshTime, selectedChartItem.id])

  return (
    <Grid item xs={xs} className={classes.container}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography component="span" className={classes.title}>
          {t("Automation Impact")}
        </Typography>
        <ButtonGroup disableElevation variant="contained" color="primary">
          {chartItem.map((item) => (
            <CustomButton
              className={`${classes.chartButton} ${
                selectedChartItem.id === item.id
                  ? classes.activeChartButton
                  : classes.inactiveChartButton
              }`}
              onClick={() => {
                if (!(selectedChartItem.id === item.id)) {
                  setSelectedChartItem(item)
                  setTempSelectedChartItem(item)
                }
              }}
              key={item.id}
            >
              {item.label}
            </CustomButton>
          ))}
        </ButtonGroup>
      </Box>
      {
        // eslint-disable-next-line no-nested-ternary
        isLoading || !timeSavedData?.datasets || (tempSelectedChartItem && tempSelectedChartItem?.params?.by !== selectedChartItem?.id) ? (
          <CircularLoader height={410} />
      ) : timeSavedData?.datasets?.[0]?.data?.length === 0 ? (
        <div className={classes.dataNotFound}>
          <DataNotFound
            backgroundColor="transparent"
            boxShadow="none"
            iconHeight={150}
            iconWidth={150}
          />
        </div>
      ) : (
        <MixedChart data={timeSavedData} options={selectedChartItem.options} />
      )
}
    </Grid>
  );
}
