import React from "react";
import Button from "@material-ui/core/Button";
import clsx from "clsx";
import useStyles from "./styles"

export default function CustomButton({ view, children, ...rest }) {
    const classes = useStyles();
    return (
      <Button {...rest} className={view ? clsx(rest?.className, classes.customButton, rest?.disabled ? classes.disabled : classes?.[view]) : rest?.className}>
        {children}
      </Button>
    )
}
