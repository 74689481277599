import React, { useEffect, useState } from "react";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Tooltip from "@material-ui/core/Tooltip";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "lodash";
import AddIcon from "@material-ui/icons/Add";
import { useQuery } from "@redux-requests/react";
import { useHistory } from "react-router-dom";
import { SearchFilter } from "../../../../components/Filter";
import { handleDisplayedLabel, isFleetAdministrator } from "../../../../util";
import {
  fetchDivisionsForFilters,
  fetchFleets,
  fetchOrchestratorsNamesAndIds,
} from "../../../../redux/actions/services";
import NoDataMenu from "../../../../components/NoData/NoDataMenu";
import ClearFilter from "../../../../components/ClearFilter";
import { initFilter } from "../../../../redux/slices/adminFilterSlice";
import { FETCH_CURRENT_USER } from "../../../../redux/constants";
import CustomAutoComplete from "../../../../components/FormFields/CustomAutoComplete";
import CustomButton from "../../../../components/CustomButton";
import CustomSwitch from "../../../Services/components/CustomSwitch";
import useStyles from "../style";

export default function AdminTableFilter({
  statusList,
  sortByList,
  searchLabel,
  handleChangeStatus,
  handleChangeOrchestrator,
  handleFilterValueChange,
  handleChangeSortBy,
  isUserAdministrationPage,
  isRobotAdministrationPage,
  handleChangeFleets,
  handleChangeDivisions,
  isDivisionAdministrationPage,
  isOrchestratorAdministrationPage,
  isTagAdministrationPage,
  entityBaseUrl,
  showAddIcon,
  addButtonText,
  handleChangeShowDeleted
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [fleets, setFleets] = useState();
  const [orchestrators, setOrchestrators] = useState([]);
  const [isInstanceOwner, setIsInstanceOwner] = useState(false);
  const [searchText, setSearchText] = useState(null);
  const [userDivision, setUserDivision] = useState();
  const filter = useSelector(({ adminFilter }) => adminFilter);
  const currentUser = useQuery({ type: FETCH_CURRENT_USER })?.data;

  React.useEffect(() => {
    setIsInstanceOwner(currentUser?.fleet?.instanceOwner && isFleetAdministrator(currentUser));
  }, [currentUser]);

  React.useEffect(() => {
    if (isInstanceOwner) {
      dispatch(fetchFleets({ ...filter[filter.currModule] })).then((res) => {
        if (res.data) {
          setFleets(res.data.content);
        }
      });
    }
    if (isRobotAdministrationPage) {
      dispatch(fetchOrchestratorsNamesAndIds()).then((res) => {
        if (res.data) {
          setOrchestrators(res?.data);
        }
      });
    }
  }, [dispatch, isInstanceOwner]);

  React.useEffect(() => {
    setSearchText(filter[filter.currModule].searchCriteria);
  }, [filter[filter.currModule].searchCriteria]);

  React.useEffect(() => {
    setSearchText(filter[filter.currModule].searchCriteria);
  }, []);

  const handleChangeFleet = (value) => {
    handleChangeFleets(value);
  };

  const handleChangeDivision = (value) => {
    handleChangeDivisions(value);
  };

  const handleOrchestrator = (value) => {
    handleChangeOrchestrator(value);
  };

  const debouncer = React.useCallback(
    debounce((nextValue) => {
      handleFilterValueChange(nextValue);
    }, 500),
    [filter.currModule],
  );

  const handlechangeSeachText = (e) => {
    const { value } = e.target;
    setSearchText(value);
    debouncer(value);
  };

  const handleClear = () => {
    dispatch(initFilter());
  };

  useEffect(() => {
    if (currentUser) { dispatch(fetchDivisionsForFilters()).then((res) => {
      setUserDivision(res?.data?.content) }) }
  }, [currentUser])

  return (
    <Grid container spacing={2} alignItems="center" justify="flex-start">
      <Box component={Grid} container item xs={2} alignItems="flex-end" paddingTop="25px !important">
        <SearchFilter
          callback={handlechangeSeachText}
          placeHolder={t(searchLabel)}
          style={classes.searchIcon}
          value={searchText}
        />
      </Box>

      {isRobotAdministrationPage && (
        <Grid item xs={2}>
          <FormControl fullWidth className={classes.autoComplete}>
            <CustomAutoComplete
                multiple
                options={orchestrators || []}
                optionLabel="name"
                value={orchestrators?.filter(({ id }) => filter[filter.currModule]?.orchestrator?.map(({ id }) => id)?.includes(id))}
                noOptionsNode={<NoDataMenu message={t("no.orchestrator.message")} />}
                onChange={handleOrchestrator}
                label={t("orchestrator")}
            />
          </FormControl>
        </Grid>
      )}
      {!isTagAdministrationPage
          && (
            <Grid item xs={2}>
              <FormControl fullWidth color="secondary">
                <CustomAutoComplete
                  multiple
                  options={statusList || []}
                  optionLabel="label"
                  optionUuid="code"
                  value={statusList.filter(({ code }) => filter[filter.currModule]?.status?.includes(code))}
                  onChange={handleChangeStatus}
                  label={t("status")}
                />
              </FormControl>
            </Grid>
      )}
      {(isUserAdministrationPage || isOrchestratorAdministrationPage || isRobotAdministrationPage) && isInstanceOwner && (
        <Grid item xs={2}>
          <FormControl fullWidth className={classes.autoComplete}>
            <CustomAutoComplete
                multiple
                options={fleets || []}
                optionLabel="companyName"
                value={fleets?.filter(({ id }) => filter[filter.currModule]?.fleets?.map(({ id }) => id)?.includes(id))}
                noOptionsNode={<NoDataMenu message={t("no.fleet.message")} />}
                onChange={handleChangeFleet}
                label={t("fleet")}
            />
          </FormControl>
        </Grid>
      )}
      {isUserAdministrationPage && !process.env.REACT_APP_DISABLE_PARTITIONING && (
      <Grid item xs={2}>
        <FormControl fullWidth className={classes.autoComplete}>
          <CustomAutoComplete
              multiple
              options={userDivision || []}
              optionLabel="divisionName"
              value={userDivision?.filter(({ id }) => filter[filter.currModule]?.divisions?.map(({ id }) => id)?.includes(id))}
              noOptionsNode={<NoDataMenu message={t("no.division.message")} />}
              onChange={handleChangeDivision}
              label={t("divisions.division")}
          />
        </FormControl>
      </Grid>
      )}
      {isDivisionAdministrationPage && currentUser?.fleet?.instanceOwner && (
      <Grid item xs={2}>
        <FormControl fullWidth className={classes.autoComplete}>
          <CustomAutoComplete
              multiple
              options={fleets || []}
              optionLabel="companyName"
              value={fleets?.filter(({ id }) => filter[filter.currModule]?.fleets?.map(({ id }) => id)?.includes(id))}
              noOptionsNode={<NoDataMenu message={t("no.fleet.message")} />}
              onChange={handleChangeFleet}
              label={t("fleet")}
          />
        </FormControl>
      </Grid>
      )}
      {sortByList && (
        <Grid item xs={2} key={filter[filter.currModule].sortBy}>
          <CustomAutoComplete
              options={sortByList ?? []}
              optionLabel={(option) => handleDisplayedLabel(t(option?.label))}
              optionUuid="code"
              value={sortByList.find(({ code }) => filter[filter.currModule].sortBy === code)}
              onChange={handleChangeSortBy}
              label={t("sortBy")}
          />
        </Grid>
      )}
      {isRobotAdministrationPage && (
        <Grid item alignItems="flex-start" justify="flex-start">
          <Box mb="-22px">
            <Tooltip
                title={t("tooltip.action.showHiddenItems")}
                placement="top"
            >
              <CustomSwitch
                  checked={filter.robots.showDeleted}
                  handleChange={handleChangeShowDeleted}
                  small
              />
            </Tooltip>
            {t("robots.show.hidden")}
          </Box>
        </Grid>
      )}
      <Grid item alignItems="flex-start" justify="flex-start">
        <Box mb="-22px">
          <ClearFilter
            clearFilter={handleClear}
            label={t("reporting.clear")}
          />
        </Box>
      </Grid>
      {showAddIcon && (
      <Box component={Grid} item container xs={3} className={classes.actionButtons} justifyContent="end">
        <CustomButton
                view="primary"
                type="submit"
                onClick={() => {
                  history.push(`${entityBaseUrl}/add`);
                }}
            >
          <AddIcon />
          {addButtonText}
        </CustomButton>
      </Box>
      )}
    </Grid>
  );
}
