/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Tooltip,
  CardActions,
  IconButton,
  Checkbox,
  Card,
  CardContent,
  Grid,
  ButtonBase, Chip,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import TrashIcon from "@material-ui/icons/Delete";
import FolderIcon from "@material-ui/icons/Folder";
import VpnKey from "@material-ui/icons/VpnKey";
import get from "lodash/get";
import ConfirmMessage from "../../../../components/ConfirmMessage";

import AdminTableCell from "../AdminTabeCell";
import PermissionManagement from "../../PermissionsManagement/PermissionsManagement";
import { isActionPermitted } from "../../../../components/HasPermission/index.js";
import { isFleetAdministrator, robotStatus } from "util";
import { SrLicensesIcon } from "components/CustomIcons/index.js";
import useStyles from "./style.js";
import LaunchIcon from "@material-ui/icons/Launch";
import { openQueue, updateQueueAction, triggerQueueAction } from "../../../../redux/slices/executionManagerSlice.js";
import { Replay } from "@material-ui/icons";
import SecurityIcon from "@material-ui/icons/Security";
import HttpsIcon from "@material-ui/icons/Https";

import {
  ASK_DELETE_QUEUE,
  CONFIRM_DELETE_QUEUE,
  ASK_ENABLE_QUEUE,
  ASK_DISABLE_QUEUE,
  ASK_DELETE_ONE_QUEUE,
  ASK_DELETE_QUEUE_FROM_ONE,
  QUEUE_DELETED
} from "util/constants";
import { toast } from "react-toastify";
import { tagStatus } from "../../../../util";
import { disabledFunctionality } from "../../../../util/constants/PermitedFeature";
import CustomSwitch from "../../../Services/components/CustomSwitch";

export default function AdminTableRow({
  row,
  displayFields,
  index,
  selected,
  setSelected,
  entityBaseUrl,
  enableDisableEntity,
  resetEntity,
  fetchEntities,
  deleteEntity,
  deleteIcon: DeleteIcon = TrashIcon,
  restoreIcon: RestoreIcon = TrashIcon,
  enableLabel,
  disableLabel,
  enableConfirm,
  disableConfirm,
  deleteEntityLabel,
  restoreEntityLabel,
  deleteEntityTooltip = "tooltip.action.delete",
  deleteEntityConfirmationButton = "Delete",
  restoreEntityConfirmationButton = "Delete",
  restoreEntityTooltip = "tooltip.action.delete",
  rowCustomActions,
  isUserAdministrationPage = false,
  isFleetAdministrationPage = false,
  isOrchestratorAdministrationPage = false,
  isRobotAdministrationPage = false,
  isQueueAdministrationPage = false,
  statusField = "status", // default "status" field in DB table
  fetchEntitiesReset,
  showStatusSwitch,
  enableDeleteMultiple = true,
  entityDeleted,
  enableEdit = true,
  enableDelete = true,
  preDeleteEntity,
  isDivisionAdministrationPage,
  isTagAdministrationPage,
  archiveEntity,
  archiveConfirmationMsg,
  archivedChip,
  archiveTooltip,
  confirmActionArchive,
  tagArchivedSuccess
}) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const active = get(row, statusField) === "ACTIVE";
  const [openConfirmationPopup, setopenConfirmationPopup] = useState(false);
  const [openMsgConfirm, setOpenMsgConfirm] = React.useState(false);
  const [openMsgConfirmArchive, setOpenMsgConfirmArchive] = React.useState(false);
  const [toDeleteId, setToDeleteId] = React.useState(null);
  const [toArchivedId, setToArchivedId] = React.useState(null);
  const [isPermissionPageOpen, setIsPermissionPageOpen] = React.useState(false);
  const [isLoading, setIsloading] = useState({
    switch: false,
    deleteAction: false,
    archive: false,
  });
  const { queueAction, processesAction, entityId } = useSelector(({ executionManager }) => executionManager);
  useEffect(() => {
    if (!processesAction && queueAction === CONFIRM_DELETE_QUEUE) {
      dispatch(deleteEntity(toDeleteId, processesAction, onSuccessDeleteQueue));
    } else {
      handleQueueActions();
    }
  }, [queueAction]);
  const handleQueueActions = () => {
    if (queueAction && entityId === get(row, "id")) {
      const actions = {
        CONFIRM_DELETE_QUEUE: {
          action: onDeleteQueueEntity
        },
        ASK_DELETE_ONE_QUEUE: {
          action: confirmDelete
        },
        CONFIRM_DISABLE_QUEUE: {
          action: onUpdateQueueStatus
        },
        CONFIRM_ENABLE_QUEUE: {
          action: onUpdateQueueStatus
        },
        CANCEL_QUEUE_ACTIONS: {
          action: onCancelStatus
        }
      };
      actions[queueAction]?.action();
    }
  }
  const onCancelStatus = () => {
    onCancel();
    cancelConfirm();
    dispatch(
      triggerQueueAction({
        queueAction: null,
        processesAction: [],
        id: null,
      })
    );
  }

  const deleteConfirmButton = isRobotAdministrationPage
    ? get(row, "isActive") === "ACTIVE"
      ? t(deleteEntityConfirmationButton)
      : t(restoreEntityConfirmationButton)
    : t("Delete");

  const moduleName = isUserAdministrationPage
    ? "user"
    : isFleetAdministrationPage
      ? "fleet"
      : isOrchestratorAdministrationPage
        ? "orchestrator"
        : "";

  const resetLoaders = () => setIsloading({ switch: false, deleteAction: false, archive: false });

  const handleChangeStatus = () => {
    const id = get(row, "id");
    const orchId = row?.orchestrator?.id;
    if (isQueueAdministrationPage) {
      // Déclencher l'action au parent (QueueManagement)
      dispatch(
        triggerQueueAction({
          queueAction: active ? ASK_DISABLE_QUEUE : ASK_ENABLE_QUEUE,
          id,
          orchId
        })
      );
    }
    else {
      setIsloading({ ...isLoading, switch: true });
      dispatch(enableDisableEntity(id, active ? "DISABLE" : "ACTIVE")).then(
        (err) => {
          resetLoaders();
          if (err?.response?.data?.detail) {
            toast.error(t(err?.response?.data?.detail))
          } else {
            fetchEntities();
          }
          setopenConfirmationPopup(false);
        }
      );
    }
  };

  const cancelConfirm = () => {
    resetLoaders();
    setOpenMsgConfirm(false);
    setOpenMsgConfirmArchive(false);
  };

  const snackBarMessageOfRobot = () => {
    const message = row?.isActive === robotStatus?.ACTIVE ? t("hidden") : t("unhidden")
    toast.success(t(entityDeleted, { status: message }))
  }

  const confirmDelete = () => {
    if (isQueueAdministrationPage) {
      if (queueAction === ASK_DELETE_ONE_QUEUE) {
        dispatch(updateQueueAction({ queueAction: ASK_DELETE_QUEUE_FROM_ONE }));
      } else {
        // Déclencher l'action au parent (QueueManagement)
        const orchId = row?.orchestrator?.id;
        dispatch(
          triggerQueueAction({
            queueAction: ASK_DELETE_QUEUE,
            id: toDeleteId,
            orchId
          })
        );
      }
    } else {
      onDeleteEntity();
    }
  };
  const onConfirmeArchiveSuccess = () => {
    setOpenMsgConfirmArchive(false)
    setIsloading({ ...isLoading, archive: false });
    fetchEntitiesReset();
    toast.success(t(tagArchivedSuccess))
  }
  const onConfirmeArchiveError = () => {
    setOpenMsgConfirmArchive(false)
    setIsloading({ ...isLoading, archive: false });
    fetchEntitiesReset();
    toast.error("Unexpected Error")
  }
  const confirmeArchive = () => {
    if (isTagAdministrationPage) {
      setIsloading({ ...isLoading, archive: true });
      dispatch(archiveEntity(toArchivedId, onConfirmeArchiveSuccess, onConfirmeArchiveError))
    }
  };

  const onDeleteEntity = () => {
    setIsloading({ ...isLoading, deleteAction: true });
    dispatch(deleteEntity(toDeleteId)).then((res) => {
      if (res?.status === 200) {
        if (isRobotAdministrationPage) snackBarMessageOfRobot()
        else toast.success(t(entityDeleted))
        setTimeout(() => fetchEntitiesReset(), 1500);
      }
      else if (res?.response?.data?.detail) toast.error(t(res?.response?.data?.detail))
      else toast.error(t("Unexpected Error"))

      setOpenMsgConfirm(false);
      resetLoaders();
    });
  };

  const onDeleteQueueEntity = () => {
    setIsloading({ ...isLoading, deleteAction: true });
    dispatch(preDeleteEntity(entityId, processesAction, onSuccessPreDeleteQueue))
  }

  const onSuccessPreDeleteQueue = (res) => {
    if (res?.data === true) {
      dispatch(deleteEntity(entityId, processesAction, onSuccessDeleteQueue))
    }
  };

  const onSuccessDeleteQueue = (res) => {
    if (res?.status === 200) {
      toast.success(t(entityDeleted));
      fetchEntitiesReset();
    } else toast.error(t(res?.response?.data?.detail || "Unexpected Error"));
    resetLoaders();
    setOpenMsgConfirm(false);
    dispatch(
      triggerQueueAction({
        queueAction: QUEUE_DELETED,
        processesAction: [],
        id: null,
      })
    );
  };

  const onUpdateQueueStatus = () => {
    const id = get(row, "id");
    setIsloading({ ...isLoading, switch: true });
    dispatch(preDeleteEntity(id, processesAction)).then((res) => {
      if (res?.status === 200 && res?.data === true) {
        dispatch(enableDisableEntity(id, active ? "DISABLE" : "ACTIVE", processesAction)).then(
          (err) => {
            resetLoaders();
            if (err?.response?.data?.detail) {
              toast.error(t(err?.response?.data?.detail))
            } else {
              fetchEntities();
              dispatch(
                triggerQueueAction({
                  queueAction: null,
                  processesAction: [],
                  id: null,
                })
              );
            }
            setopenConfirmationPopup(false);
          }
        );
      }
    });
  };

  const onCancel = () => {
    resetLoaders();
    setopenConfirmationPopup(false);
  };

  const currentUser = useSelector(({ requests }) => get(requests, "queries.FETCH_CURRENT_USER.data"));
  const isCurrentUser = Number(currentUser?.id) === row?.id && isUserAdministrationPage;

  const deleteColor = isCurrentUser ? "" : "error";
  const isRobotActive = row?.isActive === "ACTIVE";
  const deleteMsg = isRobotAdministrationPage
    ? isRobotActive
      ? t(deleteEntityLabel)
      : t(restoreEntityLabel)
    : typeof deleteEntityLabel === "string"
      ? t(deleteEntityLabel)
      : deleteEntityLabel;
  const IconPlaceHolder = () => (
    <Grid item className={classes.rowActionButtons} style={{ width: 40 }}>
      &nbsp;
    </Grid>
  );

  const displayFieldsWidth = rowCustomActions ? 9 : 12;

  return (
    <>
      <Card key={index} className={classes.root} elevation={1}>
        <ButtonBase
          focusRipple
          onClick={() => history.push(`${entityBaseUrl}/view/${get(row, "id")}`)}
          style={{ width: "100%" }}
        >
          <CardContent className={classes.content}>
            <Grid container alignItems="center" item xs={12}>
              <Grid container alignItems="center" item xs={displayFieldsWidth}>
                <div className={classes.viewClickContainer}>
                  <Grid container justify="flex-start" alignItems="center">
                    {displayFields?.map((field, index1) => (
                      <AdminTableCell key={index1} field={field} row={row} />
                    ))}
                  </Grid>
                </div>
              </Grid>
              {rowCustomActions ? (
                <Grid container alignItems="center" item xs={3}>
                  {rowCustomActions?.map((field, index1) => (
                    <AdminTableCell key={index1} field={field} row={row} />
                  ))}
                </Grid>
              ) : null}
            </Grid>
          </CardContent>

          <CardActions className={classes.rowActions}>
            <Grid container xs={12} justify="flex-end" alignItems="center">
              {isFleetAdministrationPage
                  && get(row, "isInstanceOwner")
                  && !process.env.REACT_APP_DISABLE_PARTITIONING && (
                  <Grid item>
                    <Tooltip title={t("instance.owner")}>
                      <ButtonBase className={classes.instanceOwnerTag}>
                        <SecurityIcon />
                      </ButtonBase>
                    </Tooltip>
                  </Grid>
                  )}
              {isOrchestratorAdministrationPage
                  && currentUser?.fleet?.instanceOwner
                  && isFleetAdministrator(currentUser)
                  && get(row, "isPrivate")
                  && (
                  <Grid item>
                    <Tooltip title={t("private")}>
                      <ButtonBase className={classes.instanceOwnerTag}>
                        <HttpsIcon />
                      </ButtonBase>
                    </Tooltip>
                  </Grid>
                  )}
              {isQueueAdministrationPage
                && isFleetAdministrator(currentUser)
                && (
                  <Grid item>
                    <Tooltip
                      placement="top"
                      title={t("queue.management.open-queue")}
                    >
                      <IconButton
                        onClick={(e) => { e.stopPropagation(); dispatch(openQueue(row.id)) }}
                      >
                        <LaunchIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip
                      placement="top"
                      title={t("queue.management.reset-queue")}
                    >
                      <IconButton
                        onClick={(e) => { e.stopPropagation(); resetEntity(row.id) }}
                      >
                        <Replay />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                )}
              {((isActionPermitted(currentUser, isUserAdministrationPage, "Edit user") || isActionPermitted(currentUser, isDivisionAdministrationPage, "Edit division")) || isFleetAdministrator(currentUser))
                && showStatusSwitch ? (
                  <Grid item>
                    <Tooltip
                      placement="top"
                      title={t(`tooltip.action.${active ? "disable" : "enable"}`)}
                    >
                      <CustomSwitch
                        id={`${moduleName}-admin-enable-disable-switch`}
                        checked={active}
                        onMouseDown={(e) => e.stopPropagation()}
                        onClick={(e) => e.stopPropagation()}
                        handleChange={() => {
                          setopenConfirmationPopup(true);
                        }}
                        disabled={isCurrentUser}
                        value={active}
                        name="checkedB"
                        inputProps={{ "aria-label": "primary checkbox" }}
                        small
                      />
                    </Tooltip>
                  </Grid>
              ) : (
                <IconPlaceHolder />
              )}
              {(isActionPermitted(currentUser, isUserAdministrationPage, "User Permissions")
                || (isUserAdministrationPage && isFleetAdministrator(currentUser))) && (
                  <Grid item>
                    <Tooltip
                      placement="top"
                      title={t("tooltip.action.permissions")}
                    >
                      <IconButton
                        id={`${moduleName}-admin-permission-btn`}
                        className={classes.rowActionButtons}
                        aria-label="edit roles"
                        onMouseDown={(e) => e.stopPropagation()}
                        onClick={(e) => {
                          e.stopPropagation();
                          setIsPermissionPageOpen(!isPermissionPageOpen);
                        }}
                      >
                        <VpnKey />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                )}
              {/* ToDo - refactore this condition when adding permissions */}
              {enableEdit && (
                  ((isFleetAdministrationPage || isUserAdministrationPage || isOrchestratorAdministrationPage || isRobotAdministrationPage || isTagAdministrationPage || isQueueAdministrationPage) && isFleetAdministrator(currentUser))
                  || (isActionPermitted(currentUser, isTagAdministrationPage, "Edit tag") && get(row, statusField) !== tagStatus.DISABLED)
                  || isActionPermitted(currentUser, isDivisionAdministrationPage, "Edit division")
                  || isActionPermitted(currentUser, isQueueAdministrationPage, "Edit SR Queue")
                  || isActionPermitted(currentUser, isUserAdministrationPage, "Edit user")
              )
               ? (
                 <Grid item>
                   <Tooltip placement="top" title={t("tooltip.action.edit")}>
                     <IconButton
                        id={`${moduleName}-admin-edit-btn`}
                        className={classes.rowActionButtons}
                        aria-label="edit"
                        onMouseDown={(e) => e.stopPropagation()}
                        onClick={(e) => {
                          e.stopPropagation();
                          history.push(
                            `${entityBaseUrl}/edit/${get(row, "id")}`
                          );
                        }}
                      >
                       <EditIcon />
                     </IconButton>
                   </Tooltip>
                 </Grid>
              ) : (
                <IconPlaceHolder />
              )}
              {
                  (isActionPermitted(currentUser, isTagAdministrationPage, "Archive") || isFleetAdministrator(currentUser)) && !disabledFunctionality && get(row, statusField) !== tagStatus.DISABLED
              && (
                <Grid item>
                  <Tooltip placement="top" title={t(archiveTooltip)}>
                    <IconButton
                      id={`${moduleName}-tag-archive-btn`}
                      className={classes.rowActionButtons}
                      classes={{ colorPrimary: classes.archiveColor }}
                      color="primary"
                      aria-label="tag-archive-btn"
                      onMouseDown={(e) => e.stopPropagation()}
                      onClick={(e) => {
                        e.stopPropagation();
                        setToArchivedId(get(row, "id"));
                        setOpenMsgConfirmArchive(true);
                      }}
                    >
                      <FolderIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              )
              }
              {isOrchestratorAdministrationPage && isFleetAdministrator(currentUser)
                && (
                  <Grid item>
                    <Tooltip placement="top" title={t("tooltip.action.license")}>
                      <IconButton
                        id={`${moduleName}-admin-licence-btn`}
                        className={classes.rowActionButtons}
                        aria-label="licence"
                        onMouseDown={(e) => e.stopPropagation()}
                        onClick={(e) => {
                          e.stopPropagation();
                          return history.push(
                            `${entityBaseUrl}/licence/${get(row, "id")}`,
                          );
                        }}
                      >
                        <SrLicensesIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                )}
              {isTagAdministrationPage && get(row, statusField) === tagStatus.DISABLED && !disabledFunctionality
                  && <Chip label={t(archivedChip)} variant="outlined" />}
              {enableDelete && (
                ((isFleetAdministrationPage || isUserAdministrationPage || isOrchestratorAdministrationPage || isRobotAdministrationPage || isTagAdministrationPage || isQueueAdministrationPage) && isFleetAdministrator(currentUser))
                || isActionPermitted(currentUser, isTagAdministrationPage, "Delete tag")
                || isActionPermitted(currentUser, isDivisionAdministrationPage, "Delete division")
                || isActionPermitted(currentUser, isQueueAdministrationPage, "Delete SR Queue")
                || isActionPermitted(currentUser, isUserAdministrationPage, "Delete user")
                ) ? (
                  <Grid item>
                    <Tooltip
                      placement="top"
                      title={
                        isRobotActive
                          ? t(deleteEntityTooltip)
                          : t(restoreEntityTooltip)
                      }
                    >
                      <IconButton
                        id={`${moduleName}-admin-delete-btn`}
                        className={classes.rowActionButtons}
                        disabled={isCurrentUser}
                        aria-label="delete"
                        onMouseDown={(e) => e.stopPropagation()}
                        onClick={(e) => {
                          e.stopPropagation();
                          setToDeleteId(get(row, "id"));
                          setOpenMsgConfirm(true);
                        }}
                      >
                        {isRobotAdministrationPage ? (
                          isRobotActive ? (
                            <RestoreIcon color={deleteColor} />
                          ) : (
                            <DeleteIcon className={classes.showIcon} />
                          )
                        ) : (
                          <TrashIcon color={deleteColor} />
                        )}
                      </IconButton>
                    </Tooltip>
                  </Grid>
              ) : (
                <IconPlaceHolder />
              )}
              {enableDeleteMultiple ? (
                  ((isActionPermitted(currentUser, isUserAdministrationPage, "Delete user") || isActionPermitted(currentUser, isDivisionAdministrationPage, "Delete division"))
                  || isFleetAdministrator(currentUser)) ? (
                    <Grid item>
                      <Checkbox
                        id={`${moduleName}-admin-select-checkbox`}
                        className={classes.rowActionButtons}
                        checked={
                          !isCurrentUser
                          && selected.includes(parseInt(row.id, 10))
                        }
                        disabled={isCurrentUser}
                        onMouseDown={(e) => e.stopPropagation()}
                        onClick={(e) => e.stopPropagation()}
                        onChange={(e) => {
                          const list = [...selected];
                          // eslint-disable-next-line
                          e.target.checked
                            ? list.push(parseInt(get(row, "id"), 10))
                            : list.splice(
                              list.indexOf(parseInt(get(row, "id"), 10)),
                              1,
                            );
                          setSelected(list);
                        }}
                      />
                    </Grid>
                ) : (
                  null
                )
              ) : (
                null
              )}
            </Grid>
          </CardActions>
        </ButtonBase>
      </Card>
      <ConfirmMessage
        message={active ? t(disableLabel) : t(enableLabel)}
        openStart={openConfirmationPopup}
        onCancel={onCancel}
        onConfirm={handleChangeStatus}
        buttonConfirm={active ? t(disableConfirm) : t(enableConfirm)}
        buttonCancel={t("Cancel")}
        isLoading={isLoading.switch}
      />

      <ConfirmMessage
        message={deleteMsg}
        openStart={openMsgConfirm}
        onCancel={cancelConfirm}
        onConfirm={confirmDelete}
        buttonConfirm={deleteConfirmButton}
        buttonCancel={t("Cancel")}
        isDelete="true"
        isLoading={isLoading.deleteAction}
      />
      <ConfirmMessage
          message={t(archiveConfirmationMsg)}
          openStart={openMsgConfirmArchive}
          onCancel={cancelConfirm}
          onConfirm={confirmeArchive}
          buttonConfirm={t(confirmActionArchive)}
          buttonCancel={t("Cancel")}
          isLoading={isLoading.archive}
      />
      {isPermissionPageOpen && (
        <PermissionManagement
          t={t}
          dispatch={dispatch}
          setIsOpen={setIsPermissionPageOpen}
          isOpen={isPermissionPageOpen}
          classes={classes}
          row={row}
          fetchEntities={fetchEntities}
        />
      )}
    </>
  );
}
