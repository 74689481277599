import React, { useEffect, useState } from "react";
import CustomDialog from "../CustomDialog";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import MuiDialogActions from "@material-ui/core/DialogActions";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { useTranslation } from "react-i18next";
import { Grid, ListItemIcon } from "@material-ui/core";
import NoDataMenu from "../../../../components/NoData/NoDataMenu";
import ClearFilter from "../../../../components/ClearFilter";
import { getStatusBGColor, robotStatus } from "../../../../util";
import CustomButton from "../../../../components/CustomButton";
import CircularLoader from "../../../../components/Loaders/CircularLoader";
import useStyles from "./style";

function RobotDialog({
  openStart,
  message,
  onConfirm,
  isDelete,
  isLoading,
  buttonConfirm,
  buttonCancel,
  onCancel,
  _robots,
  setSelectedRobotName,
  selectedRobotName,
  isRobotRequired = true,
}) {
  const classes = useStyles();

  const handleOnClickItem = (r, index) => {
    setSelectedRobotName(r?.robotName);
    setSelectedRobotIndex(index);
  };

  const { t } = useTranslation();

  const [selectedRobotIndex, setSelectedRobotIndex] = useState(null);

  const [robotData, setRobotData] = useState(null);

  useEffect(() => {
    const newRobots = _robots?.filter((r) => r?.isActive === robotStatus.ACTIVE);
    setRobotData(newRobots);
  }, [_robots]);

  const handleClear = () => {
    setSelectedRobotName(null);
    setSelectedRobotIndex(null);
  }

  return (
    <CustomDialog
      open={openStart}
      aria-labelledby="confirm_message-dialog-title"
      maxWidth="xs"
    >
      <DialogContent>
        <DialogContentText id="confirm_message-dialog-content">
          <Grid container xs={12}>
            <Grid container xs={10} style={{ margin: "auto" }}>
              {message}
            </Grid>
            {!isRobotRequired
              && (
              <Grid container xs={2}>
                <ClearFilter
                  clearFilter={handleClear}
                  size="24px"
                  label={t("Clear")}
                />
              </Grid>)}
          </Grid>
        </DialogContentText>

        <List key={_robots}>
          {
            !robotData && <CircularLoader color="inherit" />
          }
          {robotData?.map((r, index) => (
            <ListItem
              button
              selected={selectedRobotIndex === index}
              className={classes.listItem}
              onClick={() => handleOnClickItem(r, index)}
            >
              <ListItemIcon>
                <span
                  style={{
                    backgroundColor:
                      getStatusBGColor(r?.displayStatus),
                  }}
                  className={classes.icon}
                />
              </ListItemIcon>
              <ListItemText primary={r.robotDisplayName} />
            </ListItem>
          ))}
          {robotData?.length === 0 && (
            <NoDataMenu message={t("start.process.no.robot.assigned")} />
          )}
        </List>
      </DialogContent>

      <MuiDialogActions className={classes.dialogActionsContainer}>
        <Box
          component={Button}
          variant="contained"
          size="medium"
          className={classes?.resetButton}
          onClick={onCancel}
          disabled={isLoading}
        >
          {buttonCancel}
        </Box>
        <CustomButton
          view="primary"
          ml="1rem"
          size="medium"
          onClick={onConfirm}
          className={isDelete && classes?.deleteButton}
          disabled={isLoading || (isRobotRequired && !selectedRobotName)}
        >
          {isLoading && <CircularProgress size={14} />}
          &nbsp;
          {buttonConfirm}
        </CustomButton>

      </MuiDialogActions>
    </CustomDialog>
  );
}

export default RobotDialog;
