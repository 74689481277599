import React from "react";
import {
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import useStyles from "../style.js";
import {
  bePeriods,
  defaultDaysPerYear,
  defaultManDayHours,
  defaultRecommendedOcccupancy,
  REGEX,
} from "../../../../../util/index.js";

const ClientParameters = ({ disableFields }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    watch,
    formState: { errors },
    register,
    getValues,
  } = useFormContext();

  return (
    <Grid item xs={12} container>
      <Grid container item xs={12} className={classes.pramClientFormRow}>
        <Grid
          item
          xs={3}
          className={classes.inputsSection}
          alignItems="flex-end"
        >
          <Typography>
            {t("fleet.management.formLabel.manDayValue")}
          </Typography>
        </Grid>
        <Grid item xs={6} className={classes.inputsSection}>
          <Grid item xs={12}>
            <TextField
              {...register("hoursPerManDay", {
                required: {
                  value: true,
                  message: t("fleet.management.formControl.required"),
                },
                pattern: {
                  value: REGEX.AMOUNT,
                  message: t("fleet.management.formControl.number"),
                },
              })}
              id="hoursPerManDay"
              label={`${t("hours")} *`}
              disabled={disableFields}
              fullWidth
              InputLabelProps={{
                shrink: !!watch("hoursPerManDay"),
              }}
              error={!!errors.hoursPerManDay?.message}
              InputProps={{ inputProps: { min: 0 } }}
              defaultValue={defaultManDayHours}
            />
          </Grid>
          <p className={classes.validation_error}>
            {errors.hoursPerManDay?.message}
          </p>
        </Grid>
        <Grid
          item
          xs={3}
          className={classes.inputsSection}
          alignItems="flex-end"
        >
          <Typography>
            {t("hours")}
            {" "}
            *
          </Typography>
        </Grid>
      </Grid>
      <Grid container item xs={12} className={classes.pramClientFormRow}>
        <Grid
          item
          xs={3}
          className={classes.inputsSection}
          alignItems="flex-end"
        >
          <Typography>{t("fleet.management.formLabel.fullTime")}</Typography>
        </Grid>
        <Grid item xs={6} className={classes.inputsSection}>
          <Grid item xs={12}>
            <TextField
              {...register("daysPerYear", {
                required: {
                  value: true,
                  message: t("fleet.management.formControl.required"),
                },
                pattern: {
                  value: REGEX.AMOUNT,
                  message: t("fleet.management.formControl.number"),
                },
              })}
              id="daysPerYear"
              label={`${t("days")} *`}
              disabled={disableFields}
              fullWidth
              InputLabelProps={{
                shrink: !!watch("daysPerYear"),
              }}
              error={!!errors.daysPerYear?.message}
              defaultValue={defaultDaysPerYear}
            />
          </Grid>
          <p className={classes.validation_error}>
            {errors.daysPerYear?.message}
          </p>
        </Grid>
        <Grid
          item
          className={classes.inputsSection}
          alignItems="flex-end"
          xs={3}
        >
          <Typography>
            {t("days")}
            {" "}
            *
          </Typography>
        </Grid>
      </Grid>
      <Grid container item xs={12} className={classes.pramClientFormRow}>
        <Grid
          item
          xs={3}
          className={classes.inputsSection}
          alignItems="flex-end"
        >
          <Typography>{t("fleet.management.formLabel.bePeriod")}</Typography>
        </Grid>
        <Grid item xs={6} className={classes.inputsSection}>
          <Grid item xs={12}>
            <Select
              {...register("beCalculationPeriod", {
                required: {
                  value: true,
                  message: t("fleet.management.formControl.required"),
                },
              })}
              disabled={disableFields}
              fullWidth
              labelId="be-periods-select"
              defaultValue={getValues("beCalculationPeriod") || 90}
            >
              {bePeriods.map((elt, i) => (
                <MenuItem key={`be-period-item-${i}`} value={elt.days}>
                  {t(elt.label)}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <p className={classes.validation_error}>
            {errors.beCalculationPeriod?.message}
          </p>
        </Grid>
      </Grid>
      <Grid Container item xs={12} className={classes.pramClientFormRow}>
        <Grid
          item
          xs={3}
          className={classes.inputsSection}
          alignItems="flex-end"
        >
          <Typography>
            {t("fleet.management.formLabel.recommendedOccupancy")}
          </Typography>
        </Grid>
        <Grid item xs={6} className={classes.inputsSection}>
          <Grid item xs={12}>
            <TextField
              {...register("recommendedOccupancy", {
                required: {
                  value: true,
                  message: t("fleet.management.formControl.required"),
                },
                pattern: {
                  value: REGEX.AMOUNT,
                  message: t("fleet.management.formControl.occupancyNumber"),
                },
                min: {
                  value: 0,
                  message: t("fleet.management.formControl.occupancyLowerThan"),
                },
                max: {
                  value: 24,
                  message: t("fleet.management.formControl.occupancyHigherThan"),
                }
              })}
              id="recommendedOccupancy"
              label={`${t("hours")} *`}
              disabled={disableFields}
              fullWidth
              InputLabelProps={{
                shrink: !!watch("recommendedOccupancy"),
              }}
              error={!!errors.hoursPerManDay?.message}
              InputProps={{
              inputProps: { min: 0, max: 24 },
              endAdornment: (
                <InputAdornment
                  position="start"
                  >
                  <Typography>
                    {(100 * (getValues("recommendedOccupancy") / 24)) ? `(${((100 * (getValues("recommendedOccupancy") / 24)).toFixed(0))}%)` : " "}
                  </Typography>
                </InputAdornment>)
                  }}
              placeholder={`${t("hours")} *`}
              defaultValue={defaultRecommendedOcccupancy}
            />
          </Grid>
          <p className={classes.validation_error}>
            {errors.recommendedOccupancy?.message}
          </p>
        </Grid>
        <Grid
          item
          xs={3}
          className={classes.inputsSection}
          alignItems="flex-end"
        >
          <Typography>
            {t("fleet.management.hoursPerDay")}
            {" "}
            *
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ClientParameters;
