import { format } from "date-fns-tz";

export const getDatePattern = (language, withTime = true) => {
    const time = " HH:mm:ss";
    let pattern = "yyyy/MM/dd";
    switch (language) {
        case "en":
            pattern = "MM/dd/yyyy";
            break;
        case "fr":
            pattern = "dd/MM/yyyy";
            break;
        case "es":
            pattern = "dd/MM/yyyy";
            break;
        case "pl":
            pattern = "dd/MM/yyyy";
            break;
        case "de":
            pattern = "yyyy/mm/dd";
            break;
        default:
            break;
    }
    if (withTime) pattern += time;
    return pattern;
};

export const formatDate = (date, language, withTime = true) => {
    const pattern = getDatePattern(language, withTime);
    return format(new Date(date), pattern);
};
