import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useTranslation } from "react-i18next";
import CustomButton from "../CustomButton";
import { ReactComponent as ExportIcon } from "../../assets/common/export.svg";
import theme from "../../style/orchestratorTheme";

const ExportButton = ({ loading, label = "export", ...rest }) => {
  const { t } = useTranslation();
  return (
    <CustomButton
      {...rest}
      view="primary"
      startIcon={
        loading ? (
          <CircularProgress color="inherit" size={20} />
        ) : (
          <ExportIcon
            height={16}
            stroke={
              rest.disabled ? theme.custom.color.button.disabledIcon : "white"
            }
          />
        )
      }
    >
      {t(label)}
    </CustomButton>
  );
};

export default ExportButton;
